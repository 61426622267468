/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-18 22:08:33
 * @modify date 2022-04-18 22:08:33
 */

import moment from 'moment'
import { toString } from 'lodash'

export const preProcessorCuppingSessionForm = (sn: any) => {
  return {
    ...sn,
    id: toString(sn.id) || '',
    name: sn.name || '',
    remote: sn.remote || false,
    startsAt: moment(sn.startsAt).valueOf() || new Date(),
    endsAt: moment(sn.endsAt).valueOf() || new Date(),
    scoreTarget: sn.scoreTarget === 1 ? true : false,
  }
}