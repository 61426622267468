import React, {useEffect, useRef, useState} from "react"
import {camelCase, cloneDeep, isEmpty, uniq} from "lodash";
import { useTranslation } from "react-i18next"
import {useNavigate, useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {
  Box,
  Container,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
  Button, IconButton, Select, MenuItem, TextField, InputAdornment
} from "@mui/material"
import {Add, Delete, Edit, ExpandLess, ExpandMore, Feed} from "@mui/icons-material"
import {
  createColumnHelper,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable
} from "@tanstack/react-table";
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'

import {useStores} from "models";
import { findRoute } from 'constants/routes'
import {BasicTable, HeaderTitle, ModalConfirm, ModalMessage, ShipmentOrderForm} from "components"
import {optionsTranslation, str2bool} from "utils"
import { fulfillmentTypes } from "constants/form"

import { useStyles } from "./sample-shipment-form.styles"
import {AddSamplesModal, ShipmentTable} from "./components";
import {GenerateLabel} from "../modal/generate-label/generate-label";
import {PrintLabel} from "../modal/generate-label/print-label/print-label";

const columnHelper = createColumnHelper<any>()

export const SampleShipmentForm = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const navigate = useNavigate()
  const params = useParams()
  const isCreate = params.id === 'new'

  const {
    companyStore: { name: companyName },
    sampleShipmentStore: {
      samplesToBeShipment, shipmentForm, groupSamplesToBeShipment, isFormComplete, sampleShipmentCounter,
      setSamplesToBeShipment, setThirdPartyEmails, saveSampleShipment, removeSampleShipments, resetShipmentForm
    },
    notificationStore
  } = useStores()

  const homeLink = findRoute('home')
  const [rowSelection, setRowSelection] = useState({})
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState(cloneDeep(samplesToBeShipment))
  const [openAddSampleModal, setOpenAddSampleModal] = useState(false)
  const [openSuccessAddedSample, setOpenSuccessAddedSample] = useState(false)
  const [openSuccessEditedSample, setOpenSuccessEditedSample] = useState(false)
  const [openConfirmShipment, setOpenConfirmShipment] = useState(false)
  const [openSuccessCreatedShipment, setOpenSuccessCreatedShipment] = useState(false)
  const [openConfirmRemove, setOpenConfirmRemove] = useState(false)
  const [openSuccessRemovedSample, setOpenSuccessRemovedSample] = useState(false)
  const refInputSampleLocation = useRef('')
  const refInputSampleWeight = useRef('')
  const [openGenerateLabel, setOpenGenerateLable] = useState(false)
  const [openPrintLabel, setOpenPrintLable] = useState(false)
  const [indexSamples, setIndexSamples] = useState(0)

  const resetRefInput = () => {
    refInputSampleLocation.current = ''
    refInputSampleWeight.current = ''
  }

  const onSetDataValue = (rowIndex, key, value) => {
    setData(old => old.map((row, index)=> {
      if (rowIndex === index) {
        return {
          ...old[rowIndex]!,
          [key]: value,
          ...(key === 'sampleFulfillmentType' && { 'sampleLocation': companyName })
        }
      }

      return row
    }))
  }

  const renderEditSampleLocation = (info) => {
    const { sampleFulfillmentType } = info.row.original
    if (!isEdit || sampleFulfillmentType === fulfillmentTypes[0]) {
      return info.renderValue()
    }

    const key = 'sampleLocation' + info.row.index
    return (
      <TextField
        fullWidth
        size='small'
        autoFocus={key === refInputSampleLocation.current}
        value={info.renderValue()}
        onChange={(e) => {
          refInputSampleWeight.current = ''
          refInputSampleLocation.current = key
          onSetDataValue(info.row.index,'sampleLocation', e.target.value)
        }}
      />
    )
  }

  const renderEditSampleWeight = (info) => {
    if (!isEdit) {
      return (
        <Box textAlign="center">
          {info.renderValue() ? `${info.renderValue()} gr` : '-' }
        </Box>
      )
    }

    const key = 'sampleWeight' + info.row.index
    return (
      <CurrencyTextField
        fullWidth
        size='small'
        type='text'
        inputMode='numeric'
        variant='outlined'
        textAlign='left'
        currencySymbol=''
        value={info.renderValue()}
        outputFormat="string"
        decimalCharacter="."
        digitGroupSeparator=","
        minimumValue="0"
        decimalPlaces={0}
        autoFocus={key === refInputSampleWeight.current}
        onChange={(event, value)=> {
          refInputSampleLocation.current = ''
          refInputSampleWeight.current = key
          onSetDataValue(info.row.index,'sampleWeight', value)
        }}
        InputProps={{
          endAdornment: (<InputAdornment position="end">gr</InputAdornment>)
        }}
      />
    )
  }

  const renderSubItem = (label, value) => {
    const val = value ? value : '-'
    return (
      <Box>
        <Typography variant="subtitle1">{label}</Typography>
        <Typography variant="body2">{val}</Typography>
      </Box>
    )
  }

  const renderSubComponent = ({ row }) => {
    const { sample } = row.original

    const items = [
      { label: t('shipment.table.producer'), value: sample.producerName },
      { label: t('shipment.table.purchaseContract'), value: sample.purchaseContractReference },
      { label: t('shipment.table.salesContract'), value: sample.salesContractReference },
      { label: t('shipment.table.processing'), value: sample.processName },
    ]

    return (
      <Stack direction="row" spacing={2} className={classes.collapseWrapper}>
        {items.map(item => renderSubItem(item.label, item.value))}
      </Stack>
    )
  }

  const columns = [
    columnHelper.accessor('sampleType', {
      id: 'sampleType',
      header: ({ table }) =>
        <Box textAlign="left">
          <FormControlLabel
            label={t('shipment.table.type')}
            control={
              <Checkbox
                checked={table.getIsAllRowsSelected()}
                indeterminate={table.getIsSomeRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
              />
            }
          />
        </Box>,
      cell: info =>
        <Box textAlign="left">
          <FormControlLabel
            label={optionsTranslation('sampleType', info.renderValue())}
            control={
              <Checkbox
                checked={info.row.getIsSelected()}
                onChange={info.row.getToggleSelectedHandler()}
              />
            }
          />
        </Box>,
    }),
    columnHelper.accessor('sampleName', {
      id: 'sampleName',
      header: () => <Box textAlign="left">{t('shipment.table.sampleName')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('sampleGrade', {
      id: 'sampleGrade',
      header: () => <Box textAlign="left">{t('shipment.table.sampleGrade')}</Box>,
      cell: info => optionsTranslation('grades', info.renderValue()),
    }),
    columnHelper.accessor('sampleLocation', {
      id: 'sampleLocation',
      header: () => <Box textAlign="left">{t('shipment.table.sampleLocation')}</Box>,
      cell: renderEditSampleLocation,
    }),
    columnHelper.accessor('sampleFulfillmentType', {
      id: 'sampleFulfillmentType',
      header: () => <Box textAlign="left">{t('shipment.table.fulfillmentType')}</Box>,
      cell: info => isEdit ?
        <Select
          size='small'
          value={info.renderValue()}
          onChange={(e) => {
            resetRefInput()
            onSetDataValue(info.row.index,'sampleFulfillmentType', e.target.value)
          }}
        >
          {fulfillmentTypes.map(item =>
            <MenuItem key={item} value={item}>{t(`options.fulfillmentType.${camelCase(item)}`)}</MenuItem>
          )}
        </Select> :
        optionsTranslation('fulfillmentType', info.renderValue()),
    }),
    columnHelper.accessor('isApprovalNeeded', {
      id: 'isApprovalNeeded',
      header: () => t('shipment.table.approvalNeeded'),
      cell: info => isEdit ?
        <Select
          size='small'
          value={info.renderValue()}
          onChange={(e) => {
            resetRefInput()
            onSetDataValue(info.row.index,'isApprovalNeeded', str2bool(e.target.value))
          }}
        >
          {[true, false].map(bool =>
            <MenuItem key={bool.toString()} value={bool.toString()}>
              {optionsTranslation('boolean', bool.toString())}
            </MenuItem>
          )}
        </Select> :
        <Box textAlign="center">
          {optionsTranslation('boolean', info.renderValue().toString())}
        </Box>,
    }),
    columnHelper.accessor('sampleWeight', {
      id: 'sampleWeight',
      header: () => t('shipment.table.weight'),
      cell: renderEditSampleWeight,
    }),
    columnHelper.accessor('isRoasted', {
      id: 'isRoasted',
      header: () => t('shipment.table.roasted'),
      cell: info => isEdit ?
        <Select
          size='small'
          value={info.renderValue()}
          onChange={(e) => {
            resetRefInput()
            onSetDataValue(info.row.index,'isRoasted', str2bool(e.target.value))
          }}
        >
          {[true, false].map(bool =>
            <MenuItem key={bool.toString()} value={bool.toString()}>
              {optionsTranslation('boolean', bool.toString())}
            </MenuItem>
          )}
        </Select> :
        <Box textAlign="center">
          {optionsTranslation('boolean', info.renderValue().toString())}
        </Box>,
    }),
    columnHelper.accessor('label', {
      id: 'label',
      header: () => t('shipment.table.generateLabel'),
      cell: info =>
        <Box textAlign="center">
          <Button
            variant="contained"
            size="small"
            sx={{p: 0.5, minWidth: 'fit-content'}}
            onClick={() => {
              setIndexSamples(parseInt(info.row.id))
              setOpenGenerateLable(true)
            }}
          >
            <Feed />
          </Button>
        </Box>,
    }),
    columnHelper.accessor('expand', {
      id: 'expand',
      header: () => '',
      cell: info =>
      <Box textAlign="center">
        <IconButton
          size="small"
          color="primary"
          onClick={info.row.getToggleExpandedHandler()}
        >
          {info.row.getIsExpanded() ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>,
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    state: { rowSelection, expanded },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    manualExpanding: true,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: row => row.sampleId,
    getCoreRowModel: getCoreRowModel(),
  })

  const onSaveSampleShipment = async () => {
    try {
      setOpenConfirmShipment(false)
      notificationStore.setLoading(true)
      await saveSampleShipment()
      setOpenSuccessCreatedShipment(true)
    } finally {
      notificationStore.setLoading(false)
    }
  }

  const onRemoveSamples = () => {
    const selections = uniq(Object.keys(rowSelection).map(key => parseInt(key)))
    const samples = removeSampleShipments(selections)
    setRowSelection({})
    setData(samples)
    setOpenConfirmRemove(false)
    setOpenSuccessRemovedSample(true)
  }

  useEffect(() => {
    if (isCreate) {
      resetShipmentForm()
    }
  }, [])

  return (
    <Box sx={{pb: {xs: 7, md: 0}}}>
      <HeaderTitle
        backUrl={homeLink}
        breadcrumb={t('common.backTo', { menu: t('menu.home')})}
        title={t(`shipment.${isCreate ? 'createSampleShipment' : 'editSampleShipment'}`)}
      />

      <Container sx={{mb: 2}}>
        <Typography variant='h5' mb={2}>{t('shipment.generalInformationForm')}</Typography>

        <Box className={classes.wrapper}>
          <ShipmentOrderForm {...shipmentForm} setValue={shipmentForm.setValue} />
        </Box>
      </Container>
      <Container sx={{mb: 2}}>
        <Typography variant='h5' mb={2}>{t('shipment.sampleInformation')}</Typography>

        <Box className={classes.wrapper}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant='h6'>
              {t('shipment.table.sample', { count: samplesToBeShipment.length })}
            </Typography>

            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                size="small"
                startIcon={<Add />}
                onClick={() => setOpenAddSampleModal(true)}
              >
                {t("shipment.button.addSample")}
              </Button>
              <Button
                variant="contained"
                size="small"
                startIcon={<Delete />}
                onClick={() => setOpenConfirmRemove(true)}
                disabled={isEmpty(rowSelection)}
              >
                {t("shipment.button.removeSample")}
              </Button>
              <Button
                variant="contained"
                size="small"
                startIcon={<Edit />}
                onClick={() => setIsEdit(true)}
                disabled={isEdit}
              >
                {t("shipment.button.edit")}
              </Button>
            </Stack>
          </Stack>

          <BasicTable table={table} renderSubComponent={renderSubComponent} />

          {isEdit && (
            <Stack direction="row" spacing={2} justifyContent="end" mt={3}>
              <Button
                variant="contained"
                sx={{width: 200}}
                onClick={() => {
                  setSamplesToBeShipment(data)
                  setIsEdit(false)
                  setOpenSuccessEditedSample(true)
                }}
              >
                {t(`shipment.button.saveChanges`)}
              </Button>
              <Button
                variant="outlined"
                sx={{width: 200}}
                onClick={() => {
                  setData(cloneDeep(samplesToBeShipment))
                  setIsEdit(false)
                }}
              >
                {t("common.cancel")}
              </Button>
            </Stack>
          )}
        </Box>
      </Container>

      <Container sx={{mt: 2, mb: 4}}>
        <Typography variant='h5' mb={2}>{t('shipment.shipmentsToBeCreated')}</Typography>

        {Object.keys(groupSamplesToBeShipment).map(group => {
          return (
            <ShipmentTable
              key={group}
              groupSamplesToBeShipment={groupSamplesToBeShipment}
              group={group}
              onSubmitEmail={setThirdPartyEmails}
            />
          )
        })}

        <Stack direction="row" spacing={2} justifyContent="end" mt={3}>
          <Button
            variant="contained"
            sx={{width: 200}}
            onClick={() => setOpenConfirmShipment(true)}
            disabled={!isFormComplete}
          >
            {t(`shipment.button.createShipment`)}
          </Button>
          <Button
            variant="outlined"
            sx={{width: 200}}
            onClick={() => navigate('/samples')}
          >
            {t("common.cancel")}
          </Button>
        </Stack>
      </Container>

      {openAddSampleModal &&
        <AddSamplesModal
          open={openAddSampleModal}
          onClose={() => setOpenAddSampleModal(false)}
          onSuccess={(samples) => {
            setData([...data, ...samples])
            setSamplesToBeShipment([...data, ...samples])
            setOpenAddSampleModal(false)
            setOpenSuccessAddedSample(true)
          }}
        />
      }

      <GenerateLabel
        index={indexSamples}
        open={openGenerateLabel}
        onClose={() => setOpenGenerateLable(false)}
        onSubmit={() => {
          setOpenGenerateLable(false)
          setOpenPrintLable(true)
        }}
      />

      <PrintLabel
        dataLabel={JSON.parse(samplesToBeShipment[indexSamples].label)}
        open={openPrintLabel}
        onClose={() => setOpenPrintLable(false)}
      />

      <ModalMessage
        open={openSuccessAddedSample}
        description={t('shipment.success.successfullyAddedSamples')}
        onSubmit={() => setOpenSuccessAddedSample(false)}
      />

      <ModalMessage
        open={openSuccessEditedSample}
        description={t('shipment.success.successfullyEditedSamples')}
        onSubmit={() => setOpenSuccessEditedSample(false)}
      />

      <ModalMessage
        open={openSuccessCreatedShipment}
        description={t('shipment.success.successfullyCreatedShipment')}
        onSubmit={() => {
          setOpenSuccessCreatedShipment(false)
          navigate('/sample-shipments')
        }}
        buttonText={t('shipment.button.goToShipmentPage')}
      />

      <ModalConfirm
        open={openConfirmShipment}
        onClose={() => setOpenConfirmShipment(false)}
        onConfirm={onSaveSampleShipment}
        title={t('shipment.createSampleShipment')}
        description={`
          ${t('shipment.modal.localShipment', {count: sampleShipmentCounter.local})} 
          ${t('shipment.modal.thirdPartyShipment', {count: sampleShipmentCounter.thirdParty})}
          <br /><br />
          ${t('shipment.modal.createShipmentConfirmation')}
        `}
      />

      <ModalConfirm
        open={openConfirmRemove}
        onClose={() => setOpenConfirmRemove(false)}
        confirm={t('common.remove')}
        onConfirm={onRemoveSamples}
        title={t('shipment.modal.removeSample')}
        description={t('shipment.modal.removeSampleDescription')}

      />

      <ModalMessage
        open={openSuccessRemovedSample}
        description={t('shipment.success.successfullyRemovedSamples')}
        onSubmit={() => setOpenSuccessRemovedSample(false)}
      />
    </Box>
  )
})
