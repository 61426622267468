/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {isBoolean, isEmpty, isNull} from "lodash"

import {
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"
import { userProps } from './user-props'
import { withUserActions } from './user-action'
import { DEFAULT_ADMIN } from 'config/env'

/**
 * Model description here for TypeScript hints.
 */
export const UserStoreModel = types
  .model("UserStore")
  .props(userProps)
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withUserActions)
  .views(self => ({
    get isProfileCompleted() {
      return self.name && self.name !== DEFAULT_ADMIN && self.role && isBoolean(self.isQgrader) && self.timeZone
    },
    get showInformation(){
      return isNull(self.isQgrader) || isNull(self.isProducer) || isEmpty(self.role)
    }
  }))

type UserStoreType = Instance<typeof UserStoreModel>
export interface UserStore extends UserStoreType {}
type UserStoreSnapshotType = SnapshotOut<typeof UserStoreModel>
export interface UserStoreSnapshot extends UserStoreSnapshotType {}
