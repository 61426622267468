/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-20 22:13:55
 * @modify date 2022-04-20 22:13:55
 */

import { filter, pick, ceil, find } from 'lodash'
import { flow, applySnapshot, getSnapshot } from "mobx-state-tree"
import { ApiResult } from "services/api"
import { DATA_PER_PAGE } from "config/env"
import users from "fixtures/user.json"
import i18n from 'i18n/i18n'

export const withCupperActions = (self: any) => ({
  actions: {
    setMessage(locale: string){
      const { setNotification } = self.rootStore.notificationStore

      setNotification({
        severity: 'success',
        message: i18n.t(locale)
      })
    },
    getCuppers: flow(function * (loadMore = false, options) {
      try {
        const { guideCupper, guideCupping } = self.rootStore.guideStore

        if (guideCupper || guideCupping) {
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            page: 1,
            totalPage: 1,
            cuppers: users.data,
            nextUrl: undefined,
          })

          return
        }

        if (loadMore && !self.nextUrl) return
        const page = loadMore ? self.page + 1 : self.page

        const res: ApiResult = yield self
          .environment
          .cupperApi
          .all({
            "size": DATA_PER_PAGE,
            "page[number]": page,
            "search": self.search,
            ...options
          })

        if (res.kind === "ok") {
          const totalPage = ceil(res.meta.pagination.records / DATA_PER_PAGE)

          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            page,
            totalPage,
            cuppers: loadMore ? [...self.cuppers, ...res.data] : res.data,
            nextUrl: res.links?.next || undefined,
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    invite: flow(function * () {
      try {
        const payload: any = pick(self, ['email', 'message'])
        const res: ApiResult = yield self.environment.cupperApi.save(payload)

        if (res.kind === "ok") return res.data

      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    remindCupper: flow(function * (id: string) {
      try {
        const payload: any = { cupperId: id }
        const res: ApiResult = yield self.environment.invitationReminderApi.save(payload)

        if (res.kind === "ok") self.setMessage('cupper.success.successfullyReminded')
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    removeCupper: flow(function * (id: string) {
      try {
        const res: ApiResult = yield self.environment.cupperApi.remove(id)

        if (res.kind === "ok") {
          const cuppers = filter(self.cuppers, cupper => cupper.id !== id)
          self.setValue('cuppers', cuppers)

          self.setMessage('cupper.success.successfullyRemoved')
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    createCuppingCupper: flow(function * (id: string, canCreateCuppingSessions: boolean) {
      try {
        const { guideCupper } = self.rootStore.guideStore
        if (guideCupper) {
          const cupper = find(self.cuppers, ['id', id])
          cupper.canCreateCuppingSessions = canCreateCuppingSessions
          return
        }

        const payload: any = { id, canCreateCuppingSessions }
        const res: ApiResult = yield self.environment.cuppingSessionsPermissionApi.save(payload)

        if (res.kind === "ok") self.setMessage('cupper.success.successfullyUpdatedStatus')
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    adminCupper: flow(function * (id: string, adminPrivileges: boolean) {
      try {
        const { guideCupper } = self.rootStore.guideStore
        if (guideCupper) {
          const cupper = find(self.cuppers, ['id', id])
          cupper.adminPrivileges = adminPrivileges
          return
        }

        const payload: any = { id, adminPrivileges }
        const res: ApiResult = yield self.environment.adminPrivilegeApi.save(payload)

        if (res.kind === "ok") self.setMessage('cupper.success.successfullyUpdatedStatus')
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    activeCupper: flow(function * (id: string, active: boolean) {
      try {
        const { guideCupper } = self.rootStore.guideStore
        if (guideCupper) {
          const cupper = find(self.cuppers, ['id', id])
          cupper.active = active
          return
        }

        const payload: any = { id, active }
        const res: ApiResult = yield self.environment.activeStatusApi.save(payload)

        if (res.kind === "ok") self.setMessage('cupper.success.successfullyUpdatedStatus')
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    approveSamplePermission: flow(function * (id: string, approve: boolean) {
      try {
        const payload: any = { id, canApproveSample: approve }
        const res: ApiResult = yield self.environment.approveSampleApi.save(payload)

        if (res.kind === "ok") self.setMessage('cupper.success.successfullyUpdatedApproveSample')
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),

    // Summary
    setCupperSummary(summary) {
      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        ...summary
      })
    },
    getCupperSummaries: flow(function * () {
      try {
        const res: ApiResult = yield self.environment.cupperSummaryApi.all()

        if (res.kind === "ok") self.setCupperSummary(res.data)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    })
  }
})
