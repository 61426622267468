import {applySnapshot, flow, getSnapshot} from "mobx-state-tree"
import {ceil} from "lodash";

import { ApiResult } from "services/api"
const DATA_PER_PAGE = 10

export const withActivityActions = (self: any) => ({
  actions: {
    getActivity: flow(function * (token) {
      try {
        if (!token) return

        const payload: any = {
          "size": DATA_PER_PAGE,
          "page[number]": self.page,
        }

        const additionalPath: string = 'activities'
        const res: ApiResult = yield self.environment.masterSampleApi.find(token, additionalPath, payload)

        if (res.kind === "ok") {
          const totalPage = ceil(res.meta.pagination.records / DATA_PER_PAGE)

          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            totalPage,
            activities: res.data
          })
        }

      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),

  }
})
