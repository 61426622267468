/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-07 19:27:21
 * @modify date 2022-03-07 19:27:21
 */

import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import {last, isString, startCase, toNumber, isUndefined, camelCase, isEmpty} from 'lodash'
import { Grid, Box, Typography, Divider, Avatar, TextField, Select, MenuItem, Card } from '@mui/material'
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'

import {IconRoastLevel, IconStartCupping} from 'assets/images'
import { descriptorSelection, descriptorTranslation } from 'utils'
import { roastLevels, defaultScores, coeDefaultScores, defaultDefects, coeDefaultDefects, colors } from 'constants/form'

import { allDescriptor } from 'constants/all-descriptors'
import { Descriptor } from 'components'

import { useStyles } from './sample-information.styles'
import { SampleInformationProps } from './sample-information.props'


export const SampleInformation: React.FC<SampleInformationProps> = (props: SampleInformationProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    sampleId, uniqKey, label, score, descriptors, value, description, isDefect, isEdit, isCoe, language, isScoreTarget,
    setDescriptor, keyDescriptor, descriptorList, onChange, setIsEditDescriptor
  } = props
  const descriptorLabels = descriptors ? descriptorSelection(allDescriptor, descriptors) : []

  const setValueDescriptor = (key, value) => {
    setDescriptor(key, value)
    setIsEditDescriptor()
  }

  const editForm = () => {
    if (isUndefined(score)) return

    if (isDefect) {
      return (
        <TextField
          size='small'
          type='tel'
          inputMode='numeric'
          value={score}
          onChange={(e) => onChange(toNumber(e.target.value) || 0)}
          sx={{width: 75}}
          inputProps={{ min: 0, pattern: '[0-9]*', className: classes.input }}
        />
      )
    }

    switch (uniqKey) {
      case 'roastLevel':
        return (
          <Select
            autoWidth
            size='small'
            value={score}
            onChange={(e) => onChange(e.target.value)}
            inputProps={{ className: classes.input }}
          >
            {roastLevels.map(level =>
              <MenuItem value={level} key={level}>
                <IconRoastLevel className={clsx(classes.roastLevelIcon, `color-${level}`)} />
              </MenuItem>
            )}
          </Select>
        )
      case 'fragrance':
      case 'mouthfeel':
      case 'flavor':
      case 'aftertaste':
      case 'acidity':
      case 'body':
      case 'balance':
      case 'salt':
      case 'bittersweet':
      case 'cleanCupScore':
      case 'sweetnessScore':
      case 'overall':
      case 'defects':
      case 'color':
        let items
        if (uniqKey === 'defects') {
          items = isCoe ? coeDefaultDefects : defaultDefects
        } else if (uniqKey === 'color') {
          items = colors
        } else {
          items = isCoe ? coeDefaultScores : defaultScores
        }

        return (
          <Select
            autoWidth
            value={score}
            onChange={(e) => onChange(e.target.value)}
            size='small'
            inputProps={{ className: classes.input }}
          >
            {items.map(score =>
              <MenuItem value={score} key={score}>
                {isString(score) ? (uniqKey === 'color' ? t(`options.color.${camelCase(score)}`) : startCase(score)) : score}
              </MenuItem>
            )}
          </Select>
        )
      case 'uniformity':
      case 'cleanCup':
      case 'sweetness':
        return (
          <Card className={classes.boxScore}>
            <Typography variant='h6'>{score}</Typography>
          </Card>
        )
      case 'weight':
        return (
          <CurrencyTextField
            size='small'
            type='text'
            inputMode='numeric'
            variant='outlined'
            textAlign='right'
            currencySymbol=''
            value={score}
            outputFormat="string"
            decimalCharacter="."
            digitGroupSeparator=","
            onChange={(event, value)=> onChange(value)}
          />
        )
      case 'smell':
        return (
          <TextField
            size='small'
            value={score}
            onChange={(e) => onChange(e.target.value)}
            sx={{width: 150}}
            inputProps={{ className: classes.input }}
          />
        )
      default:
        break;
    }
  }

  const editDiscriptor = () => {
    return (
      <Descriptor
        key={`${uniqKey}`}
        descriptorList={descriptorList}
        sampleId={sampleId}
        descriptors = {descriptors}
        addDescriptor={(values) => setValueDescriptor(keyDescriptor, values)}
      />
    )
  }

  const editCup = (index, state) => {
    if (!value) return

    value[index] = state
    onChange(value)
  }

  const renderScore = () => {
    switch (uniqKey) {
      case 'roastLevel':
        return (
          <IconRoastLevel className={clsx(classes.roastLevelIcon, `color-${score}`)} />
        )
      default:
        return (
          <Typography
            variant='subtitle2'
            className={clsx(classes.scoreDescriptorColor, isScoreTarget && 'selected-score-target')}
          >
            {isString(score) ? (uniqKey === 'color' ? t(`options.color.${camelCase(score)}`) : startCase(score)) : score}
          </Typography>
        )
    }
  }

  return (
    <Box className={classes.wrapper}>
      <Grid container spacing={2} sx={{alignItems: 'center'}}>
        <Grid item xs={12} md={isDefect ? 12 : 4} className={classes.score}>
          <Typography
            variant='subtitle2'
            className={clsx(classes.labelColor, isScoreTarget && 'selected-score-target')}
          >
            {label}
          </Typography>

          {isEdit ? editForm() : renderScore()}
        </Grid>
        {!isDefect &&
          <Grid item xs={12} md={8}>
              <Box className={classes.descriptorWrapper}>
                {(descriptors && isEdit) && editDiscriptor()}
                {(descriptors && !isEdit) &&
                  descriptorLabels.map((descriptor, index) =>
                      <React.Fragment key={index}>
                        <Typography
                          variant='subtitle2'
                          component='span'
                          className={clsx(classes.scoreDescriptorColor, isScoreTarget && 'selected-score-target')}
                        >
                          {descriptorTranslation(language, descriptor)}
                        </Typography>
                        {last(descriptorLabels) !== descriptorLabels[index] &&
                            <Divider orientation="vertical" sx={{mr: 1, height: 10}}/>
                        }
                      </React.Fragment>
                    )
                }
              </Box>

            {value &&
              <Box className={classes.descriptorWrapper}>
                {value.map((item, index) =>
                  <Avatar
                    key={index}
                    className={clsx(classes.cup, item && 'selected', (item && isScoreTarget === 1) && 'selected-score-target')}
                    {...(isEdit && {onClick: () => editCup(index, !item) })}
                  >
                    <IconStartCupping className={clsx(classes.cupIcon, item && 'selected')} />
                  </Avatar>
                )}
              </Box>
            }
            {!isUndefined(description) &&
              isEdit ?
                <TextField
                  fullWidth
                  multiline
                  minRows={3}
                  value={description}
                  onChange={(e) => onChange(e.target.value)}
                /> :
                <Typography variant='body2' sx={{wordBreak: 'break-word'}}>{description}</Typography>
            }
          </Grid>
        }
      </Grid>
    </Box>
  )
}
