/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-01 03:40:36
 * @modify date 2022-03-01 03:40:36
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors, verticalCentered } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
      padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
      '&.sca': {
        border: 'none',
        padding: '16px 0'
      }
    },
    cupWrapper: {
      display: 'flex',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap'
    },
    cup: {
      cursor: 'pointer',
      border: `1px solid ${colors.magnolia}`,
      backgroundColor: 'transparent',
      '&.selected': {
        border: 'none',
        backgroundColor: colors.primary.main
      },
      '&.sca-selected': {
        backgroundColor: '#FC6C17'
      },
      '&.sca-defect-selected': {
        backgroundColor: "#B12028"
      }
    },
    cupIcon: {
      fill: colors.magnolia,
      width: 30,
      height: 30,
      '&.selected': {
        fill: 'white'
      }
    },
    scoreWrapper: {
      ...verticalCentered,
      justifyContent: 'end',
      position: 'absolute',
      right: theme.spacing(4),
      padding: 0,
      paddingTop: `${theme.spacing(1)} !important`,
      [theme.breakpoints.up('md')]: {
        position: 'relative',
      },
    },
    score: {
      padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      minWidth: 40,
      textAlign: 'center',
      color: colors.text.o50,
      marginLeft: theme.spacing(1)
    }
  })
)
