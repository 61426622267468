/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-04-22 03:10:06
 */

import { Instance, SnapshotOut, types } from "mobx-state-tree"

import { 
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"

import { companyProps } from './company-props'
import { withCompanyViews } from './company-view'
import { withCompanyActions } from './company-action'

/**
 * Model description here for TypeScript hints.
 */
export const CompanyStoreModel = types
  .model("CompanyStore")
  .props(companyProps)
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withCompanyViews)
  .extend(withCompanyActions)

type CompanyStoreType = Instance<typeof CompanyStoreModel>
export interface CompanyStore extends CompanyStoreType {}
type CompanyStoreSnapshotType = SnapshotOut<typeof CompanyStoreModel>
export interface CompanyStoreSnapshot extends CompanyStoreSnapshotType {}
