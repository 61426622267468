/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-08 20:51:57
 * @modify date 2022-03-08 20:51:57
 */
import React from 'react'
import {
  FormControl,
  FormLabel,
  TextField,
  FormHelperText,
  Typography
} from '@mui/material'
import ReactHtmlParser from 'react-html-parser'

import { FormInputProps } from './form-input.props'

export const FormInput: React.FC<FormInputProps> = (props: FormInputProps) => {
  const {
    label,
    subLabel,
    formControlProps,
    formLabelProps,
    textFieldProps,
    children,
    helperText
  } = props

  const labelStringType = typeof label === 'string'

  return (
    <FormControl fullWidth margin='normal' {...formControlProps}>
      <FormLabel {...formLabelProps}>{labelStringType ? ReactHtmlParser(label) : label}</FormLabel>
      {subLabel && <Typography variant="body2" mt={-1} mb={1}>{subLabel}</Typography>}
      {textFieldProps && <TextField  fullWidth size='small' {...textFieldProps}/>}
      {children}
      {formControlProps?.error && <FormHelperText sx={{mx: 0}}>{helperText}</FormHelperText>}
    </FormControl>
  )
}
