/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-30 13:05:28
 * @modify date 2022-01-30 13:05:28
 * @desc [description]
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, Typography, Divider } from '@mui/material'

import {
  beanImage,
  oneCup,
  highestScore,
  lowestScore,
  goalCup,
  twoCups,
  threeCups,
  fourCups,
} from 'assets/images'

import { useStyles } from './card-score.styles'
import { CardScoreProps } from './card-score.props'

export const CardScore: React.FC<CardScoreProps> = (props: CardScoreProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { title, score, rightBorder } = props

  const image = () => {
    switch(title) {
      case "samplesCupped":
        return beanImage
      case "cuppingSessions":
        return oneCup
      case "highestScoreOfThisWeek":
        return highestScore
      case "lowestScoreOfThisWeek":
        return lowestScore
      case "weeklyGoalSamplesCupped":
      case "weeklyGoalCuppingSessions":
        return goalCup
      case "sampleCuppedWeekly":
      case "sampleCuppedHeldWeekly":
        return twoCups
      case "sampleCuppedMonthly":
      case "sampleCuppedHeldMonthly":
        return threeCups
      case "sampleCuppedQuarterly":
      case "sampleCuppedHeldQuarterly":
        return fourCups
      default:
        return fourCups
    }
  }

  return (
    <Box className={classes.wrapper}>
      <Card variant="outlined" className={classes.cardWrapper}>
        <Box className={classes.boxWrapper}>
          <Box className={classes.imageWrapper}>
            <img src={image()} alt={title} className={classes.image} />
          </Box>
          <Typography variant='h3' color='primary' sx={{wordBreak: 'break-word'}}>
            {score}
          </Typography>
        </Box>

        <Typography variant='subtitle2'>
          {t(`home.${title}`)}
        </Typography>
      </Card>
      {rightBorder && <Divider orientation="vertical" flexItem sx={{my: 4, display: {md: 'none'}}} />}
    </Box>
  )
} 