/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-05 12:40:53
 * @modify date 2022-04-14 22:43:50
 */

import React from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { MoreVert, NotificationsActive, DeleteOutline } from '@mui/icons-material'
import { Card, Grid, FormControlLabel, Checkbox, Typography, Menu, MenuItem, IconButton } from '@mui/material'

import { AvatarProfile, MenuIcon } from 'components'

import { useStyles } from './cupper-item.styles'
import { CupperItemProps } from './cupper-item.props'

export const CupperItem: React.FC<CupperItemProps> = (props: CupperItemProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    id, avatar, name, email, canCreateCuppingSessions, adminPrivileges, active, lastActivity, module,
    activationSettingDisabled, adminPrivilegesSettingDisabledFor, unremindable, unremovable, isAdmin, canApproveSample,
    onRemindCupper, onRemoveCupper, onCreateCupping, onAdminPrivilege, onActiveStatus, onApproveSamplePermission
  } = props

  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorMenu)
  const dateActivity = moment(lastActivity)
  const latestActivity = dateActivity.isValid() ? dateActivity.format('DD MMM YYYY | HH:mm') : ''

  return (
    <Card className={classes.wrapper}>
      <Grid container className={clsx(classes.container, module && 'module')}>
        <Grid item xs={12} md={module ? 12 : 3} className={clsx(classes.item, module && 'module')}>
          <AvatarProfile name={name} email={email} image={avatar} />
        </Grid>
        <Grid item xs={12} md={module ? 12 : 6} className={clsx(classes.item, module && 'module')}>
          <Grid container sx={{textAlign: 'center', alignItems: 'end'}}>
            <Grid item xs={3}>
              <FormControlLabel
                value="top"
                control={
                  <Checkbox
                    checked={canApproveSample}
                    disabled={!isAdmin}
                    onChange={() => onApproveSamplePermission(id, !canApproveSample)}
                  />
                }
                label={`${t('cupper.canApproveOrRejectSamples')}`}
                labelPlacement="top"
                className={clsx(classes.checkbox, module && 'module')}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                value="top"
                control={
                  <Checkbox
                    checked={canCreateCuppingSessions}
                    disabled={activationSettingDisabled || !isAdmin}
                    onChange={() => onCreateCupping(id, !canCreateCuppingSessions)}
                  />
                }
                label={`${t('cupper.canCreateCuppingSession')}`}
                labelPlacement="top"
                className={clsx(classes.checkbox, module && 'module')}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                value="top"
                control={
                  <Checkbox
                    checked={adminPrivileges}
                    disabled={adminPrivilegesSettingDisabledFor || !isAdmin}
                    onChange={() => onAdminPrivilege(id, !adminPrivileges)}
                  />
                }
                label={`${t('cupper.admin')}`}
                labelPlacement="top"
                className={clsx(classes.checkbox, module && 'module')}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                value="top"
                control={
                  <Checkbox
                    checked={active}
                    disabled={activationSettingDisabled || !isAdmin}
                    onChange={() => onActiveStatus(id, !active)}
                  />
                }
                label={`${t('cupper.active')}`}
                labelPlacement="top"
                className={clsx(classes.checkbox, module && 'module')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={module ? 12 : 2} className={clsx(classes.item, module && 'module')}>
          <Typography variant='body2' className={clsx(classes.latestActivity, !module && 'list')}>
            {latestActivity}
          </Typography>
        </Grid>
        <Grid item xs={12} md={module ? 12  : 1} className={clsx(classes.menuList, module && 'module')}>
          <IconButton
            size="small"
            color="primary"
            onClick={(event) => setAnchorMenu(event.currentTarget)}
            className={clsx(classes.iconButton, module && 'module')}
          >
            <MoreVert />
          </IconButton>

          <Menu
            anchorEl={anchorMenu}
            open={openMenu}
            onClose={() => setAnchorMenu(null)}
            onClick={() => setAnchorMenu(null)}
          >
            <MenuItem
              onClick={() => onRemindCupper(id)}
              disabled={unremindable || !isAdmin}
            >
              <MenuIcon><NotificationsActive /></MenuIcon>
              {t('cupper.remind')}
            </MenuItem>
            <MenuItem
              onClick={() => onRemoveCupper(id)}
              disabled={unremovable || !isAdmin}
            >
              <MenuIcon><DeleteOutline /></MenuIcon>
              {t('common.delete')}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Card>
  )
}
