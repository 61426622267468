/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */

import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { 
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"

import { sampleScoreProps } from "./sample-score-props"
import { withSampleScoreViews } from "./sample-score-view"
import { withSampleScoreActions } from "./sample-score-action"

/**
 * Model description here for TypeScript hints.
 */
export const SampleScoreModel = types
  .model("SampleScore")
  .props(sampleScoreProps)
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withSampleScoreViews)
  .extend(withSampleScoreActions)

type SampleScoreType = Instance<typeof SampleScoreModel>
export interface SampleScore extends SampleScoreType {}
type SampleScoreSnapshotType = SnapshotOut<typeof SampleScoreModel>
export interface SampleScoreSnapshot extends SampleScoreSnapshotType {}
