/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-30 13:04:33
 * @modify date 2022-01-30 13:04:33
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors } from 'assets'

 export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      border: 'none',
      borderRadius: 0,
      padding: `${theme.spacing(2.5)} ${theme.spacing(3)}`,
      [theme.breakpoints.up('md')]: {
        border: `1px solid ${colors.border.primary}`,
        borderRadius: 5,
      }
    },
    avatarGroup: {
      justifyContent: 'start',
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2)
    },
  })
 )