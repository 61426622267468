/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-01 03:40:36
 * @modify date 2022-04-19 02:18:52
 */

import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Card, Grid, Typography, Avatar } from '@mui/material'

import { verticalCentered } from 'assets'
import { IconStartCupping } from 'assets/images'

import { CupProps } from './cup.props'
import { useStyles } from './cup.styles'

export const Cup: React.FC<CupProps> = (props: CupProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { label, cups, score, onClick, isSca, isScaDefect } = props

  const forSca = isSca || isScaDefect

  return (
    <Card className={clsx(classes.wrapper, forSca && 'sca')}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={forSca ? 5 : 3} sx={{...verticalCentered}}>
          <Typography variant='subtitle1'>{label}</Typography>
        </Grid>
        <Grid item xs={12} md={forSca ? 7 : 6} className={classes.cupWrapper}>
          {cups.map((item, index) =>
            <Avatar key={index} className={clsx(classes.cup, item && 'selected', item && isSca && 'sca-selected', item && isScaDefect && 'sca-defect-selected')} onClick={() => onClick(index)}>
              <IconStartCupping className={clsx(classes.cupIcon, item && 'selected')} />
            </Avatar>
          )}
        </Grid>
        {!forSca &&
          <Grid item xs={12} md={3} className={classes.scoreWrapper}>
            <Typography variant='body3'>{t('descriptor.score')}</Typography>
            <Card className={classes.score}>{score}</Card>
          </Grid>
        }
      </Grid>
    </Card>
  )
}
