/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-30 13:04:33
 * @modify date 2022-01-30 13:04:33
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { verticalCentered } from 'assets'


export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3)
    },
    linkWrapper: {
      ...verticalCentered,
      marginBottom: theme.spacing(1)
    },
    link: {
      display: 'flex',
      marginRight: theme.spacing(1)
    },
    titleWrapper: {
      ...verticalCentered,
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
      }
    },
    title: {
      [theme.breakpoints.down('md')]: {
        alignSelf: 'flex-start',
        '&.has-content': {
          marginBottom: theme.spacing(3)
        }
      }
    }
  })
)
