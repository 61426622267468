import React from 'react'
import { useTranslation } from "react-i18next"
import {Button, Grid, Stack, TextField, Typography} from "@mui/material"
import {clone, filter, find, includes, isString, truncate} from "lodash"
import {isMobile} from "react-device-detect"

import { Descriptor } from 'components'
import { flavorDescriptorList } from 'constants/flavor-descriptors'

import {translateScaDescriptor} from "utils"
import {sucafinaTastingForm} from "constants/form"

export const SucafinaTastingInformation = (
  {
    score,
    sampleId,
    isEdit,
    setIsEditScore,
    setIsEditDescriptor,
    language,
  }: {
    score: any,
    sampleId: any,
    isEdit: boolean,
    setIsEditScore: () => void,
    setIsEditDescriptor: () => void,
    language: string,
  }) => {
  const { t } = useTranslation()
  const { emoticons, interestStatus, tastingDescriptors, notes, setScoreValue } = score
  const [tempEmoticons, setTempEmoticons] = React.useState(clone(emoticons))

  const truncateDescription = (desc: string) => {
    if (isMobile) return truncate(desc, {
      'length': 50,
      'omission': '...'
    })

    return desc
  }

  const setValue = (key, value) => {
    setScoreValue(key, value)
    setIsEditScore()
  }

  const setValueDescriptor = (key, value) => {
    setScoreValue(key, value)
    setIsEditDescriptor()
  }

  const addOrRemoveEmoticon = (value) => {
    const newEmoticons = includes(emoticons, value)
      ? filter(emoticons, emo => emo !== value)
      : [...emoticons, value]

    setTempEmoticons(newEmoticons)
    setValue('emoticons', newEmoticons)
  }

  const renderEmoticon = (value: number) => {
    const emo = find(sucafinaTastingForm, ['value', value])
    if (!emo) return null

    return (
      <Button
        variant='outlined'
        {...isEdit && {onClick: () => addOrRemoveEmoticon(emo.value)}}
      >
        <emo.emoticon width={24} height={24} opacity={includes(tempEmoticons, emo.value) ? 1 : 0.5} />
      </Button>
    )
  }

  const renderInterestStatus = () => {
    return (
      <Stack direction="row" spacing={isEdit ? 2 : 0}>
        <Button
          variant={interestStatus && isEdit ? 'contained' : 'outlined'}
          sx={{display: !interestStatus && !isEdit ? 'none' : 'block'}}
          {...isEdit && {onClick: () => setValue('interestStatus', true)}}
        >
          {t('options.boolean.true')}
        </Button>
        <Button
          variant={!interestStatus && isEdit ? 'contained' : 'outlined'}
          sx={{display: interestStatus && !isEdit ? 'none' : 'block'}}
          {...isEdit && {onClick: () => setValue('interestStatus', false)}}
        >
          {t('options.boolean.false')}
        </Button>
      </Stack>
    )
  }

  const renderTastingDescriptors = (descriptor) => {
    return (
      <Typography variant={isString(descriptor) ? "caption" : "h6"} color="primary">
        {isString(descriptor) ? truncateDescription(descriptor) : translateScaDescriptor(language, descriptor)}
      </Typography>
    )
  }

  const renderNote = () => {
    if (isEdit) {
      return (
        <TextField
          fullWidth
          multiline
          minRows={4}
          value={notes}
          onChange={(e) => setValue('notes', e.target.value)}
        />
      )
    }

    return (
      <Typography variant="caption" color="primary">
        {notes}
      </Typography>
    )
  }

  const editDiscriptor = () => {
    return (
      <Descriptor
        key={`descriptor${tastingDescriptors}`}
        sampleId={sampleId}
        descriptors={tastingDescriptors}
        descriptorList={flavorDescriptorList}
        addDescriptor={(values) => setValueDescriptor('tastingDescriptors', values)}
      />
      )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant='h6' sx={{mb: 2}} textTransform="uppercase">
          {t('sampleScore.howDidYourFeel')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap" mb={3}>
          {!isEdit && tempEmoticons.map(emoticon => renderEmoticon(emoticon))}
          {isEdit && sucafinaTastingForm.map(emoticon => renderEmoticon(emoticon.value))}
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant='h6' sx={{mb: 2}} textTransform="uppercase">
          {t('sampleScore.IdBeInterested')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap" mb={3}>
          {renderInterestStatus()}
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant='h6' sx={{mb: 2}} textTransform="uppercase">
          {t('descriptor.tasting')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap" mb={3}>
          {isEdit ? editDiscriptor() : tastingDescriptors.map(descriptor => renderTastingDescriptors(descriptor))}
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant='h6' sx={{mb: 2}} textTransform="uppercase">
          {t('descriptor.notes')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        {renderNote()}
      </Grid>
    </Grid>
  )
}
