import {types} from "mobx-state-tree";
import {defaultSampleProps} from "../sample/default-sample.props";

export const sampleProps = {
  id: types.maybeNull(types.string),
  sampleType: types.maybeNull(types.string),
  sampleName: types.maybeNull(types.string),
  sampleGrade: types.maybeNull(types.string),
  sampleLocation: types.maybeNull(types.string),
  sampleFulfillmentType: types.maybeNull(types.string),
  isApprovalNeeded: types.maybeNull(types.boolean),
  sampleWeight: types.maybeNull(types.string),
  isRoasted: types.maybeNull(types.boolean),
  thirdPartyEmail: types.maybeNull(types.string),
  sampleWarehouseReference: types.maybeNull(types.string),
  sampleId: types.maybeNull(types.union(types.string, types.number)),
  sample: types.maybeNull(types.model(defaultSampleProps)),
  label:types.maybeNull(types.string)
}
