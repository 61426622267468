import React from 'react'
import { observer } from "mobx-react-lite"
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, IconButton, Snackbar, SvgIcon, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import ReactHtmlParser from 'react-html-parser'
import { useNavigate } from "react-router-dom"
import { findRoute } from 'constants/routes'

import { useStores } from "models"
import { MiniLogo } from 'assets/images'
import { colors } from 'assets'

export const TutorialNotification = observer(() => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const {
        userStore: { sawNavigationTutorial, updateNavigationTutorial, setValue: setUserValue }
    } = useStores()

    const [isMobile, setIsMobile] = React.useState(false)
    const tutorialLink = findRoute('tutorial')

    const handleClose = (update = false) => {
      setUserValue('sawNavigationTutorial', true)
      if (!update) return

      updateNavigationTutorial()
    }

    const onSetLayout = () => {

        if (window.innerWidth < 900) {
            setIsMobile(true)
            return
        }

        setIsMobile(false)
    }

    React.useLayoutEffect(() => {
        window.addEventListener('resize', onSetLayout)

        onSetLayout()
        return () => window.removeEventListener("resize", onSetLayout)
    }, [])

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={!sawNavigationTutorial}
            onClose={() => handleClose()}
            >
            <Box component="div" sx={{
                display: 'inline',
                boxShadow: 3,
                width: 458,
                height: 180,
                bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                color: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                p: 1,
                m: 1,
                borderRadius: 2,
                textAlign: 'left',
            }}>
                <Grid container sx={{ marginLeft: 1 }}>
                    {isMobile ?
                        <>
                            <Grid item xs={2} sx={{ pt: 2 }}>
                                <SvgIcon component={MiniLogo} inheritViewBox sx={{ height: 50, width: 50, mb: 2 }} />
                            </Grid>

                            <Grid item xs={10} sx={{ pt: 1 }}>
                                <Typography sx={{ mb: 1 }}>
                                    {ReactHtmlParser(t('modal.tutorialModalTitle'))}
                                </Typography>
                                <Typography sx={{ mb: 1 }}>
                                    {ReactHtmlParser(t('modal.tutorialModalDescription'))}
                                </Typography>
                            </Grid>

                        </>
                        :
                        <>
                            <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => handleClose()}
                                >
                                    <CloseIcon fontSize="small" sx={{ color: colors.text.o25 }} />
                                </IconButton>
                            </Grid>

                            <Grid item xs={10} sx={{ mt: -2 }}>
                                <Typography sx={{ mb: 1 }}>
                                    {ReactHtmlParser(t('modal.tutorialModalTitle'))}
                                </Typography>
                                <Typography sx={{ mb: 1 }}>
                                    {ReactHtmlParser(t('modal.tutorialModalDescription'))}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ mt: -2 }}>
                                <SvgIcon component={MiniLogo} inheritViewBox sx={{ height: 50, width: 50, mb: 2 }} />

                            </Grid>
                        </>

                    }

                    <Grid container sx={{ maxWidth: "450px", mt: 2 }}>
                        <Grid item xs={6}>
                            <Button
                                variant='contained'
                                color='primary'
                                size="medium"
                                onClick={() => navigate(tutorialLink)}
                            >
                                {t('tutorial.yesGotoTutorial')}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant='text'
                                size="medium"
                                sx={{ backgroundColor: 'white' }}
                                onClick={() => handleClose(true)}
                            >
                                <Typography sx={{ color: colors.primary.main }}> {t('tutorial.SkipForNow')}</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

        </Snackbar>
    )
})