import {Instance, SnapshotOut, types} from "mobx-state-tree";
import {withEnvironment, withGeneralError, withReset, withRootStore, withSetValue} from "models/extensions";

import {shipmentOrderFormProps} from "./shipment-order-form.props";

export const ShipmentOrderFormModel = types
  .model("ShipmentOrderForm")
  .props(shipmentOrderFormProps)
  .extend(withRootStore)
  .extend(withEnvironment)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)

type ShipmentOrderFormType = Instance<typeof ShipmentOrderFormModel>
export interface ShipmentOrderForm extends ShipmentOrderFormType {}
type ShipmentOrderFormSnapshotType = SnapshotOut<typeof ShipmentOrderFormModel>
export interface ShipmentOrderFormSnapshot extends ShipmentOrderFormSnapshotType {}
