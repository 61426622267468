/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-03 20:13:36
 * @modify date 2022-02-03 20:13:36
 */

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { TabPanel, LoadingButton } from '@mui/lab'
import {isEmpty, camelCase, has, assign, toString, isNull} from 'lodash'
import {
  Box,
  Grid,
  Button,
  Typography,
  Select,
  MenuItem,
  Stack,
  Divider,
  FormControlLabel,
  Radio, Collapse, FormHelperText
} from '@mui/material'
import {ExpandLess, ExpandMore} from "@mui/icons-material"

import { useStores } from 'models'
import { timezones } from 'constants/timezone'
import { LANGUANGES } from 'constants/languanges'
import {errorMessage, errorStatus, globalAction, pointerMessages, str2bool, validateEmail} from 'utils'
import { Avatar, FormInput, ModalConfirm, ModalCropImage } from 'components'
import { roles } from "constants/form"
import { verticalCentered } from 'assets'

import {useStyles} from "./profile.styles"

export const Profile: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    userStore: {
      name, email, avatar, languagePreference, timeZone, role: userRole, isQgrader, isProducer,
      saveProfile, uploadAvatar, removeAvatar, setValue: setUserValue, isCompanyAdmin
    },
    modalStore: { setValue: setModalValue },
    companyStore: { companyType, countryCode, name: companyName},
    notificationStore
  } = useStores()
  const [fileProfile, setFileProfile] = React.useState<any>()
  const [openCrop, setOpenCrop] = React.useState(false)
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [errors, setErrors] = React.useState<any>({})
  const [expandInformation, setExpandInformation] = React.useState(false)
  const [expandRole, setExpandRole] = React.useState(true)
  const [errorTimeZone, setErrorTimeZone] = React.useState(false);

  const {getRootProps, getInputProps} = useDropzone({
    maxFiles: 1,
    maxSize: 5242880,
    accept: 'image/jpeg, image/jpg, image/png',
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {
      if (!isEmpty(fileRejections)) {
        const file = fileRejections[0]
        file.errors.forEach(error => {
          notificationStore.setNotification({
            severity: 'error',
            message: error.code === "file-too-large" ?
                      t(`error.${camelCase(error.code)}`, {maxSize: '5 MB'}) :
                      error.message
          })
        })

        return
      }

      const profile = acceptedFiles[0]
      Object.assign(profile, {
        preview: URL.createObjectURL(profile)
      })

      setFileProfile(profile)
      setOpenCrop(true)
    }
  })

  const validations = () => {
    setErrors({})
    let validationErrors = {}

    if (!name) {
      assign(validationErrors, {
        "name": [{ message: t('profile.error.fieldIsEmpty') }]
      })
    }

    const validEmail = validateEmail(email)
    if (!validEmail) {
      assign(validationErrors, {
        "email": [{ message: t('profile.error.emailIsInvalid') }]
      })
    }

    if (!timeZone) {
      assign(validationErrors, {
        "timeZone": [{ message: t('profile.error.timeZoneInvalid') }]
      })
      setErrorTimeZone(true)
    }
    if (isEmpty(userRole)) {
      assign(validationErrors, {
        "additionalInformation": [{ message: t('profile.error.additionalInformation') }]
      })
    }
    if (isNull(isQgrader)) {
      assign(validationErrors, {
        "grader": [{ message: t('profile.error.additionalInformation') }]
      })
    }
    if (isNull(isProducer)) {
      assign(validationErrors, {
        "producer": [{ message: t('profile.error.additionalInformation') }]
      })
    }

    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors)
      return false
    }

    return true
  }

  const onCompleteCrop = async (properties) => {
    const {previewUrl, blob} = properties
    const avatar = new File([blob], `${name}-profile.jpeg`, {type: "image/jpg"})
    setUserValue('avatar', previewUrl)

    globalAction(notificationStore, {
      action: async () => await uploadAvatar(avatar),
      afterAction: () => setOpenCrop(false)
    })
  }

  const onRemoveAvatar = async () => {
    globalAction(notificationStore, {
      action: removeAvatar,
      afterAction: () => setOpenConfirm(false)
    })
  }

  const onUpdateProfile = async () => {
    if (!validations()) return

    globalAction(notificationStore, {
      action: saveProfile,
      afterAction: () => {
        if (isCompanyAdmin && (isEmpty(companyType) || isEmpty(countryCode) || isEmpty(companyName))) {
          setModalValue('settingTab', "company")
        }
      }
    })
  }

  return (
    <TabPanel value="profile">
      <Box sx={{...verticalCentered, mb: 1}}>
        <Avatar name={name} image={avatar} sx={{width: 70, height: 70}} />
        <Box sx={{ml: 4}}>
          <Stack direction="row" spacing={2} sx={{mb: 1}}>
            <Box {...getRootProps()}>
              <input {...getInputProps()} />
              <Button
                color='primary'
                className='btn-text'
              >
                {t('profile.uploadYourPhoto')}
              </Button>
            </Box>
            {avatar &&
              <>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Button
                  color='error'
                  className='btn-text'
                  onClick={() => setOpenConfirm(true)}
                >
                  {t('profile.removePhoto')}
                </Button>
              </>
            }
          </Stack>
          <Typography>{t('profile.photoUploadDescription')}</Typography>
          <Typography variant='caption' paragraph>{t('profile.uploadImageTypesDescription', {max: 5})}</Typography>
        </Box>
      </Box>

      <Grid container spacing={2} sx={{mb: 3}}>
        <Grid item xs={12} sm={6}>
          <FormInput
            label={t('profile.fullName')}
            formControlProps={{margin: 'none'}}
            textFieldProps={{
              placeholder: t('profile.name'),
              value: name,
              onChange: (event) => setUserValue('name', event.target.value),
              error: !name || (!name && has(errors, 'name')),
              helperText: !name && pointerMessages('name', errors)
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInput
            label={t('profile.email')}
            formControlProps={{margin: 'none'}}
            textFieldProps={{
              placeholder: 'example@email.com',
              value: email,
              onChange: (event) => setUserValue('email', event.target.value),
              error: !validateEmail(email) || (!validateEmail(email) && has(errors, 'email')),
              helperText: !validateEmail(email) && pointerMessages('email', errors)
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInput label={t('profile.languagePreference')} formControlProps={{margin: 'none'}}>
            <Select
              fullWidth
              size='small'
              value={languagePreference}
              onChange={event => setUserValue('languagePreference', event.target.value)}
            >
              {
                Object.keys(LANGUANGES).map((key: string) => {
                  return <MenuItem value={key} key={key}>{LANGUANGES[key]}</MenuItem>
                })
              }
            </Select>
          </FormInput>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInput label={t('profile.timeZone')} formControlProps={{margin: 'none'}}>
            <Select
              fullWidth
              size='small'
              placeholder={t('profile.selectTimeZone')}
              value={timeZone}
              onChange={event => {
                setUserValue('timeZone', event.target.value)
                setErrorTimeZone(false)
              }}
              error={errorTimeZone}
            >
              {timezones.map((tz) => <MenuItem key={tz.value} value={tz.value}>{tz.label}</MenuItem>)}
            </Select>
            {errorTimeZone && <FormHelperText sx={{color: '#DE8282', fontStyle: 'italic'}}>{t('profile.error.timeZoneInvalid')}</FormHelperText>}
          </FormInput>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.expandWrapper}
          onClick={() => setExpandInformation(!expandInformation)}
          sx={{ justifyContent: 'center' }}
        >
          <Typography variant='subtitle1'>{t('profile.additionalInformation')}</Typography>
          {expandInformation ? <ExpandLess /> : <ExpandMore />}

        </Grid>

        <Collapse in={expandInformation} timeout="auto" unmountOnExit sx={{pl: 2}}>
          <Grid item xs={12} className={classes.expandWrapper} onClick={() => setExpandRole(!expandRole)}>
            <Typography variant='subtitle1'>{t('profile.chooseRole')}</Typography>
            {expandRole ? <ExpandLess /> : <ExpandMore />}
          </Grid>
          <Grid item xs={12} sx={{mb: 2}}>
            <Collapse in={expandRole} timeout="auto" unmountOnExit>
              <Grid container spacing={1}>
                {roles.map(role =>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      key={role.key}
                      value={role.key}
                      control={<Radio color="primary" />}
                      label={toString(t(role.label))}
                      labelPlacement="end"
                      checked={role.key === userRole}
                      onChange={e => setUserValue('role', (e.target as HTMLInputElement).value)}
                    />
                  </Grid>
                )}
              </Grid>
            </Collapse>
          </Grid>
          <Grid item xs={12} sm={6} sx={{mb: 2}}>
            <FormInput label={t('profile.areYouQgrader')} formControlProps={{margin: 'none'}}>
              <Grid container spacing={1}>
                {[true, false].map(bool =>
                  <Grid item xs={6}>
                    <FormControlLabel
                      key={`option-${bool}`}
                      value={bool}
                      control={<Radio color="primary" />}
                      label={toString(t(`options.boolean.${bool}`))}
                      labelPlacement="end"
                      checked={bool === isQgrader}
                      onChange={e => setUserValue('isQgrader', str2bool((e.target as HTMLInputElement).value))}
                    />
                  </Grid>
                )}
              </Grid>
              {errorStatus('isQgrader', isQgrader, errors) &&
                <Typography variant='caption' color='error'>{errorMessage('isQgrader', isQgrader, errors)}</Typography>
              }
            </FormInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput label={t('profile.areYouProducer')} formControlProps={{margin: 'none'}}>
              <Grid container spacing={1}>
                {[true, false].map(bool =>
                  <Grid item xs={6}>
                    <FormControlLabel
                      key={`option-${bool}`}
                      value={bool}
                      control={<Radio color="primary" />}
                      label={toString(t(`options.boolean.${bool}`))}
                      labelPlacement="end"
                      checked={bool === isProducer}
                      onChange={e => setUserValue('isProducer', str2bool((e.target as HTMLInputElement).value))}
                    />
                  </Grid>
                )}
              </Grid>
              {errorStatus('isProducer', isProducer, errors) &&
                <Typography variant='caption' color='error'>{errorMessage('isProducer', isProducer, errors)}</Typography>
              }
            </FormInput>
          </Grid>
        </Collapse>
        {((isNull(isQgrader) || isNull(isProducer) || isEmpty(userRole))) &&
          <FormHelperText sx={{color: '#DE8282', fontStyle: 'italic', ml: 2}}>
            {t('profile.error.additionalInformation')}
          </FormHelperText>
        }
      </Grid>

      <LoadingButton
        fullWidth
        size='large'
        variant="contained"
        onClick={onUpdateProfile}
      >
        {t('profile.saveChanges')}
      </LoadingButton>

      {fileProfile &&
        <ModalCropImage
          open={openCrop}
          onClose={() => setOpenCrop(false)}
          file={fileProfile}
          onComplete={(properties) => onCompleteCrop(properties)}
        />
      }

      <ModalConfirm
        open={openConfirm}
        confirm={t('common.delete')}
        cancel={t('common.cancel')}
        title={t('profile.removePhoto')}
        description={t('profile.deletePhotoDescription')}
        onClose={() => setOpenConfirm(false)}
        onConfirm={onRemoveAvatar}
      />
    </TabPanel>
  )
})
