/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-03 16:43:21
 * @modify date 2022-02-04 19:37:46
 */

import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors, verticalCentered } from 'assets'
  
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      paddingBottom: theme.spacing(0.5),
      borderBottom: `1px solid ${colors.border.primary}`
    },
    leftContent: {
      ...verticalCentered
    },
    rightContent: {
      ...verticalCentered,
      justifyContent: 'space-between'
    }
  })
)