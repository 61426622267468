/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-20 21:46:24
 * @modify date 2022-04-20 21:46:24
 */

import { types } from 'mobx-state-tree'

export const Descriptor = types.model({
  name: types.maybeNull(types.string),
  score: types.maybeNull(types.union(types.number, types.string)),
  descriptor: types.maybeNull(types.string),  
})