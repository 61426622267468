/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-07 19:28:17
 * @modify date 2022-03-07 19:28:17
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors, verticalCentered } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(2),
      borderBottom: `1px solid ${colors.border.primary}`,
    },
    score: {
      ...verticalCentered,
      justifyContent: 'space-between'
    },
    descriptorWrapper: {
      ...verticalCentered,
      flexWrap: 'wrap'
    },
    labelColor: {
      marginRight: theme.spacing(1),
      '&.selected-score-target': {
        color: colors.secondary.main
      }
    },
    scoreDescriptorColor: {
      marginRight: theme.spacing(1),
      color: colors.primary.main,
      '&.selected-score-target': {
        color: colors.secondary.main
      }
    },
    cup: {
      width: 24,
      height: 24,
      cursor: 'pointer',
      border: `1px solid ${colors.magnolia}`,
      backgroundColor: 'transparent',
      marginRight: theme.spacing(1),
      '&.selected': {
        border: 'none',
        backgroundColor: colors.primary.main
      },
      '&.selected-score-target': {
        border: 'none',
        backgroundColor: colors.secondary.main
      }
    },
    cupIcon: {
      fill: colors.magnolia,
      height: '77%',
      '&.selected': {
        fill: 'white'
      }
    },
    boxScore: {
      padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      minWidth: 40,
      textAlign: 'center',
      color: colors.primary.main,
    },
    input: {
      fontWeight: 600,
      color: colors.primary.main
    },
    roastLevelIcon: {
      width: 24,
      height: 24,
      '&.color-0': { fill: '#A5715B' },
      '&.color-25': { fill: '#8C4E34' },
      '&.color-50': { fill: '#8C3B19' },
      '&.color-75': { fill: '#652C14' },
      '&.color-100': { fill: '#451400' },
    },
  })
)
