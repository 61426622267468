import { assignIn } from 'lodash'
import { wheelLogo } from 'assets/images'

declare const window: any

function installResizeHandlerFor(visualization, deferUpdateByMillis) {
  var fn = function() {
    var id = visualization.get("id"), element
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (id && (element = document.getElementById(id))) {
      visualization.resize()
    }
  }

  // Call fn at most once within a single minInterval.
  function defer(minInterval, fn) {
    var last
    var deferId
    return function() {
      var now = new Date().getTime()

      if (deferId) {
        window.clearTimeout(deferId)
        deferId = undefined
      }

      if (!last || last + minInterval <= now) {
        last = now
        fn()
      } else {
        // eslint-disable-next-line no-caller
        deferId = window.setTimeout(arguments.callee, Math.min(1, minInterval / 5))
      }
    }
  }

  if (undefined === deferUpdateByMillis) {
    deferUpdateByMillis = 500
  }

  window.addEventListener("resize", defer(deferUpdateByMillis, fn))
  window.addEventListener("orientationchange", defer(deferUpdateByMillis, fn))
}

export const flavorWheel = (dataGroups, logo = '', elementId = "tastify-wheel", languange = 'en', isFull = false, onSelectionChange = (descriptors) => {}) => {
  // We respin until the visualization container has non-zero area (there are race 
  // conditions on Chrome which permit that) and the visualization class is loaded.
  const container = document.getElementById(elementId)
  if (!container) return

  if (container.clientWidth <= 0 || container.clientHeight <= 0 || !window["CarrotSearchCircles"]) {
    setTimeout(() => flavorWheel(dataGroups, logo, elementId, languange, isFull, onSelectionChange), 500)
    return
  }

  // Inititate circles
  const circles = new window.CarrotSearchCircles({
    id: elementId
  })

  // Starts Color customization
  const customAttributes = (opts, params, vars) => {
    vars.groupColor = params.group.gcolor || params.group.color
    vars.labelColor = "auto"
  }

  // Starts Label customization
  const customLabel = (opts, params, vars) => {
    vars.labelText = languange === 'en' ? params.group.label : params.group[`label_${languange}`]
  }

  // Setting attribution
  const attribution_size = isFull ? 0.25 : 0.275
  const attribution_y = isFull ? "39%" : "50%"

  const basicSettings = (groups) => {
    return {
      dataObject: { groups: groups }, // Dataset
      pixelRatio: 3,
      backgroundColor: "rgba(0,0,0,0)", // Background color with alpha
      captureMouseEvents: false,
      rolloutTime: 2,
      rolloutAnimation: "tumbler",
      visibleGroupCount: 200,
      noTexturingCurvature: 0.1
    }
  }

  const groupsSettings = (customAttributes) => {
    return {
      groupColorDecorator: customAttributes,
      groupLabelDecorator: customLabel,
      groupHoverOutlineColor: "#000",
      groupHoverColor: "rgba(0,0,0,0.1)",
      groupSelectionColor: "rgba(237,237,237,0.1)",
      groupSelectionOutlineWidth: 2,
      groupSelectionOutlineColor: "#89bd48",
      groupFontFamily: "HeroNew, Open Sans, Arial, sans-serif",
      groupMaxFontSize: 25
    }
  }

  const attributionSettings = (attribution_y, attribution_size) => {
    const companyLogo = logo || wheelLogo

    return {
      attributionLogo: companyLogo,
      attributionPositionX: "50%",
      attributionPositionY: attribution_y,
      attributionStayOnTime: 0,
      attributionSize: function(attrs) {   
        const { w, h } = attrs.layout   
        
        if (w > h) {
          attrs.imageHeight = h * attribution_size * 0.95
          attrs.imageWidth  = attrs.imageHeight * 0.95
        } else {
          attrs.imageWidth  = w * attribution_size * 0.95
          attrs.imageHeight = attrs.imageWidth * 0.95
        }
      }
    }
  }

  const responseSettings = () => {
    return {
      onBeforeZoom:       () => { return false },
      onBeforeSelection:  () => { return false },
      onGroupDoubleClick: (info) => {
        const state = info.group.selected ? true : false
        circles.set("selection", {
          groups: [info.group.id],
          selected: !state
        })
        onSelectionChange(circles.get("selection").groups)
      }
    }
  }

  const flavorWheelSettings = assignIn(
    {},
    basicSettings(dataGroups),
    groupsSettings(customAttributes),
    attributionSettings(attribution_y, attribution_size),
    responseSettings()
  )

  circles.set(flavorWheelSettings)
  circles.set("selection", {all: true, selected: false})

  //titles
  if (isFull) {
    circles.set({
      titleBar: "inscribed",
      titleBarTextColor: "#000",
      titleBarMaxFontSize: 30,
    })
  }

  installResizeHandlerFor(circles, 0)
}