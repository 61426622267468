/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-18 00:12:38
 * @modify date 2022-02-05 12:39:34
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors } from 'assets'
  
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      border: 'none',
      height: '100%',
      [theme.breakpoints.up('md')]: {
        border: `1px solid ${colors.border.primary}`,
      }
    },
    container: {
      padding: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
        '&.module': {
          position: 'relative',
          alignItems: 'start',
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2)
        },
      }
    },
    item: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
        '&.module': {
          marginBottom: theme.spacing(2)
        }
      }
    },
    menuList: {
      right: 10,
      position: 'absolute',
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        textAlign: 'right',
        '&.module': {
          right: 10,
          position: 'absolute',
        }
      }
    },
    checkbox: {
      [theme.breakpoints.up('md')]: {
        '&.module .MuiFormControlLabel-label': {
          display: 'block'
        },

        '& .MuiFormControlLabel-label': {
          display: 'none',
          fontSize: '0.875rem'
        }
      }
    },
    iconButton: {
      margin: 0,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark
      },
      [theme.breakpoints.up('md')]: {
        transform: 'rotate(90deg)',
        '&.module': {
          transform: 'rotate(0deg)'
        }
      }
    },
    latestActivity: {
      marginBottom: 0,
      textAlign: 'right',
      [theme.breakpoints.up('md')]: {
        '&.list': {
          textAlign: 'left'
        }
      }
    }
  })
)