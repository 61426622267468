/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-06 21:23:13
 * @modify date 2022-02-06 21:23:13
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
      fontSize: '0.875rem',
      border: 'none',
      borderRadius: 0,
      wordBreak: 'break-all',
      padding: `${theme.spacing(1)} ${theme.spacing(2.5)}`,
      borderBottom: `1px solid ${colors.border.primary}`,
      [theme.breakpoints.up('md')]: {
        borderRadius: 5,
        border: `1px solid ${colors.border.primary}`,
        padding: 0,
        paddingTop: 6,
        paddingBottom: 6,
      }
    },
    iconButton: {
      padding: theme.spacing(1),
      '& svg': {
        width: 20,
        height: 20
      }
    },
    iconMore: {
      margin: 0,
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark
      }
    },
    gridHeader: {
      fontWeight: 600,
      wordBreak: 'break-word',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    gridItem: {
      wordBreak: 'break-word',
      [theme.breakpoints.up('md')]: {
        textAlign: 'center',
        '&.left': {
          textAlign: 'left'
        }
      }
    },
    buttonWrapper: {
      position: 'absolute',
      right: theme.spacing(3),
      top: 0,
      paddingTop: '0 !important',
      [theme.breakpoints.up('md')]: {
        top: 'unset',
        display: 'none'
      }
    },
    tooltipHalfWidth: {
      width: '50%'
    },
    cupNowButton: {
      padding: '6px 8px',
      fontSize: '0.75 rem'
    }
  })
)
