/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-05-30 23:02:13
 * @modify date 2022-05-30 23:02:13
 */

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import ReactHtmlParser from 'react-html-parser'
import { Box, Typography, SvgIcon, Button } from '@mui/material'

import { centered } from 'assets'
import { people, Logo } from 'assets/images'
import { ModalWrapper, SocialMedia } from 'components'
import { colors } from 'assets'

import { ThankYouProps } from './thank-you.props'

export const ThankYou: React.FC<ThankYouProps> = observer((props: ThankYouProps) => {
  const { t } = useTranslation()
  const { email, open, close } = props

  return (
    <ModalWrapper
      open={open}
      containerProps={{
        sx: {
          minWidth: '100%',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Box sx={{...centered, flexDirection: 'column', flex: 1}}>
        <img src={people} alt='people' />
        <Box sx={{flex: 1}} textAlign='center'>
          <Typography variant='h2'>{t('signUp.success.thankYouForSignUp')}</Typography>
          <Typography variant='body1' fontSize={20}>
            {ReactHtmlParser(t('signUp.success.confirmationEmailSignUpDescription', {email}))}
          </Typography>

          <Button
            variant='contained'
            className='wide'
            sx={{mt: 5, mb: 3}}
            onClick={close}
          >
            {t('signUp.signIn')}
          </Button>
          <Box>
            <SvgIcon component={Logo} inheritViewBox sx={{height: 50, width: 250, mb: 2}}/>
            <Typography variant='h4' sx={{color: colors.secondary.main}} paragraph>
              {t('signUp.followUs')}
            </Typography>
            <SocialMedia />
          </Box>
        </Box>
      </Box>
    </ModalWrapper>
  )
})