/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import {Instance, SnapshotOut, types, flow, applySnapshot, getSnapshot} from "mobx-state-tree"
import {ceil, find, omit, toNumber} from "lodash"

import { ApiResult } from "services/api"
import {
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"
import { defectProps } from "models/defect"
import {flattenGreenGrading} from "utils"
import {GreenGradingTypeModel} from "./green-grading-types"
import {GreenGradingModel, preProcessorGreenGrading} from "../green-grading";

const DATA_PER_PAGE = 10

/**
 * Model description here for TypeScript hints.
 */
export const GreenGradingStoreModel = types
  .model("GreenGradingStore")
  .props({
    greenGradingTypes: types.optional(types.array(GreenGradingTypeModel), []),
    selectedGreenGradingType: types.maybeNull(types.reference(GreenGradingTypeModel)),
    greenGradingSettings: types.optional(types.array(types.model(defectProps)), []),
    greenGradings: types.optional(types.array(GreenGradingModel), []),
    selectedGreenGrading: types.optional(GreenGradingModel, {}),
    page: types.optional(types.number, 1),
    totalPage: types.optional(types.number, 0),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .actions(self => ({
    setSelectedGreenGradingType: (id: string) => {
      const selectedGreenGradingType = find(self.greenGradingTypes, { id })
      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        selectedGreenGradingType: selectedGreenGradingType
      })
    },
    getGreenGradingTypes: flow(function * () {
      try {
        const res: ApiResult = yield self.environment.greenGradingTypeApi.all()

        if (res.kind === "ok") {
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            greenGradingTypes: res.data
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    getGreenGradingSettings: flow(function * (id) {
      try {
        const payload = { green_grading_type_id: id }
        const res: ApiResult = yield self.environment.greenGradingSettingApi.all(payload)

        if (res.kind === "ok") {
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            greenGradingSettings: flattenGreenGrading(res.data).map(item => {
              return {
                ...item,
                id: item.id.toString(),
              }
            })
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    getGreenGradings: flow(function * (id) {
      try {
        const payload: any = {
          "size": DATA_PER_PAGE,
          "page[number]": self.page,
        }
        const additionalPath = 'green_gradings'
        const res: ApiResult = yield self.environment.masterSampleApi.find(id, additionalPath, payload)

        if (res.kind === "ok") {
          const totalPage = ceil(res.meta.pagination.records / DATA_PER_PAGE)
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            totalPage,
            greenGradings: res.data.map(item => {
              return preProcessorGreenGrading(item)
            }),
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    newGreenGrading(greenGradingTypeId) {
      const { greenGradingSettings } = self.rootStore.greenGradingStore

      const setDefects = greenGradingSettings.map(setting => {
        return { ...setting, count: 0 }
      })

      const greenGrading = preProcessorGreenGrading({ greenGradingTypeId: toNumber(greenGradingTypeId), defects: setDefects })
      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        selectedGreenGrading: greenGrading,
      })
    },
    addGreenGrading: flow(function * (defects, sampleId) {
      try {
        if (!self.selectedGreenGrading) return

        let omits = ['createdAt', 'author', 'defects']

        const payload: any = {
          ...omit(self.selectedGreenGrading, omits),
          sampleId,
          defects: defects.map(defect => ({ ...defect, count: toNumber(defect.count)})),
          size_14: toNumber(self.selectedGreenGrading.size14),
          size_15: toNumber(self.selectedGreenGrading.size15),
          size_16: toNumber(self.selectedGreenGrading.size16),
          size_17: toNumber(self.selectedGreenGrading.size17),
          size_18: toNumber(self.selectedGreenGrading.size18),
          size_19: toNumber(self.selectedGreenGrading.size19),
        }

        const res: ApiResult = yield self.environment.greenGradingApi.save(payload)

        if (res.kind === "ok") {

        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
  }))
  .views(self => ({
    get greenGradingTypeWeight() {
      if (!self.selectedGreenGradingType) return 0

      switch (self.selectedGreenGradingType.unit) {
        case 'kg':
          return self.selectedGreenGradingType.sampleSize * 1000
        case 'lbs':
          return self.selectedGreenGradingType.sampleSize * 453.592
        default:
          return self.selectedGreenGradingType.sampleSize
      }
    }
  }))

type GreenGradingStoreType = Instance<typeof GreenGradingStoreModel>
export interface GreenGradingStore extends GreenGradingStoreType {}
type GreenGradingStoreSnapshotType = SnapshotOut<typeof GreenGradingStoreModel>
export interface GreenGradingStoreSnapshot extends GreenGradingStoreSnapshotType {}
