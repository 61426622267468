/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */

import { Instance, SnapshotOut, types } from "mobx-state-tree"

import {
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"

import { cuppingSessionFormProps } from './cupping-session-form-props'
import { withCuppingSessionFormViews } from './cupping-session-form-view'
import { withCuppingSessionFormActions } from './cupping-session-form-action'

/**
 * Model description here for TypeScript hints.
 */
export const CuppingSessionFormStoreModel = types
  .model("CuppingSessionFormStore")
  .props(cuppingSessionFormProps)
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withCuppingSessionFormViews)
  .extend(withCuppingSessionFormActions)

type CuppingSessionFormStoreType = Instance<typeof CuppingSessionFormStoreModel>
export interface CuppingSessionFormStore extends CuppingSessionFormStoreType {}
type CuppingSessionFormStoreSnapshotType = SnapshotOut<typeof CuppingSessionFormStoreModel>
export interface CuppingSessionFormStoreSnapshot extends CuppingSessionFormStoreSnapshotType {}
