/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-20 18:39:46
 * @modify date 2022-01-20 18:39:46
 * @desc A page to show info using image and title
 */
import React from 'react'

import { Box, Typography } from '@mui/material'

import { useStyles } from './text-inline.styles'
import { TextInlineProps } from './text-inline.props'

export const TextInline: React.FC<TextInlineProps> = (props: TextInlineProps) => {
  const classes = useStyles()
  const { title, description } = props

  return (
    <Box className={classes.wrapper}>
      <Typography variant="body2" sx={{wordBreak: 'break-word'}}>
        {title}
      </Typography>
      {description && 
        <Typography variant="caption" sx={{pl: 0.5, whiteSpace: 'nowrap'}}>
          {description}
        </Typography>
      }
    </Box>
  )
}