import { Api } from "./api"

export class CuppingSessionApi extends Api {
  constructor() {
    const type = 'cupping_sessions'
    super(type, [
      'name', 'remote', 'location', 'description', 'sampleIdStructure', 'cuppingProtocol',
      'descriptorSet', 'numberOfSamples', 'blind', 'scoreTarget', 'hideScoreTarget', 'invitedCuppersIds',
      'guestInvitationsAttributes', 'startDate', 'startTime','endDate', 'endTime','isPublic', 'isPaused',
      'publicCuppingRequestInformationAttributes', 'sampleIds'
    ])
  }
}
