/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import { Instance, SnapshotOut, types } from "mobx-state-tree"

import {
  withGeneralError, withReset, withRootStore, withEnvironment, withSetValue
} from "models/extensions"
import {ShipmentOrderFormModel, shipmentOrderFormPreProcessor} from "models/shipment-order-form";

import {sampleProps} from "./sample-shipment.props"
import {withSampleShipmentActions} from "./sample-shipment-action";
import {withSampleShipmentViews} from "./sample-shipment.view";

/**
 * Model description here for TypeScript hints.
 */
export const SampleShipmentStoreModel = types
  .model("SampleShipmentStore")
  .props({
    samplesToBeShipment: types.optional(
      types.array(types.model(sampleProps)),
      []
    ),
    shipmentForm: types.optional(ShipmentOrderFormModel, shipmentOrderFormPreProcessor({})),
  })
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withEnvironment)
  .extend(withSampleShipmentActions)
  .extend(withSampleShipmentViews)

type SampleShipmentStoreType = Instance<typeof SampleShipmentStoreModel>
export interface SampleShipmentStore extends SampleShipmentStoreType {}
type SampleShipmentStoreSnapshotType = SnapshotOut<typeof SampleShipmentStoreModel>
export interface SampleShipmentStoreSnapshot extends SampleShipmentStoreSnapshotType {}
