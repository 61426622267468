/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-09 03:00:58
 * @modify date 2022-04-18 01:46:09
 */

import React, {useCallback, useState} from 'react'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {startCase, isEmpty, toString, clone, debounce, camelCase, isNumber} from 'lodash'
import { TabPanel } from '@mui/lab'
import { ExpandMore, ExpandLess, DeleteOutline, ModeEdit, Delete as DeleteIcon, Warning as WarningIcon } from '@mui/icons-material'
import {
  Grid,
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  SvgIcon,
  Stack,
  Avatar,
  Collapse,
} from '@mui/material'
import ReactHtmlParser from 'react-html-parser'

import { useStores } from 'models'
import { DEBOUNCE_TIME } from "config/env"
import { findRoute } from 'constants/routes'
import { globalAction, alphabet } from 'utils'
import { FormInput, ModalWrapper, ModalConfirm } from 'components'
import { IconEdit, IconSampleInfo, IconGreenGrading } from 'assets/images'

import { SampleForm } from "../sample-form/sample-form"
import { useStyles } from './sample-item.styles'
import {AdvanceSearch} from "../advance-search/advance-search";


export const SampleItem: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const cuppingScoreLink = findRoute('sampleScore')
  const {
    companyStore: { hasGreenGradingAccess, showCoe, showSca, showSucafinaTasting },
    cuppingSessionStore: { startCupping, selectedCuppingSession: { canBeStarted, uniqueToken, canNotCupNow, canNotBeEdited }},
    sampleStore: { samples, saveSample, removeSample, selectedSample, selectedSample: {
      id, sampleId, name, greenGradings, setGreenGrading, removeGreenGrading,
      setSample, cloneSample, resetSample, setValue: setSampleValue
    }},
    masterSampleStore: { getSearchMasterSamples, setValue: setMasterSampleValue },
    modalStore: { sampleId: openSampleId, setValue: setModalValue },
    notificationStore
  } = useStores()

  const [defaultSampleId, setDefaultSampleId] = useState('')
  const [expanded, setExpanded] = useState<string | false>(false)
  const [openConfirmSample, setOpenConfirmSample] = useState(false)
  const [openConfirmGreenGrading, setOpenConfirmGreenGrading] = useState(false)
  const [cloneSelectedSample] = useState(cloneSample())
  const [errors, setErrors] = useState<any>([])
  const [errorSampleId, setErrorSampleId] = useState(false)
  const [openMasterSample, setOpenMasterSample] = useState(false)
  const [loadingMasterSample, setLoadingMasterSample] = useState(false)
  const [openAdvanceSearch, setOpenAdvanceSearch] = React.useState(false)
  const [isMobile, setIsMobile] = React.useState(false)
  const [temporaryAcidityAndSaltDescriptors, setTemporaryAcidityAndSaltDescriptors] = React.useState<any[]>([])
  const [temporaryBodyAndMouthfeelDescriptors, setTemporaryBodyAndMouthfeelDescriptors] = React.useState<any[]>([])
  const [temporaryFragranceDescriptors, setTemporaryFragranceDescriptors] = React.useState<any[]>([])
  const [temporaryFlavorAndAftertasteDescriptors, setTemporaryFlavorAndAftertasteDescriptors] = React.useState<any[]>([])
  const [temporaryDefectsDescriptors, setTemporaryDefectsDescriptors] = React.useState<any[]>([])
  const [temporaryListSearch, setTemporaryListSearch] = React.useState<any[]>([])
  const [searchAdvanceData,setSearchAdvanceData] = React.useState<any[]>([])
  const [isLoading, setIsLoading] = React.useState(false)

  const validation = (isDiscard = false) => {
    if (!name && !isDiscard) {
      setErrors({
        "name": [{ message: t('sample.error.sampleNameRequired') }]
      })

      return false
    }

    return true
  }

  const onSaveSample = (isComplete = false) => {
    setErrors({})
    if (!isComplete && !validation()) return

    const actions: Array<any> = samples.map(sample => {
      return saveSample(sample.id)
    }) || []

    globalAction(notificationStore, {
      complexAction: true,
      action: async () => {
        await Promise.all(actions)
        notificationStore.setNotification({
          severity: 'success',
          message: t('sample.success.successfullyUpdatedSample')
        })

        if (isComplete) navigate(cuppingScoreLink)
      }
    })
  }

  const onComplete = async () => {
    if (canBeStarted) await startCupping(uniqueToken)

    onSaveSample(true)
  }

  const onRemoveSample = () => {
    globalAction(notificationStore, {
      complexAction: true,
      action: async () => {
        await removeSample(id)
        notificationStore.setNotification({
          severity: 'success',
          message: t('sample.success.successfullyRemovedSample')
        })
      }
    })
  }

  const showGreenGradingOptions = () => {
    setModalValue('greenGradingOptions', true)
  }

  const onCloseSampleId = () => {
    setSampleValue('sampleId', defaultSampleId)
    setModalValue('sampleId', false)
  }

  const renderSampleIdModal = (
    <ModalWrapper
      open={openSampleId}
      onClose={onCloseSampleId}
      maxWidth='xs'
    >
      <FormInput
        label={t('sample.sampleId')}
        textFieldProps={{
          value: sampleId,
          onChange: (e) => setSampleValue('sampleId', e.target.value),
          inputProps: {maxLength: 10}
        }}
        formControlProps={{error: errorSampleId}}
        helperText={t('sample.error.useDifferentSampleId')}
      />

      <Box sx={{mt: 2, textAlign: 'right'}}>
        <Button
          variant='outlined'
          sx={{ml: 2}}
          onClick={onCloseSampleId}
        >
          {t('common.cancel')}
        </Button>
        <Button
          variant='contained'
          sx={{ml: 2}}
          onClick={() => {
            setModalValue('sampleId', false)
            setDefaultSampleId(clone(sampleId))
          }}
          disabled={errorSampleId}
        >
          {t('common.save')}
        </Button>
      </Box>
    </ModalWrapper>
  )

  const renderSampleButtons = (
    <Stack direction='row' spacing={2}>
      <Button
        size='small'
        variant='contained'
        onClick={() => {
          setOpenAdvanceSearch(!openAdvanceSearch)
        }}
        sx={{ml: 2, flex: 1}}
        className={classes.buttonRounded}
      >
        {t('sample.masterSample.exportSampleInformation')}
      </Button>
      {hasGreenGradingAccess && (
        <Button
          variant="contained"
          size="small"
          startIcon={
            <Avatar className={classes.iconRounded}>
              <IconGreenGrading />
            </Avatar>
          }
          onClick={showGreenGradingOptions}
          className={classes.buttonRounded}
        >
          {t("greenGrading.addGreenGrading")}
        </Button>
      )}
      {!canNotBeEdited && (
        <Button
          variant="contained"
          size="small"
          startIcon={
            <Avatar className={classes.iconRounded}>
              <ModeEdit />
            </Avatar>
          }
          onClick={() => setModalValue("sampleId", true)}
          className={classes.buttonRounded}
        >
          {t("sample.button.editSampleId")}
        </Button>
      )}
      {samples.length > 1 &&
        <Button
          variant="contained"
          size="small"
          startIcon={
            <Avatar className={classes.iconRounded}>
              <DeleteIcon />
            </Avatar>
          }
          onClick={() => setOpenConfirmSample(true)}
          className={classes.buttonRounded}
        >
          {t("sample.button.deleteSample")}
        </Button>
      }
    </Stack>
  )

  const renderSaveButtons = (
    <Stack direction='row' spacing={2} className={classes.buttonWrapper}>
      <Button
        variant='contained'
        className='wide'
        onClick={() => onSaveSample()}
      >
        {t('common.save')}
      </Button>
      {!canNotCupNow &&
        <Button
          variant='contained'
          className='wide'
          onClick={onComplete}
        >
          {t('cuppingSession.cupNow')}
        </Button>
      }
      <Button
        variant='outlined'
        className='wide'
        onClick={() => {
          if (!validation(true)) return
          resetSample(cloneSelectedSample)
        }}
      >
        {t('common.discard')}
      </Button>
    </Stack>
  )

  const handleExpand = (panel) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const renderGreenGradingItem = (label: string, value: string) => {
    return (
      <Grid item xs={12}>
        <Grid container className={classes.greenGradingItem}>
          <Grid item xs={6}>
            <Typography variant='subtitle2'>{label}</Typography>
          </Grid>
          <Grid item xs={6} sx={{wordBreak: 'break-word'}}>
            <Typography variant='subtitle2' color='primary'>{value}</Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const setSampleFromMaster = (sample) => {
    if (!sample) return

    setMasterSampleValue('searchQuery', sample.name)
    setSample(sample)
    setSampleValue('masterSampleName', sample.name)
  }

  const showGreenGrading = (greenGradingId: number) => {
    setGreenGrading(greenGradingId)
    setModalValue('greenGrading', true)
    setExpanded(false)
  }

  const confirmDeleteGreenGrading = (greenGradingId: number) => {
    setOpenConfirmGreenGrading(true)
    setGreenGrading(greenGradingId)
  }

  const onRemoveGreenGrading = async () => {
    setOpenConfirmGreenGrading(false)
    setExpanded(false)

    globalAction(notificationStore, {
      action: removeGreenGrading,
      afterAction: () => {
        notificationStore.setNotification({
          severity: 'success',
          message: t('greenGrading.success.successfullyDeletedGreenGrading')
        })
      }
    })
  }

  const onCheckingSampleId = React.useCallback(
    debounce((id, sid) => {
      for (let s of samples) {
        if (s.id !== id && s.sampleId === sid) {
          setErrorSampleId(true)
          break
        }
      }
    }, 100), []
  )

  const onEnter = (event) => {
    if (event.key === 'Enter') {
      onSaveSample()
      return
    }
  }

  const onSearchMaster = useCallback(
    debounce(async () => {
      try{
        setIsLoading(true)
        setLoadingMasterSample(true)
        await getSearchMasterSamples()
      } finally {
        setLoadingMasterSample(false)
        setIsLoading(false)
      }
    }, DEBOUNCE_TIME), []
  )

  React.useEffect(() => {
    if (!sampleId) {
      setErrorSampleId(true)
      return
    }

    setErrorSampleId(false)
    onCheckingSampleId(id, sampleId)
  }, [sampleId])

  React.useEffect(() => {
    setDefaultSampleId(clone(sampleId))
  }, [id])


  React.useEffect(() => {
    window.addEventListener('keydown', onEnter)
    return () => window.removeEventListener("keydown", onEnter)
  }, [name])

  return (
    <TabPanel value={toString(id)} className={classes.tabPanelWrapper}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className={classes.titleWrapper}>
            <Box className={classes.title}>
              <SvgIcon component={IconSampleInfo} color='primary' inheritViewBox sx={{mr: 1, p: 0.25}} />
              <Typography variant='h6'>{t('sample.sampleInfo')}</Typography>
            </Box>
            {renderSampleButtons}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} className={classes.formRow}>
            <Grid item xs={12} md={isEmpty(greenGradings) ? 12 : 6}>
              {selectedSample &&
                <SampleForm
                  {...selectedSample}
                  errors={errors}
                  setValue={setSampleValue}
                  showCoe={showCoe}
                  showSca={showSca}
                  showSucafinaTasting={showSucafinaTasting}
                />}
            </Grid>

            {!isEmpty(greenGradings) &&
              <Grid item xs={12} md={6}>
                <Box className={classes.titleWrapper}>
                  <SvgIcon component={IconGreenGrading} color='primary' inheritViewBox sx={{mr: 1}} />
                  <Typography variant='h6'>{t('sample.greenGrading')}</Typography>
                </Box>
                <Box sx={{mb: 5}}></Box>

                {greenGradings.map((greenGrading, index) =>
                  <Accordion
                    key={index}
                    elevation={0}
                    expanded={expanded === index}
                    onChange={handleExpand(index)}
                    className={classes.accordion}
                  >
                    <AccordionSummary expandIcon={<ExpandMore color='primary'/>}>
                      <Typography color='primary' variant='subtitle1'>
                        {alphabet(index + 1)}. {greenGrading.author}, {moment(greenGrading.createdAt).format('YYYY-MM-DD HH:mm')}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={1}>
                        {renderGreenGradingItem(t('greenGrading.author'), greenGrading.author)}
                        {renderGreenGradingItem(t('greenGrading.color'), t(`options.color.${camelCase(greenGrading.color)}`))}
                        {renderGreenGradingItem(t('greenGrading.weight'), `${greenGrading.weight} ${t('greenGrading.grams')}`)}
                        {renderGreenGradingItem(t('greenGrading.smell'), startCase(greenGrading.smell))}
                        {Object.keys(greenGrading.totalDefects).map(key => renderGreenGradingItem(t(`greenGrading.total${startCase(key)}Defects`), greenGrading.totalDefects[key]))}
                        {renderGreenGradingItem(t('greenGrading.createdAt'), moment(greenGrading.createdAt).format('YYYY-MM-DD HH:mm'))}
                      </Grid>
                      <Box sx={{mt: 2, textAlign: 'right'}}>
                        {hasGreenGradingAccess &&
                          <>
                            <IconButton
                              size="small"
                              color='primary'
                              className='bg'
                              sx={{ml: 1}}
                              onClick={() => showGreenGrading(greenGrading.id)}
                            >
                              <SvgIcon component={IconEdit} inheritViewBox />
                            </IconButton>
                            <IconButton
                              size="small"
                              color='primary'
                              className='bg'
                              sx={{ml: 1}}
                              onClick={() => confirmDeleteGreenGrading(greenGrading.id)}
                            >
                              <DeleteOutline />
                            </IconButton>
                          </>
                        }
                        <Button
                          size='small'
                          variant='contained'
                          endIcon={<ExpandLess />}
                          onClick={() => setExpanded(false)}
                          sx={{ml: 1}}
                        >
                          {t('greenGrading.details')}
                        </Button>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                )}
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>

      {renderSaveButtons}
      {renderSampleIdModal}

      <ModalConfirm
        open={openConfirmGreenGrading}
        description={t('greenGrading.removeGreenGradingConfirmDescription')}
        onClose={() => setOpenConfirmGreenGrading(false)}
        onConfirm={onRemoveGreenGrading}
      />
      <ModalConfirm
        open={openConfirmSample}
        title={t('sample.removeSampleConfirmTitle')}
        description={ReactHtmlParser(t('sample.removeSampleConfirmDescription', { sampleId }))}
        onClose={() => setOpenConfirmSample(false)}
        onConfirm={onRemoveSample}
        confirm={t('sample.button.continue')}
        icon={
          <Box textAlign="center" width={300}>
            <WarningIcon className={classes.warningIcon} />
          </Box>
        }
      />
      <ModalWrapper
        maxWidth='md'
        open={openAdvanceSearch}
        onClose={() => setOpenAdvanceSearch(false)}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <AdvanceSearch
              isMobile={isMobile}
              expanded={openAdvanceSearch}
              temporaryDefectsDescriptors = {temporaryDefectsDescriptors}
              temporarySearchAdvanceData = {searchAdvanceData}
              temporaryFlavorAndAftertasteDescriptors = {temporaryFlavorAndAftertasteDescriptors}
              temporaryFragranceDescriptors = {temporaryFragranceDescriptors}
              temporaryBodyAndMouthfeelDescriptors = {temporaryBodyAndMouthfeelDescriptors}
              temporaryAcidityAndSaltDescriptors = {temporaryAcidityAndSaltDescriptors}
              temporaryListSearch = {temporaryListSearch}
              isLoading={isLoading}
              addTemporarySearchAdvanceData={(item)=>{
                setSearchAdvanceData(item)
              }}
              addTemporaryDefectsDescriptors={(descriptors) => {
                setTemporaryDefectsDescriptors(descriptors)
              }}
              addTemporaryFlavorAndAftertasteDescriptors={(descriptors) => {
                setTemporaryFlavorAndAftertasteDescriptors(descriptors)
              }}
              addTemporaryFragranceDescriptors={(descriptors) => {
                setTemporaryFragranceDescriptors(descriptors)
              }}
              addTemporaryBodyAndMouthfeelDescriptors={(descriptors) => {
                setTemporaryBodyAndMouthfeelDescriptors(descriptors)
              }}
              addTemporaryAcidityAndSaltDescriptors={(descriptors) => {
                setTemporaryAcidityAndSaltDescriptors(descriptors)
              }}
              addTemporaryListSearch={(data) => {
                setTemporaryListSearch(data)
              }}
              onSearch={() => {
                setMasterSampleValue('page', 1)
                onSearchMaster()
              }}
              onCancel={() => {
                setOpenAdvanceSearch(false)
                window.scrollTo(0, 0)
                setTemporaryDefectsDescriptors([])
                setTemporaryFlavorAndAftertasteDescriptors([])
                setTemporaryFragranceDescriptors([])
                setTemporaryBodyAndMouthfeelDescriptors([])
                setTemporaryAcidityAndSaltDescriptors([])
                setTemporaryListSearch([])
              }}
              onSelect={(sample) => {
                setSampleFromMaster(sample)
              }}
            />
          </Grid>
        </Grid>
      </ModalWrapper>
    </TabPanel>
  )
})
