/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-04 06:08:29
 * @modify date 2022-04-04 06:08:29
 */

import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Button, Stack } from '@mui/material'

import { useStores } from 'models'
import { ModalWrapper } from 'components'
import { COMPLAIN_EMAIL_ADDRESS } from 'config/env'

import { En } from './components/en'

export const TermsOfUse: React.FC = observer(() => {
  const { t } = useTranslation()
  const { modalStore: { termsOfUse, setValue }} = useStores()

  const openMail = (
    <a href={`mailto:${COMPLAIN_EMAIL_ADDRESS}`}>{COMPLAIN_EMAIL_ADDRESS}</a>
  )

  const openPrivacy = (
    <Link
      to='#'
      onClick={() => {
        setValue('termsOfUse', false)
        setValue('privacy', true)
      }}
    >
      {t('menu.privacyStatement')}
    </Link>
  )

  const attributes = {
    openMail: openMail,
    openPrivacy: openPrivacy
  }

  const languageComponent = () => {
    return <En {...attributes} />
  }
  
  return (
    <ModalWrapper
      maxWidth='md'
      open={termsOfUse}
      onClose={() => setValue('termsOfUse', false)}
    >
      {languageComponent()}

      <Stack direction="row" justifyContent="center">
        <Button variant='contained' className='wide' onClick={() => setValue('termsOfUse', false)}>
          {t('common.close')}
        </Button>
      </Stack>
    </ModalWrapper>
  )
})