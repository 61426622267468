import { IStateTreeNode } from "mobx-state-tree"

/**
 * Set a value to the model property.
 */
export const withSetValue = (self: IStateTreeNode) => ({
  actions: {
    /**
     * Set a value to the model property.
     * @param property The property name
     * @param value The property value
     */
    setValue(property: string, value: any): void {
      self[property] = value
    },
  },
})
