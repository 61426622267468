import React from 'react'
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Container, Button, Typography } from "@mui/material"

import { centered } from "assets";
import { signIn } from "assets/images"

export const Forbidden: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Container sx={{...centered, flexDirection: 'column', flex: 1, minHeight: '100vh'}}>
      <img src={signIn} alt='not-found' style={{ maxWidth: '50%' }} />
      <Typography variant='h4' sx={{mt: 3}}>
        {t('forbidden.title')}
      </Typography>
      <Typography variant='body1'>
        {t('forbidden.description')}
      </Typography>
      <Button
        variant='contained'
        className='wide'
        onClick={() => navigate('/')}
        sx={{mt: 3}}
      >
        {t('forbidden.button')}
      </Button>
    </Container>
  )
}
