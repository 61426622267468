const SHARE_TWEETER_URL = 'https://twitter.com/intent/tweet'
const SHARE_FACEBOOK_URL = 'https://www.facebook.com/sharer/sharer.php'
const SHARE_LINKEDIN_URL = 'http://www.linkedin.com/shareArticle'
const SHARE_PINTEREST_URL = 'https://www.pinterest.com/pin/create/button/'
const SHARE_INSTAGRAM_URL = 'https://www.instagram.com/tastifyapp/'
const SHARE_YOUTUBE_URL = 'https://www.youtube.com/channel/UCt2C8ORUb23UaFfiwVKIIsA/featured'

export {
  SHARE_TWEETER_URL,
  SHARE_FACEBOOK_URL,
  SHARE_LINKEDIN_URL,
  SHARE_PINTEREST_URL,
  SHARE_INSTAGRAM_URL,
  SHARE_YOUTUBE_URL
}