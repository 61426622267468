import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import {camelCase, cloneDeep} from "lodash";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {Box, Button, Typography} from "@mui/material";
import {Visibility} from "@mui/icons-material";

import {useStores} from "models";
import {globalAction} from "utils";
import {BasicTable} from "components";
import {emptyCupping} from "assets/images";

const columnHelper = createColumnHelper<any>()

export const CuppingResults = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const {
    sampleStore: { getSamples },
    cuppingResultStore: { cuppingResult, page, totalPage, setValue, getCuppingResults },
    notificationStore
  } = useStores()

  const [isFetching, setIsFetching] = useState(false)
  const data = cloneDeep(cuppingResult)

  const columns = [
    columnHelper.accessor('cuppingSessionName', {
      id: 'cuppingSessionName',
      header: () => <Box textAlign="left">{t('sample.detail.cuppedSessionName')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('cuppedBy', {
      id: 'cuppedBy',
      header: () => <Box textAlign="left">{t('sample.detail.by')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('cuppingSessionOwner', {
      id: 'cuppingSessionOwner',
      header: () => <Box textAlign="left">{t('sample.detail.cuppedSessionOwner')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('cuppingSessionStartDate', {
      id: 'cuppingSessionStartDate',
      header: () => <Box textAlign="left">{t('sample.detail.cuppingSessionStartDate')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('sampleProtocol', {
      id: 'sampleProtocol',
      header: () => <Box textAlign="left">{t('sample.detail.protocol')}</Box>,
      cell: info => info.renderValue() ? t(`options.cuppingProtocol.${camelCase(info.renderValue())}`) : '',
    }),
    columnHelper.accessor('avgDefectScore', {
      id: 'avgDefectScore',
      header: () => t('sample.detail.avgDefectScore'),
      cell: info => <Box textAlign="center">{info.renderValue()}</Box>,
    }),
    columnHelper.accessor('avgScore', {
      id: 'avgScore',
      header: () => t('sample.detail.avgScore'),
      cell: info => <Box textAlign="center">{info.renderValue()}</Box>,
    }),
    columnHelper.accessor('uniqueToken', {
      id: 'uniqueToken',
      header: () => t('sample.detail.action'),
      cell: info =>
        <Box textAlign="center">
          <Button
            variant="contained"
            sx={{ p: '6px', minWidth: 'unset' }}
            onClick={() => onReviewCuppingSession(info.renderValue())}
          >
            <Visibility />
          </Button>
        </Box>,
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const onChangePage = (_, p) => {
    setValue('page', p)
  }

  const onReviewCuppingSession = (cuppingSessionId: string) => {
    globalAction(notificationStore, {
      complexAction: true,
      action: async () => {
        await getSamples(cuppingSessionId)

        navigate(`/sample-review?sample-id=${params.id}`)
      }
    })
  }

  useEffect(() => {
    (async () => {
      try {
        setIsFetching(true)
        await getCuppingResults(params.id)
      } finally {
        setIsFetching(false)
      }
    })()
  }, [page])

  useEffect(() => {
    setValue('page', 1)
  }, [])

  return (
    <Box px={2} py={2.5}>
      {cuppingResult.length > 0 ? (
        <BasicTable
          table={table}
          page={page}
          totalPage={totalPage}
          onChangePage={onChangePage}
          isFetching={isFetching}
        />
        ): (
        <Box py={4} textAlign="center">
          <img src={emptyCupping} />
          <Typography mt={2} variant="h5">{t('sample.detail.emptyCuppingResult')}</Typography>
        </Box>
      )}
    </Box>
  )
})
