/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-20 21:46:24
 * @modify date 2022-04-20 21:46:24
 */

import { types } from 'mobx-state-tree'

export const GreenGrading = types.model({
  color: types.maybeNull(types.string),
  weight: types.maybeNull(types.string),
  smell: types.maybeNull(types.string),
})