/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-09 02:40:45
 * @modify date 2022-03-09 02:40:45
 * @desc [description]
 */
import React from 'react'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import {toNumber, camelCase, cloneDeep, groupBy, isNull, startCase} from 'lodash'
import { LoadingButton } from '@mui/lab'
import {Button, Grid, Box, MenuItem, Typography, Select, Stack} from '@mui/material'

import { useStores } from 'models'
import { ModalWrapper, FormInput, GreenGradingForm } from 'components'
import {colors, defectKeys} from 'constants/form'

import { useStyles } from './green-grading-modal.styles'
import { GreenGradingModalProps } from './green-grading-modal.props'

export const GreenGradingModal: React.FC<GreenGradingModalProps> = observer((props: GreenGradingModalProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { sampleId, open, onClose } = props
  const {
    userStore: { name },
    greenGradingStore: { selectedGreenGradingType, greenGradingTypeWeight, addGreenGrading,
      selectedGreenGrading: {
        id, color, weight, smell, defects,
        roastColor, ovenTest, otaTest, glyphosateTest,
        size14, size15, size16, size17, size18, size19,
        setValue
      },
    },
    notificationStore: { setNotification }
  } = useStores()

  const [isLoading, setIsLoading] = React.useState(false)
  const [tmpDefects, setTmpDefects] = React.useState<any>([])
  const groupDefects = groupBy(tmpDefects, 'category')

  const screenSizes = [
    {id: 'size14', value: size14},
    {id: 'size15', value: size15},
    {id: 'size16', value: size16},
    {id: 'size17', value: size17},
    {id: 'size18', value: size18},
    {id: 'size19', value: size19},
  ]

  const onAddGreenGrading = async () => {
    setIsLoading(true)

    try{
      const res = await addGreenGrading(tmpDefects, sampleId)
      onClose()

      if (res) {
        res.base?.map(message => setNotification(message))
        return
      }

      const message = {
        severity: 'success',
        message: t('greenGrading.success.successfullyAddedGreenGrading')
      }
      setNotification(message)
    } finally {
      setIsLoading(false)
    }
  }

  const onCancelAdding = () => {
    onClose()
  }

  const handleChangeDefect = (value, id) => {
    tmpDefects[tmpDefects.findIndex( key => key.id === id)].count = value
  }

  React.useEffect(() => {
    if (open) {
      setTmpDefects(cloneDeep(defects))
      if (weight === 0) setValue('weight', greenGradingTypeWeight)
    }
  }, [open])

  React.useEffect(() => {
    setValue('author', name)
    setValue('createdAt', moment().format('YYYY-MM-DD HH:mm'))
  }, [])

  return (
    <ModalWrapper maxWidth="md" open={open} onClose={onCancelAdding}>
      <Stack spacing={2} mb={2}>
        <Typography variant="h6">
          {isNull(id)
            ? t("greenGrading.newGreenGrading")
            : t("greenGrading.updateGreenGrading")}
        </Typography>

        <Typography variant="subtitle1">
          {t('greenGrading.greenGradingForm') + ': ' + selectedGreenGradingType?.name}
        </Typography>
      </Stack>

      <Grid container spacing={2} alignItems="end">
        <Grid item xs={12} md={3}>
          <GreenGradingForm
            formInputLabel={`${t("greenGrading.weight")}`}
            formInputProps={{ margin: "none" }}
            value={weight}
            onChange={(e,value) => setValue("weight", toNumber(value))}
            size="small"
            label='gr.'
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput
            label={t("greenGrading.color")}
            formControlProps={{ margin: "none" }}
          >
            <Select
              size="small"
              defaultValue=""
              value={color}
              onChange={(e) => setValue("color", e.target.value)}
            >
              {colors.map((item) => (
                <MenuItem key={item} value={item}>
                  {t(`options.color.${camelCase(item)}`)}
                </MenuItem>
              ))}
            </Select>
          </FormInput>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            label={t("greenGrading.smell")}
            formControlProps={{ margin: "none" }}
            textFieldProps={{
              value: smell ?? '',
              onChange: (e) => setValue("smell", e.target.value),
              inputProps: { maxLength: 255 },
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput
            label={t("greenGrading.roastColor")}
            formControlProps={{ margin: "none" }}
            textFieldProps={{
              value: roastColor ?? '',
              onChange: (e) => setValue("roastColor", e.target.value),
              inputProps: { maxLength: 255 },
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput
            label={t("greenGrading.ovenTest")}
            formControlProps={{ margin: "none" }}
            textFieldProps={{
              value: ovenTest ?? '',
              onChange: (e) => setValue("ovenTest", e.target.value),
              inputProps: { maxLength: 255 },
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput
            label={t("greenGrading.otaTest")}
            formControlProps={{ margin: "none" }}
            textFieldProps={{
              value: otaTest ?? '',
              onChange: (e) => setValue("otaTest", e.target.value),
              inputProps: { maxLength: 255 },
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput
            label={t("greenGrading.glyphosateTest")}
            formControlProps={{ margin: "none" }}
            textFieldProps={{
              value: glyphosateTest ?? '',
              onChange: (e) => setValue("glyphosateTest", e.target.value),
              inputProps: { maxLength: 255 },
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={2} alignItems="end">
        <Grid item xs={12}>
          <Typography variant="h6">
            {t('greenGrading.screenSize.title')}
          </Typography>
        </Grid>
        {screenSizes.map((item) =>
          <Grid item xs={12} md={2} key={item.id}>
            <GreenGradingForm
              formInputLabel={t(`greenGrading.screenSize.${item.id}`)}
              formInputProps={{ margin: "none" }}
              value={item.value}
              onChange={(e,value) => setValue(item.id, toNumber(value))}
              size="small"
              label='gr.'
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2}>
        {defectKeys.map((key) =>
          <Grid item xs={12} mt={2}>
            <Typography variant="h6" mt={2} mb={2}>
              {startCase(key)}
            </Typography>
            <Grid container spacing={2} alignItems="end">
              {groupDefects[key].map((defect) =>
                <Grid item xs={12} md={3}>
                  <GreenGradingForm
                    key={defect.id}
                    id={defect.id}
                    size="small"
                    formInputLabel={defect.defectType ?? ''}
                    formInputProps={{ margin: "none" }}
                    value={defect.count}
                    type={key}
                    onChange={(e, v) => handleChangeDefect(v, defect.id)}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>

      <Box className={classes.buttonWrapper}>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          disabled={!color || toNumber(weight) <= 0}
          onClick={onAddGreenGrading}
          className="wide"
          sx={{ mr: 2 }}
        >
          {t("common.save")}
        </LoadingButton>
        <Button variant="outlined" onClick={onCancelAdding} className="wide">
          {t("common.cancel")}
        </Button>
      </Box>
    </ModalWrapper>
  );
})

