 import { Theme } from '@mui/material'
 import { createStyles, makeStyles } from '@mui/styles'
 import { centered, colors, horizontalCentered, verticalCentered, withBorder } from 'assets'

const gridItem = {
  ...verticalCentered,
  borderBottom: `1px solid ${colors.border.primary}`,
  padding: 10,
  alignItems: 'start'
}

const gridItemDescriptor = {
  ...verticalCentered,
  ...withBorder,
  padding: 8,
  alignItems: 'start',

}

 export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
       wrapper: {
         padding: theme.spacing(1)
       },
       legend: {
        fontStyle: 'italic',
        '&.bold': {
          fontWeight: 500
        }
      },
      vertical: {
        ...verticalCentered
      },
      exportButton: {
        display: 'block',
        backgroundColor: 'white',
        bottom: 0,
        zIndex: 1,
        padding: theme.spacing(2),
        position: 'fixed',
        borderRadius: '10px 10px 0 0',
        [theme.breakpoints.up('md')]: {
          display: 'none'
        }
      },
      headerCardWheel: {
        ...horizontalCentered,
        height: 185,
        minWidth: 185,
        border: "none",
        boxShadow: "none",
        [theme.breakpoints.up('md')]: {
          minWidth: 'auto'
        }
      },
      infoItemWrapper: {
        backgroundColor: 'white',
        '&.collapsed': {
          marginBottom: theme.spacing(2.5)
        },
      },
      infoItem: {
        ...gridItem,
        textAlign: 'center',
        justifyContent: 'center',
        color: colors.primary.main,
        height: 50,
        '&.color-0': {
          borderBottom: 'white',
          marginBottom: theme.spacing(-3)
        },
      },
      infoItemValue: {
        width: '100%',
        flex: 1,
        position: 'relative'
      },
      wheel: {
        width: '100%',
        height: '100%',
        flex: 1,
        position: 'relative'
      },
      boxDesktop: {
        overflow: 'auto',
        whiteSpace: 'nowrap',
        padding: 1,

      },
      sticky: {
        position: "sticky",
        left: 0,
        background: "white",
        boxShadow: "5px 2px 5px grey",
        borderRight: "2px solid black"
      },
      tableCallDescriptors: {
        minWidth: 265,
        textAlign: 'center',
        alignItems: 'center',
        border: 1,
        borderStyle: 'dashed',
        borderColor: colors.text.o25
      }
   })
 )
