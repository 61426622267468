/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-01 00:50:29
 * @modify date 2022-03-01 00:50:29
 */
import React from 'react'
import {times, toNumber} from 'lodash'
import { useTranslation } from "react-i18next"
import {
  Box,
  Typography,
  Slider,
  TextField,
} from '@mui/material'

import { useStyles } from './sca-slide.styles'
import { ScaSlideProps } from './sca-slide.props'

export const ScaSlide: React.FC<ScaSlideProps> = (props: ScaSlideProps) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { label, value, onChange, step = 1, min = 0, max = 15 } = props

  const marks = times(max + 1, (n) => {
    if (n % 5 === 0) return { value: n, label: n.toString() }
    return { value: n }
  })

  const handleChange = (e) => {
    let value = toNumber(e.target.value)

    if (isNaN(value)) value = 0
    if (value < min) value = 0
    if (value > max) value = max

    onChange(value)
  }

  return (
    <Box>
      <Typography variant='h6'>{label.toUpperCase()}</Typography>
      <Box className={classes.wrapper}>
        <Box className={classes.slider}>
          <Box className={classes.sliderLabel}>
            <Typography variant='body2' color="#B12028">{t('descriptor.low')}</Typography>
            <Typography variant='body2' color="#FC6C17">{t('descriptor.medium')}</Typography>
            <Typography variant='body2' color="#7CD55A">{t('descriptor.high')}</Typography>
          </Box>
          <Slider
            value={value}
            onChange={handleChange}
            step={step}
            min={min}
            max={max}
            marks={marks}
            valueLabelDisplay="auto"
          />
        </Box>
        <Box className={classes.score}>
          <Typography variant='body2' className={classes.text}>{t('descriptor.intensity')}</Typography>
          <TextField
            type='tel'
            size='small'
            inputMode='numeric'
            inputProps={{ min: "0", max: "15", pattern: '[0-9]*', size: "1" }}
            value={value}
            onChange={handleChange}
            sx={{width: 45, textAlign: 'right'}}
          />
        </Box>
      </Box>
    </Box>
  )
}
