/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import { Instance, SnapshotOut, types } from "mobx-state-tree"

import {
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"

import { SampleInfo } from './sample-info'
import { Descriptor } from './descriptor'
import { GreenGrading } from './green-grading'
import { withCompareViews } from './compare-view'
import { withCompareActions } from './compare-action'


/**
 * Model description here for TypeScript hints.
 */
export const CompareStoreModel = types
  .model("CompareStore")
  .props({
    ids: types.optional(types.array(types.string), []),
    cuppingProtocol: types.optional(types.string, ''),
    samples: types.optional(types.array(types.model({
      cachedAverageScore: types.maybeNull(types.string),
      sampleInfo: types.optional(SampleInfo, {}),
      descriptors: types.optional(types.array(Descriptor), []),
      lastGreenGrading: types.maybeNull(types.optional(GreenGrading, {})),
      defects: types.optional(types.string, ''),
      wheelData: types.optional(types.string, ''),
    })), [])
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withCompareViews)
  .extend(withCompareActions)

type CompareStoreType = Instance<typeof CompareStoreModel>
export interface CompareStore extends CompareStoreType {}
type CompareStoreSnapshotType = SnapshotOut<typeof CompareStoreModel>
export interface CompareStoreSnapshot extends CompareStoreSnapshotType {}
