/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-25 00:28:50
 * @modify date 2022-03-25 00:28:50
 */

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Button, Box } from '@mui/material'
import { CheckCircle, Error } from '@mui/icons-material'

import { ModalWrapper } from 'components'

import { ModalMessageProps } from './modal-message.props'

export const ModalMessage: React.FC<ModalMessageProps> = (props: ModalMessageProps) => {
  const { t } = useTranslation()
  const { open, type = 'success', description, buttonText = t('common.close'), onSubmit } = props

  const renderIcon = () => {
    let Icon = CheckCircle

    if (type === 'error') Icon = Error
    
    return (
      <Icon color={type} sx={{ fontSize: 80 }} />
    )
  }

  return (
    <ModalWrapper open={open} maxWidth='sm'>
      <Box sx={{textAlign: 'center'}}>
        {renderIcon()}

        <Typography variant='h2' align='center'>{t(`common.${type}`)}</Typography>
        <Typography variant='body2' align='center'>{description}</Typography>

        <Button variant='contained' color='primary' onClick={onSubmit} sx={{mt: 5, width: 300}}>
          {buttonText}
        </Button>
      </Box>
    </ModalWrapper>
  )
}