/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-06 21:22:33
 * @modify date 2022-02-06 21:22:33
 */

import React from 'react'
import clsx from 'clsx'
import {camelCase, isEmpty, isNull, isNumber, startCase} from 'lodash'
import { useTranslation } from 'react-i18next'
import { Card, Grid, Button, IconButton, Menu, MenuItem, Checkbox, Typography, Collapse, SvgIcon, Box } from '@mui/material'
import {
  MoreHoriz,
  ExpandMore,
  ExpandLess,
  Summarize,
  Archive,
  DeleteOutline,
  FileCopy,
  Visibility
} from '@mui/icons-material'
import moment from "moment/moment";

import { MenuIcon } from 'components'
import { countryName } from "utils";
import { IconCup, IconCountry, IconCupper, IconBean, IconProcess, IconStartCupping, IconGreenGrading, IconEdit, IconTotalScore, IconShare } from 'assets/images'

import { SampleItemProps } from './sample-item.props'
import { useStyles } from './sample-item.styles'
import { colors } from 'assets'

import {getCode} from "country-list"

export const SampleItem: React.FC<SampleItemProps> = (props: SampleItemProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    isMobile, id, name, sampleId, species, countryCode, grade, cuppingProtocol, numberOfCuppers, sampleType, country, producerName, cropYear, averageScore,
    regionName, processName, hasBeenCupped, canNotAddSampleInfo, canDeleteSample, isReview, ids, supplierName, canSeeReport, salesContractReference, createdAt,
    onEdit, onCupping, onReport, seeReport, onArchive, onRemove, onCheck, goToDetail
  } = props

  const [expanded, setExpanded] = React.useState(false)
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorMenu)

  const sampleCards = [
    {
      image: IconCup,
      title: t('sample.sampleType'),
      subtitle: startCase(sampleType)
    },
    {
      image: IconCountry,
      title: t('sample.country'),
      subtitle: country
    },
    {
      image: IconCupper,
      title: t('sample.producer'),
      subtitle: producerName
    },
    {
      image: IconBean,
      title: t('sample.cropYear'),
      subtitle: cropYear?.toString()
    },
    {
      image: IconCountry,
      title: t('sample.region'),
      subtitle: regionName
    },
    {
      image: IconProcess,
      title: t('sample.process'),
      subtitle: processName
    }
  ]

  const mobileSampleCards = [
    {

      title: t('sample.sampleId'),
      subtitle: startCase(sampleId)
    },
    {

      title: numberOfCuppers === 1 ? t('sample.cupper'):t('sample.cuppers'),
      subtitle: numberOfCuppers
    },
    {

      title: t('sample.country'),
      subtitle: country
    },
    {

      title: t('sample.producer'),
      subtitle: producerName
    },
    {

      title: t('sample.process'),
      subtitle: processName
    },
    {

      title: t('sample.cropYear'),
      subtitle: cropYear?.toString()
    },
    {

      title: t('sample.supplier'),
      subtitle: supplierName
    },
    {

      title: t('sample.sampleType'),
      subtitle: startCase(sampleType)
    },
  ]

  const isChecked = ids.includes(id.toString())

  if (isMobile) {
    return (
      <Card className={classes.mobileWrapper}>
        <Grid container rowSpacing={1} className={classes.mobileFirstRow}>
          {isReview &&
            <Grid item xs={12} md={isReview ? 2 : 1}>
              <Grid container spacing={1} className={classes.firstRow}>
                <Grid item xs={2} md={6}>
                  <Checkbox sx={{
                    color: "#8D57B2",
                    '&.Mui-checked': {
                      color: "#8D57B2",
                    },
                  }} checked={isChecked} onChange={onCheck} />
                </Grid>

                <Grid item xs={6} md={6} textAlign='left' marginLeft={-3}>
                  <Typography variant='body2'>{name}</Typography>
                </Grid>
              </Grid>
            </Grid>
          }

          <Grid item xs={12} sx={{mb: 1, mt: -1}}>
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={10} sx={{ml: '-22px'}}>
                <Typography variant='body2'>
                  {t(`options.cuppingProtocol.${camelCase(cuppingProtocol)}`)}
                  &nbsp;
                  {t('sample.cuppingForm')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {isReview &&
            <Grid item xs={12} md={isReview ? 2 : 1}>
              <Grid container spacing={1} className={classes.mobileRowDetail}>
                <Grid item xs={1} textAlign='center'>
                  <IconButton
                    size='small'
                    color='primary'

                    sx={{ mr: 0 }}>
                    <SvgIcon component={IconTotalScore} inheritViewBox />
                  </IconButton>
                </Grid>
                <Grid item xs={11} textAlign='left'>
                  <Typography variant='body2'>{hasBeenCupped}</Typography>
                </Grid>

                {!!country &&
                  <Grid item xs={2} md={2} sx={{borderLeft: `1px solid ${colors.border.primary}`, textAlign: "center"}}>
                    <Typography variant='body2' sx={{color: colors.text.o25, ml: -1}}>{getCode(country)}</Typography>
                  </Grid>
                }
                {!!processName &&
                  <Grid item xs={6} md={6} sx={{borderLeft: `1px solid ${colors.border.primary}`, textAlign: "left"}}>
                    <Typography variant='body2' sx={{color: colors.text.o25}}>{processName}</Typography>
                  </Grid>
                }

                <Grid item xs={1} md={6} className={classes.mobileButtonWrapperExpand}>
                  <IconButton
                    size="medium"
                    style={{ backgroundColor: "#8D57B2" }}
                    onClick={() => setExpanded(!expanded)}
                    sx={{ p: 0 }}
                  >
                    {expanded ? <ExpandLess style={{ color: "#FFFFFF" }} /> : <ExpandMore style={{ color: "#FFFFFF" }} />}
                  </IconButton>

                </Grid>
              </Grid>
            </Grid>
          }
          {isReview ?
            <Grid item xs={12} md={1} className={classes.mobileButtonWrapper}>
              <IconButton
                size="medium"
                color="primary"
                onClick={(event) => setAnchorMenu(event.currentTarget)}
                className={clsx(classes.iconButton)}
                sx={{ p: 0 }}
              >
                <MoreHoriz />
              </IconButton>

              <Menu
                anchorEl={anchorMenu}
                open={openMenu}
                onClose={() => setAnchorMenu(null)}
                onClick={() => setAnchorMenu(null)}
              >
                <MenuItem onClick={onCupping}>
                  <MenuIcon><SvgIcon component={IconStartCupping} inheritViewBox /></MenuIcon>
                  {t('sample.cuppingSession')}
                </MenuItem>
                <MenuItem onClick={onEdit}>
                  <MenuIcon><SvgIcon component={IconGreenGrading} inheritViewBox /></MenuIcon>
                  {t('sample.greenGrading')}
                </MenuItem>
                <MenuItem disabled={canNotAddSampleInfo} onClick={onEdit}>
                  <MenuIcon><SvgIcon component={IconEdit} inheritViewBox /></MenuIcon>
                  {t('common.edit')}
                </MenuItem>
                <MenuItem onClick={onArchive}>
                  <MenuIcon><Archive /></MenuIcon>
                  {t('sample.archive')}
                </MenuItem>
                <MenuItem disabled={!canDeleteSample} onClick={onRemove}>
                  <MenuIcon><DeleteOutline /></MenuIcon>
                  {t('common.delete')}
                </MenuItem>
              </Menu>
            </Grid> :
            <Grid item xs={12} md={1} className={classes.buttonWrapper}>
              <Button
                size='small'
                variant='contained'
                startIcon={<Summarize />}
                onClick={onReport}
              >
                <SvgIcon component={IconStartCupping} inheritViewBox /> {t('sample.report')}
              </Button>
            </Grid>
          }

          <Grid item xs={12}>
            <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ml: 1}}>
              <Card sx={{ borderColor: colors.border.primary, padding: 1 }}>
                {mobileSampleCards.map((sampleCard, index) =>
                  <Grid container rowSpacing={1} md key={index} >
                    <Grid item xs={4}>
                      <Box sx={{mb: 1}}>
                        <Typography variant='subtitle2'>
                          {sampleCard.title === "Sample Id" ? "ID" : sampleCard.title}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={8}>
                      <Box sx={{mb: 1}}>
                        <Typography variant='subtitle2' sx={{color: colors.text.o75}}>
                          {sampleCard.subtitle}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} md={isReview ? 2 : 1} paddingTop={2}>
                  <Grid container spacing={2} rowSpacing={2}>
                    <Grid item xs={6}>
                      <Button
                        disabled={!canSeeReport}
                        size='medium'
                        variant='contained'
                        onClick={onReport}
                        fullWidth
                      >
                        <IconButton
                          size='small'
                          style={{ color: "#FFFFFF" }}

                          sx={{ mr: 0 }}><SvgIcon component={IconStartCupping} inheritViewBox /> </IconButton>{t('report.seeReport')}
                      </Button>
                    </Grid>

                    <Grid item xs={6}>
                      <Button
                        disabled={!isNumber(hasBeenCupped)}
                        size='medium'
                        variant='outlined'
                        onClick={seeReport}
                        fullWidth
                      >
                        <IconButton
                          size='small'
                          color='primary'

                          sx={{ mr: 0 }}><SvgIcon component={IconShare} inheritViewBox /></IconButton> {t('sample.share')} {t('sample.report')}
                      </Button>
                    </Grid>

                  </Grid>

                </Grid>
              </Card>

            </Collapse>
          </Grid>
        </Grid>
      </Card>
    )
  }

  return (
    <Card className={classes.wrapper}>
      <Grid container alignItems='center'>
        <Grid item md={2}>
          {isReview &&
            <Checkbox checked={isChecked} onChange={onCheck} sx={{py: 0}} />
          }

          {name}
        </Grid>
        <Grid item md={9}>
          <Grid container>
            <Grid item xs className={classes.textCenter}>{moment(createdAt).format('DD/MM/YYYY')}</Grid>
            <Grid item xs className={classes.textCenter}>{species && t(`options.cuppingProtocol.${camelCase(species)}`)}</Grid>
            <Grid item xs className={classes.textCenter}>{!isEmpty(sampleType) && t(`options.sampleType.${camelCase(sampleType)}`)}</Grid>
            <Grid item xs className={classes.textCenter}>{countryName(countryCode)}</Grid>
            <Grid item xs className={classes.textCenter}>{processName}</Grid>
            <Grid item xs className={classes.textBold}>{salesContractReference}</Grid>
            <Grid item xs className={classes.textCenter}>{grade}</Grid>
            <Grid item xs className={classes.textBold}>{(averageScore === 0 || isNull(averageScore)) ? "-" : averageScore}</Grid>
          </Grid>
        </Grid>
        {isReview ?
          <Grid item xs={12} md={1}>
            <Grid container>
              <Grid item xs={8} textAlign='center'>
                <Button
                  variant="contained"
                  sx={{ p: '6px', minWidth: 'unset' }}
                  onClick={goToDetail}
                >
                  <Visibility />
                </Button>
              </Grid>
              <Grid item xs={4}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={(event) => setAnchorMenu(event.currentTarget)}
                  className={clsx(classes.iconButton)}
                  sx={{ p: 0, pr: 0.5 }}
                >
                  <MoreHoriz />
                </IconButton>

                <Menu
                  anchorEl={anchorMenu}
                  open={openMenu}
                  onClose={() => setAnchorMenu(null)}
                  onClick={() => setAnchorMenu(null)}
                >
                  <MenuItem disabled={canNotAddSampleInfo} onClick={onEdit}>
                    <MenuIcon><SvgIcon component={IconEdit} inheritViewBox /></MenuIcon>
                    {t('common.edit')}
                  </MenuItem>
                  <MenuItem>
                    <MenuIcon><FileCopy /></MenuIcon>
                    {t('sample.samples.button.copy')}
                  </MenuItem>
                  <MenuItem
                    disabled={!canSeeReport}
                    onClick={onReport}
                  >
                    <MenuIcon><Summarize /></MenuIcon>
                    {t('sample.report')}
                  </MenuItem>
                  <MenuItem onClick={onArchive}>
                    <MenuIcon><Archive /></MenuIcon>
                    {t('sample.archive')}
                  </MenuItem>
                  <MenuItem disabled={!canDeleteSample} onClick={onRemove}>
                    <MenuIcon><DeleteOutline /></MenuIcon>
                    {t('common.delete')}
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
          :
          <Grid item xs={12} md={1} className={classes.buttonWrapper}>
            <Button
              size='small'
              variant='contained'
              startIcon={<Summarize />}
              onClick={onReport}
            >
              {t('sample.report')}
            </Button>
          </Grid>
        }
      </Grid>
    </Card>
  )
}
