/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-29 05:07:02
 * @modify date 2022-04-29 05:07:02
 */

import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { find, isEmpty, some, clone, uniq, pull, union } from 'lodash'
import { Stack, Typography, Button, Box, TextField, InputAdornment, Chip, Divider, Grid, } from '@mui/material'
import { AddCircleOutline, TurnLeft, Close } from '@mui/icons-material'

import { colors } from 'assets'
import { FormInput, ModalWrapper } from 'components'
import { addAlpha, textDescriptorsTranslation, descriptorTranslation, descriptorColor, flavorWheel } from 'utils'

import { useStyles } from './descriptor-filter.styles'
import { DescriptorFilterProps } from './descriptor-filter.props'

export const DescriptorFilter: React.FC<DescriptorFilterProps> = (props: DescriptorFilterProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { name, open, language, descriptors, descriptorList, onClose, addDescriptor } = props

  const [customDescriptor, setCustomDescriptor] = React.useState('')
  const [temporaryDescriptors, setTemporaryDescriptors] = React.useState<any[]>([])
  const [temporaryDescriptorList, setTemporaryDescriptorList] = React.useState<any[]>([])
  const [isMobile, setIsMobile] = React.useState(false)
  const idElement = `tastify-wheel-${name}`

  const descriptorStyle = (descriptor: any, isActive: boolean) => {
    if (isEmpty(temporaryDescriptors) || isActive) {
      return {
        borderColor: descriptor.color,
        boxShadow: isActive ? `0px 0px 4px ${addAlpha(descriptor.color, 0.5)}`: ''
      }
    }

    return {
      borderColor: colors.border.primary,
      color: colors.text.o50
    }
  }

  const addOrRemoveTemporaryDescriptors = (descriptor: any, isActive: boolean) => {
    const newDescriptors = clone(temporaryDescriptors)
    if (!newDescriptors) return

    isActive ? pull(newDescriptors, descriptor) : newDescriptors.push(descriptor)
    setTemporaryDescriptors(uniq(newDescriptors))
  }

  const updateDescriptorList = (temporaryList, descriptor, isActive) => {
    const applySelected = find(temporaryList, ['id', descriptor.id])
    if (applySelected) {
      temporaryList.forEach(list => list.selected = false)
      applySelected.selected = !isActive
      return
    }

    const next = find(temporaryList, 'selected')
    if (!next) return

    updateDescriptorList(next.groups, descriptor, isActive)
  }

  const addOrRemoveGroupDescriptors = (descriptor, isActive: boolean) => {   
    const newTemporaryDescriptorList = clone(temporaryDescriptorList)
    updateDescriptorList(newTemporaryDescriptorList, descriptor, isActive)
    setTemporaryDescriptorList(newTemporaryDescriptorList)
  }

  const addCustomDescriptor = () => {
    if (!temporaryDescriptorList) return
    
    const descriptorObject = {
      id: uuidv4(),
      label: customDescriptor
    }

    setTemporaryDescriptorList(uniq([...temporaryDescriptorList, descriptorObject]))
    addOrRemoveTemporaryDescriptors(descriptorObject, false)
    setCustomDescriptor('')
  }

  const descriptorPicker = (descriptor) => {
    const hasGroups = !isEmpty(descriptor.groups)
    const isActive = hasGroups ? descriptor.selected : some(temporaryDescriptors, ['label', descriptor.label])

    return (
      <Chip
        key={descriptor.id}
        size="small"
        variant='outlined'
        label={descriptorTranslation(language, descriptor)}
        deleteIcon={<AddCircleOutline />}
        sx={{ ...descriptorStyle(descriptor, isActive), ...descriptorColor(descriptor, isActive) }}
        {...(!isActive && {onClick: () => hasGroups ? addOrRemoveGroupDescriptors(descriptor, isActive) : addOrRemoveTemporaryDescriptors(descriptor, isActive)})}
        {...(isActive && hasGroups && {onDelete: () => addOrRemoveTemporaryDescriptors(descriptor, false)})}
      />
    )
  }

  const renderDescriptors = (descriptors, parentDescriptor: any = {}, isChild: boolean = false) => {
    if (isEmpty(descriptors)) return
    
    return (
      <Box sx={{mt: 2}}>
        {isChild &&
          <Box sx={{display: 'inline-flex'}}>
            <TurnLeft className={classes.leftArrow}/>
            <Typography>{t('descriptor.descriptorOf')}</Typography>
            <Chip
              key={parentDescriptor.id}
              size="small"
              variant='outlined'
              sx={{...descriptorColor(parentDescriptor), ml: 1}}
              label={descriptorTranslation(language, parentDescriptor)}
            />
          </Box>
        }
        <Box className={classes.descriptorWrapper} sx={{ml: isChild ? 3 : 0}}>
          {descriptors.map(descriptor => descriptorPicker(descriptor))}

          {descriptors.map(descriptor => { 
            if (descriptor.selected) return renderDescriptors(descriptor.groups, descriptor, true) 

            return null
          })}
        </Box>
      </Box>
    )
  }

  const renderMobile = (
    <>
      <Typography variant='subtitle1' sx={{mb: 1}}>{t('descriptor.selectDescriptors')}</Typography>
      <TextField 
        size='small'
        value={customDescriptor}
        onChange={(e) => setCustomDescriptor(e.target.value)}
        placeholder={t('descriptor.addCustomDescriptor')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                size='small'
                variant='contained'
                onClick={addCustomDescriptor}
              >
                {t('descriptor.add')}
              </Button>
            </InputAdornment>
          ),
        }}
        sx={{mb: 2}}
      />

      <Typography variant='h6'>{t('descriptor.mainDescriptors')}</Typography>
      {renderDescriptors(temporaryDescriptorList)}

      {!isEmpty(temporaryDescriptors) &&
        <>
          <Divider sx={{my: 2}} />
          <Typography variant='body2' sx={{mb: 1}}>{t('descriptor.selectedDescriptors')}:</Typography>
          {temporaryDescriptors.map(descriptor => 
            <Chip
              key={descriptor.id}
              size="small"
              variant='outlined'
              sx={descriptorColor(descriptor)}
              label={descriptorTranslation(language, descriptor)}
              onDelete={() => addOrRemoveTemporaryDescriptors(descriptor, true)}
            />
          )}
        </>
      }

      <Stack spacing={2} sx={{mt: 5}}>
        <Button
          fullWidth
          size='small'
          variant='outlined'
          className='wide'
          onClick={() => {
            setTemporaryDescriptors([])
            addDescriptor([])
          }}
          startIcon={<Close color='error' />}
        >
          {t('descriptor.clearAll')}
        </Button>
        <Button
          fullWidth
          variant='contained'
          className='wide'
          sx={{mr: 2}}
          onClick={() => {
            onClose()
            addDescriptor(temporaryDescriptors)
          }}
        >
          {t('common.apply')}
        </Button>
      </Stack>
    </>
  )

  const renderDesktop = (
    <>
      <Typography variant='subtitle1' sx={{mb: 1}}>{t('descriptor.selectDescriptors')}</Typography>
      <Grid container spacing={2} sx={{flex: 1}}>
        <Grid item xs={8}>
          <Box sx={{width: '100%', height: '100%'}}>
            <Box id={idElement} className={classes.wheel}></Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <FormInput 
            label={t('descriptor.descriptor')}
            textFieldProps={{
              multiline: true,
              rows: 10,
              inputProps: {
                readOnly: true
              },
              value: textDescriptorsTranslation(language, temporaryDescriptors),
              placeholder: t('descriptor.selectDescriptorWheel')
            }}
          />

          {temporaryDescriptors.length > 0 &&
            <Button
              fullWidth
              size='small'
              variant='outlined'
              className='wide'
              onClick={() => {
                setTemporaryDescriptors([])
                addDescriptor([])
              }}
              startIcon={<Close color='error' />}
            >
              {t('descriptor.clearAll')}
            </Button>
          }

          <Stack direction='row' spacing={2} sx={{mt: 5}}>
            <Button
              fullWidth
              variant='contained'
              sx={{mr: 2}}
              onClick={() => {
                onClose()
                addDescriptor(temporaryDescriptors)
              }}
            >
              {t('common.apply')}
            </Button>
            <Button
              fullWidth
              size='small'
              variant='outlined'
              onClick={onClose}
            >
              {t('common.cancel')}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  )

  React.useEffect(() => {
    if (!open) return

    const cloneDescriptorList = clone(descriptorList)    
    const descriptorSelections = clone(descriptors)
    // const descriptorSelections = descriptorSelection(cloneDescriptorList, descriptors ? descriptors.split(',') : [])

    setTemporaryDescriptors(descriptorSelections)
    setTemporaryDescriptorList(cloneDescriptorList)
  }, [open])

  const onSetLayout = () => {
    if(window.innerWidth < 900) {
      setIsMobile(true)
      return
    }

    setIsMobile(false)
  }

  React.useLayoutEffect(() => {
    flavorWheel(
      temporaryDescriptorList, 
      '', 
      idElement, 
      language,
      true, 
      (descriptors) => setTemporaryDescriptors(union(temporaryDescriptors, descriptors))
    )

  }, [temporaryDescriptorList])
  
  React.useLayoutEffect(() => {
    window.addEventListener('resize', onSetLayout)

    onSetLayout()
    return () => window.removeEventListener("resize", onSetLayout)
  }, [])

  return (
    <ModalWrapper
      maxWidth={isMobile ? 'sm' : 'lg'}
      open={open}
      onClose={onClose}
      {...(!isMobile && {containerProps: {
        sx: {
          height: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }
      }})}
    >
      {isMobile ? renderMobile : renderDesktop}
    </ModalWrapper>
  )
}