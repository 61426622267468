/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-06-27 16:45:20
 * @modify date 2022-06-27 16:45:20
 */

import React from 'react'
import { IconButton } from '@mui/material'
import { Close as IconClose } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

interface props {
  snackbarKey: string | number
}

export const CloseNotification: React.FC<props> = (props: props) => {
  const { closeSnackbar } = useSnackbar()

  return (
    <IconButton
      onClick={() => closeSnackbar(props.snackbarKey)}
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        color: 'white'
      }}
    >
      <IconClose />
    </IconButton>
  )
}