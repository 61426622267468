/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-18 00:12:38
 * @modify date 2022-01-18 00:12:38
 * @desc Style for home page
 */
 import { Theme } from '@mui/material'
 import { createStyles, makeStyles } from '@mui/styles'
 import { centered, colors, flexColumn } from 'assets'

 export const useStyles = makeStyles((theme: Theme) =>
   createStyles({
    wrapper: {
      flexDirection: 'column-reverse',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row'
      }
    },
    linkWrapper: {
      height: '100%',
      borderTop: `1px solid ${colors.border.primary}`,
      borderBottom: `1px solid ${colors.border.primary}`,
      [theme.breakpoints.up('md')]: {
        border: 'none'
      }
    },
    linkItem: {
      border: 'none'
    },
    activityWrapper: {
      ...flexColumn,
      height: '100%',
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      borderLeft: 'none',
      borderRight: 'none',
      borderRadius: 0,
      [theme.breakpoints.up('md')]: {
        border: `1px solid ${colors.border.primary}`,
        borderRadius: 5
      }
    },
    activityItem: {
      borderBottom: `1px solid ${colors.border.primary}`,
      [theme.breakpoints.up('md')]: {
        border: 'none',
      }
    },
    iconButton: {
      padding: 4,
      border: '1px solid',
      color: theme.palette.primary.main,
      '& svg': {
        width: '3rem',
        height: '3rem',
      },
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark,
        '& svg': {
          color: theme.palette.primary.dark
        }
      },
      '&.paused': {
        backgroundColor: colors.secondary.o25
      }
    },
    emptyActivity: {
      ...centered,
      flex: 1,
      flexDirection: 'column'
    }
   })
 )
