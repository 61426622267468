/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-15 15:29:27
 * @modify date 2022-03-31 01:25:17
 * @desc Style for sign-in page
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { verticalCentered } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonWrapper: {
      ...verticalCentered,
      justifyContent: 'space-evenly',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    button: {
      width: '200px',
      marginRight: theme.spacing(1)
    },
    expandWrapper: {
      ...verticalCentered,
      justifyContent: "space-between",
      cursor: 'pointer',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    }
  })
)