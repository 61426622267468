/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-01 17:30:12
 * @modify date 2022-03-01 17:30:12
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      minHeight: 250,
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      '&.no-descriptor': {
        alignItems: 'center',
        justifyContent: 'center'
      }
    },
    descriptorWrapper: {
      flex: 1,
      '&.no-descriptor': {
        padding: theme.spacing(3),
        textAlign: 'center'
      }
    },
    addDescriptor: {
      borderRadius: 99, 
      width: 'fit-content',
      borderStyle: 'dashed',
      '&:hover': {
        borderStyle: 'dashed'
      }
    },
    hasNoDescriptor: {
      color: colors.text.o50,
      fontStyle: 'italic',
      textAlign: 'center'
    },
    calculationWrapper: {},
    score: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      minWidth: 22,
      color: colors.text.o50,
      display: 'inline-flex'
    },
    coeTextWrapper: {
      fontStyle: 'italic',
      opacity: 0.8
    }
  })
)