import i18n from "i18n/i18n"
import {chain, isEmpty, keys, startCase, values} from "lodash"

import { colors } from "assets"

export const withMasterSampleViews = (self: any) => ({
  views: {
    get searchValues() {
      return chain(self.search)
        .omitBy(isEmpty)
        .assign({
          ...(self.search.generalSearch && {generalSearch: self.search.generalSearch}),
          ...(self.search.scoredByMe && {scoredByMe: self.search.scoredByMe})
        })
        .value()
    },
    get getCloudWords() {
      return JSON.parse(self.masterSampleDetail.aggregatedCloudWords)
    },
    get getFlavorWheelGroups() {
      return JSON.parse(self.masterSampleDetail.aggregatedFlavorWheels)
    },
    get getAverageScoresForGraph() {
      const data = JSON.parse(self.masterSampleDetail.aggregatedSpiderWeb)
      const labels = keys(data).map(k => startCase(k))

      const graph = {
        labels: labels,
        datasets: [
          {
            label: i18n.t('sample.averageScore'),
            data: values(data),
            backgroundColor: colors.primary.o25,
            borderColor: colors.primary.main,
            borderWidth: 2,
          },
        ],
      }

      return graph
    },
  }
})
