/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-17 22:40:30
 * @modify date 2022-04-19 20:49:38
 */
import { flow } from "mobx-state-tree"
import { ApiResult } from "services/api"
import { toString } from "lodash"

export const withIndividualReportActions = (self: any) => ({
  actions: {
    setIndividualReport(data) {
      const reports = data.map(d => {
        return {
          ...d,
          id: toString(d.id),
          sampleName: toString(d.sampleName),
          scores: JSON.stringify(d.scores),
          flavorWheelGroups: JSON.stringify(d.flavorWheelGroups),
          flavorWheelOnlyFragrance: JSON.stringify(d.flavorWheelOnlyFragrance),
          flavorWheelOnlyFlavor: JSON.stringify(d.flavorWheelOnlyFlavor),
          topFlavorWheelGroups: JSON.stringify(d.topFlavorWheelGroups),
          topFlavorWheelOnlyFragrance: JSON.stringify(d.topFlavorWheelOnlyFragrance),
          topFlavorWheelOnlyFlavor: JSON.stringify(d.topFlavorWheelOnlyFlavor),
          interestStatus : JSON.stringify(d.interestStatus)
        }
      })

      self.setValue('individualReports', reports)
    },
    getIndividualReport: flow(function * (token, params) {
      try {
        if (!token) return

        const res: ApiResult = yield self.environment.individualReportApi.find(token, `individual_report${params}`)

        if (res.kind === "ok") self.setIndividualReport(res.data)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),

  }
})
