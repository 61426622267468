/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-14 22:33:51
 * @modify date 2022-02-01 16:22:41
 */
import React from 'react'
import { Container, Grid, Typography } from '@mui/material'

import { useStyles } from './authentication-wrapper.styles'
import { AuthenticationWrapperProps } from './authentication-wrapper.props'

export const AuthenticationWrapper: React.FC<AuthenticationWrapperProps> = (props: AuthenticationWrapperProps) => {
  const classes = useStyles()
  const { title, image, isTextOnly = false, children } = props

  const authenticationTitle = () => {
    if (isTextOnly) {
      return (
        <>
          {title}
        </>
      )
    }

    return (
    <Container fixed sx={{textAlign: 'center'}}>
      {title && <Typography variant='h2' className={classes.title}>{title}</Typography>}
      <img src={image} alt="AuthenticationImage" className={classes.image} />
    </Container>
    )
  }

  return (
    <Container data-testid="containerAuthenticationWrapper">
      <Grid container spacing={2} className={classes.wrapper}>
        <Grid item xs={12} sm={12} md={6} className={classes.containerWrapper} > 
          {authenticationTitle()}
        </Grid>
        <Grid item xs={12} sm={12} md={6} sx={{ my: 4 }}> 
          <Container fixed className={classes.wrapper}>
            {children}
          </Container>
        </Grid>
      </Grid>
    </Container>
  )
}