/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-01 01:08:43
 * @modify date 2022-04-01 01:08:43
 */
import React from 'react'
import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop'
import { LoadingButton } from '@mui/lab'

import { imagePreview } from 'utils'
import { ModalWrapper } from 'components'
import { horizontalCentered } from 'assets'

import { ModalCropImageProps } from './modal-crop-image.props'
import 'react-image-crop/dist/ReactCrop.css'

const defaultCropValue = {
  x: 0,
  y: 0,
  width: 100,
  height: 100,
  unit: '%' as const
}

export const ModalCropImage: React.FC<ModalCropImageProps> = (props: ModalCropImageProps) => {
  const { t } = useTranslation()
  const { open, file, onClose, onComplete } = props

  const [crop, setCrop] = React.useState<Crop>(defaultCropValue)
  const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>()
  const imageElement = React.createRef<HTMLImageElement>()
  const [isLoading, setIsLoading] = React.useState(false)

  const onCompleteCrop = async () => {  
    const enableComplete = completedCrop?.width && completedCrop?.height && imageElement.current  
    if(!enableComplete) return

    try {
      setIsLoading(true)
      const imageProperties = await imagePreview(imageElement.current, completedCrop)
      onComplete(imageProperties)
    } finally {
      setIsLoading(false)
    }
  }

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget
    const newCrop = {
      ...defaultCropValue,
      width,
      height,
      unit: 'px' as const
    }
    
    setCrop(newCrop)
    setCompletedCrop(newCrop)
  }
  
  return (
    <ModalWrapper
      maxWidth='sm'
      open={open}
      onClose={onClose}
    >
      <Box sx={{...horizontalCentered}}>
        <ReactCrop
          aspect={1}
          crop={crop}
          onChange={c => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
        >
          <img ref={imageElement} src={file?.preview} alt='crop-profile' onLoad={onImageLoad} />
        </ReactCrop>
      </Box>

      <Box sx={{...horizontalCentered, mt: 4}}>
        <LoadingButton
          variant='contained'
          className='wide'
          onClick={onCompleteCrop}
          sx={{mx: 1}}
          loading={isLoading}
        >
          {t('common.save')}
        </LoadingButton>
        <Button
          variant='outlined'
          className='wide'
          onClick={onClose}
          sx={{mx: 1}}
        >
          {t('common.cancel')}
        </Button>
      </Box>
    </ModalWrapper>
  )
}