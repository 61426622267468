import React from 'react'
import {keys, pick, toNumber} from "lodash"
import { useTranslation } from "react-i18next"
import {Box, Typography, Checkbox} from "@mui/material"
import { LoadingButton } from "@mui/lab"

import { ModalWrapper } from "components"
import { SettingQrCodeProps } from "./setting-qr-code.props"

const defaultSetting = {
  nameStatus: 0,
  emailStatus: 0,
  companyNameStatus: 0,
  roleStatus: 0
}

export const SettingQrCode = (props: SettingQrCodeProps) => {
  const { t } = useTranslation()
  const { open = false, onClose, cuppingSession, onSave } = props
  const [setting, setSetting] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(false)

  const handleChange = (event, key) => {
    const value = toNumber(event.target.value)

    if (value === setting[key]) {
      setSetting({
        ...setting,
        [key]: 0
      })
      return
    }
    if(setting[key] >= 1 ){
      if(setting[key] === 3){
        setSetting({
          ...setting,
          [key]: setting[key] - value
        })
        return
      }
      setSetting({
        ...setting,
        [key]:setting[key] + value
      })
      return
    }

    setSetting({
      ...setting,
      [key]: value
    })
  }

  const updateInformation = () => {
    try {
      setIsLoading(true)
      onSave(cuppingSession.id, setting)
      onClose()
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    const requestInformation = pick(cuppingSession.publicCuppingRequestInformation, keys(defaultSetting))
    setSetting(
      Object.keys(defaultSetting).reduce(
        (attrs, key) => ({
          ...attrs,
          [key]: requestInformation[key] || defaultSetting[key]
        }),
        {}
      )
    )
  }, [cuppingSession.id])

  return (
    <ModalWrapper open={open} onClose={onClose} maxWidth="xs">
      <Box mb={2}>
        <Typography variant='h6' sx={{mb: 0.5}}>
          {t('cuppingSession.setting.title')}
        </Typography>

        <Typography variant="body2">
          {t('cuppingSession.setting.subtitle')}
        </Typography>
      </Box>
      <table width="100%">
        <thead style={{fontSize: 14}}>
          <tr>
            <th></th>
            <th>{t('cuppingSession.setting.collect')}</th>
            <th>{t('cuppingSession.setting.mandatory')}</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(setting).map(key=> {
            return (
            <tr key={`${cuppingSession.id}-${key}`}>
              <td>{t(`cuppingSession.setting.${key}`)}</td>
              <td align="center">
                <Checkbox
                  checked={setting[key] === 1 || setting[key] === 3}
                  value={1}
                  onChange={(event) => handleChange(event, key)}
                />
              </td>
              <td align="center">
                <Checkbox
                  checked={setting[key] === 2 || setting[key] === 3}
                  value={2}
                  onChange={(event) => handleChange(event, key)}
                />
              </td>
            </tr>
            )
          })}
        </tbody>
      </table>

      <LoadingButton
        variant='contained'
        className='wide'
        onClick={updateInformation}
        sx={{mt: 2}}
        loading={isLoading}
      >
        {t('common.save')}
      </LoadingButton>
    </ModalWrapper>
  )
}
