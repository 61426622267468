/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-14 23:27:39
 * @modify date 2022-01-14 23:27:39
 * @desc Style for info page component
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

import { centered } from 'assets'

 export const useStyles = makeStyles((theme: Theme) =>
   createStyles({
     wrapper: {
       ...centered,
       height: `calc(100vh - 16px)`
     },
     image: {
      width: '100%',
      height: 'auto',
      [theme.breakpoints.up('md')]: {
        width: '50%'
      }
    },
     title: {}
   })
 )