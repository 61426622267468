/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-06 20:03:48
 * @modify date 2022-02-06 20:03:48
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors, verticalCentered } from 'assets'
 
export const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      filterWrapper: {
        padding: theme.spacing(4)
      },
      formWrapper: {
        marginBottom: theme.spacing(3),
        border: `1px solid ${colors.border.primary}`,
        borderRadius: 5
      },
      collapseFilter: {
        ...verticalCentered,
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2.5)
      },
      formRow: {
        marginBottom: theme.spacing(2),
        alignItems: 'flex-end',
     
      },
      gridButton: {
        marginBottom: theme.spacing(1.5),
        alignItems: 'center',
     
      },
       titleWrapper: {
         ...verticalCentered,
         justifyContent: 'space-between',
         backgroundColor: colors.primary.main,
         padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
         color: 'white',
         '& svg': {
           color: 'white',
         }
       },
       button: {
        minWidth: 'fit-content',
        marginLeft: theme.spacing(2),
        backgroundColor: "#E0D1EA",   
        borderColor: "#E0D1EA",
       
      },
       buttonSearch: {
         height: '100%',
         color: 'inherit',
         borderColor: colors.border.primary,
         '&:hover, &.active': {
           backgroundColor: colors.primary.main,
           border: `1px solid ${colors.border.primary}`,
           color: 'white'
         },
         '& > span:first-child': {
           paddingRight: 4,
           maxWidth: '100%'
         },
         '&.inactive:hover': {
           color: 'inherit',
           backgroundColor: 'inherit',
           border: `1px solid ${colors.border.primary}`,
         }
       },
     boxDesktop: {
       overflow: 'auto',
       whiteSpace: 'nowrap',
       padding: 1,
       marginTop: -5,
       marginLeft: -2,
       marginRight: -2,
     }
  })
)