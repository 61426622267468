import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { centered, colors } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    totalScoreWrapper: {
      ...centered,
      height: '100%',
      textAlign: 'center',
      flexDirection: 'column',
      margin: `${theme.spacing(2)} 0`
    },
    legend: {
      fontStyle: 'italic',
      '&.bold': {
        fontWeight: 500
      }
    },
    approved: {
      color: colors.tertiary.main,
      textTransform: 'uppercase'
    },
    rejected: {
      color: colors.error.primary,
      textTransform: 'uppercase'
    },
    powered: {
      ...centered,
      '&.absolute': {
        position: 'absolute',
        right: 10,
        bottom: 5
      }
    },
    wheelWrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    wheel: {
      maxWidth: '100%',
      maxHeight: '100%',
      flex: 1,
      position: 'relative'
    },
    tableDefect: {
      '& th, td': {
        fontSize: '0.75rem'
      },
      '& tfoot > tr > td': {
        borderBottom: 'none'
      }
    },
    primary: {
      color: colors.primary.main
    },
    socialIcon: {
      padding: `${theme.spacing(1)} !important`
    },
    cellMobile: {
      whiteSpace: 'nowrap',
      padding: theme.spacing(0.5),
    }
  })
)