/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-16 21:35:14
 * @modify date 2022-03-16 21:35:14
 */

import React from 'react'
import clsx from 'clsx'
import moment from 'moment'
import {
  startCase,
  groupBy,
  isEmpty,
  sumBy,
  split,
  trim,
  camelCase,
  map,
  debounce,
  clone,
  includes,
  pull,
  find
} from 'lodash'
import ReactWordcloud from 'react-wordcloud'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import {useParams, useNavigate, useLocation} from 'react-router-dom'
import { Radar } from 'react-chartjs-2'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Box,
  Container,
  Grid,
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Popover,
  IconButton,
  Collapse,
  SvgIcon,
  AppBar,
  Switch
} from '@mui/material'
import { Visibility, VisibilityOff, Email, ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share"
import { FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon } from 'react-share'
import { isMobile } from "react-device-detect"

import { centered, colors } from 'assets'
import { useStores } from 'models'
import { IconReportShare, Logo} from 'assets/images'
import { DEBOUNCE_TIME } from 'config/env'
import { findRoute } from 'constants/routes'
import { printPage, validateEmail, descriptorSelection, descriptorTranslation } from 'utils'
import { FormInput, HeaderTitle, ModalWrapper } from 'components'
import {
  minimumScore,
  maximumScore,
  arabicaScoreTable,
  robustaScoreTable,
  coeScoreTable,
  scaScoreTable,
  scaDescriptiveScoreTable, sucafinaTastingForm
} from 'constants/form'
import { allDescriptor } from 'constants/all-descriptors'

import { useStyles } from './sample-report.styles'
import { flavorWheel } from 'utils'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)

export const SampleReport: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const locations = useLocation()
  const searchParams = new URLSearchParams(locations.search)

  const homeLink = findRoute('home')
  const sampleLink = findRoute('reviewSamples')
  const sampleInformationLink = findRoute('sampleInformation')
  const sampleReviewLink = findRoute('sampleReview')
  const printElement = React.createRef<Element | undefined>()

  const {
    sampleReportStore: {
      title, emails, message, isCuppingOwner, cuppingProtocol, cuppingDate, scores, getSampleReport, getAverageScoresForGraph,
      getCloudWords, allScoreDescriptors, getFlavorWheelOnlyFragrance, getFlavorWheelOnlyFlavor, getFlavorWheelGroups,
      getDefects, sendReportInvitation, setValue: setSampleReportValue, getSampleRecord: sampleRecord, emoticons, interestStatus
    },
    userStore: { isAuthenticated, languagePreference },
    companyStore: { base64Logo, isEnterprisePlus },
    guideStore: { guideSample },
    notificationStore: { setLoading, setNotification, setValue: setNotificationValue }
  } = useStores()

  const [isGuideSample] = React.useState(guideSample)
  const [isShowCupper, setIsShowCupper] = React.useState(false)
  const [isPrinting, setIsPrinting] = React.useState(false)
  const [isFragranceWheel, setIsFragranceWheel] = React.useState(true)
  const [isFlavorWheel, setIsFlavorWheel] = React.useState(true)
  const [anchorShare, setAnchorShare] = React.useState<HTMLButtonElement | null>(null)
  const openShare = Boolean(anchorShare)
  const [openShareEmail, setOpenShareEmail] = React.useState(false)
  const [isSendingEmail, setIsSendingEmail] = React.useState(false)
  const [isEmailError, setIsEmailError] = React.useState(false)
  const [expanded, setExpanded] = React.useState(false)
  const [openNps, setOpenNps] = React.useState(false)
  const [select, setSelect] = React.useState({})
  const [selectAll, setSelectAll] = React.useState(false)
  const [ids, setIds] = React.useState<String[]>([])
  const [selectedIndexExpanded, setSelectedIndexExpanded] = React.useState("")
  const [emailTextHelper, setEmailTextHelper] = React.useState(t('report.emailShareSampleDescription'))
  const [groupDefects, setGroupDefects] = React.useState<any>({})

  const descriptorLabels = allScoreDescriptors ? descriptorSelection(allDescriptor, allScoreDescriptors) : []
  const isSca = cuppingProtocol === 'sca'
  const isScaDescriptive = cuppingProtocol === 'sca_descriptive'
  const isScaAffective = cuppingProtocol === 'sca_affective'
  const isSucafinaTesting = cuppingProtocol === 'sucafina_tasting'

  const reportUrl = window.location.href

  const scoreTable = () => {
    switch (cuppingProtocol) {
      case 'arabica':
        return arabicaScoreTable
      case 'robusta':
        return robustaScoreTable
      case 'cup_of_excellence':
        return coeScoreTable
      case 'sca_descriptive':
        return scaDescriptiveScoreTable
      case 'sca_affective':
      case 'sca':
        return scaScoreTable
      default:
        return []
    }
  }

  const handleSwitchAll = (select: boolean) => {
    setSelectAll(select);
    scores.map(item => {
      handleSwitch(select, item.id)
    })
  };

  const onSelectAll = () => {
    if(selectAll) return ids.length = 0

    scores.map(item => {
      ids.push(item.id)
    })
  }

  const handleSwitch =(select: boolean, id:string)  => {
    setSelect((values) => ({
      ...values,
      [id]: select
    }));
  }

  const onSelect = (id: string) => {
    const newIds = clone(ids)

    if (includes(ids, id)) {
      pull(newIds, id)

    } else {
      newIds.push(id)
    }
    setIds(newIds)
  }

  const renderEmoticon = (value: number) => {
    const emo = find(sucafinaTastingForm, ['value', value])
    if (!emo) return null

    return (
      <emo.emoticon width={24} height={24} />
    )
  }

  const poweredImage = (absolute = false) => {
    return (
      <Box className={clsx(classes.powered, absolute && 'absolute')}>
        <Typography variant='body3' sx={{mr: 0.5}}>{t('report.poweredBy')}</Typography>
        <Logo width={50} height={25} />
      </Box>
    )
  }

  const renderSucafinaTastingForm = () => {
    return(
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap"  sx={{p : 3, justifyContent: 'center'}}>
            {emoticons.map(emoticon => renderEmoticon(emoticon))}
          </Stack>
        </Grid>
      </Grid>
    )
  }

  const shareByEmail = async() => {
    let isCorrectEmails = true
    setIsEmailError(false)
    setEmailTextHelper(t('report.emailShareSampleDescription'))

    split(emails, ',').forEach(email => { if (!validateEmail(trim(email))) isCorrectEmails = false })

    if (!isCorrectEmails) {
      setIsEmailError(true)
      setEmailTextHelper(t('report.error.emailIsInvalid'))
      return
    }

    setSampleReportValue('emails', emails.replace(/\s/g, ""))
    setIsSendingEmail(true)

    try{
      const res = await sendReportInvitation(params.id)
      res.base?.map(message => setNotification(message))

      setOpenShareEmail(false)
      setAnchorShare(null)
    } finally {
      setIsSendingEmail(false)
    }
  }

  const renderEmailModal = (
    <ModalWrapper
      maxWidth='sm'
      open={openShareEmail}
      onClose={() => setOpenShareEmail(false)}
    >
      <Typography variant='h5'>{t('report.shareReport')}</Typography>
      <FormInput
        label={t('report.to')}
        textFieldProps={{
          error: isEmailError,
          value: emails,
          onChange: (e) => setSampleReportValue('emails', e.target.value),
          helperText: emailTextHelper
        }}
      />
      <FormInput
        label={t('report.emailMessage')}
        textFieldProps={{
          multiline: true,
          minRows: 5,
          value: message,
          onChange: (e) => setSampleReportValue('message', e.target.value),
        }}
      />

      <Box sx={{mt: 2, textAlign: 'center'}}>
        <Button
          variant='outlined'
          onClick={() => setOpenShareEmail(false)}
          className='wide'
          sx={{mr: 2}}
        >
          {t('common.cancel')}
        </Button>
        <LoadingButton
          variant='contained'
          onClick={shareByEmail}
          className='wide'
          disabled={!emails && !message}
          loading={isSendingEmail}
        >
          {t('report.send')}
        </LoadingButton>
      </Box>
    </ModalWrapper>
  )

  const renderButtons = (
    <Stack direction='row' spacing={1} >
      {(isCuppingOwner && !isMobile && !isScaDescriptive) &&
        <Button
          size='small'
          variant='contained'
          startIcon={isShowCupper ? <VisibilityOff /> : <Visibility />}
          onClick={() => {
            setIsShowCupper(!isShowCupper)
            setOpenNps(true)
          }
          }
        >
          {isShowCupper ? t('report.hideCupper') : t('report.showCupper')}
        </Button>
      }
      {(isAuthenticated && !isMobile) &&
        <>
          <Button
            size='small'
            variant='contained'
            sx={{ml: 1}}
            onClick={(event) => setAnchorShare(event.currentTarget)}
          >
            {t('report.share')}
          </Button>
          <Popover
            open={openShare}
            anchorEl={anchorShare}
            onClose={() => setAnchorShare(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: {pt: 1, px: 2}
            }}
          >
            <IconButton color='primary' onClick={() => setOpenShareEmail(true)} sx={{pb: 2.5}}>
              <Email sx={{width: 24, height: 24}} />
            </IconButton>
            <FacebookShareButton url={reportUrl} className={classes.socialIcon}>
              <FacebookIcon size={24} round />
            </FacebookShareButton>
            <LinkedinShareButton url={reportUrl} className={classes.socialIcon}>
              <LinkedinIcon size={24} round />
            </LinkedinShareButton>
            <TwitterShareButton url={reportUrl} className={classes.socialIcon}>
              <TwitterIcon size={24} round />
            </TwitterShareButton>
            <WhatsappShareButton title={message} url={reportUrl} className={classes.socialIcon}>
              <WhatsappIcon size={24} round />
            </WhatsappShareButton>
          </Popover>
        </>
      }

      {!isMobile &&
        <>
          <Button
            size='small'
            variant='contained'
            sx={{ ml: 1 }}
            onClick={() => setIsPrinting(true)}
          >
            {t('report.print')}
          </Button>
        </>
      }
    </Stack>
  )

  const renderDescriptionItem = (label: string, value: string) => {
    return (
      <Grid item xs={6}>
        <Typography variant='body1'>{label}</Typography>
        <Typography variant='h6' color='primary'>{value || '-'}</Typography>
      </Grid>
    )
  }

  const renderDescriptionItemMobileVersion = (label: string, value : string) => {
    return (
      <Grid container sx={{ p: 1, borderBottom: 1, borderColor: colors.primary.o25 }}>
        <Grid item xs={6}>
          <Box sx={{ width: '100%', height: '25px', mt: 1 }}>
            <Typography>{label}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ width: '100%', textAlign: 'end', height: '25px', mt: 1 }}>
            <Typography sx={{ mr: 2, color: "#8D57B2" }}>{value || '-'}</Typography>
          </Box>
        </Grid>
      </Grid>
    )
  }

  const renderDescriptions = (
    <Paper elevation={0} variant="outlined" sx={{p: isMobile ? 1 : 4, position: 'relative'}}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={isScaDescriptive ? 12 : 8}>
          {isMobile ?
            <>
              <Grid item xs={12} md={8}>
                {renderDescriptionItemMobileVersion(t('sample.varietals'), sampleRecord?.varietals?.join(', '))}
                {renderDescriptionItemMobileVersion(t('report.cuppingDate'), moment(cuppingDate).format('DD MMMM YYYY'))}
                {renderDescriptionItemMobileVersion(t('sample.country'), sampleRecord?.countryCode)}
                {renderDescriptionItemMobileVersion(t('sample.process'), sampleRecord?.processName)}
                {renderDescriptionItemMobileVersion(t('sample.referenceNumber'), sampleRecord?.referenceNumber)}
                {renderDescriptionItemMobileVersion(t('sample.producer'), sampleRecord?.producerName)}
                {renderDescriptionItemMobileVersion(t('sample.moisture'), sampleRecord?.moisture && `${sampleRecord?.moisture} %`)}
                {renderDescriptionItemMobileVersion(t('sample.waterActivity'), sampleRecord?.waterActivity)}

              </Grid>
            </>
            :
            <>
              <Grid container spacing={2}>
                {renderDescriptionItem(t('sample.species'), startCase(sampleRecord?.species))}
                {renderDescriptionItem(t('sample.cropYear'), sampleRecord?.cropYear)}
                {renderDescriptionItem(t('report.cuppingDate'), moment(cuppingDate).format('DD MMMM YYYY'))}
                {renderDescriptionItem(t('sample.referenceNumber'), sampleRecord?.referenceNumber)}
                {renderDescriptionItem(t('sample.producer'), sampleRecord?.producerName)}
                {renderDescriptionItem(t('sample.country'), sampleRecord?.countryCode)}
                {renderDescriptionItem(t('sample.process'), sampleRecord?.processName)}
                {renderDescriptionItem(t('sample.moisture'), sampleRecord?.moisture && `${sampleRecord?.moisture} %`)}
                {renderDescriptionItem(t('sample.varietals'), sampleRecord?.varietals?.join(', '))}
                {renderDescriptionItem(t('sample.waterActivity'), sampleRecord?.waterActivity)}
              </Grid>
            </>
          }
        </Grid>

        {!isScaDescriptive &&
          <Grid item xs={12} md={4}>
            {isSucafinaTesting &&
              <>
                <Box sx={{textAlign: 'center'}}>
                  {interestStatus.map(item =>
                    <Button
                      size="medium"
                      variant='outlined'
                      sx={{ml : 1}}
                    >
                      {`${item.label === "true" ? t('report.boolean.true'): t('report.boolean.false')} (${item.qty})`}
                    </Button>
                  )}
                </Box>
              </>
            }
            <Box className={classes.totalScoreWrapper}>
              <Typography variant='h4'>{t('sampleScore.totalScore')}</Typography>
              <Typography variant='h1' color='primary'>{sampleRecord?.cachedAverageScore}</Typography>
              {sampleRecord?.status &&
                <Typography
                  variant='h4'
                  className={clsx(sampleRecord.status && classes[sampleRecord.status])}
                >
                  {t(`sample.status.${sampleRecord.status}`)}
                </Typography>
              }
            </Box>
          </Grid>
        }
        {poweredImage(true)}
      </Grid>
    </Paper>
  )

  const renderSpiderWord = (
    <Paper elevation={0} variant="outlined" sx={{p: 4}}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{maxHeight: {md: 600}}}>
          <ReactWordcloud
            words={getCloudWords}
            options={{
              rotations: 1,
              rotationAngles: [0, 0],
              fontSizes: [20, 50],
              enableTooltip: false
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{...centered}}>
          <Radar
            data={getAverageScoresForGraph}
            options={{
              plugins: {
                legend: {
                  display: false
                }
              },
              scales: {
                r: {
                  min: minimumScore,
                  max: maximumScore,
                  pointLabels: {
                    font: {
                      size: 16,
                    }
                  }
                }
              }
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  )

  const renderDefects = (type, defects) => {
    if (!defects) return null

    const total = sumBy(defects, defect => (defect as any).total)
    if (total === 0) return null

    return (
      <Grid item xs={12} md={6}>
        <Table size='small' className={classes.tableDefect}>
          <TableHead>
            <TableRow>
              <TableCell>{startCase(type)}</TableCell>
              <TableCell align='center'>{t('report.count')}</TableCell>
              <TableCell align='center'>{t('report.total')}</TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {defects.map((defect, index) => {
              if (defect.count === 0) return null

              return (
                <TableRow key={index}>
                  <TableCell>{defect.defectType}</TableCell>
                  <TableCell className={classes.primary} align='center'>{defect.count}</TableCell>
                  <TableCell className={classes.primary} align='center'>{defect.total}</TableCell>
                  <TableCell className={classes.primary} align='center'>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align='center'>
                <Typography variant='subtitle1'>{total}</Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
    )
  }

  const renderSessionDetails = (
    <Paper elevation={0} variant="outlined" sx={{p: isMobile ? 2 : 4}}>
      {isSucafinaTesting &&
        <>
          {renderSucafinaTastingForm()}
        </>
      }
      {(isSca || isScaAffective) &&
        <>
          <Box sx={{color: colors.text.o50, fontSize: 'small', fontStyle: 'italic', mb: 1}}>
            <span>{t('report.affectiveScoreBySca')}</span>
            <sup>*</sup>
          </Box>
          <Stack direction="row" spacing={1} sx={{mb: 2, fontStyle: 'italic'}}>
            <Typography variant='subtitle1'>Score / TS =</Typography>
            <Typography variant='subtitle1' color={colors.text.o75}> 0.6525 x</Typography>
            <Typography variant='subtitle1'>(FA + AR + FL + AF + AC +SW + MO + OV)</Typography>
            <Typography variant='subtitle1' color={colors.text.o75}> + 52.75</Typography>
            <Typography variant='subtitle1' color={colors.text.o75}> - 2 x <strong>(NU)</strong></Typography>
            <Typography variant='subtitle1' color={colors.text.o75}> - 4 x <strong>(DC)</strong></Typography>
          </Stack>
        </>
      }
      {!isSucafinaTesting &&
        <>
          <Typography variant='subtitle1'>{t('report.cuppingScores')}:</Typography>
          <TableContainer sx={{ maxWidth: '100%' }}>
            <Table stickyHeader size='small' sx={{my: 2}}>
              <TableHead>
                <TableRow>
                  <TableCell className={clsx('sticky-cell', isMobile && classes.cellMobile)}>
                    <Typography variant='subtitle1'>{t('report.cupper')}</Typography>
                  </TableCell>
                  {scoreTable().map(tc =>
                    <TableCell key={tc.abbr} align="center" className={clsx(isMobile && classes.cellMobile)}>
                      <Typography variant='subtitle1'>{tc.abbr}</Typography>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {scores.map((sc, index) =>
                  <TableRow key={`${cuppingProtocol}${index}`}>
                    <TableCell className={clsx('sticky-cell', isMobile && classes.cellMobile)}>
                      <Typography variant='body3'>
                        {ids[ids.findIndex( x => x === sc.id)] ? sc.cupperName : `${t('report.cupper')} ${index + 1}`}
                      </Typography>
                    </TableCell>
                    {scoreTable().map(tc =>
                      <TableCell key={tc.abbr} align="center" className={clsx(isMobile && classes.cellMobile)}>
                        <Typography variant='body3' color='primary'>
                          {sc[tc.key]}
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{mb: 2}}>
            {scoreTable().map((tc, index) =>
              <React.Fragment key={index}>
                <Typography variant='body3' className={clsx(classes.legend, 'bold')}>{tc.abbr}: </Typography>
                <Typography variant='body3' className={classes.legend}>
                  {t(tc.translation)} {scoreTable().length - 1 !== index && ' - '}
                </Typography>
              </React.Fragment>
            )}
          </Box>
          {(isSca || isScaAffective) &&
            <Box sx={{color: colors.text.o50, fontSize: 'small', fontStyle: 'italic', mb: 1}}>
              <span>{t('report.totalScoreNearestDescription')}</span>
            </Box>
          }
          {!isEmpty(descriptorLabels) &&
            <>
              <Typography variant='subtitle1'>{t('report.descriptors')}:</Typography>
              {descriptorLabels.map((descriptor, index) =>
                <React.Fragment key={index}>
                  <Typography variant='body3' color='primary' fontWeight={500} component='span'>{descriptorTranslation(languagePreference, descriptor)}</Typography>
                  {descriptorLabels.length - 1 !== index && <Typography variant='body3' color='text.disabled'> | </Typography>}
                </React.Fragment>
              )}
            </>
          }
        </>
      }

      {map(scores, 'notes').some(Boolean) &&
        <>
          <Typography variant='subtitle1' sx={{mt: 2}}>{t('descriptor.notes')}:</Typography>
          {scores.map((sc, index) => {
            if (!sc.notes) return null

            return (
              <Grid key={index} container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='body3' fontWeight={500}>
                    {isShowCupper ? sc.cupperName : `${t('report.cupper')} ${index + 1}`}
                  </Typography>
                </Grid>
                <Grid item xs><Typography variant='body3' sx={{wordBreak: 'break-word'}}>{sc.notes}</Typography></Grid>
              </Grid>
            )
          })}
        </>
      }

      {!isEmpty(groupDefects) &&
        <>
          <Typography variant='subtitle1' sx={{mt: 3}}>{t('descriptor.defects')}:</Typography>
          <Grid container spacing={2}>
            {Object.keys(groupDefects).map(key => renderDefects(key, groupDefects[key]))}
          </Grid>
        </>
      }
    </Paper>
  )

  const onGetSampleReport = React.useCallback(
    debounce(async (token) => {
      try{
        setNotificationValue('loading', true)
        await getSampleReport(token)

        const defects = getDefects(searchParams.get('green_grading'))
        setGroupDefects(groupBy(defects, 'category'))
      } catch {
        navigate(-1)
      } finally {
        setNotificationValue('loading', false)
      }
    }, DEBOUNCE_TIME), []
  )

  const renderResultDetailMobileVersion = (
    <Box>
      {(isSca || isScaAffective) &&
        <>
          <Box sx={{color: colors.text.o50, fontSize: 'small', fontStyle: 'italic', mb: 1}}>
            <span>{t('report.affectiveScoreBySca')}</span>
            <sup>*</sup>
          </Box>
          <Grid container>
            <Grid item xs={12} sx={{pb: 1.4}}>
              <Typography variant='subtitle1'>Score / TS = 0.6525 x (FA + AR + FL + AF + AC +SW + MO + OV) + 52.75 - 2 x (NU) - 4 x (DC)</Typography>
            </Grid>
          </Grid>
        </>
      }
      <Typography sx={{p: 1, mt: 1}}>{t("report.cuppingScores")}</Typography>
      {scores.map((sc, i) => {

        return (
          <>
            <Grid container sx={{p:1, borderBottom: 1, borderColor: colors.primary.o25}} key={i}>
              <Grid item xs={10} sx={{pt: 1.4}}>
                <Typography color="#8D57B2">{`Cuppers ${i + 1}`}</Typography>
              </Grid>
              <Grid item xs={2} >
                <IconButton
                  size="medium"
                  onClick={() => {
                    setExpanded(!expanded)
                    if (selectedIndexExpanded === i) {
                      setSelectedIndexExpanded("")
                    } else {
                      setSelectedIndexExpanded(i)
                    }
                  }}
                  sx={{ pl: 3 }}
                >
                  {i === selectedIndexExpanded  ? !isPrinting && <ArrowDropUp sx={{ fontSize: "30px" }} /> : !isPrinting && <ArrowDropDown sx={{ fontSize: "30px" }} />}
                </IconButton>
              </Grid>
              <Grid item xs={12} >
                <Collapse in={i === (isPrinting ? i: selectedIndexExpanded)} timeout="auto" unmountOnExit sx={{ ml: 1, p: isPrinting ? 2:0 }}>
                  {(isShowCupper || isPrinting) &&
                    <>
                      <Grid container justifyContent="space-between" sx={{ ml: -1, borderBottom: 1, borderColor: colors.primary.o25 }}>
                        <Grid item xs={6} >
                          <Box sx={{ width: '100%', height: '30px', mt: 2 }}>
                            <Typography>{t('sample.cuppers')}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box sx={{ width: '100%', textAlign: 'end', height: '30px', mt: 2 }}>
                            <Typography sx={{ mr: 2, color: "#8D57B2" }}>{sc.cupperName}</Typography>
                          </Box>

                        </Grid>

                      </Grid>
                    </>
                  }

                  {scoreTable().map(tc => {
                    return (
                      <Grid container justifyContent="space-between" sx={{ ml: -1, borderBottom: 1, borderColor: colors.primary.o25 }}>
                        <Grid item xs={6} >
                          <Box sx={{ width: '100%', height: '30px', mt: 2 }}>
                            <Typography>{t(tc.translation)}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box sx={{ width: '100%', textAlign: 'end', height: '30px', mt: 2 }}>
                            <Typography sx={{ mr: 2, color: "#8D57B2" }}>  {sc[tc.key]}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    )
                  })}
                  <Grid container justifyContent="space-between" sx={{ml: -1}}>
                    <Grid item xs={12}>
                      <Typography variant='subtitle1' sx={{ mb: 1, mt: 2 }}>{t('report.descriptors')}:</Typography>
                      {descriptorLabels.map((descriptor, index) =>
                        <React.Fragment key={index}>
                          <Typography variant='body3' color='primary' fontWeight={500} component='span'>{descriptorTranslation(languagePreference, descriptor)}</Typography>
                          {descriptorLabels.length - 1 !== index && <Typography variant='body3' color='text.disabled'> | </Typography>}
                        </React.Fragment>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between" sx={{ml: -1, mb: isPrinting ? 5:0}}>
                    <Grid item xs={2} >
                      <Box sx={{width: '100%', height: '30px', mt: 2}}>
                        <Typography>{t('descriptor.notes')}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={9}>
                      <Box sx={{width: '100%', textAlign: 'end',height: '30px',mt: 2}}>
                        <Typography sx={{ color: "#8D57B2"}}>{sc.notes}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
            {(isPrinting && (i + 1) % 2 === 0) &&  <div className="html2pdf__page-break" />}
          </>
        )

      })}
      {!isEmpty(groupDefects) &&
        <>
          <Typography variant='subtitle1' sx={{mt: 2, ml: 1}}>{t('descriptor.defects')}:</Typography>
          <Grid container spacing={2}>
            {Object.keys(groupDefects).map(key => renderDefects(key, groupDefects[key]))}
          </Grid>
        </>
      }
    </Box>
  )

  const renderShareButtonMobileVersion = (
    <Box justifyContent="flex-start" sx={{ flexDirection: 'row', display: 'flex' }}>
      <Box><Typography>{t('sample.share')} :</Typography></Box>
      <Box sx={{mt: -1}}>
        <IconButton color='primary' onClick={() => setOpenShareEmail(true)} sx={{ pb: 3}}>
          <Email sx={{ width: 24, height: 24 }} />
        </IconButton>
        <FacebookShareButton url={reportUrl} className={classes.socialIcon}>
          <FacebookIcon size={24} round />
        </FacebookShareButton>
        <LinkedinShareButton url={reportUrl} className={classes.socialIcon}>
          <LinkedinIcon size={24} round />
        </LinkedinShareButton>
        <TwitterShareButton url={reportUrl} className={classes.socialIcon}>
          <TwitterIcon size={24} round />
        </TwitterShareButton>
        <WhatsappShareButton title={message} url={reportUrl} className={classes.socialIcon}>
          <WhatsappIcon size={24} round />
        </WhatsappShareButton>

      </Box>
    </Box>
  )

  const renderButtonMobileVersion = (
    <AppBar  position="fixed" sx={{ top: 'auto', bottom: 0, backgroundColor: 'white'}}>
      <Grid container spacing={1} sx={{mt: -2, p: 2}}>
        {isCuppingOwner && !isScaDescriptive &&
          <Grid item xs={6}>
            <Button
              fullWidth
              size='small'
              variant='contained'
              startIcon={isShowCupper ? <VisibilityOff /> : <Visibility />}
              onClick={() => setIsShowCupper(!isShowCupper)}
            >
              {isShowCupper ? t('report.hideCupper') : t('report.showCupper')}
            </Button>
          </Grid>
        }

        <Grid item xs={!isScaDescriptive ? 6: 12}>
          <Button
            fullWidth
            size='small'
            variant='contained'
            onClick={() => setIsPrinting(true)}
          >
            <SvgIcon component={IconReportShare} inheritViewBox sx={{ mr: 1, width: 15, height: 15 }} />{t('sample.export')}
          </Button>
        </Grid>
      </Grid>
    </AppBar>
  )

  React.useEffect(() => {
    scores.map(item => {
      handleSwitch(false, item.id)
    })
  }, [])

  React.useEffect(() => {
    if (!guideSample && isGuideSample) navigate(homeLink)
  }, [guideSample])

  React.useEffect(() => {
    if (isPrinting) return

    flavorWheel(getFlavorWheelGroups, isEnterprisePlus && base64Logo)
  }, [getFlavorWheelGroups])

  React.useEffect(() => {
    if (isPrinting) return

    if (isFragranceWheel && isFlavorWheel) {
      flavorWheel(getFlavorWheelGroups, isEnterprisePlus && base64Logo)
      return
    }

    if (isFragranceWheel) {
      flavorWheel(getFlavorWheelOnlyFragrance, isEnterprisePlus && base64Logo)
      return
    }

    flavorWheel(getFlavorWheelOnlyFlavor, isEnterprisePlus && base64Logo)

  }, [isFragranceWheel, isFlavorWheel])

  React.useEffect(() => {
    if (!params.id) return

    onGetSampleReport(params.id)
  }, [languagePreference])

  React.useEffect(() => {
    setSampleReportValue('message', t('report.messageShareSampleDescription', {
      sessionTitle: title
    }))
  }, [title])

  React.useEffect(() => {
    if (!isPrinting || !printElement.current) return

    (async () => {
      try {
        setLoading(true)
        await printPage(printElement.current)
      } finally {
        setIsPrinting(false)
        setLoading(false)
      }
    })()
  }, [isPrinting])

  return (
    <>
      {!isPrinting &&
        <HeaderTitle
          title={title}
          rightContent={renderButtons}
          sx={{mb: '0 !important'}}
          {...(isAuthenticated && {
            dataLinks: [
              {
                backUrl: sampleLink ,
                breadcrumb: t('common.backTo', { menu: t('menu.reviewSamples')}),
              },
              {
                backUrl: sampleInformationLink ,
                breadcrumb: t('common.goTo', { menu: t('menu.sampleInformation')}),
              },
              {
                backUrl: sampleReviewLink ,
                breadcrumb: t('common.goTo', { menu: t('menu.sampleReview')}),
              }
            ]
          })}
        />
      }

      <Box ref={printElement} data-name='sample-report'>
        {isPrinting &&
          <Container>
            <Typography variant='h4'>{title}</Typography>
          </Container>
        }

        <Container sx={{mb: 2, display: 'flex'}}>
          <Typography variant='body2'>{t('report.cuppedUsing')}</Typography>
          &nbsp;
          <Typography variant='h6'>
            {t(`options.cuppingProtocol.${camelCase(cuppingProtocol)}`)}
            &nbsp;
            {t(`cuppingSession.cuppingForm`)}
          </Typography>
        </Container>

        <Container sx={{mb: 2}}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={isScaAffective ? 12 : 8}>
              {(isMobile && isAuthenticated) && !isPrinting && renderShareButtonMobileVersion}
              {renderDescriptions}
            </Grid>
            {!isScaAffective &&
              <Grid item xs={12} md={4}>
                <Box className={classes.wheelWrapper} sx={{minHeight: {xs: 500, md: 300}}}>
                  <div id="tastify-wheel" className={classes.wheel}></div>
                  <Box sx={{textAlign: 'center'}}>
                    {poweredImage()}
                    {!isSucafinaTesting &&
                      <>
                        <FormControlLabel
                          label={`${t('descriptor.fragranceOrAroma')}`}
                          control={
                            <Checkbox
                              size="small"
                              disabled={!isFlavorWheel}
                              checked={isFragranceWheel}
                              onChange={() => setIsFragranceWheel(!isFragranceWheel)}
                            />
                          }
                        />
                        <FormControlLabel
                          label={`${t('descriptor.flavor')}`}
                          control={
                            <Checkbox
                              size="small"
                              disabled={!isFragranceWheel}
                              checked={isFlavorWheel}
                              onChange={() => setIsFlavorWheel(!isFlavorWheel)}
                            />
                          }
                        />

                      </>
                    }
                  </Box>
                </Box>
              </Grid>
            }
          </Grid>
        </Container>

        {(!cuppingProtocol.includes("sca") && !cuppingProtocol.includes("sucafina_tasting")) &&
          <Container sx={{mb: 2}}>
            <Typography variant='h6' sx={{mb: 1}}>{t('report.theCup')}</Typography>
            {renderSpiderWord}
          </Container>
        }

        <div className="html2pdf__page-break" />
        <Container sx={{mb: 6}}>
          <Typography variant='h6' sx={{mb: 1}}>{!isSucafinaTesting && t('report.sessionDetail')}</Typography>
          {isMobile ? renderResultDetailMobileVersion  : renderSessionDetails}
        </Container>

        {isMobile && !isPrinting && !(window as any).isRNWebview && renderButtonMobileVersion}
      </Box>

      {renderEmailModal}

      <ModalWrapper
        open={openNps}
        maxWidth='sm'
      >
        <Grid container spacing={2} textAlign="center">
          <Grid item xs={6}>
            <Typography variant='h6' sx={{mb: 1}}>{t('report.listOfCuppers')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6' sx={{mb: 1, color: colors.primary.main}}>{t('report.show')}</Typography>
          </Grid>
          {scores.map((sc, i) => {
            return (
              <>
                <Grid item xs={6}>
                  <Typography variant='h6' >{sc.cupperName}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Switch
                    checked={select[sc.id]}
                    onChange={(event, checked) => {
                      onSelect(sc.id)
                      handleSwitch(checked, sc.id)
                    }}
                  />
                </Grid>
              </>
            )
          })}
        </Grid>
        <Box display="flex" alignItems="center" justifyContent="center" sx={{mt: 2}}>
          <Button variant='text' onClick={() => {
            handleSwitchAll(true)
            onSelectAll()
          }} sx={{width: 200, mr: 1}}>
            {t('report.showAll')}
          </Button>
          <Button variant='outlined' onClick={() => {
            setOpenNps(false);
            setIsShowCupper(false)
          }} sx={{width: 200, mr: 1}}>
            {t('report.save')}
          </Button>
        </Box>
      </ModalWrapper>
    </>
  )
})
