import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { defectProps } from "./defect.props"

/**
 * Model description here for TypeScript hints.
 */
export const DefectModel = types
  .model("Defect")
  .props({
    ...defectProps,
    count: types.optional(types.number, 0),
  })

/**
* Un-comment the following to omit model attributes from your snapshots (and from async storage).
* Useful for sensitive data like passwords, or transitive state like whether a modal is open.

* Note that you'll need to import `omit` from ramda, which is already included in the project!
*  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
*/

type DefectType = Instance<typeof DefectModel>
export interface Defect extends DefectType {}
type DefectSnapshotType = SnapshotOut<typeof DefectModel>
export interface DefectSnapshot extends DefectSnapshotType {}
