import React from 'react'
import {last, truncate} from "lodash"
import { useTranslation } from "react-i18next"
import {Box, Button, Divider, Grid, MenuItem, Select, Stack, TextField, Typography} from "@mui/material"
import {isMobile} from "react-device-detect"

import {colors} from "assets"
import {Descriptor} from "components"
import {translateDescriptor} from "utils"
import {commercialDefaultScores} from "constants/form"
import { acidityDescriptorList } from 'constants/acidity-descriptors'
import { bodyDescriptorList } from 'constants/body-descriptors'
import { flavorDescriptorList } from 'constants/flavor-descriptors'
import { defectDescriptorList } from 'constants/defect-descriptors'

export const CommercialInformation = (
  {
    score,
    sampleId,
    isEdit,
    setIsEditScore,
    setIsEditDescriptor,
    language,
  }: {
    score: any,
    sampleId: any,
    isEdit: boolean,
    setIsEditScore: () => void,
    setIsEditDescriptor: () => void,
    language: string,
  }) => {
  const { t } = useTranslation()
  const {
    flavor, flavorDescriptors, acidity, acidityDescriptors, body, bodyDescriptors, sweetnessScore, sweetnessDescriptors,
    uniformityDetected, defectDetected, defectsDescriptors, cups, notes, setScoreValue
  } = score

  const truncateDescription = (desc: string) => {
    if (isMobile) return truncate(desc, {
      'length': 50,
      'omission': '...'
    })

    return desc
  }

  const setValue = (key, value) => {
    setScoreValue(key, value)
    setIsEditScore()
  }

  const renderDescriptors = (keyDescriptor, descriptors, descriptorList) => {
    if (isEdit) {
      return (
        <Descriptor
          key={`${keyDescriptor}`}
          descriptorList={descriptorList}
          sampleId={sampleId}
          descriptors = {descriptors}
          addDescriptor={(values) => {
            setScoreValue(keyDescriptor, values)
            setIsEditDescriptor()
          }}
        />
      )
    }

    return (
      <>
        {descriptors.map((descriptor, index) =>
          <React.Fragment key={index}>
            <Typography
              key={index}
              variant='subtitle2'
              component='span'
              color="primary"
              sx={{mr: 1}}
            >
              {translateDescriptor(language, descriptor)}
            </Typography>
            {last(descriptors) !== descriptors[index] &&
              <Divider orientation="vertical" sx={{mr: 1, height: '12px'}} />
            }
          </React.Fragment>
        )}
      </>
    )
  }

  const renderNote = () => {
    return (
      <Box sx={{p: 2, borderBottom: `1px solid ${colors.border.primary}`}}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle2">{t('descriptor.notes')}</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            {isEdit ?
              <TextField
                fullWidth
                multiline
                minRows={4}
                value={notes}
                onChange={(e) => setValue('notes', e.target.value)}
              /> :
              <Typography variant="caption" color="primary">
                {truncateDescription(notes)}
              </Typography>
            }
          </Grid>
        </Grid>
      </Box>
    )
  }

  const renderScore = (key, label, score, keyDescriptor?, descriptors?, descriptorList?) => {
    return (
      <Box sx={{p: 2, borderBottom: `1px solid ${colors.border.primary}`}}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} display='flex' justifyContent='space-between'>
            <Typography variant="subtitle2">{t(label)}</Typography>

            {isEdit ?
              <Select
                autoWidth
                value={score}
                onChange={(e) => setValue(key, e.target.value)}
                size='small'
                sx={{height: 'fit-content'}}
              >
                {commercialDefaultScores.map(score =>
                  <MenuItem value={score} key={score}>
                    {score}
                  </MenuItem>
                )}
              </Select> :
              <Typography variant="subtitle2" color="primary">{score}</Typography>
            }
          </Grid>

          {descriptors &&
            <Grid item xs={12} md={8} display="flex">
              {renderDescriptors(keyDescriptor, descriptors, descriptorList)}
            </Grid>
          }
        </Grid>
      </Box>
    )
  }

  const renderBoolean = (key, label, status, keyDescriptor?, descriptors?, descriptorList?) => {
    return (
      <Box sx={{p: 2, borderBottom: `1px solid ${colors.border.primary}`}}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} display='flex' alignItems="center" justifyContent='space-between'>
            <Typography variant="subtitle2">{t(label)}</Typography>
            <Stack direction="row" spacing={isEdit ? 2 : 0}>
              <Button
                size='small'
                variant={status && isEdit ? 'contained' : 'outlined'}
                sx={{display: !status && !isEdit ? 'none' : 'block'}}
                {...isEdit && {onClick: () => setValue(key, true)}}
              >
                {t('options.boolean.true')}
              </Button>
              <Button
                size='small'
                variant={!status && isEdit ? 'contained' : 'outlined'}
                sx={{display: status && !isEdit ? 'none' : 'block'}}
                {...isEdit && {onClick: () => setValue(key, false)}}
              >
                {t('options.boolean.false')}
              </Button>
            </Stack>
          </Grid>
          {descriptors &&
            <Grid item xs={12} md={8} display="flex">
              {renderDescriptors(keyDescriptor, descriptors, descriptorList)}
            </Grid>
          }
        </Grid>
      </Box>
    )
  }

  return (
    <Box>
      {renderScore('flavor', 'descriptor.flavor', flavor, 'flavorDescriptors', flavorDescriptors, flavorDescriptorList)}
      {renderScore('body', 'descriptor.body', body, 'bodyDescriptors', bodyDescriptors, bodyDescriptorList)}
      {renderScore('acidity', 'descriptor.acidity', acidity, 'acidityDescriptors', acidityDescriptors, acidityDescriptorList)}
      {renderScore('sweetnessScore', 'descriptor.sweetness', sweetnessScore, 'sweetnessDescriptors', sweetnessDescriptors, flavorDescriptorList)}
      {renderBoolean('uniformityDetected', 'descriptor.uniformity', uniformityDetected)}
      {renderBoolean('defectDetected', 'descriptor.defectDetected', defectDetected, 'defectsDescriptors', defectsDescriptors, defectDescriptorList)}
      {renderScore('cups', 'descriptor.numberOfCups', cups)}
      {renderNote()}
    </Box>
  )
}
