import React from "react";
import QRCode from "react-qr-code";
import {Box, Divider, Grid, IconButton, Stack, SvgIcon, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import {camelCase, isEmpty} from "lodash";
import {CopyAllOutlined, PrintOutlined} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import {ModalWrapper} from "components";
import {useTranslation} from "react-i18next";

import {countries} from "utils"
import {colors} from "assets";
import {LogoSucafina} from "assets/images";
import { printPage } from 'utils'

import {PrintLabelProps} from "./print-label.props";


export const PrintLabel: React.FC<PrintLabelProps> = observer((props: PrintLabelProps) => {

  const { open, onClose, dataLabel} = props
  const { t } = useTranslation()
  const printElement = React.createRef<Element | undefined>()

  const [isPrinting, setIsPrinting] = React.useState(false)

  const statusQrCode = isEmpty(dataLabel) ? false : dataLabel[0]['status']

  const onCancel= () => {
    onClose()
  }

  const getValueLabel = (attribute, data) => {

    if(isEmpty(data)) return ""

    switch (attribute) {
      case "sampleType":
        return t(`options.sampleType.${camelCase(data)}`)
      case "species":
        return t(`options.species.${camelCase(data)}`)
      case "moisture":
        return `${data} %`
      case "bagWeight":
        return `${data} kg`
      case "country":
        return countries.filter(x => x.code === data)[0]["name"]
      default:
        return data
    }
  }

  React.useEffect(() => {
    if (!isPrinting || !printElement.current) return

    (async () => {
      try {
        await printPage(printElement.current,true)
      } finally {
        setIsPrinting(false)

      }
    })()
  }, [isPrinting])

  return (
    <ModalWrapper
      maxWidth='md'
      open={open}
      onClose={onCancel}
    >
      <Box ref={printElement} data-name='print-label'  sx={{border: 1, p: 2, mt: 2, borderColor: colors.text.primary}}>
        <Stack
          direction="row-reverse"
          justifyContent="space-around"
          alignItems="center"
          spacing={2}
        >
          {statusQrCode &&
              <div style={{ height: "auto", margin: "0 auto", maxWidth: 300, width: "100%" }}>
                  <QRCode
                      size={150}
                      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                      value={"tes"}
                      viewBox={`0 0 256 256`}
                  />
              </div>
          }
          <SvgIcon component={LogoSucafina} inheritViewBox sx={{height: 150, width: 400}} />
        </Stack>
        <Divider sx={{mt: 4, mb: 4}} />
        <Grid container direction="row" spacing={2}>
          {!isEmpty(dataLabel) && dataLabel.filter(x=> x.status === true && x.attribute !== "qrcode").map((data, index) => {
           return(
             <>
               <Grid item xs={6}>
                 <Typography sx={{ fontWeight: 600 }}>{t(`shipment.sharedInformation.${data.attribute}`)}</Typography>
                 <Typography>{getValueLabel(data.attribute,data.value)}</Typography>
               </Grid>
             </>
           )
          })}
        </Grid>
      </Box>
      <Stack
        direction="row-reverse"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{mt: 3}}
      >
        <IconButton
          size='small'
          color='primary'
          sx={{ mr: 0 }}><SvgIcon component={CopyAllOutlined} inheritViewBox /><Typography variant='h6' color={colors.primary.main}>{t('shipment.button.share')}</Typography>
        </IconButton>
        <LoadingButton
          variant='text'
          color='primary'
          loadingPosition="start"
          startIcon={<SvgIcon component={PrintOutlined} inheritViewBox sx={{width: 25, height: 25}}/>}
          onClick={() => setIsPrinting(true)}
          loading={isPrinting}
        >
          <Typography variant='h6' color={colors.primary.main}>{t('shipment.button.printLabel')}</Typography>
        </LoadingButton>
      </Stack>
    </ModalWrapper>
  )
})