/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-15 18:38:58
 * @modify date 2022-01-15 18:38:58
 * @desc Component for showing the alert messages
 */
import React from 'react'
import { Stack, Alert, AlertTitle } from '@mui/material'

import { StackAlertProps } from './stack-alert.props'

export const StackAlert: React.FC<StackAlertProps> = (props: StackAlertProps) => { 
  const { alerts, ...rest } = props

  const renderAlert = (alert, index: number) => {
    const severity = alert.severity || "error"
    
    return (
      <Alert severity={severity} key={index} {...rest}>
        {alert.title && <AlertTitle sx={{fontWeight: 700}}>{alert.title}</AlertTitle>}
        
        {alert.message}
      </Alert>
    )
  }

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      {alerts.map((alert, index) => renderAlert(alert, index))}
    </Stack>
  )
}