import { showAllLanguages } from 'config/setting'

export const LANGUANGES = {
  en: 'English',
  es: 'Español',
  ko: '한국어',
  id: 'Bahasa',
  ...(showAllLanguages && {
    pt: 'Português',
    ja: '日本語',
    'zh-CN': '中文',
    'fr-CA': 'Français (CA)',
  })
}
