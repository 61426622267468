/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-22 02:07:44
 * @modify date 2022-04-22 02:07:44
 * @desc [description]
 */

import { types } from 'mobx-state-tree'

export const Subscription = types.model({
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  price: types.maybeNull(types.number),
  currency: types.maybeNull(types.string),
  billingInterval: types.maybeNull(types.string),
  trialPeriodDays: types.maybeNull(types.number),
})