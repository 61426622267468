/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-18 22:11:48
 * @modify date 2022-04-18 22:11:48
 */

export const withCuppingSessionFormViews = (self: any) => ({
  views: {
    get guestInvitationPayload() {
      return self.guestInvitations.map(invitation =>  { 
        return {
          "id": invitation.id,
          "guest_name": invitation.guestName,
          "guest_email": invitation.guestEmail
        }
      })
    }
  }
})