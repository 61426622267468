export const defectDescriptorList = [{
  "id": 1,
  "label": "sweaty",
  "positive": 0,
  "color": "#CED689",
  "label_es": "sudoroso",
  "selected": false,
  "open": 1,
  "flavor_type": "defect",
  "zoomed": 0,
  "label_pt": "suado",
  "weight": 1,
  "parent_id": null,
  "label_zh-CN": "汗味",
  "label_zh-HK": null,
  "label_fr-CA": "âpre",
  "label_ko": "땀에 젖은",
  "label_id": "berkeringat",
  "label_ja": "汗のような",
  "groups": [{
    "id": 2,
    "label": "butyric acid",
    "positive": 0,
    "color": "#CED689",
    "label_es": "ácido butírico",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "ácido butírico",
    "weight": 1,
    "parent_id": 1,
    "label_zh-CN": "丁酸",
    "label_zh-HK": null,
    "label_fr-CA": "acide butyrique",
    "label_ko": "썩은 악취",
    "label_id": "asam butirat",
    "label_ja": "酪酸",
    "groups": []
  }, {
    "id": 3,
    "label": "soapy",
    "positive": 0,
    "color": "#CED689",
    "label_es": "jabonoso",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "ensaboado",
    "weight": 1,
    "parent_id": 1,
    "label_zh-CN": "肥皂味",
    "label_zh-HK": null,
    "label_fr-CA": "savonneux",
    "label_ko": "비누냄새",
    "label_id": "rasa sabun",
    "label_ja": "石けんのような",
    "groups": []
  }, {
    "id": 4,
    "label": "lactic",
    "positive": 0,
    "color": "#CED689",
    "label_es": "láctico",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "láctico",
    "weight": 1,
    "parent_id": 1,
    "label_zh-CN": "乳酸",
    "label_zh-HK": null,
    "label_fr-CA": "lactique",
    "label_ko": "끈적이는 강한 신맛",
    "label_id": "laktat",
    "label_ja": "乳製品のような",
    "groups": []
  }]
}, {
  "id": 5,
  "label": "hidy",
  "positive": 0,
  "color": "#83C38F",
  "label_es": "piel",
  "selected": false,
  "open": 1,
  "flavor_type": "defect",
  "zoomed": 0,
  "label_pt": "pele",
  "weight": 1,
  "parent_id": null,
  "label_zh-CN": "皮革味",
  "label_zh-HK": null,
  "label_fr-CA": "cuir",
  "label_ko": "기름 냄새",
  "label_id": "tersembunyi/leathery",
  "label_ja": "獣皮のような",
  "groups": [{
    "id": 6,
    "label": "tallowy",
    "positive": 0,
    "color": "#83C38F",
    "label_es": "seboso",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "seboso",
    "weight": 1,
    "parent_id": 5,
    "label_zh-CN": "油腻",
    "label_zh-HK": null,
    "label_fr-CA": "suif",
    "label_ko": "동물기름 냄새",
    "label_id": "gemuk",
    "label_ja": "獣脂のような",
    "groups": []
  }, {
    "id": 7,
    "label": "leather like",
    "positive": 0,
    "color": "#83C38F",
    "label_es": "cuero",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "couro",
    "weight": 1,
    "parent_id": 5,
    "label_zh-CN": "类皮革",
    "label_zh-HK": null,
    "label_fr-CA": "cuir",
    "label_ko": "가죽 같은",
    "label_id": "seperti kulit",
    "label_ja": "革製品のような",
    "groups": []
  }, {
    "id": 8,
    "label": "wet wool",
    "positive": 0,
    "color": "#83C38F",
    "label_es": "lana mojada",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "lã molhada",
    "weight": 1,
    "parent_id": 5,
    "label_zh-CN": "湿羊毛",
    "label_zh-HK": null,
    "label_fr-CA": "laine mouillé",
    "label_ko": "젖은 양털",
    "label_id": "wol basah",
    "label_ja": "濡れた羊毛",
    "groups": []
  }]
}, {
  "id": 9,
  "label": "horsey",
  "positive": 0,
  "color": "#01A59C",
  "label_es": "caballo",
  "selected": false,
  "open": 1,
  "flavor_type": "defect",
  "zoomed": 0,
  "label_pt": "cavalo",
  "weight": 1,
  "parent_id": null,
  "label_zh-CN": "马味",
  "label_zh-HK": null,
  "label_fr-CA": "laine",
  "label_ko": "말 냄새",
  "label_id": "kaku",
  "label_ja": "馬のような",
  "groups": [{
    "id": 10,
    "label": "hircine",
    "positive": 0,
    "color": "#01A59C",
    "label_es": "hircine",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "hircine",
    "weight": 1,
    "parent_id": 9,
    "label_zh-CN": "羊膻味",
    "label_zh-HK": null,
    "label_fr-CA": "hircine",
    "label_ko": "염소냄새",
    "label_id": "bau kambing",
    "label_ja": "ヤギのような",
    "groups": []
  }, {
    "id": 11,
    "label": "cooked beef",
    "positive": 0,
    "color": "#01A59C",
    "label_es": "carne cocida",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "carne cozida",
    "weight": 1,
    "parent_id": 9,
    "label_zh-CN": "熟牛肉",
    "label_zh-HK": null,
    "label_fr-CA": "boeuf cuit",
    "label_ko": "익은 소고기 냄새",
    "label_id": "daging sapi masak",
    "label_ja": "調理牛肉",
    "groups": []
  }, {
    "id": 12,
    "label": "gamey",
    "positive": 0,
    "color": "#01A59C",
    "label_es": "caza",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "caça",
    "weight": 1,
    "parent_id": 9,
    "label_zh-CN": "腥味",
    "label_zh-HK": null,
    "label_fr-CA": "gibier",
    "label_ko": "조류시체 냄새",
    "label_id": "gamey/bau daging diburu",
    "label_ja": "ジビエ（狩獣肉）のような",
    "groups": []
  }]
}, {
  "id": 13,
  "label": "fermented",
  "positive": 0,
  "color": "#00A9CC",
  "label_es": "fermentado",
  "selected": false,
  "open": 1,
  "flavor_type": "defect",
  "zoomed": 0,
  "label_pt": "fermentados",
  "weight": 1,
  "parent_id": null,
  "label_zh-CN": "发酵",
  "label_zh-HK": null,
  "label_fr-CA": "fermenté",
  "label_ko": "발효취",
  "label_id": "habil fermentasi",
  "label_ja": "発酵",
  "groups": [{
    "id": 14,
    "label": "coffee pulp",
    "positive": 0,
    "color": "#00A9CC",
    "label_es": "pulpa de café",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "polpa de café",
    "weight": 1,
    "parent_id": 13,
    "label_zh-CN": "咖啡果肉",
    "label_zh-HK": null,
    "label_fr-CA": "coffee pulp",
    "label_ko": "커피 과육",
    "label_id": "ampas/selulosa kopi",
    "label_ja": "コーヒーパルプのような",
    "groups": []
  }, {
    "id": 15,
    "label": "acerbic",
    "positive": 0,
    "color": "#00A9CC",
    "label_es": "acerbo",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "mordaz",
    "weight": 1,
    "parent_id": 13,
    "label_zh-CN": "尖酸",
    "label_zh-HK": null,
    "label_fr-CA": "acerbe",
    "label_ko": "시큼한 맛",
    "label_id": "tajam",
    "label_ja": "すっぱい",
    "groups": []
  }, {
    "id": 16,
    "label": "leesy",
    "positive": 0,
    "color": "#00A9CC",
    "label_es": "sedimentos de vino",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "sedimentos de vinho",
    "weight": 1,
    "parent_id": 13,
    "label_zh-CN": "酒渣",
    "label_zh-HK": null,
    "label_fr-CA": "winy",
    "label_ko": "술 앙금 찌꺼기 맛",
    "label_id": "krimi",
    "label_ja": "おり（ワイン粕）のような",
    "groups": []
  }]
}, {
  "id": 17,
  "label": "rioy",
  "positive": 0,
  "color": "#029DDF",
  "label_es": "rio",
  "selected": false,
  "open": 1,
  "flavor_type": "defect",
  "zoomed": 0,
  "label_pt": "rio",
  "weight": 1,
  "parent_id": null,
  "label_zh-CN": "里约味",
  "label_zh-HK": null,
  "label_fr-CA": "rioy",
  "label_ko": "리오",
  "label_id": "rioy",
  "label_ja": "リオ臭",
  "groups": [{
    "id": 18,
    "label": "iodine",
    "positive": 0,
    "color": "#029DDF",
    "label_es": "yodo",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "iodo",
    "weight": 1,
    "parent_id": 17,
    "label_zh-CN": "碘味",
    "label_zh-HK": null,
    "label_fr-CA": "iode",
    "label_ko": "요오드",
    "label_id": "yodium",
    "label_ja": "ヨウ素、ヨード臭、ヨードチンキ臭",
    "groups": []
  }, {
    "id": 19,
    "label": "carbolic",
    "positive": 0,
    "color": "#029DDF",
    "label_es": "carbólico",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "carbólico",
    "weight": 1,
    "parent_id": 17,
    "label_zh-CN": "碳味",
    "label_zh-HK": null,
    "label_fr-CA": "carbolique",
    "label_ko": "석탄산",
    "label_id": "karbol",
    "label_ja": "石炭酸（フェノール）のような",
    "groups": []
  }, {
    "id": 20,
    "label": "acrid",
    "positive": 0,
    "color": "#029DDF",
    "label_es": "acre",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "pungente",
    "weight": 1,
    "parent_id": 17,
    "label_zh-CN": "刺鼻",
    "label_zh-HK": null,
    "label_fr-CA": "âcre",
    "label_ko": "매캐한",
    "label_id": "tajam/sengit",
    "label_ja": "いがらっぽいすっぱさ",
    "groups": []
  }]
}, {
  "id": 21,
  "label": "rubbery",
  "positive": 0,
  "color": "#0193CF",
  "label_es": "caucho",
  "selected": false,
  "open": 1,
  "flavor_type": "defect",
  "zoomed": 0,
  "label_pt": "borracha",
  "weight": 1,
  "parent_id": null,
  "label_zh-CN": "橡胶味",
  "label_zh-HK": null,
  "label_fr-CA": "caoutchouteux",
  "label_ko": "고무 냄새",
  "label_id": "rasa karet",
  "label_ja": "ゴム臭",
  "groups": [{
    "id": 22,
    "label": "butyl phenol",
    "positive": 0,
    "color": "#0193CF",
    "label_es": "butil fenol",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "butil fenol",
    "weight": 1,
    "parent_id": 21,
    "label_zh-CN": "丁酚",
    "label_zh-HK": null,
    "label_fr-CA": "butyl phenol",
    "label_ko": "부틸페놀",
    "label_id": "butil fenol",
    "label_ja": "ブチルフェノール（ゴムタイヤのような）",
    "groups": []
  }, {
    "id": 23,
    "label": "kerosene",
    "positive": 0,
    "color": "#0193CF",
    "label_es": "queroseno",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "querosene",
    "weight": 1,
    "parent_id": 21,
    "label_zh-CN": "煤油",
    "label_zh-HK": null,
    "label_fr-CA": "kérosène",
    "label_ko": "등유",
    "label_id": "minyak tanah",
    "label_ja": "灯油",
    "groups": []
  }, {
    "id": 24,
    "label": "ethanol",
    "positive": 0,
    "color": "#0193CF",
    "label_es": "etanol",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "etanol",
    "weight": 1,
    "parent_id": 21,
    "label_zh-CN": "乙醇",
    "label_zh-HK": null,
    "label_fr-CA": "éthanol",
    "label_ko": "에탄올",
    "label_id": "etanol",
    "label_ja": "エタノール",
    "groups": []
  }]
}, {
  "id": 25,
  "label": "grassy",
  "positive": 0,
  "color": "#9C8AB4",
  "label_es": "herboso",
  "selected": false,
  "open": 1,
  "flavor_type": "defect",
  "zoomed": 0,
  "label_pt": "gramíneo",
  "weight": 1,
  "parent_id": null,
  "label_zh-CN": "草味",
  "label_zh-HK": null,
  "label_fr-CA": "herb",
  "label_ko": "풀 냄새",
  "label_id": "rasa rerumputan",
  "label_ja": "草のような",
  "groups": [{
    "id": 26,
    "label": "green",
    "positive": 0,
    "color": "#9C8AB4",
    "label_es": "verde",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "verde",
    "weight": 1,
    "parent_id": 25,
    "label_zh-CN": "蔬菜",
    "label_zh-HK": null,
    "label_fr-CA": "vert",
    "label_ko": "신선한 풋내",
    "label_id": "dedauanan segar",
    "label_ja": "青臭い",
    "groups": []
  }, {
    "id": 27,
    "label": "hay",
    "positive": 0,
    "color": "#9C8AB4",
    "label_es": "heno",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "feno",
    "weight": 1,
    "parent_id": 25,
    "label_zh-CN": "干草",
    "label_zh-HK": null,
    "label_fr-CA": "foin",
    "label_ko": "건초",
    "label_id": "jerami",
    "label_ja": "干し草",
    "groups": []
  }, {
    "id": 28,
    "label": "strawy",
    "positive": 0,
    "color": "#9C8AB4",
    "label_es": "paja",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "palha",
    "weight": 1,
    "parent_id": 25,
    "label_zh-CN": "稻草",
    "label_zh-HK": null,
    "label_fr-CA": "paille",
    "label_ko": "밀짚",
    "label_id": "tekstur jerami",
    "label_ja": "わらのような",
    "groups": []
  }]
}, {
  "id": 29,
  "label": "aged",
  "positive": 0,
  "color": "#B181AB",
  "label_es": "viejo",
  "selected": false,
  "open": 1,
  "flavor_type": "defect",
  "zoomed": 0,
  "label_pt": "velho",
  "weight": 1,
  "parent_id": null,
  "label_zh-CN": "老旧",
  "label_zh-HK": null,
  "label_fr-CA": "vieilli",
  "label_ko": "오래된",
  "label_id": "lama/tua",
  "label_ja": "古びた",
  "groups": [{
    "id": 30,
    "label": "full",
    "positive": 0,
    "color": "#B181AB",
    "label_es": "lleno",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "pleno",
    "weight": 1,
    "parent_id": 29,
    "label_zh-CN": "丰富",
    "label_zh-HK": null,
    "label_fr-CA": "beaucoup",
    "label_ko": "가득 찬",
    "label_id": "penuh",
    "label_ja": "十分の熟成した",
    "groups": []
  }, {
    "id": 31,
    "label": "rounded",
    "positive": 0,
    "color": "#B181AB",
    "label_es": "redondeado",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "arredondado",
    "weight": 1,
    "parent_id": 29,
    "label_zh-CN": "圆润",
    "label_zh-HK": null,
    "label_fr-CA": "median",
    "label_ko": "특징없이 완만한",
    "label_id": "utuh",
    "label_ja": "角がとれた",
    "groups": []
  }, {
    "id": 32,
    "label": "smooth",
    "positive": 0,
    "color": "#B181AB",
    "label_es": "suave",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "suave",
    "weight": 1,
    "parent_id": 29,
    "label_zh-CN": "顺滑",
    "label_zh-HK": null,
    "label_fr-CA": "faible",
    "label_ko": "부드러운",
    "label_id": "halus",
    "label_ja": "なめらか",
    "groups": []
  }]
}, {
  "id": 33,
  "label": "woody",
  "positive": 0,
  "color": "#9E6F9D",
  "label_es": "maderozo",
  "selected": false,
  "open": 1,
  "flavor_type": "defect",
  "zoomed": 0,
  "label_pt": "arborizado",
  "weight": 1,
  "parent_id": null,
  "label_zh-CN": "木头味",
  "label_zh-HK": null,
  "label_fr-CA": "boisé",
  "label_ko": "나무냄새",
  "label_id": "rasa kekayuan",
  "label_ja": "木質臭",
  "groups": [{
    "id": 34,
    "label": "wet paper",
    "positive": 0,
    "color": "#9E6F9D",
    "label_es": "papel mojado",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "papel molhado",
    "weight": 1,
    "parent_id": 33,
    "label_zh-CN": "湿纸味",
    "label_zh-HK": null,
    "label_fr-CA": "papier mouillé",
    "label_ko": "젖은 종이",
    "label_id": "kertas basah",
    "label_ja": "ねれた紙",
    "groups": []
  }, {
    "id": 35,
    "label": "wet cardboard",
    "positive": 0,
    "color": "#9E6F9D",
    "label_es": "cartón mojado",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "cartão molhado",
    "weight": 1,
    "parent_id": 33,
    "label_zh-CN": "湿纸板",
    "label_zh-HK": null,
    "label_fr-CA": "carton mouillé",
    "label_ko": "젖은 상자",
    "label_id": "kardus basah",
    "label_ja": "ぬれたダンボール",
    "groups": []
  }, {
    "id": 36,
    "label": "filter pad",
    "positive": 0,
    "color": "#9E6F9D",
    "label_es": "filtro de papel",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "filtro de papel",
    "weight": 1,
    "parent_id": 33,
    "label_zh-CN": "过滤垫",
    "label_zh-HK": null,
    "label_fr-CA": "papier filter",
    "label_ko": "필터 패드",
    "label_id": "filter pad",
    "label_ja": "ぶ厚いろ紙",
    "groups": []
  }]
}, {
  "id": 37,
  "label": "baked",
  "positive": 0,
  "color": "#B02964",
  "label_es": "horneado",
  "selected": false,
  "open": 1,
  "flavor_type": "defect",
  "zoomed": 0,
  "label_pt": "cozido",
  "weight": 1,
  "parent_id": null,
  "label_zh-CN": "烘焙味",
  "label_zh-HK": null,
  "label_fr-CA": "cuit",
  "label_ko": "향기없이 누른 내",
  "label_id": "terpanggang",
  "label_ja": "ゆっくり焼かれた、ベークド",
  "groups": [{
    "id": 38,
    "label": "dull",
    "positive": 0,
    "color": "#B02964",
    "label_es": "apagado",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "topado",
    "weight": 1,
    "parent_id": 37,
    "label_zh-CN": "呆板",
    "label_zh-HK": null,
    "label_fr-CA": "terne",
    "label_ko": "흐릿한",
    "label_id": "membosankan",
    "label_ja": "気の抜けた",
    "groups": []
  }, {
    "id": 39,
    "label": "flat",
    "positive": 0,
    "color": "#B02964",
    "label_es": "plano",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "plano",
    "weight": 1,
    "parent_id": 37,
    "label_zh-CN": "平淡",
    "label_zh-HK": null,
    "label_fr-CA": "flat",
    "label_ko": "향기가 약한",
    "label_id": "datar",
    "label_ja": "フラット、平坦な",
    "groups": []
  }, {
    "id": 40,
    "label": "bakey",
    "positive": 0,
    "color": "#B02964",
    "label_es": "bakey",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "bakey",
    "weight": 1,
    "parent_id": 37,
    "label_zh-CN": "焙烤味",
    "label_zh-HK": null,
    "label_fr-CA": "baked",
    "label_ko": "김빠진 맛",
    "label_id": "rasa panggang",
    "label_ja": "パンのように焼けた",
    "groups": []
  }]
}, {
  "id": 41,
  "label": "scorched",
  "positive": 0,
  "color": "#C56A69",
  "label_es": "quemado",
  "selected": false,
  "open": 1,
  "flavor_type": "defect",
  "zoomed": 0,
  "label_pt": "chamuscado",
  "weight": 1,
  "parent_id": null,
  "label_zh-CN": "焦味",
  "label_zh-HK": null,
  "label_fr-CA": "brûlé",
  "label_ko": "그을은",
  "label_id": "hangus",
  "label_ja": "外側が焦げた",
  "groups": [{
    "id": 42,
    "label": "cooked",
    "positive": 0,
    "color": "#C56A69",
    "label_es": "cocido",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "cozinhado",
    "weight": 1,
    "parent_id": 41,
    "label_zh-CN": "烹饪味",
    "label_zh-HK": null,
    "label_fr-CA": "cuites",
    "label_ko": "산미와 맛이 빈약한",
    "label_id": "matang",
    "label_ja": "加熱調理された",
    "groups": []
  }, {
    "id": 43,
    "label": "charred",
    "positive": 0,
    "color": "#C56A69",
    "label_es": "carbonizado",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "carbonizado",
    "weight": 1,
    "parent_id": 41,
    "label_zh-CN": "烧焦味",
    "label_zh-HK": null,
    "label_fr-CA": "carbonisé",
    "label_ko": "숯이 된",
    "label_id": "hangus",
    "label_ja": "黒く焦げた、炭化した",
    "groups": []
  }, {
    "id": 44,
    "label": "empyreumatic",
    "positive": 0,
    "color": "#C56A69",
    "label_es": "empireumático",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "empireumático",
    "weight": 1,
    "parent_id": 41,
    "label_zh-CN": "焦臭",
    "label_zh-HK": null,
    "label_fr-CA": "phénol",
    "label_ko": "탄 냄새",
    "label_id": "bau organik gosong",
    "label_ja": "真っ黒焦げの、消し炭のような、燃えかすのような",
    "groups": []
  }]
}, {
  "id": 45,
  "label": "tipped",
  "positive": 0,
  "color": "#E79472",
  "label_es": "tipped",
  "selected": false,
  "open": 1,
  "flavor_type": "defect",
  "zoomed": 0,
  "label_pt": "tipped",
  "weight": 1,
  "parent_id": null,
  "label_zh-CN": "焦尖",
  "label_zh-HK": null,
  "label_fr-CA": "almidon",
  "label_ko": "약한 탄내",
  "label_id": "berujung",
  "label_ja": "焦げが混じった、豆の先端がこげた",
  "groups": [{
    "id": 46,
    "label": "cereal like",
    "positive": 0,
    "color": "#E79472",
    "label_es": "cereal",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "cereal",
    "weight": 1,
    "parent_id": 45,
    "label_zh-CN": "类谷物",
    "label_zh-HK": null,
    "label_fr-CA": "céréale",
    "label_ko": "곡물 같은",
    "label_id": "seperti sereal",
    "label_ja": "シリアルのような",
    "groups": []
  }, {
    "id": 47,
    "label": "biscuity",
    "positive": 0,
    "color": "#E79472",
    "label_es": "biscuity",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "biscuity",
    "weight": 1,
    "parent_id": 45,
    "label_zh-CN": "饼干",
    "label_zh-HK": null,
    "label_fr-CA": "biscuit",
    "label_ko": "비스킷 같은",
    "label_id": "seperti biskuit",
    "label_ja": "ビスケットのような",
    "groups": []
  }, {
    "id": 48,
    "label": "skunky",
    "positive": 0,
    "color": "#E79472",
    "label_es": "zorrillo",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "jaritataca",
    "weight": 1,
    "parent_id": 45,
    "label_zh-CN": "臭鼬",
    "label_zh-HK": null,
    "label_fr-CA": "mouffette",
    "label_ko": "유황의 불쾌한 냄새",
    "label_id": "tengik",
    "label_ja": "悪臭がする",
    "groups": []
  }]
}, {
  "id": 49,
  "label": "baggy",
  "positive": 0,
  "color": "#EDB383",
  "label_es": "yute",
  "selected": false,
  "open": 1,
  "flavor_type": "defect",
  "zoomed": 0,
  "label_pt": "jute",
  "weight": 1,
  "parent_id": null,
  "label_zh-CN": "麻袋味",
  "label_zh-HK": null,
  "label_fr-CA": "baggy",
  "label_ko": "커피포대 냄새",
  "label_id": "seperti karung",
  "label_ja": "麻袋・ドンゴロスのような",
  "groups": [{
    "id": 50,
    "label": "carvacrol",
    "positive": 0,
    "color": "#EDB383",
    "label_es": "carvacrol",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "carvacrol",
    "weight": 1,
    "parent_id": 49,
    "label_zh-CN": "香芹酚",
    "label_zh-HK": null,
    "label_fr-CA": "jute",
    "label_ko": "카르바크롤",
    "label_ja": "カルバクロール（オレガノ油のような）",
    "groups": []
  }, {
    "id": 51,
    "label": "fatty",
    "positive": 0,
    "color": "#EDB383",
    "label_es": "graso",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "gorduroso",
    "weight": 1,
    "parent_id": 49,
    "label_zh-CN": "肥腻",
    "label_zh-HK": null,
    "label_fr-CA": "oileux",
    "label_ko": "지방이 많은",
    "label_id": "berlemak",
    "label_ja": "脂肪っぽい",
    "groups": []
  }, {
    "id": 52,
    "label": "mineral oil",
    "positive": 0,
    "color": "#EDB383",
    "label_es": "aceite mineral",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "óleo mineral",
    "weight": 1,
    "parent_id": 49,
    "label_zh-CN": "矿物油",
    "label_zh-HK": null,
    "label_fr-CA": "huile minérale",
    "label_ko": "석유, 파라핀 오일",
    "label_id": "minyak mineral",
    "label_ja": "鉱油",
    "groups": []
  }]
}, {
  "id": 53,
  "label": "moldy",
  "positive": 0,
  "color": "#EFC27E",
  "label_es": "mohoso",
  "selected": false,
  "open": 1,
  "flavor_type": "defect",
  "zoomed": 0,
  "label_pt": "mofado",
  "weight": 1,
  "parent_id": null,
  "label_zh-CN": "霉变",
  "label_zh-HK": null,
  "label_fr-CA": "moisi",
  "label_ko": "곰팡이 냄새",
  "label_id": "berjamur/lapuk",
  "label_ja": "カビ臭",
  "groups": [{
    "id": 54,
    "label": "yeasty",
    "positive": 0,
    "color": "#EFC27E",
    "label_es": "levadura",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "levedura",
    "weight": 1,
    "parent_id": 53,
    "label_zh-CN": "酵母",
    "label_zh-HK": null,
    "label_fr-CA": "levure",
    "label_ko": "이스트 냄새(효모발효)",
    "label_id": "beragi",
    "label_ja": "イースト（酵母）のような",
    "groups": []
  }, {
    "id": 55,
    "label": "starchy",
    "positive": 0,
    "color": "#EFC27E",
    "label_es": "amiláceo",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "empertigado",
    "weight": 1,
    "parent_id": 53,
    "label_zh-CN": "淀粉",
    "label_zh-HK": null,
    "label_fr-CA": "fécule",
    "label_ko": "전분",
    "label_id": "bertepung",
    "label_ja": "でんぷん質の",
    "groups": []
  }, {
    "id": 56,
    "label": "cappy",
    "positive": 0,
    "color": "#EFC27E",
    "label_es": "cappy",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "cappy",
    "weight": 1,
    "parent_id": 53,
    "label_zh-CN": "臭奶酪",
    "label_zh-HK": null,
    "label_fr-CA": "cappy",
    "label_ko": "치즈 발효취",
    "label_id": "cappy (juice)",
    "label_ja": "牛乳キャップのような",
    "groups": []
  }]
}, {
  "id": 57,
  "label": "musty",
  "positive": 0,
  "color": "#F5D87C",
  "label_es": "rancio",
  "selected": false,
  "open": 1,
  "flavor_type": "defect",
  "zoomed": 0,
  "label_pt": "rançoso",
  "weight": 1,
  "parent_id": null,
  "label_zh-CN": "发霉",
  "label_zh-HK": null,
  "label_fr-CA": "pourri",
  "label_ko": "퀴퀴한 냄새",
  "label_id": "apek",
  "label_ja": "カビのような",
  "groups": [{
    "id": 58,
    "label": "concrete",
    "positive": 0,
    "color": "#F5D87C",
    "label_es": "concreto",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "concreto",
    "weight": 1,
    "parent_id": 57,
    "label_zh-CN": "混凝土",
    "label_zh-HK": null,
    "label_fr-CA": "ciment",
    "label_ko": "콘크리트",
    "label_id": "jelas",
    "label_ja": "コンクリート",
    "groups": []
  }, {
    "id": 59,
    "label": "mildewy",
    "positive": 0,
    "color": "#F5D87C",
    "label_es": "mildiu",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "míldio",
    "weight": 1,
    "parent_id": 57,
    "label_zh-CN": "发霉",
    "label_zh-HK": null,
    "label_fr-CA": "moisissure",
    "label_ko": "흰곰팡이가 난",
    "label_id": "berjamur",
    "label_ja": "かびの生えた",
    "groups": []
  }, {
    "id": 60,
    "label": "mulch like",
    "positive": 0,
    "color": "#F5D87C",
    "label_es": "casca",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "casca",
    "weight": 1,
    "parent_id": 57,
    "label_zh-CN": "类似护根物",
    "label_zh-HK": null,
    "label_fr-CA": "paillis",
    "label_ko": "(농사 시)흙을 덮는 짚, 톱밥 등의 덮개 같은",
    "label_id": "seperti mulsa",
    "label_ja": "根覆いのような、敷きわらのような",
    "groups": []
  }, {
    "id": 73,
    "label": "petrichor",
    "positive": 0,
    "color": "#FFDF46",
    "label_es": "petricor",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "petrichor",
    "weight": 1,
    "parent_id": 65,
    "label_zh-CN": "彼得里科尔",
    "label_zh-HK": null,
    "label_fr-CA": "petrichor",
    "label_ko": "페트리코르",
    "label_id": "petrichor",
    "label_ja": "ペトリコール",
    "groups": []
  }]
}, {
  "id": 61,
  "label": "dirty",
  "positive": 0,
  "color": "#FAE47E",
  "label_es": "sucio",
  "selected": false,
  "open": 1,
  "flavor_type": "defect",
  "zoomed": 0,
  "label_pt": "sujo",
  "weight": 1,
  "parent_id": null,
  "label_zh-CN": "泥土味",
  "label_zh-HK": null,
  "label_fr-CA": "sales",
  "label_ko": "지저분한",
  "label_id": "kotor",
  "label_ja": "土ぼこり、汚れた",
  "groups": [{
    "id": 62,
    "label": "dusty",
    "positive": 0,
    "color": "#FAE47E",
    "label_es": "polvoriento",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "poeirento",
    "weight": 1,
    "parent_id": 61,
    "label_zh-CN": "灰尘",
    "label_zh-HK": null,
    "label_fr-CA": "poussiéreux",
    "label_ko": "흙먼지",
    "label_id": "berdebu",
    "label_ja": "ほこり臭い",
    "groups": []
  }, {
    "id": 63,
    "label": "grady",
    "positive": 0,
    "color": "#FAE47E",
    "label_es": "grady",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "grady",
    "weight": 1,
    "parent_id": 61,
    "label_zh-CN": "混沌的",
    "label_zh-HK": null,
    "label_fr-CA": "liege",
    "label_ko": "뒤죽박죽 결점두가 있는",
    "label_id": "grady",
    "label_ja": "庭のような、ほこりっぽい",
    "groups": []
  }, {
    "id": 64,
    "label": "barny",
    "positive": 0,
    "color": "#FAE47E",
    "label_es": "establo",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "celeiro",
    "weight": 1,
    "parent_id": 61,
    "label_zh-CN": "谷仓",
    "label_zh-HK": null,
    "label_fr-CA": "étable",
    "label_ko": "외양간",
    "label_id": "barny",
    "label_ja": "納屋のような",
    "groups": []
  }]
}, {
  "id": 65,
  "label": "groundy",
  "positive": 0,
  "color": "#FFDF46",
  "label_es": "suelo",
  "selected": false,
  "open": 1,
  "flavor_type": "defect",
  "zoomed": 0,
  "label_pt": "solo",
  "weight": 1,
  "parent_id": null,
  "label_zh-CN": "地面味",
  "label_zh-HK": null,
  "label_fr-CA": "terre noire",
  "label_ko": "땅 냄새",
  "label_id": "rasa bumi",
  "label_ja": "大地のような",
  "groups": [{
    "id": 66,
    "label": "mushroom",
    "positive": 0,
    "color": "#FFDF46",
    "label_es": "hongo",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "cogumelo",
    "weight": 1,
    "parent_id": 65,
    "label_zh-CN": "蘑菇",
    "label_zh-HK": null,
    "label_fr-CA": "champignon",
    "label_ko": "버섯",
    "label_id": "jamur",
    "label_ja": "マッシュルーム",
    "groups": []
  }, {
    "id": 67,
    "label": "raw potato",
    "positive": 0,
    "color": "#FFDF46",
    "label_es": "papa",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "batata",
    "weight": 1,
    "parent_id": 65,
    "label_zh-CN": "生土豆",
    "label_zh-HK": null,
    "label_fr-CA": "pomme de terre cru",
    "label_ko": "생감자",
    "label_id": "kentang mentah",
    "label_ja": "生のじゃがいも",
    "groups": []
  }, {
    "id": 68,
    "label": "erpsig",
    "positive": 0,
    "color": "#FFDF46",
    "label_es": "erpsig",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "erpsig",
    "weight": 1,
    "parent_id": 65,
    "label_zh-CN": "豌豆",
    "label_zh-HK": null,
    "label_fr-CA": "racine",
    "label_ko": "콩내",
    "label_id": "erpsig",
    "label_ja": "じゃがいものような",
    "groups": []
  }]
}, {
  "id": 69,
  "label": "earthy",
  "positive": 0,
  "color": "#F1E47C",
  "label_es": "terroso",
  "selected": false,
  "open": 1,
  "flavor_type": "defect",
  "zoomed": 0,
  "label_pt": "terroso",
  "weight": 1,
  "parent_id": null,
  "label_zh-CN": "土味",
  "label_zh-HK": null,
  "label_fr-CA": "terreux",
  "label_ko": "흙 냄새",
  "label_id": "rasa tanah",
  "label_ja": "土の匂い、土臭さ",
  "groups": [{
    "id": 70,
    "label": "fresh earth",
    "positive": 0,
    "color": "#F1E47C",
    "label_es": "tierra fresca",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "terra fresca",
    "weight": 1,
    "parent_id": 69,
    "label_zh-CN": "新鲜泥土",
    "label_zh-HK": null,
    "label_fr-CA": "terre fraîche",
    "label_ko": "신선한 흙",
    "label_id": "tanah segar",
    "label_ja": "きれいな土",
    "groups": []
  }, {
    "id": 71,
    "label": "wet soil",
    "positive": 0,
    "color": "#F1E47C",
    "label_es": "suelo húmedo",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "solo úmido",
    "weight": 1,
    "parent_id": 69,
    "label_zh-CN": "潮湿土壤",
    "label_zh-HK": null,
    "label_fr-CA": "terre mouillée",
    "label_ko": "젖은 토양",
    "label_id": "tanah basah",
    "label_ja": "湿った土",
    "groups": []
  }, {
    "id": 72,
    "label": "hummus",
    "positive": 0,
    "color": "#F1E47C",
    "label_es": "hummus",
    "selected": false,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 0,
    "label_pt": "hummus",
    "weight": 1,
    "parent_id": 69,
    "label_zh-CN": "鹰嘴豆泥",
    "label_zh-HK": null,
    "label_fr-CA": "hummus",
    "label_ko": "후무스",
    "label_id": "hummus",
    "label_ja": "腐葉土",
    "groups": []
  }]
}]