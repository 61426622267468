import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import EN from "./en.json"
import ES from "./es.json"
import FR from "./fr-CA.json"
import ID from "./id.json"
import JA from "./ja.json"
import KO from "./ko.json"
import PT from "./pt.json"
import CN from "./zh-CN.json"

// the translations
const resources = {
  en: { translation: EN },
  es: { translation: ES },
  "fr-CA": { translation: FR },
  id: { translation: ID },
  ja: { translation: JA },
  ko: { translation: KO },
  pt: { translation: PT },
  "zh-CN": { translation: CN },
}

i18n
  .use(initReactI18next)
  .init({
    resources: resources,
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false
    }
  })

export default i18n