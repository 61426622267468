import { IStateTreeNode, applySnapshot } from "mobx-state-tree"

/**
 * Reset property to the default state.
 */
export const withReset = (self: IStateTreeNode) => ({
  actions: {
    /**
     * Reset property to the default state.
     */
    reset() {
      applySnapshot(self, {})
    },
  },
})
