/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-17 23:35:31
 * @modify date 2022-04-17 23:35:31
 */

import moment from "moment"
import {toNumber, toString} from 'lodash'

export const samplePreProcessor = (sn: any) => {
  return {
    ...sn,
    id: toNumber(sn.id) || '',
    sampleId: sn.sampleId || '',
    name: sn.name || '',
    sampleType: sn.sampleType || '',
    species: sn.species || '',
    receivedOn: moment(sn.receivedOn).valueOf() || new Date(),
    cropYear: toString(sn.cropYear) || '',
    countryCode: sn.countryCode || '',
    producerName: sn.producerName || '',
    bagWeight: toString(sn.bagWeight) || '',
    numberOfBag: toString(sn.numberOfBag) || '',
    varietalsTags: sn.varietalsTags || '',
    referenceNumber: sn.referenceNumber || '',
    grade: sn.grade || '',
    processName: sn.processName || '',
    supplierName: sn.supplierName || '',
    shippingInfo: sn.shippingInfo || '',
    notesAndRemarks: sn.notesAndRemarks || '',
    waterActivity: toString(sn.waterActivity) || '',
    moisture: toString(sn.moisture )|| '',
    mass: toString(sn.mass) || '',
    volume: toString(sn.volume) || '',
    cachedAverageScore: sn.cachedAverageScore || '',
    status: sn.status || '',
    farmerNotes: sn.farmerNotes || '',
    storySubject: sn.storySubject || '',
    storyDescription: sn.storyDescription || '',
    temperature: toString(sn.temperature) || '',
    uniqueToken: sn.uniqueToken || '',
    purchaseContractReference: sn.purchaseContractReference || '',
    salesContractReference: sn.salesContractReference || '',
    originGrade: sn.originGrade || '',
    warehouseReference: sn.warehouseReference || '',
    protocolForm: sn.protocolForm || '',
    customer: sn.customer || '',
    customerCode: sn.customerCode || '',
    description: sn.description || '',
    receivedWeight: toString(sn.receivedWeight) || '',
  }
}
