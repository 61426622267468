/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-06 20:03:53
 * @modify date 2022-04-14 23:17:39
 */

import React from 'react'
import {clone, find, isNil, map} from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ArrowDropDown } from '@mui/icons-material'
import { Container, Button, Avatar, Menu, MenuItem, Grid, Stack, Typography } from '@mui/material'

import { useStores } from 'models'
import { globalAction } from 'utils'
import { findRoute } from 'constants/routes'
import { IconStartCupping, emptySearch } from 'assets/images'
import { HeaderTitle, Pagination, ModalConfirm } from 'components'
import { cuppingSessionFilters, cuppingSessionSorts } from 'constants/form'

import { SessionItem, SettingQrCode } from './components'
import { useStyles } from './cupping-sessions.styles'
import { PrintQr } from 'pages/modal/print-qr/print-qr'

export const CuppingSessions: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const {
    userStore: { canCreateCuppingSessions },
    companyStore: { isDormantAccount },
    cuppingSessionStore: {
      filter, page, totalPage, cuppingSessions, startCupping,
      getCuppingSessions, getCuppingSession, removeCuppingSession, resendWebhook, playOrPause, updateRequestInformation,
      setValue: setCuppingSessionStoreValue
    },
    modalStore: { printQRCode, setValue: setModalValue },
    sampleStore: { getSamples,samples },
    reviewSampleStore: { setValue: setReviewSampleValue },
    notificationStore
  } = useStores()

  const formCuppingSessionLink = '/cupping-sessions/new'
  const sampleInformationLink = findRoute('sampleInformation')
  const sampleScoreLink = findRoute('sampleScore')
  const sampleReviewLink = findRoute('sampleReview')
  const forbiddenLink = findRoute('forbidden')

  const [anchorFilter, setAnchorFilter] = React.useState<null | HTMLElement>(null)
  const openFilter = Boolean(anchorFilter)
  const [headerSorts, setHeaderSorts ] = React.useState(cuppingSessionSorts)
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [urlInvitationsQrCode, setUrlInvitationsQrCode] = React.useState('')
  const [selectDataCuppingSession, setSelectDataCuppingSession] = React.useState<any>({})
  const [openSetting, setOpenSetting] = React.useState(false)

  const setSort = (key: string) => {
    let newSort = clone(headerSorts)
    newSort[key] = !newSort[key]

    setHeaderSorts(newSort)
  }

  const onAction = (action: void, link: string = '') => {
    globalAction(notificationStore, {
      complexAction: true,
      action: async () => {
        await action

        if (link) navigate(link)
      }
    })
  }

  const onRemove = () => globalAction(notificationStore, { action: removeCuppingSession })

  const onResendWebhook = (token) => globalAction(notificationStore, { action: () => resendWebhook(token) })

  const onLoadCuppingSessions = () => globalAction(notificationStore, { action: getCuppingSessions })

  React.useEffect(() => { onLoadCuppingSessions() }, [page, filter])
  React.useEffect(() => {
    if (isDormantAccount) navigate(forbiddenLink)
    setCuppingSessionStoreValue('selectedCuppingSession', undefined)

    if (isNil(params.id)) return

    onAction(getCuppingSession(params.id), sampleScoreLink)
  }, [])

  return (
    <>
      <HeaderTitle
        breadcrumb={t('menu.cuppingSessions')}
        title={t('cuppingSession.letsStartCupping')}
        rightContent={(
          <Stack direction='row' spacing={2} className={classes.buttonWrapper}>
            {canCreateCuppingSessions &&
              <Button
                variant="contained"
                startIcon={
                  <Avatar className={classes.iconRounded}>
                    <IconStartCupping />
                  </Avatar>
                }
                sx={{borderRadius: 8}}
                className={classes.buttonRounded}
                onClick={() => {
                  navigate(formCuppingSessionLink)
                  setReviewSampleValue('cuppingSamples', [])
                }}
              >
                {t('cuppingSession.newCuppingSession')}
              </Button>
            }

            <Button
              variant="outlined"
              endIcon={<ArrowDropDown />}
              sx={{borderRadius: 8}}
              onClick={(event) => setAnchorFilter(event.currentTarget)}
            >
              {t(find(cuppingSessionFilters, ['key', filter])?.label || '')}
            </Button>
            <Menu
              anchorEl={anchorFilter}
              open={openFilter}
              onClose={() => setAnchorFilter(null)}
              onClick={() => setAnchorFilter(null)}
            >
              {cuppingSessionFilters.map((filter) =>
                <MenuItem
                  key={filter.key}
                  value={filter.key}
                  onClick={() => setCuppingSessionStoreValue('filter', filter.key)}
                >
                  {t(filter.label)}
                </MenuItem>
              )}
            </Menu>
          </Stack>
        )}
      />

      {cuppingSessions.length > 0 ?
        <Container sx={{px: {xs: 0, md: 3}}}>
          <Grid container spacing={1} className={classes.gridWrapper}>
            <Grid item xs={12} className={classes.gridHeader}>
              <Grid
                container
                sx={{alignItems: 'end'}}
              >
                <Grid item md={3}>
                  <Grid container sx={{textAlign: 'center'}}>
                    <Grid item xs={3}>
                      <Button
                        onClick={() => setSort('id')}
                        className={classes.headerButton}
                      >
                        {t('cuppingSession.id')}
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        onClick={() => setSort('owner')}
                        className={classes.headerButton}
                      >
                        {t('cuppingSession.owner')}
                      </Button>
                    </Grid>
                    <Grid item xs={5}>
                      <Button
                        onClick={() => setSort('time')}
                        className={classes.headerButton}
                      >
                        {t('cuppingSession.time')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={2}>
                  <Button
                    onClick={() => setSort('name')}
                    className={classes.headerButton}
                  >
                    {t('cuppingSession.name')}
                  </Button>
                </Grid>
                <Grid item md={1}>
                  <Button
                    onClick={() => setSort('location')}
                    className={classes.headerButton}
                  >
                    {t('cuppingSession.location')}
                  </Button>
                </Grid>
                <Grid item md={1} fontWeight={600}>
                  {t('cuppingSession.cuppingForm')}
                </Grid>
                <Grid item md={5} fontWeight={600}>
                  <Grid container sx={{textAlign: 'center', alignItems: 'end'}}>
                    <Grid item xs={2}>{t('cuppingSession.samplesInformation')}</Grid>
                    <Grid item xs={3}>{t('cuppingSession.status')}</Grid>
                    <Grid item xs={2}>{t('common.edit')}</Grid>
                    <Grid item xs={2}>{t('cuppingSession.review')}</Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item md={1}></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {cuppingSessions.map(cuppingSession =>
              <Grid item xs={12} key={cuppingSession.id} className={classes.listItem}>
                <SessionItem
                  {...cuppingSession}
                  onStartCupping={() => onAction(startCupping(cuppingSession.uniqueToken))}
                  onCuppingNow={() => onAction(getSamples(cuppingSession.uniqueToken), sampleScoreLink)}
                  onEditSampleInfo={() => onAction(getSamples(cuppingSession.uniqueToken), sampleInformationLink)}
                  onReviewCuppingSession={() => onAction(getSamples(cuppingSession.uniqueToken), sampleReviewLink)}
                  onRemoveCuppingSession={() => {
                    setOpenConfirm(true)
                    setCuppingSessionStoreValue('selectedCuppingSession', cuppingSession.id)
                  }}
                  onResendWebhook={() => onResendWebhook(cuppingSession.uniqueToken)}
                  onPrintQrCode={()=>{
                    getSamples(cuppingSession.uniqueToken)
                    setSelectDataCuppingSession(cuppingSession)
                    setModalValue('printQRCode', true)
                    setUrlInvitationsQrCode(`${window.location.origin}/invitations/${cuppingSession.uniqueToken} `)
                  }}
                  onPlayOrPause={() => onAction(playOrPause(cuppingSession.id))}
                  onSettingQrCode={() => {
                    setSelectDataCuppingSession(cuppingSession)
                    setOpenSetting(true)
                  }}
                />
              </Grid>
            )}
          </Grid>

          {totalPage > 0 &&
            <Pagination
              page={page}
              count={totalPage}
              onChange={(e, p) => setCuppingSessionStoreValue('page', p)}
            />
          }
        </Container> :
        <Container sx={{textAlign: 'center'}}>
          <Typography variant="h3" sx={{mb: 4}}>
              {t('cuppingSession.noResultFound')}
          </Typography>
          <img src={emptySearch} alt="Not found" />
        </Container>
      }

      <ModalConfirm
        open={openConfirm}
        description={t('cuppingSession.removeCuppingSessionConfirmDescription')}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          setOpenConfirm(false)
          onRemove()
        }}
      />
       <PrintQr
        open={printQRCode}
        onClose={() => setModalValue('printQRCode', false)}
        setUrl = {urlInvitationsQrCode}
        selectCuppingSession = {selectDataCuppingSession}
        sampelName ={map(samples, 'name')}
      />

      {selectDataCuppingSession && openSetting &&
        <SettingQrCode
          open={openSetting}
          onClose={() => setOpenSetting(false)}
          cuppingSession={selectDataCuppingSession}
          onSave={updateRequestInformation}
        />
      }
    </>
  )
})
