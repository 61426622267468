/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-14 23:27:39
 * @modify date 2022-01-14 23:27:39
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { verticalCentered } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      paddingTop: '2.5rem',
    },
    containerWrapper: {
      ...verticalCentered,
      marginBottom: theme.spacing(4),
      maxHeight: `calc(100vh - ${theme.spacing(12)})`
    },
    title: {
      marginBottom: theme.spacing(6),
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.up('md')]: {
        textAlign: 'left'
      }
    },
    image: {
      maxWidth: '80%'
    }
  })
)