import React from "react"
import {Box} from "@mui/material"
import {GenerateLabel} from "../modal/generate-label/generate-label";

export const SampleShipments = () => {
  return (
    <Box>
      Shipments
    </Box>
  )
}
