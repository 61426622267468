import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import {camelCase, ceil, cloneDeep, findIndex} from "lodash";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {Box, Button, Typography} from "@mui/material";
import {Visibility} from "@mui/icons-material";

import {BasicTable, GreenGradingReview, ModalWrapper} from "components";
import {useStores} from "models";
import {emptyGreenGrading} from "assets/images";
import {GreenGradingOptionsModal} from "./green-grading-options-modal/green-grading-options";
import {GreenGradingModal} from "./green-grading-modal/green-grading-modal";

const columnHelper = createColumnHelper<any>()

export const GreenGradings = observer(() => {
  const { t } = useTranslation()
  const params = useParams()

  const {
    masterSampleStore: { getCuppingSessionSamples },
    greenGradingStore: { page, totalPage, greenGradings, getGreenGradings, setValue: setGreenGradingValue },
    modalStore: { greenGrading, setValue: setModalValue }
  } = useStores()

  const [isFetching, setIsFetching] = useState(false)
  const [openGreenGrading, setOpenGreenGrading] = useState(false)
  const [sampleId, setSampleId] = useState('')
  const [greenGradingIndex, setGreenGradingIndex] = useState(0)
  const data = cloneDeep(greenGradings)

  const columns = [
    columnHelper.accessor('author', {
      id: 'author',
      header: () => <Box textAlign="left">{t('sample.detail.gradedBy')}</Box>,
      cell: info => <Box fontWeight={600}>{info.renderValue()}</Box>,
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => <Box textAlign="left">{t('sample.detail.gradedOn')}</Box>,
      cell: info => <Box>{moment(info.renderValue()).format('L')}</Box>,
    }),
    columnHelper.accessor('screenSize', {
      id: 'screenSize',
      header: () => <Box textAlign="left">{t('sample.detail.screenSize')}</Box>,
      cell: info => <Box>16/19 - {ceil(info.renderValue())}%</Box>,
    }),
    columnHelper.accessor('smell', {
      id: 'smell',
      header: () => <Box textAlign="left">{t('sample.detail.smell')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('color', {
      id: 'color',
      header: () => <Box textAlign="left">Color</Box>,
      cell: info => <Box>{info.renderValue() ? t(`options.color.${camelCase(info.renderValue())}`) : ''}</Box>,
    }),
    columnHelper.accessor('total', {
      id: 'total',
      header: () => t('sample.detail.totalDefects'),
      cell: info => {
        const total = JSON.parse(info.renderValue())
        return <Box textAlign="center">{total.all}</Box>
      },
    }),
    columnHelper.accessor('id', {
      id: 'id',
      header: () => 'Action',
      cell: info =>
        <Box textAlign="center">
          <Button
            variant="contained"
            sx={{ p: '6px', minWidth: 'unset' }}
            onClick={() => {
              const index = findIndex(greenGradings, ['id', info.renderValue()])
              if (index < 0) return

              setGreenGradingIndex(index)
              setOpenGreenGrading(true)
            }}
          >
            <Visibility />
          </Button>
        </Box>,
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const loadGreenGradings = async () => {
    try {
      setIsFetching(true)
      await getGreenGradings(params.id)
    } finally {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    loadGreenGradings()
  }, [page])

  useEffect(() => {
    setGreenGradingValue('page', 1)
  }, [])

  return (
    <Box px={2} py={2.5}>
      <Box mb={2} textAlign="right">
        <Button
          size="small"
          variant="contained"
          onClick={async () => {
            await getCuppingSessionSamples(params.id)
            setModalValue("greenGradingOptions", true)
          }}
        >
          + Create Green Grading
        </Button>
      </Box>

      {greenGradings.length > 0 ? (
          <BasicTable
            table={table}
            page={page}
            totalPage={totalPage}
            isFetching={isFetching}
            onChangePage={(_, p) => setGreenGradingValue('page', p)}
          />
        ) : (
          <Box py={4} textAlign="center">
            <img src={emptyGreenGrading} />
            <Typography mt={2} variant="h5">{t('sample.detail.emptyGreenGrading')}</Typography>
          </Box>
        )
      }

      <GreenGradingOptionsModal
        sampleId={sampleId}
        setSampleId={setSampleId}
      />

      <GreenGradingModal
        sampleId={sampleId}
        open={greenGrading}
        onClose={() => {
          setModalValue("greenGrading", false)
          loadGreenGradings()
          setSampleId('')
        }}
      />

      <ModalWrapper
        maxWidth='md'
        open={openGreenGrading}
        onClose={() => setOpenGreenGrading(false)}
      >
        <GreenGradingReview
          greenGrading={greenGradings[greenGradingIndex]}
        />
      </ModalWrapper>
    </Box>
  )
})
