export const aftertasteDescriptorList = [{
	"id": 169,
	"label": "juicy",
	"color": "#F8A953",
	"parent_id": null,
	"label_es": "jugoso",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "suculento",
	"label_zh-CN": "多汁",
	"weight": 1.0,
	"label_fr-CA": "juteux",
	"label_ko": "주스 같은",
	"label_id": "juicy",
	"label_ja": "ジューシー"
}, {
	"id": 170,
	"label": "lingering",
	"color": "#BAD7BA",
	"parent_id": null,
	"label_es": "prolongado",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "prolongado",
	"label_zh-CN": "缠绵",
	"weight": 1.22,
	"label_fr-CA": "persistant",
	"label_ko": "오래 머무는",
	"label_id": "tidak cepat hilang",
	"label_ja": "長引く"
}, {
	"id": 171,
	"label": "dirty",
	"color": "#BAD7BA",
	"parent_id": null,
	"label_es": "sucio",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "sujo",
	"label_zh-CN": "脏",
	"weight": 1.05,
	"label_fr-CA": "poussière",
	"label_ko": "지저분한",
	"label_id": "kotor",
	"label_ja": "土ぼこり、汚れた"
}, {
	"id": 172,
	"label": "muted",
	"color": "#A2885C",
	"parent_id": null,
	"label_es": "apagado",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "apagado",
	"label_zh-CN": "低哑",
	"weight": 1.11,
	"label_fr-CA": "absente",
	"label_ko": "무미한",
	"label_id": "tidak menonjol",
	"label_ja": "くすんだ"
}, {
	"id": 173,
	"label": "dull",
	"color": "#A2885C",
	"parent_id": null,
	"label_es": "deslucido",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "maçante",
	"label_zh-CN": "平淡",
	"weight": 1.13,
	"label_fr-CA": "crémeux",
	"label_ko": "밋밋한",
	"label_id": "hambar",
	"label_ja": "気の抜けた"
}, {
	"id": 174,
	"label": "mild",
	"color": "#A2885C",
	"parent_id": null,
	"label_es": "leve",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "leve",
	"label_zh-CN": "柔和",
	"weight": 1.13,
	"label_fr-CA": "modéré",
	"label_ko": "맛이 부드러운",
	"label_id": "ringan",
	"label_ja": "マイルド"
}, {
	"id": 175,
	"label": "structured",
	"color": "#F8A953",
	"parent_id": null,
	"label_es": "estructurado",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "estruturado",
	"label_zh-CN": "有序",
	"weight": 1.23,
	"label_fr-CA": "balancé",
	"label_ko": "구조가 잘 잡힌",
	"label_id": "terstruktur",
	"label_ja": "構造がある"
}, {
	"id": 176,
	"label": "balanced",
	"color": "#F8A953",
	"parent_id": null,
	"label_es": "balanceado",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "balanceado",
	"label_zh-CN": "平衡",
	"weight": 1.21,
	"label_fr-CA": "équilibré",
	"label_ko": "균형이 잡힌",
	"label_id": "seimbang",
	"label_ja": "バランスがある"
}, {
	"id": 177,
	"label": "rounded",
	"color": "#F8A953",
	"parent_id": null,
	"label_es": "redondeado",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "redondo",
	"label_zh-CN": "圆满",
	"weight": 1.24,
	"label_fr-CA": "rond",
	"label_ko": "둥근",
	"label_id": "bulat",
	"label_ja": "丸みがある"
}, {
	"id": 178,
	"label": "soft",
	"color": "#A9F3F9",
	"parent_id": null,
	"label_es": "blando",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "macio",
	"label_zh-CN": "柔软",
	"weight": 1.2,
	"label_fr-CA": "doux",
	"label_ko": "촉감이 부드러운",
	"label_id": "halus",
	"label_ja": "柔らかい"
}, {
	"id": 179,
	"label": "faint",
	"color": "#A9F3F9",
	"parent_id": null,
	"label_es": "débil",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "fraco",
	"label_zh-CN": "暗沉",
	"weight": 1.15,
	"label_fr-CA": "faible",
	"label_ko": "희미한",
	"label_id": "samar-samar",
	"label_ja": "淡い"
}, {
	"id": 180,
	"label": "delicate",
	"color": "#A9F3F9",
	"parent_id": null,
	"label_es": "delicado",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "delicado",
	"label_zh-CN": "精巧",
	"weight": 1.24,
	"label_fr-CA": "délicat",
	"label_ko": "섬세하고 우아한",
	"label_id": "elok",
	"label_ja": "デリケートな"
}, {
	"id": 181,
	"label": "dry",
	"color": "#A6F868",
	"parent_id": null,
	"label_es": "seco",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "seco",
	"label_zh-CN": "干",
	"weight": 1.14,
	"label_fr-CA": "sec",
	"label_ko": "마른",
	"label_id": "kering",
	"label_ja": "ドライ"
}, {
	"id": 182,
	"label": "astringent",
	"color": "#A6F868",
	"parent_id": null,
	"label_es": "astringente",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "adstringente",
	"label_zh-CN": "涩",
	"weight": 1.11,
	"label_fr-CA": "astringent",
	"label_ko": "떫은",
	"label_id": "astringent",
	"label_ja": "渋味"
}, {
	"id": 183,
	"label": "quick",
	"color": "#9DB4E1",
	"parent_id": null,
	"label_es": "rápido",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "rápido",
	"label_zh-CN": "短",
	"weight": 1.15,
	"label_fr-CA": "vif",
	"label_ko": "여운이 짧은",
	"label_id": "cepat",
	"label_ja": "速い"
}, {
	"id": 184,
	"label": "clean",
	"color": "#9DB4E1",
	"parent_id": null,
	"label_es": "limpio",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "limpo",
	"label_zh-CN": "干净",
	"weight": 1.2,
	"label_fr-CA": "propre",
	"label_ko": "깨끗한",
	"label_id": "bersih",
	"label_ja": "クリーン"
}, {
	"id": 186,
	"label": "harsh",
	"color": "#A6F868",
	"parent_id": null,
	"label_es": "aspero",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "áspero",
	"label_zh-CN": "粗糙",
	"weight": 1.12,
	"label_fr-CA": "acre",
	"label_ko": "거슬리는",
	"label_id": "kasar",
	"label_ja": "粗い"
}]