export const scaDefectDescriptors = [
  {
    "id": 1,
    "label": "potato",
    "positive": 0,
    "color": "#000000",
    "label_es": "papa",
    "selected": 1,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 1,
    "label_pt": null,
    "weights": 1,
    "parent_id": null,
    "label_ko": "감자",
    "label_rw": null,
    "label_sw": null,
    "label_zh-CN": null,
    "label_zh-HK": null,
    "label_fr-CA": null,
    "label_id": "Potato/Mentah",
    "groups": []
  }, 	{
    "id": 2,
    "label": "phenol",
    "positive": 0,
    "color": "#DB646A",
    "label_es": "fenól",
    "selected": 1,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 1,
    "label_pt": null,
    "weights": 1,
    "parent_id": null,
    "label_ko": "페놀",
    "label_rw": null,
    "label_sw": null,
    "label_zh-CN": null,
    "label_zh-HK": null,
    "label_fr-CA": null,
    "label_id": "Phenolic",
    "groups": []
  }, 	{
    "id": 3,
    "label": "mold",
    "positive": 0,
    "color": "#A3A36F",
    "label_es": "enmohecido",
    "selected": 1,
    "open": 1,
    "flavor_type": "defect",
    "zoomed": 1,
    "label_pt": null,
    "weights": 1,
    "parent_id": null,
    "label_ko": "곰팡이",
    "label_rw": null,
    "label_sw": null,
    "label_zh-CN": null,
    "label_zh-HK": null,
    "label_fr-CA": null,
    "label_id": "Berjamur/Lapuk",
    "groups": []
  }
]
