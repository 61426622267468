/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-05-30 23:02:13
 * @modify date 2022-05-30 23:02:13
 */

import React from 'react'
import {assign, find, has, isEmpty, isNull, startCase, toString} from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import ReactHtmlParser from 'react-html-parser'
import {
  Box,
  Typography,
  SvgIcon,
  Button,
  Grid,
  Select,
  MenuItem,
  FormHelperText,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormLabel,
  FormControl, Popover, Collapse, FormControlLabel, Radio,
} from '@mui/material'
import {ExpandLess, ExpandMore, HelpOutline, Visibility, VisibilityOff} from "@mui/icons-material"
import {LoadingButton} from "@mui/lab"

import { useStores } from 'models'
import { centered } from 'assets'
import {FormInput, ModalWrapper} from 'components'
import { Logo } from 'assets/images'
import { colors } from 'assets'
import { timezones } from "constants/timezone"
import {countries, errorMessage, errorStatus, globalAction, pointerMessages, str2bool, validatePassword} from "utils"
import { DEFAULT_ADMIN, DEFAULT_COMPANY } from "config/env"
import { roles } from "constants/form"

import { useStyles } from "./welcome.styles"

export const Welcome: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    userStore: {
      isProfileCompleted, name, isCompanyAdmin, timeZone, saveProfile,
      password, passwordConfirmation, updatePassword, role: userRole, isQgrader, isProducer,
      showInformation, setValue: setUserValue
    },
    companyStore: {
      subscriptionPlanId, trialDaysLeft, isCompanyCompletedWithoutType, name: companyName, countryCode,
      saveCompany, setValue: setCompanyValue
    },
    subscriptionStore: { subscriptions },
    notificationStore
  } = useStores()

  const [open, setOpen] = React.useState(false)
  const [errors, setErrors] = React.useState<any>({})
  const [errorTimeZone, setErrorTimeZone] = React.useState(false)
  const [errorCountry, setErrorCountry] = React.useState(false)
  const [step, setStep] = React.useState(1)
  const [expandInformation, setExpandInformation] = React.useState(false)
  const [expandRole, setExpandRole] = React.useState(true)

  const [currentPassword, setCurrentPassword] = React.useState('')
  const [showPassword, setShowPassword] = React.useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = React.useState(false)
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false)
  const [anchorPassword, setAnchorPassword] = React.useState<HTMLButtonElement | null>(null)
  const openPassword = Boolean(anchorPassword)

  const subscription = find(subscriptions, ['id', subscriptionPlanId])
  const isDefaultName = name === DEFAULT_ADMIN
  const isDefaultCompany = companyName === DEFAULT_COMPANY

  const isPasswordError = has(errors, 'password') && !validatePassword(password)
  const isPasswordConfirmationError = has(errors, 'passwordConfirmation') && password !== passwordConfirmation

  const profileValidation = () => {
    setErrors({})
    let validationErrors = {}

    if (!name || isDefaultName) {
      assign(validationErrors, {
        "name": [{ message: t('profile.error.fieldIsEmpty') }]
      })
    }

    if (!companyName || isDefaultCompany) {
      assign(validationErrors, {
        "companyName": [{ message: t('company.error.fieldNameCompanyEmpty') }]
      })
    }

    if (!timeZone) {
      assign(validationErrors, {
        "timeZone": [{message: t('profile.error.timeZoneInvalid')}]
      })
      setErrorTimeZone(true)
    }

    if (!countryCode) {
      assign(validationErrors, {
        "countryCode": [{ message: t('company.error.fieldCountryEmpty') }]
      })
      setErrorCountry(true)
    }

    if (isEmpty(userRole)) {
      assign(validationErrors, {
        "additionalInformation": [{ message: t('profile.error.additionalInformation') }]
      })
    }

    if (isNull(isQgrader)) {
      assign(validationErrors, {
        "grader": [{ message: t('profile.error.additionalInformation') }]
      })
    }

    if (isNull(isProducer)) {
      assign(validationErrors, {
        "producer": [{ message: t('profile.error.additionalInformation') }]
      })
    }

    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors)
      return false
    }

    return true
  }

  const passwordValidations = () => {
    setErrors({})
    let validationErrors = {}

    if (!(password === passwordConfirmation)) {
      assign(validationErrors, {
        "passwordConfirmation": [{ message: t('profile.error.passwordIsNotMatch') }]
      })
    }

    if (!validatePassword(password)) {
      assign(validationErrors, {
        "password": [{ message: t('profile.error.passwordIsInvalid') }]
      })
    }

    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors)
      return false
    }

    return true
  }

  const onUpdateProfile = async () => {
    if (!profileValidation()) return

    globalAction(notificationStore, {
      action: async () => await Promise.all([saveProfile(), saveCompany()]),
      afterAction: () => {
        setStep(2)
      }
    })
  }

  const onUpdatePassword = async () => {
    if (!passwordValidations()) return

    globalAction(notificationStore, {
      action: async () => await updatePassword(currentPassword),
      afterAction: () => {
        setCurrentPassword('')
        setUserValue('password', '')
        setUserValue('passwordConfirmation', '')
        setOpen(false)
      }
    })
  }

  const renderStep1 = (
    <Grid container>
      <Grid item xs={12}>
        <FormInput
          label={t('profile.fullName')}
          formControlProps={{margin: 'none'}}
          textFieldProps={{
            placeholder: t('profile.name'),
            value: name,
            onChange: (event) => setUserValue('name', event.target.value),
            error: !name || isDefaultName || (!name && has(errors, 'name')),
            helperText: (!name || isDefaultName) && pointerMessages('name', errors)
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInput
          label={t('company.companyName')}
          textFieldProps={{
            placeholder: "E.G. Tastify Company",
            value: companyName,
            onChange: (e) => setCompanyValue('name', e.target.value),
            error: !companyName || isDefaultCompany || (!companyName && has(errors, 'companyName')),
            helperText: (!companyName || isDefaultCompany) && pointerMessages('companyName', errors)
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInput label={t('profile.timeZone')}>
          <Select
            fullWidth
            size='small'
            placeholder={t('profile.selectTimeZone')}
            value={timeZone}
            onChange={event => {
              setUserValue('timeZone', event.target.value)
              setErrorTimeZone(false)
            }}
            error={errorTimeZone}
          >
            {timezones.map((tz) => <MenuItem key={tz.value} value={tz.value}>{tz.label}</MenuItem>)}
          </Select>
          {errorTimeZone && <FormHelperText sx={{color: '#DE8282', fontStyle: 'italic'}}>{t('profile.error.timeZoneInvalid')}</FormHelperText>}
        </FormInput>
      </Grid>
      <Grid item xs={12}>
        <FormInput label={t('company.country')}>
          <Select
            fullWidth
            size='small'
            value={countryCode}
            onChange={event => {
              setCompanyValue('countryCode', event.target.value)
              setErrorCountry(false)
            }}
            error={errorCountry}

          >
            {countries.map(item => <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>)}
          </Select>
          {errorCountry && <FormHelperText sx={{color: '#DE8282', fontStyle: 'italic'}}>{t('company.error.fieldCountryEmpty')}</FormHelperText>}
        </FormInput>
      </Grid>

      {showInformation &&
        <Grid
          item
          xs={12}
          className={classes.expandWrapper}
          onClick={() => setExpandInformation(!expandInformation)}
          sx={{justifyContent: 'center'}}
        >
          <Typography variant='subtitle1'>{t('profile.additionalInformation')}</Typography>
          {expandInformation ? <ExpandLess/> : <ExpandMore/>}
        </Grid>
      }

      <Collapse in={expandInformation} timeout="auto" unmountOnExit sx={{pl: 2}}>
        <Grid item xs={12} className={classes.expandWrapper} onClick={() => setExpandRole(!expandRole)}>
          <Typography variant='subtitle1'>{t('profile.chooseRole')}</Typography>
          {expandRole ? <ExpandLess /> : <ExpandMore />}
        </Grid>
        <Grid item xs={12} sx={{mb: 2}}>
          <Collapse in={expandRole} timeout="auto" unmountOnExit>
            <Grid container spacing={1}>
              {roles.map(role =>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    key={role.key}
                    value={role.key}
                    control={<Radio color="primary" />}
                    label={toString(t(role.label))}
                    labelPlacement="end"
                    checked={role.key === userRole}
                    onChange={e => setUserValue('role', (e.target as HTMLInputElement).value)}
                  />
                </Grid>
              )}
            </Grid>
          </Collapse>
        </Grid>
        <Grid item xs={12} sm={6} sx={{mb: 2}}>
          <FormInput label={t('profile.areYouQgrader')} formControlProps={{margin: 'none'}}>
            <Grid container spacing={1}>
              {[true, false].map(bool =>
                <Grid item xs={6}>
                  <FormControlLabel
                    key={`option-${bool}`}
                    value={bool}
                    control={<Radio color="primary" />}
                    label={toString(t(`options.boolean.${bool}`))}
                    labelPlacement="end"
                    checked={bool === isQgrader}
                    onChange={e => setUserValue('isQgrader', str2bool((e.target as HTMLInputElement).value))}
                  />
                </Grid>
              )}
            </Grid>
            {errorStatus('isQgrader', isQgrader, errors) &&
              <Typography variant='caption' color='error'>{errorMessage('isQgrader', isQgrader, errors)}</Typography>
            }
          </FormInput>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInput label={t('profile.areYouProducer')} formControlProps={{margin: 'none'}}>
            <Grid container spacing={1}>
              {[true, false].map(bool =>
                <Grid item xs={6}>
                  <FormControlLabel
                    key={`option-${bool}`}
                    value={bool}
                    control={<Radio color="primary" />}
                    label={toString(t(`options.boolean.${bool}`))}
                    labelPlacement="end"
                    checked={bool === isProducer}
                    onChange={e => setUserValue('isProducer', str2bool((e.target as HTMLInputElement).value))}
                  />
                </Grid>
              )}
            </Grid>
            {errorStatus('isProducer', isProducer, errors) &&
              <Typography variant='caption' color='error'>{errorMessage('isProducer', isProducer, errors)}</Typography>
            }
          </FormInput>
        </Grid>
      </Collapse>
      {showInformation &&
        <FormHelperText sx={{color: '#DE8282', fontStyle: 'italic', ml: 2}}>
          {t('profile.error.additionalInformation')}
        </FormHelperText>
      }
    </Grid>
  )

  const renderStep2 = (
    <Grid container>
      <Grid item xs={12}>
        <FormControl fullWidth margin='normal'>
          <Box display="flex">
            <FormLabel>{t('profile.currentPassword')}</FormLabel>
            <IconButton
              color='primary'
              onClick={(event) => setAnchorPassword(event.currentTarget)}
              sx={{mt: '-10px'}}
            >
              <HelpOutline />
            </IconButton>
            <Popover
              open={openPassword}
              anchorEl={anchorPassword}
              onClose={() => setAnchorPassword(null)}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              PaperProps={{
                sx: {width: 300}
              }}
            >
              <Typography sx={{ p: 2 }}>{t('profile.findPasswordToEmail')}</Typography>
            </Popover>
          </Box>

          <OutlinedInput
            size='small'
            type={showCurrentPassword ? 'text' : 'password'}
            placeholder={t('profile.enterCurrentPasswordHere')}
            value={currentPassword}
            onChange={e => setCurrentPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowCurrentPassword(!showCurrentPassword)} >
                  {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormInput
          label={t('profile.newPassword')}
          formControlProps={{
            variant: 'standard',
            error: isPasswordError
          }}
        >
          <OutlinedInput
            size='small'
            type={showPassword ? 'text' : 'password'}
            placeholder={t('profile.enterNewPasswordHere')}
            value={password}
            onChange={e => setUserValue('password', e.target.value)}
            error={isPasswordError}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText error={isPasswordError}>
            {pointerMessages('password', errors) || t('profile.error.passwordIsInvalid')}
          </FormHelperText>
        </FormInput>
      </Grid>
      <Grid item xs={12}>
        <FormInput label={t('profile.confirmNewPassword')}>
          <OutlinedInput
            size='small'
            type={showPasswordConfirmation ? 'text' : 'password'}
            placeholder={t('profile.enterConfirmNewPasswordHere')}
            value={passwordConfirmation}
            onChange={e => setUserValue('passwordConfirmation', e.target.value)}
            error={isPasswordConfirmationError}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)} >
                  {showPasswordConfirmation ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {isPasswordConfirmationError &&
            <FormHelperText error={true}>
              {pointerMessages('passwordConfirmation', errors)}
            </FormHelperText>
          }
        </FormInput>
      </Grid>
    </Grid>
  )

  React.useEffect(() => {
    if (!isProfileCompleted || (!isCompanyCompletedWithoutType && isCompanyAdmin)) {
      setOpen(true)
    }
  }, [])

  return (
    <ModalWrapper
      maxWidth='sm'
      open={open}
    >
      <Box sx={{...centered, flexDirection: 'column', flex: 1}}>
        <SvgIcon component={Logo} inheritViewBox sx={{height: 50, width: 200, mb: 2}} />
        <Typography
          variant='body1'
          align='center'
          sx={{my: 2, '& strong': {color: colors.primary.main}}}
        >
          {ReactHtmlParser(t('modal.welcomeModalDescription', {
            count: trialDaysLeft,
            plan: startCase(subscription?.name || '')
          }))}
        </Typography>
      </Box>

      {step === 1 && renderStep1}
      {step === 2 && renderStep2}

      <Box mt={2}>
        {step === 1 &&
          <Button
            variant='contained'
            color='primary'
            size="large"
            onClick={onUpdateProfile}
          >
            {t('profile.completeProfileNow')}
          </Button>
        }
      </Box>

      {step === 2 &&
        <Box mt={2} display="flex">
          <LoadingButton
            fullWidth
            size='large'
            variant="contained"
            disabled={!currentPassword || !password || !passwordConfirmation}
            onClick={onUpdatePassword}
            sx={{mr: 2}}
          >
            {t('profile.saveChanges')}
          </LoadingButton>
          <Button
            variant='outlined'
            color='secondary'
            size="large"
            onClick={() => setOpen(false)}
          >
            {t('profile.skip')}
          </Button>
        </Box>
      }
    </ModalWrapper>
  )
})
