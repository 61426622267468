/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-06 20:03:48
 * @modify date 2022-02-06 20:03:48
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors } from 'assets'
 
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonWrapper: {
      width: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.up('md')]: {
        width: 'fit-content',
      }
    },
    buttonRounded: {
      '&:hover': {
        '& svg': {
          fill: theme.palette.primary.dark
        }
      }
    },
    iconRounded: {
      width: 20,
      height: 20,
      backgroundColor: 'white',
      '& svg': {
        height: 16,
        fill: theme.palette.primary.main,
      }
    },
    gridWrapper: {
      borderTop: `1px solid ${colors.border.primary}`,
      [theme.breakpoints.up('md')]: {
        border: 'none',
      }
    },
    gridHeader: {
      fontSize: '0.875rem',
      fontWeight: 500,
      display: 'none',
      '& .MuiGrid-item': {
        overflow: 'hidden'
      },
      [theme.breakpoints.up('md')]: {
        display: 'block',
      }
    },
    listItem: {
      backgroundColor: 'white',
      [theme.breakpoints.up('md')]: {
        backgroundColor: 'transparent',
      }
    },
    headerButton: {
      padding: 0,
      textTransform: 'none',
      fontSize: '0.875rem',
      fontWeight: 500,
      color: colors.text.primary,
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '& .MuiButton-endIcon': {
        marginLeft: 0
      }
    }
  })
)