import { Instance, SnapshotOut, types } from "mobx-state-tree"

import {
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"

import { withIndividualReportActions } from "./individual-report-action"

/**
 * Model description here for TypeScript hints.
 */

const DescriptorModel = types.model({
  label: types.string,
  qty: types.number
})

export const IndividualReportStoreModel = types
  .model("IndividualReportStore")
  .props({
    individualReports: types.optional(
      types.array(types.model({
        id: types.optional(types.string, ''),
        sampleName: types.optional(types.string, ''),
        average: types.optional(types.string, ''),
        range: types.optional(types.string, ''),
        totalCupper: types.optional(types.number, 0),
        descriptors: types.optional(types.array(DescriptorModel),[]),
        defects: types.optional(types.array(DescriptorModel),[]),
        scores: types.optional(types.string, '{}'),
        flavorWheelGroups: types.optional(types.string, '{}'),
        flavorWheelOnlyFragrance: types.optional(types.string, '{}'),
        flavorWheelOnlyFlavor: types.optional(types.string, '{}'),
        topFlavorWheelGroups: types.optional(types.string, '{}'),
        topFlavorWheelOnlyFragrance: types.optional(types.string, '{}'),
        topFlavorWheelOnlyFlavor: types.optional(types.string, '{}'),
        cuppingProtocol: types.optional(types.string, ''),
        emoticons: types.optional(types.array(types.number), []),
        interestStatus: types.optional(types.string, '{}'),
      }
    )), [])
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withIndividualReportActions)

type IndividualReportStoreType = Instance<typeof IndividualReportStoreModel>
export interface IndividualReportStore extends IndividualReportStoreType {}
type IndividualReportStoreSnapshotType = SnapshotOut<typeof IndividualReportStoreModel>
export interface IndividualReportStoreSnapshot extends IndividualReportStoreSnapshotType {}
