import React from 'react'
import { observer } from "mobx-react-lite"
import { useTranslation } from 'react-i18next'
import {Box, Button, SvgIcon, Typography} from "@mui/material"
import ReactHtmlParser from 'react-html-parser'

import { useStores} from "models"
import { ModalWrapper } from 'components'
import { Logo } from 'assets/images'

export const ProfileCompletion = observer(() => {
  const { t } = useTranslation()
  const {
    userStore: { isProfileCompleted, isCompanyAdmin },
    companyStore: { isCompanyCompleted },
    modalStore: { setting, setValue: setModalValue }
  } = useStores()

  const [open, setOpen] = React.useState(true)
  const openCompany = !isCompanyCompleted && isCompanyAdmin 

  const handleClose = () => {
    setOpen(false)
    setModalValue('setting', true)

    if (!isProfileCompleted) {
      setModalValue('settingTab', 'profile')
      return
    }

    if (openCompany) setModalValue('settingTab', 'company')
  }

  return (
    <ModalWrapper
      maxWidth='sm'
      open={(!isProfileCompleted || openCompany) && open && !setting}
    >
      <Box sx={{my: 1, textAlign: 'center'}}>
        <SvgIcon component={Logo} inheritViewBox sx={{height: 50, width: 200, mb: 4}} />
        <Typography variant='h4' sx={{mb: 2.5}}>
          {t('profile.profileCompletionTitle')}
        </Typography>
        <Typography sx={{mb: 2.5}}>
          {ReactHtmlParser(t('profile.profileCompletionDescription'))}
        </Typography>

        <Button
          variant='contained'
          color='primary'
          size="large"
          onClick={handleClose}
        >
          {t('profile.completeProfileNow')}
        </Button>
      </Box>
    </ModalWrapper>
  )
})