/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-19 02:59:03
 * @modify date 2022-04-19 02:59:03
 */

import { findIndex, remove } from 'lodash'
import {flow, applySnapshot, getSnapshot} from "mobx-state-tree"
import { ApiResult } from "services/api"
import { CUPPED_STORAGE_KEY, SAMPLE_STORAGE_KEY } from 'config/env'
import { saveString, loadString } from 'utils/storage'
import {
  scoreStep,
  minimumScore,
  maximumScore,
  minimumCupScore,
  maximumCupScore,
  defaultScoreScaValue,
  minimumCommercialScore, maximumCommercialScore, scoreCommercialStep, defaultScoreMarketing
} from 'constants/form'

export const withSampleScoreActions = (self: any) => ({
  actions: {
    setLocalStorage: flow(function * (sample) {
      const { selectedScore } = sample
      const { selectedCuppingSession } = self.rootStore.cuppingSessionStore

      if (!selectedScore || !selectedCuppingSession) return

      const scoreStorage = yield loadString(SAMPLE_STORAGE_KEY)
      const newScore = {
        ...selectedScore,
        sample: sample.id,
        cuppingProtocol: selectedCuppingSession.cuppingProtocol
      }

      if (!scoreStorage) {
        yield saveString(SAMPLE_STORAGE_KEY, JSON.stringify([newScore]))
        return
      }

      let temporaryScore = JSON.parse(scoreStorage)
      const index = findIndex(temporaryScore, ['sample', sample.id])

      if (index < 0) {
        temporaryScore.push(newScore)
        yield saveString(SAMPLE_STORAGE_KEY, JSON.stringify(temporaryScore))
        return
      }

      applySnapshot(selectedScore, { ...temporaryScore[index] })
    }),
    saveLocalStorage: flow(function * () {
      const { selectedSample, selectedSample: { selectedScore }} = self.rootStore.sampleStore
      const { selectedCuppingSession } = self.rootStore.cuppingSessionStore

      self.setValue('cuppingProtocol', selectedCuppingSession.cuppingProtocol)

      const scoreStorage = yield loadString(SAMPLE_STORAGE_KEY)
      if (!scoreStorage) return

      const newScore = {
        ...selectedScore,
        sample: selectedSample.id,
        cuppingProtocol: selectedCuppingSession.cuppingProtocol
      }

      let temporaryScore = JSON.parse(scoreStorage)
      const index = findIndex(temporaryScore, ['sample', selectedSample.id])

      if (index < 0) {
        temporaryScore.push(newScore)
      } else {
        temporaryScore[index] = newScore
      }

      yield saveString(SAMPLE_STORAGE_KEY, JSON.stringify(temporaryScore))
    }),
    removeLocalStorage: flow(function * (id) {
      const scoreStorage = yield loadString(SAMPLE_STORAGE_KEY)
      if (!scoreStorage) return

      let temporaryScore = JSON.parse(scoreStorage)
      remove(temporaryScore, {'sample': id})

      yield saveString(SAMPLE_STORAGE_KEY, JSON.stringify(temporaryScore))
    }),
    saveCuppedScore: flow(function * (uniqueToken: string, urlToken: string) {
      const cuppedStorage = yield loadString(CUPPED_STORAGE_KEY)

      let temporaryCupped = JSON.parse(cuppedStorage) || {}
      temporaryCupped = {
        ...temporaryCupped,
        [uniqueToken]: urlToken
      }

      yield saveString(CUPPED_STORAGE_KEY, JSON.stringify(temporaryCupped))
    }),
    addingScore(value: number, attribute: string) {
      let maxScore, step
      switch (self.cuppingProtocol) {
        case 'cup_of_excellence':
          maxScore = maximumCupScore
          step = scoreStep
          break

        case 'commercial':
          maxScore = maximumCommercialScore
          step = scoreCommercialStep
          break

        default:
          step = scoreStep
          maxScore = maximumScore
      }

      if (value >= maxScore) return

      self.setValue(attribute, value + step)
      self.setValue('totalScore', self.calculateTotalScore)
      self.saveLocalStorage()
    },
    decreaseScore(value: number, attribute: string) {
      let minScore, step
      switch (self.cuppingProtocol) {
        case 'cup_of_excellence':
          minScore = minimumCupScore
          step = scoreStep
          break

        case 'commercial':
          minScore = minimumCommercialScore
          step = scoreCommercialStep
          break

        default:
          minScore = minimumScore
          step = scoreStep
      }

      if (value <= minScore) return

      self.setValue(attribute, value - step)
      self.setValue('totalScore', self.calculateTotalScore)
      self.saveLocalStorage()
    },
    resetScore(id) {
      self.removeLocalStorage(id)
      self.reset()
    },
    onClickCup(index: number, items, attribute: string) {
      self.setValue(`${attribute}${index+1}`, !items[index])
      self.setValue('totalScore', self.calculateTotalScore)
      self.saveLocalStorage()
    },
    setScoreValue(key, value) {
      self.setValue(key, value)

      self.setValue('totalScore', self.calculateTotalScore)
      self.saveLocalStorage()
    },
    saveScore: flow(function * (id) {
      try {
        const payload: any = {...self.payloads, sampleId: id}
        const res: ApiResult = yield self.environment.sampleScoreApi.save(payload)

        if (res.kind === "ok") {
          self.removeLocalStorage(id)
          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw error
      }
    }),
    guestScore: flow(function * (id, captchaToken) {
      try {
        const {
          cuppingSessionStore: { selectedCuppingSession, guestUrlToken },
          guestInformationStore
        } = self.rootStore
        if (!selectedCuppingSession) return

        const payload: any = {
          ...self.payloads,
          ...guestInformationStore,
          sampleId: id,
          uniqueToken: selectedCuppingSession.uniqueToken,
          urlToken: guestUrlToken,
          captchaToken
        }

        const res: ApiResult = yield self.environment.guestScoreApi.save(payload)

        if (res.kind === "ok") {
          yield self.removeLocalStorage(id)
          yield self.saveCuppedScore(selectedCuppingSession.uniqueToken + '_' + id, res.data.urlToken)

          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw Error()
      }
    }),
    triggerWebhook: flow(function * (token) {
      try {
        if (!token) return

        const payload: any = {cuppingSessionId: token}
        const additionalPath: string = 'trigger_webhook'

        yield self.environment.sampleScoreApi.save(payload, {}, additionalPath)

      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    setScaDefaultValue(isScaDescriptive: boolean) {
      const defaultValue = isScaDescriptive ? 0 : defaultScoreScaValue
      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        fragrance: defaultValue,
        aroma: defaultValue,
        acidity: defaultValue,
        body: defaultValue,
        flavor: defaultValue,
        aftertaste: defaultValue,
        balance: defaultValue,
        salt: defaultValue,
        bittersweet: defaultValue,
        mouthfeel: defaultValue,
        cleanCupScore: defaultValue,
        sweetnessScore: defaultValue,
        overall: defaultValue,
      })
    },
    setCommercialDefaultValue() {

      const defaultValue = defaultScoreMarketing
      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        flavor: defaultValue,
        flavorIntensity: defaultValue,
        acidity: defaultValue,
        acidityIntensity: defaultValue,
        body: defaultValue,
        bodyIntensity: defaultValue,
        sweetnessScore: defaultValue,
        sweetnessIntensity: defaultValue,
      })
    }
  }
})
