/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-18 00:15:10
 * @modify date 2022-02-01 16:39:12
 */

import React from 'react'
import { has } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'
import { 
  Container, Typography, Input, 
  InputAdornment, IconButton, FormHelperText 
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { useStores } from "models"
import { findRoute } from 'constants/routes'
import { AuthenticationWrapper, StackAlert, FormInput } from 'components'
import { validatePassword, pointerMessages, errorStatus, errorMessage } from 'utils'

import { useStyles } from './reset-password.styles'
import { ResetPasswordProps } from './reset-password.props'

export const ResetPassword: React.FC<ResetPasswordProps> = observer((props: ResetPasswordProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const homeLink = findRoute('home')
  const signInLink = findRoute('signIn')
  const { userStore: { token, password, passwordConfirmation, setValue, resetPassword } } = useStores()
  
  const { image } = props
  const [isLoading, setIsLoading] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  const [errors, setErrors] = React.useState<any>([])

  const onReset = async () => {
    setIsLoading(true)

    try {
      if (!password){
        setErrors({ 
          "password": [{ message: t('resetPassword.error.passwordCantBeEmpty') }]
        })
        return
      }
      if (!passwordConfirmation){
        setErrors({ 
          "passwordConfirmation": [{ message: t('resetPassword.error.passwordCantBeEmpty') }]
        })
        return
      }

      if (!(password === passwordConfirmation)) {
        setValue('passwordConfirmation', '')
        setErrors({ 
          "passwordConfirmation": [{ message: t('resetPassword.error.passwordIsNotMatch') }]
        })
        return
      }

      if (!validatePassword(password)) {
        setErrors({ 
          "password": [{ message: t('resetPassword.error.passwordIsInvalid') }]
        })
        return
      }

      if (!executeRecaptcha) return

      const token = await executeRecaptcha('public')
      setValue('captchaToken', token)
      await resetPassword()
      navigate(homeLink, { replace: true })
    } catch (e: any) {
      setErrors(e.errors)
      setValue('captchaToken', null)
    } finally {
      setIsLoading(false)
    }    
  }

  React.useEffect(() => {
    if (showPassword) setValue('passwordConfirmation', password)
  }, [password, showPassword])

  React.useEffect(() => {
    if (!params.token) {
      navigate(signInLink, { replace: true })
      return
    }

    setValue('token', params.token)
  }, [token, params])

  const isPasswordError = has(errors, 'password') && !validatePassword(password)
  const isPasswordConfirmationError = errorStatus('passwordConfirmation', passwordConfirmation, errors)
  
  return (
    <AuthenticationWrapper title={t('resetPassword.title')} image={image} >
      <Typography variant='h4' className={classes.title}>{t('resetPassword.header')}</Typography>
      {has(errors, 'base') && <StackAlert alerts={errors['base']} sx={{mb: 2}} />}

      <FormInput
        label={t('resetPassword.password')}
        formControlProps={{ 
          variant: 'standard',
          error: isPasswordError
        }}
      >
        <Input 
          type={showPassword ? 'text' : 'password'}
          value={password} 
          onChange={event => setValue('password', event.target.value)}
          error={isPasswordError} 
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)} >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {isPasswordError && 
          <FormHelperText error={true}>
            {pointerMessages('password', errors)}
          </FormHelperText>
        }
      </FormInput>

      {!showPassword &&
        <FormInput
          label={t('resetPassword.passwordConfirmation')}
          formControlProps={{ 
            variant: 'standard',
            error: isPasswordConfirmationError
          }}
        >
          <Input 
            type={showPassword ? 'text' : 'password'}
            value={passwordConfirmation} 
            onChange={event => setValue('passwordConfirmation', event.target.value)}
            error={isPasswordConfirmationError} 
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {isPasswordConfirmationError && 
            <FormHelperText error={true}>
              {errorMessage('passwordConfirmation', passwordConfirmation, errors)}
            </FormHelperText>
          }
        </FormInput>
      }

      <Container className={classes.buttonWrapper}>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          size="large"
          onClick={onReset}
          className={classes.button}
          sx={{ fontSize: 'h6.fontSize' }}
        >
          {t('resetPassword.saveNewPassword')}
        </LoadingButton>
      </Container>
    </AuthenticationWrapper>
  )
})