/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-18 00:12:38
 * @modify date 2022-01-18 00:12:38
 * @desc Style for forgot password page
 */

import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { verticalCentered } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(2)
    },
    buttonWrapper: {
      ...verticalCentered,
      justifyContent: 'space-evenly',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2)
    },
    button: {
      textTransform: 'capitalize',
    }
  })
)