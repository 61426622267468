/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-06-16 21:40:23
 * @modify date 2022-06-16 21:40:23
 */

import React from 'react'
import { Stack } from '@mui/material'
import { 
  iconFacebook,
  iconInstagram,
  iconLinkedin,
  iconTwitter,
  iconYoutube
} from 'assets/images'

import {
  SHARE_TWEETER_URL,
  SHARE_FACEBOOK_URL,
  SHARE_LINKEDIN_URL,
  SHARE_INSTAGRAM_URL,
  SHARE_YOUTUBE_URL 
} from 'constants/share'

export const SocialMedia: React.FC = () => {  
  return (
    <Stack direction='row' spacing={1} justifyContent='center'>
      <a href={SHARE_FACEBOOK_URL} target='_blank' rel="noreferrer">
        <img src={iconFacebook} alt='facebook' />
      </a>
      <a href={SHARE_TWEETER_URL} target='_blank' rel="noreferrer">
        <img src={iconTwitter} alt='twitter' />
      </a>
      <a href={SHARE_YOUTUBE_URL} target='_blank' rel="noreferrer">
        <img src={iconYoutube} alt='youtube' />
      </a>
      <a href={SHARE_INSTAGRAM_URL} target='_blank' rel="noreferrer">
        <img src={iconInstagram} alt='instagram' />
      </a>
      <a href={SHARE_LINKEDIN_URL} target='_blank' rel="noreferrer">
        <img src={iconLinkedin} alt='linkedin' />
      </a>
    </Stack>
  )
}