import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from "react-router-dom"
import App from './App'
import 'i18n/i18n'
import reportWebVitals from './reportWebVitals'
import {Helmet} from "react-helmet"

ReactDOM.render(
  <BrowserRouter>
    <App />

    <Helmet>
      <meta charSet="utf-8" />
      <title>Tastify</title>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
      <meta name="description" content="Simplify the way you cup coffee. Organize your samples with robust management tools. Visualize each coffee with beautiful reports and flavor wheels." />
      <script src="/js/carrotsearch.circles.js" type="text/javascript" />
      <script src="/js/carrotsearch.circles.asserts.js" type="text/javascript" />
    </Helmet>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
