/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import { uniq, map } from 'lodash'
import { Instance, SnapshotOut, types, flow, applySnapshot, getSnapshot } from "mobx-state-tree"

import { ApiResult } from "services/api"
import { 
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"
import { DATA_PER_PAGE } from "config/env"

/**
 * Model description here for TypeScript hints.
 */
export const ProducerStoreModel = types
  .model("ProducerStore")
  .props({
    producers: types.optional(types.array(types.string), []),
    search: types.optional(types.string, ""),
    page: types.optional(types.number, 1),
    totalPage: types.optional(types.number, 0)
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .actions(self=> ({
    setProducers(producers) {
      applySnapshot(self, {
        ...getSnapshot(self),
        producers: producers
      })
    }
  }))
  .actions(self => ({
    getProducers: flow(function * () {
      try {
        const res: ApiResult = yield self
          .environment
          .producerApi
          .all({
            "size": DATA_PER_PAGE,
            "term": self.search
          })          

        if (res.kind === "ok") self.setProducers(uniq(map(res.data, 'name')))
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }), 
  }))

type ProducerStoreType = Instance<typeof ProducerStoreModel>
export interface ProducerStore extends ProducerStoreType {}
type ProducerStoreSnapshotType = SnapshotOut<typeof ProducerStoreModel>
export interface ProducerStoreSnapshot extends ProducerStoreSnapshotType {}
