import React, {useRef, useState} from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {Box, Button, Checkbox, InputAdornment, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {camelCase, isEmpty, isNumber, range, toString} from "lodash";
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'

import {useStores} from "models";
import {countries} from "utils"
import {specieses, sampleTypes, grades, fulfillmentTypes, cuppingProtocols} from 'constants/form'
import {ModalWrapper, BasicTable} from "components";
import {colors} from "assets";

import {GenerateLabelProps} from "./generate-label.props";

export const GenerateLabel: React.FC<GenerateLabelProps> = observer((props: GenerateLabelProps) => {

  const { open, onClose, index, onSubmit} = props
  const { t } = useTranslation()

  const {
    sampleShipmentStore: {
      samplesToBeShipment, setSamplesToBeShipmentGeneratelabel
    }
  } = useStores()

  const [data, setData] = useState([{attribute: "", status: false, value: ""}]);
  const refInputData = useRef('')

  const dataSamples = samplesToBeShipment[index]['sample']

  const defaultData = [
    {
      attribute: "qrcode",
      status: true,
      value: samplesToBeShipment[index]["qrcode"]
    },
    {
      attribute: "sampleName",
      status: isEmpty(samplesToBeShipment[index]["sampleName"])? false : true,
      value: samplesToBeShipment[index]["sampleName"]
    },
    {
      attribute: "sampleType",
      status: isEmpty(samplesToBeShipment[index]["sampleType"])? false : true,
      value: samplesToBeShipment[index]["sampleType"]
    },
    {
      attribute: "country",
      status: isEmpty(dataSamples["countryCode"])? false : true,
      value: dataSamples["countryCode"]
    },
    {
      attribute: "grade",
      status: isEmpty(dataSamples["grade"])? false : true,
      value: dataSamples["grade"]
    },
    {
      attribute: "coffeeProcessing",
      status: isEmpty(dataSamples["processName"])? false : true,
      value: dataSamples["processName"]
    },
    {
      attribute: "cropYear",
      status: isEmpty(dataSamples["cropYear"])? false : true,
      value: dataSamples["cropYear"]
    },
    {
      attribute: "species",
      status: isEmpty(dataSamples["species"])? false : true,
      value: dataSamples["species"]
    },
    {
      attribute: "varietals",
      status: isEmpty(dataSamples["varietalsTags"])? false : true,
      value: dataSamples["varietalsTags"]
    },
    {
      attribute: "moisture",
      status: isEmpty(dataSamples["moisture"])? false : true,
      value: dataSamples["moisture"]
    },
    {
      attribute: "waterActivity",
      status: isEmpty(dataSamples["waterActivity"])? false : true,
      value: dataSamples["waterActivity"]
    },
    {
      attribute: "numberOfBags",
      status: isEmpty(dataSamples["numberOfBag"])? false : true,
      value: dataSamples["numberOfBag"]
    },
    {
      attribute: "bagWeight",
      status: isEmpty(dataSamples["bagWeight"])? false : true,
      value: dataSamples["bagWeight"]
    },
    {
      attribute: "customer",
      status: isEmpty(dataSamples["customer"])? false : true,
      value: dataSamples["customer"]
    },
    {
      attribute: "salesContractReference",
      status: isEmpty(dataSamples["salesContractReference"])? false : true,
      value: dataSamples["salesContractReference"]
    },
    {
      attribute: "purchaseContractReference",
      status: isEmpty(dataSamples["purchaseContractReference"])? false : true,
      value: dataSamples["purchaseContractReference"]
    }
  ]

  const columnHelper = createColumnHelper<any>()

  const getSymbol = (index) => {
    switch (index) {
      case "9":
        return "%"
      case "12":
        return "kg"
      default:
        return ""

    }

  }

  const renderEditStatus = (info) => {
    const key = 'value' + info.row.index

    return(
      <Checkbox sx={{
        color: "#8D57B2",
        '&.Mui-checked': {
          color: "#8D57B2",
        },
      }}
        checked={info.renderValue()}
        onChange={(event) => {
          refInputData.current = key
          handlerUpdate(info, event.target.checked)
        }
      }
      />
    )
  }

  const renderEditData = (info) => {
    const key = 'value' + info.row.index
    switch (info.row.id) {
      case "0":
        return(
          <Box></Box>
        )
      case "2":
        return(
          <Select
            fullWidth
            size='small'
            autoFocus={key === refInputData.current}
            defaultValue=""
            value={info.renderValue()}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdate(info, e.target.value)
            }}
          >
            <MenuItem value='' sx={{ height: 34 }} />
            {sampleTypes.map(item =>
              <MenuItem key={item} value={item}>{t(`options.sampleType.${camelCase(item)}`)}</MenuItem>
            )}
          </Select>
        )
      case "3":
        return(
          <Select
            fullWidth
            size='small'
            autoFocus={key === refInputData.current}
            defaultValue=""
            value={info.renderValue()}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdate(info, e.target.value)
            }}
          >
            <MenuItem value='' sx={{height: 34}} />
            {countries.map(item =>
              <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
            )}
          </Select>
        )
      case "4":
        return(
          <Select
            fullWidth
            size='small'
            autoFocus={key === refInputData.current}
            defaultValue=""
            value={info.renderValue()}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdate(info, e.target.value)
            }}
          >
            <MenuItem value='' sx={{ height: 34 }} />
            {grades.map(item =>
              <MenuItem key={item} value={item}>{t(`options.grades.${item}`)}</MenuItem>
            )}
          </Select>
        )
      case "6":
        return(
          <Select
            fullWidth
            size='small'
            autoFocus={key === refInputData.current}
            defaultValue=""
            value={info.renderValue()}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdate(info, e.target.value)
            }}
          >
            <MenuItem value='' sx={{height: 34}} />
            {range((new Date()).getFullYear(), 2010).map(item =>
              <MenuItem key={item} value={toString(item)}>{item}</MenuItem>
            )}
          </Select>
        )
      case "7":
        return(
          <Select
            fullWidth
            size='small'
            autoFocus={key === refInputData.current}
            defaultValue=""
            value={info.renderValue()}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdate(info, e.target.value)
            }}
          >
            <MenuItem value='' sx={{ height: 34 }} />
            {specieses.map(item =>
              <MenuItem key={item} value={item}>{t(`options.species.${item}`)}</MenuItem>
            )}
          </Select>
        )
      case "9":
      case "10":
      case "11":
      case "12":
        return(
          <CurrencyTextField
            fullWidth
            size='small'
            type='text'
            inputMode='numeric'
            variant='outlined'
            textAlign='left'
            currencySymbol=''
            autoFocus={key === refInputData.current}
            value={info.renderValue()}
            outputFormat="string"
            decimalCharacter="."
            digitGroupSeparator=","
            decimalPlaces={0}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdate(info, e.target.value)
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {getSymbol(info.row.id)}
                </InputAdornment>)
            }}
          />
        )
      default:
        return(
          <TextField
            fullWidth
            size='small'
            autoFocus={key === refInputData.current}
            value={info.renderValue()}
            onChange={(e) => {
              refInputData.current = key
              handlerUpdate(info, e.target.value)
            }}
          />
        )
    }

  }

  const columns = [
    columnHelper.accessor('status', {
      id: 'status',
      header: () => <Box textAlign="left">{t('shipment.sharedInformation.includeInLabels')}</Box>,
      cell: renderEditStatus,
    }),
    columnHelper.accessor('attribute', {
      id: 'attribute',
      header: () => <Box textAlign="left">{t('shipment.sharedInformation.information')}</Box>,
      cell: info => t(`shipment.sharedInformation.${info.renderValue()}`) ,
    }),
    columnHelper.accessor('value', {
      id: 'value',
      header: () => <Box textAlign="left">{t('shipment.sharedInformation.data')}</Box>,
      cell: renderEditData,
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const handlerUpdate= (info, value) =>{
    let newArr = [...data];
    newArr[info.row.id][info.column.id] = value;

    setData([...newArr]);
  }

  React.useEffect(() => {

    if(isEmpty(JSON.parse(samplesToBeShipment[index].label))){
      setData(defaultData)
      return
    }
    setData(JSON.parse(samplesToBeShipment[index].label))

  }, [index])

  return (
    <ModalWrapper
      maxWidth='md'
      open={open}
    >
      <Typography>{t('shipment.sharedInformation.title')}</Typography>
      <Typography sx={{ fontWeight: 200 }}>{t('shipment.sharedInformation.sharedInformationDescription')}</Typography>
      <Box sx={{borderRadius: 2, border: 1, p: 2, mt: 2, borderColor: colors.text.o25}}>
        <BasicTable table={table} />
      </Box>
      <Stack direction="row" spacing={2} sx={{mb: 1, mt: 4}}>
        <Button
          variant='contained'
          fullWidth
          onClick={() =>{
            setSamplesToBeShipmentGeneratelabel(index, data)
            onSubmit()
          }}
        >
          {t('shipment.button.submit')}
        </Button>
        <Button variant='outlined' fullWidth onClick={() =>{onClose()}}>
          {t('shipment.button.cancel')}
        </Button>
      </Stack>
    </ModalWrapper>
  )
})