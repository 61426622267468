/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-05 04:08:45
 * @modify date 2022-04-05 04:08:45
 */

import React from 'react'
import moment from 'moment'
import clsx from "clsx"
import { range, camelCase, isBoolean, isEmpty, isString, isArray, includes } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Clear as ClearIcon, Search as SearchIcon, DeleteOutline as DeleteIcon } from '@mui/icons-material'
import {
  Button,
  MenuItem,
  Grid,
  Card,
  Box,
  Typography,
  CardContent,
  TextField,
  Select,
  InputAdornment,
  SvgIcon,
  Stack,
  IconButton,
  Container,
  Divider,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'
import ReactHtmlParser from 'react-html-parser'

import { useStores } from 'models'
import { FormInput, ModalWrapper, DescriptorFilter } from 'components'
import { IconSampleInfo } from 'assets/images'
import { textDescriptorsTranslation, countries } from 'utils'
import { specieses, sampleTypes, statuses } from 'constants/form'

import { acidityDescriptorList } from 'constants/acidity-descriptors'
import { aftertasteDescriptorList } from 'constants/aftertaste-descriptors'
import { bodyDescriptorList } from 'constants/body-descriptors'
import { flavorDescriptorList } from 'constants/flavor-descriptors'
import { defectDescriptorList } from 'constants/defect-descriptors'

import { useStyles } from './advance-search.styles'
import { AdvanceSearchProps, searchValueProps } from './advance-search.props'
import { colors } from 'assets'

export const AdvanceSearch: React.FC<AdvanceSearchProps> = observer((props: AdvanceSearchProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    isMobile,
    expanded,
    temporarySearchAdvanceData,
    temporaryDefectsDescriptors,
    temporaryFlavorAndAftertasteDescriptors,
    temporaryFragranceDescriptors,
    temporaryBodyAndMouthfeelDescriptors,
    temporaryAcidityAndSaltDescriptors,
    temporaryListSearch,
    addTemporarySearchAdvanceData,
    addTemporaryDefectsDescriptors,
    addTemporaryFlavorAndAftertasteDescriptors,
    addTemporaryFragranceDescriptors,
    addTemporaryBodyAndMouthfeelDescriptors,
    addTemporaryAcidityAndSaltDescriptors,
    addTemporaryListSearch,
    onSearch,
    onCancel,
    onSelect,
    isLoading
  } = props
  const {
    userStore: { languagePreference },
    masterSampleStore: { getSearchMasterSamples,searchMasterSamples, searchValues, search: {
      name, regionName, scoredByMe, species, receivedOn, sampleType, cropYear, numberOfBags, reference, shippingInfo,
      waterActivity, moisture, mass, volume, countryCode, status, externalId, sampleId, producerName, bagWeight,
      grade, processName, supplierName, notesAndRemarks, averageScoreMin, averageScoreMax,
      setValue: setSearchValue, reset
    }}
  } = useStores()

  const [openAcidity, setOpenAcidity] = React.useState(false)
  const [openBody, setOpenBody] = React.useState(false)
  const [openFragrance, setOpenFragrance] = React.useState(false)
  const [openFlavor, setOpenFlavor] = React.useState(false)
  const [openDefect, setOpenDefect] = React.useState(false)

  const [selectSampleNames, setselectSampleNames] = React.useState('')
  const [selectScoredByMes, setSelectScoredByMes] = React.useState('');
  const [selectSpeciess, setSelectSpeciess] = React.useState('');
  const [selectValueSampleType, setSelectValueSampleType] = React.useState('');
  const [selectValueReceivedOn, setSelectValueReceivedOn] = React.useState('');
  const [selectValueCropYear, setSelectValueCropYear] = React.useState('');
  const [selectValueNumberOfBags, setSelectValueNumberOfBags] = React.useState('')
  const [selectValueReference, setSelectValueReference] = React.useState('')
  const [selectValueWaterActivity, setSelectValueWaterActivity] = React.useState('')
  const [selectValueMoisture, setSelectValueMoisture] = React.useState('')
  const [selectValueMass, setSelectValueMass] = React.useState('')
  const [selectValueVolume, setSelectValueVolume] = React.useState('')
  const [selectValueCountryCode, setSelectValueCountryCode] = React.useState('')
  const [selectValueStatus, setSelectValueStatus] = React.useState('')
  const [selectValueExternalId, setSelectValueExternalId] = React.useState('')
  const [selectValueSampleId, setSelectValueSampleId] = React.useState('')
  const [selectValueProducerName, setSelectValueProducerName] = React.useState('')
  const [selectValueBagWeight, setSelectValueBagWeight] = React.useState('')
  const [selectValueGrade, setSelectValueGrade] = React.useState('')
  const [selectValueProcessName, setSelectValueProcessName] = React.useState('')
  const [selectValueSupplierName, setSelectValueSupplierName] = React.useState('')
  const [selectValueNotesAndRemarks, setSelectValueNotesAndRemarks] = React.useState('')
  const [selectValueAverageScoreMin, setSelectValueAverageScoreMin] = React.useState('')
  const [selectValueAverageScoreMax, setSelectValueAverageScoreMax] = React.useState('')
  const [selectValueAcidityAndSaltDescriptors, setSelectValueAcidityAndSaltDescriptors] = React.useState('')
  const [selectValueBodyAndMouthfeelDescriptors, setSelectValueBodyAndMouthfeelDescriptors] = React.useState('')
  const [selectValueFragranceDescriptors, setSelectValueFragranceDescriptors] = React.useState('')
  const [selectValueFlavorAndAftertasteDescriptors, setSelectValueFlavorAndAftertasteDescriptors] = React.useState('')
  const [selectValueDefectsDescriptors, setSelectValueDefectsDescriptors] = React.useState('')

  const handleChange = (id) => {
    deleteSelectContent(id)
    switch (id) {
      case 'sampleName':
        setselectSampleNames('');
        setSearchValue("name", '');
        break
      case 'scoredByMe':
        setSelectScoredByMes('');
        setSearchValue('scoredByMe', false)
        break
      case 'species':
        setSelectSpeciess('')
        setSearchValue('species', '')
        break
      case 'sampleType':
        setSelectValueSampleType('')
        setSearchValue('sampleType', '')
        break
      case 'receivedOn':
        setSelectValueReceivedOn('')
        setSearchValue('receivedOn', '')
        break
      case 'cropYear':
        setSelectValueCropYear('')
        setSearchValue('cropYear', '')
        break
      case 'numberOfBags':
        setSelectValueNumberOfBags('')
        setSearchValue('numberOfBags', '')
        break
      case 'reference':
        setSelectValueReference('')
        setSearchValue('reference', '')
        break
      case 'waterActivity':
        setSelectValueWaterActivity('')
        setSearchValue('waterActivity', '')
        break
      case 'moisture':
        setSelectValueMoisture('')
        setSearchValue('moisture', '')
        break
      case 'mass':
        setSelectValueMass('')
        setSearchValue('mass', '')
        break
      case 'volume':
        setSelectValueVolume('')
        setSearchValue('volume', '')
        break
      case 'countryCode':
        setSelectValueCountryCode('')
        setSearchValue('countryCode', '')
        break
      case 'status':
        setSelectValueStatus('')
        setSearchValue('status', '')
        break
      case 'externalId':
        setSelectValueExternalId('')
        setSearchValue('externalId', '')
        break
      case 'sampleId':
        setSelectValueSampleId('')
        setSearchValue('sampleId', '')
        break
      case 'producerName':
        setSelectValueProducerName('')
        setSearchValue('producerName', '')
        break
      case 'bagWeight':
        setSelectValueBagWeight('')
        setSearchValue('bagWeight', '')
        break
      case 'grade':
        setSelectValueGrade('')
        setSearchValue('grade', '')
        break
      case 'processName':
        setSelectValueProcessName('')
        setSearchValue('processName', '')
        break
      case 'supplierName':
        setSelectValueSupplierName('')
        setSearchValue('supplierName', '')
        break
      case 'notesAndRemarks':
        setSelectValueNotesAndRemarks('')
        setSearchValue('notesAndRemarks', '')
        break
      case 'averageScoreMin':
        setSelectValueAverageScoreMin('')
        setSearchValue('averageScoreMin', '')
        break
      case 'averageScoreMax':
        setSelectValueAverageScoreMax('')
        setSearchValue('averageScoreMax', '')
        break
      case 'acidityAndSaltDescriptors':
        setSelectValueAcidityAndSaltDescriptors('')
        setSearchValue('acidityAndSaltDescriptors', '')
        addTemporaryAcidityAndSaltDescriptors([])
        break
      case 'bodyAndMouthfeelDescriptors':
        setSelectValueBodyAndMouthfeelDescriptors('')
        setSearchValue('bodyAndMouthfeelDescriptors', '')
        addTemporaryBodyAndMouthfeelDescriptors([])
        break
      case 'fragranceDescriptors':
        setSelectValueFragranceDescriptors('')
        setSearchValue('fragranceDescriptors', '')
        addTemporaryFragranceDescriptors([])
        break
      case 'flavorAndAftertasteDescriptors':
        setSelectValueFlavorAndAftertasteDescriptors('')
        setSearchValue('flavorAndAftertasteDescriptors', '')
        addTemporaryFlavorAndAftertasteDescriptors([])
        break
      case 'defectsDescriptors':
        setSelectValueDefectsDescriptors('')
        setSearchValue('defectsDescriptors', '')
        addTemporaryDefectsDescriptors([])
        break

    }

  };

  const searchAdvanceData: searchValueProps[] = [
    {
      id: 'sampleName',
      label: t('sample.sampleName'),
      key: 'name',
      value: name
    },
    {
      id: 'scoredByMe',
      label: t('sample.scoredByMe'),
      value: scoredByMe
    },
    {
      id: 'species',
      label: t('sample.species'),
      value: species
    },
    // {
    //   id: 'receivedOn',
    //   label: t('sample.receivedOn'),
    //   value: receivedOn
    // },
    {
      id: 'sampleType',
      label: t('sample.sampleType'),
      value: sampleType
    },
    {
      id: 'cropYear',
      label: t('sample.cropYear'),
      value: cropYear
    },
    {
      id: 'numberOfBags',
      label: t('sample.numberOfBags'),
      value: numberOfBags
    },
    {
      id: 'reference',
      label: t('sample.reference'),
      value: reference
    },
    {
      id: 'waterActivity',
      label: t('sample.waterActivity'),
      value: waterActivity
    },
    {
      id: 'moisture',
      label: `${t('sample.moisture')} (%)`,
      value: moisture
    },
    {
      id: 'mass',
      label: `${t('sample.mass')} (gr)`,
      value: mass
    },
    {
      id: 'volume',
      label: `${t('sample.volume')} (mL)`,
      value: volume
    },
    {
      id: 'countryCode',
      label: t('sample.country'),
      value: countryCode
    },
    {
      id: 'status',
      label: t('sample.status.title'),
      value: status
    },
    {
      id: 'externalId',
      label: t('sample.externalId'),
      value: externalId
    },
    {
      id: 'sampleId',
      label: t('sample.sampleId'),
      value: sampleId
    },
    {
      id: 'producerName',
      label: t('sample.producerName'),
      value: producerName
    },
    {
      id: 'bagWeight',
      label: t('sample.bagWeight'),
      value: bagWeight
    },
    {
      id: 'grade',
      label: t('sample.grade'),
      value: grade
    },
    {
      id: 'processName',
      label: t('sample.coffeeProcessing'),
      value: processName
    },
    {
      id: 'supplierName',
      label: t('sample.supplier'),
      value: supplierName
    },
    {
      id: 'notesAndRemarks',
      label: t('sample.notesAndRemarks'),
      value: notesAndRemarks
    },
    {
      id: 'averageScoreMin',
      label: t('sample.averageScoreMin'),
      value: averageScoreMin
    },
    {
      id: 'averageScoreMax',
      label: t('sample.averageScoreMax'),
      value: averageScoreMax
    },
    {
      id: 'acidityAndSaltDescriptors',
      label: t('sample.aciditySaltAcidDescriptors'),
      value: temporaryAcidityAndSaltDescriptors
    },
    {
      id: 'bodyAndMouthfeelDescriptors',
      label: t('sample.bodyMouthfeelDescriptors'),
      value: temporaryBodyAndMouthfeelDescriptors
    },
    {
      id: 'fragranceDescriptors',
      label: t('sample.fragranceDescriptors'),
      value: temporaryFragranceDescriptors
    },
    {
      id: 'flavorAndAftertasteDescriptors',
      label: t('sample.flavorAftertasteDescriptors'),
      value: temporaryFlavorAndAftertasteDescriptors
    },
    {
      id: 'defectsDescriptors',
      label: t('sample.defectsDescriptors'),
      value: temporaryDefectsDescriptors
    }
  ]

  const renderSearchMaster = () => {
    return (
      <Box>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">{t('sample.masterSample.name')}</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchMasterSamples.map(data => (
                <TableRow key={data.id}>
                  <TableCell align="left">{data.name}</TableCell>
                  <TableCell align="right">
                    <Button
                    variant='outlined'
                    className='wide'
                    onClick={() => {
                      onSelect(data)
                      resetValues()
                      onCancel()
                    }}
                  >
                    {t('common.select')}
                  </Button>
                  </TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }

  const resetValues = () => {
    reset()
    temporaryListSearch.map(item =>
         handleChange(item)
    )
    addTemporaryListSearch([])
    onSearch()
  }

  const onEnter = (event) => {
    if (event.key !== 'Enter') return

    onSearch()
  }

  const renderDescriptorModals = (
    <>
      <DescriptorFilter
        key={`acidityAndSaltDescriptors${temporaryAcidityAndSaltDescriptors}`}
        name='acidityAndSaltDescriptors'
        open={openAcidity}
        onClose={() => setOpenAcidity(false)}
        language={languagePreference}
        descriptors={temporaryAcidityAndSaltDescriptors}
        descriptorList={acidityDescriptorList}
        addDescriptor={(descriptors) => {
          setSearchValue('acidityAndSaltDescriptors', textDescriptorsTranslation('en', descriptors))
          addTemporaryAcidityAndSaltDescriptors(descriptors)
        }}
      />
      <DescriptorFilter
        key={`bodyAndMouthfeelDescriptors${temporaryBodyAndMouthfeelDescriptors}`}
        name='bodyAndMouthfeelDescriptors'
        open={openBody}
        onClose={() => setOpenBody(false)}
        language={languagePreference}
        descriptors={temporaryBodyAndMouthfeelDescriptors}
        descriptorList={bodyDescriptorList}
        addDescriptor={(descriptors) => {
          setSearchValue('bodyAndMouthfeelDescriptors', textDescriptorsTranslation('en', descriptors))
          addTemporaryBodyAndMouthfeelDescriptors(descriptors)
        }}
      />
      <DescriptorFilter
        key={`fragranceDescriptors${temporaryFragranceDescriptors}`}
        name='fragranceDescriptors'
        open={openFragrance}
        onClose={() => setOpenFragrance(false)}
        language={languagePreference}
        descriptors={temporaryFragranceDescriptors}
        descriptorList={flavorDescriptorList}
        addDescriptor={(descriptors) => {
          setSearchValue('fragranceDescriptors', textDescriptorsTranslation('en', descriptors))
          addTemporaryFragranceDescriptors(descriptors)
        }}
      />
      <DescriptorFilter
        key={`flavorAndAftertasteDescriptors${temporaryFlavorAndAftertasteDescriptors}`}
        name='flavorAndAftertasteDescriptors'
        open={openFlavor}
        onClose={() => setOpenFlavor(false)}
        language={languagePreference}
        descriptors={temporaryFlavorAndAftertasteDescriptors}
        descriptorList={aftertasteDescriptorList}
        addDescriptor={(descriptors) => {
          setSearchValue('flavorAndAftertasteDescriptors', textDescriptorsTranslation('en', descriptors))
          addTemporaryFlavorAndAftertasteDescriptors(descriptors)
        }}
      />
      <DescriptorFilter
        key={`defectsDescriptors${temporaryDefectsDescriptors}`}
        name='defectsDescriptors'
        open={openDefect}
        onClose={() => setOpenDefect(false)}
        language={languagePreference}
        descriptors={temporaryDefectsDescriptors}
        descriptorList={defectDescriptorList}
        addDescriptor={(descriptors) => {
          setSearchValue('defectsDescriptors', textDescriptorsTranslation('en', descriptors))
          addTemporaryDefectsDescriptors(descriptors)
        }}
      />
    </>
  )

  const selectContent = (id) => {
    switch (id) {
      case 'sampleName':
        setselectSampleNames(id);
        break
      case 'scoredByMe':
        setSelectScoredByMes(id);
        break
      case 'species':
        setSelectSpeciess(id)
        break
      case 'sampleType':
        setSelectValueSampleType(id)
        break
      case 'receivedOn':
        setSelectValueReceivedOn(id)
        break
      case 'cropYear':
        setSelectValueCropYear(id)
        break
      case 'numberOfBags':
        setSelectValueNumberOfBags(id)
        break
      case 'reference':
        setSelectValueReference(id)
        break
      case 'waterActivity':
        setSelectValueWaterActivity(id)
        break
      case 'moisture':
        setSelectValueMoisture(id)
        break
      case 'mass':
        setSelectValueMass(id)
        break
      case 'volume':
        setSelectValueVolume(id)
        break
      case 'countryCode':
        setSelectValueCountryCode(id)
        break
      case 'status':
        setSelectValueStatus(id)
        break
      case 'externalId':
        setSelectValueExternalId(id)
        break
      case 'sampleId':
        setSelectValueSampleId(id)
        break
      case 'producerName':
        setSelectValueProducerName(id)
        break
      case 'bagWeight':
        setSelectValueBagWeight(id)
        break
      case 'grade':
        setSelectValueGrade(id)
        break
      case 'processName':
        setSelectValueProcessName(id)
        break
      case 'supplierName':
        setSelectValueSupplierName(id)
        break
      case 'notesAndRemarks':
        setSelectValueNotesAndRemarks(id)
        break
      case 'averageScoreMin':
        setSelectValueAverageScoreMin(id)
        break
      case 'averageScoreMax':
        setSelectValueAverageScoreMax(id)
        break
      case 'acidityAndSaltDescriptors':
        setSelectValueAcidityAndSaltDescriptors(id)
        break
      case 'bodyAndMouthfeelDescriptors':
        setSelectValueBodyAndMouthfeelDescriptors(id)
        break
      case 'fragranceDescriptors':
        setSelectValueFragranceDescriptors(id)
        break
      case 'flavorAndAftertasteDescriptors':
        setSelectValueFlavorAndAftertasteDescriptors(id)
        break
      case 'defectsDescriptors':
        setSelectValueDefectsDescriptors(id)
        break
    }
  }

  const deleteSelectContent = (item) => {
    addTemporaryListSearch(current =>
      current.filter(data => {
        return data !== item;
      }),
    );

  }

  const buttonDeleteDesktop = (item) => {
    return(
      <>
        <Grid item xs={12} sx={{ textAlign: 'center', mb: 5 }}>
          <Button
            size='medium'
            onClick={() => handleChange(item)}>
            <Typography variant='body3'>{t('common.delete')}</Typography>
            <DeleteIcon sx={{ color: 'primary', width: '16.17px', height: '18.5px', ml: 1 }} />
          </Button>
        </Grid>
      </>
    )
  }

  const buttonDeleteMobile = (item) => {
    return (
      <>
        <Grid item xs={2} className={classes.gridButton}>
          <IconButton size='small' className={classes.button} onClick={() => handleChange(item)}>
            <DeleteIcon sx={{ color: '#8D57B2' }} />
          </IconButton>
        </Grid>
      </>
    )
  }

  const buttonClearAllMobile = () => {
    return (
      <>
        <Button
          fullWidth
          size='small'
          variant="outlined"
          startIcon={<ClearIcon sx={{color: '#E74C3C'}}/>}
          onClick={resetValues}
        >
          {t('sample.clearAllFilters')}
        </Button>
      </>
    )
  }

  const renderSelectionContentDesktop = (item) => {
    return (
      <>
        {(item['name'] || !isEmpty(selectSampleNames)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12} >
                <Grid item xs={12}>
                  {selectBox(item['name'] ? "sampleName" : selectSampleNames)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput
                    textFieldProps={{
                      value: name,
                      onChange: (e) => setSearchValue('name', e.target.value),
                    }}
                  />
                </Grid>
                {buttonDeleteDesktop('sampleName')}

              </Grid>
            </Grid>
          </Box>
        }

        {(item['scoredByMe'] || !isEmpty(selectScoredByMes)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['scoredByMe'] ? "scoredByMe" : selectScoredByMes)}
                </Grid>
                <Grid item xs={12} >
                <Button
                  size='small'
                  variant='outlined'
                  className={clsx(classes.buttonSearch, scoredByMe ? 'active' : 'inactive')}
                  onClick={() => setSearchValue('scoredByMe', !scoredByMe)}
                  sx={{ width: 'fit-content',mt: 1.5, mb: 1 }}
                >
                  {t('sample.show')}
                </Button>
                </Grid>
                {buttonDeleteDesktop('scoredByMe')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['species'] || !isEmpty(selectSpeciess)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['species'] ? "species" : selectSpeciess)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput>
                    <Select
                      size='small'
                      value={species}
                      onChange={(e) => setSearchValue('species', e.target.value)}
                    >
                      <MenuItem value='' sx={{ height: 34 }} />
                      {specieses.map(item =>
                        <MenuItem key={item} value={item}>{t(`options.species.${camelCase(item)}`)}</MenuItem>
                      )}
                    </Select>
                  </FormInput>
                </Grid>
                {buttonDeleteDesktop('species') }
              </Grid>
            </Grid>
          </Box>
        }
        {(item['sampleType'] || !isEmpty(selectValueSampleType)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['sampleType'] ? "sampleType" : selectValueSampleType)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput>
                    <Select
                      size='small'
                      value={sampleType || ""}
                      onChange={(e) => setSearchValue('sampleType', e.target.value)}
                    >
                      <MenuItem value='' sx={{ height: 34 }} />
                      {sampleTypes.map(item =>
                        <MenuItem key={item} value={item}>{t(`options.sampleType.${camelCase(item)}`)}</MenuItem>
                      )}
                    </Select>
                  </FormInput>
                </Grid>
                {buttonDeleteDesktop('sampleType')}
              </Grid>
            </Grid>
          </Box>
        }

        {(item['receivedOn'] || !isEmpty(selectValueReceivedOn)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['receivedOn'] ? "receivedOn" : selectValueReceivedOn)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput>
                    <DatePicker
                      mask='____/__/__'
                      inputFormat="yyyy/MM/DD"
                      value={receivedOn}
                      onChange={(value) => {
                        setSearchValue('receivedOn', value ? moment(value?.valueOf()).format('YYYY/MM/DD') : '')
                      }}
                      renderInput={(params) => <TextField {...params} size='small' error={false} />}
                    />
                  </FormInput>
                </Grid>
                {buttonDeleteDesktop('receivedOn')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['cropYear'] || !isEmpty(selectValueCropYear)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['cropYear'] ? "cropYear" : selectValueCropYear)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput>
                    <Select
                      size='small'
                      value={cropYear}
                      onChange={(e) => setSearchValue('cropYear', e.target.value)}
                    >
                      <MenuItem value='' sx={{ height: 34 }} />
                      {range((new Date()).getFullYear(), 2010).map(item =>
                        <MenuItem key={item} value={item.toString()}>{item}</MenuItem>
                      )}
                    </Select>
                  </FormInput>
                </Grid>
                {buttonDeleteDesktop('cropYear')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['numberOfBags'] || !isEmpty(selectValueNumberOfBags)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['numberOfBags'] ? "numberOfBags" : selectValueNumberOfBags)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput>
                    <CurrencyTextField
                      key='numberOfBags'
                      fullWidth
                      size='small'
                      type='text'
                      inputMode='numeric'
                      variant='outlined'
                      textAlign='left'
                      currencySymbol=''
                      value={numberOfBags}
                      outputFormat="string"
                      decimalCharacter="."
                      digitGroupSeparator=","
                      decimalPlaces={0}
                      onChange={(event, value) => setSearchValue('numberOfBags', value)}
                    />
                  </FormInput>
                </Grid>
                {buttonDeleteDesktop('numberOfBags')}

              </Grid>
            </Grid>
          </Box>
        }
        {(item['reference'] || !isEmpty(selectValueReference)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['reference'] ? "reference" : selectValueReference)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput

                    textFieldProps={{
                      value: reference,
                      onChange: (e) => setSearchValue('reference', e.target.value)
                    }}
                  />
                </Grid>
               {buttonDeleteDesktop('reference')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['waterActivity'] || !isEmpty(selectValueWaterActivity)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['waterActivity'] ? "waterActivity" : selectValueWaterActivity)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput>
                    <CurrencyTextField
                      key='waterActivity'
                      fullWidth
                      size='small'
                      type='text'
                      inputMode='numeric'
                      variant='outlined'
                      textAlign='left'
                      currencySymbol=''
                      value={waterActivity}
                      outputFormat="string"
                      decimalCharacter="."
                      digitGroupSeparator=","
                      onChange={(event, value) => setSearchValue('waterActivity', value)}
                    />
                  </FormInput>
                </Grid>
               {buttonDeleteDesktop('waterActivity')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['moisture'] || !isEmpty(selectValueMoisture)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['moisture'] ? "moisture" : selectValueMoisture)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput>
                    <CurrencyTextField
                      key='moisture'
                      fullWidth
                      size='small'
                      type='text'
                      inputMode='numeric'
                      variant='outlined'
                      textAlign='left'
                      currencySymbol=''
                      value={moisture}
                      outputFormat="string"
                      decimalCharacter="."
                      digitGroupSeparator=","
                      onChange={(event, value) => setSearchValue('moisture', value)}
                      InputProps={{
                        endAdornment: (<InputAdornment position="end">%</InputAdornment>)
                      }}
                    />
                  </FormInput>
                </Grid>
                {buttonDeleteDesktop('moisture')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['mass'] || !isEmpty(selectValueMass)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['mass'] ? "mass" : selectValueMass)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput>
                    <CurrencyTextField
                      key='mass'
                      fullWidth
                      size='small'
                      type='text'
                      inputMode='numeric'
                      variant='outlined'
                      textAlign='left'
                      currencySymbol=''
                      value={mass}
                      outputFormat="string"
                      decimalCharacter="."
                      digitGroupSeparator=","
                      onChange={(event, value) => setSearchValue('mass', value)}
                      InputProps={{
                        endAdornment: (<InputAdornment position="end">gr</InputAdornment>)
                      }}
                    />
                  </FormInput>
                </Grid>
                {buttonDeleteDesktop('mass')}

              </Grid>
            </Grid>
          </Box>
        }
        {(item['volume'] || !isEmpty(selectValueVolume)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['volume'] ? "volume" : selectValueVolume)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput>
                    <CurrencyTextField
                      key='volume'
                      fullWidth
                      size='small'
                      type='text'
                      inputMode='numeric'
                      variant='outlined'
                      textAlign='left'
                      currencySymbol=''
                      value={volume}
                      outputFormat="string"
                      decimalCharacter="."
                      digitGroupSeparator=","
                      onChange={(event, value) => setSearchValue('volume', value)}
                      InputProps={{
                        endAdornment: (<InputAdornment position="end">mL</InputAdornment>)
                      }}
                    />
                  </FormInput>
                </Grid>
                {buttonDeleteDesktop('volume')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['countryCode'] || !isEmpty(selectValueCountryCode)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['countryCode'] ? "countryCode" : selectValueCountryCode)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput>
                    <Select
                      size='small'
                      value={countryCode}
                      onChange={(e) => setSearchValue('countryCode', e.target.value)}
                    >
                      <MenuItem value='' sx={{ height: 34 }} />
                      {countries.map(item =>
                        <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
                      )}
                    </Select>
                  </FormInput>
                </Grid>
                {buttonDeleteDesktop('countryCode')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['status'] || !isEmpty(selectValueStatus)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['status'] ? "status" : selectValueStatus)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput>
                    <Select
                      size='small'
                      value={status}
                      onChange={(e) => setSearchValue('status', e.target.value)}
                    >
                      <MenuItem value='' sx={{ height: 34 }} />
                      {statuses.map(item =>
                        <MenuItem key={item} value={item}>{t(`sample.status.${camelCase(item)}`)}</MenuItem>
                      )}
                    </Select>
                  </FormInput>
                </Grid>
                {buttonDeleteDesktop('status')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['externalId'] || !isEmpty(selectValueExternalId)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['externalId'] ? "externalId" : selectValueExternalId)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput
                    textFieldProps={{
                      value: externalId,
                      onChange: (e) => setSearchValue('externalId', e.target.value)
                    }}
                  />
                </Grid>
                {buttonDeleteDesktop('externalId')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['sampleId'] || !isEmpty(selectValueSampleId)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['sampleId'] ? "sampleId" : selectValueSampleId)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput
                    textFieldProps={{
                      value: sampleId,
                      onChange: (e) => setSearchValue('sampleId', e.target.value)
                    }}
                  />
                </Grid>
                {buttonDeleteDesktop('sampleId')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['producerName'] || !isEmpty(selectValueProducerName)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['producerName'] ? "producerName" : selectValueProducerName)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput
                    textFieldProps={{
                      value: producerName,
                      onChange: (e) => setSearchValue('producerName', e.target.value)
                    }}
                  />
                </Grid>
                {buttonDeleteDesktop('producerName')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['bagWeight'] || !isEmpty(selectValueBagWeight)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['bagWeight'] ? "bagWeight" : selectValueBagWeight)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput>
                    <CurrencyTextField
                      key='bagWeight'
                      fullWidth
                      size='small'
                      type='text'
                      inputMode='numeric'
                      variant='outlined'
                      textAlign='left'
                      currencySymbol=''
                      value={bagWeight}
                      outputFormat="string"
                      decimalCharacter="."
                      digitGroupSeparator=","
                      onChange={(event, value) => setSearchValue('bagWeight', value)}
                    />
                  </FormInput>
                </Grid>
                {buttonDeleteDesktop('bagWeight')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['grade'] || !isEmpty(selectValueGrade)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['grade'] ? "grade" : selectValueGrade)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput
                    textFieldProps={{
                      value: grade,
                      onChange: (e) => setSearchValue('grade', e.target.value)
                    }}
                  />
                </Grid>
                {buttonDeleteDesktop('grade')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['processName'] || !isEmpty(selectValueProcessName)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['processName'] ? "processName" : selectValueProcessName)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput
                    textFieldProps={{
                      value: processName,
                      onChange: (e) => setSearchValue('processName', e.target.value)
                    }}
                  />
                </Grid>
                {buttonDeleteDesktop('processName')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['supplierName'] || !isEmpty(selectValueSupplierName)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['supplierName'] ? "supplierName" : selectValueSupplierName)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput
                    textFieldProps={{
                      value: supplierName,
                      onChange: (e) => setSearchValue('supplierName', e.target.value)
                    }}
                  />
                </Grid>
                {buttonDeleteDesktop('supplierName')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['notesAndRemarks'] || !isEmpty(selectValueNotesAndRemarks)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['notesAndRemarks'] ? "notesAndRemarks" : selectValueNotesAndRemarks)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput
                    textFieldProps={{
                      value: notesAndRemarks,
                      onChange: (e) => setSearchValue('notesAndRemarks', e.target.value)
                    }}
                  />
                </Grid>
                {buttonDeleteDesktop('notesAndRemarks') }
              </Grid>
            </Grid>
          </Box>
        }
        {(item['averageScoreMin'] || !isEmpty(selectValueAverageScoreMin)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['averageScoreMin'] ? "averageScoreMin" : selectValueAverageScoreMin)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput>
                    <CurrencyTextField
                      key='averageScoreMin'
                      fullWidth
                      size='small'
                      type='text'
                      inputMode='numeric'
                      variant='outlined'
                      textAlign='left'
                      currencySymbol=''
                      value={averageScoreMin}
                      outputFormat="string"
                      decimalCharacter="."
                      digitGroupSeparator=","
                      onChange={(event, value) => setSearchValue('averageScoreMin', value)}
                    />
                  </FormInput>
                </Grid>
                {buttonDeleteDesktop('averageScoreMin')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['averageScoreMax'] || !isEmpty(selectValueAverageScoreMax)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['averageScoreMax'] ? "averageScoreMax" : selectValueAverageScoreMax)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput>
                    <CurrencyTextField
                      key='averageScoreMax'
                      fullWidth
                      size='small'
                      type='text'
                      inputMode='numeric'
                      variant='outlined'
                      textAlign='left'
                      currencySymbol=''
                      value={averageScoreMax}
                      outputFormat="string"
                      decimalCharacter="."
                      digitGroupSeparator=","
                      onChange={(event, value) => setSearchValue('averageScoreMax', value)}
                    />
                  </FormInput>
                </Grid>
                 {buttonDeleteDesktop('averageScoreMax')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['acidityAndSaltDescriptors'] || !isEmpty(selectValueAcidityAndSaltDescriptors)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['acidityAndSaltDescriptors'] ? "acidityAndSaltDescriptors" : selectValueAcidityAndSaltDescriptors)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput
                    textFieldProps={{
                      value: textDescriptorsTranslation(languagePreference, temporaryAcidityAndSaltDescriptors),
                      onClick: () => setOpenAcidity(true)
                    }}
                  />
                </Grid>
                {buttonDeleteDesktop('acidityAndSaltDescriptors')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['bodyAndMouthfeelDescriptors'] || !isEmpty(selectValueBodyAndMouthfeelDescriptors)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['bodyAndMouthfeelDescriptors'] ? "bodyAndMouthfeelDescriptors" : selectValueBodyAndMouthfeelDescriptors)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput
                    textFieldProps={{
                      value: textDescriptorsTranslation(languagePreference, temporaryBodyAndMouthfeelDescriptors),
                      onClick: () => setOpenBody(true)
                    }}
                  />
                </Grid>
                {buttonDeleteDesktop('bodyAndMouthfeelDescriptors')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['fragranceDescriptors'] || !isEmpty(selectValueFragranceDescriptors)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['fragranceDescriptors'] ? "fragranceDescriptors" : selectValueFragranceDescriptors)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput
                    textFieldProps={{
                      value: textDescriptorsTranslation(languagePreference, temporaryFragranceDescriptors),
                      onClick: () => setOpenFragrance(true)
                    }}
                  />
                </Grid>
                {buttonDeleteDesktop('fragranceDescriptors')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['flavorAndAftertasteDescriptors'] || !isEmpty(selectValueFlavorAndAftertasteDescriptors)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['flavorAndAftertasteDescriptors'] ? "flavorAndAftertasteDescriptors" : selectValueFlavorAndAftertasteDescriptors)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput
                    textFieldProps={{
                      value: textDescriptorsTranslation(languagePreference, temporaryFlavorAndAftertasteDescriptors),
                      onClick: () => setOpenFlavor(true)
                    }}
                  />
                </Grid>
                {buttonDeleteDesktop('flavorAndAftertasteDescriptors')}
              </Grid>
            </Grid>
          </Box>
        }
        {(item['defectsDescriptors'] || !isEmpty(selectValueDefectsDescriptors)) &&
          <Box component="div" sx={{ display: 'inline', p: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {selectBox(item['defectsDescriptors'] ? "defectsDescriptors" : selectValueDefectsDescriptors)}
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                  <FormInput
                    textFieldProps={{
                      value: textDescriptorsTranslation(languagePreference, temporaryDefectsDescriptors),
                      onClick: () => setOpenDefect(true)
                    }}
                  />
                </Grid>
                {buttonDeleteDesktop('defectsDescriptors')}
              </Grid>
            </Grid>
          </Box>
        }
      </>
     )
  }

  const selectBox = (value) => {
    return (
      <FormInput label={isMobile ? '':t('sample.selectCategory')}>
        <Select
          size='small'
          value={value}
          onChange={(e) =>{
            selectContent(e.target.value)
            addTemporaryListSearch([...temporaryListSearch, e.target.value])
            handleChange(value)
             }}
        >
          <MenuItem value='' sx={{ height: 34 }} />
          <MenuItem key={value} value={value}>{searchAdvanceData.filter(data => data.id == value)[0].label}</MenuItem>
          {searchAdvanceData.filter(data => {
            return !includes(temporaryListSearch, data.id)
          }).map(item =>
            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>

          )}

        </Select>
      </FormInput>
    )
  }

  const renderSelectContent = (item) => {
    return (
      <Container sx={{ mt: 0 }}>
        {(item['name'] || !isEmpty(selectSampleNames)) && <Grid item xs={12} md={12}>
          <Grid container spacing={1} className={classes.formRow}>
            <Grid item xs={10} sx={{mb: -3}}>
              {selectBox(item['name'] ? "sampleName" : selectSampleNames)}
            </Grid>
            <Grid item xs={10}>
              <FormInput
                textFieldProps={{
                  value: name,
                  onChange: (e) => setSearchValue('name', e.target.value),
                }}
              >

              </FormInput>
            </Grid>
            {buttonDeleteMobile("sampleName")}
          </Grid>
          <Divider variant="fullWidth" sx={{ mt: 1 }} />
        </Grid>
        }
        {(item['scoredByMe'] || !isEmpty(selectScoredByMes)) &&
        <FormInput>
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{mt: -3}}>
                {selectBox(item['scoredByMe'] ? "scoredByMe" : selectScoredByMes)}
              </Grid>
              <Grid item xs={10}>
                <Button
                  size='small'
                  variant='outlined'
                  className={clsx(classes.buttonSearch, scoredByMe ? 'active' : 'inactive')}
                  onClick={() => setSearchValue('scoredByMe', !scoredByMe)}
                  sx={{ width: 'fit-content', mt: -4 }}
                >
                  {t('sample.show')}
                </Button>
              </Grid>
              {buttonDeleteMobile('scoredByMe')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>


        </FormInput>
        }

        {(item['species']  || !isEmpty(selectSpeciess))  &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['species'] ? "species" : selectSpeciess)}
              </Grid>
              <Grid item xs={10}>
                <FormInput>
                  <Select
                    size='small'
                    value={species}
                    onChange={(e) => setSearchValue('species', e.target.value)}
                  >
                    <MenuItem value='' sx={{ height: 34 }} />
                    {specieses.map(item =>
                      <MenuItem key={item} value={item}>{t(`options.species.${camelCase(item)}`)}</MenuItem>
                    )}
                  </Select>
                </FormInput>
              </Grid>
              {buttonDeleteMobile('species')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }

        {(item['sampleType']  || !isEmpty(selectValueSampleType)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
            <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['sampleType'] ? "sampleType" : selectValueSampleType)}
              </Grid>
              <Grid item xs={10}>
                <FormInput>
                  <Select
                    size='small'
                    value={sampleType || ""}
                    onChange={(e) => setSearchValue('sampleType', e.target.value)}
                  >
                    <MenuItem value='' sx={{ height: 34 }} />
                    {sampleTypes.map(item =>
                      <MenuItem key={item} value={item}>{t(`options.sampleType.${camelCase(item)}`)}</MenuItem>
                    )}
                  </Select>
                </FormInput>
              </Grid>
              {buttonDeleteMobile('sampleType')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }

        {(item['receivedOn']  || !isEmpty(selectValueReceivedOn)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['receivedOn'] ? "receivedOn" : selectValueReceivedOn)}
              </Grid>
              <Grid item xs={10}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <FormInput>
                    <DatePicker
                      mask='____/__/__'
                      inputFormat="yyyy/MM/DD"
                      value={receivedOn}
                      onChange={(value) => {
                        setSearchValue('receivedOn', value ? moment(value?.valueOf()).format('YYYY/MM/DD') : '')
                      }}
                      renderInput={(params) => <TextField {...params} size='small' error={false} />}
                    />
                  </FormInput>
                </LocalizationProvider>
              </Grid>
              {buttonDeleteMobile('receivedOn')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }

        {(item['cropYear']  || !isEmpty(selectValueCropYear)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['cropYear'] ? "cropYear" : selectValueCropYear)}
              </Grid>
              <Grid item xs={10}>
                <FormInput>
                  <Select
                    size='small'
                    value={cropYear}
                    onChange={(e) => setSearchValue('cropYear', e.target.value)}
                  >
                    <MenuItem value='' sx={{ height: 34 }} />
                    {range((new Date()).getFullYear(), 2010).map(item =>
                      <MenuItem key={item} value={item.toString()}>{item}</MenuItem>
                    )}
                  </Select>
                </FormInput>
              </Grid>
              {buttonDeleteMobile('cropYear')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }

        {(item['numberOfBags']  || !isEmpty(selectValueNumberOfBags)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['numberOfBags'] ? "numberOfBags" : selectValueNumberOfBags)}
              </Grid>
              <Grid item xs={10}>
                <FormInput>
                  <CurrencyTextField
                    key='numberOfBags'
                    fullWidth
                    size='small'
                    type='text'
                    inputMode='numeric'
                    variant='outlined'
                    textAlign='left'
                    currencySymbol=''
                    value={numberOfBags}
                    outputFormat="string"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    decimalPlaces={0}
                    onChange={(event, value) => setSearchValue('numberOfBags', value)}
                  />
                </FormInput>
              </Grid>
              {buttonDeleteMobile('numberOfBags')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }

        {(item['reference']  || !isEmpty(selectValueReference)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['reference'] ? "reference" : selectValueReference)}
              </Grid>
              <Grid item xs={10}>
                <FormInput
                  textFieldProps={{
                    value: reference,
                    onChange: (e) => setSearchValue('reference', e.target.value)
                  }}
                />
              </Grid>
              {buttonDeleteMobile('reference')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }

        {(item['waterActivity']  || !isEmpty(selectValueWaterActivity)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['waterActivity'] ? "waterActivity" : selectValueWaterActivity)}
              </Grid>
              <Grid item xs={10}>
                <FormInput>
                  <CurrencyTextField
                    key='waterActivity'
                    fullWidth
                    size='small'
                    type='text'
                    inputMode='numeric'
                    variant='outlined'
                    textAlign='left'
                    currencySymbol=''
                    value={waterActivity}
                    outputFormat="string"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    onChange={(event, value) => setSearchValue('waterActivity', value)}
                  />
                </FormInput>
              </Grid>
              {buttonDeleteMobile('waterActivity')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }

        {(item['moisture']  || !isEmpty(selectValueMoisture)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['moisture'] ? "moisture" : selectValueMoisture)}
              </Grid>
              <Grid item xs={10}>
                <FormInput>
                  <CurrencyTextField
                    key='moisture'
                    fullWidth
                    size='small'
                    type='text'
                    inputMode='numeric'
                    variant='outlined'
                    textAlign='left'
                    currencySymbol=''
                    value={moisture}
                    outputFormat="string"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    onChange={(event, value) => setSearchValue('moisture', value)}
                    InputProps={{
                      endAdornment: (<InputAdornment position="end">%</InputAdornment>)
                    }}
                  />
                </FormInput>
              </Grid>
              {buttonDeleteMobile('moisture')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }

        {(item['mass']  || !isEmpty(selectValueMass)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['mass'] ? "mass" : selectValueMass)}
              </Grid>
              <Grid item xs={10}>
                <FormInput>
                  <CurrencyTextField
                    key='mass'
                    fullWidth
                    size='small'
                    type='text'
                    inputMode='numeric'
                    variant='outlined'
                    textAlign='left'
                    currencySymbol=''
                    value={mass}
                    outputFormat="string"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    onChange={(event, value) => setSearchValue('mass', value)}
                    InputProps={{
                      endAdornment: (<InputAdornment position="end">gr</InputAdornment>)
                    }}
                  />
                </FormInput>
              </Grid>
              {buttonDeleteMobile('mass')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }


        {(item['volume']  || !isEmpty(selectValueVolume)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['volume'] ? "volume" : selectValueVolume)}
              </Grid>
              <Grid item xs={10}>
                <FormInput>
                  <CurrencyTextField
                    key='volume'
                    fullWidth
                    size='small'
                    type='text'
                    inputMode='numeric'
                    variant='outlined'
                    textAlign='left'
                    currencySymbol=''
                    value={volume}
                    outputFormat="string"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    onChange={(event, value) => setSearchValue('volume', value)}
                    InputProps={{
                      endAdornment: (<InputAdornment position="end">mL</InputAdornment>)
                    }}
                  />
                </FormInput>
              </Grid>
              {buttonDeleteMobile('volume')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }

        {(item['countryCode']  || !isEmpty(selectValueCountryCode)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['countryCode'] ? "countryCode" : selectValueCountryCode)}
              </Grid>
              <Grid item xs={10}>
                <FormInput>
                  <Select
                    size='small'
                    value={countryCode}
                    onChange={(e) => setSearchValue('countryCode', e.target.value)}
                  >
                    <MenuItem value='' sx={{ height: 34 }} />
                    {countries.map(item =>
                      <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
                    )}
                  </Select>
                </FormInput>
              </Grid>
              {buttonDeleteMobile('countryCode')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }

        {(item['status']  || !isEmpty(selectValueStatus)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['status'] ? "status" : selectValueStatus)}
              </Grid>
              <Grid item xs={10}>
                <FormInput>
                  <Select
                    size='small'
                    value={status}
                    onChange={(e) => setSearchValue('status', e.target.value)}
                  >
                    <MenuItem value='' sx={{ height: 34 }} />
                    {statuses.map(item =>
                      <MenuItem key={item} value={item}>{t(`sample.status.${camelCase(item)}`)}</MenuItem>
                    )}
                  </Select>
                </FormInput>
              </Grid>
              {buttonDeleteMobile('status')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }

        {(item['externalId']  || !isEmpty(selectValueExternalId)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['externalId'] ? "externalId" : selectValueExternalId)}
              </Grid>
              <Grid item xs={10}>
                <FormInput
                  textFieldProps={{
                    value: externalId,
                    onChange: (e) => setSearchValue('externalId', e.target.value)
                  }}
                />
              </Grid>
             {buttonDeleteMobile('externalId')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }

        {(item['sampleId']  || !isEmpty(selectValueSampleId)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['sampleId'] ? "sampleId" : selectValueSampleId)}
              </Grid>
              <Grid item xs={10}>
                <FormInput
                  textFieldProps={{
                    value: sampleId,
                    onChange: (e) => setSearchValue('sampleId', e.target.value)
                  }}
                />
              </Grid>
             {buttonDeleteMobile('sampleId')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }

        {(item['producerName']  || !isEmpty(selectValueProducerName)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['producerName'] ? "producerName" : selectValueProducerName)}
              </Grid>
              <Grid item xs={10}>
                <FormInput
                  textFieldProps={{
                    value: producerName,
                    onChange: (e) => setSearchValue('producerName', e.target.value)
                  }}
                />
              </Grid>
              {buttonDeleteMobile('producerName')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }

        {(item['bagWeight']  || !isEmpty(selectValueBagWeight)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['bagWeight'] ? "bagWeight" : selectValueBagWeight)}
              </Grid>
              <Grid item xs={10}>
                <FormInput>
                  <CurrencyTextField
                    key='bagWeight'
                    fullWidth
                    size='small'
                    type='text'
                    inputMode='numeric'
                    variant='outlined'
                    textAlign='left'
                    currencySymbol=''
                    value={bagWeight}
                    outputFormat="string"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    onChange={(event, value) => setSearchValue('bagWeight', value)}
                  />
                </FormInput>
              </Grid>
              {buttonDeleteMobile('bagWeight')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }


        {(item['grade']  || !isEmpty(selectValueGrade)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['grade'] ? "grade" : selectValueGrade)}
              </Grid>
              <Grid item xs={10}>
                <FormInput
                  textFieldProps={{
                    value: grade,
                    onChange: (e) => setSearchValue('grade', e.target.value)
                  }}
                />
              </Grid>
              {buttonDeleteMobile('grade')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }


        {(item['processName']  || !isEmpty(selectValueProcessName)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['processName'] ? "processName" : selectValueProcessName)}
              </Grid>
              <Grid item xs={10}>
                <FormInput
                  textFieldProps={{
                    value: processName,
                    onChange: (e) => setSearchValue('processName', e.target.value)
                  }}
                />
              </Grid>
              {buttonDeleteMobile('processName')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }

        {(item['supplierName']  || !isEmpty(selectValueSupplierName)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['supplierName'] ? "supplierName" : selectValueSupplierName)}
              </Grid>
              <Grid item xs={10}>
                <FormInput
                  textFieldProps={{
                    value: supplierName,
                    onChange: (e) => setSearchValue('supplierName', e.target.value)
                  }}
                />
              </Grid>
              {buttonDeleteMobile('supplierName')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }

        {(item['notesAndRemarks']  || !isEmpty(selectValueNotesAndRemarks)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['notesAndRemarks'] ? "notesAndRemarks" : selectValueNotesAndRemarks)}
              </Grid>
              <Grid item xs={10}>
                <FormInput
                  textFieldProps={{
                    value: notesAndRemarks,
                    onChange: (e) => setSearchValue('notesAndRemarks', e.target.value)
                  }}
                />
              </Grid>
             {buttonDeleteMobile('notesAndRemarks')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }


        {(item['averageScoreMin']  || !isEmpty(selectValueAverageScoreMin)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['averageScoreMin'] ? "averageScoreMin" : selectValueAverageScoreMin)}
              </Grid>
              <Grid item xs={10}>
                <FormInput>
                  <CurrencyTextField
                    key='averageScoreMin'
                    fullWidth
                    size='small'
                    type='text'
                    inputMode='numeric'
                    variant='outlined'
                    textAlign='left'
                    currencySymbol=''
                    value={averageScoreMin}
                    outputFormat="string"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    onChange={(event, value) => setSearchValue('averageScoreMin', value)}
                  />
                </FormInput>
              </Grid>
              {buttonDeleteMobile('averageScoreMin')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }


        {(item['averageScoreMax']  || !isEmpty(selectValueAverageScoreMax)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['averageScoreMax'] ? "averageScoreMax" : selectValueAverageScoreMax)}
              </Grid>
              <Grid item xs={10}>
                <FormInput>
                  <CurrencyTextField
                    key='averageScoreMax'
                    fullWidth
                    size='small'
                    type='text'
                    inputMode='numeric'
                    variant='outlined'
                    textAlign='left'
                    currencySymbol=''
                    value={averageScoreMax}
                    outputFormat="string"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    onChange={(event, value) => setSearchValue('averageScoreMax', value)}
                  />
                </FormInput>
              </Grid>
              {buttonDeleteMobile('averageScoreMax')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }


        {(item['acidityAndSaltDescriptors']  || !isEmpty(selectValueAcidityAndSaltDescriptors)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['acidityAndSaltDescriptors'] ? "acidityAndSaltDescriptors" : selectValueAcidityAndSaltDescriptors)}
              </Grid>
              <Grid item xs={10}>
                <FormInput
                  textFieldProps={{
                    value: textDescriptorsTranslation(languagePreference,  temporaryAcidityAndSaltDescriptors),
                    onClick: () => setOpenAcidity(true)
                  }}
                />
              </Grid>
              {buttonDeleteMobile('acidityAndSaltDescriptors')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }



        {(item['bodyAndMouthfeelDescriptors']  || !isEmpty(selectValueBodyAndMouthfeelDescriptors)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['bodyAndMouthfeelDescriptors'] ? "bodyAndMouthfeelDescriptors" : selectValueBodyAndMouthfeelDescriptors)}
              </Grid>
              <Grid item xs={10}>
                <FormInput
                  textFieldProps={{
                    value: textDescriptorsTranslation(languagePreference, temporaryBodyAndMouthfeelDescriptors),
                    onClick: () => setOpenBody(true)
                  }}
                />
              </Grid>
              {buttonDeleteMobile('bodyAndMouthfeelDescriptors')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }

        {(item['fragranceDescriptors']  || !isEmpty(selectValueFragranceDescriptors)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['fragranceDescriptors'] ? "fragranceDescriptors" : selectValueFragranceDescriptors)}
              </Grid>
              <Grid item xs={10}>
                <FormInput
                  textFieldProps={{
                    value: textDescriptorsTranslation(languagePreference, temporaryFragranceDescriptors),
                    onClick: () => setOpenFragrance(true)
                  }}
                />
              </Grid>
              {buttonDeleteMobile('fragranceDescriptors')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }


        {(item['flavorAndAftertasteDescriptors']  || !isEmpty(selectValueFlavorAndAftertasteDescriptors)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['flavorAndAftertasteDescriptors'] ? "flavorAndAftertasteDescriptors" : selectValueFlavorAndAftertasteDescriptors)}
              </Grid>
              <Grid item xs={10}>
                <FormInput
                  textFieldProps={{
                    value: textDescriptorsTranslation(languagePreference, temporaryFlavorAndAftertasteDescriptors),
                    onClick: () => setOpenFlavor(true)
                  }}
                />
              </Grid>
              {buttonDeleteMobile('flavorAndAftertasteDescriptors')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }


        {(item['defectsDescriptors']  || !isEmpty(selectValueDefectsDescriptors)) &&
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} className={classes.formRow}>
              <Grid item xs={10} sx={{ mb: -3 }}>
                {selectBox(item['defectsDescriptors'] ? "defectsDescriptors" : selectValueDefectsDescriptors)}
              </Grid>
              <Grid item xs={10}>
                <FormInput
                  textFieldProps={{
                    value: textDescriptorsTranslation(languagePreference, temporaryDefectsDescriptors),
                    onClick: () => setOpenDefect(true)
                  }}
                />
              </Grid>
              {buttonDeleteMobile('defectsDescriptors')}
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 1 }} />
          </Grid>
        }
      </Container>
    )

  }

  const renderSearchValue = (item) => {
    let label: string = item.label
    let resetValue: any = ''

    if (isString(item.value)) label += `: "${item.value}"`
    if (isArray(item.value)) label += `: "${textDescriptorsTranslation(languagePreference, item.value)}"`
    if (isBoolean(item.value)) resetValue = false

    return (
      <Chip
        key={item.id}
        size="small"
        variant='outlined'
        color='primary'
        sx={{ mr: 1, mb: 1 }}
        label={label}
        onDelete={() => setSearchValue(item.key || item.id, resetValue)}
      />
    )
  }

  React.useEffect(() => {
    window.addEventListener('keydown', onEnter)
    return () => window.removeEventListener("keydown", onEnter)

  }, [])

  if (isMobile) {

    return (
      <ModalWrapper
        open={expanded}
        containerProps={{
          sx: {
            p: '0 !important',
            top: 'unset !important',
            bottom: 0,
            border: 'none',
            transform: 'translate(-50%) !important',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }
        }}
      >
        <Box className={classes.titleWrapper}>
          <Typography variant='h6'>Advanced Search</Typography>
          <IconButton
            size="small"
            onClick={onCancel}
          >
            <ClearIcon />
          </IconButton>
        </Box>
        <Container sx={{ mt: 1 }}>
          {(!isEmpty(temporaryListSearch) || !isEmpty(searchValues)) &&
            buttonClearAllMobile()
          }
        </Container>
        <Container sx={{ mt: 1 , mb: -4}}>
          <FormInput label={t('sample.selectCategory')}>

          </FormInput>
        </Container>

        {renderSelectContent(searchValues)}
        <Container sx={{ mt: -3 }}>
          <FormInput>
            <Select
              size='small'
              value={searchAdvanceData}
              onChange={(e) => {
                selectContent(e.target.value)
                addTemporaryListSearch([...temporaryListSearch, e.target.value])
              }}
            >
              <MenuItem value='' sx={{ height: 34 }} />
              {searchAdvanceData.filter(data => {
                return !includes(temporaryListSearch, data.id)
              }).map(item =>
                <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
              )}
            </Select>
          </FormInput>

        </Container>
        <Container sx={{ mt: 1 }}>
        {isEmpty(temporaryListSearch) &&
          <Typography variant='body2' sx={{color: colors.text.o50}}>
            {t('sample.selectCategoryDescription')}
          </Typography>
        }
        </Container>
        <Box sx={{ p: 2, boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.1)' }} marginTop={2}>
          <Button
            disabled = {isEmpty(searchValues)}
            fullWidth
            variant='contained'
            onClick={() => {
              onSearch()
              onCancel()
              addTemporarySearchAdvanceData(searchAdvanceData)
              addTemporaryDefectsDescriptors(temporaryDefectsDescriptors)
              addTemporaryFlavorAndAftertasteDescriptors(temporaryFlavorAndAftertasteDescriptors)
              addTemporaryFragranceDescriptors(temporaryFragranceDescriptors)
              addTemporaryBodyAndMouthfeelDescriptors(temporaryBodyAndMouthfeelDescriptors)
              addTemporaryAcidityAndSaltDescriptors(temporaryAcidityAndSaltDescriptors)
            }}
          >
            {t('common.search')}
          </Button>
        </Box>

        {renderDescriptorModals}
      </ModalWrapper>
    )
  }

  return (
    <Card className={classes.filterWrapper}>
      <Box className={classes.collapseFilter}>
        <Typography sx={{ display: 'flex' }}>
          <SvgIcon component={IconSampleInfo} color='primary' inheritViewBox sx={{ mr: 1 }} />
          {t('sample.sampleInfo')}
        </Typography>
        <Button
          size='small'
          startIcon={<ClearIcon sx={{color: '#E74C3C'}}/>}
          onClick={resetValues}
        >
          <Typography variant='body2'> {t('sample.clearAllFilters')} </Typography>
        </Button>
      </Box>
      <CardContent >
        <Box
          component="div"
          className={classes.boxDesktop}
        >
          {renderSelectionContentDesktop(searchValues)}
          <Box component="div" sx={{ display: 'inline', width: 50, p: 2 }}>
            <Grid container spacing={1} sx = {{maxWidth: "250px", direction: "row", display: "inline-flex"}} >
              <Grid item xs={12}>

                <Grid item xs={12}>
                  <FormInput label={t('sample.selectCategory')}>
                    <Select
                      size='small'
                      value={searchAdvanceData}
                      onChange={(e) => {
                        selectContent(e.target.value)
                        addTemporaryListSearch([...temporaryListSearch, e.target.value])
                      }}
                    >
                      <MenuItem value='' />
                      {searchAdvanceData.filter(data => {
                        return !includes(temporaryListSearch, data.id)
                      }).map(item =>
                        <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                      )}
                    </Select>
                  </FormInput>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {isEmpty(temporaryListSearch) &&
          <Box component="div" sx={{ display: 'inline', width: 50}}>
              <Grid container spacing={1} sx={{ maxWidth: "250px", direction: "row", display: "inline-flex" }} >
                <Grid item xs={12} sx={{ pt: 20 }}>
                  <FormInput >
                    <Typography variant='body2' sx={{color: colors.text.o50}}>
                    {ReactHtmlParser(t('sample.selectCategoryEmpty'))}
                    </Typography>
                  </FormInput>
                </Grid>
              </Grid>
          </Box>
          }


        </Box>
      </CardContent>
      <Stack direction='row' spacing={2} justifyContent='end'>
        <LoadingButton
          variant='contained'
          className='wide'
          loading={isLoading}
          onClick={() => {
            onSearch()
            addTemporarySearchAdvanceData(searchAdvanceData)
            addTemporaryDefectsDescriptors(temporaryDefectsDescriptors)
            addTemporaryFlavorAndAftertasteDescriptors(temporaryFlavorAndAftertasteDescriptors)
            addTemporaryFragranceDescriptors(temporaryFragranceDescriptors)
            addTemporaryBodyAndMouthfeelDescriptors(temporaryBodyAndMouthfeelDescriptors)
            addTemporaryAcidityAndSaltDescriptors(temporaryAcidityAndSaltDescriptors)
          }}
        >
          {t('common.search')}
        </LoadingButton>
      </Stack>
      {!isEmpty(temporaryListSearch) && renderSearchMaster()}
      {renderDescriptorModals}
    </Card>
  )
})
