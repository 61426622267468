/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:39:51
 * @modify date 2022-01-30 15:55:59
 */
import { Instance, SnapshotOut, types } from "mobx-state-tree"

import { withReset, withSetValue } from "models/extensions"
import { UserStoreModel } from "models/user-store"
import { CompanyStoreModel } from "models/company-store"
import { DashboardStoreModel } from "models/dashboard-store"
import { CupperStoreModel } from "models/cupper-store"
import { CuppingSessionStoreModel } from "models/cupping-session-store"
import { CuppingSessionFormStoreModel } from "models/cupping-session-form"
import { SampleStoreModel } from "models/sample-store"
import { MasterSampleStoreModel } from "models/master-sample-store"
import { ReviewSampleStoreModel } from "models/review-sample-store"
import { SampleReportStoreModel } from "models/sample-report-store"
import { SupplierStoreModel } from "models/supplier-store"
import { ProcessStoreModel } from "models/process-store"
import { ProducerStoreModel } from "models/producer-store"
import { LocationStoreModel } from "models/location-store"
import { SubscriptionStoreModel } from "models/subscription-store"
import { WebhookStoreModel } from "models/webhook-store"
import { CompareStoreModel } from "models/compare-store"
import { ModalStoreModel } from "models/modal-store"
import { GuideStoreModel } from "models/guide-store/guide-store"
import { NotificationStoreModel } from "models/notification-store"
import { CollectiveReportStoreModel } from "models/collective-report-store"
import { IndividualReportStoreModel } from "models/individual-report-store"
import { NpsStoreModel } from "models/nps-store"
import { RedeemCodeModel } from "models/redeem-code"
import { GuestInformationStoreModel } from "models/guest-information-store"
import { GreenGradingStoreModel } from "models/green-grading-store"
import { CuppingResultStoreModel } from "models/cupping-result-store"
import { ActivityStoreModel } from "models/activity-store";

import { withRootStoreAction } from "./root-store-action"
import {SampleShipmentStoreModel} from "../sample-shipment-store";

/**
 * A RootStore model.
 */
export const RootStoreModel = types
  .model("RootStore")
  .props({
    userStore: types.optional(UserStoreModel, {}),
    companyStore: types.optional(CompanyStoreModel, {}),
    dashboardStore: types.optional(DashboardStoreModel, {}),
    cupperStore: types.optional(CupperStoreModel, {}),
    cuppingSessionStore: types.optional(CuppingSessionStoreModel, {}),
    cuppingSessionFormStore: types.optional(CuppingSessionFormStoreModel, {}),
    sampleStore: types.optional(SampleStoreModel, {}),
    masterSampleStore: types.optional(MasterSampleStoreModel, {}),
    reviewSampleStore: types.optional(ReviewSampleStoreModel, {}),
    sampleReportStore: types.optional(SampleReportStoreModel, {}),
    supplierStore: types.optional(SupplierStoreModel, {}),
    processStore: types.optional(ProcessStoreModel, {}),
    producerStore: types.optional(ProducerStoreModel, {}),
    locationStore: types.optional(LocationStoreModel, {}),
    subscriptionStore: types.optional(SubscriptionStoreModel, {}),
    webhookStore: types.optional(WebhookStoreModel, {}),
    compareStore: types.optional(CompareStoreModel, {}),
    modalStore: types.optional(ModalStoreModel, {}),
    notificationStore: types.optional(NotificationStoreModel, {}),
    guideStore: types.optional(GuideStoreModel, {}),
    collectiveReportStore: types.optional(CollectiveReportStoreModel, {}),
    individualReportStore: types.optional(IndividualReportStoreModel, {}),
    npsStore: types.optional(NpsStoreModel, {}),
    redeemCode: types.optional(RedeemCodeModel, {}),
    guestInformationStore: types.optional(GuestInformationStoreModel, {}),
    greenGradingStore: types.optional(GreenGradingStoreModel, {}),
    cuppingResultStore: types.optional(CuppingResultStoreModel, {}),
    activityStore: types.optional(ActivityStoreModel, {}),
    sampleShipmentStore: types.optional(SampleShipmentStoreModel, {}),
    shipmentStore: types.optional(SampleShipmentStoreModel, {}),
  })
  .extend(withReset)
  .extend(withSetValue)
  .extend(withRootStoreAction)

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
