/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-14 23:27:39
 * @modify date 2022-01-14 23:27:39
 * @desc Style for stack alert component
 */
import { createStyles, makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      paddingRight: 24
    },
    icon: {
      marginTop: 4,
      '&.no-title': {
        marginTop: 0
      }
    },
    title: {
      fontWeight: 600
    }
  })
)