export const loadGuide = (callback) => {
  if (!window['userGuiding']) loadGuide(callback)

  callback()
}

export const listenGuideEnd = (callback) => {
  (window as any).userGuidingLayer.push({
    event: 'onPreviewEnd',
    fn: (data) => callback(data)
   })
}