/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-17 22:29:31
 * @modify date 2022-04-17 22:29:31
 */

import { types } from "mobx-state-tree"

import { GreenGradingModel, preProcessorGreenGrading } from "models/green-grading"
import { SampleScoreModel, preProcessorSampleScore } from 'models/sample-score'
import { sampleImageProps } from './sample-image-props'
import { defaultSampleProps} from "./default-sample.props"

export const sampleProps = {
  ...defaultSampleProps,

  sampleId: types.string,
  farmerNotes: types.string,
  storySubject: types.string,
  storyDescription: types.string,
  isScoreTarget: types.maybeNull(types.boolean),
  cachedAverageScore: types.string,
  status: types.maybeNull(types.string),
  uniqueToken: types.optional(types.string, ''),
  images: types.optional(types.array(types.model(sampleImageProps)), []),

  selectedGreenGrading: types.maybeNull(GreenGradingModel),
  greenGradings: types.optional(types.array(
    types.snapshotProcessor(GreenGradingModel, {
      preProcessor: (sn: any) => preProcessorGreenGrading(sn)
  })), []),

  selectedScore: types.optional(SampleScoreModel, {}),
  scores: types.optional(types.array(
    types.snapshotProcessor(SampleScoreModel, {
      preProcessor: (sn: any) => preProcessorSampleScore(sn)
  })), []),

  tab: types.optional(types.string, 'descriptive'),
  cuppingProtocol: types.maybeNull(types.string),

  masterId: types.maybeNull(types.number),
  masterSampleName: types.maybeNull(types.string),
}

