/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-05 22:42:18
 * @modify date 2022-02-05 22:42:18
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors } from 'assets'
  
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      padding: theme.spacing(3),
      paddingTop: theme.spacing(2),
      borderRadius: 5,
      border: `1px solid ${colors.border.primary}`,
      maxHeight: '100vh',
      overflow: 'auto',
      [theme.breakpoints.down('md')]: {
        maxHeight: '90vh',
      }
    }
  })
)