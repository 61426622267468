import React from 'react'
import { observer } from "mobx-react-lite"
import { toNumber } from "lodash"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Button, TextField } from "@mui/material"

import { useStores } from "models"
import { FormInput, ModalWrapper } from "components"

export const MasterSampleModal = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [numberOfSamples, setNumberOfSamples] = React.useState(1)

  const {
    modalStore: { masterSample, setValue: setModalValue },
  } = useStores()

  const onCreateSample = () => {
    setModalValue('masterSample', false)
    navigate(`/samples/new?n=${numberOfSamples}`)
  }

  return (
    <ModalWrapper
      maxWidth="xs"
      open={masterSample}
      onClose={() => setModalValue('masterSample', false)}
    >
      <FormInput label={t('cuppingSession.numberOfSamples')} formControlProps={{sx: {mt: 0}}}>
        <TextField
          type='tel'
          size='small'
          inputMode='numeric'
          inputProps={{ min: "1", max: "30", pattern: '[0-9]*', size: "1" }}
          value={numberOfSamples}
          onChange={(e) => setNumberOfSamples(toNumber(e.target.value) || 0)}
          sx={{maxWidth: 75, mr:1}}
        />
      </FormInput>

      <Button
        variant="contained"
        sx={{mt: 2}}
        className='wide'
        onClick={onCreateSample}
        disabled={numberOfSamples < 1 || numberOfSamples >= 52}
      >
        {t('sample.masterSample.button.create')}
      </Button>
    </ModalWrapper>
  )
})
