import { SelectChangeEvent } from '@mui/material/Select'

export const setValue = (state) => (event: React.ChangeEvent<HTMLInputElement> ) => {
  state(event.target.value)
}

export const setSelect = (state) => (event: SelectChangeEvent) => {
  state(event.target.value)
}

export const str2bool = (value) => {
  if (value && typeof value === "string") {
    if (value.toLowerCase() === "true") return true
    if (value.toLowerCase() === "false") return false
  }
  return value
}
