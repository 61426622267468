/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-03 16:43:21
 * @modify date 2022-02-03 20:13:18
 */
 import { Theme } from '@mui/material'
 import { createStyles, makeStyles } from '@mui/styles'
 import { verticalCentered } from 'assets'
  
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expandWrapper: {
      ...verticalCentered,
      cursor: 'pointer',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1)
    }
  })
)