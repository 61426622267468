/**
 * @author I Wayan Bayu N
 * @email bayuiwo@gmail.com
 * @create date 2023-03-23 02:40:03
 * @modify date 2023-03-23 02:54:14
 */
import React from 'react'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import {  PrintOutlined, CopyAllOutlined } from '@mui/icons-material'
import {
  Grid, Box, IconButton, Typography, Container,
  SvgIcon,
} from '@mui/material'
import QRCode from "react-qr-code";
import { isMobile } from "react-device-detect"
import { LoadingButton } from '@mui/lab'
import ReactHtmlParser from 'react-html-parser'

import { Logo } from 'assets/images'
import { ModalWrapper } from 'components'
import { colors } from 'assets'
import { printPage } from 'utils'

import { PrintQrProps } from './print-qr.props'

export const PrintQr: React.FC<PrintQrProps> = observer((props: PrintQrProps) => {
  const { t } = useTranslation()
  const { open, onClose, setUrl, selectCuppingSession, sampelName } = props
  const printElement = React.createRef<Element | undefined>()

  const [copySuccess, setCopySuccess] = React.useState('');
  const [isPrinting, setIsPrinting] = React.useState(false)

  const onCancel= () => {
    onClose()
  }

  const renderPrintQr = () => {
    return (
      <Container>
        <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 3 , marginTop: 5}}>
          <Grid item xs={12}>
            <Box>
              <Logo width={416} height={135} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography variant='h2' color={colors.text.primary}>{t('qrCode.title')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography variant='h4' sx={{fontWeight: '400'}} color={colors.primary.main}>
                {ReactHtmlParser(t('qrCode.description'))}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box marginTop={1}>
              <Typography variant='h3' color={colors.primary.main}>{`${t('sample.cuppingSession')}# ${selectCuppingSession["id"]}`}  - {selectCuppingSession["description"]}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
          <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 6, marginTop: 5 }}>
            <Grid item xs={12}>
              <div style={{ height: "auto", margin: "0 auto", maxWidth: 256, width: "100%" }}>
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={setUrl}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </Grid>
          </Grid>
          </Grid>
          <Grid item xs={5}>
            <Box sx={{width: '100%', textAlign: 'right'}}>
              <Typography variant='h4' color={colors.primary.main}>{`${t('cuppingSession.startAt')}:`} </Typography>
              <Typography variant='h4' color={colors.primary.main}>{`${t('cuppingSession.endAt')}:`} </Typography>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box sx={{width: '100%', textAlign: 'left'}}>
              <Typography variant='h4' sx={{fontWeight: '400'}} color={colors.primary.main}>{`${moment(selectCuppingSession["startsAt"]).format('MMMM DD, YYYY - HH:mm')}`} </Typography>
              <Typography variant='h4' sx={{fontWeight: '400'}} color={colors.primary.main}>{`${moment(selectCuppingSession["endAt"]).format('MMMM DD, YYYY - HH:mm')}`} </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{width: '100%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', mt: 1, flexDirection: 'row', display: 'flex'}}>
              <Box>
              <Typography variant='h4' sx={{fontWeight: '400'}} color={colors.primary.main}>{`${t('cuppingSession.cuppingProtocol')}: `} </Typography>
              </Box>
              <Box sx={{ml: 1}}>
              <Typography variant='h4' color={colors.primary.main}> {t(`options.cuppingProtocol.${selectCuppingSession["cuppingProtocol"]}`)} </Typography>
              </Box>


            </Box>
          </Grid>

        </Grid>

        {!selectCuppingSession?.blind &&
          <Grid container spacing={1} sx={{alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: 8}}>
            <Grid item xs/>
            <Grid item xs={8}>
              <Box sx={{width: 800, backgroundColor: colors.primary.o25, p: 3}}>
                <Typography variant='h3' color={colors.primary.main}>Sample(s) to Cup</Typography>
                <Box component="div" sx={{textOverflow: 'ellipsis', textAlign: 'left', direction: "row"}}>
                  {sampelName.map((name, i) => {
                    i++
                    return (
                      <div style={{width: 700, whiteSpace: 'nowrap'}}>
                        <Box
                          component="div"
                          sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            p: 1,
                            color: (theme) => colors.primary.main,
                            fontSize: '1.5rem',
                            fontWeight: '400',
                          }}
                        >
                          {`${i}. ${name}`}
                        </Box>

                      </div>
                    )
                  })}
                </Box>
              </Box>
            </Grid>
            <Grid item xs/>
          </Grid>
        }
      </Container>
    )
  }

  React.useEffect(() => {
    if (!isPrinting || !printElement.current) return

    (async () => {
      try {
        await printPage(printElement.current,true)
      } finally {
        setIsPrinting(false)

      }
    })()
  }, [isPrinting])

  return (
    <>
      <ModalWrapper
          maxWidth='xs'
          open={open}
          onClose={onCancel}
      >

          <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 3 }}>

              <Grid item xs={11}>
                  <div style={{ height: "auto", margin: "0 auto", maxWidth: 220, width: "100%" }}>
                      <QRCode
                          size={220}
                          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                          value={setUrl}
                          viewBox={`0 0 220 220`}
                      />
                  </div>

              </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 3 }}>
              <Grid item xs={11}>
                  <div style={{ width: isMobile ? 300 : 360, whiteSpace: 'nowrap' }}>
                      <Box
                          component="div"
                          sx={{
                              p: 1,
                              backgroundColor: colors.primary.o25,
                              borderRadius: 10,
                          }}
                      >
                        <Box
                            component="div"
                            sx={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                p: 1,
                                ml: 5,
                                mr: 5,
                                color: (theme) => colors.primary.main,
                                fontSize: '0.875rem',
                                fontWeight: '700',
                            }}
                        >
                            {setUrl}
                        </Box>
                      </Box>
                  </div>
              </Grid>
          </Grid>


          <Grid container spacing={1} sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
              <Grid item xs={6}>
                  <IconButton
                          size='small'
                          color='primary'
                          onClick={() =>  {
                            setCopySuccess("Copied!")
                            navigator.clipboard.writeText(setUrl)
                        }}
                          sx={{ mr: 0 }}><SvgIcon component={CopyAllOutlined} inheritViewBox /><Typography variant='h6' color={colors.primary.main}>{t('sample.copyLink')}</Typography>
                    </IconButton>

              </Grid>
              <Grid item xs={6}>
                  <LoadingButton
                    variant='text'
                    color='primary'
                    loadingPosition="start"
                    startIcon={<SvgIcon component={PrintOutlined} inheritViewBox sx={{width: 25, height: 25}}/>}
                    onClick={() => setIsPrinting(true)}
                    loading={isPrinting}
                  >
                     <Typography variant='h6' color={colors.primary.main}>{t('sample.printQR')}</Typography>
                  </LoadingButton>
              </Grid>

          </Grid>
      </ModalWrapper>
      {isPrinting &&
            <Box ref={printElement} sx={{width: 1200}} data-name='print-qr-code' >
              {renderPrintQr()}
            </Box>
          }
      </>
  )
})
