/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-30 13:05:28
 * @modify date 2022-01-30 13:05:28
 * @desc [description]
 */
import React from 'react'
import moment from 'moment'
import { truncate } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Box, Card, Typography, AvatarGroup, Avatar, Tooltip } from '@mui/material'

import { colors } from 'assets'
import { IconCalendar } from 'assets/images'


import { useStyles } from './card-upcoming.styles'
import { CardUpcomingProps } from './card-upcoming.props'
const randomColor = require('randomcolor')

export const CardUpcoming: React.FC<CardUpcomingProps> = (props: CardUpcomingProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { 
    ownerName, startsAt, endsAt, numberForCompany, name, 
    hasGuestInvitation, totalInvitation, cuppers
  } = props
  
  const date = () => {
    const isSameDate = moment(startsAt).isSame(endsAt, 'day')

    if (isSameDate) return `${moment(startsAt).format('DD MMM YYYY, HH:mm')} - ${moment(endsAt).format('HH:mm')}`

    return `${moment(startsAt).format('DD MMM YYYY, HH:mm')} - ${moment(endsAt).format('DD MMM YYYY, HH:mm')}`
  }
  
  const displayAvatar = (cupper, index) => {
    const {name, avatar} = cupper
    const color = randomColor()
    const initials = name?.split(" ").map((n)=>n[0]).join("").toUpperCase()

    return (
      <Tooltip title={name} key={index}>
        {avatar ? 
          <Avatar alt={name} src={avatar} /> : 
          <Avatar sx={{ bgcolor: color, mx: 2 }}>{initials}</Avatar>
        }
      </Tooltip>
    )
  }

  return (
    <Card variant="outlined" className={classes.wrapper}>
      <Box sx={{display: 'flex', mb: 1}}>
        <IconCalendar fill={colors.primary.main} />
        <Typography variant='body2' sx={{ml: 1}}>
          {date()}
        </Typography>
      </Box>
      <Box sx={{flex: 1}}>
        <Typography variant='caption'>
          {t('home.cuppingSession')} #{numberForCompany}
        </Typography>

        <Typography variant='h6' color='primary' sx={{wordBreak: 'break-word'}}>
          {truncate(name, {'length': 80})}
        </Typography>

        <Typography variant='caption'>
          {t('home.by')} <strong>{ownerName}</strong> {hasGuestInvitation && t('home.withGuest')}
        </Typography>
      </Box>

      <AvatarGroup max={5} total={totalInvitation} spacing='small' className={classes.avatarGroup}>
        {cuppers?.map((cupper, index) => displayAvatar(cupper, index))}
      </AvatarGroup>
    </Card>
  )
} 