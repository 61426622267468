/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-17 22:31:37
 * @modify date 2022-02-17 22:31:37
 */

import React from 'react'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import { map, toNumber, toString, camelCase } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Container, Button, Avatar, Tab, Stack } from '@mui/material'
import { TabContext, TabList } from '@mui/lab'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import { Add as AddIcon } from "@mui/icons-material"
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'

import { useStores } from 'models'
import { findRoute } from 'constants/routes'
import { FormInput, HeaderTitle, ModalWrapper } from 'components'
import { globalAction } from "utils"
import { PrintQr } from 'pages/modal/print-qr/print-qr'

import { useStyles } from './sample-information.styles'
import { GreenGradingModal, SampleItem, GreenGradingOptionsModal } from './components'

export const SampleInformation: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const cuppingSessionsLink = findRoute('cuppingSessions')
  const sampleReviewLink = findRoute('sampleReview')

  const {
    cuppingSessionStore: { selectedCuppingSession },
    sampleStore: { samples, selectedSample, saveSample, addSample, setValue: setSampleValue },
    masterSampleStore: { setValue: setMasterSampleValue },
    modalStore: { greenGrading, printQRCode, setValue: setModalValue },
    notificationStore
  } = useStores()

  const [tab, setTab] = React.useState('')
  const [urlInvitationsQrCode, setUrlInvitationsQrCode] = React.useState('')
  const [openNumberSampleModal, setOpenNumberSampleModal] = React.useState(false)
  const [numberOfSamples, setNumberOfSample] = React.useState('')

  const onAddSample = async () => {
    globalAction(notificationStore, {
      action: async () => await addSample(toNumber(numberOfSamples), selectedCuppingSession?.uniqueToken),
      afterAction: () => {
        notificationStore.setNotification({
          severity: 'success',
          message: t('sample.success.successfullyAddSample')
        })
      }
    })

    setOpenNumberSampleModal(false)
    setNumberOfSample('')
  }

  const renderNumberSampleModal = (
    <ModalWrapper
      open={openNumberSampleModal}
      onClose={() => setOpenNumberSampleModal(false)}
      maxWidth="xs"
    >
      <FormInput label={t('sample.numberOfSamplesTobeAdded')}>
        <CurrencyTextField
          key="numberOfSamplesTobeAdded"
          fullWidth
          size='small'
          type='text'
          inputMode='numeric'
          variant='outlined'
          textAlign='left'
          currencySymbol=''
          value={numberOfSamples}
          outputFormat="string"
          decimalCharacter="."
          digitGroupSeparator=","
          decimalPlaces={0}
          onChange={(event, value)=> setNumberOfSample(value)}
        />
      </FormInput>
      <Button
        variant='contained'
        className='wide'
        onClick={onAddSample}
        sx={{mt: 2}}
        disabled={!selectedCuppingSession?.uniqueToken || !numberOfSamples}
      >
        {t('common.save')}
      </Button>
    </ModalWrapper>
  )

  const subtitle = (
    `<strong>${t(`options.cuppingProtocol.${camelCase(selectedCuppingSession?.cuppingProtocol)}`)}</strong> ` +
    `${t(`cuppingSession.cuppingForm`)} <br/>` +
    `${t("cuppingSession.startAt")}: ${moment(selectedCuppingSession?.startsAt).format("MMMM DD, YYYY - HH:mm")}`
  )

  React.useEffect(() => {
    setMasterSampleValue('searchQuery', '')
    setUrlInvitationsQrCode(`${window.location.origin}/invitations/${selectedCuppingSession?.uniqueToken} `)
  },[])

  React.useEffect(() => {
    setTab(toString(selectedSample.id))
    setMasterSampleValue('searchQuery', selectedSample.masterSampleName ?? '')
  }, [selectedSample])

  return (
    <>
      <HeaderTitle
        dataLinks={[
          {
            backUrl: cuppingSessionsLink,
            breadcrumb: t("common.backTo", {menu: t("menu.cuppingSessions")})
          },
          {
            backUrl: sampleReviewLink,
            breadcrumb: t('common.goTo', {menu: t('menu.sampleReview')}),
          },
        ]}
        title={`${t("sample.cuppingSession")} ${selectedCuppingSession?.id}`}
        subtitle={subtitle}
      />

      <Container className={classes.containerWrapper}>
        <Stack direction='row' spacing={2} justifyContent="end">
          <Button
            variant="contained"
            size="small"
            startIcon={
              <Avatar className={classes.iconRounded}>
                <AddIcon />
              </Avatar>
            }
            onClick={() => setOpenNumberSampleModal(true)}
            className={classes.buttonRounded}
          >
            {t("sample.button.addSamples")}
          </Button>

          {selectedCuppingSession?.isPublic && (
            <Button
              variant="contained"
              startIcon={
                <Avatar className={classes.iconRounded}>
                  <QrCode2Icon />
                </Avatar>
              }
              onClick={() => setModalValue("printQRCode", true)}
              className={classes.buttonRounded}
            >
              {t("sample.printQRCode")}
            </Button>
          )}
        </Stack>
      </Container>

      {toString(selectedSample?.id) === tab && (
        <Container>
          <TabContext value={tab}>
            <TabList
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              onChange={(event, value) => {
                saveSample(toNumber(tab));
                setSampleValue("selectedSample", value);
                setTab(value);
              }}
            >
              {samples.map((sample) => (
                <Tab
                  key={sample.id}
                  value={toString(sample.id)}
                  label={sample.sampleId}
                />
              ))}
            </TabList>

            {selectedSample && <SampleItem />}
          </TabContext>
        </Container>
      )}

      {selectedSample?.selectedGreenGrading && (
        <GreenGradingModal
          open={greenGrading}
          onClose={() => setModalValue("greenGrading", false)}
        />
      )}

      <GreenGradingOptionsModal />

      <PrintQr
        open={printQRCode}
        onClose={() => setModalValue("printQRCode", false)}
        setUrl={urlInvitationsQrCode}
        selectCuppingSession={selectedCuppingSession}
        sampelName={map(samples, "name")}
      />

      {renderNumberSampleModal}
    </>
  );
})


