/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-03 15:23:27
 * @modify date 2022-03-29 04:08:56
 */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Box, Typography, Grid, Avatar } from '@mui/material'

import { useStores } from "models"
import { companyLogo } from 'assets/images'
import { Avatar as AvatarComponent, ModalWrapper } from 'components'

import { verticalCentered } from 'assets'

export const Profile: React.FC = observer(() => {
  const { t } = useTranslation()
  const { 
    userStore: { name, email, avatar },
    companyStore: { name: companyName, logo },
    modalStore: { profile, setValue }
  } = useStores()
  
  return (
    <ModalWrapper
      open={profile}
      onClose={() => setValue('profile', false)}
      maxWidth='xs'
    >
      <Typography variant="h6" sx={{mb: 2}}>
        {t('profile.myProfile')}
      </Typography>
      
      <Grid container>
        <Grid item xs={4}>
          <AvatarComponent name={name} image={avatar} sx={{width: 100, height: 100}} />
        </Grid>
        <Grid item xs={8}>
          <Typography variant='h5'>{name}</Typography>
          <Typography variant='caption' color='text.disabled'>{email}</Typography>
          <Box sx={{my: 1, ...verticalCentered}}>
            <Avatar alt={companyName} src={logo ? logo : companyLogo} />
            <Box sx={{ml: 1}}>
              <Typography variant='caption' color='text.disabled'>{t('profile.organization')}</Typography>
              <Typography variant='h6' sx={{mb: 1}}>{companyName}</Typography>  
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ModalWrapper>
  )
})