/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-30 13:05:28
 * @modify date 2022-01-30 13:05:28
 * @desc [description]
 */
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Typography, Box } from '@mui/material'

import { useStyles } from './card-image-link.styles'
import { CardImageLinkProps } from './card-image-link.props'

export const CardImageLink: React.FC<CardImageLinkProps> = (props: CardImageLinkProps) => {
  const classes = useStyles()
  const { title, image, linkUrl } = props

  return (
    <Link to={linkUrl}>
      <Card variant="outlined" className={classes.wrapper}>
        <Box className={classes.imageWrapper}>
          <img src={image} alt={title} className={classes.image} />
        </Box>
        <Typography variant='subtitle2' align='center' sx={{mb: 2, px: 1}}>
          {title}
        </Typography>
      </Card>
    </Link>
  )
} 