/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-24 21:47:29
 * @modify date 2022-01-24 21:47:29
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors, horizontalCentered, verticalCentered } from 'assets'
const textBase= () => {
  return {
    ...horizontalCentered,
    flexDirection: 'column'
  }
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      whiteSpace: 'nowrap'
    },
    rtl: {
      ...verticalCentered,
      textAlign: 'end',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      flexDirection: 'row-reverse',
    },
    company: {
      color: colors.text.o25
    },
    text: {
      ...textBase
    },
    reportText: {
      ...textBase,
      maxWidth: 125,
      overflow: 'hidden'
    }
  })
)