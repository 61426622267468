/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-09 20:50:08
 * @modify date 2022-02-09 20:50:08
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors, centered, verticalCentered, horizontalCentered, withBorder } from 'assets'

const gridItem = {
  ...verticalCentered,
  ...withBorder,
  padding: 10,
  alignItems: 'start'
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    vertical: {
      ...verticalCentered
    },
    headerWrapper: {
      ...centered,
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    },
    buttonWrapper: {
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(5),
      [theme.breakpoints.up('md')]: {
        paddingBottom: 0,
        display: 'flex',
        justifyContent: 'flex-end'
      }
    },
    headerContainer: {
      ...centered,
      flexDirection: 'column',
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
        textAlign: 'center'
      }
    },
    headerItem: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block'
      }
    },
    headerButtons: {
      ...verticalCentered,
      justifyContent: 'space-around',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    wheelWrapper: {
      display: 'inline-flex',
      maxHeight:  350,
      maxWidth: '100%',
      overflow: 'auto',
      marginBottom: theme.spacing(3),
      flexWrap: 'nowrap'
    },
    wheelItem: {
      minWidth: 300,
      [theme.breakpoints.up('md')]: {
        minWidth: 'auto'
      }
    },
    wheelInfo: {
      borderStyle: 'dashed',
      padding: theme.spacing(1),
      textAlign: 'center',
      color: colors.primary.main,
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    exportButton: {
      display: 'block',
      backgroundColor: 'white',
      bottom: 0,
      zIndex: 1,
      padding: theme.spacing(2),
      position: 'fixed',
      borderRadius: '10px 10px 0 0',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    headerCard: {
      ...horizontalCentered,
      height: 300,
      padding: theme.spacing(2),
      flexDirection: 'column',
      borderStyle: 'dashed'
    },
    imageWrapper: {
      ...centered,
      flex: 1,
      cursor: 'pointer',
      marginBottom: theme.spacing(1)
    },
    image: {
      width: 100,
      height: 100
    },
    infoWrapper: {
      marginBottom: theme.spacing(3)
    },
    infoHeader: {
      ...centered,
      ...withBorder,
      backgroundColor: 'white',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
    },
    infoItemWrapper: {
      backgroundColor: 'white',
      '&.collapsed': {
        marginBottom: theme.spacing(2.5)
      },
    },
    infoItemHeader: {
      ...gridItem,
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        backgroundColor: colors.primary.main,
        color: 'white',
        '& svg': {
          color: 'white'
        }
      }
    },
    infoItem: {
      ...gridItem,
      textAlign: 'center',
      justifyContent: 'center',
      borderStyle: 'dashed',
      color: colors.primary.main,
      '&.color-0': {
        color: 'white',
        backgroundColor: colors.primary.o25
      },
      '&.color-1': {
        color: 'white',
        backgroundColor: colors.primary.o50
      },
      '&.color-2': {
        color: 'white',
        backgroundColor: colors.primary.o75
      },
      '&.color-3': {
        color: 'white',
        backgroundColor: colors.primary.main
      },
      '&.no-content': {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'block'
        }
      },
    },
    infoItemName: {
      color: colors.text.primary,
      width: '50%',
      textAlign: 'left',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    infoItemValue: {
      width: '50%',
    },
    wheel: {
      width: '100%',
      height: '100%',
      flex: 1,
      position: 'relative'
    },
    sampleList: {
      ...verticalCentered,
      justifyContent: 'space-between',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    mobileOnly: {
      display: 'block !important',
      [theme.breakpoints.up('md')]: {
        display: 'none !important'
      }
    }
  })
)