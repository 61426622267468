/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-20 18:39:46
 * @modify date 2022-01-20 18:39:46
 * @desc A page to show info using image and title
 */
import React from 'react'

import { Container, Typography } from '@mui/material'

import { useStyles } from './info-page.styles'
import { InfoPageProps } from './info-page.props'

export const InfoPage: React.FC<InfoPageProps> = (props: InfoPageProps) => {
  const classes = useStyles()
  const { title, image, icon: Icon } = props

  return (
    <Container className={classes.wrapper}>
      {image && <img src={image} alt='Info' className={classes.image} />}
      {Icon && <Icon className={classes.image} />}
      {title && 
        <Typography variant="h1" component="h2">
          {title}
        </Typography>
        }
    </Container>
  )
}