import { Instance, SnapshotOut, types } from "mobx-state-tree"

import {
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"

import { withActivityActions } from "./activity-action"

/**
 * Model description here for TypeScript hints.
 */

const ActivityModel = types.model({
  eventType: types.string,
  doneBy: types.string,
  date: types.string
})

export const ActivityStoreModel = types
  .model("ActivityStore")
  .props({
    activities: types.optional(types.array(ActivityModel),[]),
    page: types.optional(types.number, 1),
    totalPage: types.optional(types.number, 0)
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withActivityActions)

type ActivityStoreType = Instance<typeof ActivityStoreModel>
export interface ActivityStore extends ActivityStoreType {}
type ActivityStoreSnapshotType = SnapshotOut<typeof ActivityStoreModel>
export interface ActivityStoreSnapshot extends ActivityStoreSnapshotType {}
