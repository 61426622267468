/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-03 20:13:36
 * @modify date 2022-04-04 17:45:34
 */

import React from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { some } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import { AddCircleOutline, DeleteOutline, ExpandMore } from '@mui/icons-material'
import { 
  Grid, Typography, Button, Box, TextField, IconButton, SvgIcon, Stack, 
  Accordion, AccordionSummary, AccordionDetails, Tab, Paper 
} from '@mui/material'

import { useStores } from 'models'
import { IconEdit } from 'assets/images'
import { ModalConfirm } from 'components'
import { globalAction, validURL } from 'utils'

import { useStyles } from './webhook.styles'

export const Webhooks: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { 
    userStore: { adminPrivileges },
    companyStore: { disableWebhook },
    webhookStore: { webhooks, addWebhook, getWebhooks, saveWebhook, getEntries, 
      setValue: setWebhookValue, selectedWebhook, formatEntries, removeWebhook, cancelAddWebhook
    },
    notificationStore
  } = useStores()

  const [isEdit, setIsEdit] = React.useState(false)
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [tab, setTab] = React.useState(null)
  const [error, setError] = React.useState(false)
  const invalidUrl = error && !validURL(selectedWebhook?.url)

  const onGetWebhook = () => globalAction(notificationStore, { action: getWebhooks })

  const onGetEntries = () => globalAction(notificationStore, { action: getEntries })

  const onSaveWebhook = () => {
    if(!validURL(selectedWebhook?.url)) {
      setError(true)
      return
    }
    
    globalAction(notificationStore, { 
      action: saveWebhook,
      afterAction: () => {
        onGetWebhook()
        setIsEdit(false)
        setError(false)
      }
    })
  }

  const onRemoveWebhook = async () => {
    setOpenConfirm(false)
    
    globalAction(notificationStore, { 
      action: removeWebhook,
      afterAction: () => onGetWebhook()
    })
  }

  const renderWebhookItem = (webhook) => {
    if (isEdit && webhook.id !== selectedWebhook?.id) return
    const isNew = webhook.id === 'new'
    
    return (
      <Grid item xs={12} key={webhook.id} className={clsx(classes.item, isEdit && 'is-edit')}>
        {isEdit ?
          <TextField
            fullWidth
            size='small'
            value={webhook.url}
            placeholder='http://your.url.com'
            onChange={(e) => selectedWebhook?.setValue('url', e.target.value)}
            error={invalidUrl}
            {...(isEdit && {helperText: invalidUrl ? t('webhook.error.webhookUrlIsInvalidMessage') : t('webhook.includeUrlDescription')})}
            sx={{mb: 1}}
          /> :
          <Typography sx={{mb: 1}}>{webhook.url}</Typography>
        }
        {!isEdit &&
          <Stack direction='row' spacing={2} justifyContent="space-between">
            {!isNew && 
              <Button
                className='btn-text'
                startIcon={<ExpandMore />}
                onClick={() => {
                  setWebhookValue('selectedWebhook', webhook.id)
                  onGetEntries()
                }}
              >
                {t('webhook.seeWebhooks')}
              </Button>
            }
            {adminPrivileges &&
              <Stack direction='row' spacing={2} sx={{height: 'fit-content'}}>
                <IconButton 
                  size='small'
                  color='primary'
                  className='bg'
                  disabled={isEdit}
                  onClick={() => {
                    setWebhookValue('selectedWebhook', webhook.id)
                    setIsEdit(true)
                    setError(false)
                  }}
                >
                  <SvgIcon component={IconEdit} fontSize='small' inheritViewBox />
                </IconButton>
                <IconButton 
                  size='small'
                  color='primary'
                  className='bg'
                  disabled={isEdit || isNew}
                  onClick={() => {
                    setWebhookValue('selectedWebhook', webhook.id)
                    setOpenConfirm(true)
                  }}
                >
                  <SvgIcon component={DeleteOutline} inheritViewBox />
                </IconButton>
              </Stack>
            }
          </Stack>
        }
      </Grid>
    )
  }

  const renderEntryItem = (entry, index) => {
    const { createdAt, requestHeaders, requestBody, responseStatus, responseHeaders, responseBody } = entry

    return (
      <Accordion key={`${uuidv4}${index}`} className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
        >
          <Typography color='primary'>{moment(createdAt).format('YYYY-MM-DD HH:mm')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TabContext value={tab || `entryRequest${index}`}>
            <TabList 
              textColor="primary"
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              onChange={(event, value) => setTab(value)} 
              aria-label="Profile settings"
            >
              <Tab value={`entryRequest${index}`} label={t('webhook.request')} className={classes.tab} />
              <Tab value={`entryResponse${index}`} label={t('webhook.response')} className={classes.tab} />
            </TabList>

            <TabPanel value={`entryRequest${index}`}>
              <Typography variant='h6' sx={{mb: 1}}>{t('webhook.headers')}</Typography>
              <Paper elevation={0} className={classes.preWrapper}>
                <pre className={classes.preText}>
                  {JSON.stringify(requestHeaders, undefined, 2)}
                </pre>
              </Paper>

              <Typography variant='h6' sx={{mb: 1}}>{t('webhook.body')}</Typography>
              <Paper elevation={0} className={classes.preWrapper}>
                <pre className={classes.preText}>
                  {JSON.stringify(requestBody, undefined, 2)}
                </pre>
              </Paper>
            </TabPanel>

            <TabPanel value={`entryResponse${index}`}>
            <Typography variant='h6' sx={{mb: 1}}>{t('webhook.status')}</Typography>
            <Paper elevation={0} className={classes.preWrapper}>
              <pre className={classes.preText}>
                {JSON.stringify(responseStatus, undefined, 2)}
              </pre>
            </Paper>

              <Typography variant='h6' sx={{mb: 1}}>{t('webhook.headers')}</Typography>
              <Paper elevation={0} className={classes.preWrapper}>
                <pre className={classes.preText}>
                  {JSON.stringify(responseHeaders, undefined, 2)}
                </pre>
              </Paper>

              <Typography variant='h6' sx={{mb: 1}}>{t('webhook.body')}</Typography>
              <Paper elevation={0} className={classes.preWrapper}>
                <pre className={classes.preText}>
                  {JSON.stringify(JSON.parse(responseBody), undefined, 2)}
                </pre>
              </Paper>
            </TabPanel>
          </TabContext>
        </AccordionDetails>
      </Accordion>
    )
  }

  React.useEffect(() => {
    (async () => {
      setWebhookValue('page', 1)
      await onGetWebhook()
    })()
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (disableWebhook) return null

  return (
    <TabPanel value="webhooks">
      <Typography variant='body2' sx={{mb: 2}}>{t('webhook.webhooksDescription')}</Typography>
      <Grid container spacing={2} sx={{mt: 2, mb: 3}}>
        {webhooks.map(webhook => renderWebhookItem(webhook))}
      </Grid>
      
      {!some(webhooks, ['id', 'new']) && adminPrivileges &&
        <Box sx={{mb: 3, textAlign: 'center'}}>
          <Button 
            className='btn-text' 
            startIcon={<AddCircleOutline />}
            onClick={() => {
              setError(false)
              setIsEdit(true)
              addWebhook()
            }}
          >
            {t('webhook.addAddress')}
          </Button>
        </Box>
      }

      {isEdit &&
        <Stack direction='row' spacing={2}>
          <Button
            fullWidth
            variant='outlined'
            onClick={() => {
              setIsEdit(false)
              cancelAddWebhook()
            }}
          >
            {t('webhook.cancel')}
          </Button>
          <Button
            fullWidth
            variant='contained'
            disabled={!selectedWebhook?.url}
            onClick={onSaveWebhook}
          >
            {t('webhook.saveUrl')}
          </Button>
        </Stack>
      }

      {formatEntries.length > 0 ?
        <Box sx={{mt: 3}}>
          <Typography variant='h6' sx={{mb: 2}}>{t('webhook.recentDeliveries')}</Typography>

          <Stack spacing={2}>
            {formatEntries.map((entry, index) => renderEntryItem(entry, index))}
          </Stack>
        </Box> :
        <Typography variant='body2' align='center' sx={{mt: 3}}>
          {t('webhook.noRecentDevelivery')}
        </Typography>
      }
      
      <ModalConfirm 
        open={openConfirm}
        title={t('webhook.removeConfirmWebhookTitle')}
        description={t('webhook.removeConfirmWebhookDescription')}
        confirm={t('webhook.confirm')}
        cancel={t('webhook.cancel')}
        onClose={() => setOpenConfirm(false)}
        onConfirm={onRemoveWebhook}
      />
    </TabPanel>
  )
})