/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-04 21:41:31
 * @modify date 2022-02-04 21:41:34
 */

import React from 'react'
import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AddCircle, Upgrade, Search, FormatListBulleted, ViewComfy } from '@mui/icons-material'
import { Box, Grid, Button, Container, TextField, InputAdornment, ToggleButtonGroup, ToggleButton, Stack, Typography } from '@mui/material'

import { colors } from 'assets'
import { useStores } from 'models'
import { globalAction } from 'utils'
import { DEBOUNCE_TIME } from 'config/env'
import { findRoute } from 'constants/routes'
import { HeaderTitle, ModalConfirm, Pagination } from 'components'
import { emptySearch } from 'assets/images'

import { useStyles } from './cuppers.styles'
import { CardInfo, CupperItem, InviteCupper } from './components'

export const Cuppers: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    userStore: { adminPrivileges, isCompanyAdmin },
    cupperStore: {
      members, admins, pendingInvitations, seatsAvailable, getCupperSummaries,
      cuppers: cupperMembers, search, page, totalPage, getCuppers,
      remindCupper, removeCupper, adminCupper, activeCupper, createCuppingCupper, approveSamplePermission,
      setValue: setCupperStoreValue
    },
    modalStore: { setValue: setModalValue },
    guideStore: { guideCupper },
    notificationStore
  } = useStores()

  const planLink = findRoute('plan')
  const [isMobile, setIsMobile] = React.useState(false)
  const [view, setView] = React.useState('list')
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [isRemove, setIsRemove] = React.useState(false)
  const [confirmAction, setConfirmAction] = React.useState(() => () => {})

  const infoCards = [
    { title: 'members', counter: members },
    { title: 'admins', counter: admins },
    { title: 'pendingInvites', counter: pendingInvitations },
    { title: 'seatsAvailable', counter: seatsAvailable }
  ]

  const buttonContent = () => {
    return (
      <Stack direction="row" spacing={2}>
        {(adminPrivileges || isCompanyAdmin) &&
          <Button
            variant="contained"
            startIcon={<AddCircle />}
            sx={{borderRadius: 8}}
            onClick={() => setModalValue('inviteCupper', true)}
          >
            {t('cupper.inviteMember')}
          </Button>
        }
        {isCompanyAdmin && !(window as any).isRNWebview &&
          <Button
            variant="outlined"
            startIcon={<Upgrade />}
            sx={{borderRadius: 8}}
            onClick={() => navigate(planLink)}
          >
            {t('subscription.upgradePlans')}
          </Button>
        }
      </Stack>
    )
  }

  const loadCuppers = () => {
    globalAction(notificationStore, {
      action: async () => {
        await getCuppers()
        await getCupperSummaries()
      }
    })
  }

  const onRemindCupper = (id) => {
    setOpenConfirm(false)
    if (guideCupper) return

    globalAction(notificationStore, {
      action: async() => await remindCupper(id)
    })
  }

  const onRemoveCupper = (id) => {
    setOpenConfirm(false)
    if (guideCupper) return

    globalAction(notificationStore, {
      action: async() => await removeCupper(id),
      afterAction: () => loadCuppers()
    })
  }

  const onSearch = React.useCallback(
    debounce(async () => {
      setCupperStoreValue('page', 1)
      globalAction(notificationStore, {
        action: getCuppers
      })
    }, DEBOUNCE_TIME), []
  )

  const onAction = (action) => {
    globalAction(notificationStore, {
      action: action,
      ...(!guideCupper && {afterAction: () => loadCuppers()})
    })
  }

  React.useEffect(() => { onSearch() }, [search])

  React.useEffect(() => {
    globalAction(notificationStore, { action: getCuppers })
  }, [page])

  React.useEffect(() => loadCuppers(), [guideCupper])

  React.useLayoutEffect(() => {
    window.addEventListener('resize', () => {
      if(window.innerWidth < 900) {
        setView('list')
        setIsMobile(true)
        return
      }

      setIsMobile(false)
    })
  }, [])

  return (
    <>
      <HeaderTitle
        breadcrumb={t('menu.cuppers')}
        title={t('cupper.manageCuppers')}
        rightContent={buttonContent()}
      />

      <Container sx={{px: {xs: 0, md: 3}}}>
        <Grid container spacing={isMobile ? 0 : 2} className={classes.gridWrapper}>
          {infoCards.map((card, index) =>
            <Grid item xs={6} md={3} key={index} className={classes.gridItem}>
              <CardInfo {...card} rightBorder={index % 2 === 0} />
            </Grid>
          )}
        </Grid>
      </Container>

      <Container sx={{px: {xs: 0, md: 3}, py: 4}}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size='small'
              placeholder={t('cupper.searchNameOrEmail')}
              value={search}
              onChange={(e) => setCupperStoreValue('search', e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              className={classes.searchForm}
            />
          </Grid>
        </Grid>
      </Container>

      {cupperMembers.length > 0 ?
        <Container sx={{px: {xs: 0, md: 3}}}>
          <Grid container spacing={2} className={classes.gridWrapper}>
            <Grid item xs={12} className={classes.gridHeader}>
              <Grid
                container
                sx={{alignItems: 'end', color: view === 'module' ? colors.body.secondary : 'inherit'}}
              >
                <Grid item md={3}>
                  <Box sx={{ml: 3}}>{t('cupper.name')}</Box>
                </Grid>
                <Grid item md={6}>
                  <Grid container sx={{textAlign: 'center', alignItems: 'end'}}>
                    <Grid item xs={3}>{t('cupper.canApproveOrRejectSamples')}</Grid>
                    <Grid item xs={3}>{t('cupper.canCreateCuppingSession')}</Grid>
                    <Grid item xs={3}>{t('cupper.admin')}</Grid>
                    <Grid item xs={3}>{t('cupper.active')}</Grid>
                  </Grid>
                </Grid>
                <Grid item md={2}>
                  {t('cupper.lastActivity')}
                </Grid>
                <Grid item md={1} className={classes.toggleButtonWrapper}>
                  <ToggleButtonGroup
                    value={view}
                    exclusive
                    onChange={(event, value) => setView(value)}
                  >
                    <ToggleButton
                      value="module"
                      color='primary'
                      aria-label="module"
                      className={classes.toggleButton}
                    >
                      <ViewComfy />
                    </ToggleButton>
                    <ToggleButton
                      value="list"
                      color='primary'
                      aria-label="list"
                      className={classes.toggleButton}
                    >
                      <FormatListBulleted />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Grid>
            {cupperMembers.map((member, index) =>
              <Grid item xs={view === 'module' ? 4 : 12} key={index} className={classes.gridItem}>
                <CupperItem
                  {...member}
                  isAdmin={adminPrivileges}
                  module={view === 'module'}
                  onRemindCupper={(id) => {
                    setIsRemove(false)
                    setOpenConfirm(true)
                    setConfirmAction(() => () => onRemindCupper(id))
                  }}
                  onRemoveCupper={(id) => {
                    setIsRemove(true)
                    setOpenConfirm(true)
                    setConfirmAction(() => () => onRemoveCupper(id))
                  }}
                  onCreateCupping={(id, status) => onAction(() => createCuppingCupper(id, status))}
                  onAdminPrivilege={(id, status) => onAction(() => adminCupper(id, status))}
                  onActiveStatus={(id, status) => onAction(() => activeCupper(id, status))}
                  onApproveSamplePermission={(id, status) => onAction(() => approveSamplePermission(id, status))}
                />
              </Grid>
            )}
          </Grid>
          {totalPage > 0 && <Pagination page={page} count={totalPage} onChange={(e, p) => setCupperStoreValue('page', p)} /> }
        </Container> :
        <Container sx={{textAlign: 'center'}}>
          <Typography variant="h3" sx={{mb: 4}}>
            {t('cupper.noResultFound')}
          </Typography>
          <img src={emptySearch} alt="Not found" />
        </Container>
       }

      <InviteCupper />

      <ModalConfirm
        open={openConfirm}
        description={isRemove ?
          `${t('cupper.removeCupperConfirmDescription')}` :
          `${t('cupper.remindCupperConfirmDescription')}`
        }
        onClose={() => setOpenConfirm(false)}
        onConfirm={confirmAction}
      />
    </>
  )
})
