/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-22 02:19:05
 * @modify date 2022-04-22 02:19:05
 */

import i18n from 'i18n/i18n'
import { pick, omit, assign, includes } from "lodash"
import { flow, applySnapshot, getSnapshot } from "mobx-state-tree"
import mixpanel from 'mixpanel-browser';

import { ApiResult, ApiErrorKind } from "services/api"
import { REFRESH_TOKEN_STORAGE_KEY, MIXPANEL_API_KEY } from 'config/env'
import { loadString, save } from "utils/storage"

export const withUserActions = (self: any) => ({
  actions: {
    setUser(user) {
      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        ...user,
        id: "me",
        userId: user.id,
        password: "",
        passwordConfirmation: "",
        token: "",
        captchaToken: "",
        termsOfService: false,
      })
    },
    setMessage(locale: string, parameters: any = {}) {
      const { setNotification } = self.rootStore.notificationStore
      setNotification({
        severity: 'success',
        message: i18n.t(locale, parameters)
      })
    },
    setCompanyStore(company) {
      const { setCompany } = self.rootStore.companyStore
      setCompany(company)
    },
    setPaymentHistories(histories) {
      const { setHistories } = self.rootStore.subscriptionStore
      setHistories(histories)
    },
    login: flow(function * () {
      try {
        mixpanel.init(MIXPANEL_API_KEY, {debug: false});
        const payload: any = pick(self, ['email', 'password', 'captchaToken'])
        const additionalPath: string = 'login'
        const res: ApiResult = yield self.environment.authApi.save(payload, {}, additionalPath)

        if (res.kind === "ok") {
          self.setUser(res.data)
          self.setCompanyStore(res.data.company)
          self.setPaymentHistories(res.data.paymentHistories || [])
          self.setMessage('signIn.success.successfullySignIn', {name: res.data.name})
          self.rootStore.modalStore.resetError()
          save("iplocation", res.data.iplocation)
          mixpanel.track("user-login", res.data)
          self.setValue('isAuthenticated', true)
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    forgotPassword: flow(function * () {
      try {
        const payload: any = pick(self, ['email', 'captchaToken'])
        const additionalPath: string = 'forgot_password'
        const res: ApiResult = yield self.environment.authApi.save(payload, {}, additionalPath)

        if (res.kind === "ok") return res.data
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    resetPassword: flow(function * () {
      try {
        const payload: any = pick(self, ['password', 'passwordConfirmation', 'token', 'captchaToken'])
        const additionalPath: string = 'reset_password'
        const res: ApiResult = yield self.environment.authApi.save(payload, {}, additionalPath)

        if (res.kind === "ok") {
          self.setUser(res.data)
          self.setCompanyStore(res.data.company)
          self.setPaymentHistories(res.data.paymentHistories || [])
          self.setMessage('resetPassword.success.successfullyResetPassword')
          self.rootStore.modalStore.resetError()
          self.setValue('isAuthenticated', true)
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    register: flow(function * () {
      try {
        let meta = {}
        const { name, initialPlanIdSelection } = self.rootStore.companyStore
        let payload: any = {...pick(self, [
            'languagePreference', 'name', 'email', 'countryCode', 'password',
            'passwordConfirmation', 'couponCode', 'termsOfService', 'captchaToken'
          ]),
          'companyAttributes': { name, 'initial_plan_id_selection': initialPlanIdSelection }
        }

        if (self.isAuthenticated) {
          payload = omit(payload, ['couponCode', 'companyAttributes'])
          meta = {context: 'registration'}
        }

        const additionalPath: string = 'register'
        const res: ApiResult = self.isAuthenticated ?
          yield self.environment.userApi.save(assign(payload, {id: self.id}), meta) :
          yield self.environment.authApi.save(payload, meta, additionalPath)

        if (res.kind === "ok") {
          if (self.isAuthenticated) self.registrationCompleted = true
          self.setUser(res.data)
          self.setCompanyStore(res.data.company)
          self.rootStore.modalStore.setValue('welcome', true)
          self.isAuthenticated = false
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    newRegistration: flow(function * (isEvent = false) {
      try {
        const { companyType } = self.rootStore.companyStore

        const meta = {context: 'easy_registration'}
        const payload: any = {
          ...pick(self, ['email', 'captchaToken', 'role', 'isQgrader', 'isProducer']),
          'companyAttributes': { 'company_type': companyType }, isEvent
        }

        const additionalPath: string = 'register'
        const res: ApiResult = yield self.environment.authApi.save(payload, meta, additionalPath)

        if (res.kind === "ok") {
          if (self.isAuthenticated) self.registrationCompleted = true
          self.setUser(res.data)
          self.setCompanyStore(res.data.company)
          self.rootStore.modalStore.setValue('welcome', true)
          self.isAuthenticated = false
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    logout: flow(function * () {
      try {
        self.rootStore.reset()

        const additionalPath: string = 'logout'
        yield self.environment.authApi.remove(additionalPath)
        yield self.environment.authApi.removeJWToken()
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    saveProfile: flow(function * () {
      try {
        const payload: any = pick(self, ['id', 'name', 'email', 'languagePreference', 'timeZone', 'role', 'isQgrader', 'isProducer'])
        const meta: object = { context: 'profile_update' }

        const res: ApiResult = yield self.environment.userApi.save(payload, meta)

        if (res.kind === "ok") {
          self.setUser(res.data)
          self.setMessage('profile.success.successfullyUpdated')
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    updatePassword: flow(function * (currentPassword) {
      try {
        const payload: any = { ...pick(self, ['id', 'password', 'passwordConfirmation']), currentPassword }
        const meta: object = { context: 'update_password' }

        const res: ApiResult = yield self.environment.userApi.save(payload, meta)

        if (res.kind === "ok") {
          self.setMessage('profile.success.successfullyUpdatedPassword')
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    uploadAvatar: flow(function * (avatar) {
      try {
        const payload: any = {
          id: self.id,
          profilePicture: avatar
        }

        const res: ApiResult = yield self.environment.userApi.uploadImage(payload)

        if (res.kind === "ok") {
          self.setUser(res.data)
          self.setMessage('profile.success.successfullyUploaded')
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    removeAvatar: flow(function * () {
      try {
        const additionalPath: string = 'remove_avatar'

        const res: ApiResult = yield self.environment.userApi.remove(self.id!, {}, additionalPath)

        if (res.kind === "ok") {
          self.avatar = ''
          self.setMessage('profile.success.successfullyRemoved')
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    updateVideoTutorial: flow(function * () {
      try {
        const payload: any = pick(self, ['id', 'sawVideoTutorial'])
        yield self.environment.userApi.save(payload)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    updateNavigationTutorial: flow(function * () {
      try {
        const payload: any = pick(self, ['id', 'sawNavigationTutorial'])
        yield self.environment.userApi.save(payload)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    refreshToken: flow(function * () {
      try {
        const token = yield loadString(REFRESH_TOKEN_STORAGE_KEY)
        const additionalPath: string = 'refresh_tokens'
        const payload: any = {
          email: self.email,
          refreshToken: token
        }

        yield self.environment.authApi.save(payload, {}, additionalPath)
      } catch (error: any) {
        const logoutRequired = includes([ApiErrorKind.UNPROCESSABLE, ApiErrorKind.NOT_FOUND], error.kind)
        if (logoutRequired) return yield self.logout()

        yield self.checkForGeneralError(error)
      }
    }),
    verifyEmail: flow(function * () {
      try {
        const additionalPath: string = 'confirmation'
        const payload: any = { confirmation_token: self.token}

        const res: ApiResult = yield self.environment.authApi.all(payload, additionalPath)

        if (res.kind === "ok") {
          self.setUser(res.data)
          self.setCompanyStore(res.data.company)
          self.setPaymentHistories(res.data.paymentHistories || [])
          self.setMessage('signIn.success.successfullySignIn', {name: res.data.name})
          self.rootStore.modalStore.resetError()
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    removeMyAccount: flow(function * (email: string, teamEmail?: string) {
      try {
        const payload: any = { email, team_email: teamEmail }
        const res: ApiResult = yield self.environment.userApi.remove(self.id, payload)

        if (res.kind === "ok") {
          self.rootStore.reset()
          yield self.environment.authApi.removeJWToken()
          window.location.href = '/sign-in'
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
  }
})
