/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-06 20:03:48
 * @modify date 2022-02-06 20:03:48
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: 'white',
      padding: theme.spacing(4),
      borderRadius: 5,
      border: `1px solid ${colors.magnolia}`,
    },
    iconRounded: {
      width: 20,
      height: 20,
      backgroundColor: 'white',
      '& svg': {
        height: 16,
        fill: theme.palette.primary.main,
      }
    },
    buttonRounded: {
      borderRadius: theme.spacing(8),
      '&:hover': {
        '& svg': {
          fill: theme.palette.primary.dark
        }
      }
    },
    content: {
      alignItems: 'center',
      padding: 8,
      borderRadius: 5,
      border: `1px solid ${colors.magnolia}`,
      marginBottom: theme.spacing(2),
    },
    collapseWrapper: {
      padding: theme.spacing(2),
      justifyContent: 'space-between',
      backgroundColor: colors.magnolia,
      borderRadius: 4
    }
  })
)
