import {applySnapshot, flow} from "mobx-state-tree"

import { remove } from "utils/storage"
import {JWT_STORAGE_KEY, LANGUAGE_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY} from "config/env"

export const withRootStoreAction = (self: any) => ({
  actions: {
    resetStoreCache() {
      applySnapshot(self, {
        userStore: self.userStore,
        companyStore: self.companyStore,
      })
    },
    resetStore: flow(function * (redirect = true) {
      yield remove(JWT_STORAGE_KEY)
      yield remove(REFRESH_TOKEN_STORAGE_KEY)
      yield remove(LANGUAGE_STORAGE_KEY)
      yield self.reset()
      if (redirect) window.location.href = "/"
    })
  }
})
