/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-18 00:15:10
 * @modify date 2022-03-30 22:10:49
 */

import React from 'react'
import { has } from 'lodash'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { TextField, Container, Typography } from '@mui/material'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { useStores } from "models"
import { findRoute } from 'constants/routes'
import { validateEmail, errorField } from 'utils'
import  { AuthenticationWrapper, StackAlert } from 'components'

import { useStyles } from './forgot-password.styles'
import { ForgotPasswordProps } from './forgot-password.props'

export const ForgotPassword: React.FC<ForgotPasswordProps> = observer((props: ForgotPasswordProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const signInLink = findRoute('signIn')
  const { userStore: { email, forgotPassword, setValue } } = useStores()

  const { image } = props
  const [isLoading, setIsLoading] = React.useState(false)
  const [disable, setDisable] = React.useState(false)
  const [errors, setErrors] = React.useState<any>([])

  const sendInstruction = async() => {
    if (!validateEmail(email)) {
      setErrors({"email": [{ message: t('forgotPassword.error.emailIsInvalid')}]})
      return
    }

    try {
      setIsLoading(true)

      if (!executeRecaptcha) return

      const token = await executeRecaptcha('public')
      setValue('captchaToken', token)
      await forgotPassword()
      setErrors({
        "base": [{ 
          severity: 'success',
          message: t('forgotPassword.success.successfullyResetEmail')
        }]
      })
      setDisable(true)
    } catch (e: any) {
      setValue('captchaToken', null)
    } finally {
      setIsLoading(false)
    }     
  }

  const onEnter = async (e) => {
    if (e.key === 'Enter') sendInstruction()
  }

  return (
    <AuthenticationWrapper title={t('forgotPassword.title')} image={image} >
      <Typography variant='h4' className={classes.title}>{t('forgotPassword.header')}</Typography>
      {has(errors, 'base') && <StackAlert alerts={errors['base']} />}

      <TextField 
        fullWidth 
        autoFocus
        sx={{my: 2}} 
        variant="standard" 
        label={t('forgotPassword.email')} 
        disabled={disable}
        value={email} 
        onChange={event => setValue('email', event.target.value)}
        onKeyPress={onEnter}
        {...errorField('email', email, errors)}
      />

      <Container className={classes.buttonWrapper}>
        <LoadingButton
          size="large"
          variant="contained"
          disabled={disable}
          loading={isLoading}
          onClick={sendInstruction}
          className={classes.button}
          sx={{ fontSize: 'h6.fontSize' }}
        >
          {t('forgotPassword.resetYourPassword')}
        </LoadingButton>
      </Container>

      <Typography align="center" variant="h6" sx={{my: 2}}>
        {t('forgotPassword.rememberYourPassword')}
        <Link to={signInLink}>{t('forgotPassword.goToSignIn')}</Link>
      </Typography>
    </AuthenticationWrapper>
  )
})