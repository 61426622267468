import React from "react";
import {useTranslation} from "react-i18next";
import {Box, Typography} from "@mui/material";

import {emptyShipment} from "assets/images";

export const Shipments = () => {
  const { t } = useTranslation()

  return (
    <Box py={4} textAlign="center">
      <img src={emptyShipment} />
      <Typography mt={2} variant="h5">{t('sample.detail.emptyShipment')}</Typography>
    </Box>
  )
}
