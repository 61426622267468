/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-05-30 23:02:13
 * @modify date 2022-06-03 03:14:42
 */

import React from 'react'
import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Elements } from '@stripe/react-stripe-js'

import { useStores } from 'models'
import { STRIPE_PROMISE } from 'config/env'
import { ModalWrapper, StripePayment } from 'components'

export const Card: React.FC = observer(() => {
  const { 
    companyStore: { isTrialExpired },
    modalStore: { addCard, setValue: setModalValue }
  } = useStores()

  return (
    <ModalWrapper 
      maxWidth='xs'
      open={addCard}
      {...(!isTrialExpired && {onClose: () => setModalValue('addCard', false)})}
    >
      <Box sx={{p: 4}}>
        <Elements stripe={STRIPE_PROMISE}>
          <StripePayment />
        </Elements>
      </Box> 
    </ModalWrapper>
  )
})