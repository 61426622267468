export const acidityDescriptorList = [{
	"id": 158,
	"label": "crisp",
	"color": "#F5E94C",
	"parent_id": null,
	"label_es": "crujiente",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "crocante",
	"label_zh-CN": "爽脆",
	"weight": 1.2,
	"label_fr-CA": "winy",
	"label_ko": "산뜻한",
	"label_id": "krispi",
	"label_ja": "サクサク"
}, {
	"id": 159,
	"label": "bright",
	"color": "#F5E94C",
	"parent_id": null,
	"label_es": "radiant",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "brilhante",
	"label_zh-CN": "明亮",
	"weight": 1.2,
	"label_fr-CA": "brillant",
	"label_ko": "밝은",
	"label_id": "terang",
	"label_ja": "明るい"
}, {
	"id": 160,
	"label": "vibrant",
	"color": "#F5E94C",
	"parent_id": null,
	"label_es": "vibrante",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "vibrante",
	"label_zh-CN": "酸甜震",
	"weight": 1.2,
	"label_fr-CA": "vibrant",
	"label_ko": "활기차고 선명한",
	"label_id": "penuh semangat",
	"label_ja": "活気のある"
}, {
	"id": 161,
	"label": "tart",
	"color": "#F5E94C",
	"parent_id": null,
	"label_es": "tart",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "azedo / acre",
	"label_zh-CN": "尖锐（柠檬的酸）",
	"weight": 1.17,
	"label_fr-CA": "acide",
	"label_ko": "시큼털털한",
	"label_id": "tart/asam yang tajam",
	"label_ja": "鋭い酸味、すっぱい"
}, {
	"id": 172,
	"label": "muted",
	"color": "#A2885C",
	"parent_id": null,
	"label_es": "apagado",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "apagado",
	"label_zh-CN": "低哑",
	"weight": 1.11,
	"label_fr-CA": "absente",
	"label_ko": "무미한",
	"label_id": "tidak menonjol",
	"label_ja": "くすんだ"
}, {
	"id": 173,
	"label": "dull",
	"color": "#A2885C",
	"parent_id": null,
	"label_es": "deslucido",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "maçante",
	"label_zh-CN": "平淡",
	"weight": 1.13,
	"label_fr-CA": "crémeux",
	"label_ko": "밋밋한",
	"label_id": "hambar",
	"label_ja": "気の抜けた"
}, {
	"id": 174,
	"label": "mild",
	"color": "#A2885C",
	"parent_id": null,
	"label_es": "leve",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "leve",
	"label_zh-CN": "柔和",
	"weight": 1.13,
	"label_fr-CA": "modéré",
	"label_ko": "맛이 부드러운",
	"label_id": "ringan",
	"label_ja": "マイルド"
}, {
	"id": 178,
	"label": "soft",
	"color": "#A9F3F9",
	"parent_id": null,
	"label_es": "blando",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "macio",
	"label_zh-CN": "柔软",
	"weight": 1.2,
	"label_fr-CA": "doux",
	"label_ko": "촉감이 부드러운",
	"label_id": "halus",
	"label_ja": "柔らかい"
}, {
	"id": 179,
	"label": "faint",
	"color": "#A9F3F9",
	"parent_id": null,
	"label_es": "débil",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "fraco",
	"label_zh-CN": "暗沉",
	"weight": 1.15,
	"label_fr-CA": "faible",
	"label_ko": "희미한",
	"label_id": "samar-samar",
	"label_ja": "淡い"
}, {
	"id": 180,
	"label": "delicate",
	"color": "#A9F3F9",
	"parent_id": null,
	"label_es": "delicado",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "delicado",
	"label_zh-CN": "精巧",
	"weight": 1.24,
	"label_fr-CA": "délicat",
	"label_ko": "섬세하고 우아한",
	"label_id": "elok",
	"label_ja": "デリケートな"
}, {
	"id": 183,
	"label": "quick",
	"color": "#9DB4E1",
	"parent_id": null,
	"label_es": "rápido",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "rápido",
	"label_zh-CN": "短",
	"weight": 1.15,
	"label_fr-CA": "vif",
	"label_ko": "여운이 짧은",
	"label_id": "cepat",
	"label_ja": "速い"
}, {
	"id": 184,
	"label": "clean",
	"color": "#9DB4E1",
	"parent_id": null,
	"label_es": "limpio",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "limpo",
	"label_zh-CN": "干净",
	"weight": 1.2,
	"label_fr-CA": "propre",
	"label_ko": "깨끗한",
	"label_id": "bersih",
	"label_ja": "クリーン"
}]