/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-20 21:57:40
 * @modify date 2022-04-20 21:57:40
 */

import { map, startCase, chain, camelCase } from 'lodash'
import { defects as defectList } from 'constants/form'

const setValues = (samples, filters, key = 'score') => {
  return chain(samples)
          .flatMap(sample => { return sample.descriptors })
          .filter(filters)
          .map(key)
          .value()
}

export const withCompareViews = (self: any) => ({
  views: {
    get sampleNames() {
      return map(self.samples, 'sampleInfo.name')
    },
    get sampleCountries() {
      return map(self.samples, 'sampleInfo.country')
    },
    get sampleTypes() {
      return map(self.samples, sample => { return startCase(sample?.sampleInfo?.sampleType || '') })
    },
    get sampleCropYears() {
      return map(self.samples, 'sampleInfo.cropYear')
    },
    get roastLevels() {
      return setValues(self.samples, ['name', 'roast_level'])
    },
    get fragrance() {
      return setValues(self.samples, ['name', 'fragrance'])
    },
    get fragranceDescriptors() {
      return setValues(self.samples, ['name', 'fragrance'], 'descriptor')
    },
    get aroma() {
      return setValues(self.samples, ['name', 'aroma'])
    },
    get salt() {
      return setValues(self.samples, ['name', 'salt'])
    },
    get saltDescriptors() {
      return setValues(self.samples, ['name', 'salt'], 'descriptor')
    },
    get bittersweet() {
      return setValues(self.samples, ['name', 'bittersweet'])
    },
    get flavor() {
      return setValues(self.samples, ['name', 'flavor'])
    },
    get flavorDescriptors() {
      return setValues(self.samples, ['name', 'flavor'], 'descriptor')
    },
    get aftertaste() {
      return setValues(self.samples, ['name', 'aftertaste'])
    },
    get aftertasteDescriptors() {
      return setValues(self.samples, ['name', 'aftertaste'], 'descriptor')
    },
    get acidity() {
      return setValues(self.samples, ['name', 'acidity'])
    },
    get acidityDescriptors() {
      return setValues(self.samples, ['name', 'acidity'], 'descriptor')
    },
    get body() {
      return setValues(self.samples, ['name', 'body'])
    },
    get bodyDescriptors() {
      return setValues(self.samples, ['name', 'body'], 'descriptor')
    },
    get balance() {
      return setValues(self.samples, ['name', 'balance'])
    },
    get balanceDescriptors() {
      return setValues(self.samples, ['name', 'balance'], 'descriptor')
    },
    get mouthfeel() {
      return setValues(self.samples, ['name', 'mouthfeel'])
    },
    get mouthfeelDescriptors() {
      return setValues(self.samples, ['name', 'mouthfeel'], 'descriptor')
    },
    get overall() {
      return setValues(self.samples, ['name', 'overall'])
    },
    get uniformity() {
      return setValues(self.samples, ['name', 'uniformity'])
    },
    get cleanCup() {
      return setValues(self.samples, ['name', 'clean_cup'])
    },
    get sweetness() {
      return setValues(self.samples, ['name', 'sweetness'])
    },
    get cleanCupScore() {
      return setValues(self.samples, ['name', 'clean_cup_score'])
    },
    get sweetnessScore() {
      return setValues(self.samples, ['name', 'sweetness_score'])
    },
    get nonUniform() {
      return setValues(self.samples, ['name', 'non_uniform_score'])
    },
    get defects() {
      return setValues(self.samples, ['name', 'defects'])
    },
    get greenGradingColor() {
      return map(self.samples, sample => { return startCase(sample?.lastGreenGrading?.color || '') })
    },
    get greenGradingWeight() {
      return map(self.samples, 'lastGreenGrading.weight')
    },
    get greenGradingSmell() {
      return map(self.samples, 'lastGreenGrading.smell')
    },
    get greenGradingDefects() {
      const defectsValues = chain(self.samples)
                            .map(sample => { return JSON.parse(sample?.defects || '') })
                            .value()

      return chain(defectList)
              .map(defect => {
                const key = startCase(defect.id)
                const values = map(defectsValues, camelCase(defect.id))
                return {...defect, name: key, values}
              })
              .value()
    }
  }
})
