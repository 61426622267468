/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-18 22:03:35
 * @modify date 2022-04-18 22:03:35
 */

import { types } from "mobx-state-tree"
const today = new Date()

export const cuppingSessionFormProps = {
  id: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  startsAt: types.optional(types.Date, today),
  endsAt: types.optional(types.Date, today.setDate(today.getDate() + 7)),
  remote: types.optional(types.boolean, false),
  location: types.optional(types.string, ''),
  description: types.optional(types.string, ''),
  sampleIdStructure: types.optional(types.string, ''),
  cuppingProtocol: types.optional(types.string, ''),
  descriptorSet: types.optional(types.string, ''),
  numberOfSamples: types.optional(types.number, 1),
  blind: types.optional(types.boolean, false),
  scoreTarget: types.optional(types.boolean, false),
  hideScoreTarget: types.optional(types.boolean, false),
  invitedCuppersIds: types.optional(types.array(types.union(types.string, types.number)), []),
  guestInvitations: types.optional(types.array(types.model({
    id: types.maybeNull(types.number),
    guestName: types.optional(types.string, ''),
    guestEmail: types.optional(types.string, ''),
  })), []),
  uniqueToken: types.optional(types.string, ''),
  canBeStarted: types.optional(types.boolean, true),
  numberForCompany: types.optional(types.number, 0),
  isPublic: types.maybeNull(types.optional(types.boolean, false)),
  hasBeenScored: types.maybeNull(types.optional(types.boolean, false)),
  sampleIds: types.optional(types.array(types.union(types.string, types.number)), []),
}
