/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-18 00:12:38
 * @modify date 2022-01-18 00:12:38
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors } from 'assets'
 
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      height: '100%'
    },
    cardWrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      border: 'none',
      height: '100%',
      padding: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        border: `1px solid ${colors.border.primary}`,
        borderRadius: 5,
        flexDirection: 'row',
        alignItems: 'center'
      }
    },
    image: {
      widht: 40,
      height: 40,
      [theme.breakpoints.up('md')]: {
        widht: 56,
        height: 56,
      }
    }
  })
)