/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-19 03:00:02
 * @modify date 2022-04-19 03:00:02
 */

import { types } from "mobx-state-tree"
import {defaultScoreValue, defaultScoreIntensity, defaultScoreScaValue} from 'constants/form'

export const sampleScoreProps = {
  id: types.maybeNull(types.number),
  cuppingProtocol: types.optional(types.string, ''),

  roastLevel: types.optional(types.number, 50),

  fragrance: types.optional(types.number, defaultScoreValue),
  fragranceIntensity: types.optional(types.number, defaultScoreIntensity),
  fragranceDescriptors: types.optional(types.array(types.string), []),
  fragranceDry: types.optional(types.number, 0),
  fragranceCrust: types.optional(types.number, 0),
  fragranceBreak: types.optional(types.number, 0),

  aroma: types.optional(types.number, defaultScoreValue),
  aromaIntensity: types.optional(types.number, defaultScoreIntensity),

  fragranceAromaDescriptors: types.optional(types.array(types.string), []),
  fragranceAromaNote: types.optional(types.string, ''),

  acidity: types.optional(types.number, defaultScoreValue),
  acidityDescriptors: types.optional(types.array(types.string), []),
  acidityIntensity: types.optional(types.number, defaultScoreIntensity),
  dryAcidity: types.optional(types.boolean, false),
  mediumAcidity: types.optional(types.boolean, false),
  sweetAcidity: types.optional(types.boolean, false),
  acidityNote: types.optional(types.string, ''),

  body: types.optional(types.number, defaultScoreValue),
  bodyDescriptors: types.optional(types.array(types.string), []),
  bodyIntensity: types.optional(types.number, defaultScoreIntensity),

  flavor: types.optional(types.number, defaultScoreValue),
  flavorIntensity: types.optional(types.number, defaultScoreIntensity),
  flavorDescriptors: types.optional(types.array(types.string), []),

  aftertaste: types.optional(types.number, defaultScoreValue),
  aftertasteIntensity: types.optional(types.number, defaultScoreIntensity),
  aftertasteDescriptors: types.optional(types.array(types.string), []),
  aftertasteBrackishToSavory: types.optional(types.number, 0),

  flavorAftertasteDescriptors: types.optional(types.array(types.string), []),
  saltyTaste: types.optional(types.boolean, false),
  sourTaste: types.optional(types.boolean, false),
  sweetTaste: types.optional(types.boolean, false),
  bitterTaste: types.optional(types.boolean, false),
  umamiTaste: types.optional(types.boolean, false),
  flavorAftertasteNote: types.optional(types.string, ''),

  balance: types.optional(types.number, defaultScoreValue),
  balanceDescriptors: types.optional(types.array(types.string), []),

  salt: types.optional(types.number, defaultScoreValue),
  saltDescriptors:types.optional(types.array(types.string), []),
  saltLowSalt: types.optional(types.number, 0),
  saltHiAcid: types.optional(types.number, 0),

  bittersweet: types.optional(types.number, defaultScoreValue),
  bittersweetLowBitter: types.optional(types.number, 0),
  bittersweetHiSweet: types.optional(types.number, 0),

  mouthfeel: types.optional(types.number, defaultScoreValue),
  mouthfeelDescriptors: types.optional(types.array(types.string), []),
  mouthfeelRoughToSmooth: types.optional(types.number, 0),
  mouthfeelIntensity: types.optional(types.number, defaultScoreIntensity),
  mouthfeelNote: types.optional(types.string, ''),
  roughMouthfeel: types.optional(types.boolean, false),
  creamyMouthfeel: types.optional(types.boolean, false),
  smoothMouthfeel: types.optional(types.boolean, false),
  mouthDryingMouthfeel: types.optional(types.boolean, false),
  metallicMouthfeel: types.optional(types.boolean, false),

  cleanCupScore: types.optional(types.number, defaultScoreValue),
  cleanCupDescriptors: types.optional(types.array(types.string), []),

  sweetnessScore: types.optional(types.number, defaultScoreValue),
  sweetnessIntensity: types.optional(types.number, defaultScoreIntensity),
  sweetnessNote: types.optional(types.string, ''),
  sweetnessDescriptors: types.optional(types.array(types.string), []),

  overall: types.optional(types.number, defaultScoreValue),
  overallNote: types.optional(types.string, ''),

  uniformity1: types.optional(types.boolean, true),
  uniformity2: types.optional(types.boolean, true),
  uniformity3: types.optional(types.boolean, true),
  uniformity4: types.optional(types.boolean, true),
  uniformity5: types.optional(types.boolean, true),
  uniformityDetected: types.maybeNull(types.boolean),

  nonUniform1: types.optional(types.boolean, false),
  nonUniform2: types.optional(types.boolean, false),
  nonUniform3: types.optional(types.boolean, false),
  nonUniform4: types.optional(types.boolean, false),
  nonUniform5: types.optional(types.boolean, false),
  nonUniformScore: types.optional(types.number, 0),

  cleanCup1: types.optional(types.boolean, true),
  cleanCup2: types.optional(types.boolean, true),
  cleanCup3: types.optional(types.boolean, true),
  cleanCup4: types.optional(types.boolean, true),
  cleanCup5: types.optional(types.boolean, true),

  sweetness1: types.optional(types.boolean, true),
  sweetness2: types.optional(types.boolean, true),
  sweetness3: types.optional(types.boolean, true),
  sweetness4: types.optional(types.boolean, true),
  sweetness5: types.optional(types.boolean, true),

  defective1: types.optional(types.boolean, false),
  defective2: types.optional(types.boolean, false),
  defective3: types.optional(types.boolean, false),
  defective4: types.optional(types.boolean, false),
  defective5: types.optional(types.boolean, false),

  defects: types.optional(types.number, 0),
  defectsDescriptors: types.optional(types.array(types.string), []),
  defectDetected: types.maybeNull(types.boolean),

  notes: types.maybeNull(types.optional(types.string, '')),

  cups: types.optional(types.number, 0),
  intensity: types.optional(types.number, 0),

  cupperName: types.maybeNull(types.string),
  cupperTitle: types.maybeNull(types.string),
  isScoreTarget: types.maybeNull(types.number),
  cupperId : types.maybeNull(types.number),
  totalScore: types.optional(types.number, 79),

  emoticons: types.optional(types.array(types.number), []),
  interestStatus: types.maybeNull(types.boolean),
  tastingScore: types.optional(types.number, 0),
  tastingDescriptors: types.optional(types.array(types.string), []),
}
