/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:54:32
 * @modify date 2022-01-13 15:54:32
 */
import i18n from "i18next"
import { AxiosRequestConfig } from "axios"
import { Serializer, Deserializer } from "jsonapi-serializer"
import { ApisauceInstance, create, ApiResponse } from "apisauce"
import { split, last, get, reduce, camelCase, mapKeys, snakeCase, omit } from "lodash"

import { saveString, loadString, remove } from "utils/storage"
import {
  API_URL,
  API_TIMEOUT,
  UPLOAD_TIMEOUT,
  API_VERSION,
  JWT_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
  LANGUAGE_STORAGE_KEY
 } from "config/env"

import { GeneralApiProblem, ApiErrorKind } from "./api-problem"

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * JSON:Api type.
   */
  type: string

  /**
   * JSON:Api attributes.
   */
  attributes: string[]

  /**
   * JSON:Api relationships.
   */
  relationships: object

  /**
   * Prefix
   */
  prefix: string

  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce: ApisauceInstance

  /**
   * Creates the api.
   *
   * @param type The JSON:Api query types.
   * @param attributes The JSON:Api query attributes.
   */
  constructor(type: string, attributes: string[], options: any = {}) {
    this.type = type
    this.attributes = attributes
    this.prefix = options.prefix
    this.relationships = options.relationships || {}
  }

  /**
   * Sets up the API.  This will be called during the boot-up
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  async setup() {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: API_URL,
      // timeout: API_TIMEOUT,
      headers: {
        "Content-Type": 'application/json',
        "Accept": `application/vnd.tastify.v${API_VERSION}+json`,
      },
    })
  }

  convertKeyToSnakeCase = (data: object) => mapKeys(data, (__, key) => snakeCase(key))
  convertKeyCamelCase = (data: object) => mapKeys(data, (__, key) => camelCase(key))

  removeEmptyPayload = (payload: object) => reduce(payload, (result, item, key) => {
    if (item === undefined || item === null) return result

    result[key] = item
    return result
  }, {})

  serialize(payload: object, meta?: object) {
    if (!payload) return {}

    const data = this.removeEmptyPayload(payload)
    const underscoreCaseMeta = meta ? this.convertKeyToSnakeCase(meta) : {}

    return new Serializer(this.type, {
      keyForAttribute: 'underscore_case',
      attributes: this.attributes,
      meta: underscoreCaseMeta
    }).serialize(data)
  }

  deserialize(payload: object) {
    if (!payload) return null

    const successes = get(payload, 'success', null)
    if (successes) {
      return reduce(successes, (result, success) => {
        const fieldName = last(split(success.source.pointer, '/'))
        const fieldNameCamel = fieldName ? camelCase(fieldName) : 'base'

        const newSuccess = { severity: 'success', title: success.title, message: success.detail }
        result[fieldNameCamel]
          ? result[fieldNameCamel].push(newSuccess)
          : (result[fieldNameCamel] = [newSuccess])

        return result
      }, {})
    }

    return new Deserializer({
      keyForAttribute: 'camelCase',
      ...this.relationships
    }).deserialize(payload)
  }

  deserializeError(response: ApiResponse<any>) {
    const errors = get(response.data, 'errors', null)

    if (!errors) return {}

    return reduce(errors, (result, error) => {
      const fieldName = last(split(error.source.pointer, '/'))
      const fieldNameCamel = fieldName ? camelCase(fieldName) : 'base'

      const newError = { code: error.code, title: error.title, message: error.detail, severity: 'error' }
      result[fieldNameCamel]
        ? result[fieldNameCamel].push(newError)
        : (result[fieldNameCamel] = [newError])

      return result
    }, {})
  }

  generateError(response: ApiResponse<any>): GeneralApiProblem | void {
    switch (response.problem) {
      case "CONNECTION_ERROR":
        return { kind: ApiErrorKind.CONNECTION, temporary: true, originalRequest: response.config }
      case "NETWORK_ERROR":
        return { kind: ApiErrorKind.CONNECTION, temporary: true, originalRequest: response.config }
      case "TIMEOUT_ERROR":
        return { kind: ApiErrorKind.TIMEOUT, temporary: true, originalRequest: response.config }
      case "SERVER_ERROR":
        return { kind: ApiErrorKind.SERVER, originalRequest: response.config }
      case "UNKNOWN_ERROR":
        return { kind: ApiErrorKind.UNKNOWN, temporary: true, originalRequest: response.config }
      case "CLIENT_ERROR":
        switch (response.status) {
          case 401:
            return { kind: ApiErrorKind.UNAUTHORIZED, originalRequest: response.config }
          case 403:
            return { kind: ApiErrorKind.FORBIDDEN, originalRequest: response.config }
          case 404:
            return { kind: ApiErrorKind.NOT_FOUND, originalRequest: response.config }
          case 410:
            return { kind: ApiErrorKind.GONE, originalRequest: response.config }
          case 422:
            return { kind: ApiErrorKind.UNPROCESSABLE, errors: this.deserializeError(response), originalRequest: response.config }
          default:
            return { kind: ApiErrorKind.REJECTED, originalRequest: response.config }
        }
      case "CANCEL_ERROR":
      default:
        return
    }
  }

  axiosConfig = async (): Promise<AxiosRequestConfig> => {
    const authorizationBearer = await loadString(JWT_STORAGE_KEY)
    const acceptLanguage = i18n.language || 'en'

    const config: AxiosRequestConfig = {
      headers: {
        "Accept-Language": acceptLanguage,
        ...(authorizationBearer && {"Authorization": authorizationBearer})
      }
    }

    return config
  }

  async saveJWToken(response: ApiResponse<any>) {
    const authorization = get(response.headers, 'authorization', null)
    const refreshToken = get(response.headers, 'refresh-token', null)
    const language = get(response.headers, 'accept-language', null)

    if (authorization) await saveString(JWT_STORAGE_KEY, authorization)

    if (refreshToken) await saveString(REFRESH_TOKEN_STORAGE_KEY, refreshToken)

    if (language) await saveString(LANGUAGE_STORAGE_KEY, language)
  }

  async removeJWToken() {
    await remove(JWT_STORAGE_KEY)
    await remove(REFRESH_TOKEN_STORAGE_KEY)
    await remove(LANGUAGE_STORAGE_KEY)
  }

  async processResult(response: ApiResponse<any>) {
    if (!response.ok) {
      const problem = this.generateError(response)
      if (problem) return Promise.reject(problem)
    }

    const deserializeData = await this.deserialize(response.data)
    const camelCaseMeta = response.data ? this.convertKeyCamelCase(response.data.meta) : {}

    await this.saveJWToken(response)

    return Promise.resolve({
      kind: "ok",
      data: deserializeData,
      links: response.data ? response.data.links : {},
      meta: camelCaseMeta
    })
  }

  generatePath(id?: string, additionalPath?: string): string {
    const path = [this.type]

    if (this.prefix) path.unshift(this.prefix)

    if (id) path.push(id)

    if (additionalPath) path.push(additionalPath)

    return path.join('/')
  }

  async save(payload: object, meta?: object, additionalPath?: string) {
    const id = get(payload, "id", null)
    const data = omit(payload, "id")
    const serializedPayload = this.serialize(data, meta)

    if (id) return this.update(id, serializedPayload, additionalPath)

    return this.create(serializedPayload, additionalPath)
  }

  async create(serializedPayload: object, additionalPath?: string) {
    const axiosConfig: AxiosRequestConfig = await this.axiosConfig()
    const response: ApiResponse<any> = await this.apisauce.post(this.generatePath(additionalPath), serializedPayload, axiosConfig)

    return await this.processResult(response)
  }

  async update(id: string, serializedPayload: object, additionalPath?: string) {
    const axiosConfig: AxiosRequestConfig = await this.axiosConfig()
    const response: ApiResponse<any> = await this.apisauce.patch(this.generatePath(id, additionalPath), serializedPayload, axiosConfig)

    return await this.processResult(response)
  }

  async find(id: string, additionalPath?: string, payload?: object) {
    const axiosConfig: AxiosRequestConfig = await this.axiosConfig()
    const response: ApiResponse<any> = await this.apisauce.get(this.generatePath(id, additionalPath), payload, axiosConfig)

    return await this.processResult(response)
  }

  async remove(id: string, payload: object = {}, additionalPath?: string) {
    const axiosConfig: AxiosRequestConfig = await this.axiosConfig()
    const response: ApiResponse<any> = await this.apisauce.delete(this.generatePath(id, additionalPath), payload, axiosConfig)

    return await this.processResult(response)
  }

  async all(params?: {}, additionalPath?: string) {
    const axiosConfig: AxiosRequestConfig = await this.axiosConfig()
    const response: ApiResponse<any> = await this.apisauce.get(this.generatePath(additionalPath), params, axiosConfig)

    return await this.processResult(response)
  }

  async export(params?: {}, additionalPath?: string) {
    const axiosConfig: AxiosRequestConfig = await this.axiosConfig()
    axiosConfig.responseType = 'blob'
    const fileExtension = additionalPath === 'sca_reports' ? 'xlsx' : 'csv'
    const fileName = additionalPath ? `${additionalPath}.${fileExtension}` : `export.${fileExtension}`

    const response: ApiResponse<any> = await this.apisauce.get(this.generatePath(additionalPath), params, axiosConfig)

    if (!response.ok) {
      const problem = this.generateError(response)
      if (problem) return Promise.reject(problem)
    }

    return {data: response.data, name: fileName}
  }

  async uploadImage(payload: Object) {
    const axiosConfig: AxiosRequestConfig = await this.axiosConfig()
    axiosConfig.headers['Content-Type'] = 'multipart/form-data'
    // axiosConfig.timeout = UPLOAD_TIMEOUT

    const data = new FormData()
    data.append('data[type]', this.type)

    Object.keys(payload).forEach(key => {
      data.append(`data[attributes][${snakeCase(key)}]`, payload[key])
    })

    const id = get(payload, "id", null)

    const response: ApiResponse<any> = id ?
      await this.apisauce.patch(this.generatePath(id), data, axiosConfig) :
      await this.apisauce.post(this.generatePath(id), data, axiosConfig)

    return await this.processResult(response)
  }
}
