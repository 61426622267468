/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import { Instance, SnapshotOut, types, flow } from "mobx-state-tree"

import {
  withGeneralError, withReset, withRootStore, withEnvironment
} from "models/extensions"

/**
 * Model description here for TypeScript hints.
 */
export const NpsStoreModel = types
  .model("NpsStore")
  .props({
    sca: types.optional(types.model({
      nps: types.optional(types.number, 0),
      descriptor: types.optional(types.string, ""),
      other: types.optional(types.string, ""),
    }), {}),
  })
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withReset)
  .extend(withEnvironment)
  .actions(self => ({
    setValue(object: string, property: string, value: any): void {
      self[object][property] = value
    },
    sendNpsSca: flow(function * (cuppingSessionId: string) {
      if (!cuppingSessionId) return

      try {
        const payload = {
          ...self.sca,
          cuppingSessionId
        }
        const res = yield self.environment.abTestApi.save(payload)
        if (res.kind === "ok") {
          self.reset()
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
  }))

type NpsStoreType = Instance<typeof NpsStoreModel>
export interface NpsStore extends NpsStoreType {}
type NpsStoreSnapshotType = SnapshotOut<typeof NpsStoreModel>
export interface NpsStoreSnapshot extends NpsStoreSnapshotType {}
