import { createStyles, makeStyles } from '@mui/styles'
import { colors } from 'assets'

export const useStyles = makeStyles(() =>
  createStyles({
    descriptorWrapper: {
      marginTop: 16
    },
    tasteWrapper: {
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #EBE8F2',
      borderRadius: 4,
      margin: '8px 0'
    },
    tasteForm: {
      marginLeft: 0,
      marginRight: 0,
      borderBottom: '1px solid #EBE8F2'
    },
    menuWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    tasteText: {
      '& span': {
        color: colors.primary.main,
        fontWeight: 700
      }
    }
  })
)
