/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-04-17 22:38:39
 */

import { Instance, SnapshotOut, types } from "mobx-state-tree"

import {
  withRootStore,
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
} from "models/extensions"

import { sampleProps } from './sample.props'
import { withSampleActions } from './sample-action'

/**
 * Model description here for TypeScript hints.
 */
export const MasterSampleModel = types
  .model("MasterSample")
  .props({
    id: types.maybeNull(types.union(types.string, types.number)),
    ...sampleProps
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withSampleActions)

type MasterSampleType = Instance<typeof MasterSampleModel>
export interface MasterSample extends MasterSampleType {}
type MasterSampleSnapshotType = SnapshotOut<typeof MasterSampleModel>
export interface MasterSampleSnapshot extends MasterSampleSnapshotType {}
