/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-14 23:27:39
 * @modify date 2022-04-13 17:48:38
 */

import React from 'react';
import { CssBaseline } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { RootStore, RootStoreProvider, setupRootStore } from 'models/root-store'

import { Logo } from 'assets/images'
import { dashboardTheme } from 'assets'
import { AuthenticationNavigation, DashboardNavigation, PublicNavigation } from 'navigation'
import { InfoPage, GlobalNotification, Loading, CloseNotification } from 'components'
import { useAppVersion } from "hooks/app-version"

const App: React.FC = () => {
  const [rootStore, setRootStore] = React.useState<RootStore | undefined>(undefined)

  useAppVersion(rootStore)

  React.useEffect(() => {
    (async () => setupRootStore().then(setRootStore))()
  }, [])

  if (!rootStore) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={dashboardTheme}>
          <CssBaseline />
          <InfoPage icon={Logo} />
        </ThemeProvider>
      </StyledEngineProvider>
    )
  }

  return (
    <RootStoreProvider value={rootStore}>
      <StyledEngineProvider injectFirst>
        <SnackbarProvider
          action={key => <CloseNotification snackbarKey={key} />}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          autoHideDuration={5000}
          hideIconVariant
        >
          <AuthenticationNavigation />

          <ThemeProvider theme={dashboardTheme}>
            <CssBaseline />
            <DashboardNavigation />
          </ThemeProvider>

          <PublicNavigation />

          <GlobalNotification />
          <Loading />
        </SnackbarProvider>
      </StyledEngineProvider>
    </RootStoreProvider>
  )
}

export default App
