import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { withSetValue } from "models/extensions"

/**
 * Model description here for TypeScript hints.
 */
export const GuideStoreModel = types
  .model("GuideStore")
  .props({
    guideCupper: types.optional(types.boolean, false),
    guideLicense: types.optional(types.boolean, false),
    guideCupping: types.optional(types.boolean, false),
    guideSample: types.optional(types.boolean, false),
    guideScore: types.optional(types.boolean, false),
  })
  .extend(withSetValue)
  .actions(self => ({
    finishGuide(data) {
      switch (data.guideId) {
        case 51138:
        case 52786:
        case 53559:
        case 53576:
          self.guideLicense = false
          break
        case 51139:
        case 52787:
        case 53571:
        case 53575:
          self.guideCupper = false
          break
        case 51133:
        case 52788:
        case 53636:
        case 53642:
          self.guideCupping = false
          break
        case 51140:
        case 52789:
        case 53649:
        case 53651:
          self.guideSample = false
          break
        case 51141:
        case 52790:
        case 53647:
        case 53650:
          self.guideScore = false
          break
        default:
          break
      }
    },
  }))
  
/**
* Un-comment the following to omit model attributes from your snapshots (and from async storage).
* Useful for sensitive data like passwords, or transitive state like whether a modal is open.

* Note that you'll need to import `omit` from ramda, which is already included in the project!
*  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
*/

type GuideStoreType = Instance<typeof GuideStoreModel>
export interface GuideStore extends GuideStoreType {}
type GuideStoreSnapshotType = SnapshotOut<typeof GuideStoreModel>
export interface GuideStoreSnapshot extends GuideStoreSnapshotType {}
