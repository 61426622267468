import React from 'react'
import { has } from "lodash"
import { observer } from 'mobx-react-lite'
import {Trans, useTranslation} from "react-i18next"
import {Box, Button, Typography, Stack, FormHelperText} from "@mui/material"

import { useStores } from 'models'
import {DormantModal, FormInput, ModalWrapper, StackAlert} from 'components'
import {globalAction, validateEmail} from "utils"
import {Link} from "react-router-dom";

export const RemoveAccount: React.FC = observer(() => {
  const { t } = useTranslation()
  const {
    userStore: { isCompanyAdmin, removeMyAccount },
    modalStore: { removeAccount, setValue: setModalValue },
    notificationStore
  } = useStores()

  const [email, setEmail] = React.useState('')
  const [delegateEmail, setDelegateEmail] = React.useState('')
  const [errors, setErrors] = React.useState<any>({})
  const emailError = !validateEmail(email) && has(errors, 'email')

  const [openDormantModal, setOpenDormantModal] = React.useState(false)
  const [openDormant, setOpenDormant] = React.useState(false)

  const confirmRemove = () => {
    setErrors({})

    const validEmail = validateEmail(email)
    if (!validEmail) {
      setErrors({
        "email": [{ message: t('profile.error.emailIsInvalid') }]
      })

      return
    }

    globalAction(notificationStore, {
      complexAction: true,
      action: async () => {
        try {
          await removeMyAccount(email, delegateEmail)
          setModalValue('removeAccount', false)
        } catch (e) {
          setErrors({
            "base": [{ message: t('profile.error.unsuccessfullyRemoveAccount') }]
          })
        }
      },
    })
  }

  const onCloseDormantModal = (status: boolean) => {
    setModalValue('removeAccount', true)
    setOpenDormant(status)
    setOpenDormantModal(status)
  }

  return (
    <>
      <ModalWrapper
        maxWidth='xs'
        open={removeAccount}
      >
        <Box mb={3}>
          <Typography variant='h5' sx={{mb: 0.5}}>
            {t('profile.removeAccountConfirmTitle')}
          </Typography>
          <Typography variant='body2' mb={2}>
            {t('profile.removeAccountConfirmDescription')}
          </Typography>

          {has(errors, 'base') && <StackAlert alerts={errors['base']} sx={{mb: 2}} />}

          <FormInput
            label={t('profile.yourEmail')}
            formControlProps={{margin: 'none'}}
            textFieldProps={{
              placeholder: 'example@email.com',
              value: email,
              onChange: (event) => setEmail(event.target.value),
              error: emailError
            }}
          />
          <FormHelperText sx={{mx: 0}}>{t('profile.emailConfirmDescription')}</FormHelperText>

          {isCompanyAdmin && (
            <Box mt={2}>
              <Typography variant='body2' mb={2}>
                {t('profile.removeAdminAccountDescription')}
              </Typography>

              <StackAlert alerts={[{message: t('profile.removeAdminAlertDescription')}]} />

              <FormInput
                label={t('profile.teamEmail')}
                textFieldProps={{
                  placeholder: 'example@email.com',
                  value: delegateEmail,
                  onChange: (event) => setDelegateEmail(event.target.value),
                }}
              />
            </Box>
          )}
        </Box>
        <Stack direction="row" spacing={2} justifyContent="center" mb={3}>
          <Button variant='contained' onClick={confirmRemove} disabled={emailError}>
            {t('common.confirm')}
          </Button>
          <Button variant='outlined' onClick={() => setModalValue('removeAccount', false)}>
            {t('common.cancel')}
          </Button>
        </Stack>

        <Typography variant='body2'>
          <Trans i18nKey="subscription.dormantAccountLink">
            Click
            <Link
              to="#"
              onClick={() => {
                setModalValue('removeAccount', false)
                setOpenDormant(true)
                setOpenDormantModal(true)
              }}
            >
              <Button className='btn-text'>here</Button>
            </Link>
            to access reduced rates to keep your data in dormant account mode
          </Trans>
        </Typography>
      </ModalWrapper>

      <DormantModal
        rootStore={useStores()}
        open={openDormantModal}
        setOpen={onCloseDormantModal}
        openDormant={openDormant}
        setOpenDormant={onCloseDormantModal}
        isSubscription
      />
    </>
  )
})
