import React from 'react'
import moment from "moment"
import clsx from "clsx"
import { useTranslation } from "react-i18next"
import {camelCase, isUndefined, toNumber, cloneDeep, merge, startCase} from "lodash"
import {Box, Grid, MenuItem, Select, TextField, Typography} from '@mui/material'
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'

import { colors } from 'constants/form'

import { useStyles } from './green-grading-review.styles'
import { GreenGradingReviewProps} from "./green-grading-review.props"

const PREFIX = 'greenGrading'

export const GreenGradingReview = ({ greenGrading, isEdit, setGreenGrading, setDefect }: GreenGradingReviewProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [temporaryGreenGrading, setTemporaryGreenGrading] = React.useState(
    merge(cloneDeep(greenGrading), {
      total: greenGrading.totalDefects,
    })
  )

  const { author, total, color, weight, smell, createdAt, defects } = temporaryGreenGrading

  const setValue = (key, value) => {
    if (setGreenGrading) setGreenGrading(key, value)
    setTemporaryGreenGrading({
      ...temporaryGreenGrading,
      [key]: value
    })
  }

  const setDefectValue = (id, value) => {
    const newDefects = defects.map(defect => {
      if (defect.id === id) {
        return {
          ...defect,
          count: value
        }
      }
      return defect
    })

    if (setDefect) setDefect(id, value)
    setTemporaryGreenGrading({
      ...temporaryGreenGrading,
      defects: newDefects
    })
  }

  const renderEditItem = (label, score) => {
    if (isUndefined(score)) return

    switch (label) {
      case 'color':
        return (
          <Select
            value={score}
            onChange={(e) => setValue(label, e.target.value)}
            size='small'
            inputProps={{ className: classes.input }}
            sx={{width: 200}}
          >
            {colors.map(c =>
              <MenuItem value={c} key={c}>
                {t(`options.color.${camelCase(c)}`)}
              </MenuItem>
            )}
          </Select>
        )
      case 'weight':
        return (
          <CurrencyTextField
            size='small'
            type='text'
            inputMode='numeric'
            variant='outlined'
            textAlign='right'
            currencySymbol=''
            value={score}
            outputFormat="string"
            decimalCharacter="."
            digitGroupSeparator=","
            onChange={(event, value)=> setValue(label, value)}
            style={{width: '200px'}}
          />
        )
      case 'smell':
        return (
          <TextField
            size='small'
            value={score}
            onChange={(e) => setValue(label, e.target.value)}
            inputProps={{ className: classes.input }}
            sx={{width: 200}}
          />
        )
      default:
        return (
          <Typography variant='body2' fontWeight={500} color="primary">{score}</Typography>
        )
    }
  }

  const renderItem = (label, value) => {
    return (
      <>
        <Grid item xs={12} md={3}>
          <Typography variant='body2' fontWeight={500}>{t(`${PREFIX}.${label}`)}</Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          {isEdit ?
            renderEditItem(label, value) :
            <Typography variant='body2' fontWeight={500} color="primary">
              {value && label === 'color' ? t(`options.color.${camelCase(value)}`) : value}
            </Typography>
          }
        </Grid>
      </>
    )
  }

  const renderEditDefect = (id, score) => {
    if (isUndefined(score)) return

    return (
      <TextField
        size='small'
        type='tel'
        inputMode='numeric'
        value={score}
        onChange={(e) => setDefectValue(id,toNumber(e.target.value) || 0)}
        sx={{width: 75}}
        inputProps={{min: 0, pattern: '[0-9]*', className: classes.input}}
      />
    )
  }

  return (
    <>
      <Grid container spacing={2}>
        {renderItem('greenGradingType', t(`${PREFIX}.types.scaSpecialty`))}
        {renderItem('author', author)}
        {Object.keys(total).map((key) => renderItem(`total${startCase(key)}Defects`, total[key]))}
        {renderItem('color', color)}
        {renderItem('weight', weight)}
        {renderItem('smell', smell)}
        {renderItem('createdAt', moment(createdAt).format('DD-MM-YYYY HH:mm'))}
      </Grid>

      {defects.length > 0 && (
        <Box sx={{mt: 5}}>
        <Typography variant='h6' sx={{mb: 2}}>{t('report.defectsInformation')}</Typography>
        <Grid container>
          {defects.map((defect, index) => {
            if (!isEdit && defect.count === 0) return null

            return (
              <Grid item xs={12} md={3} key={index}>
                <Box className={classes.wrapper}>
                  <Grid container spacing={2} sx={{alignItems: 'center'}}>
                    <Grid item xs={12} md={12} className={classes.score}>
                      <Typography
                        variant='subtitle2'
                        className={clsx(classes.labelColor)}
                      >
                        {defect.defectType}
                      </Typography>
                      {isEdit ?
                        renderEditDefect(defect.id, defect.count) :
                        <Typography
                          variant='subtitle2'
                          className={clsx(classes.scoreDescriptorColor)}
                        >
                          {defect.count}
                        </Typography>
                      }
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
      )}
    </>
  )
}
