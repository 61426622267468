/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-28 17:53:53
 * @modify date 2022-03-28 17:53:53
 */

import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { centered, colors, verticalCentered } from 'assets'
 
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropBoxImage: {
      ...centered,
      flexDirection: 'column',
      padding: theme.spacing(3),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      border: `1px dashed ${colors.primary.main}`,
      backgroundColor: colors.cultured
    },
    filePreview: {
      ...verticalCentered,
      padding: theme.spacing(2),
      backgroundColor: colors.cultured,
      borderRadius: 5,
      marginBottom: theme.spacing(1),
      '&.dragging': {
        border: `1px solid ${colors.primary.main}`,
        top: 'auto !important',
        left: 'auto !important',
        '& svg': {
          fill: colors.primary.main
        } 
      },
      '&.error': {
        border: `1px solid ${colors.error.primary}`
      }
    },
    imageWrapper: {
      ...verticalCentered,
      flex: 1,
    },
    fileImage: {
      maxWidth: '90%',
      maxHeight: 60,
      borderRadius: 5
    }
  })
)
