/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-20 22:12:20
 * @modify date 2022-04-20 22:12:20
 */

import { types } from "mobx-state-tree"
import { CupperModel } from "models/cupper/cupper"

export const cupperProps = {
  cuppers: types.array(CupperModel),
  email: types.optional(types.string, ""),
  message: types.optional(types.string, ""),
  search: types.optional(types.string, ""),
  page: types.optional(types.number, 1),
  totalPage: types.optional(types.number, 0),
  nextUrl: types.maybe(types.string),
  records: types.optional(types.number, 0),

  // Summary
  members: types.optional(types.number, 0),
  admins: types.optional(types.number, 0),
  pendingInvitations: types.optional(types.number, 0),
  seatsAvailable: types.optional(types.number, 0)
}