import {types} from "mobx-state-tree";

export const cuppingResultProps = {
  id: types.maybeNull(types.string),
  cuppingSessionName: types.maybeNull(types.string),
  cuppedBy: types.maybeNull(types.string),
  cuppingSessionOwner: types.maybeNull(types.string),
  cuppingSessionStartDate: types.maybeNull(types.string),
  sampleProtocol: types.maybeNull(types.string),
  avgDefectScore: types.maybeNull(types.number),
  avgScore: types.maybeNull(types.string),
  uniqueToken: types.maybeNull(types.string),
}
