/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-22 02:05:32
 * @modify date 2022-04-22 02:05:32
 */

import { types } from 'mobx-state-tree'

export const PaymentHistory = types.model({
  id: types.string,
  date: types.string,
  card: types.string,
  amount: types.number,
  name: types.string,
  currency: types.string,
  billingInterval: types.string,
  trialPeriodDays: types.maybeNull(types.number),
  status: types.string
})