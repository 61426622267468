/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-15 20:28:17
 * @modify date 2022-02-15 20:28:17
 */

import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors, horizontalCentered } from 'assets'
 
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepWrapper: {
      maxWidth: 600,
      marginBottom: theme.spacing(4)
    },
    stepLabel: {
      '& .MuiStepLabel-label': {
        '&.Mui-completed, &.Mui-active': {
          color: colors.primary.main
        }
      }
    },
    planWrapper: {
      textAlign: 'center',
      padding: theme.spacing(4),
      position: 'relative',
      border: `2px solid  ${colors.primary.main}`,
      borderRadius: 18,
      transition: '.5s',
      minHeight: 775,
      maxWidth: 368,
      marginLeft: 'auto',
      marginRight: 'auto',
      '&:hover': {
        boxShadow: '4.4474px 8.8948px 44.474px #ffa552'
      },
      '&.enterprise': {
        minHeight: 875
      }
    },
    image: {
      width: 150,
      position: 'absolute',
      top: -100,
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    priceWrapper: {
      ...horizontalCentered,
      flexWrap: 'wrap'
    },
    feature: {
      display: 'flex',
      marginBottom: theme.spacing(1)
    },
    ribbon: {
      backgroundColor: colors.secondary.main,
      padding: theme.spacing(0.5),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(1),
      width: 'fit-content',
      borderBottomRightRadius: 9,
      position: 'absolute',
      left: -10,
      top: 50,
      '&::before': {
        content: "''",
        bottom: '-0.4em',
        position: 'absolute',
        display: 'block',
        borderStyle: 'solid',
        borderColor: `${colors.secondary.main} transparent transparent transparent`,
        zIndex: '-1',
        left: 1,
        borderWidth: '0.5em 0 0 0.5em'
      }
    },
    cardWrapper: {
      padding: theme.spacing(4),
      maxWidth: 360,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    cardCheckbox: {
      marginBottom: theme.spacing(1),
      '& span': {
        fontSize: 12
      }
    }
  })
)