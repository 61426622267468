import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Model description here for TypeScript hints.
 */
export const GreenGradingTypeModel = types
  .model("GreenGradingType")
  .props({
    id: types.identifier,
    name: types.string,
    sampleSize: types.number,
    species: types.string,
    unit: types.string,
  })

/**
 * Un-comment the following to omit model attributes from your snapshots (and from async storage).
 * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

 * Note that you'll need to import `omit` from ramda, which is already included in the project!
 *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
 */

type GreenGradingTypeType = Instance<typeof GreenGradingTypeModel>
export interface GreenGradingType extends GreenGradingTypeType {}
type GreenGradingTypeSnapshotType = SnapshotOut<typeof GreenGradingTypeModel>
export interface GreenGradingTypeSnapshot extends GreenGradingTypeSnapshotType {}
