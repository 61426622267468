import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {camelCase, isEmpty} from "lodash";
import {observer} from "mobx-react-lite";
import moment from "moment";
import {Radar} from "react-chartjs-2";
import ReactWordcloud from 'react-wordcloud'
import {useParams} from "react-router-dom";
import {BarChart, ExpandLess, ExpandMore} from "@mui/icons-material";
import {Box, Collapse, Container, Grid, Stack, Tab, Tabs, Typography} from "@mui/material";

import {useStores} from "models";
import {HeaderTitle} from "components";
import {findRoute} from "constants/routes";
import {countryName, flavorWheel, globalAction} from "utils";
import {maximumScore, minimumScore} from "constants/form";
import {
  emptyCloudWords,
  emptyCupping,
  emptyFlavorWheel,
  emptySpiderWeb,
  IconBean,
  IconCountryOfOrigin,
  IconYear,
  IconSampleProccess,
  IconSampleType,
  IconStartCupping,
  IconDoubleBeans,
  IconShippingFast
} from "assets/images";

import {useStyles} from "./sample-details.styles";
import {CuppingResults, GreenGradings, Activity, Shipments} from "./components"

export const SampleDetails = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const params = useParams()

  const {
    companyStore: { base64Logo, isEnterprisePlus },
    masterSampleStore: {
      masterSampleDetail: {
        name, countryCode, sampleType, processName, cropYear, receivedOn, species, grade, bagWeight, volume,
        supplierName, varietalsTags, protocolForm, moisture, temperature, producerName, salesContractReference,
        receivedWeight, waterActivity, customer, purchaseContractReference, numberOfBag, mass,
      },
      getFlavorWheelGroups, getAverageScoresForGraph, getCloudWords,
      getMasterSample
    },
    notificationStore
  } = useStores()

  const [openSample, setOpenSample] = useState(true)
  const [tab, setTab] = useState('cupping')

  const homeLink = findRoute('home')

  const sampleInformation = [
    {
      icon: <IconBean className={classes.icon} style={{ transform: 'scale(0.8)'}} />,
      label: t('sample.sampleName'),
      value: name
    },
    {
      icon: <IconCountryOfOrigin className={classes.icon} />,
      label: t('sample.country'),
      value: countryName(countryCode)
    },
    {
      icon: <IconSampleType className={classes.icon} />,
      label: t('sample.sampleType'),
      value: sampleType
    },
    {
      icon: <IconSampleProccess className={classes.icon} />,
      label: t('sample.coffeeProcessing'),
      value: processName
    },
    {
      icon: <IconYear className={classes.icon} />,
      label: t('sample.cropYear'),
      value: cropYear
    }
  ]

  const sampleDetails = [
    {
      label: t('sample.receivedDate'),
      value: moment(receivedOn).format('DD/MM/YYYY')
    },
    {
      label: t('sample.species'),
      value: species ? t(`options.species.${camelCase(species)}`) : ''
    },
    {
      label: t('sample.grade'),
      value: grade ? t(`options.grades.${grade}`) : ''
    },
    {
      label: t('sample.bagWeight'),
      value: bagWeight ? `${bagWeight} kg` : ''
    },
    {
      label: t('sample.volume'),
      value: volume ? `${volume} mL` : ''
    },
    {
      label: t('sample.supplier'),
      value: supplierName
    },
    {
      label: t('sample.varietals'),
      value: varietalsTags
    },
    {
      label: t('sample.preferredCuppingProtocol'),
      value: protocolForm
    },
    {
      label: t('sample.moisture'),
      value: moisture ? `${moisture}%` : ''
    },
    {
      label: t('sample.temperature'),
      value: temperature
    },
    {
      label: t('sample.producer'),
      value: producerName
    },
    {
      label: t('sample.salesContractReference'),
      value: salesContractReference
    },
    {
      label: t('sample.receivedWeight'),
      value: receivedWeight
    },
    {
      label: t('sample.waterActivity'),
      value: waterActivity
    },
    {},
    {
      label: t('sample.customer'),
      value: customer
    },
    {
      label: t('sample.purchaseContractReference'),
      value: purchaseContractReference
    },
    {
      label: t('sample.numberOfBags'),
      value: numberOfBag
    },
    {
      label: t('sample.mass'),
      value: mass ? `${mass} gr` : ''
    },
  ]

  const renderTabContent = () => {
    switch (tab) {
      case 'cupping':
        return <CuppingResults />
      case 'green-grading':
        return <GreenGradings />
      case 'shipment':
        return <Shipments />
      case 'activity':
        return <Activity />
      default:
        return (
          <Box py={4} textAlign="center">
            <img src={emptyCupping} />
          </Box>
        )
    }
  }

  useEffect(() => {
    flavorWheel(getFlavorWheelGroups, isEnterprisePlus && base64Logo)
  }, [getFlavorWheelGroups])

  useEffect(() => {
    globalAction(notificationStore, {
      complexAction: true,
      action: async () => {
        await getMasterSample(params.id, true)
      },
    })
  }, [params.id])

  return (
    <Box>
      <HeaderTitle
        backUrl={homeLink}
        title={t('menu.sampleDetails')}
        breadcrumb={t('common.backTo', { menu: t('menu.home')})}
      />

      <Container>
        <Box className={classes.wrapper} sx={{ px: 3, py: 2.5 }}>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={4}>
              {isEmpty(getFlavorWheelGroups) ?
                <img src={emptyFlavorWheel} height="100%" /> :
                <div id="tastify-wheel" style={{width: '100%', height: '100%'}}></div>
              }
            </Grid>

            <Grid item xs={4}>
              {isEmpty(getAverageScoresForGraph.datasets[0].data) ?
                <img src={emptySpiderWeb} height="100%" /> :
                <Radar
                  data={getAverageScoresForGraph}
                  options={{
                    plugins: {
                      legend: {
                        display: false
                      }
                    },
                    scales: {
                      r: {
                        min: minimumScore,
                        max: maximumScore,
                        pointLabels: {
                          font: {
                            size: 16,
                          }
                        }
                      }
                    }
                  }}
                />
              }
            </Grid>

            <Grid item xs={4}>
              {isEmpty(getCloudWords) ?
                <img src={emptyCloudWords} height="100%" width="291px" /> :
                <ReactWordcloud
                  words={getCloudWords}
                  options={{
                    rotations: 1,
                    rotationAngles: [0, 0],
                    fontSizes: [20, 30],
                    enableTooltip: false
                  }}
                />
              }
            </Grid>
          </Grid>

          <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mb: 3 }}>
            {sampleInformation.map((item, index) => (
              <Box key={index} className={classes.sampleInfo}>
                {item.icon}
                <Box>
                  <Typography variant="subtitle1">{item.label}:</Typography>
                  <Typography variant="body2">{item.value}</Typography>
                </Box>
              </Box>
            ))}
          </Stack>

          <Box className={classes.sampleDetail}>
            <Box
              className={classes.collapseButton}
              onClick={() => setOpenSample(!openSample)}
            >
              <Typography variant="h6">{t('sample.viewDetailedInformation')}</Typography>
              {openSample ?
                <ExpandLess color='primary' fontSize='large' /> :
                <ExpandMore color='primary' fontSize='large' />
              }
            </Box>

            <Collapse in={openSample} timeout="auto" unmountOnExit>
              <Grid container spacing={2} columns={10} mt={2}>
                {sampleDetails.map((item, index) => (
                  <Grid item md={2} key={index}>
                    {item.label && <Typography variant="subtitle1">{item.label}:</Typography>}
                    {item.value && <Typography variant="body2">{item.value}</Typography>}
                  </Grid>
                ))}
              </Grid>
            </Collapse>
          </Box>
        </Box>

        <Box className={classes.wrapper}>
          <Tabs
            value={tab}
            onChange={(e, v) => setTab(v)}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            className={classes.tabs}
          >
            <Tab value="cupping" label="Cupping Result" icon={<IconStartCupping />} iconPosition="start" />
            <Tab value="green-grading" label="Green Grading" icon={<IconDoubleBeans />} iconPosition="start" />
            <Tab value="shipment" label="Shipment" icon={<IconShippingFast />} iconPosition="start" />
            <Tab value="activity" label="Activity" icon={<BarChart />} iconPosition="start" />
          </Tabs>

          {renderTabContent()}
        </Box>
      </Container>
    </Box>
  )
})
