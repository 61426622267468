import { samplePreProcessor } from "models/sample";
import { fulfillmentTypes } from "constants/form"

export const sampleShipmentPreProcessor = (sn: any) => {
  return {
    ...sn,
    id: sn.sample ? sn.id : '',
    sampleType: sn.sampleType || '',
    sampleName: sn.sampleName || sn.name,
    sampleGrade: sn.sampleGrade || sn.grade,
    sampleLocation: sn.sampleLocation || '',
    sampleFulfillmentType: sn.sampleFulfillmentType || sn.fulfillmentType || fulfillmentTypes[0],
    isApprovalNeeded: sn.isApprovalNeeded || false,
    sampleWeight: sn.sampleWeight || sn.receivedWeight,
    isRoasted: sn.isRoasted || false,
    thirdPartyEmail: sn.thirdPartyEmail || '',
    sampleWarehouseReference: sn.sampleWarehouseReference || sn.warehouseReference,
    sampleId: sn.sampleId || sn.id,
    sample: sn.sample ? samplePreProcessor(sn.sample) : samplePreProcessor(sn)
  }
}
