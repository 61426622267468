export const shipmentOrderFormPreProcessor = (sn: any) => {
  return {
    ...sn,
    id: sn.id || '',
    customer: sn.customer || '',
    recipient: sn.recipient || '',
    streetAddress: sn.streetAddress || '',
    streetAddress2: sn.streetAddress2 || '',
    city: sn.city || '',
    state: sn.state || '',
    country: sn.country || '',
    zipCode: sn.zipCode || '',
    isUrgentDelivery: sn.isUrgentDelivery || false,
    requestedByEmail: sn.requestedByEmail || '',
    recipientEmails: sn.recipientEmails || '',
    traderEmails: sn.traderEmails || '',
    qualityControlEmails: sn.qualityControlEmails || '',
    approvalEmails: sn.approvalEmails || '',
  }
}
