/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-06 20:03:48
 * @modify date 2022-02-06 20:03:48
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors, verticalCentered } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonWrapper: {
      position: 'fixed',
      padding: theme.spacing(2),
      paddingTop: 0,
      bottom: 0,
      zIndex: 99,
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '10px 10px 0 0',
      borderTop: `1px solid ${colors.border.primary}`,
      [theme.breakpoints.up('md')]: {
        textAlign: 'right',
        position: 'relative',
        display: 'block',
        backgroundColor: 'transparent',
        borderTop: 'unset'
      }
    },
    buttonRounded: {
      '&:hover': {
        '& svg': {
          fill: theme.palette.primary.dark
        }
      }
    },
    iconRounded: {
      width: 20,
      height: 20,
      backgroundColor: 'white',
      '& svg': {
        height: 16,
        fill: theme.palette.primary.main,
      }
    },
    gridWrapper: {
      borderTop: `1px solid ${colors.border.primary}`,
      [theme.breakpoints.up('md')]: {
        border: 'none',
      }
    },
    gridHeader: {
      fontWeight: 500,
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      }
    },
    headerItem: {
      overflow: 'hidden',
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    listItem: {
      backgroundColor: 'white',
      [theme.breakpoints.up('md')]: {
        backgroundColor: 'transparent',
      }
    },
    filterWrapper: {
      padding: theme.spacing(4)
    },
    formWrapper: {
      border: `1px solid ${colors.border.primary}`,
      borderRadius: 5
    },
    collapseFilter: {
      ...verticalCentered,
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2.5)
    },
    formRow: {
        marginBottom: theme.spacing(2),
        alignItems: 'end'
      }
  })
)
