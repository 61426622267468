import {flatMapDeep} from "lodash"

const getGreenGrading = (greenGrading) => {
  if(!greenGrading.defectTypeChildren || !greenGrading.defectTypeChildren.length) return greenGrading

  return [greenGrading, flatMapDeep(greenGrading.defectTypeChildren, getGreenGrading)]
}

export const flattenGreenGrading = (greenGradings) => {
  return flatMapDeep(greenGradings, getGreenGrading)
}
