/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-04-18 00:10:19
 */

import { Instance, SnapshotOut, types } from "mobx-state-tree"

import {
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"
import { CuppingSessionModel } from 'models/cupping-session'
import { withCuppingSessionActions } from './cupping-session-action'

/**
 * Model description here for TypeScript hints.
 */
export const CuppingSessionStoreModel = types
  .model("CuppingSessionStore")
  .props({
    cuppingSessions: types.array(CuppingSessionModel),
    selectedCuppingSession: types.safeReference(CuppingSessionModel),
    filter: types.optional(types.string, ""),
    page: types.optional(types.number, 1),
    totalPage: types.optional(types.number, 0),
    guestUrlToken: types.optional(types.string, ""),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withCuppingSessionActions)

type CuppingSessionStoreType = Instance<typeof CuppingSessionStoreModel>
export interface CuppingSessionStore extends CuppingSessionStoreType {}
type CuppingSessionStoreSnapshotType = SnapshotOut<typeof CuppingSessionStoreModel>
export interface CuppingSessionStoreSnapshot extends CuppingSessionStoreSnapshotType {}
