
export const globalAction = async (notificationStore, options: any = {}) => {
  const { setNotification, setLoading } = notificationStore

  try {
    setLoading(true)
    if (options.complexAction) {
      await options.action()
      return
    }

    const res = await options.action()

    if (res) res.base?.map(message => setNotification(message))

    if (options.afterAction) options.afterAction()
  } catch (e: any) {
    if (options.onError) {
      options.onError()
    }

    if (!e.errors) return

    if (options.setErrors) {
      options.setErrors(e.errors)
      return
    }

    e.errors.base?.map(message => setNotification(message))
  } finally {
    setLoading(false)
  }
}
