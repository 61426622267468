/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-22 03:09:05
 * @modify date 2022-04-22 03:09:05
 */

import moment from 'moment'
import {
  INDIVIDUAL_PLAN_ID,
  ENTERPRISE_PLAN_ID,
  ENTERPRISE_PLUS_PLAN_ID,
  TRIAL_STATUS_NAME,
  CANCEL_STATUS_NAME,
  UNPAID_STATUS_NAME,
  PAST_DUE_STATUS_NAME,
  DEFAULT_ADMIN,
  DORMANT_PLAN_ID,
  BETA_PLAN_ID
} from 'config/env'
import {isEventModule, isScaModule} from "config/setting"

export const withCompanyViews = (self: any) => ({
  views: {
    get canInviteCuppers() {
      return self.subscriptionPlanId === ENTERPRISE_PLUS_PLAN_ID ||
        self.subscriptionPlanId === ENTERPRISE_PLAN_ID ||
        self.subscriptionPlanId === BETA_PLAN_ID ||
        self.initialPlanIdSelection === ENTERPRISE_PLUS_PLAN_ID ||
        self.initialPlanIdSelection === ENTERPRISE_PLAN_ID ||
        self.initialPlanIdSelection === BETA_PLAN_ID
    },
    get canInviteGuestCuppers() {
      return self.subscriptionPlanId === ENTERPRISE_PLUS_PLAN_ID ||
        self.subscriptionPlanId === BETA_PLAN_ID ||
        self.initialPlanIdSelection === ENTERPRISE_PLUS_PLAN_ID ||
        self.initialPlanIdSelection === BETA_PLAN_ID ||
        self.hasGuestUsers
    },
    get maximumGuestInvitation() {
      return self.licensesNumber + self.freeGuestsNumber
    },
    get isTrial() {
      return self.subscriptionStatus === TRIAL_STATUS_NAME
    },
    get isTrialExpired() {
      return self.subscriptionStatus === TRIAL_STATUS_NAME && moment().diff(moment(self.subscriptionTrialEndAt)) > 0
    },
    get isCustomer() {
      return !!self.subscriptionCustomerId && !!self.subscriptionId
    },
    get trialDaysLeft() {
      return moment(self.subscriptionTrialEndAt || moment().add(30, 'days')).endOf('day').fromNow(true)
    },
    get cancelSubscription() {
      return self.subscriptionStatus === CANCEL_STATUS_NAME
    },
    get unpaidSubscription() {
      const unpaidStatus = [CANCEL_STATUS_NAME, UNPAID_STATUS_NAME, PAST_DUE_STATUS_NAME]
      return unpaidStatus.includes(self.subscriptionStatus)
    },
    get isDormantAccount() {
      return self.subscriptionPlanId === DORMANT_PLAN_ID
    },
    get disableWebhook() {
      return self.subscriptionPlanId === INDIVIDUAL_PLAN_ID || self.initialPlanIdSelection === INDIVIDUAL_PLAN_ID
    },
    get disableCoe() {
      return self.subscriptionPlanId === INDIVIDUAL_PLAN_ID || self.initialPlanIdSelection === INDIVIDUAL_PLAN_ID
    },
    get isIndividual() {
      return self.subscriptionPlanId === INDIVIDUAL_PLAN_ID || self.initialPlanIdSelection === INDIVIDUAL_PLAN_ID
    },
    get isEnterprisePlus() {
      return self.subscriptionPlanId === ENTERPRISE_PLUS_PLAN_ID || self.initialPlanIdSelection === ENTERPRISE_PLUS_PLAN_ID
    },
    get isCompanyCompleted() {
      return self.companyType && self.name && self.name !== DEFAULT_ADMIN && self.countryCode
    },
    get isCompanyCompletedWithoutType() {
      return self.name && self.name !== DEFAULT_ADMIN && self.countryCode
    },
    get showCoe() {
      return isScaModule || isEventModule || !self.isIndividual
    },
    get showSca() {
      return isScaModule || isEventModule || !self.isIndividual
    },
    get showSucafinaTasting() {
      return isScaModule || isEventModule || self.isSustainableHarvest
    },
    get showCommercial() {
      return isScaModule || isEventModule || self.isSustainableHarvest
    },
    get showPublicCupping() {
      return isScaModule || isEventModule || self.isSustainableHarvest || self.publicCupping
    }
  }
})
