/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-22 03:05:03
 * @modify date 2022-04-22 03:05:03
 */

import { types } from 'mobx-state-tree'

export const companyProps = {
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  logo: types.maybeNull(types.union(types.string, types.number)),
  country: types.optional(types.string, ''),
  adminId: types.maybeNull(types.number),
  countryCode: types.maybeNull(types.string),
  licensesNumber: types.optional(types.number, 0),
  subscriptionCustomerId: types.maybeNull(types.string),
  subscriptionId: types.maybeNull(types.string),
  hasAccessToApi: types.maybeNull(types.boolean),
  hasGreenGradingAccess: types.maybeNull(types.boolean),
  subscriptionPlanId: types.maybeNull(types.string),
  subscriptionPlanName: types.maybeNull(types.string),
  subscriptionStatus: types.maybeNull(types.string),
  subscriptionNextPaymentAttemptAt: types.maybeNull(types.string),
  subscriptionTrialEndAt: types.maybeNull(types.string),
  isSustainableHarvest: types.optional(types.boolean, false),
  webhookUrlsNumber: types.optional(types.number, 0),
  initialPlanIdSelection: types.maybeNull(types.string),
  variableNumberOfLicenses: types.optional(types.boolean, false),
  hasCuppingSessionsProtocolChoice: types.optional(types.boolean, false),
  freeLicensesNumber: types.optional(types.number, 0),
  isKeurig: types.maybeNull(types.boolean),
  hasGuestUsers: types.maybeNull(types.boolean),
  freeGuestsNumber: types.optional(types.number, 0),
  scaFeature: types.optional(types.boolean, false),
  card: types.maybeNull(types.string),
  base64Logo: types.maybeNull(types.string),
  companyType: types.maybeNull(types.string),
  removeAt: types.maybeNull(types.string),
  publicCupping: types.maybeNull(types.boolean),
}
