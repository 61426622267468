import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors, horizontalCentered } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonWrapper: {
      ...horizontalCentered,
      marginTop: theme.spacing(4)
    },
    primary: {
      color: colors.primary.main
    }
  })
)