
import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {withRootStore, withSetValue} from "models/extensions"
import { greenGradingProps } from './green-grading-prop'
import { withGreenGradingViews } from './green-grading-view'
import { withGreenGradingActions } from './green-grading-action'

/**
 * Model description here for TypeScript hints.
 */
export const GreenGradingModel = types
  .model("GreenGrading")
  .props(greenGradingProps)
  .extend(withSetValue)
  .extend(withRootStore)
  .extend(withGreenGradingViews)
  .extend(withGreenGradingActions)

/**
* Un-comment the following to omit model attributes from your snapshots (and from async storage).
* Useful for sensitive data like passwords, or transitive state like whether a modal is open.

* Note that you'll need to import `omit` from ramda, which is already included in the project!
*  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
*/

type GreenGradingType = Instance<typeof GreenGradingModel>
export interface GreenGrading extends GreenGradingType {}
type GreenGradingSnapshotType = SnapshotOut<typeof GreenGradingModel>
export interface GreenGradingSnapshot extends GreenGradingSnapshotType {}
