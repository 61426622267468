/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-17 22:40:30
 * @modify date 2022-04-19 20:49:38
 */

import {assign, toNumber, values} from 'lodash'
import { applySnapshot, getSnapshot, flow } from "mobx-state-tree"
import { ApiResult } from "services/api"
import report from 'fixtures/report.json'
import {loadString} from "../../utils/storage";
import {CUPPED_STORAGE_KEY} from "../../config/env";

export const withSampleReportActions = (self: any) => ({
  actions: {
    setSampleReport(sample) {
      const words = sample.cloudWords.map(({weight: value, ...rest }) => ({value, ...rest}))

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        ...sample,
        sampleRecord: JSON.stringify(sample.sampleRecord),
        averageScoresForGraph: JSON.stringify(sample.averageScoresForGraph),
        cloudWords: JSON.stringify(words),
        flavorWheelGroups: JSON.stringify(sample.flavorWheelGroups),
        flavorWheelOnlyFragrance: JSON.stringify(sample.flavorWheelOnlyFragrance),
        flavorWheelOnlyFlavor: JSON.stringify(sample.flavorWheelOnlyFlavor),
      })
    },
    getSampleReport: flow(function * (token, isIndividual = false) {
      try {
        const { guideSample } = self.rootStore.guideStore
        if (guideSample) {
          self.setSampleReport(report)

          return
        }

        if (!token) return

        const { getGuestUrlToken } = self.rootStore.cuppingSessionStore
        const { isAuthenticated } = self.rootStore.userStore
        const guestToken = yield getGuestUrlToken(token)
        const additionalPath = isIndividual ? 'individual_report' : ''

        const payload = isAuthenticated ? {} : { guest_invitation_token: guestToken }
        const res: ApiResult = yield self.environment.sampleReportApi.find(token, additionalPath, payload)

        if (res.kind === "ok") self.setSampleReport(res.data)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    sendReportInvitation: flow(function * (token) {
      try {
        const payload: any = assign({id: token, to: self.emails, message: self.message})
        const res: ApiResult = yield self.environment.sampleReportApi.save(payload, {}, 'share')

        if (res.kind === "ok") {
          self.emails = ''
          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    getDefects: (id) => {
      const greenGrading = self.greenGradings.find((greenGrading) => greenGrading.id === toNumber(id))

      return greenGrading ? values(greenGrading.defects) : self.allDefects
    }
  }
})
