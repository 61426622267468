/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-16 22:28:00
 * @modify date 2022-02-16 22:28:00
 */

import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
   
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardWrapper: {
      padding: theme.spacing(4),
      maxWidth: 360
    }
  })
)