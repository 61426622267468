/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-22 19:42:42
 * @modify date 2022-04-22 19:42:42
 * @desc [description]
 */

import { applySnapshot, getSnapshot, flow } from "mobx-state-tree"
import {map, head, find, chain, isEmpty, pick, filter} from 'lodash'
import { ApiResult } from "services/api"
import { samplePreProcessor } from "models/sample"
import samples from 'fixtures/samples.json'
import scores from 'fixtures/scores.json'
import {loadString} from "utils/storage"
import {CUPPED_STORAGE_KEY} from "config/env"

export const withSampleActions = (self: any) => ({
  actions: {
    setSamples(newSamples: Array<any>, id = undefined) {
      self.addAllToPool(newSamples.map(sample => samplePreProcessor(sample)))

      const selectedSample = id ? find(newSamples, ['id', id]).id : head(newSamples)?.id

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samples: map(newSamples, 'id'),
        selectedSample
      })
    },
    getIndividualUrl: flow(function * (uniqueToken, sampleId) {
      const cuppedStorage = yield loadString(CUPPED_STORAGE_KEY)
      if (!cuppedStorage) return

      const temporaryCupped = JSON.parse(cuppedStorage)
      return temporaryCupped[uniqueToken + '_' + sampleId]
    }),
    getSamples: flow(function * (token: string) {
      try {
        if (!token) return

        const { guideSample, guideScore } = self.rootStore.guideStore
        if (guideSample || guideScore) {
          self.setSamples(guideSample ? samples.samples : scores.samples)

          return
        }

        const { cuppingSessions, setValue: setCuppingSessionValue } = self.rootStore.cuppingSessionStore
        const cuppingSession = find(cuppingSessions, ['uniqueToken', token])
        if (cuppingSession) setCuppingSessionValue('selectedCuppingSession', cuppingSession)

        const additionalPath: string = 'samples'
        const res: ApiResult = yield self.environment.cuppingSessionApi.find(token, additionalPath)

        if (res.kind === "ok") {
          self.setSamples(res.data.samples.map(sample => {
            return {
              ...sample,
              cuppingProtocol: cuppingSession.cuppingProtocol
            }
          }))
          self.selectedSample?.selectLastGreenGrading()
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    saveSample: flow(function * (id) {
      try {
        if (!id) return

        const sample = find(self.samples, ['id', id])
        if (!sample) return

        const payload: any = chain(sample)
                              .omit(['greenGradings','selectedGreenGrading', 'scores', 'selectedScore', 'cachedAverageScore', 'status', 'uniqueToken', 'images'])
                              .omitBy(isEmpty)
                              .assign({id: sample.id, receivedOn: sample.receivedOn, isScoreTarget: sample.isScoreTarget, masterId: sample.masterId})
                              .value()

        const res: ApiResult = yield self.environment.sampleApi.save(payload)

        if (res.kind === "ok") return res.data
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    updateCalibrationStatus: flow(function * (all: boolean = false) {
      try {
        let actions: Array<any> = []
        if (all) {
          self.samples.forEach(s => {
            const payload = pick(s, ['id', 'isScoreTarget'])
            actions.push(self.environment.sampleApi.save(payload))
          })
        } else {
          const payload = pick(self.selectedSample, ['id', 'isScoreTarget'])
          actions.push(self.environment.sampleApi.save(payload))
        }

        Promise.all(actions)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }

    }),
    addSample: flow(function * (numberOfSamples, cuppingSessionUniqueToken) {
      try {
        const payload = {
          numberOfSamples,
          cuppingSessionUniqueToken
        }

        const res: ApiResult = yield self.environment.sampleApi.save(payload, {})

        if (res.kind === "ok") {
          self.setSamples(res.data.samples, self.selectedSample.id)
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    removeSample: flow(function * (id: string, isFully = true) {
      try {
        let additionalPath
        if (isFully) additionalPath = '?destroy_fully=true'

        const res: ApiResult = yield self.environment.sampleApi.remove(id, {}, additionalPath)

        if (res.kind === "ok") {
          if (self.samples.length > 1) {
            const newSamples = filter(self.samples, sample => { return sample.id !== id })
            applySnapshot(self, {
              ...getSnapshot(self as object) as any,
              samples: newSamples,
              selectedSample: head(newSamples)
            })
          }

          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
  }
})
