import React from 'react'
import { CssBaseline } from "@mui/material"
import { ThemeProvider } from '@mui/material/styles'
import { Routes, Route } from 'react-router-dom'

import { authenticationTheme } from "assets"
import { publicRoutes } from "constants/routes"

export const PublicNavigation = () => {
  return (
    <ThemeProvider theme={authenticationTheme}>
      <CssBaseline />
      <Routes>
        {publicRoutes.map((route) =>
          <Route key={route.name} path={route.path} element={route.element} />
        )}
      </Routes>
    </ThemeProvider>
  )
}
