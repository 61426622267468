/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-17 22:40:30
 * @modify date 2022-04-17 22:40:30
 */

import {applySnapshot, getSnapshot, flow, detach} from "mobx-state-tree"
import {clone, cloneDeep, find, findIndex, filter, omit, toString, isEmpty, pick, isNull, last, toNumber} from 'lodash'
import { ApiResult } from "services/api"
import {preProcessorGreenGrading} from "../green-grading";

export const withSampleActions = (self: any) => ({
  actions: {
    // Sample
    cloneSample() {
      return clone(self)
    },
    setSample(sample) {
      applySnapshot(self, {
        ...(omit(sample, ['id', 'sampleId', 'images', 'selectedGreenGrading', 'greenGradings', 'selectedScore', 'scores', 'masterId'])),
        id: self.id,
        sampleId: self.sampleId,
        masterId: sample.id,
      })
    },
    resetSample(sample) {
      applySnapshot(self, {
        ...(omit(sample, ['images', 'selectedScore', 'scores'])),
      })
    },
    saveStatus: flow(function * () {
      try {
        const payload: any = pick(self, ['id', 'status'])
        const additionalPath: string = 'status'
        const res: ApiResult = yield self.environment.sampleApi.save(payload, {}, additionalPath)

        if (res.kind === "ok") return res.data
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),

    // Image
    setImages(images) {
      self.setValue('images', images)
    },
    getImages: flow(function * () {
      try {
        const res: ApiResult = yield self.environment.sampleImageApi.all({"sample_id": self.id})

        if (res.kind === "ok") self.setImages(res.data)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    saveImage: flow(function * (image, orderNumber) {
      try {
        let payload: any = {
          sampleId: self.id,
          orderNumber,
          image
        }

        if (image.id) {
          delete payload.image
          payload.id = image.id
          yield self.environment.sampleImageApi.save(payload)
          return
        }

        yield self.environment.sampleImageApi.uploadImage(payload)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    removeImage: flow(function * (image) {
      try {
        const id = image.id
        if (!id) return

        const res: ApiResult = yield self.environment.sampleImageApi.remove(id)

        if (res.kind === "ok") {
          const images = filter(self.images, image => image.id !== id)
          self.setImages(images)
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),

    // Score
    setScore(index: number) {
      if (isEmpty(self.scores)) return

      const formerIndex = findIndex(self.scores, ['id', self.selectedScore.id])
      if (self.selectedScore.id && formerIndex >= 0 && formerIndex !== index) self.scores[formerIndex] = cloneDeep(self.selectedScore)

      self.selectedScore = cloneDeep(self.scores[index])
    },
    updateScore() {
      const index = findIndex(self.scores, ['id', self.selectedScore.id])
      if (index < 0) return

      self.scores[index] = cloneDeep(self.selectedScore)
      self.scores[index].setValue('salt', self.selectedScore.salt)
      self.scores[index].setValue('bittersweet', self.selectedScore.bittersweet)
      self.scores[index].setValue('mouthfeel', self.selectedScore.mouthfeel)
      self.scores[index].setValue('cleanCupScore', self.selectedScore.cleanCupScore)
      self.scores[index].setValue('sweetnessScore', self.selectedScore.sweetnessScore)
    },

    // Green grading
    setGreenGradings(greenGradings) {
      detach(self.selectedGreenGrading)

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        greenGradings: greenGradings
      })
    },
    selectLastGreenGrading() {
      const lastGreenGrading = last(self.greenGradings)

      if (!lastGreenGrading) return

      self.setValue('selectedGreenGrading', cloneDeep(lastGreenGrading))
    },
    selectGreenGrading(id) {
      const greenGrading = self.greenGradings.find(item => toString(item.id) === toString(id))

      if (!greenGrading) return

      self.setValue('selectedGreenGrading', cloneDeep(greenGrading))
    },
    newGreenGrading(greenGradingTypeId) {
      self.selectedGreenGrading = preProcessorGreenGrading({ greenGradingTypeId: toNumber(greenGradingTypeId) })
      self.selectedGreenGrading?.setDefectInformation()
    },
    setGreenGrading(id) {
      const greenGrading = find(self.greenGradings, ['id', id])

      self.selectedGreenGrading = cloneDeep(greenGrading)
    },
    unsetGreenGrading() {
      if (!self.selectedGreenGrading) return

      const greenGradings = filter(self.greenGradings, item => item.id !== self.selectedGreenGrading.id)
      self.setGreenGradings([...greenGradings])
    },
    addGreenGrading: flow(function * (defects) {
      try {
        if (!self.selectedGreenGrading) return

        const isAdding = isNull(self.selectedGreenGrading.id)
        let omits = ['createdAt', 'author', 'defects']
        if (isAdding) omits.push('id')

        const payload: any = {
          ...omit(self.selectedGreenGrading, omits),
          sampleId: self.id,
          defects: defects.map(defect => ({ ...defect, count: toNumber(defect.count)})),
          size_14: toNumber(self.selectedGreenGrading.size14),
          size_15: toNumber(self.selectedGreenGrading.size15),
          size_16: toNumber(self.selectedGreenGrading.size16),
          size_17: toNumber(self.selectedGreenGrading.size17),
          size_18: toNumber(self.selectedGreenGrading.size18),
          size_19: toNumber(self.selectedGreenGrading.size19),
        }

        const res: ApiResult = yield self.environment.greenGradingApi.save(payload)

        if (res.kind === "ok") {
          const newGreenGrading = preProcessorGreenGrading(res.data)
          const greenGradings = self.greenGradings.map(item => {
            if (item.id === newGreenGrading.id) return newGreenGrading
            return item
          })

          if (isAdding) greenGradings.push(newGreenGrading)

          self.setGreenGradings(greenGradings)

          if (!isAdding) return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    removeGreenGrading: flow(function * () {
      try {
        if (!self.selectedGreenGrading) return

        const id = toString(self.selectedGreenGrading.id)
        const res: ApiResult = yield self.environment.greenGradingApi.remove(id)

        if (res.kind === "ok") {
          self.unsetGreenGrading()
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    })
  }
})
