import { types } from "mobx-state-tree"

export const defectProps = {
  id: types.string,
  category: types.maybeNull(types.string),
  defectType: types.maybeNull(types.string),
  defectTypeId: types.maybeNull(types.number),
  equivalent: types.maybeNull(types.number),
  min: types.maybeNull(types.number),
  max: types.maybeNull(types.number),
  localization: types.maybeNull(types.string),
  total: types.maybeNull(types.number),
}
