import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {cloneDeep} from "lodash";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Box } from "@mui/material";

import {useStores} from "models";
import {BasicTable} from "components";

const columnHelper = createColumnHelper<any>()

export const Activity = observer((props) => {
  const { t } = useTranslation()
  const params = useParams()
  const {
    activityStore: {getActivity, activities, page, totalPage, setValue: setActivityValue}
  } = useStores()

  const [loading, setLoading] = React.useState(false)
  const data = cloneDeep(activities)

  const columns = [
    columnHelper.accessor('eventType', {
      id: 'eventType',
      header: () => <Box textAlign="left">{t('sample.detail.eventType')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('doneBy', {
      id: 'doneBy',
      header: () => <Box textAlign="left">{t('sample.detail.doneBy')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('date', {
      id: 'date',
      header: () => <Box textAlign="left">{t('sample.detail.date')}</Box>,
      cell: info => info.renderValue(),
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const onChangePage = (_, page) => {
    setActivityValue("page",page)
  }

  useEffect(() => {

    (async () => {
      try {
        setLoading(true)
        await getActivity(params.id)
      } finally {
        setLoading(false)
      }
    })()

  }, [page])

  return (
    <Box px={2} py={2.5}>
      <BasicTable
        table={table}
        page={page}
        totalPage={totalPage}
        onChangePage={onChangePage}
        isFetching={loading}
      />
    </Box>
  )
})
