import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {colors, verticalCentered} from "assets";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(2),
      borderBottom: `1px solid ${colors.border.primary}`,
    },
    score: {
      ...verticalCentered,
      justifyContent: 'space-between'
    },
    labelColor: {
      marginRight: theme.spacing(1),
      '&.selected-score-target': {
        color: colors.secondary.main
      }
    },
    scoreDescriptorColor: {
      marginRight: theme.spacing(1),
      color: colors.primary.main,
      '&.selected-score-target': {
        color: colors.secondary.main
      }
    },
    input: {
      fontWeight: 600,
      color: colors.primary.main
    }
  })
)
