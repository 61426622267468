/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import { omit, some, filter } from 'lodash'
import { Instance, SnapshotOut, types, flow, applySnapshot, getSnapshot } from "mobx-state-tree"

import i18n from "i18n/i18n"
import { ApiResult } from "services/api"
import { 
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"

/**
 * Model description here for TypeScript hints.
 */
const Webhook = types.model({
  id: types.identifier,
  url: types.string
}).extend(withSetValue)



export const WebhookStoreModel = types
  .model("WebhookStore")
  .props({
    webhooks: types.optional(types.array(Webhook), []),
    selectedWebhook: types.safeReference(Webhook),
    entries: types.optional(types.array(types.string), []),
    page: types.optional(types.number, 1),
    totalPage: types.optional(types.number, 0),
    nextUrl: types.maybe(types.string)
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .views(self=> ({
    get formatEntries() {
      return self.entries.map(entry => { return JSON.parse(entry)})
    }
  }))
  .actions(self=> ({
    setWebhooks(webhooks) {
      applySnapshot(self, {
        ...getSnapshot(self),
        webhooks: webhooks,
        selectedWebhook: undefined
      })
    },
    setEntries(entries) {
      const newEntries = entries.map(entry => { return JSON.stringify(entry)})
      
      applySnapshot(self, {
        ...getSnapshot(self),
        entries: newEntries
      })
    },
    addWebhook() {
      if (some(self.webhooks, ['id', 'new'])) return

      self.webhooks.push({
        id: 'new',
        url: ''
      })

      applySnapshot(self, {
        ...getSnapshot(self),
        selectedWebhook: 'new'
      })
    }
  }))
  .actions(self => ({
    getWebhooks: flow(function * () {
      try {
        const res: ApiResult = yield self.environment.webhookApi.all()          

        if (res.kind === "ok") self.setWebhooks(res.data)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }), 
    getEntries: flow(function * () {
      try {
        if (!self.selectedWebhook || self.selectedWebhook?.id === 'new') return

        const additionalPath: string = 'entries'
        const res: ApiResult = yield self.environment.webhookApi.find(self.selectedWebhook?.id, additionalPath)          

        if (res.kind === "ok") self.setEntries(res.data)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }), 
    saveWebhook: flow(function * () {
      try {
        if (!self.selectedWebhook) return
        const { setNotification } = self.rootStore.notificationStore
        
        const isNew = self.selectedWebhook.id === 'new'
        const payload: any = isNew ? omit(self.selectedWebhook, ['id']) : self.selectedWebhook

        const res: ApiResult = yield self.environment.webhookApi.save(payload)          

        if (res.kind === "ok") {
          self.selectedWebhook = undefined
          setNotification({
            severity: 'success',
            message: i18n.t(`webhook.success.${isNew ? 'successfullyCreated' : 'successfullyUpdated'}`)
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }), 
    removeWebhook: flow(function * () {
      try {
        if (!self.selectedWebhook) return
        const { setNotification } = self.rootStore.notificationStore

        const res: ApiResult = yield self.environment.webhookApi.remove(self.selectedWebhook.id)          

        if (res.kind === "ok") {
          self.selectedWebhook = undefined
          setNotification({
            severity: 'success',
            message: i18n.t(`webhook.success.successfullyRemoved`)
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }), 
    cancelAddWebhook() {
      const newWebhooks = filter(self.webhooks, webhook => { return webhook.id !== 'new' })
      self.setWebhooks(newWebhooks)
    }
  }))

type WebhookStoreType = Instance<typeof WebhookStoreModel>
export interface WebhookStore extends WebhookStoreType {}
type WebhookStoreSnapshotType = SnapshotOut<typeof WebhookStoreModel>
export interface WebhookStoreSnapshot extends WebhookStoreSnapshotType {}
