/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-28 18:50:15
 * @modify date 2022-02-01 17:25:31
 */
 import { Theme } from '@mui/material'
 import { createStyles, makeStyles } from '@mui/styles'
 import { colors, centered, verticalCentered } from 'assets'
import { isMobile } from "react-device-detect"

 export const drawerWidth = 270
 export const headerHigh = 54

 export const useStyles = makeStyles((theme: Theme) =>
   createStyles({
     verticalCenter: {
       ...verticalCentered
     },
     appBar: {
       boxShadow: 'none',
       color: theme.palette.text.primary,
     },
     toolbar: {
       height: headerHigh,
       minHeight: '50px',
       backgroundColor: 'white',
       justifyContent: 'space-between',
     },
     toolbarDrawer: {
       paddingLeft: theme.spacing(3),
       paddingRight: theme.spacing(3),
       marginBottom: theme.spacing(2)
     },
     iconButton: {
       margin: 0,
       color: theme.palette.primary.main,
       '&:hover': {
         backgroundColor: 'transparent',
         color: theme.palette.primary.dark
       }
     },
     drawer: {
       '& .MuiDrawer-paper': {
         transition: theme.transitions.create('width', {
           easing: theme.transitions.easing.sharp,
           duration: theme.transitions.duration.enteringScreen,
         }),
         overflowX: 'hidden',
         boxSizing: 'border-box',
         width: drawerWidth
       }
     },
     miniDrawer: {
       '& .MuiDrawer-paper': {
         transition: theme.transitions.create('width', {
           easing: theme.transitions.easing.sharp,
           duration: theme.transitions.duration.leavingScreen,
         }),
         overflowX: 'hidden',
         width: `calc(${theme.spacing(7)} + 1px)`,
         '& .MuiToolbar-root': {
           flexDirection: 'column',
           alignItems: 'center',
           justifyContent: 'unset',
           padding: 0,
           marginTop: theme.spacing(2.5)
         }
       }
     },
     logo: {
       display: 'flex'
     },
     search: {
       "& .MuiInput-underline:before": {
         borderBottom: "none"
       }
     },
     menuItem: {
       borderRadius: 5,
       marginTop: theme.spacing(1),
       marginBottom: theme.spacing(1),
       '&:hover': {
         backgroundColor: colors.primary.o25,
         marginTop: theme.spacing(1),
         marginBottom: theme.spacing(1)
       },
       '&.active': {
         backgroundColor: colors.primary.o25,
         '& .MuiListItemIcon-root': {
           backgroundColor: colors.primary.main
         },
         '& .MuiTypography-root': {
           fontWeight: 600
         }
       }
     },
     menuMiniDrawer: {
       padding: 8,
       marginLeft: 'auto',
       marginRight: 'auto',
       backgroundColor: 'transparent',
       width: 'fit-content',
       '&.active': {
         backgroundColor: colors.primary.main,
         '& .MuiListItemIcon-root': {
           backgroundColor: 'transparent'
         },
       },
       '& .MuiListItemIcon-root': {
         marginRight: 0
       },
       '& .MuiListItemText-root': {
         display: 'none'
       }
     },
     menuIcon: {
       ...centered,
       minWidth: 0,
       width: 24,
       height: 24,
       borderRadius: 5,
       marginRight: 10,
       '& svg': {
        width: 24,
        height: 24
       }
     },
     pageWrapper: {
       paddingLeft: 0,
       paddingTop: (headerHigh + (isMobile ? 26 : 0)),
       '&.miniDrawer': {
         paddingLeft: `calc(${theme.spacing(7)} + 1px)`
       },
       [theme.breakpoints.up('md')]: {
         paddingLeft: drawerWidth
       }
     },
     banner: {
       borderRadius: 5,
       marginRight: theme.spacing(1),
       padding: theme.spacing(1),
       backgroundColor: colors.primary.main,
       color: "white",
       fontWeight: 500,
       fontSize: 'smaller',
       textAlign: 'center',
       '&.dormant': {
         backgroundColor: colors.secondary.main,
         width: '100%'
       }
     }
   })
 )
