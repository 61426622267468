/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-18 22:11:48
 * @modify date 2022-04-18 22:11:48
 */

import { map } from "lodash"

export const withCuppersViews = (self: any) => ({
  views: {
    get activeCuppers() {
      return map(self.cuppers, 'active')
    },
    get activeCuppersLength() {
      return self.activeCuppers.length
    }
  }
})