import { Api } from "./api"

export class SampleTransactionApi extends Api {
  constructor() {
    super('sample_transactions', [
      "customer", "streetAddress", "recipient", "streetAddress2", "country", "state", "city", "zipCode", "isUrgentDelivery", "requestedByEmail",
      "recipientEmails", "traderEmails", "qualityControlEmails", "approvalEmails", "type", "sampleTransactionItemsAttributes","label"
    ])
  }
}
