export const bodyDescriptorList = [{
	"id": 140,
	"label": "light",
	"color": "#B0B0B0",
	"parent_id": null,
	"label_es": "ligero",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "body",
	"positive": 1,
	"label_pt": "ligeiro",
	"label_zh-CN": "轻",
	"weight": 1.18,
	"label_fr-CA": "léger",
	"label_ko": "가벼운",
	"label_id": "ringan",
	"label_ja": "軽い",
	"groups": [{
		"id": 141,
		"label": "watery",
		"color": "#B0B0B0",
		"parent_id": 140,
		"label_es": "aguado",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "body",
		"positive": 1,
		"label_pt": "aguado",
		"label_zh-CN": "水",
		"weight": 1.11,
		"label_fr-CA": "aqueux",
		"label_ko": "물처럼 묽은",
		"label_id": "seperti air",
		"label_ja": "水っぽい",
		"groups": []
	}, {
		"id": 142,
		"label": "tea-like",
		"color": "#B0B0B0",
		"parent_id": 140,
		"label_es": "como té",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "body",
		"positive": 1,
		"label_pt": "chá",
		"label_zh-CN": "茶感",
		"weight": 1.25,
		"label_fr-CA": "thé",
		"label_ko": "차 같은",
		"label_id": "seperti teh",
		"label_ja": "お茶っぽい",
		"groups": []
	}, {
		"id": 143,
		"label": "silky",
		"color": "#B0B0B0",
		"parent_id": 140,
		"label_es": "sedoso",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "body",
		"positive": 1,
		"label_pt": "sedoso",
		"label_zh-CN": "丝滑",
		"weight": 1.24,
		"label_fr-CA": "velouté",
		"label_ko": "실크처럼 부드러운",
		"label_id": "silky",
		"label_ja": "シルキー・なめらか",
		"groups": []
	}, {
		"id": 144,
		"label": "slick",
		"color": "#B0B0B0",
		"parent_id": 140,
		"label_es": "resbaladizo",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "body",
		"positive": 1,
		"label_pt": "liso",
		"label_zh-CN": "光滑",
		"label_zh-HK": "slick",
		"weight": 1.15,
		"label_fr-CA": "fade",
		"label_ko": "미끌미끌한",
		"label_id": "licin",
		"label_ja": "すべすべした",
		"groups": []
	}, {
		"id": 145,
		"label": "juicy",
		"color": "#B0B0B0",
		"parent_id": 140,
		"label_es": "jugoso",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "body",
		"positive": 1,
		"label_pt": "suculento",
		"label_zh-CN": "多汁",
		"weight": 1.21,
		"label_fr-CA": "juteux",
		"label_ko": "주스 같은",
		"label_id": "juicy",
		"label_ja": "ジューシー",
		"groups": []
	}]
}, {
	"id": 146,
	"label": "medium",
	"color": "#747474",
	"parent_id": null,
	"label_es": "medio",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "body",
	"positive": 1,
	"label_pt": "médio",
	"label_zh-CN": "中",
	"weight": 1.17,
	"label_fr-CA": "médium",
	"label_ko": "중간의",
	"label_id": "medium",
	"label_ja": "ミディアム",
	"groups": [{
		"id": 147,
		"label": "smooth",
		"color": "#747474",
		"parent_id": 146,
		"label_es": "suave",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "body",
		"positive": 1,
		"label_pt": "suave",
		"label_zh-CN": "柔顺",
		"weight": 1.22,
		"label_fr-CA": "moelleux",
		"label_ko": "목넘김이 부드러운",
		"label_id": "mulus",
		"label_ja": "スムース・なめらか",
		"groups": []
	}, {
		"id": 148,
		"label": "2% milk",
		"color": "#747474",
		"parent_id": 146,
		"label_es": "2% leche",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "body",
		"positive": 1,
		"label_pt": "leite desnatado",
		"label_zh-CN": "2%脱脂奶",
		"weight": 1.0,
		"label_fr-CA": "laiteux",
		"label_ko": "저지방 우유",
		"label_id": "susu 2%",
		"label_ja": "低脂肪ミルク",
		"groups": []
	}, {
		"id": 149,
		"label": "syrupy",
		"color": "#747474",
		"parent_id": 146,
		"label_es": "almibarado",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "body",
		"positive": 1,
		"label_pt": "meloso",
		"label_zh-CN": "糖浆",
		"weight": 1.22,
		"label_fr-CA": "sirupeux",
		"label_ko": "시럽 같은",
		"label_id": "seperti sirup",
		"label_ja": "ドロドロした・シロップのような",
		"groups": []
	}, {
		"id": 150,
		"label": "round",
		"color": "#747474",
		"parent_id": 146,
		"label_es": "redondo",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "body",
		"positive": 1,
		"label_pt": "redondo",
		"label_zh-CN": "圆润",
		"weight": 1.21,
		"label_fr-CA": "rond",
		"label_ko": "둥근",
		"label_id": "utuh",
		"label_ja": "丸い",
		"groups": []
	}, {
		"id": 151,
		"label": "creamy",
		"color": "#747474",
		"parent_id": 146,
		"label_es": "cremoso",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "body",
		"positive": 1,
		"label_pt": "cremoso",
		"label_zh-CN": "奶油",
		"weight": 1.2,
		"label_fr-CA": "crémeux",
		"label_ko": "크림같은",
		"label_id": "creamy",
		"label_ja": "クリーミー",
		"groups": []
	}, {
		"id": 240,
		"label": "chalky",
		"color": "#747474",
		"parent_id": 146,
		"label_es": "gredoso",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "body",
		"positive": 1,
		"label_pt": "calcário",
		"label_zh-CN": "粉粒",
		"weight": 1.13,
		"label_fr-CA": "crayeux",
		"label_ko": "분필",
		"label_id": "berkapur",
		"label_ja": "チョークっぽい",
		"groups": []
	}]
}, {
	"id": 152,
	"label": "heavy",
	"color": "#414141",
	"parent_id": null,
	"label_es": "cremoso",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "body",
	"positive": 1,
	"label_pt": "encorpado",
	"label_zh-CN": "重",
	"weight": 1.17,
	"label_fr-CA": "fort",
	"label_ko": "무거운",
	"label_id": "berat",
	"label_ja": "重い",
	"groups": [{
		"id": 153,
		"label": "full",
		"color": "#414141",
		"parent_id": 152,
		"label_es": "lleno",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "body",
		"positive": 1,
		"label_pt": "cheio",
		"label_zh-CN": "饱满",
		"weight": 1.2,
		"label_fr-CA": "beaucoup",
		"label_ko": "가득찬",
		"label_id": "penuh",
		"label_ja": "ふくよか・たっぷり",
		"groups": []
	}, {
		"id": 154,
		"label": "velvety",
		"color": "#414141",
		"parent_id": 152,
		"label_es": "aterciopelado",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "body",
		"positive": 1,
		"label_pt": "aveludado",
		"label_zh-CN": "天鹅绒",
		"weight": 1.24,
		"label_fr-CA": "velouté",
		"label_ko": "벨벳같은",
		"label_id": "seperti beludru",
		"label_ja": "ベルベットのよう・ピロードのよう",
		"groups": []
	}, {
		"id": 155,
		"label": "big",
		"color": "#414141",
		"parent_id": 152,
		"label_es": "grande",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "body",
		"positive": 1,
		"label_pt": "grande",
		"label_zh-CN": "大",
		"weight": 1.21,
		"label_fr-CA": "big",
		"label_ko": "큰",
		"label_id": "besar",
		"label_ja": "大きい",
		"groups": []
	}, {
		"id": 156,
		"label": "chewy",
		"color": "#414141",
		"parent_id": 152,
		"label_es": "vigoroso",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "body",
		"positive": 1,
		"label_pt": "mastigável",
		"label_zh-CN": "劲道",
		"weight": 1.15,
		"label_fr-CA": "moelleux",
		"label_ko": "쫀득한",
		"label_id": "kenyal",
		"label_ja": "歯ごたえのある",
		"groups": []
	}, {
		"id": 157,
		"label": "coating",
		"color": "#414141",
		"parent_id": 152,
		"label_es": "revestimiento",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "body",
		"positive": 1,
		"label_pt": "revestimento",
		"label_zh-CN": "糖衣",
		"weight": 1.0,
		"label_fr-CA": "enrober",
		"label_ko": "감싸는 듯한",
		"label_id": "seperti beludru",
		"label_ja": "コーティングされた",
		"groups": []
	}, {
		"id": 237,
		"label": "oily",
		"color": "#414141",
		"parent_id": 152,
		"label_es": "aceitoso",
		"open": 1,
		"zoomed": 0,
		"selected": false,
		"flavor_type": "body",
		"positive": 1,
		"label_pt": "oleoso",
		"label_zh-CN": "油腻",
		"weight": 1.16,
		"label_fr-CA": "gras",
		"label_ko": "기름기있는",
		"label_id": "berminyak",
		"label_ja": "油っぽい",
		"groups": []
	}]
}]