import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { ScoresChartProps } from './scores-chart.props'
import { useTranslation } from 'react-i18next'
import { colors } from 'assets';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const ScoreChart: React.FC<ScoresChartProps> = (props: ScoresChartProps) => {
  const { t } = useTranslation()
  const { datasets, labels, labelsFullName, scores, isMobile, maxMinYaxis } = props
  const [dataChart] = React.useState({ labels: labels!, datasets: datasets! });
  let portrait = window.matchMedia("(orientation: portrait)");
  const [portraits , setPortraits] = React.useState(true)

  const options = {
    responsive: true,
    interaction: {
      mode: 'point' as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: false,
        text: 'Chart.js Line Chart - Multi Axis',
      },
      tooltip: {
        // Disable the on-canvas tooltip
        enabled: false,
        external: (context) => {
          // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip');

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          // Set caret Position (above, below,no-transform ).As I need above I don't delete that class
          tooltipEl.classList.remove('below', 'no-transform');

          //set variable for position data chart
          let positionBydataChart = 0;

          // Set HTML & Data
          if (tooltipModel.body) {
            const dataFromCurrentElement = tooltipModel.dataPoints[0];
            const currentElement = dataFromCurrentElement.dataIndex;
            const formattedValue = dataFromCurrentElement.formattedValue.trim();
            const currentDataToShow = formattedValue.substr(1, formattedValue.length - 2).split(' ');
            if (dataFromCurrentElement.dataset.label === "Average Score") return

            let innerHtml=`<div style="border-collapse: separate; overflow: hidden; border-radius: 10px; box-shadow: 0 6px 12px rgba(0,0,0,.175);">
            <div style="background-color: rgb(255, 255, 255,0.8); padding-top: 5px; padding-bottom: 1px; padding-left: 7px; color: #656E76; font-size: 14px; border-bottom: solid 0px #DDD">
              <span style="font-weight: 600">${t(labelsFullName![dataFromCurrentElement.dataIndex])}</span>
            </div>`;

            tooltipModel.dataPoints.map((x,index) => {

              if(index === 0 ){
                innerHtml += `
                <div style="display: flex; padding: 0.5rem; background-color: rgb(255, 255, 255,0.8)">
                    <div style="display: flex; margin-right: 0.5rem;align-items: center  ">
                        <div style="border-radius: 20%; background-color: ${x.dataset.backgroundColor}; height: 8px; width: 8px;"></div>
                    </div>
                    <div style="display: flex; font-size: 12px; color: #656E76">
                        <div style="width: 120px"> <span style="font-weight: 600">${x.dataset.label === null  ? "":x.dataset.label}</span></div>
                        <div style="border-radius: 13px; background-color: ${colors.primary.main} ; height: 20px; width: 35px;margin-left: 5.3rem; color: white; text-align:center  "> ${scores![x.datasetIndex]['data'][x.dataIndex]}</div>
                    </div>
                </div>
                <div style="color: #656E76;display: flex;  flex-direction: row; background-color: rgb(255, 255, 255,0.8); margin-top: -1rem ;padding: 0.5rem;font-weight: 600; font-size: 12px; border-bottom: solid 1px #DDD">
                    <div style="width: 200px">Avg. Score difference</div> 
                    <div style="color: ${colors.secondary.main}; text-align:center; margin-left: 1.4rem; height: 20px; width: 30px; font-weight:bold">${Number(x.parsed.y)}</div>
                </div>
              `
              return
              }

              positionBydataChart += 40

              innerHtml += `
              <div style="display: flex; padding: 0.5rem; background-color: rgb(255, 255, 255,0.8)">
                  <div style="display: flex; margin-right: 0.5rem;align-items: center  ">
                      <div style="border-radius: 20%; background-color: ${x.dataset.backgroundColor}; height: 8px; width: 8px;"></div>
                  </div>
                  <div style="display: flex; font-size: 12px; color: #656E76">
                      <div style="width: 120px"> <span style="font-weight: 600">${x.dataset.label === null  ? "":x.dataset.label}</span></div>
                      <div style="border-radius: 13px; background-color: ${colors.primary.main} ; height: 20px; width: 35px;margin-left: 5.3rem; color: white; text-align:center  "> ${scores![x.datasetIndex]['data'][x.dataIndex]}</div>
                  </div>
              </div>
            `
            })

            const innerHtmlButton = `</div> <div style="border-left: 10px solid transparent; border-right: 10px solid transparent;border-top: 10px solid rgb(255, 255, 255,1); width: 15px; margin-left: 4.5rem; margin-top: -0.3rem"></div>`;

            (isMobile && portraits) ? tooltipEl.querySelector('table')!.innerHTML = `${innerHtml}` : tooltipEl.querySelector('table')!.innerHTML = `${innerHtml} ${innerHtmlButton}`;
          }

          const position = context.chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = '1';
          tooltipEl.style.position = 'absolute';
          if(isMobile && portraits){
            tooltipEl.style.left = 150 + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset - positionBydataChart + tooltipModel.caretX  + 'px';
          } else{
            tooltipEl.style.left = position.left + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset - positionBydataChart + tooltipModel.caretY + 'px';
          }

          tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
          tooltipEl.style.marginLeft = '-80px ';
          tooltipEl.style.marginTop = '-100px'
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.fontFamily = '"sans-serif"';
        }
      },

      legend: {
        display: false
      },
    },
    scales: {
      x: {
        offset: true,
        grid: {
          display: false
        },
        ticks : {
          minRotation : (isMobile && portraits) ? 90 : 0
        }
      },
      x1: {
        offset: true,
        display: false,
        position: 'right' as const,
        grid: {
          display: false
        }
      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        min: Math.floor(maxMinYaxis![0].min),
        max: Math.ceil(maxMinYaxis![0].max),
        ticks : {
          minRotation : (isMobile && portraits) ? 90 : 0
        }
      },
      y1: {
        type: 'linear' as const,
        display: false,
        position: 'left' as const,
      },
    },
  };

  React.useLayoutEffect(() => {
    portrait.addEventListener("change", function(e) {
      if(e.matches) {
        // Portrait mode
        setPortraits(true)
      } else {
        // Landscape
        setPortraits(false)
      }
    })
  }, [])

  return (
    <>
      {(isMobile && portraits) ?
          <div
              style={{
                width: 350,
                height: 350,
                transform: "rotate(90deg)"
              }}
          >
            <Line height={350} width={350} options={options} data={dataChart} />
          </div> :
          <Line height={100} options={options} data={dataChart} />
      }
    </>
  )

}
