import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {centered, colors} from "assets";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
      border: 'none',
      borderRadius: 0,
      padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      borderBottom: `1px solid ${colors.border.primary}`,
      [theme.breakpoints.up('md')]: {
        borderRadius: 5,
        border: `1px solid ${colors.border.primary}`,
      }
    },
    iconButton: {
      margin: 0,
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark
      }
    },
    score: {
      color: 'white',
      backgroundColor: colors.primary.o50,
      borderRadius: '50%',
      fontSize: 'smaller',
      fontWeight: 600,
      height: 35,
      width: 35,
      marginLeft: 'auto',
      marginRight: 'auto',
      ...centered
    },
    highlight: {
      backgroundColor: colors.secondary.o50,
      borderRadius: 5,
      margin: 5,
      padding: "5px 2px"
    }
  })
)
