/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-03 16:43:21
 * @modify date 2022-02-03 20:13:18
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { centered, colors } from 'assets'
  
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      border: 'none',
      boxShadow: 'none',
      borderBottom: `1px solid ${colors.border.primary}`,
      '&:before': {
        content: 'none'
      }
    },
    preWrapper: {
      backgroundColor: colors.cultured,
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2)
    },
    preText: {
      whiteSpace: 'pre-wrap'
    },
    tab: {
      border: 'none'
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      borderBottom: `1px solid ${colors.border.primary}`,
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      '&.is-edit': {
        borderBottom: 'none'
      },
      [theme.breakpoints.up('md')]: {
        ...centered,
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
    }
  })
)