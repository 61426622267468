/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-01 02:11:30
 * @modify date 2022-03-01 02:11:30
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { centered, colors } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      minHeight: 150,
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      '&.no-descriptor': {
        alignItems: 'center',
        justifyContent: 'center'
      },
      '&.row': {
        flexDirection: 'row',
        minHeight: 'unset'
      },
      '&.no-title': {
        padding: '8px 0',
        borderColor: 'transparent'
      }
    },
    scoreWrapper: {
      ...centered
    },
    inputScore: {
      width: 125,
      borderRadius: 99,
      marginLeft: theme.spacing(1),
      backgroundColor: colors.primary.o25,
      '& .MuiInputBase-root': {
        borderRadius: 99
      },
      '& input': {
        textAlign: 'center',
        color: colors.text.primary,
        fontWeight: 600,
        '&.Mui-disabled': {
          WebkitTextFillColor: 'inherit',
          backgroundColor: 'unset',
        }
      },
      '& fieldset': {
        border: 'none'
      }
    },
    descriptorWrapper: {
      flex: 1,
      '&.no-descriptor': {
        ...centered,
        padding: theme.spacing(3),
      }
    },
    addDescriptor: {
      borderRadius: 99,
      width: 'fit-content',
      borderStyle: 'dashed',
      '&:hover': {
        borderStyle: 'dashed'
      }
    },
    hasNoDescriptor: {
      color: colors.text.o50,
      fontStyle: 'italic',
      textAlign: 'center'
    },
    descriptorButton: {
      textAlign: 'center',
      marginTop: theme.spacing(4)
    },
    leftArrow: {
      transform: 'rotate(180deg)',
      color: colors.text.o25
    }
  })
)
