/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-05-30 23:02:13
 * @modify date 2022-05-30 23:02:13
 */

import React from 'react'
import { startsWith } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom"
import ReactHtmlParser from 'react-html-parser'
import { Box, Typography, SvgIcon, Button, Stack } from '@mui/material'

import { useStores } from 'models'
import { centered } from 'assets'
import { ModalWrapper } from 'components'
import { Logo } from 'assets/images'
import { colors } from 'assets'
import { findRoute } from 'constants/routes'

export const Trial: React.FC = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    userStore: { fromActivation, sawNavigationTutorial },
    companyStore: { isTrial, trialDaysLeft, isTrialExpired, subscriptionCustomerId, subscriptionId },
    modalStore: { trialEnd, welcome, setValue: setModalValue }
  } = useStores()

  const planLink = findRoute('plan')
  const closeModal = startsWith(subscriptionCustomerId, 'cus', ) && startsWith(subscriptionId, 'sub')
  const openModal = trialEnd && !welcome && !closeModal && !fromActivation && sawNavigationTutorial && !(window as any).isRNWebview

  React.useEffect(() => {
    if (!isTrial || (window as any).isRNWebview) return

    setModalValue('trialEnd', true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTrial])

  return (
    <ModalWrapper
      maxWidth='sm'
      open={openModal}
      {...(!isTrialExpired && {onClose: () => setModalValue('trialEnd', false)})}
      {...(isTrialExpired && {containerProps: { sx: {py: "32px !important"}}})}
    >
      <Box sx={{...centered, flexDirection: 'column', flex: 1}}>
        <SvgIcon component={Logo} inheritViewBox sx={{height: 50, width: 200}} />
        <Typography
          variant='body1'
          align='center'
          sx={{my: 2, '& strong': {color: colors.primary.main}}}
        >
          {ReactHtmlParser(isTrialExpired ? t('subscription.trialExpired') : t('subscription.trialExpiredDescription', {
            count: trialDaysLeft,
          }))}
        </Typography>

        <Stack spacing={2} sx={{mt: 2}}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setModalValue('trialEnd', false)
              navigate(planLink)
            }}
          >
            {isTrialExpired ? t('subscription.upgradeMyAccountNow') : t('subscription.subscribeNow')}
          </Button>
          {!isTrialExpired &&
            <Button
              className='btn-text'
              onClick={() => setModalValue('trialEnd', false)}
            >
              {t('subscription.later')}
            </Button>
          }
        </Stack>
      </Box>
    </ModalWrapper>
  )
})
