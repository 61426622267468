/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-28 20:20:43
 * @modify date 2022-02-28 20:20:43
 */

import React from 'react'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactHtmlParser from 'react-html-parser'
import {isEmpty, toString, camelCase, isUndefined, includes, upperFirst, isNull} from 'lodash'
import {
  HelpOutline,
  FilterList,
  FilterListOff,
  LockOutlined,
  LockOpenOutlined,
  Warning
} from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Portal } from '@mui/base'
import {
  Tab, Container, Grid, Card, Typography, Box, Button, IconButton, Popover,
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Divider, Rating, Alert
} from '@mui/material'
import { isMobile } from "react-device-detect"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { useStores } from 'models'
import { globalAction } from 'utils'
import { findRoute } from 'constants/routes'
import {
  arabicaScoreTable, coeScoreTable, commercialScoreTable,
  defaultTotalScoreCuppingProtocols,
  roastLevels, robustaScoreTable,
  scaDescriptiveScoreTable,
  scaScoreTable, sucafinaTastingTable
} from 'constants/form'
import {IconTotalScore, IconRoastLevel } from 'assets/images'
import { HeaderTitle, ModalWrapper, ModalConfirm, ModalMessage, FormInput } from 'components'
import { colors } from 'assets'

import { useStyles } from './sample-score.styles'
import {Arabica, Robusta, Coe, Sca, ScoreTimer, SucafinaTasting, Commercial} from './components'

export const SampleScore: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const {
    userStore: { isAuthenticated, isJoiningAbTest },
    dashboardStore: { samplesCupped },
    cuppingSessionStore: { selectedCuppingSession, getCuppingSession, guestUrlToken },
    sampleStore: { samples, getSamples, setValue: setSampleValue, selectedSample,
      selectedSample: { id, sampleId, name, tab: tabSample, selectedScore: {
        roastLevel, cuppingProtocol, scoreKeys, totalScore, calculateTotalScore, triggerWebhook, setScoreValue, resetScore
      }
    }},
    npsStore: { sca: { nps, descriptor, other }, setValue: setNpsValue, sendNpsSca },
    guideStore: { guideScore },
    notificationStore
  } = useStores()

  const cuppingSessionLink = findRoute('cuppingSessions')
  const sampleReviewLink = findRoute('sampleReview')
  const homeLink = findRoute('home')

  const [tab, setTab] = React.useState('')
  const [isGuideScore] = React.useState(guideScore)
  const [openReview, setOpenReview] = React.useState(false)
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [openMessage, setOpenMessage] = React.useState(false)
  const [openError, setOpenError] = React.useState(false)
  const [openNps, setOpenNps] = React.useState(false)
  const [openWarning, setOpenWarning] = React.useState(false)
  const [hideScore, setHideScore] = React.useState(true)
  const [anchorRoastLevel, setAnchorRoastLevel] = React.useState<HTMLButtonElement | null>(null)
  const openRoastLevel = Boolean(anchorRoastLevel)
  const isScaAffective = cuppingProtocol === 'sca_affective'
  const isScaDescriptive = cuppingProtocol === 'sca_descriptive'
  const isSucafinaTasting = cuppingProtocol === 'sucafina_tasting'
  const isCommercial = cuppingProtocol === 'commercial'
  const showRoastLevel = !cuppingProtocol.includes('sca') && !isSucafinaTasting && !isCommercial

  const showScore = () => {
    if (selectedCuppingSession?.cuppingProtocol.includes('sca')) return tabSample === 'affective'

    if (isSucafinaTasting) return false

    return true
  }

  const setErrors = () => {
    notificationStore.setNotification({
      severity: 'error',
      message: t('sampleScore.error.notAllowedCup')
    })

    navigate(-1)
  }

  const onSaveScores = async () => {
    setOpenConfirm(false)

    const actions: Array<any> = samples.map(async (sample) => {
      if (isAuthenticated) return sample.selectedScore.saveScore(sample.id)

      if (!executeRecaptcha) return
      const captchaToken = await executeRecaptcha('public')
      sample.selectedScore.guestScore(sample.id, captchaToken)
    }) || []

    globalAction(notificationStore, {
      complexAction: true,
      action: async () => {
        try {
          await Promise.all(actions)
          if (isAuthenticated) await triggerWebhook(selectedCuppingSession.uniqueToken)
          isJoiningAbTest && selectedCuppingSession?.cuppingProtocol.includes('sca') ? setOpenNps(true) : setOpenMessage(true)
        } catch (e) {
          setOpenError(true)
        }
      }
    })
  }

  const checkTotalScore = () => {
    let checkData = false
    samples.forEach( dataSample => {
      if(totalScoreByProtocol( selectedCuppingSession?.cuppingProtocol , dataSample) === 0) {
        checkData = true
      }
    })
    if(checkData) {
      setOpenWarning(true)
    } else{
      setOpenReview(true)
    }
  }

  const totalScoreByProtocol = (protocol, dataSample) => {
    let total = 0
    switch (protocol) {
      case 'sca':
      case 'sca_affective':
        return total = scaScoreTable.reduce((prev, current) => {
          prev += dataSample.selectedScore[current.key]
          if (defaultTotalScoreCuppingProtocols[defaultTotalScoreCuppingProtocols.findIndex( x => x.key === protocol)].totalScore === prev) return 0
          return prev;
        }, 0);
      case 'sca_descriptive':
        return total = scaDescriptiveScoreTable.reduce((prev, current) => {
          prev += dataSample.selectedScore[current.key]
          return prev;
        }, 0);
      case 'arabica':
        return total = arabicaScoreTable.reduce((prev, current) => {
          prev += dataSample.selectedScore[current.key]
          if (defaultTotalScoreCuppingProtocols[defaultTotalScoreCuppingProtocols.findIndex( x => x.key === protocol)].totalScore === prev) return 0
          return prev;
        }, 0);
      case 'robusta':
        return total = robustaScoreTable.reduce((prev, current) => {
          prev += dataSample.selectedScore[current.key]
          if (defaultTotalScoreCuppingProtocols[defaultTotalScoreCuppingProtocols.findIndex( x => x.key === protocol)].totalScore === prev) return 0
          return prev;
        }, 0);
      case 'cup_of_excellence':
        return total = coeScoreTable.reduce((prev, current) => {
          prev += dataSample.selectedScore[current.key]
          if (defaultTotalScoreCuppingProtocols[defaultTotalScoreCuppingProtocols.findIndex( x => x.key === protocol)].totalScore === prev) return 0
          return prev;
        }, 0);
      case 'sucafina_tasting':
        return total = sucafinaTastingTable.reduce((prev, current) => {
          prev += dataSample.selectedScore[current.key]
          if (defaultTotalScoreCuppingProtocols[defaultTotalScoreCuppingProtocols.findIndex( x => x.key === protocol)].totalScore === prev) return 0
          return prev;
        }, 0);
      case 'commercial':
        return total = commercialScoreTable.reduce((prev, current) => {
          prev += dataSample.selectedScore[current.key]
          if (defaultTotalScoreCuppingProtocols[defaultTotalScoreCuppingProtocols.findIndex( x => x.key === protocol)].totalScore === prev) return 0
          return prev;
        }, 0);
      default:
        return total
    }
  }

  const onContinue = () => {
    setOpenMessage(false)
    if (!isAuthenticated) {
      navigate(`/invitations/${selectedCuppingSession.uniqueToken}${guestUrlToken ? '?token=' + guestUrlToken : ''}`, { replace: true })
      return
    }

    globalAction(notificationStore, {
      action: async() => await getSamples(selectedCuppingSession.uniqueToken),
      afterAction: navigate(sampleReviewLink)
    })
  }

  const setScore = () => {
    samples.forEach(sample => {
      if (sample.selectedScore) {
        if (!selectedCuppingSession?.cuppingProtocol) return

        const protocol = selectedCuppingSession.cuppingProtocol

        sample.selectedScore.setValue('cuppingProtocol', protocol)
        if (protocol.includes("sca")) sample.selectedScore.setScaDefaultValue(protocol === 'sca_descriptive')
        if (protocol === "commercial") sample.selectedScore.setCommercialDefaultValue()

        sample.selectedScore.setLocalStorage(sample)
        if (protocol === "sca_affective") sample.setValue("tab","affective")
        if (protocol === "sucafina_tasting") sample.setValue("interestStatus", false)

        sample.selectedScore.setValue('totalScore', sample.selectedScore.calculateTotalScore)
      }
    })
  }

  const sendNpsAction = async (isSend = true) => globalAction(notificationStore, {
    complexAction: true,
    action: async () => {
      if (isSend) await sendNpsSca(selectedCuppingSession?.id)
      await getSamples(selectedCuppingSession.uniqueToken)
      setOpenNps(false)
      navigate(sampleReviewLink)
    }
  })

  const renderTitleItem = (
    <Card className={classes.titleWrapper}>
      <Grid container sx={{alignItems: 'center'}}>
        <Grid item xs={12} md={4}>
          <Typography variant='body3'>
            {t('sampleScore.sample')} #{sampleId}<br/>
          </Typography>
          {!selectedCuppingSession.blind &&
            <Typography variant='h6'>{name}</Typography>
          }
        </Grid>
        {showScore() &&
          <Grid item xs={12} md={5} className={classes.midleTitle}>
                <Grid container sx={{alignItems: 'center'}}>
                  <Grid item xs={6}>
                    {!hideScore &&
                        <>
                          <Typography variant='body3'>{t('sampleScore.totalScore')}</Typography>
                          <Box className={classes.totalScoreWrapper}>
                            <IconTotalScore className={classes.totalScoreIcon}/>
                            <Typography variant='h3' color='primary'>{totalScore}</Typography>
                          </Box>
                        </>
                    }
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{mb: 1}}>
                      <IconButton className={classes.iconButtonHideScore}
                          onClick={() => {
                            hideScore ? setHideScore(false) : setHideScore(true)
                          }}
                      >
                        {!hideScore ?
                            <LockOutlined sx={{color: "white"}}/> :
                            <LockOpenOutlined sx={{color: "white"}}/>
                        }
                      </IconButton>
                    </Box>
                    {!hideScore ?
                        <Typography variant='body2' color='primary'>{t('sampleScore.hideScore')}</Typography> :
                        <Typography variant='body2' color='primary' >{t('sampleScore.viewScore')}</Typography>
                    }
                  </Grid>
                </Grid>
          </Grid>
        }
        <Grid item xs={12} md={showScore() ? 3 : 6} sx={{textAlign: {xs: 'center', md: 'right'}, display: {xs: 'none', md: 'block'}}}>
          <Button variant='contained' onClick={() => checkTotalScore()}>
            {t('sampleScore.reviewAndSubmit')}
          </Button>
        </Grid>
      </Grid>
    </Card>
  )

  const renderRoastLevelItem = (item: number, isReview: boolean = false) => {
    const selected = item === roastLevel ? 'selected' : ''

    return (
      <Grid
        xs
        item
        key={item}
        className={clsx(classes.roastLevelItem, selected, isReview && 'review')}
        {...(!isReview && {onClick: () => setScoreValue('roastLevel', item)})}
      >
        <IconRoastLevel className={clsx(classes.roastLevelIcon, `color-${item}`, isReview && 'review')}/>
        <Typography variant='caption' fontWeight={600}>
          {t(`descriptor.roastLevel${item}`)}
        </Typography>
      </Grid>
    )
  }

  const renderRoastLevel = (
    <>
      <Box className={classes.roastLevelWrapper}>
        <Typography variant='h6'>
          {t('descriptor.roastLevel')}
        </Typography>
        <IconButton
          color='primary'
          onClick={(event) => setAnchorRoastLevel(event.currentTarget)}
        >
          <HelpOutline />

        </IconButton>
        <Popover
          open={openRoastLevel}
          anchorEl={anchorRoastLevel}
          onClose={() => setAnchorRoastLevel(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          PaperProps={{
            sx: {width: 300}
          }}
        >
          <Typography sx={{ p: 2 }}>{ReactHtmlParser(t('sampleScore.roastLevelInfo'))}</Typography>
        </Popover>
      </Box>
      <Grid container sx={{flex: 1}}>
        {roastLevels.map(item => renderRoastLevelItem(item))}
      </Grid>
    </>
  )

  const renderProtocol = () => {
    switch (selectedCuppingSession?.cuppingProtocol) {
      case 'sca':
      case 'sca_affective':
      case 'sca_descriptive':
        return <Sca />
      case 'arabica':
        return <Arabica />
      case 'robusta':
        return <Robusta />
      case 'sucafina_tasting':
        return <SucafinaTasting />
      case 'cup_of_excellence':
        return <Coe />
      case 'commercial':
        return <Commercial />
      default:
        return null
    }
  }

  const renderTabItem = (
    <TabPanel value={toString(id)} className={classes.panelWrapper}>
      {renderProtocol()}
    </TabPanel>
  )

  const renderReview = (
    <ModalWrapper
      open={openReview}
      containerProps={{
        sx: { p: '0 !important', width: 'fit-content', maxWidth: '100%' }
      }}
    >
      {cuppingProtocol.includes('sca') ? (
        <TableContainer sx={{ maxWidth: '100%' }}>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell className='sticky-cell' align="center" sx={{minWidth: 175, py: 1}}>
                  <Typography variant='h6'>{t('sampleScore.sample')}</Typography>
                </TableCell>
                {samples.map(sample =>
                  <TableCell key={sample.id} align="center" sx={{minWidth: 175}} className={classes.tableHeader}>
                    <Box>
                      <Typography variant='h6'>{sample.sampleId}</Typography>
                      <Box className='th-wrapper'>
                        <Typography variant='subtitle2' align='center' sx={{py: 0.5, width: '50%'}}>
                          {t('descriptor.descriptive')}
                        </Typography>
                        <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
                        <Typography variant='subtitle2' align='center' sx={{py: 0.5, width: '50%'}}>
                          {t('descriptor.affective')}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {scoreKeys.map(sk =>
                <TableRow key={sk.key}>
                  <TableCell className={clsx('sticky-cell', classes.scoreReviewTitle)}>
                    <Typography variant='body2' align="left">{t(sk.label)}</Typography>
                  </TableCell>
                  {samples.map(sample =>
                    <TableCell key={`${sk.key}${sample.id}`} className={classes.borderDashed} sx={{p: 0}}>
                      {sk.key === 'roastLevel' && renderRoastLevelItem(sample.selectedScore[sk.key], true)}

                      <Box className={classes.scoreReviewContent} justifyContent="normal">
                        {sk.descriptors &&
                          (isEmpty(sample.selectedScore[`${sk.key}Descriptors`]) ?
                            <FilterListOff color='disabled' sx={{mr: 1}}/> :
                            <FilterList color='primary' sx={{mr: 1}}/>)}

                        <Typography
                          variant={isScaAffective ? 'caption' : 'body2'}
                          color={isScaAffective ? colors.text.o25 : 'primary'}
                          align='center'
                          sx={{py: 0.5, width: '50%'}}
                        >
                          {sk.hasOwnProperty('descriptive') && isScaAffective && 'N/A'}
                          {sk.hasOwnProperty('descriptive') && !isScaAffective && sample.selectedScore[sk.keyIntensity]}
                        </Typography>
                        <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
                        <Typography
                          variant={isScaDescriptive ? 'caption' : 'body2'}
                          color={isScaDescriptive ? colors.text.o25 : 'primary'}
                          align='center'
                          sx={{py: 0.5, width: '50%'}}
                        >
                          {isScaDescriptive ? 'N/A' : sample.selectedScore[sk.keyScore || sk.key]}
                        </Typography>
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              )}

              <TableRow>
                <TableCell className={clsx('sticky-cell', classes.scoreReviewTitle)}>
                  <Typography variant='subtitle1' align="left">{t('sampleScore.total')}</Typography>
                </TableCell>
                {samples.map(sample =>
                  <TableCell key={`totalScore${sample.id}`} align='center' className={classes.borderDashed}>
                    <Typography
                      variant='subtitle1'
                      color={cuppingProtocol === 'sca_descriptive' ? colors.text.o25 : 'primary'}
                    >
                      {cuppingProtocol === 'sca_descriptive' ? 'N/A' : sample.selectedScore.totalScore}
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer sx={{ maxWidth: '100%' }}>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell className='sticky-cell' align="center" sx={{minWidth: 175, py: 1}}>
                  <Typography variant='h6'>{t('sampleScore.sample')}</Typography>
                </TableCell>
                {samples.map(sample =>
                  <TableCell key={sample.id} align="center" sx={{minWidth: 175}}>
                    <Typography variant='h6'>{sample.sampleId}</Typography>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {scoreKeys.map(sk =>
                <TableRow key={sk.key}>
                  <TableCell className={clsx('sticky-cell', classes.scoreReviewTitle)}>
                    <Typography variant='body2'>{t(sk.label)}</Typography>
                  </TableCell>
                  {samples.map(sample =>
                    <TableCell key={`${sk.key}${sample.id}`} className={classes.borderDashed}>
                      {sk.key === 'roastLevel' && renderRoastLevelItem(sample.selectedScore[sk.key], true)}

                      <Box className={classes.scoreReviewContent}>
                        {sk.descriptors &&
                          (isEmpty(sample.selectedScore[`${sk.key}Descriptors`]) ?
                            <FilterListOff color='disabled' sx={{mr: 1}}/> :
                            <FilterList color='primary' sx={{mr: 1}}/>)
                        }
                        {!isNull(sample.selectedScore[sk.key]) && sk.key === 'interestStatus' && t(`options.boolean.${sample.selectedScore[sk.key]}`)}

                        {!isUndefined(sk.score) && sk.key !== 'roastLevel' &&
                          <Typography variant='body2' color='primary'>
                            {sample.selectedScore[includes(sample.selectedScore.unscoreKeys, sk.key) ? `total${upperFirst(sk.key)}`: sk.key]}
                          </Typography>
                        }
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              )}

              <TableRow>
                <TableCell className={clsx('sticky-cell', classes.scoreReviewTitle)}>
                  <Typography variant='subtitle1'>{t('sampleScore.totalScore')}</Typography>
                </TableCell>
                {samples.map(sample =>
                  <TableCell key={`totalScore${sample.id}`} align='center' className={classes.borderDashed}>
                    <Typography variant='subtitle1' color='primary'>{sample.selectedScore.totalScore}</Typography>
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Box className={classes.totalScoreWrapper} sx={{my: 3}}>
        <Button variant='outlined' sx={{mr: 2}} onClick={() => setOpenReview(false)}>
          {t('common.return')
        }</Button>
        <Button
          variant='contained'
          sx={{mr: 2}}
          onClick={() => {
            setOpenReview(false)
            setOpenConfirm(true)
          }}
        >
          {t('sampleScore.submitAndFinish')}
          </Button>
      </Box>
    </ModalWrapper>
  )

  const renderButtons = (
    <Box sx={{mb: 5, textAlign: 'right'}} className={clsx(isMobile && classes.buttonMobileWrapper)}>
      <Button variant='outlined' onClick={() => resetScore(id)}>
        {t('common.discard')}
      </Button>
      <Button variant='contained' onClick={() => checkTotalScore()} sx={{ml: 1}}>
        {t('sampleScore.reviewAndSubmit')}
      </Button>
    </Box>
  )

  React.useEffect(() => {
    if (!guideScore && isGuideScore) navigate(homeLink)
  }, [guideScore])

  React.useEffect(() => { setTab(toString(id)) }, [id])

  React.useEffect(() => {
    if (!selectedCuppingSession?.canNotCupNow) return

    setErrors()
   }, [selectedCuppingSession?.canNotCupNow])

  React.useEffect(() => {
    setScoreValue('totalScore', calculateTotalScore)
  }, [selectedSample])

  React.useEffect(() => {
    setScore()
    if (!isAuthenticated || selectedCuppingSession) return

    setErrors()
  }, [])

  if (!selectedCuppingSession) return null

  return (
    <Box>
      <HeaderTitle
        {...(isAuthenticated && {
          backUrl: cuppingSessionLink,
          breadcrumb: t('common.backTo', { menu: t('menu.cuppingSessions')})
        })}
        title={`${t('sampleScore.cuppingSession')} #${selectedCuppingSession?.numberForCompany}`}
        subtitle={`${t('sampleScore.currentlyUsingForm', { protocol: t(`options.cuppingProtocol.${camelCase(selectedCuppingSession?.cuppingProtocol)}`) })}`}
        {...(!cuppingProtocol.includes('sca') && {rightContent: <ScoreTimer closeTooltip={samplesCupped > 0} />})}
      />

      {toString(id) === tab &&
        <Container>
          <TabContext value={tab}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={!showRoastLevel ? 12 : 8}>
                <TabList
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  onChange={(event, value) => {
                    setSampleValue('selectedSample', value)
                    setTab(value)
                  }}
                  className={classes.tabWrapper}
                >
                  {samples.map(sample =>
                    <Tab key={sample.id} value={toString(sample.id)} label={sample.sampleId} className={classes.tabHeader} />
                  )}
                </TabList>

                {renderTitleItem}
              </Grid>
              {showRoastLevel &&
                <Grid item xs={12} md={4} sx={{display: 'flex', flexDirection: 'column'}}>
                  {renderRoastLevel}
                </Grid>
              }
            </Grid>

            {renderTabItem}
          </TabContext>

          {isMobile ? <Portal>{renderButtons}</Portal> : renderButtons}
        </Container>
      }

      {renderReview}

      <ModalConfirm
        open={openConfirm}
        description={t('sampleScore.confirmSubmitScoreDescription')}
        confirm={t('common.confirm')}
        cancel={t('common.cancel')}
        onClose={() => {
          setOpenConfirm(false)
          setOpenReview(true)
        }}
        onConfirm={onSaveScores}
      />

      <ModalMessage
        open={openMessage}
        description={t('sampleScore.success.cuppingScoreSubmitted')}
        buttonText={t('sampleScore.continue')}
        onSubmit={onContinue}
      />
      <ModalWrapper open={openNps} maxWidth='sm'>
        <Alert severity="success" sx={{mt: 1, mb: 3}}>
          {t('sampleScore.success.cuppingScoreSubmitted')}
        </Alert>

        <Typography fontWeight={600} variant="body2" sx={{mb: .5}}>
          {t('abTest.cuppingSession.npsQuestion')}
        </Typography>
        <Rating
          max={10}
          value={nps}
          onChange={(event, newValue) => {
            setNpsValue('sca', 'nps', newValue)
          }}
        />
        <FormInput
          label={t('abTest.cuppingSession.descriptorQuestion')}
          textFieldProps={{
            multiline: true,
            rows: 3,
            value: descriptor,
            onChange: e => setNpsValue('sca', 'descriptor', e.target.value)
          }}
        />
        <FormInput
          label={t('abTest.cuppingSession.otherQuestion')}
          textFieldProps={{
            multiline: true,
            rows: 3,
            value: other,
            onChange: e => setNpsValue('sca', 'other', e.target.value)
          }}
        />

        <Box display="flex" alignItems="center" justifyContent="center" sx={{mt: 2}}>
          <Button variant='outlined' onClick={() => sendNpsAction(false)} sx={{width: 200, mr: 1}}>
            {t('abTest.skip')}
          </Button>
          <Button variant='contained' color='primary' onClick={() => sendNpsAction()} sx={{width: 200, ml: 1}}>
            {t('sampleScore.continue')}
          </Button>
        </Box>
      </ModalWrapper>

      <ModalWrapper open={openWarning} maxWidth='sm'>
        <Box className={classes.messageWarning} sx={{mt: 2}}>
          <Warning sx={{ fontSize: 80, color: colors.primary.main }}/>
          <Typography fontWeight="bold" variant="body2" sx={{color: colors.primary.main}}>
            {t('sampleScore.warning')}
          </Typography>
        </Box>

        <Box className={classes.messageWarning} sx={{mt: 3, textAlign: 'center', mb: 5}}>
          <Typography fontWeight={600} variant="h5">
            {ReactHtmlParser(t('sampleScore.messageWarning'))}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="center" sx={{mt: 2}}>
          <Button variant='outlined' onClick={() => setOpenWarning(false)} sx={{width: 200, mr: 1}}>
            {t('sampleScore.cancel')}
          </Button>
          <Button
              variant='contained'
              color='primary'
              onClick={() => {
                setOpenWarning(false)
                setOpenReview(true)
              }}
              sx={{width: 200, ml: 1}}>
            {t('sampleScore.continue')}
          </Button>
        </Box>
      </ModalWrapper>

      <ModalMessage
        type="error"
        open={openError}
        description={t('sampleScore.error.cuppingScoreCancelled')}
        buttonText={t('sampleScore.tryAgain')}
        onSubmit={() => setOpenError(false)}
      />
    </Box>
  )
})
