export const balanceDescriptorList = [{
	"id": 162,
	"label": "wild",
	"color": "#E9B6E9",
	"parent_id": null,
	"label_es": "silvestre",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "selvagem",
	"label_zh-CN": "狂野",
	"weight": 1.15,
	"label_fr-CA": "sharp",
	"label_ko": "거친",
	"label_id": "liar",
	"label_ja": "ワイルド・荒い"
}, {
	"id": 163,
	"label": "unbalanced",
	"color": "#E9B6E9",
	"parent_id": null,
	"label_es": "desbalanceado",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "desbalanceado",
	"label_zh-CN": "不平衡",
	"weight": 1.1,
	"label_fr-CA": "instable",
	"label_ko": "불균형한",
	"label_id": "tidak imbang",
	"label_ja": "かたよった"
}, {
	"id": 164,
	"label": "sharp",
	"color": "#E9B6E9",
	"parent_id": null,
	"label_es": "penetrante",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "agudo",
	"label_zh-CN": "尖酸",
	"weight": 1.18,
	"label_fr-CA": "piquant",
	"label_ko": "톡 쏘는 듯한",
	"label_id": "tajam",
	"label_ja": "とげっぽい、とげのある味"
}, {
	"id": 165,
	"label": "pointed",
	"color": "#E9B6E9",
	"parent_id": null,
	"label_es": "mordaz",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "áspero",
	"label_zh-CN": "喧宾夺主",
	"weight": 1.13,
	"label_fr-CA": "pointu",
	"label_ko": "뾰족한",
	"label_id": "lancip",
	"label_ja": "尖った"
}, {
	"id": 166,
	"label": "dense",
	"color": "#E46ED3",
	"parent_id": null,
	"label_es": "dense",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "denso",
	"label_zh-CN": "稠密",
	"weight": 1.17,
	"label_fr-CA": "dense",
	"label_ko": "농밀한",
	"label_id": "tebal",
	"label_ja": "濃い"
}, {
	"id": 167,
	"label": "deep",
	"color": "#E46ED3",
	"parent_id": null,
	"label_es": "espeso",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "espesso",
	"label_zh-CN": "深厚",
	"weight": 1.22,
	"label_fr-CA": "profond",
	"label_ko": "깊은",
	"label_id": "dalam",
	"label_ja": "深い"
}, {
	"id": 168,
	"label": "complex",
	"color": "#E46ED3",
	"parent_id": null,
	"label_es": "complejo",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "complexo",
	"label_zh-CN": "复杂",
	"weight": 1.27,
	"label_fr-CA": "complexe",
	"label_ko": "복합적인",
	"label_id": "kompleks",
	"label_ja": "複雑な"
}, {
	"id": 169,
	"label": "juicy",
	"color": "#F8A953",
	"parent_id": null,
	"label_es": "jugoso",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "suculento",
	"label_zh-CN": "多汁",
	"weight": 1.0,
	"label_fr-CA": "juteux",
	"label_ko": "주스 같은",
	"label_id": "juicy",
	"label_ja": "ジューシー"
}, {
	"id": 175,
	"label": "structured",
	"color": "#F8A953",
	"parent_id": null,
	"label_es": "estructurado",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "estruturado",
	"label_zh-CN": "有序",
	"weight": 1.23,
	"label_fr-CA": "balancé",
	"label_ko": "구조가 잘 잡힌",
	"label_id": "terstruktur",
	"label_ja": "構造がある"
}, {
	"id": 176,
	"label": "balanced",
	"color": "#F8A953",
	"parent_id": null,
	"label_es": "balanceado",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "balanceado",
	"label_zh-CN": "平衡",
	"weight": 1.21,
	"label_fr-CA": "équilibré",
	"label_ko": "균형이 잡힌",
	"label_id": "seimbang",
	"label_ja": "バランスがある"
}, {
	"id": 177,
	"label": "rounded",
	"color": "#F8A953",
	"parent_id": null,
	"label_es": "redondeado",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "redondo",
	"label_zh-CN": "圆满",
	"weight": 1.24,
	"label_fr-CA": "rond",
	"label_ko": "둥근",
	"label_id": "bulat",
	"label_ja": "丸みがある"
}, {
	"id": 238,
	"label": "fresh",
	"color": "#E9B6E9",
	"parent_id": null,
	"label_es": "fresco",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "fresco",
	"label_zh-CN": "新鲜",
	"weight": 1.17,
	"label_fr-CA": "frais",
	"label_ko": "신선한",
	"label_id": "fresh",
	"label_ja": "新鮮な"
}, {
	"id": 239,
	"label": "inconsistent",
	"color": "#E9B6E9",
	"parent_id": null,
	"label_es": "inconsistente",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "inconsistente",
	"label_zh-CN": "不一致",
	"weight": 1.12,
	"label_fr-CA": "constant",
	"label_ko": "너무 자주 변하는",
	"label_id": "tidak konsisten",
	"label_ja": "一貫性がない"
}, {
	"id": 241,
	"label": "bold",
	"color": "#E46ED3",
	"parent_id": null,
	"label_es": "acentuado",
	"open": 1,
	"zoomed": 0,
	"selected": false,
	"flavor_type": "intensifier",
	"positive": 1,
	"label_pt": "vigoroso",
	"label_zh-CN": "明显的",
	"weight": 1.18,
	"label_fr-CA": "intense",
	"label_ko": "선명한",
	"label_id": "mencolok/tegas",
	"label_ja": "大胆な"
}]