/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-03 20:13:36
 * @modify date 2022-04-14 20:44:22
 */

import React from 'react'
import {isEmpty, camelCase, assign, toString, isNull} from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import { TabPanel, LoadingButton } from '@mui/lab'
import {
  Box,
  Grid,
  Button,
  Typography,
  Select,
  MenuItem,
  Avatar,
  Stack,
  Divider,
  FormControlLabel,
  Radio,
  FormHelperText
} from '@mui/material'

import { useStores } from 'models'
import { companyLogo } from 'assets/images'
import { globalAction, countries, errorField } from 'utils'
import { FormInput, ModalConfirm } from 'components'
import { companyTypes } from "constants/form"

import { verticalCentered } from 'assets'

export const Company: React.FC = observer(() => {
  const { t } = useTranslation()
  const {
    userStore: { adminPrivileges, timeZone, role, isQgrader, isCompanyAdmin, name: fullName },
    companyStore: { name, countryCode, logo, saveCompany, uploadLogo, removeLogo, companyType, setValue: setCompanyValue },
    modalStore: { setValue: setModalValue },
    notificationStore
  } = useStores()
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [errors, setErrors] = React.useState<any>({})
  const [errorCountry, setErrorCountry] = React.useState(false)

  const {getRootProps, getInputProps} = useDropzone({
    maxFiles: 1,
    maxSize: 5242880,
    accept: 'image/png',
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {
      if (!isEmpty(fileRejections)) {
        const file = fileRejections[0]
        file.errors.forEach(error => {
          notificationStore.setNotification({
            severity: 'error',
            message: error.code === "file-too-large" ?
                      t(`error.${camelCase(error.code)}`, {maxSize: '5 MB'}) :
                      error.message
          })
        })

        return
      }

      const tempLogo = acceptedFiles[0]
      const url = URL.createObjectURL(tempLogo)
      Object.assign(tempLogo, {
        preview: url
      })

      setCompanyValue('logo', url)
      onUploadLogo(tempLogo)
    }
  })

  const validations = () => {
    setErrors({})
    let validationErrors = {}

    if (!name) {
      assign(validationErrors, {
        "name": [{ message: t('company.error.fieldNameCompanyEmpty') }]
      })
    }
    if (!countryCode) {
      assign(validationErrors, {
        "countryCode": [{ message: t('company.error.fieldCountryEmpty') }]
      })
      setErrorCountry(true)
    }
    if (isEmpty(companyType)) {
      assign(validationErrors, {
        "profileCompany": [{ message: t('company.error.fieldCompanyProfileEmpty') }]
      })

    }

    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors)
      return false
    }

    return true
  }

  const onUploadLogo = (newLogo) => {
    globalAction(notificationStore, {
      action: async () => await uploadLogo(newLogo),
    })
  }

  const onRemoveLogo = () => {
    globalAction(notificationStore, {
      action: removeLogo,
      afterAction: () => setOpenConfirm(false)
    })
  }

  const onUpdateCompany = () => {

    if (!validations()) return

    globalAction(notificationStore, {
      action: saveCompany,
      afterAction: () => {
        if (isCompanyAdmin && (isEmpty(timeZone) || isEmpty(role) || isNull(isQgrader) || !fullName)) {
          setModalValue('settingTab', "profile")
        }
      },
      setErrors: setErrors
    })
  }

  return (
    <TabPanel value="company">
      <Box sx={{...verticalCentered, mb: 1}}>
        <Avatar alt={name} src={logo ? logo : companyLogo} sx={{width: 70, height: 70}} />
        <Box sx={{ml: 4}}>
          <Stack direction="row" spacing={2} sx={{mb: 2}}>
            <Box {...getRootProps()}>
              <input {...getInputProps()} />
              <Button
                color='primary'
                className='btn-text'
              >
                {t('company.uploadCompanyLogo')}
              </Button>
            </Box>
            {logo &&
              <>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Button
                  color='error'
                  className='btn-text'
                  onClick={() => setOpenConfirm(true)}
                >
                  {t('company.removeLogo')}
                </Button>
              </>
            }
          </Stack>
          <Typography>{t('company.companyLogoDescription')}</Typography>
          <Typography variant='caption' paragraph>
            {t('company.uploadImageTypesDescription', {max: 5})}
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2} rowSpacing={3} sx={{mb: 3}}>
        <Grid item xs={12} sm={6}>
          <FormInput
            label={t('company.companyName')}
            textFieldProps={{
              placeholder: "E.G. Tastify Company",
              value: name,
              onChange: (e) => setCompanyValue('name', e.target.value),
              ...errorField('name', name, errors)
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInput label={t('company.country')}>
            <Select
              fullWidth
              size='small'
              value={countryCode}
              onChange={event => {
                setCompanyValue('countryCode', event.target.value)
                setErrorCountry(false)
              }}
              error={errorCountry}

            >
              {countries.map(item => <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>)}
            </Select>
            {errorCountry && <FormHelperText sx={{color: '#DE8282', fontStyle: 'italic'}}>{t('company.error.fieldCountryEmpty')}</FormHelperText>}
          </FormInput>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{mb: 1}}>{t('company.doesYourOrganizationDo')}</Typography>
          <Grid container spacing={1}>
            {companyTypes.map(org =>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  key={org.key}
                  value={org.key}
                  control={<Radio color="primary" />}
                  label={toString(t(org.label))}
                  labelPlacement="end"
                  checked={org.key === companyType}
                  onChange={e => setCompanyValue('companyType', (e.target as HTMLInputElement).value)}
                />
              </Grid>
            )}

            {(isEmpty(companyType)) && <FormHelperText sx={{color: '#DE8282', fontStyle: 'italic'}}>{t('company.error.fieldCompanyProfileEmpty')}</FormHelperText>}
          </Grid>
        </Grid>
      </Grid>

      <LoadingButton
        fullWidth
        size='large'
        variant="contained"
        onClick={onUpdateCompany}
        disabled={!adminPrivileges}
      >
        {t('company.saveChanges')}
      </LoadingButton>

      <ModalConfirm
        open={openConfirm}
        confirm={t('company.delete')}
        cancel={t('company.cancel')}
        title={t('company.removeLogo')}
        description={t('company.deleteLogoDescription')}
        onClose={() => setOpenConfirm(false)}
        onConfirm={onRemoveLogo}
      />
    </TabPanel>
  )
})
