/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-28 20:22:31
 * @modify date 2022-02-28 20:22:31
 */

import React from 'react'
import { isNull, toNumber } from "lodash"
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Card, Grid, Typography, Stack, Button, TextField, Box } from '@mui/material'

import { useStores } from 'models'
import { acidityDescriptorList } from 'constants/acidity-descriptors'
import { bodyDescriptorList } from 'constants/body-descriptors'
import { flavorDescriptorList } from 'constants/flavor-descriptors'
import { defectDescriptorList } from 'constants/defect-descriptors'
import { Descriptor } from "components"

export const Commercial: React.FC = observer(() => {
  const { t } = useTranslation()

  const { sampleStore: {
    selectedSample: { sampleId, selectedScore: {
      flavor, flavorDescriptors, flavorIntensity,
      acidity, acidityDescriptors, acidityIntensity,
      body, bodyDescriptors, bodyIntensity,
      uniformityDetected, defectDetected, defectsDescriptors, notes, cups,
      sweetnessScore, sweetnessIntensity, sweetnessDescriptors,
      addingScore, decreaseScore, setScoreValue
    }}
  } } = useStores()

  const factorySlides = { step: 0.5, min: 1, max: 3 }

  const flavorSlides = [
    {
      ...factorySlides,
      label: t('descriptor.flavorIntensity'),
      value: flavorIntensity,
      onChange: (e) => setScoreValue('flavorIntensity', e.target.value)
    }
  ]

  const bodySlides = [
    {
      ...factorySlides,
      label: t('descriptor.bodyLevel'),
      value: bodyIntensity,
      onChange: (e) => setScoreValue('bodyIntensity', e.target.value)
    }
  ]

  const aciditySlides = [
    {
      ...factorySlides,
      label: t('descriptor.acidityIntensity'),
      value: acidityIntensity,
      onChange: (e) => setScoreValue('acidityIntensity', e.target.value)
    }
  ]

  const sweetnessSlides = [
    {
      ...factorySlides,
      label: t('descriptor.sweetnessIntensity'),
      value: sweetnessIntensity,
      onChange: (e) => setScoreValue('sweetnessIntensity', e.target.value)
    }
  ]

  const boxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px'
  }

  const handleChange = (e) => {
    let value = toNumber(e.target.value)

    if (isNaN(value)) value = 1
    if (value < 1) value = 1
    if (value > 5) value = 5

    setScoreValue('cups', value)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`flavor${flavorDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.flavor')}
          descriptors={flavorDescriptors}
          descriptorList={flavorDescriptorList}
          addDescriptor={(values) => setScoreValue('flavorDescriptors', values)}
          slides={flavorSlides}
          score={flavor}
          addScore={() => addingScore(flavor, 'flavor')}
          decreaseScore={() => decreaseScore(flavor, 'flavor')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`body${bodyDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.body')}
          descriptors={bodyDescriptors}
          descriptorList={bodyDescriptorList}
          addDescriptor={(values) => setScoreValue('bodyDescriptors', values)}
          slides={bodySlides}
          score={body}
          addScore={() => addingScore(body, 'body')}
          decreaseScore={() => decreaseScore(body, 'body')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`acidity${acidityDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.acidity')}
          descriptors={acidityDescriptors}
          descriptorList={acidityDescriptorList}
          addDescriptor={(values) => setScoreValue('acidityDescriptors', values)}
          slides={aciditySlides}
          score={acidity}
          addScore={() => addingScore(acidity, 'acidity')}
          decreaseScore={() => decreaseScore(acidity, 'acidity')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`sweetness${sweetnessDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.sweetness')}
          descriptors={sweetnessDescriptors}
          descriptorList={flavorDescriptorList}
          addDescriptor={(values) => setScoreValue('sweetnessDescriptors', values)}
          slides={sweetnessSlides}
          score={sweetnessScore}
          addScore={() => addingScore(sweetnessScore, 'sweetnessScore')}
          decreaseScore={() => decreaseScore(sweetnessScore, 'sweetnessScore')}
        />
      </Grid>
      <Grid item xs={12} md={4} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
        <Card sx={boxStyle}>
          <Typography variant='subtitle1'>{t('descriptor.uniformity')}</Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant={uniformityDetected ? 'contained' : 'outlined'}
              onClick={() => setScoreValue('uniformityDetected', true)}
            >
              {t('options.boolean.true')}
            </Button>
            <Button
              variant={!isNull(uniformityDetected) && !uniformityDetected ? 'contained' : 'outlined'}
              onClick={() => setScoreValue('uniformityDetected', false)}
            >
              {t('options.boolean.false')}
            </Button>
          </Stack>
        </Card>
        <Card sx={{...boxStyle, flexDirection: 'column'}}>
          <Stack spacing={2} sx={{width: '100%'}}>
            <Box sx={{...boxStyle, p: 0}}>
              <Typography variant='subtitle1'>{t('descriptor.defectDetected')}</Typography>
              <Stack direction="row" spacing={2}>
                <Button
                  variant={defectDetected ? 'contained' : 'outlined'}
                  onClick={() => setScoreValue('defectDetected', true)}
                >
                  {t('options.boolean.true')}
                </Button>
                <Button
                  variant={!isNull(defectDetected) && !defectDetected ? 'contained' : 'outlined'}
                  onClick={() => setScoreValue('defectDetected', false)}
                >
                  {t('options.boolean.false')}
                </Button>
              </Stack>
            </Box>
            <Box sx={{...boxStyle, p: 0}}>
              <Typography variant='subtitle1'>{t('descriptor.numberOfCups')}</Typography>
              <TextField
                type='tel'
                size='small'
                inputMode='numeric'
                inputProps={{ min: "1", max: "5", pattern: '[0-9]*', size: "1" }}
                value={cups}
                onChange={handleChange}
                sx={{maxWidth: 100}}
              />
            </Box>
            <Descriptor
              key={`defects${defectsDescriptors}`}
              sampleId={sampleId}
              title={t('descriptor.defects')}
              descriptors={defectsDescriptors}
              descriptorList={defectDescriptorList}
              addDescriptor={(values) => setScoreValue('defectsDescriptors', values)}
            />
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card sx={{px: 4, py: 3, height: '100%'}}>
          <Typography variant='h6' sx={{mb: 2}}>{t('descriptor.notes')}</Typography>
          <TextField
            fullWidth
            multiline
            minRows={12}
            value={notes}
            onChange={(e) => setScoreValue('notes', e.target.value)}
          />
        </Card>
      </Grid>
    </Grid>
  )
})
