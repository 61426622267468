export const timezones = [
  { value: "International Date Line West", label: "(GMT-12:00) International Date Line West" },
  { value: "American Samoa", label: "(GMT-11:00) American Samoa" },
  { value: "Midway Island", label: "(GMT-11:00) Midway Island" },
  { value: "Hawaii", label: "(GMT-10:00) Hawaii" },
  { value: "Alaska", label: "(GMT-09:00) Alaska" },
  { value: "Pacific Time (US & Canada)", label: "(GMT-08:00) Pacific Time (US & Canada)" },
  { value: "Tijuana", label: "(GMT-08:00) Tijuana" },
  { value: "Arizona", label: "(GMT-07:00) Arizona" },
  { value: "Chihuahua", label: "(GMT-07:00) Chihuahua" },
  { value: "Mazatlan", label: "(GMT-07:00) Mazatlan" },
  { value: "Mountain Time (US & Canada)", label: "(GMT-07:00) Mountain Time (US & Canada)" },
  { value: "Central America", label: "(GMT-06:00) Central America" },
  { value: "Central Time (US & Canada)", label: "(GMT-06:00) Central Time (US & Canada)" },
  { value: "Guadalajara", label: "(GMT-06:00) Guadalajara" },
  { value: "Mexico City", label: "(GMT-06:00) Mexico City" },
  { value: "Monterrey", label: "(GMT-06:00) Monterrey" },
  { value: "Saskatchewan", label: "(GMT-06:00) Saskatchewan" },
  { value: "Bogota", label: "(GMT-05:00) Bogota" },
  { value: "Eastern Time (US & Canada)", label: "(GMT-05:00) Eastern Time (US & Canada)" },
  { value: "Indiana (East)", label: "(GMT-05:00) Indiana (East)" },
  { value: "Lima", label: "(GMT-05:00) Lima" },
  { value: "Quito", label: "(GMT-05:00) Quito" },
  { value: "Atlantic Time (Canada)", label: "(GMT-04:00) Atlantic Time (Canada)" },
  { value: "Caracas", label: "(GMT-04:00) Caracas" },
  { value: "Georgetown", label: "(GMT-04:00) Georgetown" },
  { value: "La Paz", label: "(GMT-04:00) La Paz" },
  { value: "Puerto Rico", label: "(GMT-04:00) Puerto Rico" },
  { value: "Santiago", label: "(GMT-04:00) Santiago" },
  { value: "Newfoundland", label: "(GMT-03:30) Newfoundland" },
  { value: "Brasilia", label: "(GMT-03:00) Brasilia" },
  { value: "Buenos Aires", label: "(GMT-03:00) Buenos Aires" },
  { value: "Greenland", label: "(GMT-03:00) Greenland" },
  { value: "Montevideo", label: "(GMT-03:00) Montevideo" },
  { value: "Mid-Atlantic", label: "(GMT-02:00) Mid-Atlantic" },
  { value: "Azores", label: "(GMT-01:00) Azores" },
  { value: "Cape Verde Is.", label: "(GMT-01:00) Cape Verde Is." },
  { value: "Edinburgh", label: "(GMT+00:00) Edinburgh" },
  { value: "Lisbon", label: "(GMT+00:00) Lisbon" },
  { value: "London", label: "(GMT+00:00) London" },
  { value: "Monrovia", label: "(GMT+00:00) Monrovia" },
  { value: "UTC", label: "(GMT+00:00) UTC" },
  { value: "Amsterdam", label: "(GMT+01:00) Amsterdam" },
  { value: "Belgrade", label: "(GMT+01:00) Belgrade" },
  { value: "Berlin", label: "(GMT+01:00) Berlin" },
  { value: "Bern", label: "(GMT+01:00) Bern" },
  { value: "Bratislava", label: "(GMT+01:00) Bratislava" },
  { value: "Brussels", label: "(GMT+01:00) Brussels" },
  { value: "Budapest", label: "(GMT+01:00) Budapest" },
  { value: "Casablanca", label: "(GMT+01:00) Casablanca" },
  { value: "Copenhagen", label: "(GMT+01:00) Copenhagen" },
  { value: "Dublin", label: "(GMT+01:00) Dublin" },
  { value: "Ljubljana", label: "(GMT+01:00) Ljubljana" },
  { value: "Madrid", label: "(GMT+01:00) Madrid" },
  { value: "Paris", label: "(GMT+01:00) Paris" },
  { value: "Prague", label: "(GMT+01:00) Prague" },
  { value: "Rome", label: "(GMT+01:00) Rome" },
  { value: "Sarajevo", label: "(GMT+01:00) Sarajevo" },
  { value: "Skopje", label: "(GMT+01:00) Skopje" },
  { value: "Stockholm", label: "(GMT+01:00) Stockholm" },
  { value: "Vienna", label: "(GMT+01:00) Vienna" },
  { value: "Warsaw", label: "(GMT+01:00) Warsaw" },
  { value: "West Central Africa", label: "(GMT+01:00) West Central Africa" },
  { value: "Zagreb", label: "(GMT+01:00) Zagreb" },
  { value: "Zurich", label: "(GMT+01:00) Zurich" },
  { value: "Athens", label: "(GMT+02:00) Athens" },
  { value: "Bucharest", label: "(GMT+02:00) Bucharest" },
  { value: "Cairo", label: "(GMT+02:00) Cairo" },
  { value: "Harare", label: "(GMT+02:00) Harare" },
  { value: "Helsinki", label: "(GMT+02:00) Helsinki" },
  { value: "Jerusalem", label: "(GMT+02:00) Jerusalem" },
  { value: "Kaliningrad", label: "(GMT+02:00) Kaliningrad" },
  { value: "Kyiv", label: "(GMT+02:00) Kyiv" },
  { value: "Pretoria", label: "(GMT+02:00) Pretoria" },
  { value: "Riga", label: "(GMT+02:00) Riga" },
  { value: "Sofia", label: "(GMT+02:00) Sofia" },
  { value: "Tallinn", label: "(GMT+02:00) Tallinn" },
  { value: "Vilnius", label: "(GMT+02:00) Vilnius" },
  { value: "Baghdad", label: "(GMT+03:00) Baghdad" },
  { value: "Istanbul", label: "(GMT+03:00) Istanbul" },
  { value: "Kuwait", label: "(GMT+03:00) Kuwait" },
  { value: "Minsk", label: "(GMT+03:00) Minsk" },
  { value: "Moscow", label: "(GMT+03:00) Moscow" },
  { value: "Nairobi", label: "(GMT+03:00) Nairobi" },
  { value: "Riyadh", label: "(GMT+03:00) Riyadh" },
  { value: "St. Petersburg", label: "(GMT+03:00) St. Petersburg" },
  { value: "Volgograd", label: "(GMT+03:00) Volgograd" },
  { value: "Tehran", label: "(GMT+03:30) Tehran" },
  { value: "Abu Dhabi", label: "(GMT+04:00) Abu Dhabi" },
  { value: "Baku", label: "(GMT+04:00) Baku" },
  { value: "Muscat", label: "(GMT+04:00) Muscat" },
  { value: "Samara", label: "(GMT+04:00) Samara" },
  { value: "Tbilisi", label: "(GMT+04:00) Tbilisi" },
  { value: "Yerevan", label: "(GMT+04:00) Yerevan" },
  { value: "Kabul", label: "(GMT+04:30) Kabul" },
  { value: "Ekaterinburg", label: "(GMT+05:00) Ekaterinburg" },
  { value: "Islamabad", label: "(GMT+05:00) Islamabad" },
  { value: "Karachi", label: "(GMT+05:00) Karachi" },
  { value: "Tashkent", label: "(GMT+05:00) Tashkent" },
  { value: "Chennai", label: "(GMT+05:30) Chennai" },
  { value: "Kolkata", label: "(GMT+05:30) Kolkata" },
  { value: "Mumbai", label: "(GMT+05:30) Mumbai" },
  { value: "New Delhi", label: "(GMT+05:30) New Delhi" },
  { value: "Sri Jayawardenepura", label: "(GMT+05:30) Sri Jayawardenepura" },
  { value: "Kathmandu", label: "(GMT+05:45) Kathmandu" },
  { value: "Almaty", label: "(GMT+06:00) Almaty" },
  { value: "Astana", label: "(GMT+06:00) Astana" },
  { value: "Dhaka", label: "(GMT+06:00) Dhaka" },
  { value: "Urumqi", label: "(GMT+06:00) Urumqi" },
  { value: "Rangoon", label: "(GMT+06:30) Rangoon" },
  { value: "Bangkok", label: "(GMT+07:00) Bangkok" },
  { value: "Hanoi", label: "(GMT+07:00) Hanoi" },
  { value: "Jakarta", label: "(GMT+07:00) Jakarta" },
  { value: "Krasnoyarsk", label: "(GMT+07:00) Krasnoyarsk" },
  { value: "Novosibirsk", label: "(GMT+07:00) Novosibirsk" },
  { value: "Beijing", label: "(GMT+08:00) Beijing" },
  { value: "Chongqing", label: "(GMT+08:00) Chongqing" },
  { value: "Hong Kong", label: "(GMT+08:00) Hong Kong" },
  { value: "Irkutsk", label: "(GMT+08:00) Irkutsk" },
  { value: "Kuala Lumpur", label: "(GMT+08:00) Kuala Lumpur" },
  { value: "Perth", label: "(GMT+08:00) Perth" },
  { value: "Singapore", label: "(GMT+08:00) Singapore" },
  { value: "Taipei", label: "(GMT+08:00) Taipei" },
  { value: "Ulaanbaatar", label: "(GMT+08:00) Ulaanbaatar" },
  { value: "Osaka", label: "(GMT+09:00) Osaka" },
  { value: "Sapporo", label: "(GMT+09:00) Sapporo" },
  { value: "Seoul", label: "(GMT+09:00) Seoul" },
  { value: "Tokyo", label: "(GMT+09:00) Tokyo" },
  { value: "Yakutsk", label: "(GMT+09:00) Yakutsk" },
  { value: "Adelaide", label: "(GMT+09:30) Adelaide" },
  { value: "Darwin", label: "(GMT+09:30) Darwin" },
  { value: "Brisbane", label: "(GMT+10:00) Brisbane" },
  { value: "Canberra", label: "(GMT+10:00) Canberra" },
  { value: "Guam", label: "(GMT+10:00) Guam" },
  { value: "Hobart", label: "(GMT+10:00) Hobart" },
  { value: "Melbourne", label: "(GMT+10:00) Melbourne" },
  { value: "Port Moresby", label: "(GMT+10:00) Port Moresby" },
  { value: "Sydney", label: "(GMT+10:00) Sydney" },
  { value: "Vladivostok", label: "(GMT+10:00) Vladivostok" },
  { value: "Magadan", label: "(GMT+11:00) Magadan" },
  { value: "New Caledonia", label: "(GMT+11:00) New Caledonia" },
  { value: "Solomon Is.", label: "(GMT+11:00) Solomon Is." },
  { value: "Srednekolymsk", label: "(GMT+11:00) Srednekolymsk" },
  { value: "Auckland", label: "(GMT+12:00) Auckland" },
  { value: "Fiji", label: "(GMT+12:00) Fiji" },
  { value: "Kamchatka", label: "(GMT+12:00) Kamchatka" },
  { value: "Marshall Is.", label: "(GMT+12:00) Marshall Is." },
  { value: "Wellington", label: "(GMT+12:00) Wellington" },
  { value: "Chatham Is.", label: "(GMT+12:45) Chatham Is." },
  { value: "Nuku'alofa", label: "(GMT+13:00) Nuku'alofa" },
  { value: "Samoa", label: "(GMT+13:00) Samoa" },
  { value: "Tokelau Is.", label: "(GMT+13:00) Tokelau Is." },
]