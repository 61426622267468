import React from 'react'
import {useLocation} from "react-router-dom";

import { APP_VERSION } from 'config/env'
import { loadString, saveString } from 'utils/storage'
import { isAuthenticateRoutes } from "constants/routes"

export const useAppVersion = (rootStore) => {
  const location = useLocation()

  React.useEffect(() => {
    if (!rootStore) return

    loadString('app_version').then(async (version) => {
      if (APP_VERSION === version) return

      rootStore.resetStoreCache()
      await saveString('app_version', APP_VERSION)
      if (!isAuthenticateRoutes(location.pathname)) window.location.href = '/'
    })
  }, [rootStore])
}
