/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import { ceil } from 'lodash'
import { Instance, SnapshotOut, types, flow, applySnapshot, getSnapshot } from "mobx-state-tree"

import {ApiResult} from "services/api"
import {
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"

import {cuppingResultProps} from "./cupping-result.props"
const DATA_PER_PAGE = 10

/**
 * Model description here for TypeScript hints.
 */
export const CuppingResultStoreModel = types
  .model("CuppingResultStore")
  .props({
    cuppingResult: types.optional(types.array(types.model(cuppingResultProps)), []),
    page: types.optional(types.number, 1),
    totalPage: types.optional(types.number, 0)
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .actions(self => ({
    getCuppingResults: flow(function * (id: string) {
      try {
        const payload: any = {
          "size": DATA_PER_PAGE,
          "page[number]": self.page,
        }

        const additionalPath = `cupping_result`
        const res: ApiResult = yield self
          .environment
          .masterSampleApi
          .find(id, additionalPath, payload)

        if (res.kind === "ok") {
          const totalPage = ceil(res.meta.pagination.records / DATA_PER_PAGE)

          applySnapshot(self, {
            ...getSnapshot(self),
            cuppingResult: res.data,
            totalPage
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
  }))

type CuppingResultStoreType = Instance<typeof CuppingResultStoreModel>
export interface CuppingResultStore extends CuppingResultStoreType {}
type CuppingResultStoreSnapshotType = SnapshotOut<typeof CuppingResultStoreModel>
export interface CuppingResultStoreSnapshot extends CuppingResultStoreSnapshotType {}
