/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-07 06:32:58
 * @modify date 2022-02-07 06:32:58
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors, verticalCentered } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: 'white',
      borderTop: `1px solid ${colors.border.primary}`,
      borderBottom: `1px solid ${colors.border.primary}`,
      padding: `${theme.spacing(4)} ${theme.spacing(5)}`,
      margin: `${theme.spacing(4)} 0`,
      [theme.breakpoints.up('md')]: {
        border: `1px solid ${colors.border.primary}`,
        borderRadius: 5
      }
    },
    toggleButton: {
      borderRadius: 99,
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    cupperWrapper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      maxHeight: 300,
      overflowY: 'auto',
      minHeight: 1
    },
    cupperItem: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      cursor: 'pointer',
      position: 'relative',
      '&.active': {
        backgroundImage: `linear-gradient(45deg, ${colors.primary.main}, ${colors.primary.o25})`,
        color: 'white',
        cursor: 'default',
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        '& p': {
          color: 'white'
        }
      }
    },
    removeCupper: {
      position: 'absolute',
      top: 0,
      right: 0
    },
    guestItem: {
      ...verticalCentered
    },
    autocomplete: {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(5)
      }
    },
    date: {
      '& svg': {
        fill: colors.primary.main
      }
    },
    sample: {
      display: 'flex',
      width: '100%',
      padding: '8px 16px',
      marginBottom: 4,
      borderRadius: 4,
      '& > div': {
        width: '33.33%'
      },
      '&.header': {
        textAlign: 'center',
      },
      '&.content': {
        backgroundColor: colors.primary.o25,
        '& > div:first-child': {
          fontWeight: 700
        },
        '& > div:nth-child(n+2)': {
          textAlign: 'center',
        }
      }
    }
  })
)
