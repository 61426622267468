/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-20 21:52:23
 * @modify date 2022-04-20 21:52:23
 */

import { clone } from 'lodash'
import { applySnapshot, getSnapshot, flow } from "mobx-state-tree"
import { ApiResult } from "services/api"

export const withCompareActions = (self: any) => ({
  actions: {
    setSample(data) {
      const newSample = {
        ...data,
        defects: JSON.stringify(data.defects),
        wheelData: JSON.stringify(data.wheelData),
      }

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        cuppingProtocol: data.cuppingProtocol,
        samples:[...self.samples, newSample]
      })
    },
    clearSample(index: number) {
      if (index < 0) return

      const newIds = clone(self.ids)
      newIds.splice(index, 1)
      self.setValue('ids', newIds)

      const newSamples = clone(self.samples)
      newSamples.splice(index, 1)
      self.setValue('samples', newSamples)
    },
    getSample: flow(function * (id: string) {
      try {
        const additionalPath = 'compare'
        const res: ApiResult = yield self.environment.sampleApi.find(id, additionalPath)

        if (res.kind === "ok") {
          self.setSample(res.data)
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
  }
})
