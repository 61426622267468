/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-28 20:22:31
 * @modify date 2022-02-28 20:22:31
 */

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Card, Grid, TextField, Typography } from '@mui/material'

import { useStores } from 'models'
import { acidityDescriptorList } from 'constants/acidity-descriptors'
import { aftertasteDescriptorList } from 'constants/aftertaste-descriptors'
import { balanceDescriptorList } from 'constants/balance-descriptors'
import { bodyDescriptorList } from 'constants/body-descriptors'
import { flavorDescriptorList } from 'constants/flavor-descriptors'
import { defectDescriptorList } from 'constants/defect-descriptors'
import { Descriptor } from 'components'

import { Cup, Defect } from '../index'

export const Arabica: React.FC = observer(() => {
  const { t } = useTranslation()
  const { sampleStore: {
    selectedSample: { sampleId, selectedScore: {
      fragrance, fragranceDescriptors, fragranceDry, fragranceBreak, 
      acidity, acidityDescriptors, acidityIntensity, 
      body, bodyDescriptors, bodyIntensity, 
      flavor, flavorDescriptors,
      aftertaste, aftertasteDescriptors,
      balance, balanceDescriptors,
      overall, defects, defectsDescriptors,
      notes, cups, intensity,
      uniformity, totalUniformity, cleanCup, totalCleanCup, sweetness, totalSweetness,
      addingScore, decreaseScore, onClickCup,
      setScoreValue
    }}
  } } = useStores()
  
  const fragranceSlides = [
    {
      label: t('descriptor.dry'),
      value: fragranceDry,
      onChange: (e) => setScoreValue('fragranceDry', e.target.value)
    },
    {
      label: t('descriptor.break'),
      value: fragranceBreak,
      onChange: (e) => setScoreValue('fragranceBreak', e.target.value)
    }
  ]

  const aciditySlides = [
    {
      label: t('descriptor.acidityIntensity'),
      value: acidityIntensity,
      onChange: (e) => setScoreValue('acidityIntensity', e.target.value)
    }
  ]

  const bodySlides = [
    {
      label: t('descriptor.bodyLevel'),
      value: bodyIntensity,
      onChange: (e) => setScoreValue('bodyIntensity', e.target.value)
    }
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`fragranceOrAroma${fragranceDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.fragranceOrAroma')}
          descriptors={fragranceDescriptors}
          descriptorList={flavorDescriptorList}
          addDescriptor={(values) => setScoreValue('fragranceDescriptors', values)}
          slides={fragranceSlides}
          score={fragrance}
          addScore={() => addingScore(fragrance, 'fragrance')}
          decreaseScore={() => decreaseScore(fragrance, 'fragrance')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`acidity${acidityDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.acidity')}
          descriptors={acidityDescriptors}
          descriptorList={acidityDescriptorList}
          addDescriptor={(values) => setScoreValue('acidityDescriptors', values)}
          slides={aciditySlides}
          score={acidity}
          addScore={() => addingScore(acidity, 'acidity')}
          decreaseScore={() => decreaseScore(acidity, 'acidity')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`body${bodyDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.body')}
          descriptors={bodyDescriptors}
          descriptorList={bodyDescriptorList}
          addDescriptor={(values) => setScoreValue('bodyDescriptors', values)}
          slides={bodySlides}
          score={body}
          addScore={() => addingScore(body, 'body')}
          decreaseScore={() => decreaseScore(body, 'body')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`flavor${flavorDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.flavor')}
          descriptors={flavorDescriptors}
          descriptorList={flavorDescriptorList}
          addDescriptor={(values) => setScoreValue('flavorDescriptors', values)}
          score={flavor}
          addScore={() => addingScore(flavor, 'flavor')}
          decreaseScore={() => decreaseScore(flavor, 'flavor')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`aftertaste${aftertasteDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.aftertaste')}
          descriptors={aftertasteDescriptors}
          descriptorList={aftertasteDescriptorList}
          addDescriptor={(values) => setScoreValue('aftertasteDescriptors', values)}
          score={aftertaste}
          addScore={() => addingScore(aftertaste, 'aftertaste')}
          decreaseScore={() => decreaseScore(aftertaste, 'aftertaste')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`balance${balanceDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.balance')}
          descriptors={balanceDescriptors}
          descriptorList={balanceDescriptorList}
          addDescriptor={(values) => setScoreValue('balanceDescriptors', values)}
          score={balance}
          addScore={() => addingScore(balance, 'balance')}
          decreaseScore={() => decreaseScore(balance, 'balance')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`overall`}
          sampleId={sampleId}
          title={t('descriptor.overall')}
          score={overall}
          addScore={() => addingScore(overall, 'overall')}
          decreaseScore={() => decreaseScore(overall, 'overall')}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Cup
              key='uniformity'
              label={t('descriptor.uniformity')}
              cups={uniformity}
              score={totalUniformity}
              onClick={(index) => onClickCup(index, uniformity, 'uniformity')}
            />
          </Grid>
          <Grid item xs={12}>
            <Cup
              key='cleanCup'
              label={t('descriptor.cleanCup')}
              cups={cleanCup}
              score={totalCleanCup}
              onClick={(index) => onClickCup(index, cleanCup, 'cleanCup')}
            />
          </Grid>
          <Grid item xs={12}>
            <Cup
              key='sweetness'
              label={t('descriptor.sweetness')}
              cups={sweetness}
              score={totalSweetness}
              onClick={(index) => onClickCup(index, sweetness, 'sweetness')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Defect
          key={`defects${defectsDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.defects')}
          cups={cups}
          intensity={intensity}
          setCup={(value) => setScoreValue('cups', value)}
          setIntensity={(value) => setScoreValue('intensity', value)}
          score={defects}
          onChange={(value) => setScoreValue('defects', value)}
          descriptors={defectsDescriptors}
          descriptorList={defectDescriptorList}
          addDescriptor={(values) => setScoreValue('defectsDescriptors', values)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Card sx={{px: 4, py: 3}}>
          <Typography variant='h6' sx={{mb: 2}}>{t('descriptor.notes')}</Typography>
          <TextField
            fullWidth
            multiline
            minRows={8}
            value={notes}
            onChange={(e) => setScoreValue('notes', e.target.value)}
          />
        </Card>
      </Grid>
    </Grid>
  )
})