import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { v4 as uuidv4 } from 'uuid'

/**
 * Model description here for TypeScript hints.
 */
export const NotificationModel = types
  .model("Notification")
  .props({
    id: types.optional(types.string, uuidv4()),
    severity: types.optional(types.string, 'default'),
    title: types.maybeNull(types.string),
    message: types.maybeNull(types.string),
  })

/**
* Un-comment the following to omit model attributes from your snapshots (and from async storage).
* Useful for sensitive data like passwords, or transitive state like whether a modal is open.

* Note that you'll need to import `omit` from ramda, which is already included in the project!
*  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
*/

type NotificationType = Instance<typeof NotificationModel>
export interface Notification extends NotificationType {}
type NotificationSnapshotType = SnapshotOut<typeof NotificationModel>
export interface NotificationSnapshot extends NotificationSnapshotType {}
