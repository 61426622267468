/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-14 23:27:39
 * @modify date 2022-01-14 23:27:39
 * @desc Style for info page component
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors } from 'assets'

 export const useStyles = makeStyles((theme: Theme) =>
   createStyles({
     wrapper: {
       display: 'flex',
       justifyContent: 'space-between',
       paddingBottom: 6,
       marginBottom: 12,
       borderBottom: `1px solid ${colors.border.primary}`
     }
   })
 )