import React from 'react'
import { has } from "lodash"
import moment from "moment"
import { Link } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"
import ReactHtmlParser from 'react-html-parser'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  SvgIcon,
  TextField,
  Typography
} from "@mui/material"

import { ModalMessage, ModalWrapper } from "components"
import { globalAction, pointerMessages, validateEmail } from "utils"
import { DORMANT_PLAN_ID } from "config/env"
import { Logo } from "assets/images"

type DormantModalProps = {
  rootStore: any
  open: boolean
  setOpen: (open: boolean) => void
  openDormant: boolean
  setOpenDormant: (open: boolean) => void
  isSubscription?: boolean
  isActivation?: boolean
}

export const DormantModal = ({ rootStore, open, setOpen, openDormant, setOpenDormant, isSubscription = false, isActivation = false }: DormantModalProps) => {
  const { t } = useTranslation()
  const {
    resetStore,
    userStore: { email: currentEmail, languagePreference: language },
    companyStore: { activateDormantAccount, removeAt, setValue: setCompanyValue },
    subscriptionStore: { cancelSubscription, updateSubscription },
    modalStore: { setValue: setModalValue },
    notificationStore,
  } = rootStore

  const [email, setEmail] = React.useState(currentEmail)
  const [errors, setErrors] = React.useState<any>({})
  const [openModalMessage, setOpenModalMessage] = React.useState(false)

  const onActivateDormantAccount = () => {
    globalAction(notificationStore, {
      action: async () => updateSubscription(DORMANT_PLAN_ID),
      afterAction: () => {
        setOpenModalMessage(true)
      }
    })
  }

  const onCancelSubscription = () => {
    globalAction(notificationStore, {
      action: cancelSubscription,
      afterAction: () => {
        setCompanyValue('subscriptionStatus', 'canceled')
        setModalValue('setting', false)
      }
    })
  }

  const onSubmitActivateDormantAccount = () => {
    setOpenDormant(false)

    if (isActivation) {
      setOpen(false)
      return
    }

    onActivateDormantAccount()
  }

  const onSubmitActivationRequest = () => {
    setErrors({})

    if (!validateEmail(email)) {
      setErrors({"email": [{ message: t('cupper.error.emailIsInvalid') }]})
      return
    }

    globalAction(notificationStore, {
      action: async () => activateDormantAccount(email),
      afterAction: () => {
        setOpenModalMessage(true)
      }
    })
  }

  const renderDormantDescription = () => {
    switch (language) {
      case 'es':
        return (
          <>
            <Typography>
              <p>
                <strong>¿Qué es el modo de hibernación?</strong>
              </p>
              <ol>
                <li>
                  Si decides cancelar o retener el pago de tu tarifa mensual,
                  ten en cuenta que tus datos y cuenta serán borrados.
                  Esto incluye cualquier historial de catas, datos de muestra de café y todos los demás datos o configuraciones personalizadas que puedas tener.
                </li>
                <li>
                  Si prefieres mantener tus datos seguros,
                  puedes pagar <strong>una tarifa mensual de $3 por usuario a Tastify</strong> para mantener tu cuenta en modo de hibernación.
                </li>
                <li>
                  El modo de hibernación también te permite acceder a datos de muestra (pero no a las páginas de sesiones de cata) y compartir/revisar informes de catas.
                  Sin embargo, este modo no te permite crear nuevas sesiones de cata.
                  Finalmente, cuando estés listo para regresar, puedes reanudar sin problemas el uso de nuestro servicio cuando lo desees cambiando tu suscripción de nuevo a la normalidad.
                </li>
              </ol>
            </Typography>

            <Button variant="contained" sx={{mt: 2}} onClick={onSubmitActivateDormantAccount}>
              ¡SÍ, REGÍSTRAME!
            </Button>
          </>
        )
      case 'pt':
        return (
          <>
            <Typography>
              <p>
                <strong>O que é o modo de hibernação?</strong>
              </p>
              <ol>
                <li>
                  Se você decidir cancelar ou reter o pagamento de sua taxa mensal,
                  esteja ciente de que seus dados e conta serão apagados.
                  Isso inclui qualquer histórico de provas de degustação, dados de amostras de café e todos os outros dados ou configurações personalizadas que você possa ter.
                </li>
                <li>
                  Se você preferir manter seus dados seguros,
                  você pode pagar <strong>uma taxa mensal de $3 por usuário para a Tastify</strong> para manter sua conta em modo dormente.
                </li>
                <li>
                  O modo dormente também permite que você acesse dados de amostras (mas não as páginas de sessões de provas de degustação) e compartilhe/avalie relatórios de provas de degustação.
                  No entanto, esse modo não permite que você crie novas sessões de provas de degustação.
                  Por fim, quando estiver pronto para retornar, você pode retomar o uso de nosso serviço sem problemas sempre que desejar, alterando sua assinatura de volta ao normal.
                </li>
              </ol>
            </Typography>

            <Button variant="contained" sx={{ mt: 2 }} onClick={onSubmitActivateDormantAccount}>
              SIM, QUERO ME CADASTRAR!
            </Button>
          </>
        )
      case 'fr-CA':
        return (
          <>
            <Typography>
              <p>
                <strong>Qu'est-ce que le mode dormant ?</strong>
              </p>
              <ol>
                <li>
                  Si vous décidez d'annuler ou de ne pas effectuer le paiement de votre frais mensuel,
                  veuillez noter que vos données et votre compte seront effacés.
                  Cela inclut tout l'historique des dégustations, les données d'échantillons de café et toutes les autres données ou paramètres personnalisés que vous pourriez avoir.
                </li>
                <li>
                  Si vous préférez conserver vos données en toute sécurité,
                  vous pouvez payer <strong>3 $ par utilisateur par mois à Tastify</strong> pour maintenir votre compte en mode dormant.
                </li>
                <li>
                  Le mode dormant vous permet également d'accéder aux données d'échantillons (mais pas aux pages de sessions de dégustation) et de partager/revoir des rapports de dégustation.
                  Cependant, ce mode ne vous permet pas de créer de nouvelles sessions de dégustation.
                  Enfin, lorsque vous êtes prêt à revenir, vous pouvez reprendre l'utilisation de notre service quand vous le souhaitez en rétablissant votre abonnement normal.
                </li>
              </ol>
            </Typography>

            <Button variant="contained" sx={{ mt: 2 }} onClick={onSubmitActivateDormantAccount}>
              OUI, INSCRIVEZ-MOI !
            </Button>
          </>
        )
      case 'id':
        return (
          <>
            <Typography>
              <p>
                <strong>Apa itu mode dormant?</strong>
              </p>
              <ol>
                <li>
                  Jika Anda memutuskan untuk membatalkan atau menahan pembayaran biaya bulanan Anda,
                  harap diperhatikan bahwa data dan akun Anda akan dihapus.
                  Ini mencakup riwayat cupping apa pun, data sampel kopi, dan semua data atau pengaturan pribadi lain yang mungkin Anda miliki.
                </li>
                <li>
                  Jika Anda lebih memilih menjaga data Anda tetap aman dan terjamin,
                  Anda dapat membayar biaya bulanan <strong>$3 per pengguna kepada Tastify</strong> untuk menjaga akun Anda dalam mode hibernasi.
                </li>
                <li>
                  Mode hibernasi juga memungkinkan Anda mengakses data sampel (tetapi tidak halaman sesi cupping) dan berbagi/ulasan laporan cupping.
                  Namun, mode ini tidak memungkinkan Anda membuat sesi cupping baru.
                  Akhirnya, saat Anda siap kembali, Anda dapat dengan lancar melanjutkan penggunaan layanan kami kapan saja yang Anda inginkan dengan mengubah langganan Anda kembali ke normal.
                </li>
              </ol>
            </Typography>

            <Button variant="contained" sx={{ mt: 2 }} onClick={onSubmitActivateDormantAccount}>
              YA, DAFTARKAN SAYA!
            </Button>
          </>
        )
      case 'ko':
        return (
          <>
            <Typography>
              <p>
                <strong>무효 모드란 무엇인가요?</strong>
              </p>
              <ol>
                <li>
                  월간 요금을 취소하거나 지불을 보류하기로 결정하는 경우, 데이터와 계정이 삭제될 것임을 인식하십시오.
                  이에는 어떤 컵핑 기록도, 커피 샘플 데이터도, 그 밖의 모든 데이터나 개인 설정도 포함됩니다.
                </li>
                <li>
                  데이터를 안전하게 보호하려면, 계정을 휴면 모드로 유지하려면 <strong>Tastify에 월간 $3/사용자 요금을 지불</strong>할 수 있습니다.
                </li>
                <li>
                  휴면 모드는 샘플 데이터에 액세스할 수 있게 해주지만(단, 컵핑 세션 페이지는 제외), 컵핑 보고서를 공유/검토하는 것도 가능합니다.
                  그러나 이 모드에서는 새로운 컵핑 세션을 생성할 수 없습니다.
                  마지막으로, 다시 정상으로 구독을 변경함으로써 언제든지 원할 때 서비스를 원활하게 재개할 수 있습니다.
                </li>
              </ol>
            </Typography>

            <Button variant="contained" sx={{ mt: 2 }} onClick={onSubmitActivateDormantAccount}>
              네, 가입합니다!
            </Button>
          </>
        )
      case 'ja':
        return (
          <>
            <Typography>
              <p>
                <strong>休止モードとは何ですか？</strong>
              </p>
              <ol>
                <li>
                  月額料金の支払いをキャンセルまたは保留することを決定する場合、データとアカウントが削除されることに注意してください。
                  これにはどのようなカッピングの履歴も、コーヒーサンプルデータも、その他のすべてのデータや個人設定も含まれます。
                </li>
                <li>
                  データを安全に保護するために、<strong>Tastifyに月額$3/ユーザーの料金を支払って</strong> アカウントを休眠モードに保つこともできます。
                </li>
                <li>
                  休眠モードではサンプルデータにアクセスできます（ただしカッピングセッションのページは除く） 、カッピングレポートを共有/レビューすることもできます。
                  ただし、このモードでは新しいカッピングセッションを作成することはできません。
                  最後に、戻る準備ができたら、通常の購読に戻すことでいつでもサービスをシームレスに再開できます。
                </li>
              </ol>
            </Typography>

            <Button variant="contained" sx={{ mt: 2 }} onClick={onSubmitActivateDormantAccount}>
              はい、サインアップします！
            </Button>
          </>
        )
      case 'zh-CN':
        return (
          <>
            <Typography>
              <p>
                <strong>什么是休眠模式？</strong>
              </p>
              <ol>
                <li>
                  如果您决定取消或暂停支付您的月费，
                  请注意您的数据和账户将被删除。
                  这包括任何品尝历史、咖啡样本数据以及您可能拥有的所有其他数据或个性化设置。
                </li>
                <li>
                  如果您愿意保护您的数据安全，
                  您可以支付<strong>每月 $3/用户的费用给 Tastify</strong>，将您的账户保持在休眠模式。
                </li>
                <li>
                  休眠模式还允许您访问样本数据（但不能访问品尝会话页面）并共享/查看品尝报告。
                  但是，此模式不允许您创建新的品尝会话。
                  最后，当您准备好返回时，只需将订阅恢复到正常状态，您随时可以无缝恢复使用我们的服务。
                </li>
              </ol>
            </Typography>

            <Button variant="contained" sx={{ mt: 2 }} onClick={onSubmitActivateDormantAccount}>
              是的，我要注册！
            </Button>
          </>
        )
      case 'en':
      default:
        return (
          <>
            <Typography>
              <p>
                <strong>What is dormant mode?</strong>
              </p>
              <ol>
                <li>
                  If you decide to cancel or withhold payment of your monthly fee,
                  please be aware that your data and account will be erased.
                  This includes any cupping history, coffee sample data and all other data or personalized settings you may have..
                </li>
                <li>
                  Should you prefer to keep your data safe and secure,
                  you can pay <strong>$3/user monthly fee to Tastify</strong> to keep your account on dormant mode.
                </li>
                <li>
                  Dormant mode also allows you to access sample data (but not cupping session pages) and share/review cupping reports.
                  This mode however does not allow you to create new cupping session.
                  Finally, when you are ready to return, you can seamlessly resume using our service whenever you wish by changing your subscription back to normal.
                </li>
              </ol>
            </Typography>

            <Button variant="contained" sx={{mt: 2}} onClick={onSubmitActivateDormantAccount}>
              YES SIGN ME UP!
            </Button>
          </>
        )
    }
  }

  const renderDormantConfirmation = () => {
    if (isSubscription) {
      return (
        <Dialog
          open={open}
          keepMounted
          onClose={() => setOpen(false)}
        >
          <DialogContent>
            <Typography variant='h5' sx={{mb: 0.5}}>{t('subscription.removeConfirmSubscriptionTitle')}</Typography>
            <Typography variant='body2'>
              {t('subscription.removeConfirmSubscriptionDescription')}
            </Typography>

            <DialogActions sx={{my: 3, justifyContent: 'center'}}>
              <Button variant='contained' onClick={onCancelSubscription}>{t('common.confirm')}</Button>
              <Button variant='outlined' onClick={() => setOpen(false)}>{t('common.cancel')}</Button>
            </DialogActions>

            <Typography variant='body2'>
              <Trans i18nKey="subscription.dormantAccountLink">
                Click
                <Link
                  to="#"
                  onClick={() => {
                    setOpen(false)
                    setOpenDormant(true)
                  }}
                >
                  <Button className='btn-text'>here</Button>
                </Link>
                to access reduced rates to keep your data in dormant account mode
              </Trans>
            </Typography>
          </DialogContent>
        </Dialog>
      )
    }

    return (
      <ModalWrapper open={open} maxWidth='sm'>
        <Box>
          <Typography variant='h5' sx={{mb: 0.5}}>
            {t('subscription.dormantAccountWarningTitle')}
          </Typography>
          <Typography variant='body2' sx={{mb: 2}}>
            {ReactHtmlParser(t('subscription.dormantAccountWarningDescription', { date: moment(removeAt).format('LL') }))}
          </Typography>

          <FormControl fullWidth margin='normal' sx={{mb: 2}}>
            <FormLabel>{t('subscription.yourActiveEmail')}</FormLabel>
            <TextField
              type="email"
              size='small'
              placeholder="example@email.com"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={has(errors, 'email')}
              helperText={pointerMessages('email', errors)}
            />
          </FormControl>

          <Button
            variant="contained"
            onClick={onSubmitActivationRequest}
          >
            {t('subscription.sendActivationRequest')}
          </Button>
        </Box>
      </ModalWrapper>
    )
  }

  React.useEffect(() => {
    if (isActivation) {
      setOpenDormant(true)
    }
  }, [isActivation])

  return (
    <>
      {renderDormantConfirmation()}

      <ModalWrapper open={openDormant} {...(!isActivation && { onClose: () => setOpenDormant(false) })} maxWidth="md">
        <SvgIcon component={Logo} inheritViewBox sx={{height: 50, width: 200, mb: 1}} />

        {renderDormantDescription()}
      </ModalWrapper>

      <ModalMessage
        open={openModalMessage}
        onSubmit={() => {
          setOpenModalMessage(false)
          resetStore()
        }}
        description={t(`subscription.success.${isSubscription ? 'dormantAccountSuccessMessage': 'thankYouForYourRequest'}`)}
      />
    </>
  )
}
