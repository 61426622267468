/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-19 21:18:41
 * @modify date 2022-04-19 21:18:41
 */

import moment from "moment"
import i18n from 'i18n/i18n'
import {ceil, some, filter, findIndex, find, pick, snakeCase, mapKeys} from "lodash"
import { applySnapshot, getSnapshot, flow } from "mobx-state-tree"
import fileDownload from 'js-file-download'

import { ApiErrorKind, ApiResult } from "services/api"
import { DATA_PER_PAGE, CUPPED_STORAGE_KEY } from "config/env"
import { loadString } from "utils/storage"
import { preProcessorCuppingSessionForm } from 'models/cupping-session-form'
import cuppingSession from 'fixtures/cupping-session.json'

export const withCuppingSessionActions = (self: any) => ({
  actions: {
    setMessage(locale: string) {
      const { setNotification } = self.rootStore.notificationStore
      setNotification({
        severity: 'success',
        message: i18n.t(locale)
      })
    },
    setCuppingSession(newCuppingSession: any) {
      const exists = some(self.cuppingSessions, ['id', newCuppingSession.id])
      const preCuppingSessionForm = preProcessorCuppingSessionForm(newCuppingSession)
      const cuppingSession = {
        ...preCuppingSessionForm,
        canNotBeEdited: false,
        startsAt: moment(preCuppingSessionForm.startsAt).toISOString(),
        endsAt: moment(preCuppingSessionForm.endsAt).toISOString(),
      }

      const newCuppingSessions = exists ?
        self.cuppingSessions.map(cs => cs.id === cuppingSession.id ? cuppingSession : cs) :
        [...self.cuppingSessions, cuppingSession]

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        cuppingSessions: newCuppingSessions,
        selectedCuppingSession: cuppingSession.id
      })

      self.rootStore.cuppingSessionFormStore.setCuppingSession(preCuppingSessionForm)
      self.selectedCuppingSession?.setValue('canNotCupNow', newCuppingSession?.canNotCupNow)
    },
    removeEmptyCuppingSession() {
      const cuppingSessions = filter(self.cuppingSessions, cs => { return cs.id !== '' })
      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        cuppingSessions: cuppingSessions
      })
    },
    getCuppedCuppingSession: flow(function * (uniqueToken) {
      const cuppedStorage = yield loadString(CUPPED_STORAGE_KEY)

      if (!cuppedStorage) return false

      const cupped = JSON.parse(cuppedStorage)

      if (!cupped) return false

      return Object.keys(cupped).some(key => key.includes(uniqueToken))
    }),

    getGuestUrlToken: flow(function * (token) {
      const { samples } = self.rootStore.sampleStore
      const cuppedStorage = yield loadString(CUPPED_STORAGE_KEY)
      const cupped = JSON.parse(cuppedStorage)

      if (!cupped) return

      const id = find(samples, ['uniqueToken', token])?.id
      return cupped[self.selectedCuppingSession.uniqueToken + '_' + id]
    }),
    getCuppingSessions: flow(function * () {
      try {
        const res: ApiResult = yield self
          .environment
          .cuppingSessionApi
          .all({
            "size": DATA_PER_PAGE,
            "page[number]": self.page,
            "filter": self.filter,
            "sort_by": 'id',
            "direction": 'desc'
          })

        if (res.kind === "ok") {
          const totalPage = ceil(res.meta.pagination.records / DATA_PER_PAGE)

          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            totalPage,
            cuppingSessions: res.data
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    getCuppingSession: flow(function * (token) {
      try {
        const { guideSample, guideScore } = self.rootStore.guideStore
        const { getSamples } = self.rootStore.sampleStore

        if (guideSample || guideScore) {
          self.setCuppingSession(cuppingSession)
          yield getSamples(token)

          return
        }

        const res: ApiResult = yield self.environment.cuppingSessionApi.find(token)

        if (res.kind === "ok") {
          self.setCuppingSession(res.data)
          yield getSamples(token)
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    startCupping: flow(function * (token) {
      try {
        if (!token) return

        const payload: any = {id: token}
        const additionalPath: string = 'start'
        const res: ApiResult = yield self.environment.cuppingSessionApi.save(payload, {}, additionalPath)

        if (res.kind === "ok") {
          const index = findIndex(self.cuppingSessions, ['uniqueToken', token])

          if (index >= 0) {
            self.cuppingSessions[index].canBeStarted = false
            self.cuppingSessions[index].canNotCupNow = false
          }

          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    removeCuppingSession: flow(function * () {
      try {
        if (!self.selectedCuppingSession?.uniqueToken) return

        const res: ApiResult = yield self.environment.cuppingSessionApi.remove(self.selectedCuppingSession.uniqueToken)

        if (res.kind === "ok") {
          if (self.cuppingSessions.length === 1) {
            yield self.getCuppingSessions()
          } else {
            const newCuppingSessions = filter(self.cuppingSessions, cs => { return cs.id !== self.selectedCuppingSession?.id })
            applySnapshot(self, {
              ...getSnapshot(self as object) as any,
              cuppingSessions: newCuppingSessions,
              selectedCuppingSession: undefined
            })
          }

          self.setMessage('cuppingSession.success.successfullyRemovedCuppingSession')
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    exportReviews: flow(function * (additionalPath) {
      try {
        if (!self.selectedCuppingSession?.uniqueToken) return

        const response = yield self
                          .environment
                          .sampleScoreApi
                          .export({
                            'cupping_session_id': self.selectedCuppingSession.uniqueToken
                          }, additionalPath)

        fileDownload(response.data, response.name)
      } catch (error: any) {
        yield self.checkForGeneralError(error)

        if (error.kind === ApiErrorKind.UNAUTHORIZED) yield self.exportReviews(additionalPath)
      }
    }),
    getGuestCuppingSession: flow(function * (unique_token: string, url_token: string) {
      try {
        self.cuppingSessions = []
        const { sampleStore: { setSamples }, companyStore: { setValue: setCompanyValue }} = self.rootStore

        const payload = url_token ? {guest_invitation_token: url_token} : {cupping_session_token: unique_token}

        const res: ApiResult = yield self.environment.guestInvitationApi.all(payload)
        if (res.kind === "ok") {
          self.setCuppingSession(res.data.cuppingSession)
          setSamples(res.data.samples.map(sample => {
            return {
              ...sample,
              cuppingProtocol: res.data.cuppingSession.cuppingProtocol
            }
          }))

          self.selectedCuppingSession?.setValue('hasSubmittedScores', res.data.cuppingSession.hasSubmittedScores)
          self.selectedCuppingSession?.setValue('ownerName', res.data.ownerName)
          self.selectedCuppingSession?.setValue('company', res.data.company)
          setCompanyValue('base64Logo', res.data.base64Logo)
          setCompanyValue('subscriptionPlanId', res.data.subscriptionPlanId)
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    resendWebhook: flow(function * (token) {
      try {
        if (!token) return

        const additionalPath: string = 'resend_to_webhook'
        const res: ApiResult = yield self.environment.cuppingSessionApi.find(token, additionalPath)

        if (res.kind === "ok") {
          const index = findIndex(self.cuppingSessions, ['uniqueToken', token])
          if (index >= 0) self.cuppingSessions[index].canNotResendToWebhook = true

          self.setMessage('cuppingSession.success.successfullyResendWebhook')
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    playOrPause: flow(function * (id) {
      try {
        const index = findIndex(self.cuppingSessions, ['id', id])
        if (index < 0) return

        const payload: any = pick(self.cuppingSessions[index], ['id', 'isPaused'])
        payload.isPaused = !payload.isPaused
        const res: ApiResult = yield self.environment.cuppingSessionApi.save(payload, {}, 'update_pause_status')

        if (res.kind === "ok") {
          self.cuppingSessions[index].setValue('isPaused', payload.isPaused)

          self.setMessage('cuppingSession.success.successfullyPlayOrPause')
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    updateRequestInformation: flow(function * (id, attributes) {
      try {
        const payload: any = {
          id,
          publicCuppingRequestInformationAttributes: mapKeys(attributes, (value, key) => {
            return snakeCase(key)
          })
        }

        const res: ApiResult = yield self.environment.cuppingSessionApi.save(payload, {}, 'update_config')

        if (res.kind === "ok") {
          const cuppingSessions = self.cuppingSessions.map(cs => {
            if (cs.id === res.data.id) {
              return {
                ...cs,
                publicCuppingRequestInformation: res.data.publicCuppingRequestInformation
              }
            }

            return cs
          })

          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            cuppingSessions,
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }

    })
  }
})
