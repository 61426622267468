/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */

import { Instance, SnapshotOut, types } from "mobx-state-tree"

import { 
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"
import { Subscription } from './subscription'
import { PaymentHistory } from './payment-history'
import { withSubscriptionActions } from './subscription-action'

/**
 * Model description here for TypeScript hints.
 */

export const SubscriptionStoreModel = types
  .model("SubscriptionStore")
  .props({
    subscriptions: types.optional(types.array(Subscription), []),
    paymentHistories: types.optional(types.array(PaymentHistory), [])
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withSubscriptionActions)

type SubscriptionStoreType = Instance<typeof SubscriptionStoreModel>
export interface SubscriptionStore extends SubscriptionStoreType {}
type SubscriptionStoreSnapshotType = SnapshotOut<typeof SubscriptionStoreModel>
export interface SubscriptionStoreSnapshot extends SubscriptionStoreSnapshotType {}
