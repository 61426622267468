/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-09 20:50:30
 * @modify date 2022-02-09 20:50:30
 */

import React from 'react'
import clsx from 'clsx'
import { Helmet } from "react-helmet"
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { debounce, includes, toNumber, chain, indexOf, isNaN, isNull, isEmpty, every, filter } from 'lodash'
import { Clear as ClearIcon, AddCircleOutline, ExpandMore, ExpandLess } from '@mui/icons-material'
import {
  Container, Button, FormControlLabel, Checkbox, Grid, Typography, Box, Card,
  IconButton, Collapse, SvgIcon, CircularProgress
} from '@mui/material'

import { useStores } from 'models'
import { findRoute } from 'constants/routes'
import { HeaderTitle, ModalWrapper, FormInput } from 'components'
import {
  IconSampleName, IconSampleProccess, IconCropYear, IconCountryOfOrigin, IconBean, IconFragrance, IconStartCupping, IconAftertaste, IconAcidity, IconBody, IconBalance, IconOverall, IconDefects, IconUniformity, IconCleanCup, IconSweetness,
  IconDefect, IconWeight, Logo, IconCup
} from 'assets/images'
import { DEBOUNCE_TIME } from 'config/env'
import { printPage, flavorWheel, globalAction, allDescriptorTranslation } from 'utils'
import { centered } from 'assets'

import { useStyles } from './compare.styles'

export const Compare: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const reviewSamplesLink = findRoute('reviewSamples')
  const {
    userStore: { languagePreference },
    compareStore: { ids, cuppingProtocol, samples, sampleNames, sampleCountries, sampleTypes, sampleCropYears,
      fragrance, fragranceDescriptors, aroma, salt, saltDescriptors, bittersweet,
      flavor, flavorDescriptors, aftertaste, aftertasteDescriptors, acidity, acidityDescriptors,
      body, bodyDescriptors, balance, balanceDescriptors, mouthfeel, mouthfeelDescriptors,
      overall, uniformity, cleanCup, sweetness, cleanCupScore, sweetnessScore, nonUniform,
      defects, greenGradingColor, greenGradingWeight, greenGradingSmell, greenGradingDefects,
      getSample, clearSample, setValue: setCompareValue, reset: resetCompare },
    reviewSampleStore: { samples: sampleList, getSamples, search: { name, setValue: setSearchValue }, setValue: setReviewSampleValue },
    companyStore: { base64Logo, isEnterprisePlus },
    notificationStore
  } = useStores()

  const [openSampleInfo, setOpenSampleInfo] = React.useState(false)
  const [openDescriptorInfo, setOpenDescriptorInfo] = React.useState(false)
  const [openGreenGradingInfo, setOpenGreenGradingInfo] = React.useState(false)
  const [openDefectsInfo, setOpenDefectsInfo] = React.useState(false)
  const [isPrinting, setIsPrinting] = React.useState(false)
  const printElement = React.createRef<Element | undefined>()
  const [isSearch, setIsSearch] = React.useState(false)
  const [openSearch, setOpenSearch] = React.useState(false)
  const [loadingSample, setLoadingSample] = React.useState(false)
  const [highlightDifference, setHighlightDifference] = React.useState(false)
  const [column, setColumn] = React.useState(4)
  const [openWheel, setOpenWheel] = React.useState(false)
  const [wheelData, setWheelData] = React.useState('')
  const [titleSample, setTitleSample] = React.useState('')
  const [tempIds, setTempIds] = React.useState<any[]>([])

  const sampleInformations = [
    {
      icon: IconSampleName,
      name: t('sample.sampleName'),
      values: sampleNames
    },
    {
      icon: IconCountryOfOrigin,
      name: t('sample.country'),
      values: sampleCountries
    },
    {
      icon: IconSampleProccess,
      name: t('sample.sampleType'),
      values: sampleTypes
    },
    {
      icon: IconCropYear,
      name: t('sample.cropYear'),
      values: sampleCropYears
    }
  ]

  const descriptors = [
    {
      icon: IconFragrance,
      name: t('descriptor.fragranceOrAroma'),
      values: fragrance,
      descriptors: fragranceDescriptors
    },
    {
      icon: IconAcidity,
      name: t('descriptor.saltOrAcid'),
      values: salt,
      descriptors: saltDescriptors
    },
    {
      icon: IconBean,
      name: t('descriptor.bitterOrSweet'),
      values: bittersweet,
    },
    {
      icon: IconStartCupping,
      name: t('descriptor.flavor'),
      values: flavor,
      descriptors: flavorDescriptors
    },
    {
      icon: IconAftertaste,
      name: t('descriptor.aftertaste'),
      values: aftertaste,
      descriptors: aftertasteDescriptors
    },
    {
      icon: IconAcidity,
      name: t('descriptor.acidity'),
      values: acidity,
      descriptors: acidityDescriptors
    },
    {
      icon: IconBody,
      name: t('descriptor.body'),
      values: body,
      descriptors: bodyDescriptors
    },
    {
      icon: IconBalance,
      name: t('descriptor.balance'),
      values: balance,
      descriptors: balanceDescriptors
    },
    {
      icon: IconCup,
      name: t('descriptor.mouthfeel'),
      values: mouthfeel,
      descriptors: mouthfeelDescriptors
    },
    {
      icon: IconOverall,
      name: t('descriptor.overall'),
      values: overall
    },
    {
      icon: IconUniformity,
      name: t('descriptor.uniformity'),
      values: uniformity
    },
    {
      icon: IconCleanCup,
      name: t('descriptor.cleanCup'),
      values: cleanCup || cleanCupScore
    },
    {
      icon: IconSweetness,
      name: t('descriptor.sweetness'),
      values: sweetness || sweetnessScore
    },
    {
      icon: IconDefects,
      name: t('descriptor.defects'),
      values: defects
    },
  ]

  const scaDescriptor = [
    {
      icon: IconFragrance,
      name: t('descriptor.fragrance'),
      values: fragrance,
    },
    {
      icon: IconFragrance,
      name: t('descriptor.aroma'),
      values: aroma,
    },
    {
      icon: IconStartCupping,
      name: t('descriptor.flavor'),
      values: flavor,
    },
    {
      icon: IconAftertaste,
      name: t('descriptor.aftertaste'),
      values: aftertaste,
    },
    {
      icon: IconAcidity,
      name: t('descriptor.acidity'),
      values: acidity,
    },
    {
      icon: IconSweetness,
      name: t('descriptor.sweetness'),
      values: sweetnessScore
    },
    {
      icon: IconCup,
      name: t('descriptor.mouthfeel'),
      values: mouthfeel,
    },
    {
      icon: IconOverall,
      name: t('descriptor.overall'),
      values: overall
    },
    {
      icon: IconDefects,
      name: t('descriptor.nonUniformCups'),
      values: nonUniform
    },
    {
      icon: IconDefects,
      name: t('descriptor.defects'),
      values: defects
    },
  ]

  const greenGradings = [
    {
      icon: IconBean,
      name: t('greenGrading.color'),
      values: greenGradingColor
    },
    {
      icon: IconWeight,
      name: t('greenGrading.weight'),
      values: greenGradingWeight
    },
    {
      icon: IconFragrance,
      name: t('greenGrading.smell'),
      values: greenGradingSmell
    }
  ]

  const onPrint = () => {
    setColumn(ids.length)
    setOpenSampleInfo(true)
    setOpenDescriptorInfo(true)
    setOpenGreenGradingInfo(true)
    setOpenDefectsInfo(true)
    setIsPrinting(true)
  }

  const onGetSample = (id: string) => globalAction(notificationStore, { action: getSample(id) })

  const loadSamples = () => {
    setCompareValue('samples', [])

    ids.map((id) => onGetSample(id))
  }

  const onSearch = React.useCallback(
    debounce(async (action) => {
      setLoadingSample(true)
      setReviewSampleValue('page', 1)

      try{
        await action()
      } finally {
        setIsSearch(true)
        setLoadingSample(false)
      }
    }, DEBOUNCE_TIME), []
  )

  const addSamples = () => {
    setTempIds([])
    setIsSearch(false)
    setOpenSearch(true)
    setSearchValue('name', '')
    setReviewSampleValue('samples', [])
  }

  const checkSample = (id) => {
    if (includes(tempIds, id)) {
      const newIds = filter(tempIds, i => { return i !== id})
      setTempIds(newIds)
      return
    }

    setTempIds([...tempIds, id])
  }

  const headerCard = (sample, index = -1) => {
    if (sample) {
      return (
        <>
          <Card className={classes.wheelInfo}>
            {sample.sampleInfo.name}
          </Card>
          <Card className={classes.headerCard}>
            {!isPrinting &&
              <Box sx={{textAlign: 'right', display: {sm: 'none', md: 'block'}}}>
                <IconButton size="small" onClick={() => clearSample(index)}>
                  <ClearIcon color='error'/>
                </IconButton>
              </Box>
            }
            <Box className={classes.imageWrapper} onClick={() => {
              setOpenWheel(true)
              setWheelData(sample.wheelData)
              setTitleSample(sample.sampleInfo.name)
              }}
            >
              <Box id={`tastify-wheel-${index}`} className={classes.wheel}></Box>
            </Box>
            <Typography variant='h4' align='center'>{sample.cachedAverageScore || '-'}</Typography>
          </Card>
        </>
      )
    }

    return (
      <Card className={classes.headerCard}>
        <Box sx={{textAlign: 'center'}}>
          <IconButton onClick={addSamples}>
            <AddCircleOutline color='primary' sx={{width: 50, height: 50}}/>
          </IconButton>
        </Box>
      </Card>
    )
  }

  const renderInfoItem = (item: any, index: number) => {
    const { icon, name, values, descriptors } = item
    const numberValues = chain(values).map(value => { return toNumber(value)}).sortBy().value()
    const n = column - ids.length

    const nullValue = every(values, v => isNull(v) || isEmpty(v.toString()))
    if (nullValue) return

    return (
      <Grid container key={index}>
        <Grid item xs={12} md className={classes.infoItemHeader}>
          <SvgIcon component={icon} color='primary' inheritViewBox />
          <Typography sx={{ml: 1, fontWeight: 600}}>{name}</Typography>
        </Grid>
        {[...Array(column)].map((_, i) =>
          <Grid
            item
            key={i}
            xs={12}
            md
            className={clsx(classes.infoItem, highlightDifference && !isNaN(toNumber(values[i])) && values[i] && `color-${indexOf(numberValues, toNumber(values[i])) + n}`, !values[i]?.toString() && 'no-content')}
          >
            <Box className={classes.infoItemName}>
              <Typography variant='body1'>{samples[i]?.sampleInfo?.name}</Typography>
            </Box>
            <Box className={classes.infoItemValue}>
              <Typography variant='h6' paragraph>{values[i]}</Typography>
              {descriptors && allDescriptorTranslation(languagePreference, descriptors[i])}
            </Box>
          </Grid>
        )}
      </Grid>
    )
  }

  const renderSearchSampleModal = (
    <ModalWrapper
      maxWidth='sm'
      open={openSearch}
      onClose={() => setOpenSearch(false)}
    >
      <Typography variant='h6'>{t('compare.addSamplesToCompare')}</Typography>
      <Typography variant='body2'>{t('compare.maxSamplesToCompare')}</Typography>
      <FormInput
        textFieldProps={{
          autoFocus: true,
          placeholder: t('compare.searchSample'),
          value: name,
          onChange: (e) => setSearchValue('name', e.target.value),
          InputProps: {
            ...(loadingSample && { endAdornment: <CircularProgress color="inherit" size={16} /> })
          }
        }}
      />

      {isEmpty(sampleList) && isSearch &&
        <Box sx={{...centered, p: 3}}>
          <Typography variant='h6'>{t('sample.sampleNotFound')}</Typography>
        </Box>
      }

      {sampleList.map(sample => {
        const disableIds = includes(ids, sample.id) || (ids.length + tempIds.length) >= column

        return (
          <Box key={sample.id} className={classes.sampleList}>
            <Box sx={{mr: 2,}} className={classes.vertical}>
              <Checkbox
                disabled={disableIds && !includes(tempIds, sample.id)}
                onClick={() => checkSample(sample.id)}
              />
              <Typography variant='body2'>
                {sample.sampleId}{!isNull(sample.name) && ` - ${sample.name}`}
              </Typography>
            </Box>

            <Button
              size='small'
              variant='contained'
              disabled={disableIds || includes(tempIds, sample.id)}
              onClick={() => {
                setCompareValue('ids', [...ids, sample.id])
                onGetSample(sample.id)
              }}
            >
              {t('compare.add')}
            </Button>
          </Box>
        )
      })}

      {!isEmpty(sampleList) &&
        <Button
          fullWidth
          size='small'
          variant='contained'
          sx={{mt: 3}}
          onClick={() => {
            setOpenSearch(false)
            setCompareValue('ids', [...ids, ...tempIds])
            loadSamples()
          }}
        >
          {t('compare.addSample')} ({tempIds.length})
        </Button>
      }
    </ModalWrapper>
  )

  const renderWheelModal = (
    <ModalWrapper
      maxWidth='md'
      open={openWheel}
      onClose={() => setOpenWheel(false)}
    >
      <Box sx={{width: '100%', height: '50vh'}}>
        <Box id='tastify-wheel' className={classes.wheel}></Box>
      </Box>
      <Typography variant='h3' align='center' sx={{my: 2}}>{titleSample}</Typography>
    </ModalWrapper>
  )

  React.useEffect(() => {
    loadSamples()
  }, [languagePreference])

  React.useEffect(() => {
    if (!name) return

    onSearch(getSamples)
  }, [name])

  React.useEffect(() => {
    if (!isPrinting || !printElement.current) return

    notificationStore.setLoading(true)
    debounce(async () => {
      try {
        await printPage(printElement.current)
      } finally {
        setIsPrinting(false)
        setColumn(4)
        notificationStore.setLoading(false)
      }
    }, 3000)()
  }, [isPrinting])

  React.useEffect(() => {
    samples.map((sample, index) => flavorWheel(JSON.parse(sample.wheelData), isEnterprisePlus && base64Logo, `tastify-wheel-${index}`))
  }, [samples.length])

  React.useEffect(() => {
    if (!openWheel) return

    debounce(() => {
      flavorWheel(JSON.parse(wheelData), isEnterprisePlus && base64Logo)
    }, 300)()
  }, [openWheel])

  return (
    <>
      {isPrinting &&
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=0.1, maximum-scale=0.1, user-scalable=0" />
          <body className="print-out" />
        </Helmet>
      }
      <HeaderTitle
        backUrl={reviewSamplesLink}
        breadcrumb={t('common.backTo', { menu: t('sample.samples') })}
        title={t('menu.compareSamples')}
      />

      <Container className={classes.buttonWrapper}>
        <Box className={classes.headerButtons}>
          <Typography variant='h4' sx={{display: { md: 'none' }}}>{ids.length} {t('sample.samples')}</Typography>
          <FormControlLabel
            control={<Checkbox checked={highlightDifference} onClick={() => setHighlightDifference(!highlightDifference)} />}
            label={`${t('compare.highlightDifferences')}`}
            sx={{ml: 2}}
          />
        </Box>
        <Box className={classes.headerButtons}>
          <Button
            fullWidth
            size='large'
            variant='contained'
            onClick={addSamples}
            startIcon={<AddCircleOutline />}
            sx={{ml: 2, display: { md: 'none' } }}
          >
            {t('compare.addSample')}
          </Button>
          <Button
            fullWidth
            size='large'
            sx={{ml: 2}}
            startIcon={<ClearIcon color='error' />}
            onClick={resetCompare}
          >
            {t('compare.clearSelection')}
          </Button>
          <Button
            fullWidth
            size='large'
            variant='contained'
            onClick={onPrint}
            sx={{ml: 2, display: { xs: 'none', md: 'inline-flex' } }}
          >
            {t('compare.exportComparison')}
          </Button>
        </Box>
      </Container>

      <Container className={classes.exportButton}>
        <Button
          fullWidth
          size='large'
          variant='contained'
          onClick={onPrint}
        >
          {t('compare.exportComparison')}
        </Button>
      </Container>

      <Box ref={printElement} data-name='sample-compare'>
        {isPrinting &&
          <Container sx={{textAlign: 'center'}}>
            <Logo width={200} height={75} />
            <Typography variant='h3' color='primary' sx={{mb: 3}}>
              {t('sample.sampleComparison')}
            </Typography>
          </Container>
        }
        <Container>
          <Box className={clsx(classes.infoHeader, classes.mobileOnly)}>
            <Typography variant="h6">{t('descriptor.flavorWheel')}</Typography>
          </Box>
          <Grid container className={classes.wheelWrapper}>
            <Grid item xs className={classes.headerWrapper}>
              <Box className={classes.headerContainer}>
                <Typography variant='h3'>{ids.length}</Typography>
                <Typography variant='h6'>{t('sample.samples')}</Typography>
              </Box>
            </Grid>
            {samples.map((sample, index) =>
              <Grid item xs key={index} className={classes.wheelItem}>
                {headerCard(sample, index)}
              </Grid>
            )}

            {[...Array(column - samples.length)].map((_, index) =>
              <Grid item xs key={index} className={classes.headerItem}>
                {headerCard('')}
              </Grid>
            )}
          </Grid>
        </Container>

        <Container className={classes.infoWrapper}>
          <Box
            className={classes.infoHeader}
            onClick={() => setOpenSampleInfo(!openSampleInfo)}
          >
            <Typography variant="h6">{t('sample.sampleInfo')}</Typography>
            {!isPrinting && (openSampleInfo ?
              <ExpandLess color='primary' fontSize='large' /> :
              <ExpandMore color='primary' fontSize='large' />
            )}
          </Box>

          <Box className={clsx(classes.infoItemWrapper, openSampleInfo && 'collapsed')}>
            <Collapse in={openSampleInfo} timeout="auto" unmountOnExit>
              {sampleInformations.map((item, index) => renderInfoItem(item, index))}
            </Collapse>
          </Box>
        </Container>

        <Container className={classes.infoWrapper}>
          <Box
            className={classes.infoHeader}
            onClick={() => setOpenDescriptorInfo(!openDescriptorInfo)}
          >
            <Typography variant="h6">{t('descriptor.attributes')}</Typography>
            {!isPrinting && (openDescriptorInfo ?
              <ExpandLess color='primary' fontSize='large' /> :
              <ExpandMore color='primary' fontSize='large' />
            )}
          </Box>

          <Box className={clsx(classes.infoItemWrapper, openDescriptorInfo && 'collapsed')}>
            <Collapse in={openDescriptorInfo} timeout="auto" unmountOnExit>
              {(cuppingProtocol === 'sca' ? scaDescriptor : descriptors).map((item, index) => renderInfoItem(item, index))}
            </Collapse>
          </Box>
        </Container>

        <Container className={classes.infoWrapper}>
          <Box
            className={classes.infoHeader}
            onClick={() => setOpenGreenGradingInfo(!openGreenGradingInfo)}
          >
            <Typography variant="h6">{t('compare.greenGrading')}</Typography>
            {!isPrinting && (openGreenGradingInfo ?
              <ExpandLess color='primary' fontSize='large' /> :
              <ExpandMore color='primary' fontSize='large' />
            )}
          </Box>

          <Box className={clsx(classes.infoItemWrapper, openGreenGradingInfo && 'collapsed')}>
            <Collapse in={openGreenGradingInfo} timeout="auto" unmountOnExit>
              {greenGradings.map((item, index) => renderInfoItem(item, index))}
            </Collapse>
          </Box>
        </Container>

        <Container className={classes.infoWrapper}>
          <Box
            className={classes.infoHeader}
            onClick={() => setOpenDefectsInfo(!openDefectsInfo)}
          >
            <Typography variant="h6">{t('descriptor.defects')}</Typography>
            {!isPrinting && (openDefectsInfo ?
              <ExpandLess color='primary' fontSize='large' /> :
              <ExpandMore color='primary' fontSize='large' />
            )}
          </Box>

          <Box className={clsx(classes.infoItemWrapper, openDefectsInfo && 'collapsed')}>
            <Collapse in={openDefectsInfo} timeout="auto" unmountOnExit>
              {greenGradingDefects.map((item, index) => {
                const defect = {...item, icon: IconDefect}
                return renderInfoItem(defect, index)
              })}
            </Collapse>
          </Box>
        </Container>
      </Box>

      {renderSearchSampleModal}
      {renderWheelModal}
    </>
  )
})
