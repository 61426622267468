/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-06 21:23:13
 * @modify date 2022-02-06 21:23:13
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import {centered, colors} from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
      border: 'none',
      borderRadius: 0,
      padding: 5,
      borderBottom: `1px solid ${colors.border.primary}`,
      [theme.breakpoints.up('md')]: {
        borderRadius: 5,
        border: `1px solid ${colors.border.primary}`,
      }
    },
    mobileWrapper: {
      position: 'relative',
      border: 'none',
      borderRadius: 0,
      padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
      borderBottom: `1px solid ${colors.border.primary}`,
      [theme.breakpoints.up('md')]: {
        borderRadius: 5,
        border: `1px solid ${colors.border.primary}`,
      },
    },
    mobileButtonWrapper: {
      position: 'absolute',
      top: -7,
      right: 20,
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        textAlign: 'right',
        top: 'unset',
        right: 'unset',
      }
    },
    mobileButtonWrapperExpand: {
      position: 'absolute',
      right: 20,
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        textAlign: 'right',
        top: 'unset',
        right: 'unset',
      }
    },
    iconButton: {
      margin: 0,
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark
      }
    },
    firstRow: {
      alignItems: 'center'
    },
    mobileFirstRow: {
      alignItems: 'center',
      marginTop: theme.spacing(-3),
      marginLeft: theme.spacing(-1)
    },
    mobileRowDetail: {
      alignItems: 'center',
      marginTop: theme.spacing(-3),
      marginLeft: theme.spacing(3),
    },
    displayMobile: {
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    displayDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block'
      }
    },
    collapseWrapper: {
      '&.MuiGrid-item': {
        paddingTop: 0
      }
    },
    buttonWrapper: {
      position: 'absolute',
      top: 10,
      right: 20,
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        textAlign: 'right',
        top: 'unset',
        right: 'unset',
      }
    },
    buttonWrapperExpand: {
      position: 'absolute',
      top: 56,
      right: 20,
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        textAlign: 'right',
        top: 'unset',
        right: 'unset',
      }
    },
    textCenter: {
      [theme.breakpoints.up('md')]: {
        textAlign: 'center',
        justifyContent: 'center'
      }
    },
    score: {
      color: 'white',
      backgroundColor: colors.primary.o50,
      borderRadius: '50%',
      fontSize: 'smaller',
      fontWeight: 600,
      height: 35,
      width: 35,
      marginLeft: 'auto',
      marginRight: 'auto',
      ...centered
    },
    highlight: {
      backgroundColor: colors.secondary.o50,
      borderRadius: 5,
      margin: 5,
      padding: "5px 2px",
      textAlign: "center"
    },
    textBold: {
      textAlign: 'center',
      fontWeight: 600,
    }
  })
)
