/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-23 21:43:07
 * @modify date 2022-03-23 21:43:07
 */

 import { find, findIndex, clone, pullAt, filter } from 'lodash'
 import { applySnapshot, getSnapshot } from "mobx-state-tree"
 import { defects as listDefects } from 'constants/form'

 export const withGreenGradingActions = (self: any) => ({
  actions: {
    addDefect(defect, beansAmount) {
      const selectedDefect = find(listDefects, ['id', defect])
      if (!selectedDefect) return

      const newDefect = {
        id: selectedDefect.id,
        count: beansAmount
      }

      self.setValue('defects', [...self.defects, newDefect])
    },
    removeDefects(index) {
      const newDefects = clone(self.defects)
      pullAt(newDefects, index)

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        defects: newDefects
      })
    },
    setDefectInformation() {
      const { greenGradingSettings } = self.rootStore.greenGradingStore

      const setDefects = greenGradingSettings.map(setting => {
        return { ...setting, count: 0 }
      })

      self.setValue('defects', setDefects)
    },
    unsetDefectInformation() {
      const setDefects = filter(self.defects, defect => { return defect.count > 0 })

      self.setValue('defects', setDefects)
    },
    setDefectScore(id, score) {
      const defects = clone(self.defects)
      const index = findIndex(defects, ['id', id])

      if (index < 0) return

      defects[index].count = score
      self.setValue('defects', defects)
    }
  }
 })
