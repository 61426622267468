/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-22 03:06:50
 * @modify date 2022-04-22 03:06:50
 * @desc [description]
 */


 import {isEmpty, pick} from "lodash"
 import { flow, applySnapshot } from "mobx-state-tree"

 import i18n from "i18n/i18n"
 import { ApiResult } from "services/api"

 export const withCompanyActions = (self: any) => ({
   actions: {
    setCompany(company) {
      applySnapshot(self, {
        ...company,
        id: 'me'
      })
    },
    getCompany: flow(function * (payload = {}) {
      try {
        const id = isEmpty(payload) ? self.id : ''
        const res: ApiResult = yield self.environment.companyApi.find(id, undefined, payload)

        if (res.kind === "ok") {
          self.setCompany(res.data)
          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    saveCompany: flow(function * () {
      try {
        const { setNotification } = self.rootStore.notificationStore

        const payload: any = { ...pick(self, ['name', 'countryCode', 'companyType']), id: 'me' }
        const res: ApiResult = yield self.environment.companyApi.save(payload)

        if (res.kind === "ok") {
          self.setCompany(res.data)
          setNotification({
            severity: 'success',
            message: i18n.t('company.success.successfullyUpdateCompany')
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    uploadLogo: flow(function * (logo) {
      try {
        const { setNotification } = self.rootStore.notificationStore
        const payload: any = {
          id: self.id,
          logo: logo
        }

        const res: ApiResult = yield self.environment.companyApi.uploadImage(payload)
        if (res.kind === "ok") {
          self.setCompany(res.data)
          setNotification({
            severity: 'success',
            message: i18n.t('company.success.successfullyUploaded')
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    removeLogo: flow(function * () {
      try {
        const { setNotification } = self.rootStore.notificationStore
        const additionalPath: string = 'remove_logo'

        const res: ApiResult = yield self.environment.companyApi.remove(self.id!, {}, additionalPath)
        if (res.kind === "ok") {
          self.logo = ''
          setNotification({
            severity: 'success',
            message: i18n.t('company.success.successfullyRemoved')
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    updateNumberOfLicense: flow(function * () {
      try {
        const { setNotification } = self.rootStore.notificationStore

        const payload: any = {numberOfLicenses: self.licensesNumber}
        const res: ApiResult = yield self.environment.numberOfLicenseApi.save(payload)

        if (res.kind === "ok") {
          setNotification({
            severity: 'success',
            message: i18n.t('subscription.success.successfullyUpdatedLicense')
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    updatePlan: flow(function * () {
      try {
        const meta = {context: 'update_subscription'}
        const payload: any = { ...pick(self, ['initialPlanIdSelection']), id: 'me' }
        const res: ApiResult = yield self.environment.companyApi.save(payload, meta)
        if (res.kind === "ok") self.setCompany(res.data)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    activateDormantAccount: flow(function * (email: string) {
      try {
        const meta = { context: 'activate_dormant_account' }
        const payload: any = { email, dormantAccountStatus: 'enable', id: 'me' }
        yield self.environment.companyApi.save(payload, meta)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    activateDormantAccountBySubscription: flow(function * (subscriptionCustomerId) {
     try {
       const payload: any = { subscriptionCustomerId }
       yield self.environment.dormantAccountApi.save(payload)
     } catch (error: any) {
       yield self.checkForGeneralError(error)
     }
   })
  }
 })
