/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import { uniq, map } from 'lodash'
import { Instance, SnapshotOut, types, flow } from "mobx-state-tree"

import { ApiResult } from "services/api"
import {
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"
import { DATA_PER_PAGE } from "config/env"

/**
 * Model description here for TypeScript hints.
 */
export const LocationStoreModel = types
  .model("LocationStore")
  .props({
    locations: types.optional(types.array(types.string), []),
    search: types.optional(types.string, ""),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .actions(self => ({
    getLocations: flow(function * () {
      try {
        const res: ApiResult = yield self
          .environment
          .cuppingSessionsLocationApi
          .all({
            "size": DATA_PER_PAGE,
            "term": self.search
          })

        if (res.kind === "ok") self.setValue('locations', uniq(map(res.data, 'name')))
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
  }))

type LocationStoreType = Instance<typeof LocationStoreModel>
export interface LocationStore extends LocationStoreType {}
type LocationStoreSnapshotType = SnapshotOut<typeof LocationStoreModel>
export interface LocationStoreSnapshot extends LocationStoreSnapshotType {}
