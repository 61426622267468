/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-03 16:43:21
 * @modify date 2022-02-03 16:43:21
 */
import { createStyles, makeStyles } from '@mui/styles'
 
export const useStyles = makeStyles(() =>
  createStyles({
    tab: {
      border: 'none'
    }
  })
)