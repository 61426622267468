/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-02 23:12:33
 * @modify date 2022-03-02 23:12:33
 */

import React from 'react'
import { observer } from 'mobx-react-lite'
import { Backdrop, CircularProgress } from '@mui/material'

import { useStores } from "models"

export const Loading: React.FC = observer(() => {
  const { notificationStore: { loading, setLoading }} = useStores()
  
  return (
    <Backdrop
      sx={{ color: 'white', zIndex: 1600 }}
      open={loading}
      onClick={() => setLoading(false)}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
})