/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */

import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"

import { filterProps } from './filter-props'
import {cuppingSampleProps, sampleProps} from './sample-props'
import { shareProps } from './share-props'
import { withReviewSampleActions } from './review-sample-action'
import {chain, isEmpty } from "lodash";

/**
 * Model description here for TypeScript hints.
 */
export const ReviewSampleStoreModel = types
  .model("ReviewSampleStore")
  .props({
    search: types.optional(filterProps, {}),
    samples: types.optional(types.array(sampleProps), []),
    page: types.optional(types.number, 1),
    totalPage: types.optional(types.number, 0),
    records: types.optional(types.number, 0),
    nextUrl: types.maybe(types.string),
    share: types.optional(shareProps, {}),
    cuppingSamples: types.optional(types.array(cuppingSampleProps), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withReviewSampleActions)
  .views(self => ({
    get searchValues() {
      return chain(self.search)
              .omitBy(isEmpty)
              .assign({
                ...(self.search.generalSearch && {generalSearch: self.search.generalSearch}),
                ...(self.search.scoredByMe && {scoredByMe: self.search.scoredByMe})
              })
              .value()
    }
  }))

type ReviewSampleStoreType = Instance<typeof ReviewSampleStoreModel>
export interface ReviewSampleStore extends ReviewSampleStoreType {}
type ReviewSampleStoreSnapshotType = SnapshotOut<typeof ReviewSampleStoreModel>
export interface ReviewSampleStoreSnapshot extends ReviewSampleStoreSnapshotType {}
