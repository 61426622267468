/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-03 20:13:36
 * @modify date 2022-03-31 21:03:25
 */
import React from 'react'
import copy from 'copy-to-clipboard'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { TabPanel } from '@mui/lab'
import { Grid, InputAdornment, IconButton, Popover, Typography } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'

import { useStores } from 'models'
import { FormInput } from 'components'

export const ApiToken: React.FC = observer(() => {
  const { t } = useTranslation()
  const { userStore: { apiToken } } = useStores()

  const [anchorInfo, setAnchorInfo] = React.useState<HTMLButtonElement | null>(null)
  const openInfo = Boolean(anchorInfo)

  React.useEffect(() => {
    if (!openInfo) return

    setTimeout(() => {
      setAnchorInfo(null)
    }, 3000)
  }, [openInfo])

  return (
    <TabPanel value="apiToken">
      <Grid container rowSpacing={3} sx={{mb: 3}}>
        <Grid item xs={12}>
          <FormInput label={t('profile.apiTokenDescription')}
            textFieldProps={{
              value: apiToken,
              disabled: true,
              InputProps: {
                endAdornment: 
                <InputAdornment position="end">
                  <IconButton
                    onClick={(event) => {
                      copy(apiToken)
                      setAnchorInfo(event.currentTarget)
                    }}
                  >
                    <ContentCopy />
                  </IconButton>
                </InputAdornment>,
              }
            }}
          />
          <Popover
            open={openInfo}
            anchorEl={anchorInfo}
            onClose={() => setAnchorInfo(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: {width: 300}
            }}
          >
            <Typography sx={{ p: 2 }}>{t('profile.success.apiTokenCopied')}</Typography>
          </Popover>
        </Grid>
      </Grid>
    </TabPanel>
  )
})