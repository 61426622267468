/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-06 18:36:40
 * @modify date 2022-03-06 18:36:40
 */

import React from 'react'
import clsx from 'clsx'
import moment from "moment"
import { getName } from 'country-list'
import { observer } from 'mobx-react-lite'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Carousel from 'react-material-ui-carousel'
import {
  toString,
  isArray,
  truncate,
  startCase,
  isEmpty,
  cloneDeep,
  orderBy,
  some,
  every,
  has,
  chain,
  clone,
  includes,
  pull,
  toNumber, camelCase
} from 'lodash'
import {
  Container, Button, Tab, Box, Chip, Grid, Typography,
  Divider, Paper, TextField, SvgIcon, Stack, IconButton, Tooltip, TooltipProps, tooltipClasses,
  Menu, MenuItem, FormControlLabel, Switch, FormGroup, Checkbox
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { DeleteOutline, VisibilityOutlined, VisibilityOffOutlined, KeyboardArrowDown } from '@mui/icons-material'
import ReactHtmlParser from 'react-html-parser'
import { styled } from '@mui/system'

import { useStores } from 'models'
import {globalAction, pointerMessages, validateEmail} from 'utils'
import { Logo, IconWarning } from 'assets/images'
import { findRoute } from 'constants/routes'
import { HeaderTitle, AvatarProfile, ModalWrapper, FormInput, GreenGradingReview } from 'components'
import { sampleStatuses } from 'constants/form'
import { centered, colors as colorAsset } from 'assets'
import { ANONYMOUS_NAME, CUPPING_SESSION_OWNER_TITLE } from "config/env"

import {
  SampleInformation,
  UploadImage,
  ScaInformation,
  SucafinaTastingInformation,
  CommercialInformation
} from './components'
import { useStyles, carouselIndicator, heightCarousel, indicatorContainer } from './sample-review.styles'

export const SampleReview: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const sampleToken = searchParams.get('sample-id') || ''

  const {
    userStore: { isAuthenticated, languagePreference, userId, canApproveSample },
    companyStore: { scaFeature },
    cuppingSessionStore: { selectedCuppingSession, exportReviews },
    sampleStore: { samples, saveSample, updateCalibrationStatus, setValue: setSampleValue, selectedSample,
      selectedSample: {
      sampleId, id, name, sampleType, countryCode, referenceNumber, status, cachedAverageScore, uniqueToken, farmerNotes, storySubject,
      storyDescription, greenGradings, selectedGreenGrading, selectGreenGrading, scores, setScore, updateScore,
      addGreenGrading, images, getImages, removeImage, saveStatus, isScoreTarget, purchaseContractReference, salesContractReference, setValue: setSelectedSampleValue,
      selectedScore: { id: scoreId, cuppingProtocol, sampleInformations, saveScore, setValue: setScoreValue },
    }},
    guideStore: { guideSample },
    sampleReportStore: {getSampleReport},
    reviewSampleStore: { shareSamples, share: { to, message, setValue: setShareValue }},
    notificationStore
  } = useStores()

  const [currentSample, setCurrentSample] = React.useState()
  const [tab, setTab] = React.useState('')
  const [isEdit, setIsEdit] = React.useState(false)
  const [isEditScore, setIsEditScore] = React.useState(false)
  const [isEditSample, setIsEditSample] = React.useState(false)
  const [isEditGreenGrading, setIsEditGreenGrading] = React.useState(false)
  const [isEditDescriptor, setIsEditDescriptor] = React.useState(false)
  const [openUpload, setOpenUpload] = React.useState(false)
  const [isGuideSample] = React.useState(guideSample)
  const reviewSamplesLink = findRoute('reviewSamples')
  const homeLink = findRoute('home')
  const sampleInformationLink = findRoute('sampleInformation')
  const sampleReviewLink = findRoute('sampleReview')
  const [isShowCuppingTargetScore, setIsShowCuppingTargetScore] = React.useState(false)
  const [showButtonCuppingTargetScore, setShowButtonCuppingTargetScore] = React.useState(false)
  const [groupScore] = React.useState<any[]>([])
  const [scoreIdOwner, setScoreIdOwner] = React.useState('')
  const [cachedAverageScoreOwnerData, setCachedAverageScoreOwnerData] = React.useState('')
  const [anchorExport, setAnchorExport] = React.useState<null | HTMLElement>(null)
  const openExport= Boolean(anchorExport)
  const [openShare, setOpenShare] = React.useState(false)
  const [shareErrors, setShareErrors] = React.useState<any>({})
  const [ids, setIds] = React.useState<String[]>([])
  const [checked, setChecked] = React.useState({})
  const [checkedAll, setCheckedAll] = React.useState(false)
  const [greenGradingTab, setGreenGradingTab] = React.useState('')

  const isPublicSession = selectedCuppingSession?.isPublic
  const isSca = selectedCuppingSession?.cuppingProtocol?.includes('sca')
  const isSucafinaTasting = selectedCuppingSession?.cuppingProtocol === 'sucafina_tasting'
  const isCommercial = selectedCuppingSession?.cuppingProtocol === 'commercial'
  const hasTeamScore = isSca ?
    selectedSample?.scores?.some((score: any) => score.cupperId === toNumber(userId)) :
    true

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip placement="top-start" {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#B12028',
      color: 'rgba(0, 0, 0, 0.87)',
      border: '1px solid #dadde9'
    },
  }));

  const tableItems = [
    {label: 'sample.referenceNumber', value: referenceNumber},
    {label: 'sample.country', value: getName(countryCode)},
    {label: 'sample.type', value: startCase(sampleType)},
    {label: 'sample.averageScore', value: cachedAverageScore},
    {label: 'report.standardDeviation', value: '0.6'},
    {label: 'sample.status.title', value: status ? t(`sample.status.${status}`) : ''},
  ]

  const tableItemCuppingTargetScore = [
    {label: t('sample.referenceNumber'), value: referenceNumber},
    {label: t('sample.country'), value: getName(countryCode)},
    {label: t('sample.type'), value: startCase(sampleType)},
    {label: t('sample.averageScore'), value: cachedAverageScore},
    {label: t('sample.targetScore'), value: cachedAverageScoreOwnerData},
    {label: t('report.standardDeviation'), value: '0.6'},
    {label: t('sample.status.title'), value: status ? t(`sample.status.${status}`) : ''},
  ]

  const groupScoreFormat = ["roastLevel","fragrance","acidity","body","salt","bittersweet","flavor","aftertaste","mouthfeel","balance","cleanCupScore","sweetnessScore","overall","uniformity","cleanCup","sweetness","defects","notes"]

  const onRemoveImage = (image) => {
    globalAction(notificationStore, {
      action: async() => await removeImage(image),
      afterAction: loadImages()
    })
  }

  const indicatorImages = images.map(image => {
    return (
      <Box className={classes.imageWrapper}>
        {isEdit &&
          <IconButton
            size="small"
            color='primary'
            className={clsx('bg', classes.imageDelete)}
            onClick={() => onRemoveImage(image)}
          >
            <DeleteOutline sx={{fontSize: 16}} />
          </IconButton>
        }
        <img src={image.imageUrl} alt={image.imageName} />
      </Box>
    )
  })

  const setValue = (setAction: any, attribute: string, value: any) => {
    if (setAction === setScoreValue) {
      setIsEditScore(true)

      if (isArray(value)) {
        value.forEach((v, i) => setScoreValue(`${attribute}${i + 1}`, v))
        return
      }
    }

    if (setAction === setSelectedSampleValue) setIsEditSample(true)
    if (setAction === selectedGreenGrading?.setValue || setAction === selectedGreenGrading?.setDefectScore) setIsEditGreenGrading(true)

    setAction(attribute, value)
  }

  const onSave = async () => {
    const { setNotification, setValue: setNotificationValue } = notificationStore

    setIsEdit(false)

    try{
      setNotificationValue('loading', true)
      let actions: Array<any> = []
      if (isEditSample) actions.push(saveSample(id))
      if (isEditGreenGrading) actions.push(addGreenGrading(selectedGreenGrading.defects))
      if (isEditScore) actions.push(saveScore(id))
      if (isEditScore || isEditDescriptor) actions.push(saveScore(id))

      const [resSample, resGreenGrading, resScore] = await Promise.all(actions)
      resSample?.base?.map(message => setNotification(message))
      setIsEditSample(false)

      resGreenGrading?.base?.map(message => setNotification(message))
      setIsEditGreenGrading(false)

      resScore?.base?.map(message => setNotification(message))
      setIsEditScore(false)
      setIsEditDescriptor(false)
    } finally {
      setNotificationValue('loading', false)
    }
  }

  const changeStatus = (statusSample) => {
    const isRemoved = (status === statusSample)
    setSelectedSampleValue('status', statusSample)

    globalAction(notificationStore, {
      action: saveStatus
    })

    if (isRemoved) setSelectedSampleValue('status', '')
  }

  const changeCalibrationStatus = (status: boolean) => {
    samples.forEach(s => s.setValue('isScoreTarget', status))
    updateCalibrationStatus(true)
  }

  const onExport = (path) => globalAction(notificationStore, { action: async() => await exportReviews(path) })

  const loadImages = () => globalAction(notificationStore, { action: getImages })

  const setDefault = () => {
    setScore(scores.findIndex( x => x.cupperTitle === CUPPING_SESSION_OWNER_TITLE))
    if (greenGradings.length > 0) setGreenGradingTab(toString(greenGradings[0].id))

    samples.map(sample => {
      handleCheckbox(false, sample.id)
    })
  }

  const setIdOwnerData = () => {
    scores.map(sc => {
      if (sc.cupperTitle === CUPPING_SESSION_OWNER_TITLE) setScoreIdOwner(sc.id)

    })
  }

  const setCachedAverageScoreOwner = () => {
    scores.map(sc => {
      if (sc.cupperTitle === CUPPING_SESSION_OWNER_TITLE) setCachedAverageScoreOwnerData(sc.totalScore)

    })
  }

  const setGroupScoreData = (item) => {
    if (isShowCuppingTargetScore == true) {
      groupScoreFormat.map(format =>
        orderBy(item, "isScoreTarget", "asc").map((samples, indexs) =>
          samples.sampleInformations.map(sample => {
            if (sample.key === format) {
              if(sample.key === "roastLevel" && samples.isScoreTarget == 1) return
              if(sample.key === "notes") return

              //add data to variable groupScore
              groupScore.push({ id: samples.id, key: sample.key, label: sample.label, score: sample.score, descriptors: sample.descriptors, isScoreTarget: samples.isScoreTarget, value: [] })

              if (format === "uniformity") {
                sample.value.map(data =>
                  groupScore[groupScore.findIndex((obj => obj.key === 'uniformity')) + indexs]['value'].push(data)
                )
              }

              if (format === "cleanCup") {
                sample.value.map(data =>
                  groupScore[groupScore.findIndex((obj => obj.key === 'cleanCup')) + indexs]['value'].push(data)
                )
              }

              if (format === "sweetness") {
                sample.value.map(data =>
                  groupScore[groupScore.findIndex((obj => obj.key === 'sweetness')) + indexs]['value'].push(data)
                )
              }

            }
          })
        )
      )
    }
    else {
      groupScore.length = 0
    }

  }

  const renderCuppers = (
    <Box className={classes.profileWrapper}>
      {scores.slice().sort((a) => a.cupperTitle === CUPPING_SESSION_OWNER_TITLE ? -1 : 1).map((sc, index) => {
        if (isPublicSession && sc.cupperName === ANONYMOUS_NAME) return

        return (
          <Box
            key={index}
            className={clsx(classes.profile, sc.id === scoreId && 'active')}
            onClick={() => {
              setScore(scores.findIndex( x => x.id === sc.id))
              setIsShowCuppingTargetScore(false)
              groupScore.length = 0
              if(sc.cupperTitle === CUPPING_SESSION_OWNER_TITLE){
                setShowButtonCuppingTargetScore(false)
                return
              }
              setShowButtonCuppingTargetScore(selectedSample.isScoreTarget && selectedSample.selectedScore.cupperTitle !== CUPPING_SESSION_OWNER_TITLE)

            }}
          >
            <AvatarProfile
              isReport
              name={sc.cupperName}
              title={t(`sampleScore.${sc.cupperTitle}`)}
              active={true}
            />
            <Chip
              label={sc.totalScore}
              color={sc.id === scoreId ? "primary" : "default"}
              className={classes.profileScore}
            />
          </Box>
        )
      })}
    </Box>
  )

  const renderDescription = (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} className={classes.carouselWrapper}>
        <Box sx={{maxWidth: 'fit-content'}}>
          <Typography variant='h5' sx={{mb: 2, wordBreak: 'break-word'}}>{name}</Typography>
          <table className={classes.table}>
            <tbody>
              {!isShowCuppingTargetScore ?
                <>
                  {tableItems.map((item, index) => {
                    if(selectedCuppingSession.cuppingProtocol ==="sca_descriptive" && item.label === "sample.averageScore") return
                    if (item.label === 'sample.status.title' && isEdit && canApproveSample) {
                      return (
                        <tr key={index}>
                          <td>{t(item.label)}</td>
                          <td>
                            <Stack direction='row' spacing={2}>
                              {sampleStatuses.map(sampleStatus =>
                                <Button
                                  key={sampleStatus.key}
                                  variant={sampleStatus.key === status ? 'contained' : 'outlined'}
                                  onClick={() => changeStatus(sampleStatus.key)}
                                  color={sampleStatus.key === 'rejected' ? 'error' : 'primary'}
                                >
                                  {t(sampleStatus.label)}
                                </Button>
                              )}
                            </Stack>
                          </td>
                        </tr>
                      )
                    }

                    return (
                      <tr key={index}>
                        <td>{t(item.label)}</td>
                        <td style={{wordBreak: 'break-word'}}>{item.value}</td>
                      </tr>
                    )
                  })}
                </> :
                <>
                  {tableItemCuppingTargetScore.map((item, index) => {
                    if(selectedCuppingSession.cuppingProtocol ==="sca_descriptive" && item.label === "sample.averageScore") return
                    if (index === (tableItemCuppingTargetScore.length - 1) && isEdit && canApproveSample) {
                      return (
                        <tr key={index}>
                          <td>{item.label}</td>
                          <td>
                            <Stack direction='row' spacing={2}>
                              {sampleStatuses.map(sampleStatus =>
                                <Button
                                  key={sampleStatus.key}
                                  variant={sampleStatus.key === status ? 'contained' : 'outlined'}
                                  onClick={() => changeStatus(sampleStatus.key)}
                                  color={sampleStatus.key === 'rejected' ? 'error' : 'primary'}
                                >
                                  {t(sampleStatus.label)}
                                </Button>
                              )}
                            </Stack>
                          </td>
                        </tr>
                      )
                    }

                  return (
                    <>
                      {item.label == t('sample.targetScore') ?
                        <tr key={index}>
                          <td><Typography variant='subtitle2' sx={{ mr: 2, color: colorAsset.secondary.main }}>{item.label}</Typography></td>
                          <td><Typography variant='subtitle2' sx={{ mr: 2, color: colorAsset.secondary.main }}>{item.value}</Typography></td>
                        </tr>
                        :
                        <tr key={index}>
                          <td>{item.label}</td>
                          <td>{item.value}</td>
                        </tr>
                      }
                    </>
                  )
                })}
              </>
            }

            </tbody>
          </table>
          <Typography variant='body3'>{t('report.farmerNotes')}</Typography>
          <Divider sx={{mt: 0.5, mb: 1}} />
          {isEdit ?
            <TextField
              fullWidth
              multiline
              minRows={3}
              value={farmerNotes}
              onChange={(e) => setValue(setSelectedSampleValue, 'farmerNotes', e.target.value)}
            /> :
            <Typography variant='body3' color='text.disabled' sx={{maxWidth: 265, wordBreak: 'break-word'}}>
              {farmerNotes || t('report.addNotesHere')}
            </Typography>
          }
        </Box>
        <Box className={classes.buttonWrapper}>
          <Box className={classes.boxWrapper}>
            <Typography variant='subtitle1' >{t('sample.samples.purchaseContract')}</Typography>
          </Box>
          <Typography variant='subtitle1' sx={{mt: 1, mb: 1}}>{isEmpty(purchaseContractReference) ? t('sample.samples.notAvailable') : purchaseContractReference}</Typography>
          <Box className={classes.boxWrapper}>
            <Typography variant='subtitle1' >{t('sample.samples.salesContract')}</Typography>
          </Box>
          <Typography variant='subtitle1' sx={{mt: 1, mb: 1}}>{isEmpty(salesContractReference) ? t('sample.samples.notAvailable') : salesContractReference}</Typography>
          <Box className={classes.boxWrapper}>
            <Typography variant='subtitle1' >{t('sample.samples.cuppingSessionId')}</Typography>
          </Box>
          <Typography variant='subtitle1' sx={{mt: 1, mb: 1}}>{id}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} className={classes.carouselWrapper}>
        <Carousel
          IndicatorIcon={indicatorImages}
          indicatorContainerProps={{ style: { ...indicatorContainer }}}
          indicatorIconButtonProps={{ style: { ...carouselIndicator }}}
          navButtonsWrapperProps={{ style: { height: heightCarousel }}}
          className={classes.carousel}
        >
          {isEmpty(indicatorImages) ?
            <Paper elevation={0} className={classes.noImageWrapper}>
              <SvgIcon component={Logo} inheritViewBox />
            </Paper>
            : indicatorImages.map((item, index) =>
              <Paper elevation={0} key={index} sx={{height: heightCarousel}}>
                {item}
              </Paper>
          )}
        </Carousel>
        <Box className={classes.buttonWrapper}>
          {isAuthenticated &&
            <Button
              variant="contained"
              disabled={isShowCuppingTargetScore || isEdit}
              onClick={() => {
                setIsEdit(true)
                setShowButtonCuppingTargetScore(false)
              }}
              sx={{mb: 1}}
            >
              {t('report.editSummary')}
            </Button>
          }

          {hasTeamScore &&
            <Button
              variant="contained"
              disabled={isShowCuppingTargetScore || isEdit }
              onClick={() => {
                const query = selectedGreenGrading ? `?green_grading=${selectedGreenGrading.id}` : ''
                navigate(`/sample-report/${uniqueToken}${query}`)
              }}
              sx={{ mb: 1 }}
            >
              {t('report.seeReport')}
            </Button>
          }

          {isAuthenticated &&
            <Button
              disabled={isShowCuppingTargetScore || isEdit}
              variant="contained"
              onClick={() => setOpenUpload(true)}
              sx={{mb: 1}}
            >
              {t('report.addPhoto')}
            </Button>
          }
          {isAuthenticated && hasTeamScore &&
            <Button
              variant="contained"
              disabled={isShowCuppingTargetScore || isEdit}
              onClick={() => setOpenShare(true)}
              sx={{ mb: 1 }}
            >
              {t('sample.shareSample')}
            </Button>
          }
          <Button
              variant="contained"
              sx={{mb: 1}}
          >
            {t('sample.greenGrading')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )

  const renderSampleInformation = () => {
    if (isSca && selectedSample && selectedSample.selectedScore) {
      return <ScaInformation
                key={`${selectedSample.key}${tab}`}
                sampleId={sampleId}
                score={selectedSample.selectedScore}
                isEdit={isEdit}
                setIsEditScore={() => setIsEditScore(true)}
                setIsEditDescriptor={() => setIsEditDescriptor(true)}
                language={languagePreference}
                cuppingProtocol={selectedCuppingSession?.cuppingProtocol}
              />
    }

    if (isSucafinaTasting && selectedSample && selectedSample.selectedScore) {
      return <SucafinaTastingInformation
                key={`${selectedSample.key}${tab}`}
                sampleId={sampleId}
                score={selectedSample.selectedScore}
                isEdit={isEdit}
                setIsEditScore={() => setIsEditScore(true)}
                setIsEditDescriptor={() => setIsEditDescriptor(true)}
                language={languagePreference}
              />
    }

    if (isCommercial && selectedSample && selectedSample.selectedScore) {
      return <CommercialInformation
        key={`${selectedSample.key}${tab}`}
        sampleId={sampleId}
        score={selectedSample.selectedScore}
        isEdit={isEdit}
        setIsEditScore={() => setIsEditScore(true)}
        setIsEditDescriptor={() => setIsEditDescriptor(true)}
        language={languagePreference}
      />
    }

    if (!isShowCuppingTargetScore) {
      return (
        <>
          {sampleInformations.map(sample =>
            <SampleInformation
              key={`${sample.key}${tab}`}
              {...sample}
              uniqKey={sample.key}
              label={t(sample.label)}
              isEdit={isEdit}
              isCoe={cuppingProtocol === 'cup_of_excellence'}
              language={languagePreference}
              onChange={(score) => setValue(setScoreValue, sample.key, score)}
              isScoreTarget={0}
              setIsEditDescriptor={() => setIsEditDescriptor(true)}
              setDescriptor={setScoreValue}
              sampleId={sampleId}
            />
          )}
          {setGroupScoreData(scores)}
        </>
      )
    }

    return (
      <>
        {setGroupScoreData(scores)}
        {groupScore.filter(x => (x.id === scoreId || x.id === scoreIdOwner)).map(sample =>
          <SampleInformation
            key={`${sample.key}${tab}`}
            {...sample}
            uniqKey={sample.key}
            label={t(sample.label)}
            isEdit={isEdit}
            isCoe={cuppingProtocol === 'cup_of_excellence'}
            language={languagePreference}
            onChange={(score) => setValue(setScoreValue, sample.key, score)}
            isScoreTarget={sample.isScoreTarget}
          />
        )}
      </>
    )
  }

  const renderButtonsCalibration = () => {
    return(
     <>
        {showButtonCuppingTargetScore &&
          <Button
            variant="contained"
            size='small'
            color='warning'
            className={classes.buttonRoundedCalibration}
            onClick={() => { !isShowCuppingTargetScore ? setIsShowCuppingTargetScore(true) : setIsShowCuppingTargetScore(false) }}
          >
            {!isShowCuppingTargetScore ?
              <>
                <VisibilityOutlined
                  sx={{
                    color: 'warning',
                    width: 16,
                    height: 18,
                    mr: 1
                  }}
                />
                <Typography variant='body3'>{t('sample.show')} {t('sample.cuppingScoreTarget')}</Typography>
              </>
              :
              <>
                <VisibilityOffOutlined
                  sx={{
                    color: 'warning',
                    width: 16,
                    height: 18,
                    mr: 1
                  }}
                />
                <Typography variant='body3'>{t('sample.hide')} {t('sample.cuppingScoreTarget')}</Typography>
              </>
            }
          </Button>
        }
        {(scores.length > 1 && some(scores, ['cupperTitle', CUPPING_SESSION_OWNER_TITLE]) === true && isScoreTarget) &&
          <Button
            variant="contained"
            size='small'
            className={classes.buttonRoundedCalibration}
            onClick={() => {
              getSampleReport(uniqueToken)
              navigate(`/sample-report-calibration/${uniqueToken}`)
            }}
            disabled={isEdit || scores.length === 1}
          >
            <Typography variant='body3'>{t('sample.viewCalibration')}</Typography>
          </Button>
        }

        {(scores.length > 1 && some(scores, ['cupperTitle', CUPPING_SESSION_OWNER_TITLE]) === false && !isScoreTarget) &&
           <HtmlTooltip
           title={
            <React.Fragment>
              <Grid container width={300}>
                  <Grid item xs={1} sx={{p: 1}}>
                   <SvgIcon component={IconWarning} inheritViewBox sx={{ height: 15, width: 15, mt: 0.2}}/>
                   </Grid>
                   <Grid item xs={11} sx={{p: 1}}>
                   <Typography variant='body2' sx={{color: 'white', fontWeight: 600}}>{ReactHtmlParser(t('sample.viewCalibrationWarningTitle'))}</Typography>
                   <Typography variant='body3' sx={{color: 'white', fontWeight: 400}}>{ReactHtmlParser(t('sample.viewCalibrationWarning'))}</Typography>
                   </Grid>
                 </Grid>
            </React.Fragment>
          }
        >
            <span>
              <Button
                variant="contained"
                disabled={true}
                size='small'
                className={classes.buttonRoundedCalibration}
              >
                <Typography variant='body3'>{t('sample.viewCalibration')}</Typography>
              </Button>
            </span>
          </HtmlTooltip>
        }
      </>
    )
  }

  const renderSampleItem = (
    <TabPanel value={toString(id)} className={classes.tabPanelWrapper}>
      {renderCuppers}
      {renderDescription}

      {!isSca && (
        <Box sx={{mt: 10, mb: 4, ...centered, flexDirection: {xs: 'column-reverse', md: 'row'}}}>
          <Box sx={{flex: 1,alignSelf: {xs: 'baseline', md: 'auto'}}}>
            {(selectedCuppingSession?.isOwner && scores.length > 1) &&
              <>
                <Typography variant='h6'>{t('report.sampleCalibration')}</Typography>
                <FormGroup>
                  <FormControlLabel
                    label={t('report.enableCalibration')}
                    control={
                      <Switch
                        disabled={!isEdit}
                        checked={isScoreTarget}
                        onChange={(e) => {
                          setSelectedSampleValue('isScoreTarget', e.target.checked)
                          updateCalibrationStatus()
                        }}
                      />
                    }
                  />
                </FormGroup>
              </>
            }
          </Box>
          <Box sx={{display: "flex",flex: 1,justifyContent: 'flex-end', alignItems:"flex-end", mr: 2, mt: 3}}>
            {renderButtonsCalibration()}
          </Box>
        </Box>
      )}

      {scoreId &&
        <Box sx={{mt: 3}}>
          <Typography variant='h6' sx={{mb: 2}}>{t('report.sampleTypeInformation')}</Typography>
          {renderSampleInformation()}
        </Box>
      }

      <Box sx={{mt: 5}}>
        <Typography variant='h6' sx={{mb: 3}}>{t('report.sampleStory')}</Typography>
        {isEdit ?
          <Box>
            <TextField
              fullWidth
              size='small'
              placeholder={t('report.subject')}
              value={storySubject}
              onChange={(e) => setValue(setSelectedSampleValue, 'storySubject', truncate(e.target.value, {'length': 200, 'omission': ''}))}
              margin='dense'
            />
            <TextField
              fullWidth
              multiline
              size='small'
              minRows={3}
              placeholder={t('report.writeStoriesDescription')}
              value={storyDescription}
              onChange={(e) => setValue(setSelectedSampleValue, 'storyDescription', truncate(e.target.value, {'length': 300, 'omission': ''}))}
              margin='dense'

            />
          </Box> :
          <Box>
            <Typography variant='body2' sx={{wordBreak: 'break-word'}}>{storySubject || '-' }</Typography>
            <Typography variant='body3' sx={{wordBreak: 'break-word'}}>{storyDescription || '-' }</Typography>
          </Box>
        }
      </Box>
      {greenGradings.length > 0 &&
        <Box sx={{mt: 5}}>
          <Typography variant='h6' sx={{mb: 3}}>{t('report.greenGradingInformation')}</Typography>
          <TabContext value={greenGradingTab}>
            <TabList
              textColor="primary"
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              onChange={(event, value) => {
                setGreenGradingTab(value)
                selectGreenGrading(value)
              }}
              aria-label="Profile settings"
            >
              {greenGradings.map(gd => {
                const selected = greenGradingTab === toString(gd.id)

                return (
                  <Tab
                    key={gd.id}
                    value={toString(gd.id)}
                    label={
                      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Typography variant='body2'>{t('greenGrading.types.scaSpecialty')}</Typography>
                        <Typography variant='body3' color={selected ? colorAsset.text.primary : colorAsset.text.o50}>
                          {moment(gd.createdAt).format('DD-MM-YYYY')}
                        </Typography>
                      </Box>
                    }
                    disabled={isEdit && !selected}
                  />
                )}
              )}
            </TabList>

            {greenGradings.map(gd => (
              <TabPanel key={gd.id} value={toString(gd.id)}>
                <GreenGradingReview
                  key={gd.id}
                  greenGrading={gd}
                  isEdit={isEdit}
                  setGreenGrading={(id, score) => setValue(selectedGreenGrading.setValue, id, score)}
                  setDefect={(id, score) => setValue(selectedGreenGrading.setDefectScore, id, score)}
                />
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      }

      {isEdit &&
        <Box sx={{mt: 5}}>
          <Button
            variant="contained"
            onClick={onSave}
            className='wide'
            sx={{mr: 1}}
          >
            {t('common.save')}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setShowButtonCuppingTargetScore((selectedSample.isScoreTarget && selectedSample.selectedScore.cupperTitle !== CUPPING_SESSION_OWNER_TITLE))
              setIsEdit(false)
              setSampleValue('selectedSample', currentSample)
            }}
            className='wide'
            sx={{mr: 1}}
          >
            {t('common.discard')}
          </Button>
        </Box>
      }
    </TabPanel>
  )

  const renderButtons = () => {
    if (!isAuthenticated) return

    return (
      <Container sx={{pb: {xs: 1, md: 0}, textAlign: 'right'}}>
        {(selectedCuppingSession?.isOwner && scores.length > 1) &&
          <FormControlLabel
            label={t('report.enableAllCalibration')}
            control={
              <Switch
                checked={every(samples, 'isScoreTarget')}
                onChange={(e) => changeCalibrationStatus(e.target.checked)}
              />
            }
          />
        }

        <Button
          variant="contained"
          sx={{ml: 1}}
          onClick={() => navigate(`/individual-report/${selectedCuppingSession?.uniqueToken}`)}
        >
          {t('report.seeIndividualReport')}
        </Button>

        {isPublicSession && (
          <Button
            variant="contained"
            sx={{ml: 1}}
            onClick={() => navigate(`/sample-report-collective/${selectedCuppingSession?.uniqueToken}`)}
          >
            {t('report.seeCollectiveReport')}
          </Button>
        )}

        {isSca ? (
          <Button
            variant="contained"
            onClick={() => onExport('sca_reports')}
            sx={{ml: 1}}
          >
            {t('report.exportReport')}
          </Button>
        ) : (
          <Button
            variant="contained"
            endIcon={<KeyboardArrowDown />}
            onClick={e => setAnchorExport(e.currentTarget)}
            sx={{ml: 1}}
          >
            {t('report.exportOptions')}
          </Button>
        )}
        <Menu
          anchorEl={anchorExport}
          open={openExport}
          onClose={() => setAnchorExport(null)}
        >
          <MenuItem
            disabled={!scaFeature}
            onClick={() => {
              setAnchorExport(null)
              onExport('contingency_table')
            }}
          >
            {t('report.exportContingencyTable')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorExport(null)
              onExport('summary')
            }}
          >
          {t('report.exportSummary')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorExport(null)
              onExport('detailed_report')
            }}
          >
            {t('report.exportDetailedReport')}
          </MenuItem>
        </Menu>
      </Container>
    )
  }

  const onShareSamples = () => {
    setShareErrors({})
    const validateEmails = chain(to).split(',')
        .map(email => { return validateEmail(email.replace(/\s/g, ""))})
        .every(Boolean).value()

    if (!validateEmails) {
      setShareErrors({ "email": [{ message: t('sample.error.emailIsInvalid') }] })
      return
    }

    setShareValue('samplesToShare', ids.join(','))

    globalAction(notificationStore, {
      action: shareSamples,
      afterAction: () => {
        setIds([])
        setOpenShare(false)
      }
    })
  }

  const handleCheckboxAll = (checked: boolean) => {
    setCheckedAll(checked);
    samples.map(sample => {
      handleCheckbox(checked, sample.id)
    })
  };

  const onCheckAll = () => {
    if(checkedAll) return ids.length = 0

    samples.map(sample => {
        ids.push(sample.id)
      })
  }

  const handleCheckbox =(checked: boolean, id:string)  => {
    setChecked((values) => ({
      ...values,
      [id]: checked
    }));
  }

  const onCheck = (id: string) => {
    const newIds = clone(ids)

    if (includes(ids, id)) {
      pull(newIds, id)

    } else {
      newIds.push(id)
    }
    setIds(newIds)

  }

  React.useEffect(() => {
    setShareValue('message', t('sample.shareSampleText'))

    if (!guideSample && isGuideSample) navigate(homeLink)
  }, [guideSample])

  React.useEffect(() => {
    updateScore()
    setIdOwnerData()
    setCachedAverageScoreOwner()
  }, [sampleInformations])

  React.useEffect(() => {
    setTab(toString(id))
    loadImages()
    setCurrentSample(cloneDeep(selectedSample))
  }, [id])

  React.useEffect(() => {
    if (scores.length > 0 && !selectedSample?.selectedScore?.id) {
      const score = selectedSample.scores.filter(score => score.cupperName !== ANONYMOUS_NAME)?.[0]
      if (!score) return

      selectedSample.setValue('selectedScore', cloneDeep(score))
    }
  }, [scores.length])

  React.useEffect(() => {
    if (sampleToken) {
      const sample = samples.find(sample => sample.uniqueToken === sampleToken)
      if (sample) {
        setSampleValue('selectedSample', sample)
        setTab(toString(sample.id))
      }
    }
  }, [sampleToken])

  React.useEffect(() => {
    setDefault()
  }, [])

  return (
    <>
      <HeaderTitle
        {...(isAuthenticated && {
          dataLinks: [
            {
              backUrl: reviewSamplesLink ,
              breadcrumb: t('common.backTo', { menu: t('menu.reviewSamples')}),
            },
            {
              backUrl: sampleInformationLink ,
              breadcrumb: t('common.goTo', { menu: t('menu.sampleInformation')}),
            },
            ...(sampleToken ? [{
              backUrl: sampleReviewLink,
              breadcrumb: t('common.goTo', { menu: t('menu.cuppingSessionReview')}),
            }] : [])
          ]
        })}
        title={sampleToken ? t('report.cuppingSampleReview') : `${t('report.cuppingSession')} #${selectedCuppingSession?.id}`}
        {...(selectedCuppingSession?.cuppingProtocol && {
          subtitle: `<strong>${t(`options.cuppingProtocol.${camelCase(selectedCuppingSession?.cuppingProtocol)}`)}</strong> ${t(`cuppingSession.cuppingForm`)}`
        })}
      />


      {renderButtons()}

      {toString(id) === tab &&
        <Container sx={{px: {xs: 0, md: 3}, mt: sampleToken ? 3 : 0}}>
          <TabContext value={tab}>
            <TabList
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              onChange={(event, value) => {
                setSampleValue('selectedSample', value)
                setTab(value)
                setIsShowCuppingTargetScore(false)
                groupScore.length = 0
                setShowButtonCuppingTargetScore(false)
              }}
              sx={{ display: sampleToken ? 'none' : 'flex' }}
            >
              {samples.map(sample =>
                <Tab key={sample.id} value={toString(sample.id)} label={sample.sampleId} disabled={isEdit} />
              )}
            </TabList>

            {renderSampleItem}
          </TabContext>
        </Container>
      }

      <UploadImage open={openUpload} onClose={() => setOpenUpload(false)} />

      <ModalWrapper
          maxWidth='sm'
          open={openShare}
          onClose={() => {
            setOpenShare(false);
          }}
      >
        <Typography variant='h5' sx={{mb: 2}}>{t('sample.shareSampleList')}</Typography>
        <Divider />

        <FormInput
            label={t('sample.sampleName')}
        />
        <Grid item xs={12}>
          <Grid container spacing={1} >
            <Grid item xs={1} >
              <Checkbox sx={{
                color: "#8D57B2",
                '&.Mui-checked': {
                  color: "#8D57B2",
                },
              }}
              checked={checkedAll}
              onChange={(event, checked) =>
              {
                onCheckAll()
                handleCheckboxAll(checked)
              }
              }
              />
            </Grid>
                <Grid item xs={8} sx={{mt: 1.5}}>
                  <Typography variant='body2'>{checkedAll ? t('cuppingSession.clearAll'):t('cuppingSession.selectAll')}</Typography>
                </Grid>



          </Grid>
        </Grid>
        {samples.map((sample, index) =>

          <Grid item xs={12}>
          <Grid container spacing={1} >
          <Grid item xs={1} >
            <Checkbox sx={{
              color: "#8D57B2",
              '&.Mui-checked': {
                color: "#8D57B2",
              },
            }}
            checked={checked[sample.id]}
            onChange={(event, checked) => {
              onCheck(sample.id)
              handleCheckbox(checked, sample.id)
            }} />
          </Grid>

            {isEmpty(sample.name) ?
                <Grid item xs={8} sx={{mt: 1.5}}>
                  <Typography variant='body2'>{sample.sampleId}</Typography>
                </Grid> :
                <Grid item xs={8} sx={{mt: 1.5}}>
                  <Typography variant='body2'>{sample.sampleId} - {sample.name}</Typography>
                </Grid>
            }


          </Grid>
          </Grid>
        )}
        <FormInput
            label={t('sample.to')}
            textFieldProps={{
              value: to,
              onChange: (e) => setShareValue('to', e.target.value),
              helperText: has(shareErrors, 'email') ? pointerMessages('email', shareErrors) : t('report.emailShareSampleDescription'),
              error: has(shareErrors, 'email')
            }}
        />
        <FormInput
            label={t('sample.message')}
            textFieldProps={{
              multiline: true,
              rows: 3,
              value: message,
              onChange: (e) => setShareValue('message', e.target.value)
            }}
        />

        <Button
            variant='contained'
            className='wide'
            sx={{mt: 3}}
            disabled={!ids || !to || !message}
            onClick={onShareSamples}
        >
          {t('report.send')}
        </Button>
      </ModalWrapper>
    </>
  )
})
