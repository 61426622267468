/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-16 22:28:00
 * @modify date 2022-02-16 22:28:00
 */

import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { centered } from 'assets'
   
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardWrapper: {
      ...centered,
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
      padding: `${theme.spacing(3)} ${theme.spacing(2)}`
    },
    button: {
      minWidth: 'fit-content',
      marginLeft: theme.spacing(1)
    }
  })
)