/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-04 21:44:04
 * @modify date 2022-02-04 21:44:04
 */

import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchForm: {
      [theme.breakpoints.down('md')]: {
        '& .MuiInputBase-root': {
          borderRadius: 0,

          '& fieldset': {
            borderLeft: 'none',
            borderRight: 'none',
          }
        }
      }
    },
    gridWrapper: {
      borderTop: `1px solid ${colors.border.primary}`,
      [theme.breakpoints.up('md')]: {
        border: 'none',
      }
    },
    gridHeader: {
      fontWeight: 500,
      fontSize: '0.875rem',
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      }
    },
    gridItem: {
      borderBottom: `1px solid ${colors.border.primary}`,
      [theme.breakpoints.up('md')]: {
        border: 'none',
      }
    },
    toggleButtonWrapper: {
      display: 'flex', 
      justifyContent: 'end'
    },
    toggleButton: {
      border: 'none',
      padding: 0,
      marginLeft: theme.spacing(0.5),
      '&:hover': {
        color: theme.palette.primary.dark,
        backgroundColor: 'transparent'
      },
      '&.Mui-selected': {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',
        '&:hover': {
          color: theme.palette.primary.dark,
          backgroundColor: 'transparent'
        },
      }
    }
  })
)