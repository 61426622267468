import {fulfillmentTypes} from "constants/form"
import {at, chain, groupBy, zipObject} from "lodash";

export const withSampleShipmentViews = (self: any) => ({
  views: {
    get groupSamplesToBeShipment(){
      let groupSamples
      const groupFulfillmentType = groupBy(self.samplesToBeShipment, 'sampleFulfillmentType')
      groupSamples = zipObject(fulfillmentTypes, at(groupFulfillmentType, fulfillmentTypes))

      if (!groupSamples[fulfillmentTypes[0]]) {
        delete groupSamples[fulfillmentTypes[0]]
      }

      if (groupSamples[fulfillmentTypes[1]]) {
        const groupThirdPartyLocation = groupBy(groupSamples.third_party, 'sampleLocation')
        groupSamples = { ...groupSamples, ...groupThirdPartyLocation }
      }

      delete groupSamples[fulfillmentTypes[1]]

      return groupSamples
    },
    get isFormComplete(){
      const email = chain(self.samplesToBeShipment)
        .filter(sample => sample.sampleFulfillmentType !== fulfillmentTypes[0])
        .every(sample => !!sample.thirdPartyEmail)
        .value()

      return self.shipmentForm.customer && self.shipmentForm.recipient && email
    },
    get sampleShipmentCounter(){
      let local = 0
      let thirdParty = 0

      Object.keys(self.groupSamplesToBeShipment).forEach(group => {
        if (group === fulfillmentTypes[0]) {
          local++
        } else {
          thirdParty++
        }
      })

      return {
        local,
        thirdParty
      }
    }
  }
})
