import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import {
  Button, Grid, Box, MenuItem, Typography, Select,
} from '@mui/material'

import { useStores } from 'models'
import { ModalWrapper, FormInput } from 'components'

import { useStyles } from './green-grading-options.styles'

interface GreenGradingOptionsModalProps {
  sampleId: string
  setSampleId: (value: string) => void
}

export const GreenGradingOptionsModal = observer(({ sampleId, setSampleId }: GreenGradingOptionsModalProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    masterSampleStore: { cuppingSessionSamples },
    greenGradingStore: { newGreenGrading, greenGradingTypes, getGreenGradingSettings, setSelectedGreenGradingType },
    modalStore: { greenGradingOptions, setValue: setModalValue }
  } = useStores()

  const [isLoading, setIsLoading] = React.useState(false)
  const [greenGradingTypeId, setGreenGradingTypeId] = React.useState('')

  const showGreenGrading = async () => {
    try {
      setIsLoading(true)
      await getGreenGradingSettings(greenGradingTypeId)
      setSelectedGreenGradingType(greenGradingTypeId)
      newGreenGrading(greenGradingTypeId)
      setModalValue('greenGrading', true)
      setModalValue('greenGradingOptions', false)
    } finally {
      setIsLoading(false)
    }
  }

  const onCancelAdding = () => {
    setModalValue('greenGradingOptions', false)
  }

  React.useEffect(() => {
    if (greenGradingOptions) setGreenGradingTypeId('')
  }, [greenGradingOptions])

  return (
    <ModalWrapper
      maxWidth='sm'
      open={greenGradingOptions}
      onClose={onCancelAdding}
    >
      <Typography variant='h6' sx={{mb: 2}}>
        {t('greenGrading.newGreenGrading')}
      </Typography>

      <Grid container spacing={2} alignItems='end' mb={2}>
        <Grid item xs={12}>
          <FormInput label={t('greenGrading.selectSample')} formControlProps={{margin: 'none'}}>
            <Select
              size='small'
              value={sampleId}
              onChange={(e) => setSampleId(e.target.value)}
            >
              <MenuItem value='' sx={{ height: 34 }} />
              {cuppingSessionSamples.map(item =>
                <MenuItem key={item.id} value={item.id}>{item.sampleName}</MenuItem>
              )}
            </Select>
          </FormInput>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems='end'>
        <Grid item xs={12}>
          <FormInput label={t('greenGrading.greenGradingFormOptions')} formControlProps={{margin: 'none'}}>
            <Select
              size='small'
              value={greenGradingTypeId}
              onChange={(e) => setGreenGradingTypeId(e.target.value)}
            >
              <MenuItem value='' sx={{ height: 34 }} />
              {greenGradingTypes.map(item =>
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              )}
            </Select>
          </FormInput>
        </Grid>
      </Grid>

      <Box className={classes.buttonWrapper}>
        <LoadingButton
          variant='contained'
          disabled={!greenGradingTypeId || !sampleId}
          loading={isLoading}
          onClick={showGreenGrading}
          className='wide'
          sx={{mr: 2}}
        >
          {t('common.continue')}
        </LoadingButton>
        <Button
          variant='outlined'
          onClick={onCancelAdding}
          className='wide'
        >
          {t('common.cancel')}
        </Button>
      </Box>
    </ModalWrapper>
  )
})
