/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-05 22:07:52
 * @modify date 2022-02-05 22:07:52
 */

import React from 'react'
import { has } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Typography, FormControl, FormLabel, TextField, Button, Stack } from '@mui/material'

import { useStores } from "models"
import { globalAction } from 'utils'
import { ModalWrapper } from 'components'
import { validateEmail, pointerMessages } from 'utils'

export const InviteCupper: React.FC = observer(() => {
  const { t } = useTranslation()
  const [errors, setErrors] = React.useState<any>({})

  const { 
    cupperStore: { email, message, invite, getCuppers, seatsAvailable, getCupperSummaries, setValue: setCupperValue },
    modalStore: { inviteCupper, setValue: setModalValue },
    guideStore: { guideCupper },
    notificationStore
  } = useStores()

  const loadCuppers = () => {
    globalAction(notificationStore, { 
      action: async () => {
        await getCuppers()
        await getCupperSummaries()
      } 
    })
  }

  const onInviteCupper = async () => {
    if (guideCupper) {
      setModalValue('inviteCupper', false)
      setCupperValue('email', '')
      return
    }
    
    setErrors({})
    
    if (!validateEmail(email)) {
      setErrors({"email": [{ message: t('cupper.error.emailIsInvalid') }]})
      return
    }

    globalAction(notificationStore, { 
      action: invite,
      afterAction: () => {
        loadCuppers()
        setCupperValue('email', '')
        setModalValue('inviteCupper', false)
      },
      setErrors: setErrors
    })  
  }

  React.useEffect(() => {
    errors.base?.map(message => notificationStore.setNotification(message))
  }, [errors])

  React.useEffect(() => {
    setCupperValue('message', `${t('cupper.invitationTemplate', { company: 'Tastify'})}`)
  }, [])
  
  return (
    <ModalWrapper
      open={inviteCupper}
      onClose={() => setModalValue('inviteCupper', false)}
      maxWidth='sm'
    >
      <Typography variant="h6" sx={{mb: 2}}>
        {t('cupper.inviteCuppers')}
      </Typography>

      <Typography variant="body3" paragraph>
        {t('cupper.cupperRemainingDescription', { counter: seatsAvailable })}
      </Typography>

      <FormControl fullWidth margin='normal'>
        <FormLabel>{t('cupper.email')}</FormLabel>
        <TextField
          type="email"
          size='small'
          placeholder="example@email.com"
          autoFocus
          value={email}
          onChange={(e) => setCupperValue('email', e.target.value)}
          error={has(errors, 'email')} 
          helperText={pointerMessages('email', errors)}
        />
      </FormControl>

      <FormControl fullWidth margin='normal'>
        <FormLabel>{t('cupper.emailMessage')}</FormLabel>
        <TextField
          multiline
          minRows={3}
          value={message}
          onChange={(e) => setCupperValue('message', e.target.value)}
        />
      </FormControl>

      <Stack direction="row" spacing={2} sx={{mt: 3}}>
        <Button
          variant="contained"
          onClick={onInviteCupper}
        >
          {t('cupper.invite')}
        </Button>
        <Button
          variant='outlined'
          onClick={() => setModalValue('inviteCupper', false)}
        >
          {t('common.cancel')}
        </Button>
      </Stack>
    </ModalWrapper>
  )
})