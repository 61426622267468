/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-21 23:48:47
 * @modify date 2022-04-21 23:48:47
 */

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, TextField } from '@mui/material'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

import { useStores } from 'models'
import { globalAction } from 'utils'
import { colors } from 'assets'
import { findRoute } from 'constants/routes'
import { DORMANT_PLAN_ID } from "config/env";

import { StripePaymentProps } from './stripe-payment.props'

export const StripePayment: React.FC<StripePaymentProps> = observer((props: StripePaymentProps) => {
  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    userStore: { email },
    companyStore: { subscriptionPlanId, initialPlanIdSelection },
    subscriptionStore: { saveCard },
    notificationStore
  } = useStores()
  const { onSave, subscriptionCustomerId, onSuccess } = props

  const homeLink = findRoute('home')
  const [emailStripe, setEmailStripe] = React.useState(email)

  const planId = subscriptionCustomerId ? DORMANT_PLAN_ID : subscriptionPlanId ?? initialPlanIdSelection

  const options = {
    style: {
      base: {
        fontFamily: 'HeroNew',
        fontWeight: 500,
        color: colors.text.primary,
        '::placeholder': {
          color: colors.text.o50,
        },
      },
    },
  }

  const onCharge = async () => {
    if (!stripe || !elements) return

    const cardElement = elements.getElement(CardElement)
    if (!cardElement) return

    const resultToken = await stripe.createToken(cardElement)
    if (resultToken?.error) {
      notificationStore.setNotification({
        severity: 'error',
        message: resultToken.error.message
      })
    }

    if (!resultToken || resultToken.error) return

    globalAction(notificationStore, {
      action: async () => await saveCard({
        planId,
        subscriptionCustomerId,
        stripeToken: resultToken.token?.id,
        stripeEmail: emailStripe
      }),
      afterAction: () => {
        onSuccess ? onSuccess() : navigate(homeLink)
      }
    })
  }

  return (
    <>
      <TextField
        fullWidth
        size='small'
        placeholder={t('subscription.email')}
        value={emailStripe}
        onChange={(e) => setEmailStripe(e.target.value)}
        sx={{mb: 3}}
      />
      <CardElement options={options}/>
      <Button
        fullWidth
        variant='contained'
        onClick={onSave || onCharge}
        sx={{mt: 3}}
        disabled={!stripe || !elements}
      >
        {t('subscription.saveChanges')}
      </Button>
    </>
  )
})
