/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-28 20:22:31
 * @modify date 2022-02-28 20:22:31
 */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Card, Grid, TextField, Typography } from '@mui/material'

import { useStores } from 'models'
import { acidityDescriptorList } from 'constants/acidity-descriptors'
import { aftertasteDescriptorList } from 'constants/aftertaste-descriptors'
import { balanceDescriptorList } from 'constants/balance-descriptors'
import { bodyDescriptorList } from 'constants/body-descriptors'
import { flavorDescriptorList } from 'constants/flavor-descriptors'
import { defectDescriptorList } from 'constants/defect-descriptors'
import { Descriptor } from 'components'

import { Defect } from '../index'

export const Coe: React.FC = observer(() => {
  const { t } = useTranslation()
  const { sampleStore: {
    selectedSample: { sampleId, selectedScore: {
      fragranceDescriptors, fragranceDry, fragranceCrust, fragranceBreak,  
      acidity, acidityDescriptors, acidityIntensity, 
      mouthfeel, mouthfeelDescriptors, mouthfeelRoughToSmooth, 
      flavor, flavorDescriptors,
      aftertaste, aftertasteDescriptors,
      balance, balanceDescriptors,
      cleanCupScore, cleanCupDescriptors,
      sweetnessScore, overall, defects, defectsDescriptors, notes, cups, intensity,
      addingScore, decreaseScore,
      setScoreValue
    }}
  } } = useStores()

  const fragranceSlides = [
    {
      label: t('descriptor.dry'),
      value: fragranceDry,
      onChange: (e) => setScoreValue('fragranceDry', e.target.value)
    },
    {
      label: t('descriptor.crust'),
      value: fragranceCrust,
      onChange: (e) => setScoreValue('fragranceCrust', e.target.value)
    },
    {
      label: t('descriptor.break'),
      value: fragranceBreak,
      onChange: (e) => setScoreValue('fragranceBreak', e.target.value)
    }
  ]

  const aciditySlides = [
    {
      label: t('descriptor.acidityIntensity'),
      value: acidityIntensity,
      onChange: (e) => setScoreValue('acidityIntensity', e.target.value)
    }
  ]

  const mouthfeelSlides = [
    {
      label: t('descriptor.roughToSmooth'),
      value: mouthfeelRoughToSmooth,
      onChange: (e) => setScoreValue('mouthfeelRoughToSmooth', e.target.value)
    }
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`fragranceOrAroma${fragranceDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.fragranceOrAroma')}
          descriptors={fragranceDescriptors}
          descriptorList={flavorDescriptorList}
          addDescriptor={(values) => setScoreValue('fragranceDescriptors', values)}
          slides={fragranceSlides}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`acidity${acidityDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.acidity')}
          descriptors={acidityDescriptors}
          descriptorList={acidityDescriptorList}
          addDescriptor={(values) => setScoreValue('acidityDescriptors', values)}
          slides={aciditySlides}
          score={acidity}
          addScore={() => addingScore(acidity, 'acidity')}
          decreaseScore={() => decreaseScore(acidity, 'acidity')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`mouthfeel${mouthfeelDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.mouthfeel')}
          descriptors={mouthfeelDescriptors}
          descriptorList={bodyDescriptorList}
          addDescriptor={(values) => setScoreValue('mouthfeelDescriptors', values)}
          slides={mouthfeelSlides}
          score={mouthfeel}
          addScore={() => addingScore(mouthfeel, 'mouthfeel')}
          decreaseScore={() => decreaseScore(mouthfeel, 'mouthfeel')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`flavor${flavorDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.flavor')}
          descriptors={flavorDescriptors}
          descriptorList={flavorDescriptorList}
          addDescriptor={(values) => setScoreValue('flavorDescriptors', values)}
          score={flavor}
          addScore={() => addingScore(flavor, 'flavor')}
          decreaseScore={() => decreaseScore(flavor, 'flavor')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`aftertaste${aftertasteDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.aftertaste')}
          descriptors={aftertasteDescriptors}
          descriptorList={aftertasteDescriptorList}
          addDescriptor={(values) => setScoreValue('aftertasteDescriptors', values)}
          score={aftertaste}
          addScore={() => addingScore(aftertaste, 'aftertaste')}
          decreaseScore={() => decreaseScore(aftertaste, 'aftertaste')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`balance${balanceDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.balance')}
          descriptors={balanceDescriptors}
          descriptorList={balanceDescriptorList}
          addDescriptor={(values) => setScoreValue('balanceDescriptors', values)}
          score={balance}
          addScore={() => addingScore(balance, 'balance')}
          decreaseScore={() => decreaseScore(balance, 'balance')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`cleanCup${cleanCupDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.cleanCup')}
          descriptors={cleanCupDescriptors}
          descriptorList={flavorDescriptorList}
          addDescriptor={(values) => setScoreValue('cleanCupDescriptors', values)}
          score={cleanCupScore}
          addScore={() => addingScore(cleanCupScore, 'cleanCupScore')}
          decreaseScore={() => decreaseScore(cleanCupScore, 'cleanCupScore')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`sweetness`}
          sampleId={sampleId}
          title={t('descriptor.sweetness')}
          score={sweetnessScore}
          addScore={() => addingScore(sweetnessScore, 'sweetnessScore')}
          decreaseScore={() => decreaseScore(sweetnessScore, 'sweetnessScore')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Defect
          isCoe
          key={`defects${defectsDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.defects')}
          descriptors={defectsDescriptors}
          descriptorList={defectDescriptorList}
          addDescriptor={(values) => setScoreValue('defectsDescriptors', values)}
          cups={cups}
          intensity={intensity}
          setCup={(value) => setScoreValue('cups', value)}
          setIntensity={(value) => setScoreValue('intensity', value)}
          score={defects}
          onChange={(value) => setScoreValue('defects', value)}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`overall`}
          sampleId={sampleId}
          title={t('descriptor.overall')}
          score={overall}
          addScore={() => addingScore(overall, 'overall')}
          decreaseScore={() => decreaseScore(overall, 'overall')}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Card sx={{px: 4, py: 3}}>
          <Typography variant='h6' sx={{mb: 2}}>{t('descriptor.notes')}</Typography>
          <TextField
            fullWidth
            multiline
            minRows={8}
            value={notes}
            onChange={(e) => setScoreValue('notes', e.target.value)}
          />
        </Card>
      </Grid>
    </Grid>
  )
})