/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-07 19:28:17
 * @modify date 2022-03-07 19:28:17
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import {colors} from "../../../../assets";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scoreWrapper: {
      display: 'flex',
      borderBottom: '1px solid #EBE8F2'
    },
    scoreItem: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px 8px',
      borderBottom: '1px solid #EBE8F2',
      '&.no-border': {
        borderBottom: 'none'
      }
    },
    descriptor: {
      width: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 16,
      wordBreak: 'break-word'
    },
    cup: {
      width: 24,
      height: 24,
      cursor: 'pointer',
      border: `1px solid ${colors.magnolia}`,
      backgroundColor: 'transparent',
      marginRight: theme.spacing(1),
      '&.selected': {
        border: 'none',
        backgroundColor: '#FF5F00'
      },
      '&.defective': {
        border: 'none',
        backgroundColor: '#B12028'
      }
    },
    cupIcon: {
      fill: colors.magnolia,
      height: '77%',
      '&.selected': {
        fill: 'white'
      }
    },
  })
)
