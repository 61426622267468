/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-20 17:16:56
 * @modify date 2022-04-20 17:16:56
 */

import { types } from 'mobx-state-tree'
import { 
  withSetValue,
  withReset 
} from "models/extensions"

export const shareProps = types.model({
  samplesToShare: types.optional(types.string, ''),
  title: types.optional(types.string, ''),
  to: types.optional(types.string, ''),
  message: types.optional(types.string, '')
})
.extend(withSetValue)
.extend(withReset)