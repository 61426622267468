/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-14 21:43:51
 * @modify date 2022-04-14 21:43:51
 */

import React from 'react'
import moment from 'moment'
import { last, take, isEmpty, truncate } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Grid, Divider, Container, Box, Typography, Card, IconButton, SvgIcon } from '@mui/material'

import { useStores } from "models"
import { globalAction } from 'utils'
import { findRoute } from 'constants/routes'
import { HeaderTitle, TextInline } from 'components'
import { cuppers, startCupping, reviewSamples, noActivity, upcomingSession, IconStartCupping } from 'assets/images'

import { useStyles } from './home.styles'
import { CardScore, CardUpcoming, CardImageLink } from './components'

export const Home: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { 
    userStore: { name }, 
    dashboardStore: { latestActivities, upcomingCupping, samplesCupped, cuppingSessions, highestScoreOfThisWeek, lowestScoreOfThisWeek, getDashboardData }, 
    notificationStore
  } = useStores()

  const formCuppingSessionsLink = findRoute('formCuppingSessions')

  const imageLinks = [
    {
      title: t('menu.cuppers'),
      image: cuppers,
      linkUrl: findRoute('cuppers')
    },
    {
      title: t('menu.startCupping'),
      image: startCupping,
      linkUrl: findRoute('cuppingSessions')
    },
    {
      title: t('menu.reviewSamples'),
      image: reviewSamples,
      linkUrl: findRoute('reviewSamples')
    },
  ]

  const productivities = {
    samplesCupped,
    cuppingSessions,
    highestScoreOfThisWeek,
    lowestScoreOfThisWeek,
  }

  const onGetData = () => globalAction(notificationStore, { action: getDashboardData })

  React.useEffect(() => {
    onGetData()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <HeaderTitle 
        breadcrumb={t('menu.home')}
        title={t('home.welcome', { cupper: name })} 
      />
      <Container sx={{px: {xs: 0, md: 3}}}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7} sx={{mb: {xs: 3}}}>
            <Grid container spacing={{xs: 0, md: 2}} className={classes.linkWrapper}>
              {imageLinks.map((link, index) => 
                <React.Fragment key={index}>
                  <Grid item xs className={classes.linkItem}>
                    <CardImageLink {...link} />
                  </Grid>
                  {last(imageLinks) !== link && 
                    <Divider orientation="vertical" flexItem sx={{my: 2, display: {md: 'none'}}} />
                  }
                </React.Fragment>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{mb: {xs: 3}}}>
            <Card className={classes.activityWrapper} data-testid='cardActivity'>
              <Typography variant='h6' sx={{mb: 1.5}}>
                {t('home.latestActivity')}
              </Typography>
              {take(latestActivities, 3).map((activity: any, index) => 
                <TextInline 
                  key={index}
                  title={truncate(activity.description, {'length': 80})}
                  description={moment(activity.startsAt).format('DD MMM YYYY HH:mm')}
                />
              )}

              {isEmpty(latestActivities) && 
                <Box className={classes.emptyActivity}>
                  <img src={noActivity} alt="No Activity" />
                  <Typography variant='subtitle2' sx={{my: 2}}>
                    {t('home.noActivity')}
                  </Typography>
                </Box>
              }
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{px: {xs: 0, md: 3}}}>
        <Grid container className={classes.wrapper} spacing={5}>
          <Grid item xs={12} md={6} sx={{mb: {xs: 3}}} data-testid='productivityInformation'>
            <Box>
              <Container sx={{px: {md: 0}}}>
                <Typography variant='h5' sx={{mb: 2}}>
                  {t('home.cuppingProductivityInformation')}
                </Typography>
              </Container>
              <Divider sx={{display: {md: 'none'}}}/>
              <Grid container spacing={{md: 2}}>
                {
                  Object.keys(productivities).map((key, index) => {
                    return (
                      <Grid item xs={6} className={classes.activityItem} key={index}>
                        <CardScore key={key} title={key} score={productivities[key]} rightBorder={index % 2 === 0}/>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{mb: {xs: 3}}}>
            <Container sx={{px: {md: 0}}}>
              <Typography variant='h5' sx={{mb: 2}}>
                {t('home.upcomingCuppingSession')}
              </Typography>
            </Container>
            <Divider sx={{display: {md: 'none'}}}/>
            <Grid container spacing={2}>
              {upcomingCupping.map((session, index) => 
                <Grid item xs={12} md={6} key={index} className={classes.activityItem}>
                  <CardUpcoming 
                    key={index}
                    owner={'name'}
                    {...session}
                  />
                </Grid>
              )}
            </Grid>
            
            {isEmpty(upcomingCupping) &&
              <Box sx={{p: 3, textAlign: 'center'}}>
                <img src={upcomingSession} alt="No Activity" />
                
                <Typography variant='h5' sx={{mt: 4, mb: 2}}>
                  {t('home.noUpcomingSession')}
                </Typography>

                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  onClick={() => navigate(formCuppingSessionsLink)}
                >
                  <SvgIcon component={IconStartCupping} color='primary' inheritViewBox />
                </IconButton>
              </Box>
            }
          </Grid>
        </Grid>
      </Container>
    </>
  )
})