import {applySnapshot, flow, getSnapshot} from "mobx-state-tree";
import { ApiResult } from "services/api";
import {shipmentOrderFormPreProcessor} from "models/shipment-order-form";
import {sampleShipmentPreProcessor} from "./sample-shipment-preprocessor";

/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-17 22:40:30
 * @modify date 2022-04-17 22:40:30
 */


export const withSampleShipmentActions = (self: any) => ({
  actions: {
    setSamplesToBeShipment(samples) {
      const { name } = self.rootStore.companyStore
      const samplesToBeShipment = samples.map(sample => sampleShipmentPreProcessor(sample))

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment
      })
    },
    getSamplesToBeShipment(samples){
      return samples.map(sample => sampleShipmentPreProcessor(sample))
    },
    setThirdPartyEmails(emails, fulfillmentType, sampleLocation){
      const samplesToBeShipment = self.samplesToBeShipment.map(sample => {
        if (sample.sampleFulfillmentType === fulfillmentType && sample.sampleLocation === sampleLocation) {
          return {
            ...sample,
            thirdPartyEmail: emails,
            sample: {
              ...sample.sample
            }
          }
        }

        return {
          ...sample,
          sample: {
            ...sample.sample
          }
        }
      })

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment
      })
    },
    setSamplesToBeShipmentGeneratelabel(index, data) {
      const samplesToBeShipment = self.samplesToBeShipment.map((sample , i) => {

        if(i === index) {
          return {
            ...sample,
            sample: {
              ...sample.sample,
            },
            label: JSON.stringify(data)
          }
        }

        return {
          ...sample,
          sample: {
            ...sample.sample
          }
        }
      })

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment
      })
    },
    saveSampleShipment: flow(function * () {
      try {
        const payload: any = {
          ...self.shipmentForm,
          type: "SampleShipment",
          sampleTransactionItemsAttributes: self.samplesToBeShipment.map(sample => {
            return {
              "sample_id": sample.sampleId,
              "sample_type": sample.sampleType,
              "sample_name": sample.sampleName,
              "sample_grade": sample.sampleGrade,
              "sample_location": sample.sampleLocation,
              "sample_fulfillment_type": sample.sampleFulfillmentType,
              "is_approval_needed": sample.isApprovalNeeded,
              "sample_weight": sample.sampleWeight,
              "is_roasted": sample.isRoasted,
              "third_party_email": sample.thirdPartyEmail,
              "sample_warehouse_reference": sample.sampleWarehouseReference,
            }
          })
        }
        const res: ApiResult = yield self.environment.sampleTransactionApi.save(payload)

        if (res.kind === "ok") {

        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw Error()
      }
    }),
    removeSampleShipments(excludedIndexes){
      const samplesToBeShipment = self.samplesToBeShipment.filter((_, index) => !excludedIndexes.includes(index))

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment
      })

      return samplesToBeShipment
    },
    resetShipmentForm(){
      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        shipmentForm: shipmentOrderFormPreProcessor({})
      })
    }
  }
})
