import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperIconField:{
      backgroundColor: "#F1F1F1",
      width: 36,
      height: 36,
      marginRight: -14,
      borderBottomRightRadius: 5,
      borderTopRightRadius: 5 ,
      borderColor: colors.text.o25
    }
  })
)
