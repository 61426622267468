import { RouteProps } from 'react-router-dom'
import { find, concat, some } from 'lodash'

import {
  SignIn,
  SignUp,
  ForgotPassword,
  ResetPassword,
  Home,
  Cuppers,
  CuppingSessions,
  CuppingSessionsForm,
  SampleInformation,
  MasterSample,
  ReviewSamples,
  Compare,
  Plan,
  License,
  CardStripe,
  SampleScore,
  SampleReview,
  SampleReport,
  SampleDetails,
  Invitation,
  Tutorial,
  Verification,
  WatchVideo,
  SampleReportCalibration,
  SampleReportCollective,
  SampleReportIndividual,
  DormantAccount,
  Forbidden,
  ParentSampleReport,
  SampleOrders,
  SampleShipments,
  SampleShipmentForm
} from 'pages'
import {
  signIn as signInImage,
  signUp as signUpImage,
  IconHome,
  IconFillHome,
  IconHomeFilled,
  IconCupper,
  IconFillCupper,
  IconCupperFilled,
  IconStartCupping,
  IconFillStartCupping,
  IconStartCuppingFilled,
  IconReviewSamples,
  IconFillReviewSamples,
  IconReviewSamplesFilled,
  IconHelp,
  IconTermConditions,
  IconFillTermConditions,
  IconPrivacyPolicy,
  IconFillPrivacyPolicy,
  IconFeedback,
  IconPlay,
  IconPlayFilled,
  IconHelpFilled,
  IconChartOutline,
  IconShippingFast
} from 'assets/images'
import { colors } from 'assets'

export interface Route extends RouteProps {
  /**
   * Menu name
   */
   name: string
}

export const authenticationRoutes: Route[] = [
  {
    path: '/sign-in',
    name: 'signIn',
    element: <SignIn image={signInImage} />
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    element: <ForgotPassword image={signInImage} />
  },
  {
    path: '/reset-password/:token',
    name: 'resetPassword',
    element: <ResetPassword image={signInImage} />
  },
  {
    path: '/sign-up',
    name: 'signUp',
    element: <SignUp image={signUpImage} />
  },
  {
    path: '/sample-report/:id',
    name: 'sampleReport',
    element: <SampleReport />
  },
  {
    path: '/individual-report/:id',
    name: 'individualReport',
    element: <SampleReportIndividual />
  },
  {
    path: '/invitations/:uniqueToken',
    name: 'invitation',
    element: <Invitation />
  },
  {
    path: '/share-samples/:token',
    name: 'shareSamples',
    element: <ReviewSamples />
  },
  {
    path: '/sample-score',
    name: 'sampleScore',
    element: <SampleScore />
  },
  {
    path: '/sample-review',
    name: 'sampleReview',
    element: <SampleReview />
  },
  {
    path: '/verification/:token',
    name: 'verification',
    element: <Verification image={signUpImage} />
  },
  {
    path: '/sample-report-calibration/:id',
    name: 'sampleReportCalibration',
    element: <SampleReportCalibration />
  },
  {
    path: '/sample-report-collective/:id',
    name: 'SampleReportCollective',
    element: <SampleReportCollective />
  },
  {
    path: '/special-event/register',
    name: 'specialEvent',
    element: <SignUp image={signUpImage} />
  },
  {
    path: '/parent-sample-report/:id',
    name: 'parentSampleReport',
    element: <ParentSampleReport />
  },
]

export const dashboardRoutes: Route[] = [
  {
    path: '/',
    name: 'home',
    element: <Home />
  },
  {
    path: '/cuppers',
    name: 'cuppers',
    element: <Cuppers />
  },
  {
    path: '/cupping-sessions',
    name: 'cuppingSessions',
    element: <CuppingSessions />
  },
  {
    path: '/cupping-sessions/:id',
    name: 'formCuppingSessions',
    element: <CuppingSessionsForm />
  },
  {
    path: '/cupping-sessions/sample-information',
    name: 'sampleInformation',
    element: <SampleInformation />
  },
  {
    path: '/samples/:id',
    name: 'masterSample',
    element: <MasterSample />
  },
  {
    path: '/samples',
    name: 'reviewSamples',
    element: <ReviewSamples />
  },
  {
    path: '/compare',
    name: 'compare',
    element: <Compare />
  },
  {
    path: '/subscription/plan',
    name: 'plan',
    element: <Plan />
  },
  {
    path: '/subscription/license',
    name: 'license',
    element: <License />
  },
  {
    path: '/subscription/card',
    name: 'card',
    element: <CardStripe />
  },
  {
    path: '/sample-score',
    name: 'sampleScore',
    element: <SampleScore />
  },
  {
    path: '/sample-review',
    name: 'sampleReview',
    element: <SampleReview />
  },
  {
    path: '/sample-report/:id',
    name: 'sampleReport',
    element: <SampleReport />
  },
  {
    path: '/individual-report/:id',
    name: 'individualReport',
    element: <SampleReportIndividual />
  },
  {
    path: '/tutorial',
    name: 'tutorial',
    element: <Tutorial />
  },
  {
    path: '/watch-video',
    name: 'watchVideo',
    element: <WatchVideo />
  },
  {
    path: '/sample-report-calibration/:id',
    name: 'sampleReportCalibration',
    element: <SampleReportCalibration />
  },
  {
    path: '/cupping-sessions/invitation/:id',
    name: 'cuppingSessionsInvitation',
    element: <CuppingSessions />
  },
  {
    path: '/sample-report-collective/:id',
    name: 'SampleReportCollective',
    element: <SampleReportCollective />
  },
  {
    path: '/share-samples/:token',
    name: 'shareSamples',
    element: <ReviewSamples />
  },
  {
    path: '/parent-sample-report/:id',
    name: 'parentSampleReport',
    element: <ParentSampleReport />
  },
  {
    path: '/sample-orders',
    name: 'sampleOrders',
    element: <SampleOrders />
  },
  {
    path: '/sample-shipments',
    name: 'sampleShipments',
    element: <SampleShipments />
  },
  {
    path: '/sample-shipments/:id',
    name: 'sampleShipmentForm',
    element: <SampleShipmentForm />
  },
  {
    path: '/samples/:id/details',
    name: 'sampleDetails',
    element: <SampleDetails />
  },
]

export const publicRoutes: Route[] = [
  {
    path: '/activation-dormant-account/:token',
    name: 'dormantAccount',
    element: <DormantAccount />
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    element: <Forbidden />
  }
]

export const findRoute = (name: string): string => {
  const route = find(concat(authenticationRoutes, dashboardRoutes, publicRoutes), route => route.name === name)

  return route?.path || '/'
}

export const sidebarMenu = [
  {
    path: findRoute('home'),
    name: 'home',
    icon: <IconHome fill={colors.primary.main} />,
    iconFill: <IconFillHome fill={colors.primary.main} />,
    iconFilled: <IconHomeFilled fill={colors.primary.main} />
  },
  {
    path: findRoute('cuppers'),
    name: 'cuppers',
    icon: <IconCupper fill={colors.primary.main} />,
    iconFill: <IconFillCupper fill={colors.primary.main} />,
    iconFilled: <IconCupperFilled fill={colors.primary.main} />
  },
  {
    path: findRoute('cuppingSessions'),
    name: 'cuppingSessions',
    icon: <IconStartCupping fill={colors.primary.main} />,
    iconFill: <IconFillStartCupping fill={colors.primary.main} />,
    iconFilled: <IconStartCuppingFilled fill={colors.primary.main} />
  },
  {
    path: findRoute('reviewSamples'),
    name: 'reviewSamples',
    icon: <IconReviewSamples fill={colors.primary.main} />,
    iconFill: <IconFillReviewSamples fill={colors.primary.main} />,
    iconFilled: <IconReviewSamplesFilled fill={colors.primary.main} />
  },
  {
    path: findRoute('sampleOrders'),
    name: 'sampleOrders',
    icon: <IconChartOutline fill={colors.primary.main} />,
    iconFill: <IconChartOutline fill={colors.primary.main} />,
    iconFilled: <IconChartOutline fill="white" />
  },
  {
    path: findRoute('sampleShipments'),
    name: 'sampleShipments',
    icon: <IconShippingFast fill={colors.primary.main} />,
    iconFill: <IconShippingFast fill={colors.primary.main} />,
    iconFilled: <IconShippingFast fill="white" />
  }
]

const pathWithParams = [
  'reset-password',
  'sample-report',
  'invitations',
  'share-samples',
  'verification',
  'sample-report-calibration',
  'sample-report-collective',
  'parent-sample-report',
  'individual-report',
  'master-sample',
  'sample-shipments',
]

const invitationRoute = 'invitations'

export const isAuthenticateRoutes = (path: string): boolean => {
  if (pathWithParams.some(v => path.includes(v))) return true

  return some(authenticationRoutes, ['path', path])
}

export const isInvitationRoutes = (path: string): boolean => {
  return path.includes(invitationRoute)
}

export const isPublicRoutes = (path: string): boolean => {
  if (path === '/') return false

  return some(publicRoutes, route => {
    const splitPath = route.path!.split('/')
    return path.includes(splitPath[1])
  })
}

export const isResetPassword = (path: string): boolean => {
  return path.includes('reset-password');
}

export const sideMenuIcon = (condition: boolean, path: string = '', miniDrawer = false) => {
  switch (path) {
    case 'tutorial':
      return condition ?
          <IconHelpFilled fill={colors.primary.main} /> :
          <IconHelp fill={colors.primary.main} />
    case 'feedback':
      return <IconFeedback fill={colors.primary.main} />
    case 'termCondition':
      return condition ?
        <IconFillTermConditions fill={colors.primary.main} /> :
        <IconTermConditions fill={colors.primary.main} />
    case 'privacy':
      return condition ?
        <IconFillPrivacyPolicy fill={colors.primary.main} /> :
        <IconPrivacyPolicy fill={colors.primary.main} />
    case 'play':
      return condition ?
          <IconPlayFilled fill={colors.primary.main} /> :
          <IconPlay fill={colors.primary.main} />
    default:
      break
  }
}
