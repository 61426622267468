/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-21 00:13:24
 * @modify date 2022-04-21 00:13:24
 */

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Box, Typography, Button } from '@mui/material'

import { useStores } from 'models'
import { centered } from 'assets'
import { ModalWrapper } from 'components'
import { requestTimeout } from 'assets/images'

export const NoConnection: React.FC = observer(() => {
  const { t } = useTranslation()
  const { modalStore: { noConnection, setValue: setModalValue }} = useStores()

  return (
    <ModalWrapper
      open={noConnection}
      onClose={() => setModalValue('noConnection', false)}
      containerProps={{
        sx: {
          minWidth: '100%',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Box sx={{...centered, flexDirection: 'column', flex: 1}} onClick={() => setModalValue('noConnection', false)}>
        <img src={requestTimeout} alt='not-found' style={{maxWidth: '100%'}} />
        <Typography variant='h5' sx={{mt: 3}}>
          {t('modal.requestTimeoutDescription')}
        </Typography>
        <Button
          variant='contained'
          className='wide'
          onClick={() => setModalValue('noConnection', false)}
          sx={{mt: 3}}
        >
          {t('common.close')}
        </Button>
      </Box>
    </ModalWrapper>
  )
})