import { map, join, has, find } from 'lodash'
import { validateEmail, validatePassword } from './form-validation'

export const pointerMessages = (field: string, errors) => {
  if (!has(errors, field)) return

  return join(map(errors[field], 'message'), '\n')
}

export const errorField = (field: string, value: any, errors: any) => {
  return {
    error : errorStatus(field, value, errors),
    helperText: errorMessage(field, value, errors)
  }
}

export const errorStatus = (field: string, value: any, errors: any) => {
  if (field === 'email') {
    if (find(errors?.email, 'code')) return has(errors, field)

    return !validateEmail(value) && has(errors, field)
  }

  if (field === 'password' || field === 'passwordConfirmation') {
    return !validatePassword(value) && has(errors, field)
  }

  return !value && has(errors, field)
}

export const errorMessage = (field: string, value: any, errors: any) => {
  if (field === 'email') return pointerMessages(field, errors)

  return !value && pointerMessages(field, errors)
}
