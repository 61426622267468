import React, { useState, useEffect, useCallback } from 'react'
import {observer} from "mobx-react-lite"
import {useTranslation} from "react-i18next"
import {camelCase, debounce, range, toString} from "lodash"
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid, InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'

import { useStores } from "models"
import { FormInput } from "components"
import {DEBOUNCE_TIME} from "config/env"
import {countries, errorMessage, errorStatus} from "utils"
import {specieses, sampleTypes, grades, fulfillmentTypes, cuppingProtocols} from 'constants/form'

import { useStyles } from "./sample-form.styles"
import {SampleFormProps} from "./sample-form.props"

export const SampleForm = observer((props: SampleFormProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    id, name, coffeeType = '', sampleType = '', species = '', receivedOn, sampleReceptionDate, cropYear, countryCode, producerName, bagWeight,
    varietalsTags, numberOfBag, referenceNumber, grade, processName, supplierName, shippingInfo, notesAndRemarks, waterActivity,
    moisture, mass, volume, purchaseContractReference, salesContractReference, originGrade, warehouseReference, protocolForm,
    customer, customerCode, description, temperature, receivedWeight, showCoe, showSca, showSucafinaTasting, masterId, errors, strategy,
    sampleLocation, fulfillmentType, cargoNumber, containerNumber, lotNumberOrIcoMarks, setValue,
  } = props

  const {
    supplierStore: { search: searchSupplier, suppliers, getSuppliers, setValue: setSupplierValue },
    processStore: { search: searchProcess, processes, getProcess, setValue: setProcessValue },
    producerStore: { search: searchProducer, producers, getProducers, setValue: setProducerValue },
  } = useStores()

  const [openSupplier, setOpenSupplier] = useState(false)
  const [loadingSupplier, setLoadingSupplier] = useState(false)
  const [openProcess, setOpenProcess] = useState(false)
  const [loadingProcess, setLoadingProcess] = useState(false)
  const [openProducer, setOpenProducer] = useState(false)
  const [loadingProducer, setLoadingProducer] = useState(false)

  const onSearch = useCallback(
    debounce(async (action) => {
      switch (action) {
        case getSuppliers:
          setLoadingSupplier(true)
          break;
        case getProcess:
          setLoadingProcess(true)
          break;
        default:
          setLoadingProducer(true)
          break;
      }

      try{
        await action()
      } finally {
        setLoadingProducer(false)
        setLoadingProcess(false)
        setLoadingSupplier(false)
      }
    }, DEBOUNCE_TIME), []
  )

  useEffect(() => {
    if (!searchSupplier) return

    onSearch(getSuppliers)
  }, [searchSupplier])

  useEffect(() => {
    if (!searchProcess) return

    onSearch(getProcess)
  }, [searchProcess])

  useEffect(() => {
    if (!searchProducer) return

    onSearch(getProducers)
  }, [searchProducer])

  return (
    <Box>
      <Grid container spacing={2} className={classes.formRow}>
        {/*First Row */}
        <Grid item xs={12} md={3}>
          <FormInput
            label={t('sample.sampleName')}
            textFieldProps={{
              placeholder: t('sample.writeSampleNameHere'),
              value: name,
              onChange: (e) => setValue('name', e.target.value),
              error: errorStatus('name', name, errors),
              helperText: errorMessage('name', name, errors),
              inputProps: {maxLength: 50}
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput label={t('sample.country')} formControlProps={{ margin: 'none' }}>
            <Select
              size='small'
              defaultValue=""
              value={countryCode}
              onChange={(e) => setValue('countryCode', e.target.value)}
            >
              <MenuItem value='' sx={{height: 34}} />
              {countries.map(item =>
                <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
              )}
            </Select>
          </FormInput>
        </Grid>
        <Grid item xs={12} md={3}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <FormInput label={t('sample.receivedDate')} formControlProps={{ margin: 'none' }}>
              <DatePicker
                mask='____/__/__'
                inputFormat="yyyy/MM/DD"
                value={receivedOn}
                onChange={(newValue) => setValue('receivedOn', newValue?.valueOf())}
                renderInput={(params) => <TextField size='small' {...params} className={classes.date} />}
              />
            </FormInput>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput label={t('sample.sampleType')} formControlProps={{ margin: 'none' }}>
            <Select
              size='small'
              defaultValue=""
              value={sampleType}
              onChange={(e) => setValue('sampleType', e.target.value)}
            >
              <MenuItem value='' sx={{ height: 34 }} />
              {sampleTypes.map(item =>
                <MenuItem key={item} value={item}>{t(`options.sampleType.${camelCase(item)}`)}</MenuItem>
              )}
            </Select>
          </FormInput>
        </Grid>

        {/* Second Row */}
        <Grid item xs={12} md={3}>
          <FormInput label={t('sample.sampleGrade')} formControlProps={{ margin: 'none' }}>
            <Select
              size='small'
              defaultValue=""
              value={grade}
              onChange={(e) => setValue('grade', e.target.value)}
            >
              <MenuItem value='' sx={{ height: 34 }} />
              {grades.map(item =>
                <MenuItem key={item} value={item}>{t(`options.grades.${item}`)}</MenuItem>
              )}
            </Select>
          </FormInput>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput label={t('sample.supplier')} formControlProps={{ margin: 'none' }}>
            <Autocomplete
              freeSolo
              open={openSupplier}
              onOpen={() => setOpenSupplier(true)}
              onClose={() => setOpenSupplier(false)}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option: string) => option}
              value={supplierName}
              options={suppliers}
              loading={loadingSupplier}
              onChange={(event, value) => setValue('supplierName', toString(value))}
              className={classes.autocomplete}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  onChange={(e) => {
                    setSupplierValue('search', e.target.value)
                    setValue('supplierName', e.target.value)
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingSupplier ? <CircularProgress color="inherit" size={16} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </FormInput>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput label={t('sample.producerName')} formControlProps={{ margin: 'none' }}>
            <Autocomplete
              freeSolo
              open={openProducer}
              onOpen={() => setOpenProducer(true)}
              onClose={() => setOpenProducer(false)}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) => option}
              options={producers}
              loading={loadingProducer}
              value={producerName}
              onChange={debounce((event, value) => setValue('producerName', toString(value)), 500)}
              className={classes.autocomplete}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  value={producerName}
                  onChange={(e) => {
                    setProducerValue('search', e.target.value)
                    setValue('producerName', e.target.value)
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingProducer ? <CircularProgress color="inherit" size={16} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </FormInput>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput
            label={`${t('sample.purchaseContractReference')} #`}
            textFieldProps={{
              value: purchaseContractReference,
              onChange: (e) => setValue('purchaseContractReference', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>

        {/* Third Row */}
        <Grid item xs={12} md={3}>
          <FormInput
            label={t('sample.strategy')}
            textFieldProps={{
              value: strategy,
              onChange: (e) => setValue('strategy', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput
            label={t('sample.customer')}
            textFieldProps={{
              value: customer,
              onChange: (e) => setValue('customer', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput
            label={t('sample.customerCode')}
            textFieldProps={{
              value: customerCode,
              onChange: (e) => setValue('customerCode', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput
            label={`${t('sample.salesContractReference')} #`}
            textFieldProps={{
              value: salesContractReference,
              onChange: (e) => setValue('salesContractReference', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>

        {/* Fourth Row */}
        <Grid item xs={12} md={8}>
          <FormInput
            label={t('sample.description')}
            textFieldProps={{
              value: description,
              autoComplete: 'off',
              multiline: true,
              minRows: 5,
              onChange: (e) => setValue('description', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={4} display="flex" justifyContent="space-between" flexDirection='column' height={162}>
          <FormInput label={t('sample.species')} formControlProps={{ margin: 'none' }}>
            <Select
              size='small'
              defaultValue=""
              value={species}
              onChange={(e) => setValue('species', e.target.value)}
            >
              <MenuItem value='' sx={{ height: 34 }} />
              {specieses.map(item =>
                <MenuItem key={item} value={item}>{t(`options.species.${camelCase(item)}`)}</MenuItem>
              )}
            </Select>
          </FormInput>
          <FormInput
            label={t('sample.varietals')}
            textFieldProps={{
              value: varietalsTags,
              autoComplete: 'off',
              onChange: (e) => setValue('varietalsTags', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>

        {/* Fifth Row */}
        <Grid item xs={12} md={6}>
          <FormInput label={t('sample.coffeeProcessing')} formControlProps={{ margin: 'none' }}>
            <Autocomplete
              freeSolo
              clearOnEscape
              open={openProcess}
              onOpen={() => setOpenProcess(true)}
              onClose={() => setOpenProcess(false)}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option: string) => option}
              options={processes}
              loading={loadingProcess}
              value={processName}
              onChange={(event, value) => setValue('processName', toString(value))}
              className={classes.autocomplete}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  onChange={(e) => {
                    setProcessValue('search', e.target.value)
                    setValue('processName', e.target.value)
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingProcess ? <CircularProgress color="inherit" size={16} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </FormInput>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput label={t('sample.preferredCuppingProtocol')} formControlProps={{ margin: 'none' }}>
            <Select
              size='small'
              defaultValue=""
              value={protocolForm}
              onChange={(e) => setValue("protocolForm",  e.target.value)}
            >
              {cuppingProtocols.map(item => {
                if (!showCoe && item === "cup_of_excellence") return null;
                if (!showSca && item.includes("sca")) return null;
                if (!showSucafinaTasting && item === "sucafina_tasting") return null;

                return (
                  <MenuItem key={item} value={item}>
                    {t(`options.cuppingProtocol.${camelCase(item)}`)}
                  </MenuItem>
                )
              })}
            </Select>
          </FormInput>
        </Grid>

        {/*  Sixth Row */}
        <Grid item xs={12} md={3}>
          <FormInput label={t('sample.receivedWeight')} formControlProps={{ margin: 'none' }}>
            <CurrencyTextField
              key={`receivedWeight${id}${masterId}`}
              fullWidth
              size='small'
              type='text'
              inputMode='numeric'
              variant='outlined'
              textAlign='left'
              currencySymbol=''
              value={receivedWeight}
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value)=> setValue('receivedWeight', value)}
              InputProps={{
                endAdornment: (<InputAdornment position="end">gr</InputAdornment>)
              }}
            />
          </FormInput>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput label={t('sample.cropYear')} formControlProps={{ margin: 'none' }}>
            <Select
              size='small'
              defaultValue=""
              value={cropYear}
              onChange={(e) => setValue('cropYear', e.target.value)}
            >
              <MenuItem value='' sx={{height: 34}} />
              {range((new Date()).getFullYear(), 2010).map(item =>
                <MenuItem key={item} value={toString(item)}>{item}</MenuItem>
              )}
            </Select>
          </FormInput>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput label={t('sample.numberOfBags')} formControlProps={{ margin: 'none' }}>
            <CurrencyTextField
              key={`numberOfBags${id}${masterId}`}
              fullWidth
              size='small'
              type='text'
              inputMode='numeric'
              variant='outlined'
              textAlign='left'
              currencySymbol=''
              value={numberOfBag}
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              decimalPlaces={0}
              onChange={(event, value)=> setValue('numberOfBag', value)}
            />
          </FormInput>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormInput label={t('sample.bagWeight')} formControlProps={{ margin: 'none' }}>
            <CurrencyTextField
              key={`bagWeight${id}${masterId}`}
              fullWidth
              size='small'
              type='text'
              inputMode='numeric'
              variant='outlined'
              textAlign='left'
              currencySymbol=''
              value={bagWeight}
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value)=> setValue('bagWeight', value)}
              InputProps={{
                endAdornment: (<InputAdornment position="end">kg</InputAdornment>)
              }}
            />
          </FormInput>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.formRow}>
        <Grid item xs={12} md>
          <FormInput label={t('sample.moisture')} formControlProps={{ margin: 'none' }}>
            <CurrencyTextField
              key={`moisture${id}${masterId}`}
              fullWidth
              size='small'
              type='text'
              inputMode='numeric'
              variant='outlined'
              textAlign='left'
              currencySymbol=''
              value={moisture}
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value)=> setValue('moisture', value)}
              InputProps={{
                endAdornment: (<InputAdornment position="end">%</InputAdornment>)
              }}
            />
          </FormInput>
        </Grid>
        <Grid item xs={12} md>
          <FormInput label={t('sample.waterActivity')} formControlProps={{ margin: 'none' }}>
            <CurrencyTextField
              key={`waterActivity${id}${masterId}`}
              fullWidth
              size='small'
              type='text'
              inputMode='numeric'
              variant='outlined'
              textAlign='left'
              currencySymbol=''
              value={waterActivity}
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value)=> setValue('waterActivity', value)}
            />
          </FormInput>
        </Grid>
        <Grid item xs={12} md>
          <FormInput label={t('sample.temperature')} formControlProps={{ margin: 'none' }}>
            <CurrencyTextField
              key={`temperatures${id}${masterId}`}
              fullWidth
              size='small'
              type='text'
              inputMode='numeric'
              variant='outlined'
              textAlign='left'
              currencySymbol=''
              value={temperature}
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value)=> setValue('temperature', value)}
            />
          </FormInput>
        </Grid>
        <Grid item xs={12} md>
          <FormInput label={t('sample.mass')} formControlProps={{ margin: 'none' }}>
            <CurrencyTextField
              key={`mass${id}${masterId}`}
              fullWidth
              size='small'
              type='text'
              inputMode='numeric'
              variant='outlined'
              textAlign='left'
              currencySymbol=''
              value={mass}
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value)=> setValue('mass', value)}
              InputProps={{
                endAdornment: (<InputAdornment position="end">gr</InputAdornment>)
              }}
            />
          </FormInput>
        </Grid>
        <Grid item xs={12} md>
          <FormInput label={t('sample.volume')} formControlProps={{ margin: 'none' }}>
            <CurrencyTextField
              key={`volume${id}${masterId}`}
              fullWidth
              size='small'
              type='text'
              inputMode='numeric'
              variant='outlined'
              textAlign='left'
              currencySymbol=''
              value={volume}
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value)=> setValue('volume', value)}
              InputProps={{
                endAdornment: (<InputAdornment position="end">mL</InputAdornment>)
              }}
            />
          </FormInput>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.formRow}>

        <Grid item xs={12} md={6}>
          <FormInput
            label={t('sample.warehouse')}
            textFieldProps={{
              value: warehouseReference,
              onChange: (e) => setValue('warehouseReference', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            label={t('sample.sampleLocation')}
            textFieldProps={{
              value: sampleLocation,
              onChange: (e) => setValue('sampleLocation', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormInput
            label={t('sample.cargoNumber')}
            textFieldProps={{
              value: cargoNumber,
              onChange: (e) => setValue('cargoNumber', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInput
            label={t('sample.containerNumber')}
            textFieldProps={{
              value: containerNumber,
              onChange: (e) => setValue('containerNumber', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInput
            label={t('sample.lotNumber')}
            textFieldProps={{
              value: lotNumberOrIcoMarks,
              onChange: (e) => setValue('lotNumberOrIcoMarks', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.formRow}>
        <Grid item xs={12} md>
          <FormInput
            label={t('sample.notesAndRemarks')}
            textFieldProps={{
              value: notesAndRemarks,
              autoComplete: 'off',
              multiline: true,
              minRows: 6,
              onChange: (e) => setValue('notesAndRemarks', e.target.value)
            }}
            formControlProps={{ margin: 'none' }}
          />
        </Grid>
      </Grid>
    </Box>
  )
})
