/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-01 00:50:09
 * @modify date 2022-03-01 00:50:09
 */
import { createStyles, makeStyles } from '@mui/styles'
import { colors } from 'assets'

export const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 20
    },
    value: {
      width: 40,
      height: 44,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: colors.text.o50
    },
    slider: {
      position: "relative",
      flex: 1
    },
    sliderLabel: {
      position: 'absolute',
      top: -12,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between'
    },
    score: {
      position: "relative",
      marginLeft: 20,
      marginBottom: 20,
      display: 'flex',
      justifyContent: 'center'
    },
    text: {
      position: 'absolute',
      top: -25
    }
  })
)
