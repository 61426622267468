/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-15 18:38:58
 * @modify date 2022-01-15 18:38:58
 * @desc Component for showing the notification messages
 */
import React from 'react'
import clsx from 'clsx'
import { forEach } from 'lodash'
import { observer } from 'mobx-react-lite'
import ReactHtmlParser from 'react-html-parser'
import { useSnackbar } from 'notistack'
import { Box, Typography } from '@mui/material'
import { CheckCircle, WarningAmber } from '@mui/icons-material'

import { useStores } from "models/root-store"

import { useStyles } from './global-notification.styles'

export const GlobalNotification: React.FC = observer (() => { 
  const classes = useStyles()
  const { notificationStore: { notifications, removeNotification } } = useStores()
  const { enqueueSnackbar } = useSnackbar()
  const [displayed, setDisplayed] = React.useState<any>([])

  const storeDisplayed = (id) => {
    setDisplayed([displayed, id])
  }

  const icon = (severity, hasTitle) => {
    switch (severity) {
      case 'success':
        return <CheckCircle className={clsx(classes.icon, severity, !hasTitle && 'no-title')} />
      case 'error':
        return <WarningAmber className={clsx(classes.icon, severity, !hasTitle && 'no-title')} />    
      default:
        return <WarningAmber className={clsx(classes.icon, severity, !hasTitle && 'no-title')} />
    }
  }

  const message = (notification) => {
    const { title, message, severity } = notification

    return (
      <Box className={clsx(classes.wrapper)}>
        {icon(severity, !!title)}
        <Box sx={{ml: 1}}>
          <Typography variant='h6' className={classes.title}>{title}</Typography>
          <Typography variant='body2'>{ReactHtmlParser(message)}</Typography>
        </Box>
      </Box>
    )
  }

  React.useEffect(() => {
    forEach(notifications, (notification) => {
      if (!notification) return
      
      if (displayed.includes(notification.id)) return
      
      enqueueSnackbar(message(notification), {
        variant: notification.severity
      })

      storeDisplayed(notification.id)
      removeNotification(notification.id)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications.length])

  return null
})