import { AxiosRequestConfig } from "axios"

export enum ApiErrorKind {
  TIMEOUT = "timeout",
  CONNECTION = "cannot-connect",
  SERVER = "server",
  UNAUTHORIZED = "unauthorized",
  FORBIDDEN = "forbidden",
  NOT_FOUND = "not-found",
  GONE = "gone",
  UNPROCESSABLE = "unprocessable-entity",
  REJECTED = "rejected",
  UNKNOWN = "unknown",
  BAD_DATA = "bad-data",
}

export type GeneralApiProblem =
  /**
   * Times up.
   */
  | { kind: ApiErrorKind.TIMEOUT; temporary: true, originalRequest?: AxiosRequestConfig }
  /**
   * Cannot connect to the server for some reason.
   */
  | { kind: ApiErrorKind.CONNECTION; temporary: true, originalRequest?: AxiosRequestConfig }
  /**
   * The server experienced a problem. Any 5xx error.
   */
  | { kind: ApiErrorKind.SERVER, originalRequest?: AxiosRequestConfig }
  /**
   * We're not allowed because we haven't identified ourself. This is 401.
   */
  | { kind: ApiErrorKind.UNAUTHORIZED, originalRequest?: AxiosRequestConfig }
  /**
   * We don't have access to perform that request. This is 403.
   */
  | { kind: ApiErrorKind.FORBIDDEN, originalRequest?: AxiosRequestConfig }
  /**
   * Unable to find that resource.  This is a 404.
   */
  | { kind: ApiErrorKind.NOT_FOUND, originalRequest?: AxiosRequestConfig }
  /**
   * Unable to find that resource.  This is a 410.
   */
  | { kind: ApiErrorKind.GONE, originalRequest?: AxiosRequestConfig }
  /**
   * The data we sent is not valid.  This is a 422.
   */
  | { kind: ApiErrorKind.UNPROCESSABLE, errors: any, originalRequest?: AxiosRequestConfig }
  /**
   * All other 4xx series errors.
   */
  | { kind: ApiErrorKind.REJECTED, originalRequest?: AxiosRequestConfig }
  /**
   * Something truly unexpected happened. Most likely can try again. This is a catch all.
   */
  | { kind: ApiErrorKind.UNKNOWN; temporary: true, originalRequest?: AxiosRequestConfig }
  /**
   * The data we received is not in the expected format.
   */
  | { kind: ApiErrorKind.BAD_DATA, originalRequest?: AxiosRequestConfig }
