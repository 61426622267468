import React from 'react'
import { Avatar as AvatarComponent } from '@mui/material'

import { AvatarProps } from './avatar.props'
const randomColor = require('randomcolor')

export const Avatar: React.FC<AvatarProps> = (props: AvatarProps) => {
  const [color] = React.useState(randomColor())
  const { name, image, ...rest } = props

  if (image) return <AvatarComponent alt={name} src={image} {...rest} />

  const initials = name?.split(" ").map((n)=>n[0]).join("").toUpperCase()
  return <AvatarComponent sx={{ bgcolor: color }} {...rest}>{initials}</AvatarComponent>
}