import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {colors} from "assets";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 12,
      marginBottom: 24
    },
    sampleInfo: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      width: 28,
      height: 28,
      marginRight: 8,
      fill: theme.palette.primary.main
    },
    sampleDetail: {
      padding: 16,
      backgroundColor: colors.primary.o25,
      borderRadius: 8
    },
    collapseButton: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tabs: {
      padding: '8px 16px',
      background: colors.primary.o25,

      '& button': {
        background: 'transparent',
        color: 'white',
        borderRadius: 4,
        border: 'none',
        fontWeight: 600,
        width: '25%',
        height: 'fit-content',
        minHeight: 'unset',

        '&.Mui-selected': {
          background: colors.primary.main,
          color: 'white'
        }
      },

      '& svg': {
        width: 20,
        height: 20,
        fill: 'white',
      },

      '& .MuiTabs-indicator': {
        display: 'none'
      }
    }
  })
)
