/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-17 22:40:30
 * @modify date 2022-04-19 20:49:32
 */

 import i18n from "i18n/i18n"
 import {keys, values, startCase, flatten, uniq, orderBy, groupBy, max, min} from "lodash"
 import { colors } from "assets"
 import { arabicaScoreTable, robustaScoreTable, coeScoreTable } from 'constants/form'
 import {
  IconBean, IconFragrance, IconStartCupping, IconAftertaste, IconAcidity, IconBody, IconBalance, IconOverall, IconDefects, IconUniformity, IconCleanCup, IconSweetness,
  IconCup
} from 'assets/images'
import { getColor } from "utils"

 const randomColor = require('randomcolor')

const searchCuppingProtocolCode = (data) => {
  switch (data.cuppingProtocol) {
    case 'arabica':
      return arabicaScoreTable
    case 'robusta':
      return robustaScoreTable
    case 'cup_of_excellence':
      return coeScoreTable
    default:
      return []
  }



}

export const withSampleReportViews = (self: any) => ({

  views: {
    get getSampleRecord() {
      return JSON.parse(self.sampleRecord)
    },
    get getAverageScoresForGraph() {
      const data = JSON.parse(self.averageScoresForGraph)
      const labels = keys(data).map(k => startCase(k))

      const graph = {
        labels: labels,
        datasets: [
          {
            label: i18n.t('sample.averageScore'),
            data: values(data),
            backgroundColor: colors.primary.o25,
            borderColor: colors.primary.main,
            borderWidth: 2,
          },
        ],
      }

      return graph
    },
    get getAverageScoresForLineChart() {
           
      let dataChart: any[] = []
      let labels: any[] = []
      let datasetChart: any[] = []
      let scoreOwner: any[] = []
      let averageScores: any[] = []
      let labelsFullName: any[] = []
      let datasetChartScore: any[] = []
      let no= 1
      let noTmp= 0
      let tmpScore = 0
      let maxMinData : any[] = []
      let maxMinDataTmp : any[] = []


      self.scores.slice().sort((a) => a.cupperTitle === "cuppingSessionOwner" ? -1 : 1).map((sc, index) => {
        if(sc.cupperTitle === "cuppingSessionOwner") {
          noTmp= no
          no= 0
        }
        if(sc.cupperId.toString() === self.rootStore.userStore.userId?.toString() && sc.cupperTitle !== "cuppingSessionOwner") {
          noTmp= no
          no = 1
        }

        dataChart.push({ no: no,cupperId: sc.cupperId, cupperName: sc.cupperTitle === "cuppingSessionOwner" ? ''+sc.cupperName+ ' (Owner)' : sc.cupperName, isScoreTarget: sc.isScoreTarget, totalScoreOwner: sc.totalScore, datasets: [], averageScores: [],scoreOwner: [] })
        no= noTmp
        searchCuppingProtocolCode(self).map(tc => {
          if (sc.cupperTitle === "cuppingSessionOwner") {
            dataChart[index]['datasets'].push({ key: tc.key, abbr: tc.abbr, score: sc[tc.key] })
            scoreOwner.push({ key: tc.key, abbr: tc.abbr, score: sc[tc.key] })
            averageScores.push({ key: tc.key, abbr: tc.abbr, score: sc[tc.key] })
            labelsFullName.push(tc.translation)
            return
          }
          dataChart[index]['datasets'].push({ key: tc.key, abbr: tc.abbr, score: sc[tc.key] })

          //summary average scores
          if(averageScores.length === 0){
            tmpScore =0
          } else {
            tmpScore = averageScores[averageScores.findIndex(x => x.abbr === tc.abbr)].score
            averageScores[averageScores.findIndex(x => x.abbr === tc.abbr)].score = tmpScore + sc[tc.key]
          }


        })

        // push average score in datacart
        dataChart[index]['averageScores'] = averageScores

        scoreOwner.map(scoreOwners =>
          dataChart[index]['scoreOwner'].push({ key: scoreOwners['key'], abbr: scoreOwners['abbr'], score: scoreOwners['score'] })
        )
        no++
      })

      orderBy(dataChart, "no", "asc").map((data, index) => {
        const color = getColor(index)
        if (index === 0) {
          data['datasets'].map(dataset => {
            if (dataset.abbr !== "TS")
              labels.push(dataset.abbr)
          })
        }
        datasetChart.push({ no: data['no'], id: data['cupperId'], label: data['cupperName'], data: [], borderColor: color, backgroundColor: color, yAxisID: 'y', maxMin:[] })
        datasetChartScore.push({ data: [] })


        //average score final
        data['averageScores'].map(averageScores =>
          data['datasets'].map(dataDataset => {
            if (averageScores['key'] === dataDataset['key']) {
              if (dataDataset.abbr !== "TS"){
                datasetChart[index]['data'].push((dataDataset.score - (averageScores.score / self.scores.length)).toFixed(2))
                datasetChartScore[index]['data'].push((dataDataset.score))
                maxMinData.push((dataDataset.score - (averageScores.score / self.scores.length)).toFixed(2))
              }
            }
          })
        )

        data['datasets'].map(dataDataset => {
          if (dataDataset.abbr !== "TS")
            datasetChart[index]['data'].push(dataDataset.score)
        })
      })

      maxMinDataTmp.push({max: Number(max(maxMinData)).toFixed(1) + 1,min: Number((min(maxMinData) - max(maxMinData))).toFixed(1)})

      // set data Average Score
      datasetChart.push({ no: -1, id: -1, label: 'Average Score', data: [0,0,0,0,0,0,0,0,0,0,0], borderColor: colors.secondary.main, backgroundColor: colors.secondary.main, yAxisID: 'y', maxMin:[] })

      return {
        labels: labels,
        labelsFullName: labelsFullName,
        datasets: datasetChart,
        sampleRecord: JSON.parse(self.sampleRecord),
        datasetChartScore: datasetChartScore,
        maxMinData : maxMinDataTmp
      }
    },
    get getCuppingResult() {
      let scores: any[] = []
      let scoreWithIcon: any[] = []
      let descriptorsInfoDatas: any[] = []
      let descriptorsInfoDatasWithIcon: any[] = []
      let icon= IconFragrance
      let mostUsedDescriptors: any[] = []
      let cupperName: any[] = [{ name: [] }]
      let dataChart: any[] = []
      let i = 0
      let tmpQty = 0
      let no= 1
      let noTmp= 0
      let indexGroup = 0
      let indexGroupChecks = 0
      let indexGroupCheck = 0

      self.scores.slice().sort((a) => a.cupperTitle === "cuppingSessionOwner" ? -1 : 1).map((sc, index) => {
        
        if(sc.cupperTitle === "cuppingSessionOwner") {
          noTmp= no
          no= 0
        }
        if(sc.cupperId.toString() === self.rootStore.userStore.userId?.toString() && sc.cupperTitle !== "cuppingSessionOwner") {
          noTmp= no
          no = 1
        }

        dataChart.push({no: no,cupperId: sc.cupperId, id: sc.id, cupperName: sc.cupperTitle === "cuppingSessionOwner" ? ''+sc.cupperName+' (Owner)' : sc.cupperName, isScoreTarget: sc.isScoreTarget, totalScoreTarget: sc.totalScore, datasets: [], flavorWhell: [] })
        no= noTmp
        //flavorWhellGroup by fragrance
        sc.fragranceDescriptors.map((fragrance,i) => {

          JSON.parse(self.flavorWheelGroups).map((flavorWheel, i) => {
            if(flavorWheel.label === fragrance){
              dataChart[index]['flavorWhell'].push({ id: flavorWheel.id, label: flavorWheel.label, weight: flavorWheel.weight, gcolor: flavorWheel.gcolor, groups: [] })
            }

            flavorWheel.groups.map((flavorWheelGroup,ii) => {
              if(flavorWheelGroup.label === fragrance){
                if(dataChart[index]['flavorWhell'].filter(obj => obj.label === flavorWheel.label).length === 1){
                  dataChart[index]['flavorWhell'].map((data,ii)=>{
                    if(data.label !== flavorWheel.label) return
                    indexGroupCheck=0
                    data.groups.map((datas,i) => {
                      if (datas.label === flavorWheelGroup.label) {
                        indexGroupCheck = 1
                      }
                    })
                  })

                  if(indexGroupCheck === 0){
                    dataChart[index]['flavorWhell'][dataChart[index]['flavorWhell'].findIndex(obj => obj.label === flavorWheel.label)]['groups'].push({
                      id: flavorWheelGroup.id,
                      label: flavorWheelGroup.label,
                      weight: flavorWheelGroup.weight,
                      gcolor: flavorWheelGroup.gcolor,
                      groups: [] })
                  }
                  return
                }
                dataChart[index]['flavorWhell'].push({ id: flavorWheel.id, label: flavorWheel.label, weight: flavorWheel.weight, gcolor: flavorWheel.gcolor, groups: [flavorWheelGroup] })
              }

              flavorWheelGroup.groups.map((flavorWheelGroups, iii) => {
                if(flavorWheelGroups.label === fragrance){
                  if(dataChart[index]['flavorWhell'].filter(obj => obj.label === flavorWheel.label).length === 1){
                    dataChart[index]['flavorWhell'].map((data,ii)=>{
                      if(data.label !== flavorWheel.label) return
                      data.groups.map((datas,i) => {
                          if (datas.label === flavorWheelGroup.label) {
                            indexGroupChecks=0
                            dataChart[index]['flavorWhell'].map((data,ii)=>{
                              if(data.label !== flavorWheel.label) return
                              data.groups.map((datas,i) => {
                                if (datas.label === flavorWheelGroup.label) {
                                  datas.groups.map((tmp, tmpi) => {
                                    if (tmp.label === fragrance) {

                                      indexGroupChecks = 1;
                                    }
                                  })
                                  indexGroup = i
                                }

                              })})

                            if(indexGroupChecks === 0){
                              dataChart[index]['flavorWhell'][dataChart[index]['flavorWhell'].findIndex(obj => obj.label === flavorWheel.label)]['groups'][indexGroup]['groups'].push({
                                id: flavorWheelGroups.id,
                                label: flavorWheelGroups.label,
                                weight: flavorWheelGroups.weight,
                                gcolor: flavorWheelGroups.gcolor,
                                groups: []
                              })
                            }
                          }else{
                            indexGroupCheck=0
                            dataChart[index]['flavorWhell'].map((data,ii)=>{
                              if(data.label !== flavorWheel.label) return
                              data.groups.map((datas,i) => {
                                if (datas.label === flavorWheelGroup.label) {
                                  indexGroupCheck = 1
                                }

                              })})

                            if(indexGroupCheck === 0){
                              dataChart[index]['flavorWhell'][dataChart[index]['flavorWhell'].findIndex(obj => obj.label === flavorWheel.label)]['groups'].push({
                                id: flavorWheelGroup.id,
                                label: flavorWheelGroup.label,
                                weight: flavorWheelGroup.weight,
                                gcolor: flavorWheelGroup.gcolor,
                                groups: [{
                                  id: flavorWheelGroups.id,
                                  label: flavorWheelGroups.label,
                                  weight: flavorWheelGroups.weight,
                                  gcolor: flavorWheelGroups.gcolor}] })
                            }
                          }
                      })
                    })
                   return
                  }
                  dataChart[index]['flavorWhell'].push({ id: flavorWheel.id, label: flavorWheel.label, weight: flavorWheel.weight, gcolor: flavorWheel.gcolor,
                    groups: [{id: flavorWheelGroup.id, label: flavorWheelGroup.label, weight: flavorWheelGroup.weight, gcolor: flavorWheelGroup.gcolor,
                      groups:[{id: flavorWheelGroups.id, label: flavorWheelGroups.label, weight: flavorWheelGroups.weight, gcolor: flavorWheelGroups.gcolor}]}] })

                }
              })

            })
          })
        })

        sc.flavorDescriptors.map((fragrance,i) => {
          JSON.parse(self.flavorWheelGroups).map((flavorWheel, i) => {
            if(flavorWheel.label === fragrance){
              dataChart[index]['flavorWhell'].push({ id: flavorWheel.id, label: flavorWheel.label, weight: flavorWheel.weight, gcolor: flavorWheel.gcolor, groups: [] })

            }

            flavorWheel.groups.map((flavorWheelGroup,ii) => {
              if(flavorWheelGroup.label === fragrance){
                if(dataChart[index]['flavorWhell'].filter(obj => obj.label === flavorWheel.label).length === 1){
                  dataChart[index]['flavorWhell'].map((data,ii)=>{
                    if(data.label !== flavorWheel.label) return
                    indexGroupCheck=0
                    data.groups.map((datas,i) => {
                      if (datas.label === flavorWheelGroup.label) {
                        indexGroupCheck = 1
                      }
                    })
                  })

                  if(indexGroupCheck === 0){
                    dataChart[index]['flavorWhell'][dataChart[index]['flavorWhell'].findIndex(obj => obj.label === flavorWheel.label)]['groups'].push({
                      id: flavorWheelGroup.id,
                      label: flavorWheelGroup.label,
                      weight: flavorWheelGroup.weight,
                      gcolor: flavorWheelGroup.gcolor,
                      groups: [] })
                  }
                  return
                }
                dataChart[index]['flavorWhell'].push({ id: flavorWheel.id, label: flavorWheel.label, weight: flavorWheel.weight, gcolor: flavorWheel.gcolor, groups: [flavorWheelGroup] })
              }

              flavorWheelGroup.groups.map((flavorWheelGroups, iii) => {
                if(flavorWheelGroups.label === fragrance){
                  if(dataChart[index]['flavorWhell'].filter(obj => obj.label === flavorWheel.label).length === 1){
                    dataChart[index]['flavorWhell'].map((data,ii)=>{
                      if(data.label !== flavorWheel.label) return
                      data.groups.map((datas,i) => {
                        if (datas.label === flavorWheelGroup.label) {
                          indexGroupChecks=0
                          dataChart[index]['flavorWhell'].map((data,ii)=>{
                            if(data.label !== flavorWheel.label) return
                            data.groups.map((datas,i) => {
                              if (datas.label === flavorWheelGroup.label) {
                                datas.groups.map((tmp, tmpi) => {
                                  if (tmp.label === fragrance) {

                                    indexGroupChecks = 1;
                                  }
                                })
                                indexGroup = i
                              }

                            })})

                          if(indexGroupChecks === 0){
                            dataChart[index]['flavorWhell'][dataChart[index]['flavorWhell'].findIndex(obj => obj.label === flavorWheel.label)]['groups'][indexGroup]['groups'].push({
                              id: flavorWheelGroups.id,
                              label: flavorWheelGroups.label,
                              weight: flavorWheelGroups.weight,
                              gcolor: flavorWheelGroups.gcolor,
                              groups: []
                            })
                          }
                        }else{
                          indexGroupCheck=0
                          dataChart[index]['flavorWhell'].map((data,ii)=>{
                            if(data.label !== flavorWheel.label) return
                            data.groups.map((datas,i) => {
                              if (datas.label === flavorWheelGroup.label) {
                                indexGroupCheck = 1
                              }

                            })})

                          if(indexGroupCheck === 0){
                            dataChart[index]['flavorWhell'][dataChart[index]['flavorWhell'].findIndex(obj => obj.label === flavorWheel.label)]['groups'].push({
                              id: flavorWheelGroup.id,
                              label: flavorWheelGroup.label,
                              weight: flavorWheelGroup.weight,
                              gcolor: flavorWheelGroup.gcolor,
                              groups: [{
                                id: flavorWheelGroups.id,
                                label: flavorWheelGroups.label,
                                weight: flavorWheelGroups.weight,
                                gcolor: flavorWheelGroups.gcolor}] })
                          }
                        }
                      })
                    })
                    return
                  }
                  dataChart[index]['flavorWhell'].push({ id: flavorWheel.id, label: flavorWheel.label, weight: flavorWheel.weight, gcolor: flavorWheel.gcolor,
                    groups: [{id: flavorWheelGroup.id, label: flavorWheelGroup.label, weight: flavorWheelGroup.weight, gcolor: flavorWheelGroup.gcolor,
                      groups:[{id: flavorWheelGroups.id, label: flavorWheelGroups.label, weight: flavorWheelGroups.weight, gcolor: flavorWheelGroups.gcolor}]}] })

                }
              })
            })
          })
        })

        searchCuppingProtocolCode(self).map(tc => {
          if (sc.cupperTitle === "cuppingSessionOwner") {
            scores.push({ key: tc.translation, score: [] })
            descriptorsInfoDatas.push({ icon: '',key: tc.translation, descriptor: [] })
            mostUsedDescriptors.push({ key: tc.translation, descriptor: [] })

          }

          dataChart[index]['datasets'].push({key: tc.key, abbr: tc.abbr, score: sc[tc.key], descriptors: tc.translation, descriptorDetail: []})

          if(tc.translation === "descriptor.fragranceOrAroma") {
            sc['fragranceDescriptors'].map( desc => {
             dataChart[index]['datasets'][dataChart[index]['datasets'].findIndex((obj => obj.descriptors === "descriptor.fragranceOrAroma"))]['descriptorDetail'].push(desc)
            })
          }

          if(tc.translation === "descriptor.saltOrAcid") {
            sc['saltDescriptors'].map( desc => {
             dataChart[index]['datasets'][dataChart[index]['datasets'].findIndex((obj => obj.descriptors === "descriptor.saltOrAcid"))]['descriptorDetail'].push(desc)
            })
          }

          if(tc.translation === "descriptor.flavor") {
            sc['flavorDescriptors'].map( desc => {
             dataChart[index]['datasets'][dataChart[index]['datasets'].findIndex((obj => obj.descriptors === "descriptor.flavor"))]['descriptorDetail'].push(desc)
            })
          }

          if(tc.translation === "descriptor.aftertaste") {
            sc['aftertasteDescriptors'].map( desc => {
             dataChart[index]['datasets'][dataChart[index]['datasets'].findIndex((obj => obj.descriptors === "descriptor.aftertaste"))]['descriptorDetail'].push(desc)
            })
          }

          if(tc.translation === "descriptor.acidity") {
            sc['acidityDescriptors'].map( desc => {
             dataChart[index]['datasets'][dataChart[index]['datasets'].findIndex((obj => obj.descriptors === "descriptor.acidity"))]['descriptorDetail'].push(desc)
            })
          }

          if(tc.translation === "descriptor.body") {
            sc['bodyDescriptors'].map( desc => {
             dataChart[index]['datasets'][dataChart[index]['datasets'].findIndex((obj => obj.descriptors === "descriptor.body"))]['descriptorDetail'].push(desc)
            })
          }

          if(tc.translation === "descriptor.balance") {
            sc['balanceDescriptors'].map( desc => {
             dataChart[index]['datasets'][dataChart[index]['datasets'].findIndex((obj => obj.descriptors === "descriptor.balance"))]['descriptorDetail'].push(desc)
            })
          }

          if(tc.translation === "descriptor.mouthfeel") {
            sc['mouthfeelDescriptors'].map( desc => {
             dataChart[index]['datasets'][dataChart[index]['datasets'].findIndex((obj => obj.descriptors === "descriptor.mouthfeel"))]['descriptorDetail'].push(desc)
            })
          }

          if(tc.translation === "descriptor.cleanCup") {
            sc['cleanCupDescriptors'].map( desc => {
             dataChart[index]['datasets'][dataChart[index]['datasets'].findIndex((obj => obj.descriptors === "descriptor.cleanCup"))]['descriptorDetail'].push(desc)
            })
          }

        })
        no++
      })

      scores.map((x, index) => {

          orderBy(dataChart, "no", "asc").map((sc, indexs) => {
          //set name cupper
          if (i < dataChart.length) cupperName[0]['name'].push(sc.cupperTitle === "cuppingSessionOwner" ? sc.cupperName : sc.cupperName)

            sc.datasets.map(data =>{
              if(data.descriptors === x.key ){
                searchCuppingProtocolCode(self).map(tc => {
                  if (x.key === tc.translation) {

                    // set value on description
                    scores[scores.findIndex((obj => obj.key === tc.translation))]['score'].push(data.score)
                  }
                })
              }

            })
          i++
        })
      })

      orderBy(dataChart, "no", "asc").map( x => {
        x.datasets.filter(filter => filter.descriptorDetail.length !== 0).map(data => {
          descriptorsInfoDatas[descriptorsInfoDatas.findIndex(obj => obj.key === data.descriptors)]['descriptor'].push(data.descriptorDetail)
        })

      })

      //set data most used description
      orderBy(dataChart, "no", "asc").map( x => {
        x.datasets.filter(filter => filter.descriptorDetail.length !== 0).map((data,index) => {
          data.descriptorDetail.map(dataDesc => {
            if(mostUsedDescriptors[mostUsedDescriptors.findIndex(obj => obj.key === data.descriptors)]['descriptor'].filter(obj => obj.label ===dataDesc).length > 0){
              tmpQty = mostUsedDescriptors[mostUsedDescriptors.findIndex(obj => obj.key === data.descriptors)]['descriptor'][mostUsedDescriptors[mostUsedDescriptors.findIndex(obj => obj.key === data.descriptors)]['descriptor'].findIndex( obj => obj.label === dataDesc)].qty
              mostUsedDescriptors[mostUsedDescriptors.findIndex(obj => obj.key === data.descriptors)]['descriptor'][mostUsedDescriptors[mostUsedDescriptors.findIndex(obj => obj.key === data.descriptors)]['descriptor'].findIndex( obj => obj.label === dataDesc)].qty = tmpQty + 1
              return
            }
            mostUsedDescriptors[mostUsedDescriptors.findIndex(obj => obj.key === data.descriptors)]['descriptor'].push({label: dataDesc, qty: 1})
          })

        })

      })

      //set data discriptions cupper with icon
      descriptorsInfoDatasWithIcon.length = 0
      descriptorsInfoDatas.map(data => {
        switch (data.key) {
          case 'descriptor.aftertaste':
            icon = IconAftertaste
            descriptorsInfoDatasWithIcon.push({ icon: icon, name: data.key, descriptor: data.descriptor })
            break
          case 'descriptor.acidity':
            icon = IconAcidity
            descriptorsInfoDatasWithIcon.push({ icon: icon, name: data.key, descriptor: data.descriptor })
            break
          case 'descriptor.balance':
            icon = IconBalance
            descriptorsInfoDatasWithIcon.push({ icon: icon, name: data.key, descriptor: data.descriptor })
            break
          case 'descriptor.defects':
            icon = IconDefects
            descriptorsInfoDatasWithIcon.push({ icon: icon, name: data.key, descriptor: data.descriptor })
            break
        }

        // set score with icon
        scoreWithIcon.length = 0
        scores.map(x => {
          switch (x.key) {
            case 'descriptor.fragranceOrAroma':
              icon = IconFragrance
              break
            case 'descriptor.saltOrAcid':
              icon = IconAcidity
              break
            case 'descriptor.bitterOrSweet':
              icon = IconBean
              break
            case 'descriptor.flavor':
              icon = IconStartCupping
              break
            case 'descriptor.aftertaste':
              icon = IconAftertaste
              break
            case 'descriptor.acidity':
              icon = IconAcidity
              break
            case 'descriptor.body':
              icon = IconBody
              break
            case 'descriptor.balance':
              icon = IconBalance
              break
            case 'descriptor.mouthfeel':
              icon = IconCup
              break
            case 'descriptor.overall':
              icon = IconOverall
              break
            case 'descriptor.uniformity':
              icon = IconUniformity
              break
            case 'descriptor.cleanCup':
              icon = IconCleanCup
              break
            case 'descriptor.sweetness':
              icon = IconSweetness
              break
            case 'descriptor.defects':
              icon = IconDefects
              break
          }
          scoreWithIcon.push({ icon: icon, name: x.key, values: x.score })
        })

      })

      return {
        cupperName: cupperName,
        scoreWithIcon: scoreWithIcon,
        dataScoreWithId: dataChart,
        descriptorsInfoDatasWithIcon: descriptorsInfoDatasWithIcon,
        mostUsedDescriptors: mostUsedDescriptors
      }
    },
    get getCloudWords() {
      return JSON.parse(self.cloudWords)
    },
    get getFlavorWheelOnlyFragrance() {
      return JSON.parse(self.flavorWheelOnlyFragrance)
    },
    get getFlavorWheelOnlyFlavor() {
      return JSON.parse(self.flavorWheelOnlyFlavor)
    },
    get getFlavorWheelGroups() {
      return JSON.parse(self.flavorWheelGroups)
    },
    get allScoreDescriptors() {
      const all = self.scores.map(sc => sc.allDescriptors)

      return uniq(flatten(all))
    },
    get allDefects() {
      const all = self.greenGradings.map(gd => values(gd.defects))

      return flatten(all)
    }
  }
})
