/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-18 21:58:36
 * @modify date 2022-04-18 21:58:36
 */

import moment from 'moment'
import { omit, toString } from 'lodash'
import { applySnapshot, getSnapshot, flow } from "mobx-state-tree"
import { ApiResult } from "services/api"
import i18n from 'i18n/i18n'

export const withCuppingSessionFormActions = (self: any) => ({
  actions: {
    setCuppingSession(data) {
      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        ...data,
        startsAt: moment(data.startsAt).valueOf(),
        endsAt: moment(data.endsAt).valueOf(),
        invitedCuppersIds: data.invitedCuppersIds?.map(id => { return toString(id) })
      })
    },
    getCuppingSession: flow(function * (token) {
      try {
        const res: ApiResult = yield self.environment.cuppingSessionApi.find(token)

        if (res.kind === "ok") self.setCuppingSession(res.data)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    saveCuppingSession: flow(function * () {
      try {
        const {
          notificationStore: { setNotification },
          cuppingSessionStore: { setCuppingSession }
        } = self.rootStore
        let isEditing = false
        const omitAttributes = ['guestInvitations', 'uniqueToken']
        if (self.id) {
          omitAttributes.push('numberOfSamples')
          isEditing = true
        }

        const payload: any = {
          ...omit(self, omitAttributes),
          startDate: moment(self.startsAt).format('YYYY-MM-DD'),
          startTime: moment(self.startsAt).format('HH:mm'),
          endDate: moment(self.endsAt).format('YYYY-MM-DD'),
          endTime: moment(self.endsAt).format('HH:mm'),
          guestInvitationsAttributes: self.guestInvitationPayload,
        }

        const res: ApiResult = yield self.environment.cuppingSessionApi.save(payload)
        if (res.kind === "ok") {
          self.setCuppingSession(res.data)
          setCuppingSession({...res.data, canNotCupNow: false})

          if(isEditing) {
            setNotification({
              severity: 'success',
              message: i18n.t('cuppingSession.success.successfullyUpdatedCuppingSession')
            })
            return
          }

          setNotification({
            severity: 'success',
            message: i18n.t('cuppingSession.success.successfullyCreatedCuppingSession')
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
  }
})
