/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-14 03:24:52
 * @modify date 2022-04-14 03:24:52
 * @desc [description]
 */

import React from 'react'
import { IconButton } from '@mui/material'

export const MenuIcon: React.FC = (props) => {
  return (
    <IconButton
      size='small'
      color='primary'
      className='bg'
      sx={{mr: 1}}
    >
      {props.children}
    </IconButton>
  )
}