/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import { Instance, SnapshotOut, types } from "mobx-state-tree"

import { withSetValue } from "models/extensions"

/**
 * Model description here for TypeScript hints.
 */
export const GuestInformationStoreModel = types
  .model("GuestInformationStore")
  .props({
    guestName: types.optional(types.string, ""),
    guestEmail: types.optional(types.string, ""),
    companyName: types.optional(types.string, ""),
    role: types.optional(types.string, ""),
  })
  .extend(withSetValue)

type GuestInformationStoreType = Instance<typeof GuestInformationStoreModel>
export interface GuestInformationStore extends GuestInformationStoreType {}
type GuestInformationStoreSnapshotType = SnapshotOut<typeof GuestInformationStoreModel>
export interface GuestInformationStoreSnapshot extends GuestInformationStoreSnapshotType {}
