/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-28 17:43:39
 * @modify date 2022-03-28 17:43:39
 */

import React from 'react'
import clsx from 'clsx'
import { clone, camelCase, isEmpty, filter } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { AddAPhoto, DragIndicator, MoreVert, DeleteOutline, Error } from '@mui/icons-material'
import { Button, Box, Typography, Menu, MenuItem, IconButton, SvgIcon } from '@mui/material'

import { useStores } from 'models'
import { IconEdit } from 'assets/images'
import { ModalWrapper, MenuIcon } from 'components'

import { useStyles } from './upload-image.styles'
import { UploadImageProps } from './upload-image.props'

export const UploadImage: React.FC<UploadImageProps> = observer((props: UploadImageProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { open, onClose } = props
  const { 
    sampleStore: { selectedSample: { images, saveImage, getImages, removeImage }},
    notificationStore: { setValue: setNotificationValue }
  } = useStores()

  const [indexFile, setIndexFile] = React.useState<null | number>(null)
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorMenu)
  const [files, setFiles] = React.useState<any[]>([])
  const {getRootProps, getInputProps} = useDropzone({
    maxSize: 5242880,
    accept: 'image/jpeg, image/jpg, image/png',
    onDrop: (acceptedFiles, fileRejections) => {
      setFiles([...files, 
        ...acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })),
        ...fileRejections.map(file => {
          Object.assign(file.file, {
            preview: URL.createObjectURL(file.file),
            errors: file.errors
          })
  
          return file.file
        })
      ])
    }
  })

  const reorder = (startIndex, endIndex = 0) => {
    const items = clone(files)
    const [removed] = items.splice(startIndex, 1)
    items.splice(endIndex, 0, removed)

    setFiles(items)
  }

  const onDragEnd = (result) => {
    const { source, destination } = result
    
    // dropped outside the list
    if (!destination) return
    if (source.index === destination.index) return

    reorder(source.index, destination.index)
  }

  const onUploadImages = async () => {
    onClose()
    setNotificationValue('loading', true)

    try{
      const filteredFiles = filter(files, file => isEmpty(file.errors))
      if (isEmpty(filteredFiles)) return

      const actions: Array<any> = filteredFiles.map((file, index) => {
                                    return saveImage(file, (index + 1))
                                  })

      await Promise.all(actions)
      await getImages()
      setFiles([...images])
    } finally {
      setNotificationValue('loading', false)
    }  
  }

  const onRemoveImage = async (index) => {
    setNotificationValue('loading', true)

    try{
      const image = files[index]
      files.splice(index, 1)
      setFiles(files)
      if (image.id) await removeImage(image)
    } finally {
      setNotificationValue('loading', false)
    }  
  }

  React.useEffect(() => {
    // TODO: Remove file url for memory leaks
    // files.forEach(file => URL.revokeObjectURL(file.preview))
    if (!open) return

    setFiles([...images])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <ModalWrapper
        maxWidth='sm'
        open={open}
        onClose={onClose}
      >
        <Typography variant='h4' sx={{mb: 1}}>{t('report.uploadPhoto')}</Typography>
        <Typography variant='body2'>{t('report.uploadImageTypesDescription', {max: 5})}</Typography>

        <Box {...getRootProps()} className={classes.dropBoxImage}>
          <input {...getInputProps()} />
          <AddAPhoto color='primary' sx={{mb: 2, fontSize: 50}}/>
          <Typography paragraph>{t('report.clickAndDragDescription')}</Typography>
        </Box>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppableImages">
            {provided => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {files.map((file, index) => {
                  const url = file.id ? file.imageUrl : file.preview

                  return (
                    <Draggable key={url} draggableId={url} index={index}>
                      {(provided, snapshot) => (
                        <Box
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          className={clsx(classes.filePreview, snapshot.isDragging && 'dragging', file?.errors && 'error')}
                        >
                          <Box className={classes.imageWrapper}>
                            <DragIndicator sx={{mr: 1}} />
                            <img src={url} alt='sample' className={classes.fileImage} />
                          </Box>

                          {file?.errors?.map((error, index) => 
                            <Typography key={`error-${index}-${url}`} variant='caption' color='error'>
                              {error.code === "file-too-large" ? 
                                    t(`error.${camelCase(error.code)}`, {maxSize: '5 MB'}) :
                                    error.message
                              }
                            </Typography>
                          )}

                          {file?.errors && 
                            <IconButton
                              size="small"
                              color="error"
                            >
                              <Error />
                            </IconButton>
                          }

                          <IconButton
                            size="small"
                            color="primary"
                            onClick={(event) => {
                              setIndexFile(index)
                              setAnchorMenu(event.currentTarget)
                            }}
                          >
                            <MoreVert />
                          </IconButton>
                          <Menu
                            anchorEl={anchorMenu}
                            open={openMenu}
                            onClose={() => setAnchorMenu(null)}
                            onClick={() => setAnchorMenu(null)}
                          >
                            <MenuItem
                              disabled={indexFile === 0}
                              onClick={() => reorder(indexFile)}
                            >
                              <MenuIcon><SvgIcon component={IconEdit} inheritViewBox /></MenuIcon>
                              {t('report.setAsThumbnail')}
                            </MenuItem>  
                            <MenuItem
                              onClick={() => onRemoveImage(indexFile)}
                            >
                              <MenuIcon><DeleteOutline /></MenuIcon>
                              {t('common.delete')}
                            </MenuItem>     
                          </Menu>
                        </Box>
                      )}
                    </Draggable>
                  )}
                )}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>

        <Box sx={{mt: 2, textAlign: 'right'}}>
          <Button
            size='small'
            variant='outlined'
            onClick={onClose}
            className='wide'
            sx={{ml: 1}}
          >
            {t('common.cancel')}
          </Button>
          <Button
            size='small'
            variant='contained'
            onClick={onUploadImages}
            className='wide'
            sx={{ml: 1}}
          >
            {t('report.done')}
          </Button>
        </Box>
      </ModalWrapper>
  )
})