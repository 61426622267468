import { concat } from 'lodash'

import { acidityDescriptorList } from './acidity-descriptors'
import { aftertasteDescriptorList } from './aftertaste-descriptors'
import { balanceDescriptorList } from './balance-descriptors'
import { bodyDescriptorList } from './body-descriptors'
import { flavorDescriptorList } from './flavor-descriptors'
import { defectDescriptorList } from './defect-descriptors'

export const allDescriptor =  concat(
                                acidityDescriptorList, aftertasteDescriptorList, balanceDescriptorList, 
                                bodyDescriptorList, flavorDescriptorList, defectDescriptorList
                              )