/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:36:04
 * @modify date 2022-04-20 23:28:08
 */

import Rollbar from 'rollbar'
import mixpanel from 'mixpanel-browser';
import { chain, isEmpty } from 'lodash'
import { create } from "apisauce"
import { AxiosRequestConfig } from 'axios'
import { flow } from "mobx-state-tree"

import { JWT_STORAGE_KEY, ROLLBAR, MIXPANEL_API_KEY } from "config/env"
import { loadString } from "utils/storage"
import { GeneralApiProblem, ApiErrorKind } from "services/api"

const retry = async (config: AxiosRequestConfig) => {
  const headers = config.headers
  const authorizationBearer = await loadString(JWT_STORAGE_KEY)
  if (authorizationBearer) headers.Authorization = authorizationBearer

  const request = create({
    baseURL: config.baseURL,
    timeout: config.timeout,
    headers
  })

  let query = ''
  if (!isEmpty(config.params)) query = "?" + chain(config.params)
                                            .map((v,k) => { return `${k}=${v}`})
                                            .join('&')
                                            .value()

  const url = config.url + query
  const payload = config.data ? JSON.parse(config.data) : {}
  await request[config.method!](url, payload)
}

/**
 * Handle general api request error, like 500, 404 and etc.
 */
export const withGeneralError = (self: any) => ({
  actions: {
    /**
     * Check for general error.
     */
    checkForGeneralError: flow(function * (error: GeneralApiProblem) {
      const rollbar = new Rollbar(ROLLBAR)
      rollbar.error(error)

      mixpanel.init(MIXPANEL_API_KEY, {debug: true});
      mixpanel.track(error.kind, {
        error
      });

      const { rootStore: { userStore, modalStore } } = self

      switch (error.kind) {
        case ApiErrorKind.CONNECTION:
        case ApiErrorKind.TIMEOUT:
          modalStore.setValue('noConnection', true)
          break
        case ApiErrorKind.SERVER:
          modalStore.setValue('serverError', true)
          throw error
          break
        case ApiErrorKind.UNAUTHORIZED:
          yield userStore.refreshToken()
          yield retry(error.originalRequest!)
          break
        case ApiErrorKind.FORBIDDEN:
        case ApiErrorKind.GONE:
          userStore.logout()
          window.location.href = '/sign-in'
          break
        case ApiErrorKind.UNKNOWN:
        case ApiErrorKind.BAD_DATA:
        case ApiErrorKind.REJECTED:
        default:
          throw error
      }
    }),
  },
})
