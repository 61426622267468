import React, { useState } from 'react'
import { has } from "lodash"
import { useTranslation } from "react-i18next"
import { Grid } from '@mui/material'
import { TabPanel, LoadingButton } from '@mui/lab'

import { useStores } from 'models'
import {FormInput, StackAlert} from "components"
import { globalAction } from "utils"

export const RedeemCode: React.FC = () => {
  const { t } = useTranslation()
  const {
    redeemCode: { sendCode },
    notificationStore
  } = useStores()
  const [code, setCode] = useState<string>('')
  const [errors, setErrors] = useState<any>({})
  const [isSuccess, setIsSuccess] = useState(false)

  const onSendCode = () => {
    setErrors({})

    globalAction(notificationStore, {
      action: async() => await sendCode(code),
      afterAction: () => {
        setCode('')
        setIsSuccess(true)
        setTimeout(() => setIsSuccess(false), 10000)
      },
      setErrors: setErrors
    })
  }

  return (
    <TabPanel value="redeem-code">
      {has(errors, 'base') && <StackAlert alerts={errors['base']} sx={{mb: 2}} />}
      {isSuccess &&
        <StackAlert
          alerts={[
            {severity: 'success', title: t('redeemCode.couponCode'), message: t('redeemCode.success.successfullyRedeem')}
          ]}
          sx={{mb: 2}}
        />
      }

      <Grid container>
        <Grid item xs={12}>
          <FormInput
            label={t('redeemCode.code')}
            textFieldProps={{
              value: code,
              onChange: e => setCode(e.target.value)
            }}
          />
        </Grid>
      </Grid>

      <LoadingButton
        fullWidth
        size='large'
        variant="contained"
        disabled={!code}
        onClick={onSendCode}
        sx={{mt: 3}}
      >
        {t('redeemCode.redeem')}
      </LoadingButton>
    </TabPanel>
  )
}
