/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import { Instance, SnapshotOut, types } from "mobx-state-tree"

import { 
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"

import { sampleReportProps } from './sample-report.props'
import { withSampleReportViews } from './sample-report-view'
import { withSampleReportActions } from "./sample-report-action"

/**
 * Model description here for TypeScript hints.
 */
export const SampleReportStoreModel = types
  .model("SampleReportStore")
  .props(sampleReportProps)
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withSampleReportViews)
  .extend(withSampleReportActions)

type SampleReportStoreType = Instance<typeof SampleReportStoreModel>
export interface SampleReportStore extends SampleReportStoreType {}
type SampleReportStoreSnapshotType = SnapshotOut<typeof SampleReportStoreModel>
export interface SampleReportStoreSnapshot extends SampleReportStoreSnapshotType {}
