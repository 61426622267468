/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-06 20:03:53
 * @modify date 2022-04-13 20:27:17
 */

import React from 'react'
import {includes, clone, pull, find, chain, has, isString, isArray, isBoolean, isEmpty, filter} from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Box, Container, Button, Grid, Collapse, Typography, Divider, Tooltip, SvgIcon, Chip, IconButton, Tabs, Stack } from '@mui/material'
import { CompareArrows, NavigateBefore, NavigateNext, Clear as ClearIcon } from '@mui/icons-material'

import { useStores } from 'models'
import { findRoute } from 'constants/routes'
import { IconShare } from 'assets/images'
import { globalAction, validateEmail, pointerMessages, textDescriptorsTranslation } from 'utils'
import { HeaderTitle, Pagination, ModalConfirm, ModalWrapper, FormInput } from 'components'

import { SampleItem, AdvanceSearch, EmptySamples } from './components'
import { useStyles } from './review-samples.styles'

export const ReviewSamples: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const { search } = useLocation()
  const {
    userStore: { languagePreference },
    cuppingSessionStore: { getCuppingSession },
    sampleStore: { setValue: setSampleValue },
    reviewSampleStore: { search: { name, generalSearch, setValue: setSearchValue, reset: resetSearch },
      share: { title, to, message, setValue: setShareValue },
      searchValues, samples, page, totalPage, records, getSamples, removeSample, shareSamples,
      setCuppingSamples, setValue: setReviewSampleValue,
    },
    sampleShipmentStore: { setSamplesToBeShipment },
    compareStore: { setValue: setCompareValue },
    guideStore: { guideSample, guideScore },
    notificationStore
  } = useStores()

  const homeLink = findRoute('home')
  const compareLInk = findRoute('compare')
  const sampleScoreLink = findRoute('sampleScore')
  const sampleReviewLink = findRoute('sampleReview')
  const sampleInformationLink = findRoute('sampleInformation')
  const [expanded, setExpanded] = React.useState(false)
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [confirmAction, setConfirmAction] = React.useState(() => () => {})
  const [description, setDescription] = React.useState('')
  const [openShare, setOpenShare] = React.useState(false)
  const [ids, setIds] = React.useState<String[]>([])
  const [isReview, setIsReview] = React.useState(true)
  const [disableCompare, setDisableCompare] = React.useState(true)
  const [cuppingProtocols, setCuppingProtocols] = React.useState<String[]>([])
  const [shareErrors, setShareErrors] = React.useState<any>({})
  const [isMobile, setIsMobile] = React.useState(false)
  const [temporaryAcidityAndSaltDescriptors, setTemporaryAcidityAndSaltDescriptors] = React.useState<any[]>([])
  const [temporaryBodyAndMouthfeelDescriptors, setTemporaryBodyAndMouthfeelDescriptors] = React.useState<any[]>([])
  const [temporaryFragranceDescriptors, setTemporaryFragranceDescriptors] = React.useState<any[]>([])
  const [temporaryFlavorAndAftertasteDescriptors, setTemporaryFlavorAndAftertasteDescriptors] = React.useState<any[]>([])
  const [temporaryDefectsDescriptors, setTemporaryDefectsDescriptors] = React.useState<any[]>([])
  const [temporaryListSearch, setTemporaryListSearch] = React.useState<any[]>([])
  const [openShareReport, setOpenShareReport] = React.useState(false)
  const [shareReportIds, setShareReportIds] = React.useState("")
  const [searchAdvanceData,setSearchAdvanceData] = React.useState<any[]>([])
  const [valueTabs, setValueTabs] = React.useState(0)
  const [reservedSamples, setReservedSamples] = React.useState<any[]>([])

  const onCheck = (e, id: string) => {
    const sample = find(samples, ['id', id])

    const newIds = clone(ids)
    const newCuppingProtocols = clone(cuppingProtocols)
    const newReservedSamples = clone(reservedSamples)
    if (e.target.checked) {
      newIds.push(id)
      newCuppingProtocols.push(sample.cuppingProtocol)
      newReservedSamples.push(sample)
    } else {
      pull(newIds, id)

      const index = newCuppingProtocols.indexOf(sample.cuppingProtocol)
      if (index >= 0) newCuppingProtocols.splice(index, 1)
      pull(newReservedSamples, sample)
    }

    setIds(newIds)
    setReservedSamples(newReservedSamples)
    setCuppingProtocols(newCuppingProtocols)
  }

  const onShareReport = (id: string) => {
    setShareReportIds(id)
    setOpenShare(true)
    setOpenShareReport(true)
  }

  const onShareSamples = () => {
    setShareErrors({})
    const validateEmails = chain(to).split(',')
                            .map(email => { return validateEmail(email.replace(/\s/g, ""))})
                            .every(Boolean).value()

    if (!validateEmails) {
      setShareErrors({ "email": [{ message: t('sample.error.emailIsInvalid') }] })
      return
    }

    setShareValue('samplesToShare', ids.join(','))

    globalAction(notificationStore, {
      action: shareSamples,
      afterAction: () => {
        setIds([])
        setOpenShare(false)
      }
    })
  }

  const onGetSamples = () => {
    globalAction(notificationStore, {
      action: async () => await getSamples(params.token)
    })
  }

  const onAction = (token: string, id: string, link: string) => {
    globalAction(notificationStore, {
      action: async () => await getCuppingSession(token),
      afterAction: () => {
        setSampleValue('selectedSample', id)
        navigate(link)
      }
    })
  }

  const onSeeReport = async (token: string, id: string) => {
    if (!isReview) {
      navigate(`/sample-report/${token}`)
      return
    }

    const sample = find(samples, ['id', id])
    if (!sample) return

    onAction(token, id, `/sample-report/${sample.uniqueToken}`)
  }

  const onRemoveSample = async (id: string, isFully = false) => {
    setOpenConfirm(false)
    globalAction(notificationStore, {
      action: async () => await removeSample(id, isFully)
    })
  }

  const onCompareSample = async () => {
    setCompareValue('samples', [])
    setCompareValue('ids', ids)
    navigate(compareLInk)
  }

  const onSetLayout = () => {
    if(window.innerWidth < 900) {
      setIsMobile(true)
      return
    }

    setIsMobile(false)
  }

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabs(newValue);
  };

  const renderSearchValue = (item) => {
    let label: string = item.label
    let resetValue: any = ''

    if (isString(item.value)) label += `: "${item.value}"`
    if (isArray(item.value)) label += `: "${textDescriptorsTranslation(languagePreference, item.value)}"`
    if (isBoolean(item.value)) resetValue = false

    return (
      <Chip
        key={item.id}
        size="medium"
        variant='outlined'
        color='primary'
        sx={{ mr: 1, mb: 1, marginTop:1}}
        label={label}
        onDelete={() => {
          setSearchValue(item.key || item.id, resetValue)
          onGetSamples()
          setTemporaryListSearch(current =>
            current.filter(data => {
              return data !== item;
            }),
          );
        }}
      />
    )
  }

  const handleChangeCategory = () => {
    temporaryListSearch.map(item =>
      Object.keys(searchValues).forEach(k => {
        if(k !== item )
        setTemporaryListSearch(current =>
          current.filter(data => {
            return data !== item;
          }),
        );
      })
    )
  }

  React.useEffect(() => {
    setDisableCompare(true)

    if (ids.length < 2 || ids.length > 4) return

    const sameProtocol = cuppingProtocols.every( (val, i, arr) => val === arr[0] )
    setDisableCompare(!sameProtocol)
  }, [ids.length])

  const renderSamples = () => {
    if (samples.length === 0) return <EmptySamples />

    return (
      <Box>
        {isMobile && !isEmpty(searchValues) &&
          <Box
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              marginTop: -3,
              marginBottom: 2
            }}
          >
            <Tabs
              value={valueTabs}
              onChange={handleChangeTabs}
              variant="scrollable"
              allowScrollButtonsMobile
              scrollButtons="auto"
              style={{ flex: 1, display: 'flex' }}
              TabIndicatorProps={{
                sx: {
                  display: "none"
                }

              }}
              ScrollButtonComponent={(props) => {

                if (
                  props.direction === "left" &&
                  !props.disabled
                ) {
                  return (

                    <Box padding={1}>
                      <IconButton size="small" style={{ backgroundColor: "#EAE7F0" }} {...props}>
                        <NavigateBefore style={{ color: "#444F58" }} />

                      </IconButton>
                    </Box>
                  );
                } else if (
                  props.direction === "right" &&
                  !props.disabled
                ) {
                  return (
                    <Box padding={1}>
                      <IconButton size="small" style={{ backgroundColor: "#EAE7F0" }}{...props}>
                        <NavigateNext style={{ color: "#444F58" }} />

                      </IconButton>
                    </Box>
                  );
                } else {
                  return null;
                }
              }}
            >

              {Object.keys(searchValues).map(k => {
                const value = find(searchAdvanceData, v => {
                  return (v.key || v.id) === k
                })
                if (value) return renderSearchValue(value)

                return null
              })}
            </Tabs>
          </Box>
        }

        {!isMobile && !isEmpty(searchValues) &&
          <Grid container sx={{p: 1}}>
            <Grid item xs={10}>
              <Tabs
                value={valueTabs}
                onChange={handleChangeTabs}
                variant="scrollable"
                scrollButtons="auto"
                style={{ flex: 1, display: 'flex' }}
                TabIndicatorProps={{
                  sx: {
                    display: "none"
                  }

                    }}
                    ScrollButtonComponent={(props) => {

                      if (
                        props.direction === "left" &&
                        !props.disabled
                      ) {
                        return (

                          <Box padding={1}>
                            <IconButton size="small" style={{ backgroundColor: "#EAE7F0" }} {...props}>
                              <NavigateBefore style={{ color: "#444F58" }} />

                            </IconButton>
                          </Box>
                        );
                      } else if (
                        props.direction === "right" &&
                        !props.disabled
                      ) {
                        return (
                          <Box padding={1}>
                            <IconButton size="small" style={{ backgroundColor: "#EAE7F0" }}{...props}>
                              <NavigateNext style={{ color: "#444F58" }} />

                            </IconButton>
                          </Box>
                        );
                      } else {
                        return null;
                      }
                    }}
                  >

                    {Object.keys(searchValues).map(k => {
                      const value = find(searchAdvanceData, v => {
                        return (v.key || v.id) === k
                      })
                      if (value) return renderSearchValue(value)

                  return null
                })}
              </Tabs>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: 'right', mt: 1 }}>
              <Button
                size='small'
                startIcon={<ClearIcon sx={{color: '#E74C3C'}}/>}
                onClick={() => {
                  resetSearch()
                  onGetSamples()
                  setExpanded(false)
                  window.scrollTo(0, 0)
                }}
              >
                <Typography variant='body2'> {t('sample.clearAllFilters')} </Typography>
              </Button>
            </Grid>
          </Grid>
        }

        <Grid container spacing={1} className={classes.gridWrapper}>
          <Grid item xs={12} className={classes.gridHeader}>
            <Grid
              container
              sx={{alignItems: 'center', textAlign: 'center'}}
            >
              <Grid
                item
                md={2}
                className={classes.headerItem}
                sx={{ textAlign: 'left', paddingLeft: '45px' }}
              >
                {t('sample.samples.name')}
              </Grid>
              <Grid item md={9}>
                <Grid container>
                  <Grid item className={classes.headerItem} xs>{t('sample.samples.createdOn')}</Grid>
                  <Grid item className={classes.headerItem} xs>{t('sample.samples.species')}</Grid>
                  <Grid item className={classes.headerItem} xs>{t('sample.samples.type')}</Grid>
                  <Grid item className={classes.headerItem} xs>{t('sample.samples.origin')}</Grid>
                  <Grid item className={classes.headerItem} xs>{t('sample.samples.process')}</Grid>
                  <Grid item className={classes.headerItem} xs>{t('sample.samples.contract')}</Grid>
                  <Grid item className={classes.headerItem} xs>{t('sample.samples.grade')}</Grid>
                  <Grid item className={classes.headerItem} xs>{t('sample.samples.averageScore')}</Grid>
                </Grid>
              </Grid>
              <Grid item md={1}>
                <Grid container>
                  <Grid item xs={8}>{t('sample.samples.action')}</Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {samples.map(sample =>
            <Grid item xs={12} key={sample.id} className={classes.listItem}>
              <SampleItem
                {...sample}
                ids={ids}
                isMobile={isMobile}
                isReview={isReview}
                onEdit={() => {
                  sample.cuppingSessionId ?
                    onAction(sample.cuppingSessionUniqueToken, sample.id, sampleInformationLink) :
                    navigate(`/samples/${sample.uniqueToken}`)
                }}
                onCupping={() => onAction(
                  sample.cuppingSessionUniqueToken, sample.id,
                  sample.averageScore > 0 ? sampleReviewLink : sampleScoreLink
                )}
                onReport={() => isReview ?
                  onSeeReport(sample.cuppingSessionUniqueToken, sample.id) :
                  onSeeReport(sample.uniqueToken, sample.id)
                }
                seeReport={() => {
                  onShareReport(sample.id)
                }}
                onArchive={() => {
                  setOpenConfirm(true)
                  setConfirmAction(() => () => onRemoveSample(sample.id))
                  setDescription(t('sample.removeSampleDescription'))
                }}
                onRemove={() => {
                  setOpenConfirm(true)
                  setConfirmAction(() => () => onRemoveSample(sample.id, true))
                  setDescription(t('sample.removeSampleDescription'))
                }}
                onCheck={(e) => onCheck(e, sample.id)}
                goToDetail={() => navigate(`/samples/${sample.uniqueToken}/details`)}
              />
            </Grid>
          )}
        </Grid>

        {totalPage > 0 && <Pagination page={page} count={totalPage} onChange={(e, p) => setReviewSampleValue('page', p)} /> }
      </Box>

    )
  }

  React.useEffect(() => {
    setDisableCompare(true)

    if (ids.length < 2 || ids.length > 4) return

    const sameProtocol = cuppingProtocols.every( (val, i, arr) => val === arr[0] )
    setDisableCompare(!sameProtocol)
  }, [ids.length])

  React.useEffect(() => {
    if (guideSample || guideScore) return

    onGetSamples()
  }, [page, guideSample, guideScore])

  React.useEffect(() => {
    if (search) {
      const query = new URLSearchParams(search).get('search')

      setReviewSampleValue('page', 1)
      setSearchValue('name', query)
      setSearchValue('producerName', query)
      setSearchValue('processName', query)
      setSearchValue('supplierName', query)
      setSearchValue('generalSearch', true)
      onGetSamples()
    }
  }, [search])

  React.useEffect(() => {
    if (params.token) setIsReview(false)

    if (search) {
      const query = new URLSearchParams(search).get('search')

      setReviewSampleValue('page', 1)
      setSearchValue('name', query)
      setSearchValue('producerName', query)
      setSearchValue('processName', query)
      setSearchValue('supplierName', query)
      setSearchValue('generalSearch', true)
    } else {
      resetSearch()
    }

    (async () => await onGetSamples())()
  }, [])

  React.useLayoutEffect(() => {
    setShareValue('message', t('sample.shareSampleText'))
    window.addEventListener('resize', onSetLayout)

    onSetLayout()
    return () => window.removeEventListener("resize", onSetLayout)
  }, [])

  return (
    <Box sx={{pb: {xs: 7, md: 0}}}>
      {isMobile ?
        <HeaderTitle
         backUrl={homeLink}
         title={t('menu.reviewSamples')}
         {...(isReview && {breadcrumb: t('common.backTo', { menu: t('menu.home')})})}
         {...(generalSearch && {subtitle: t('sample.showingResult', {total: records, query: name})})}
        /> :
        <HeaderTitle
          backUrl={homeLink}
          title={t('menu.reviewSamples')}
          {...(isReview && {breadcrumb: t('common.backTo', { menu: t('menu.home')})})}
          {...(generalSearch && {subtitle: t('sample.showingResult', {total: records, query: name})})}
        />
      }

      {isReview &&
        <Container>
          <Stack direction="row" spacing={1} justifyContent="end">
            <Button
                size='large'
                variant='contained'
                onClick={() => navigate(`/samples/new`)}
              >
                {t('sample.samples.newSample')}
            </Button>
            <Button
                size='large'
                variant='contained'
                disabled={reservedSamples.length === 0}
                onClick={() => {
                  setSamplesToBeShipment(reservedSamples)
                  navigate(`/sample-shipments/new`)
                }}
            >
               {t('sample.samples.newShipment')}
            </Button>
            <Button
                size='large'
                variant='contained'
                disabled={reservedSamples.length === 0}
                onClick={() => {
                  setCuppingSamples(reservedSamples)
                  navigate(`/cupping-sessions/new`)
                }}
            >
              {t('sample.samples.newCuppingSession')}
            </Button>
            <Tooltip title={disableCompare ? `${t('sample.theSameCuppingProtocolDescription')}` : ""} arrow>
              <span>
                <Button
                  size='large'
                  variant='contained'
                  disabled={disableCompare}
                  onClick={onCompareSample}
                  sx={{ height: '100%' }}
                >
                  {isMobile ? <CompareArrows/> : t('sample.compare')}
                </Button>
              </span>
            </Tooltip>
            <Button
              size='large'
              variant='contained'
              disabled={ids.length < 1}
              onClick={() => setOpenShare(true)}
            >
              {isMobile ? <SvgIcon component={IconShare} inheritViewBox/> : t('sample.share')}
            </Button>
            <Button
              size='large'
              variant='contained'
              onClick={() => {
                setExpanded(!expanded)
                handleChangeCategory()
              }}
            >
              {t('sample.advanceSearch')}
            </Button>
          </Stack>
        </Container>
      }

      <Container sx={{mb: 3}}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <AdvanceSearch
            isMobile={isMobile}
            expanded={expanded}
            temporaryDefectsDescriptors = {temporaryDefectsDescriptors}
            temporarySearchAdvanceData = {searchAdvanceData}
            temporaryFlavorAndAftertasteDescriptors = {temporaryFlavorAndAftertasteDescriptors}
            temporaryFragranceDescriptors = {temporaryFragranceDescriptors}
            temporaryBodyAndMouthfeelDescriptors = {temporaryBodyAndMouthfeelDescriptors}
            temporaryAcidityAndSaltDescriptors = {temporaryAcidityAndSaltDescriptors}
            temporaryListSearch = {temporaryListSearch}
            addTemporarySearchAdvanceData={(item)=>{
              setSearchAdvanceData(item)
            }}
            addTemporaryDefectsDescriptors={(descriptors) => {
              setTemporaryDefectsDescriptors(descriptors)
            }}
            addTemporaryFlavorAndAftertasteDescriptors={(descriptors) => {
              setTemporaryFlavorAndAftertasteDescriptors(descriptors)
            }}
            addTemporaryFragranceDescriptors={(descriptors) => {
              setTemporaryFragranceDescriptors(descriptors)
            }}
            addTemporaryBodyAndMouthfeelDescriptors={(descriptors) => {
              setTemporaryBodyAndMouthfeelDescriptors(descriptors)
            }}
            addTemporaryAcidityAndSaltDescriptors={(descriptors) => {
              setTemporaryAcidityAndSaltDescriptors(descriptors)
            }}
            addTemporaryListSearch={(data) => {
              setTemporaryListSearch(data)
            }}
            onSearch={() => {
              setSearchValue('generalSearch', false)
              setReviewSampleValue('page', 1)
              handleChangeCategory()
              onGetSamples()
            }}
            onCancel={() => {
              onGetSamples()
              setExpanded(false)
              window.scrollTo(0, 0)
              setTemporaryDefectsDescriptors([])
              setTemporaryFlavorAndAftertasteDescriptors([])
              setTemporaryFragranceDescriptors([])
              setTemporaryBodyAndMouthfeelDescriptors([])
              setTemporaryAcidityAndSaltDescriptors([])
              setTemporaryListSearch([])
            }}
          />
        </Collapse>
      </Container>

      {/* Tab */}
      <Container sx={{px: {xs: 0, md: 3}, mb: 5}}>
        {renderSamples()}
      </Container>

      <ModalConfirm
        open={openConfirm}
        description={description}
        onClose={() => setOpenConfirm(false)}
        onConfirm={confirmAction}
      />

      <ModalWrapper
        maxWidth='sm'
        open={openShare}
        onClose={() => {
          setOpenShare(false);
          setOpenShareReport(false)}}
      >
        <Typography variant='h5' sx={{mb: 2}}>{t('sample.shareSampleList')}</Typography>
        <Divider />
        {openShareReport ?
          <FormInput
            label={t('sample.samplesToShare')}
            textFieldProps={{
              disabled: true,
              value: shareReportIds
            }}
          /> :
          <FormInput
            label={t('sample.samplesToShare')}
            textFieldProps={{
              disabled: true,
              value: ids.join(', ')
            }}
          />
        }

        <FormInput
          label={t('sample.title')}
          textFieldProps={{
            value: title,
            onChange: (e) => setShareValue('title', e.target.value)
          }}
        />
        <FormInput
          label={t('sample.to')}
          textFieldProps={{
            value: to,
            onChange: (e) => setShareValue('to', e.target.value),
            helperText: has(shareErrors, 'email') ? pointerMessages('email', shareErrors) : t('report.emailShareSampleDescription'),
            error: has(shareErrors, 'email')
          }}
        />
        <FormInput
          label={t('sample.emailMessage')}
          textFieldProps={{
            multiline: true,
            rows: 3,
            value: message,
            onChange: (e) => setShareValue('message', e.target.value)
          }}
        />

        <Button
          variant='contained'
          className='wide'
          sx={{mt: 3}}
          disabled={!title || !to || !message}
          onClick={onShareSamples}
        >
          {t('report.send')}
        </Button>
      </ModalWrapper>
    </Box>
  )
})
