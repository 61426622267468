import { Api } from "./api"

export class GreenGradingApi extends Api {
  constructor() {
    super('green_gradings', [
      'color', 'weight', 'smell', 'defects', 'sampleId', 'greenGradingTypeId',
      'roastColor', 'ovenTest', 'otaTest', 'glyphosateTest',
      'size_14', 'size_15', 'size_16', 'size_17', 'size_18', 'size_19',
    ])
  }
}
