/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-20 17:19:32
 * @modify date 2022-04-20 17:19:32
 */

import {ceil, isEmpty, chain, snakeCase, filter, pull, clone} from "lodash"
import { flow, applySnapshot, getSnapshot } from "mobx-state-tree"
import { ApiResult } from "services/api"
import { DATA_PER_PAGE } from "config/env"
import sample from 'fixtures/sample.json'
import { reviewSamplePreProcessor } from "./review-sample-preprocessor"

export const withReviewSampleActions = (self: any) => ({
  actions: {
    setCuppingSamples(samples) {
      const newCuppingSamples = samples.map(sample => reviewSamplePreProcessor(sample))

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        cuppingSamples: newCuppingSamples
      })
    },
    getSamples: flow(function * (token = '') {
      try {
        const { guideSample, guideScore } = self.rootStore.guideStore

        if (guideSample || guideScore) {
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            page: 1,
            totalPage: 1,
            samples: sample.data,
            nextUrl: undefined,
          })

          return
        }

        let search = {}
        if (!token) {
          chain(self.search)
            .omitBy(isEmpty)
            .assign({scoredByMe: self.search.scoredByMe, generalSearch: self.search.generalSearch})
            .forEach((value, key) => { search[`search[${snakeCase(key)}]`] = value})
            .value()
        }

        const payload: any = {
          ...search,
          "size": DATA_PER_PAGE,
          "page[number]": self.page,
        }

        const res: ApiResult = token ?
          yield self.environment.shareSampleApi.all(payload, token) :
          yield self.environment.masterSampleApi.all(payload)

      if (res.kind === "ok") {
        const totalPage = ceil(res.meta.pagination.records / DATA_PER_PAGE)
        const hasNextPage = res.links && res.links.next
        const samples = res.data.map((sample: any) => reviewSamplePreProcessor(sample))

        applySnapshot(self, {
          ...self,
          totalPage,
          samples,
          records: res.meta.pagination.records,
          nextUrl: hasNextPage ? res.links.next : undefined,
        })
      }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    shareSamples: flow(function * (){
      try {
        const payload: any = self.share
        const additionalPath = 'sharing'

        const res: ApiResult = yield self.environment.sampleApi.save(payload, {}, additionalPath)

        if (res.kind === "ok") {
          self.share.reset()
          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    removeSample: flow(function * (id: string, isFully = true) {
      try {
        let additionalPath
        if (isFully) additionalPath = '?destroy_fully=true'

        const res: ApiResult = yield self.environment.sampleApi.remove(id, {}, additionalPath)

        if (res.kind === "ok") {
          if (self.samples.length === 1) {
            yield self.getSamples()
          } else {
            const newSamples = filter(self.samples, sample => { return sample.id !== id })
            applySnapshot(self, {
              ...getSnapshot(self as object) as any,
              samples: newSamples
            })
          }

          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
  }
})
