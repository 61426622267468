/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import { filter } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { Instance, SnapshotOut, types } from "mobx-state-tree"

import { 
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"
import { NotificationModel } from 'models/notification'

/**
 * Model description here for TypeScript hints.
 */
export const NotificationStoreModel = types
  .model("NotificationStore")
  .props({
    notifications: types.array(NotificationModel),
    loading: types.optional(types.boolean, false)
  })
  .extend(withRootStore)
  .extend(withSetValue)
  .extend(withReset)
  .actions(self => ({
    setNotification(message: object) {
      self.notifications.push({...message, id: uuidv4()})
    },
    removeNotification(key: string) {  
      const notifications = filter(self.notifications, notif => notif.id !== key)
      self.setValue('notifications', notifications)
    },
    setLoading(status: boolean) {
      self.setValue('loading', status)
    }
  }))

type NotificationStoreType = Instance<typeof NotificationStoreModel>
export interface NotificationStore extends NotificationStoreType {}
type NotificationStoreSnapshotType = SnapshotOut<typeof NotificationStoreModel>
export interface NotificationStoreSnapshot extends NotificationStoreSnapshotType {}
