import {types} from "mobx-state-tree";

export const shipmentOrderFormProps = {
  id: types.string,
  customer: types.string,
  recipient: types.string,
  streetAddress: types.string,
  streetAddress2: types.string,
  city: types.string,
  state: types.string,
  country: types.string,
  zipCode: types.string,
  isUrgentDelivery: types.boolean,
  requestedByEmail: types.string,
  recipientEmails: types.string,
  traderEmails: types.string,
  qualityControlEmails: types.string,
  approvalEmails: types.string,
}
