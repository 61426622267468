/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-17 23:22:17
 * @modify date 2022-04-17 23:22:17
 * @desc [description]
 */

import { types } from "mobx-state-tree"

export const sampleImageProps = {
  id: types.maybeNull(types.string),
  orderNumber: types.optional(types.number, 0),
  imageUrl: types.maybeNull(types.string),
  imageName: types.maybeNull(types.string),
}