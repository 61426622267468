/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-05-30 23:02:13
 * @modify date 2022-05-30 23:02:13
 */

import React from 'react'
import { observer } from 'mobx-react-lite'
import { Box } from '@mui/material'

import { useStores } from 'models'
import { ModalWrapper } from 'components'
import { VIDEO_TUTORIAL_URL } from 'config/env'

export const Video: React.FC = observer(() => {
  const { 
    userStore: { sawVideoTutorial, updateVideoTutorial, setValue: setUserValue }
  } = useStores()

  return (
    <ModalWrapper 
      open={sawVideoTutorial}
      onClose={() => {
        setUserValue('sawVideoTutorial', false)
        updateVideoTutorial()
      }}
    >
      <Box sx={{mt: 1}}>
        <video width="100%" height="100%" controls autoPlay>
          <source src={VIDEO_TUTORIAL_URL} type='video/mp4' />
        </video>
      </Box>
    </ModalWrapper>
  )
})