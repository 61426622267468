import React from 'react';
import {useTranslation} from "react-i18next";
import {Country, State} from "country-state-city";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Select, Stack,
  Typography
} from "@mui/material";

import { ShipmentOrderFormProps } from './shipment-order-form.props';
import {FormInput} from "../form-input/form-input";

export const ShipmentOrderForm = (props: ShipmentOrderFormProps) => {
  const { t } = useTranslation()
  const {
    customer, streetAddress, recipient, streetAddress2, country, state, city, zipCode, isUrgentDelivery, requestedByEmail,
    recipientEmails, traderEmails, qualityControlEmails, approvalEmails, setValue
  } = props

  const emailPlaceholder = t('shipmentOrderForm.emailHelperText')

  return (
    <Box>
      <Grid container spacing={2}>

        {/* Left content */}
        <Grid item xs={12} md={6}>
          <Typography variant='h6'>{t('shipmentOrderForm.shippingInformation')}</Typography>

          <Grid container spacing={2} mt={1} mb={4}>
            <Grid item xs={12} md={6}>
              <FormInput
                label={t('shipmentOrderForm.customer')}
                formControlProps={{ margin: 'none' }}
                textFieldProps={{
                  placeholder: t('shipmentOrderForm.writeHere'),
                  value: customer,
                  onChange: (event) => setValue('customer', event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                label={t('shipmentOrderForm.recipientName')}
                formControlProps={{ margin: 'none' }}
                textFieldProps={{
                  placeholder: t('shipmentOrderForm.writeHere'),
                  value: recipient,
                  onChange: (event) => setValue('recipient', event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput label={t('shipmentOrderForm.country')} formControlProps={{ margin: 'none' }}>
                <Select
                  size='small'
                  defaultValue=""
                  value={country}
                  onChange={(event) => {
                    setValue('state', '')
                    setValue('country', event.target.value)
                  }}
                >
                  {Country.getAllCountries().map(country =>
                    <MenuItem key={country.isoCode} value={country.name}>{country.name}</MenuItem>
                  )}
                </Select>
              </FormInput>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput label={t('shipmentOrderForm.state')} formControlProps={{ margin: 'none' }}>
                <Select
                  size='small'
                  defaultValue=""
                  value={state}
                  onChange={(event) => {
                    setValue('state', event.target.value)
                  }}
                >
                  {State.getStatesOfCountryByName(country).map(state =>
                    <MenuItem key={state.isoCode} value={state.name}>{state.name}</MenuItem>
                  )}
                </Select>
              </FormInput>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                label={t('shipmentOrderForm.streetAddress')}
                formControlProps={{ margin: 'none' }}
                textFieldProps={{
                  placeholder: t('shipmentOrderForm.writeHere'),
                  value: streetAddress,
                  onChange: (event) => setValue('streetAddress', event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                label={`${t('shipmentOrderForm.streetAddress2')} (${t('shipmentOrderForm.optional')})`}
                formControlProps={{ margin: 'none' }}
                textFieldProps={{
                  placeholder: t('shipmentOrderForm.writeHere'),
                  value: streetAddress2,
                  onChange: (event) => setValue('streetAddress2', event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                label={t('shipmentOrderForm.city')}
                formControlProps={{ margin: 'none' }}
                textFieldProps={{
                  placeholder: t('shipmentOrderForm.writeHere'),
                  value: city,
                  onChange: (event) => setValue('city', event.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                label={t('shipmentOrderForm.zipCode')}
                formControlProps={{ margin: 'none' }}
                textFieldProps={{
                  placeholder: t('shipmentOrderForm.writeHere'),
                  value: zipCode,
                  onChange: (event) => setValue('zipCode', event.target.value)
                }}
              />
            </Grid>
          </Grid>

          <Typography variant='h6'>{t('shipmentOrderForm.otherInformation')}</Typography>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} md={6}>
              <FormControl margin="none">
                <FormLabel>{t('shipmentOrderForm.urgentDelivery')}</FormLabel>
                <FormControlLabel
                  label={t('shipmentOrderForm.yes')}
                  control={
                    <Checkbox
                      checked={isUrgentDelivery}
                      onChange={(e) =>  setValue('isUrgentDelivery', e.target.checked)}
                    />
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                label={t('shipmentOrderForm.requestedBy')}
                formControlProps={{ margin: 'none' }}
                textFieldProps={{
                  placeholder: 'john@example.com',
                  value: requestedByEmail,
                  onChange: (event) => setValue('requestedByEmail', event.target.value)
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Right content */}
        <Grid item xs={12} md={6}>
          <Typography variant='h6' mb="18px">
            {t('shipmentOrderForm.notificationInformation')}
          </Typography>

          <FormInput
            label={`${t('shipmentOrderForm.recipientsEmail')} <sup>1</sup>`}
            formControlProps={{ margin: 'none' }}
            textFieldProps={{
              placeholder: emailPlaceholder,
              value: recipientEmails,
              onChange: (event) => setValue('recipientEmails', event.target.value)
            }}
          />

          <Typography variant="subtitle1" mt={1.5}>{t('shipmentOrderForm.cc')}</Typography>
          <FormInput
            label={`${t('shipmentOrderForm.trader')} <sup>2</sup>`}
            formControlProps={{ margin: 'none' }}
            textFieldProps={{
              placeholder: emailPlaceholder,
              value: traderEmails,
              onChange: (event) => setValue('traderEmails', event.target.value)
            }}
          />

          <FormInput
            label={`${t('shipmentOrderForm.qc')} <sup>3</sup>`}
            formControlProps={{ margin: 'none', sx: { mt: 1.5 }}}
            textFieldProps={{
              placeholder: emailPlaceholder,
              value: qualityControlEmails,
              onChange: (event) => setValue('qualityControlEmails', event.target.value)
            }}
          />

          <FormInput
            label={`${t('shipmentOrderForm.sampleApprovalOnly')} <sup>4</sup>`}
            formControlProps={{ margin: 'none', sx: { mt: 1.5 }}}
            textFieldProps={{
              placeholder: emailPlaceholder,
              value: approvalEmails,
              onChange: (event) => setValue('approvalEmails', event.target.value)
            }}
          />

          <Stack mt={2} spacing={0.5}>
            <Typography variant="body3">*{t('shipmentOrderForm.emailHelperText')}</Typography>
            <Typography variant="body3"><sup>1</sup>{t('shipmentOrderForm.recipientDescription')}</Typography>
            <Typography variant="body3"><sup>2</sup>{t('shipmentOrderForm.traderDescription')}</Typography>
            <Typography variant="body3"><sup>3</sup>{t('shipmentOrderForm.qcDescription')}</Typography>
            <Typography variant="body3"><sup>4</sup>{t('shipmentOrderForm.approvalDescription')}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}
