import React from "react";
import i18n from "i18n/i18n"
import {useTranslation} from "react-i18next";
import {cloneDeep, uniq} from "lodash";
import moment from "moment/moment";
import {observer} from "mobx-react-lite";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import {
  Box,
  Button,
  Checkbox, Collapse,
  FormControlLabel, Stack, Typography,
} from "@mui/material";

import {useStores} from "models";

import {BasicTable, ModalWrapper} from "components";
import {AdvanceSearch} from "pages/review-samples/components";
import {countryName, globalAction, optionsTranslation} from "utils";

const columnHelper = createColumnHelper<any>()
const columns = [
  columnHelper.accessor('name', {
    id: 'name',
    header: ({ table }) =>
      <Box textAlign="left">
        <FormControlLabel
          label={i18n.t('sample.samples.name')}
          control={
            <Checkbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          }
        />
      </Box>,
    cell: info =>
      <Box textAlign="left">
        <FormControlLabel
          label={info.renderValue()}
          control={
            <Checkbox
              checked={info.row.getIsSelected()}
              onChange={info.row.getToggleSelectedHandler()}
            />
          }
        />
      </Box>,
  }),
  columnHelper.accessor('createdAt', {
    id: 'createdAt',
    header: () => <Box textAlign="left">{i18n.t('sample.samples.createdOn')}</Box>,
    cell: info => moment(info.renderValue()).format('DD/MM/YYYY'),
  }),
  columnHelper.accessor('species', {
    id: 'species',
    header: () => <Box textAlign="left">{i18n.t('sample.samples.species')}</Box>,
    cell: info => optionsTranslation('cuppingProtocol', info.renderValue()),
  }),
  columnHelper.accessor('sampleType', {
    id: 'sampleType',
    header: () => <Box textAlign="left">{i18n.t('sample.samples.type')}</Box>,
    cell: info => optionsTranslation('sampleType', info.renderValue()),
  }),
  columnHelper.accessor('countryCode', {
    id: 'countryCode',
    header: () => <Box textAlign="left">{i18n.t('sample.samples.origin')}</Box>,
    cell: info => countryName(info.renderValue()),
  }),
  columnHelper.accessor('processName', {
    id: 'processName',
    header: () => <Box textAlign="left">{i18n.t('sample.samples.process')}</Box>,
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('salesContractReference', {
    id: 'salesContractReference',
    header: () => <Box textAlign="left">{i18n.t('sample.samples.contract')}</Box>,
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('grade', {
    id: 'grade',
    header: () => <Box textAlign="left">{i18n.t('sample.samples.grade')}</Box>,
    cell: info => info.renderValue(),
  }),
]

interface AddSamplesModalProps {
  open: boolean
  onClose: () => void
  onSuccess: (samples) => void
}

export const AddSamplesModal = observer(({ open, onClose, onSuccess }: AddSamplesModalProps) => {
  const { t } = useTranslation()
  const {
    reviewSampleStore: { search: { setValue: setSearchValue, reset: resetSearch },
      searchValues, samples, page, totalPage, getSamples,
      setValue: setReviewSampleValue,
    },
    sampleShipmentStore: { getSamplesToBeShipment },
    notificationStore
  } = useStores()

  const [isMobile, setIsMobile] = React.useState(false)
  const [temporaryAcidityAndSaltDescriptors, setTemporaryAcidityAndSaltDescriptors] = React.useState<any[]>([])
  const [temporaryBodyAndMouthfeelDescriptors, setTemporaryBodyAndMouthfeelDescriptors] = React.useState<any[]>([])
  const [temporaryFragranceDescriptors, setTemporaryFragranceDescriptors] = React.useState<any[]>([])
  const [temporaryFlavorAndAftertasteDescriptors, setTemporaryFlavorAndAftertasteDescriptors] = React.useState<any[]>([])
  const [temporaryDefectsDescriptors, setTemporaryDefectsDescriptors] = React.useState<any[]>([])
  const [searchAdvanceData,setSearchAdvanceData] = React.useState<any[]>([])

  const [expanded, setExpanded] = React.useState(false)
  const [temporaryListSearch, setTemporaryListSearch] = React.useState<any[]>([])
  const [rowSelection, setRowSelection] = React.useState({})

  const table = useReactTable({
    data: cloneDeep(samples),
    columns,
    state: { rowSelection },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
  })

  const handleChangeCategory = () => {
    temporaryListSearch.map(item =>
      Object.keys(searchValues).forEach(k => {
        if(k !== item )
          setTemporaryListSearch(current =>
            current.filter(data => {
              return data !== item;
            }),
          );
      })
    )
  }

  const onGetSamples = () => {
    globalAction(notificationStore, {
      action: async () => await getSamples()
    })
  }

  const onSetLayout = () => {
    if(window.innerWidth < 900) {
      setIsMobile(true)
      return
    }

    setIsMobile(false)
  }

  const onAddingSamples = () => {
    const selections = uniq(Object.keys(rowSelection).map(key => parseInt(key)))

    const newSamples = samples.filter((_, index) => selections.includes(index))
    setRowSelection({})
    onSuccess(getSamplesToBeShipment(newSamples))
  }

  React.useEffect(() => {
    // resetSearch()

    (async () => await onGetSamples())()
  }, [])

  React.useLayoutEffect(() => {
    window.addEventListener('resize', onSetLayout)

    onSetLayout()
    return () => window.removeEventListener("resize", onSetLayout)
  }, [])

  return (
    <ModalWrapper open={open}>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <Box>
          <Typography variant="h5">{t('shipment.modal.addSample')}</Typography>
          <Typography>{t('shipment.modal.selectSamplesToBeAdd')}</Typography>
        </Box>
        <Button
          size='large'
          variant='contained'
          onClick={() => {
            setExpanded(!expanded)
            handleChangeCategory()
          }}
        >
          {t('sample.advanceSearch')}
        </Button>
      </Stack>

      <Collapse in={expanded} timeout="auto" unmountOnExit sx={{mb: 2}}>
        <AdvanceSearch
          isMobile={isMobile}
          expanded={expanded}
          temporaryDefectsDescriptors = {temporaryDefectsDescriptors}
          temporarySearchAdvanceData = {searchAdvanceData}
          temporaryFlavorAndAftertasteDescriptors = {temporaryFlavorAndAftertasteDescriptors}
          temporaryFragranceDescriptors = {temporaryFragranceDescriptors}
          temporaryBodyAndMouthfeelDescriptors = {temporaryBodyAndMouthfeelDescriptors}
          temporaryAcidityAndSaltDescriptors = {temporaryAcidityAndSaltDescriptors}
          temporaryListSearch = {temporaryListSearch}
          addTemporarySearchAdvanceData={(item)=>{
            setSearchAdvanceData(item)
          }}
          addTemporaryDefectsDescriptors={(descriptors) => {
            setTemporaryDefectsDescriptors(descriptors)
          }}
          addTemporaryFlavorAndAftertasteDescriptors={(descriptors) => {
            setTemporaryFlavorAndAftertasteDescriptors(descriptors)
          }}
          addTemporaryFragranceDescriptors={(descriptors) => {
            setTemporaryFragranceDescriptors(descriptors)
          }}
          addTemporaryBodyAndMouthfeelDescriptors={(descriptors) => {
            setTemporaryBodyAndMouthfeelDescriptors(descriptors)
          }}
          addTemporaryAcidityAndSaltDescriptors={(descriptors) => {
            setTemporaryAcidityAndSaltDescriptors(descriptors)
          }}
          addTemporaryListSearch={(data) => {
            setTemporaryListSearch(data)
          }}
          onSearch={() => {
            setSearchValue('generalSearch', false)
            setReviewSampleValue('page', 1)
            handleChangeCategory()
            onGetSamples()
          }}
          onCancel={() => {
            onGetSamples()
            setExpanded(false)
            window.scrollTo(0, 0)
            setTemporaryDefectsDescriptors([])
            setTemporaryFlavorAndAftertasteDescriptors([])
            setTemporaryFragranceDescriptors([])
            setTemporaryBodyAndMouthfeelDescriptors([])
            setTemporaryAcidityAndSaltDescriptors([])
            setTemporaryListSearch([])
          }}
        />
      </Collapse>

      <BasicTable table={table} page={page} totalPage={totalPage} />

      <Stack direction="row" spacing={2} justifyContent="end" mt={3}>
        <Button
          variant="contained"
          sx={{width: 200}}
          onClick={onAddingSamples}
        >
          {t(`shipment.button.submit`)}
        </Button>
        <Button
          variant="outlined"
          sx={{width: 200}}
          onClick={onClose}
        >
          {t("common.cancel")}
        </Button>
      </Stack>
    </ModalWrapper>
  )
})
