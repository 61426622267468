/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-17 22:31:28
 * @modify date 2022-02-17 22:31:28
 */

import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerWrapper: {
      paddingBottom: theme.spacing(1),
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(0),
        marginBottom: theme.spacing(1),
        textAlign: 'right',
      }
    },
    iconRounded: {
      width: 20,
      height: 20,
      backgroundColor: 'white',
      '& svg': {
        height: 16,
        fill: theme.palette.primary.main,
      }
    },
    buttonRounded: {
      borderRadius: theme.spacing(8),
      '&:hover': {
        '& svg': {
          fill: theme.palette.primary.dark
        }
      }
    },
  })
)
