export const addAlpha = (color, opacity) => {
  // coerce values so ti is between 0 and 1.
  var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

const COLORS = ['#6D5143', '#1D9F61', '#3952A1', '#E68B3D', '#EB3344', '#6253A1']

const lengthColor = COLORS.length

const getIndexColor = (index) => {

  if (index >= lengthColor) {
    getIndexColor(index - lengthColor)
  } else {
    return index
  }
}

export const getColor = (index) => {
  const i = getIndexColor(index)
  return COLORS[i]
}