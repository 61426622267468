/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import { uniq, map } from 'lodash'
import { Instance, SnapshotOut, types, flow, applySnapshot, getSnapshot } from "mobx-state-tree"

import { ApiResult } from "services/api"
import { 
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"
import { DATA_PER_PAGE } from "config/env"

/**
 * Model description here for TypeScript hints.
 */
export const ProcessStoreModel = types
  .model("ProcessStore")
  .props({
    processes: types.optional(types.array(types.string), []),
    search: types.optional(types.string, "")
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .actions(self=> ({
    setProcesss(processes) {
      applySnapshot(self, {
        ...getSnapshot(self),
        processes: processes
      })
    }
  }))
  .actions(self => ({
    getProcess: flow(function * () {
      try {
        const res: ApiResult = yield self
          .environment
          .processApi
          .all({
            "size": DATA_PER_PAGE,
            "term": self.search
          })          

        if (res.kind === "ok") self.setProcesss(uniq(map(res.data, 'name')))
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }), 
  }))

type ProcessStoreType = Instance<typeof ProcessStoreModel>
export interface ProcessStore extends ProcessStoreType {}
type ProcessStoreSnapshotType = SnapshotOut<typeof ProcessStoreModel>
export interface ProcessStoreSnapshot extends ProcessStoreSnapshotType {}
