/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-30 13:04:33
 * @modify date 2022-01-30 13:04:33
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors, verticalCentered } from 'assets'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      ...verticalCentered,
      flexDirection: 'column',
      justifyContent: 'space-between',
      border: 'none',
      borderRadius: 0,
      height: '100%',
      [theme.breakpoints.up('md')]: {
        border: `1px solid ${colors.border.primary}`,
        borderRadius: 5
      }
    },
    imageWrapper: {
      display: 'inherit',
      flex: 1,
      width: '80%',
      padding: theme.spacing(2),
      paddingTop: theme.spacing(4),
    },
    image: {
      maxHeight: '100%',
      maxWidth: '100%',
      objectFit: 'contain'
    }
  })
)