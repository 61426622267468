/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-17 22:29:31
 * @modify date 2022-04-17 22:29:31
 */

import { types } from "mobx-state-tree"
import { SampleScoreModel, preProcessorSampleScore } from 'models/sample-score'
import { GreenGradingReportModel, preProcessorGreenGrading } from "models/green-grading"

export const sampleReportProps = {
  id: types.optional(types.string, ''),
  title: types.optional(types.string, ''),
  emails: types.optional(types.string, ''),
  message: types.optional(types.string, ''),
  isCuppingOwner: types.optional(types.boolean, false),
  cuppingProtocol: types.optional(types.string, ''),
  cuppingDate: types.optional(types.string, ''),
  averageScoresForGraph: types.optional(types.string, '{}'),
  cloudWords: types.optional(types.string, '{}'),
  flavorWheelGroups: types.optional(types.string, '{}'),
  flavorWheelOnlyFragrance: types.optional(types.string, '{}'),
  flavorWheelOnlyFlavor: types.optional(types.string, '{}'),
  sampleRecord: types.optional(types.string, '{}'),
  emoticons: types.optional(types.array(types.number), []),
  interestStatus: types.optional(types.array(types.model({
    label: types.optional(types.string, ''),
      qty: types.optional(types.number, 0),
    })), []),
  scores: types.optional(types.array(
    types.snapshotProcessor(SampleScoreModel, {
      preProcessor: (sn: any) => preProcessorSampleScore(sn)
  })), []),
  greenGradings: types.optional(types.array(
    types.snapshotProcessor(GreenGradingReportModel, {
      preProcessor: (sn: any) => preProcessorGreenGrading(sn)
  })), []),
}