/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-04 23:02:18
 * @modify date 2022-02-04 23:02:18
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Box, Typography, Divider } from '@mui/material'

import { members, admins, pendingInvites, seatAvailable } from 'assets/images'

import { InfoProps } from './card-info.props'
import { useStyles } from './card-info.styles'

export const CardInfo: React.FC<InfoProps> = (props: InfoProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { title, counter, rightBorder } = props

  const image = () => {
    switch (title) {
      case 'members':
        return members
      case 'admins':
        return admins
      case 'pendingInvites':
        return pendingInvites
      default:
        return seatAvailable
    }
  }

  return (
    <Box className={classes.wrapper}>
      <Card variant="outlined" className={classes.cardWrapper}>
        <Box sx={{textAlign: 'right', mr: {md: 2}}}>
          <img alt={title} src={image()} className={classes.image} />
        </Box>
        <Box>
          <Typography variant='h3' color='primary'>{counter}</Typography>
          <Typography variant='body2'>{t(`cupper.${title}`)}</Typography>
        </Box>
      </Card>
      {rightBorder && <Divider orientation="vertical" flexItem sx={{my: 4, display: {md: 'none'}}} />}
    </Box>
  )
}