/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-22 02:09:49
 * @modify date 2022-04-22 02:09:49
 */

import { flow, applySnapshot, getSnapshot } from "mobx-state-tree"
import { ApiResult } from "services/api"

export const withSubscriptionActions = (self: any) => ({
  actions: {
    setSubscription(subscriptions) {
      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        subscriptions: subscriptions
      })
    },
    setHistories(histories) {
      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        paymentHistories: histories
      })
    },
    getSubscriptions: flow(function * () {
      try {
        const id = self.rootStore.userStore.subscriptionId
        const res: ApiResult = yield self.environment.subscriptionApi.find(id)

        if (res.kind === "ok") self.setSubscription(res.data)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    updateSubscription: flow(function * (planId, isActivate = false) {
      try {
        const { setUser, setCompanyStore, setPaymentHistories, setMessage } = self.rootStore.userStore
        const payload: any = { planId }
        const additionalPath = isActivate ? 'activate' : 'change'
        const res: ApiResult = yield self.environment.subscriptionApi.save(payload, {}, additionalPath)

        if (res.kind === "ok") {
          setUser(res.data)
          setCompanyStore(res.data.company)
          setPaymentHistories(res.data.paymentHistories)
          setMessage('subscription.success.successfullyChangedPlan')
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    cancelSubscription: flow(function * () {
      try {
        const additionalPath = 'cancel'
        const res: ApiResult = yield self.environment.subscriptionApi.save({}, {}, additionalPath)

        if (res.kind === "ok") self.rootStore.resetStore()
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    saveCard: flow(function * (payload) {
      try {
        const {
          userStore: {setUser, setCompanyStore, setPaymentHistories, setMessage },
          companyStore: { subscriptionId }
        } = self.rootStore
        const additionalPath = subscriptionId || payload.subscriptionCustomerId ? 'card' : 'activate'
        const res: ApiResult = yield self.environment.subscriptionApi.save(payload, {}, additionalPath)

        if (res.kind === "ok") {
          setMessage('subscription.success.successfullySubbittedCard')
          if (subscriptionId) return

          setUser(res.data)
          setCompanyStore(res.data.company)
          setPaymentHistories(res.data.paymentHistories)
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
  }
})
