/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-06 21:23:13
 * @modify date 2022-02-06 21:23:13
 */
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { colors, verticalCentered } from 'assets'
  
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      ...verticalCentered,
      padding: theme.spacing(1.5),
      height: '100%'
    },
    avatar: {
      marginRight: theme.spacing(1),
      backgroundColor: colors.primary.o25
    },
    icon: {
      width: 20,
      fill: colors.primary.main,
    }
  })
)