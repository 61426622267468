/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-23 21:46:28
 * @modify date 2022-03-23 21:46:28
 */

import {sumBy, toNumber} from 'lodash'

export const withGreenGradingViews = (self: any) => ({
  views: {
    get totalDefects() {
      return JSON.parse(self.total)
    },
    get totalPrimary() {
      return sumBy(self.defects, (defect: any) => {
        if (defect.type === 'primary') return defect.total

        return 0
      })
    },
    get totalSecondary() {
      return sumBy(self.defects, (defect: any) => {
        if (defect.type === 'secondary') return defect.total

        return 0
      })
    },
    get greenGradingInformation() {
      return [
        {key: 'color', label: 'greenGrading.color', score: self.color},
        {key: 'weight', label: 'greenGrading.weight', score: toNumber(self.weight)},
        {key: 'smell', label: 'greenGrading.smell', description: self.smell}
      ]
    },
  }
})
