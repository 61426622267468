import React from 'react'
import { useTranslation } from "react-i18next";
import {Container, Typography} from "@mui/material";

import { emptySearch } from "assets/images";

export const EmptySamples = () => {
  const { t } = useTranslation()

  return (
    <Container sx={{textAlign: 'center'}}>
      <Typography variant="h3" sx={{mb: 4}}>
        {t('sample.noResultFound')}
      </Typography>
      <img src={emptySearch} alt="Not found" />
    </Container>
  )
}
