import React from 'react'
import { Box, Typography, Avatar } from '@mui/material'

import { AvatarProfileProps } from './avatar-profile.props'
import { useStyles } from './avatar-profile.styles'
const randomColor = require('randomcolor')

export const AvatarProfile: React.FC<AvatarProfileProps> = (props: AvatarProfileProps) => {
  const classes = useStyles()
  const { name, email, company, image, rtl, title, isReport, active, onClick } = props
  const [color] = React.useState(randomColor())
  
  const displayImage = () => {
    if (image) return <Avatar alt={name} src={image} />

    const initials = name?.split(" ").map((n)=>n[0]).join("").toUpperCase()
    return <Avatar sx={{ bgcolor: color, mx: isReport ? 0 : 2 }}>{initials}</Avatar>
  }

  const boxStyle = rtl ? 'rtl' : 'wrapper'

  return (
    <Box className={classes[boxStyle]} onClick={onClick}>
      {displayImage()}
      {isReport ? 
        <Box sx={{mx: 1}} className={classes.reportText}>
          <Typography className={classes.company} variant='caption'>{title}</Typography>
          <Typography variant='body2' color={active ? 'primary' : 'text.disabled'}>{name}</Typography>
        </Box> :
        <Box sx={{mx: 1}} className={classes.text}>
          <Typography variant='body2'>{name}</Typography>
          {email && <Typography variant='body3'>{email}</Typography>}
          {company && <Typography className={classes.company} variant='body3'>{company}</Typography>}
        </Box>
      }
    </Box>
  )
}