/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-22 02:22:28
 * @modify date 2022-04-22 02:22:28
 * @desc [description]
 */

import { types } from "mobx-state-tree"

export const userProps = {
  id: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  email: types.optional(types.string, ""),
  password: types.optional(types.string, ""),
  passwordConfirmation: types.optional(types.string, ""),
  token: types.optional(types.string, ""),
  couponCode: types.optional(types.string, ""),
  termsOfService: types.optional(types.boolean, false),
  isAuthenticated: types.optional(types.boolean, false),
  name: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  timeZone: types.maybeNull(types.string),
  avatar: types.maybeNull(types.union(types.string, types.number)),
  apiToken: types.maybeNull(types.string),
  languagePreference: types.maybeNull(types.optional(types.string, "en")),
  active: types.optional(types.boolean, false),
  systemAdmin: types.optional(types.boolean, false),
  adminPrivileges: types.optional(types.boolean, false),
  isCompanyAdmin: types.optional(types.boolean, false),
  canCreateCuppingSessions: types.optional(types.boolean, false),
  registrationCompleted: types.optional(types.boolean, false),
  sawNavigationTutorial: types.optional(types.boolean, false),
  sawCuppingTutorial: types.optional(types.boolean, false),
  sawCuppersTutorial: types.optional(types.boolean, false),
  sawVideoTutorial: types.optional(types.boolean, false),
  captchaToken: types.maybeNull(types.string),
  fromActivation: types.optional(types.boolean, false),
  role: types.maybeNull(types.string),
  isQgrader: types.maybeNull(types.boolean),
  isJoiningAbTest: types.maybeNull(types.boolean),
  isProducer: types.maybeNull(types.boolean),
  canApproveSample: types.optional(types.boolean, false),
}
