/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-03 20:13:36
 * @modify date 2022-06-28 13:26:48
 */

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { isEmpty, assign, has } from 'lodash'
import { TabPanel, LoadingButton } from '@mui/lab'
import { Grid, OutlinedInput, InputAdornment, IconButton, FormHelperText } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { useStores } from 'models'
import { FormInput } from 'components'
import { globalAction, pointerMessages, validatePassword } from 'utils'

export const Password: React.FC = observer(() => {
  const { t } = useTranslation()
  const { 
    userStore: { password, passwordConfirmation, updatePassword, setValue },
    modalStore: { setValue: setModalValue },
    notificationStore
  } = useStores()

  const [currentPassword, setCurrentPassword] = React.useState('')
  const [showPassword, setShowPassword] = React.useState(false)
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false)
  const [errors, setErrors] = React.useState<any>({})

  const validations = () => {
    let validationErrors = {}

    if (!(password === passwordConfirmation)) {
      assign(validationErrors, { 
        "passwordConfirmation": [{ message: t('profile.error.passwordIsNotMatch') }]
      })
    }

    if (!validatePassword(password)) {
      assign(validationErrors, { 
        "password": [{ message: t('profile.error.passwordIsInvalid') }]
      })
    }
    
    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors)
      return false
    }

    return true
  }

  const onUpdatePassword = () => {
    setErrors({})
    if (!validations()) return
    
    globalAction(notificationStore, {
      action: async () => await updatePassword(currentPassword),
      afterAction: () => {
        setCurrentPassword('')
        setValue('password', '')
        setValue('passwordConfirmation', '')
        setModalValue('setting', false)
      }
    })
  }
  
  const onEnter = async (e) => {
    if (e.key === 'Enter') onUpdatePassword()
  }

  React.useEffect(() => {
    if (showPassword) setValue('passwordConfirmation', password)
  }, [password, showPassword])

  const isPasswordError = has(errors, 'password') && !validatePassword(password)
  const isPasswordConfirmationError = has(errors, 'passwordConfirmation') && password !== passwordConfirmation

  return (
    <TabPanel value="password">
      <Grid container>
        <Grid item xs={12}>
          <FormInput label={t('profile.currentPassword')}>
            <OutlinedInput 
              size='small'
              type={showCurrentPassword ? 'text' : 'password'}
              placeholder={t('profile.enterCurrentPasswordHere')}
              value={currentPassword} 
              onChange={e => setCurrentPassword(e.target.value)}
              onKeyPress={onEnter}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowCurrentPassword(!showCurrentPassword)} >
                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormInput>
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label={t('profile.newPassword')}
            formControlProps={{ 
              variant: 'standard',
              error: isPasswordError
            }}
          >
            <OutlinedInput 
              size='small'
              type={showPassword ? 'text' : 'password'}
              placeholder={t('profile.enterNewPasswordHere')}
              value={password} 
              onChange={e => setValue('password', e.target.value)}
              onKeyPress={onEnter}
              error={isPasswordError}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error={isPasswordError}>
              {pointerMessages('password', errors) || t('profile.error.passwordIsInvalid')}
            </FormHelperText>
          </FormInput>
        </Grid>
        {!showPassword &&
          <Grid item xs={12}>
            <FormInput label={t('profile.confirmNewPassword')}>
              <OutlinedInput 
                size='small'
                type={showPassword ? 'text' : 'password'}
                placeholder={t('profile.enterConfirmNewPasswordHere')}
                value={passwordConfirmation} 
                onChange={e => setValue('passwordConfirmation', e.target.value)}
                onKeyPress={onEnter}
                error={isPasswordConfirmationError}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {isPasswordConfirmationError && 
                <FormHelperText error={true}>
                  {pointerMessages('passwordConfirmation', errors)}
                </FormHelperText>
              }
            </FormInput>
          </Grid>
        }
      </Grid>

      <LoadingButton 
        fullWidth
        size='large'
        variant="contained"
        disabled={!currentPassword || !password || !passwordConfirmation}
        onClick={onUpdatePassword}
        sx={{mt: 3}}
      >
        {t('profile.saveChanges')}
      </LoadingButton>
    </TabPanel>
  )
})