/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-28 20:22:31
 * @modify date 2022-02-28 20:22:31
 */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Card, Grid, TextField, Typography } from '@mui/material'

import { useStores } from 'models'
import { acidityDescriptorList } from 'constants/acidity-descriptors'
import { aftertasteDescriptorList } from 'constants/aftertaste-descriptors'
import { balanceDescriptorList } from 'constants/balance-descriptors'
import { bodyDescriptorList } from 'constants/body-descriptors'
import { flavorDescriptorList } from 'constants/flavor-descriptors'
import { defectDescriptorList } from 'constants/defect-descriptors'
import { Descriptor } from 'components'

import { Cup, Defect } from '../index'

export const Robusta: React.FC = observer(() => {
  const { t } = useTranslation()
  const { sampleStore: {
    selectedSample: { sampleId, selectedScore: {
      fragrance, fragranceDescriptors, fragranceDry, fragranceBreak, 
      salt, saltDescriptors, saltLowSalt, saltHiAcid, 
      bittersweet, bittersweetLowBitter, bittersweetHiSweet, 
      flavor, flavorDescriptors,
      aftertaste, aftertasteDescriptors,
      balance, balanceDescriptors,
      mouthfeel, mouthfeelDescriptors, mouthfeelRoughToSmooth,
      overall, defects, defectsDescriptors,
      notes, cups, intensity,
      uniformity, totalUniformity, cleanCup, totalCleanCup, 
      addingScore, decreaseScore, onClickCup,
      setScoreValue
    }}
  } } = useStores()

  const fragranceSlides = [
    {
      label: t('descriptor.dry'),
      value: fragranceDry,
      onChange: (e) => setScoreValue('fragranceDry', e.target.value)
    },
    {
      label: t('descriptor.break'),
      value: fragranceBreak,
      onChange: (e) => setScoreValue('fragranceBreak', e.target.value)
    }
  ]

  const saltAcidSlides = [
    {
      label: t('descriptor.lowSalt'),
      value: saltLowSalt,
      onChange: (e) => setScoreValue('saltLowSalt', e.target.value)
    },
    {
      label: t('descriptor.hiAcid'),
      value: saltHiAcid,
      onChange: (e) => setScoreValue('saltHiAcid', e.target.value)
    }
  ]

  const bitterSweetSlides = [
    {
      label: t('descriptor.lowBitter'),
      value: bittersweetLowBitter,
      onChange: (e) => setScoreValue('bittersweetLowBitter', e.target.value)
    },
    {
      label: t('descriptor.hiSweet'),
      value: bittersweetHiSweet,
      onChange: (e) => setScoreValue('bittersweetHiSweet', e.target.value)
    }
  ]

  const mouthfeelSlides = [
    {
      label: t('descriptor.roughToSmooth'),
      value: mouthfeelRoughToSmooth,
      onChange: (e) => setScoreValue('mouthfeelRoughToSmooth', e.target.value)
    }
  ]
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`fragranceOrAroma${fragranceDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.fragranceOrAroma')}
          descriptors={fragranceDescriptors}
          descriptorList={flavorDescriptorList}
          addDescriptor={(values) => setScoreValue('fragranceDescriptors', values)}
          slides={fragranceSlides}
          score={fragrance}
          addScore={() => addingScore(fragrance, 'fragrance')}
          decreaseScore={() => decreaseScore(fragrance, 'fragrance')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`saltOrAcid${saltDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.saltOrAcid')}
          descriptors={saltDescriptors}
          descriptorList={acidityDescriptorList}
          addDescriptor={(values) => setScoreValue('saltDescriptors', values)}
          slides={saltAcidSlides}
          score={salt}
          addScore={() => addingScore(salt, 'salt')}
          decreaseScore={() => decreaseScore(salt, 'salt')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`bitterOrSweet`}
          sampleId={sampleId}
          title={t('descriptor.bitterOrSweet')}
          isEmptyDescriptor={true}
          slides={bitterSweetSlides}
          score={bittersweet}
          addScore={() => addingScore(bittersweet, 'bittersweet')}
          decreaseScore={() => decreaseScore(bittersweet, 'bittersweet')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`flavor${flavorDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.flavor')}
          descriptors={flavorDescriptors}
          descriptorList={flavorDescriptorList}
          addDescriptor={(values) => setScoreValue('flavorDescriptors', values)}
          score={flavor}
          addScore={() => addingScore(flavor, 'flavor')}
          decreaseScore={() => decreaseScore(flavor, 'flavor')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`aftertaste${aftertasteDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.aftertaste')}
          descriptors={aftertasteDescriptors}
          descriptorList={aftertasteDescriptorList}
          addDescriptor={(values) => setScoreValue('aftertasteDescriptors', values)}
          score={aftertaste}
          addScore={() => addingScore(aftertaste, 'aftertaste')}
          decreaseScore={() => decreaseScore(aftertaste, 'aftertaste')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`mouthfeel${mouthfeelDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.mouthfeel')}
          descriptors={mouthfeelDescriptors}
          descriptorList={bodyDescriptorList}
          addDescriptor={(values) => setScoreValue('mouthfeelDescriptors', values)}
          slides={mouthfeelSlides}
          score={mouthfeel}
          addScore={() => addingScore(mouthfeel, 'mouthfeel')}
          decreaseScore={() => decreaseScore(mouthfeel, 'mouthfeel')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Descriptor
          key={`balance${balanceDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.balance')}
          descriptors={balanceDescriptors}
          descriptorList={balanceDescriptorList}
          addDescriptor={(values) => setScoreValue('balanceDescriptors', values)}
          score={balance}
          addScore={() => addingScore(balance, 'balance')}
          decreaseScore={() => decreaseScore(balance, 'balance')}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Cup
              key='uniformity'
              label={t('descriptor.uniformity')}
              cups={uniformity}
              score={totalUniformity}
              onClick={(index) => onClickCup(index, uniformity, 'uniformity')}
            />
          </Grid>
          <Grid item xs={12}>
            <Cup
              key='cleanCup'
              label={t('descriptor.cleanCup')}
              cups={cleanCup}
              score={totalCleanCup}
              onClick={(index) => onClickCup(index, cleanCup, 'cleanCup')}
            />
          </Grid>
          <Grid item xs={12}>
            <Descriptor
              isRow
              key='overall'
              sampleId={sampleId}
              title={t('descriptor.overall')}
              score={overall}
              addScore={() => addingScore(overall, 'overall')}
              decreaseScore={() => decreaseScore(overall, 'overall')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Defect
          key={`defects${defectsDescriptors}`}
          sampleId={sampleId}
          title={t('descriptor.defects')}
          cups={cups}
          intensity={intensity}
          setCup={(value) => setScoreValue('cups', value)}
          setIntensity={(value) => setScoreValue('intensity', value)}
          score={defects}
          onChange={(value) => setScoreValue('defects', value)}
          descriptors={defectsDescriptors}
          descriptorList={defectDescriptorList}
          addDescriptor={(values) => setScoreValue('defectsDescriptors', values)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Card sx={{px: 4, py: 3}}>
          <Typography variant='h6' sx={{mb: 2}}>{t('descriptor.notes')}</Typography>
          <TextField
            fullWidth
            multiline
            minRows={8}
            value={notes}
            onChange={(e) => setScoreValue('notes', e.target.value)}
          />
        </Card>
      </Grid>
    </Grid>
  )
})