/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-04 06:18:17
 * @modify date 2022-04-04 06:18:17
 */

import React from 'react'
import { Box, Typography } from '@mui/material'

import { LanguageProps } from './languange.props'

export const En: React.FC<LanguageProps> = (props: LanguageProps) => {  
  const { openMail, openPrivacy } = props
  
  return (
    <Box>
      <Typography variant='h5' sx={{mb: 2, mt: {md: '-25px'}}}>
        TERMS OF USE FOR THE TASTIFY PROGRAM
      </Typography>
      <Typography variant='body1' paragraph>
        Thank you for visiting TASTIFY, INC.’s (“Company” or “We” or “Us” or “TASTIFY”) site which provides access to its “Tastify Program,” as identified below (unless indicated otherwise, the term “Site” means, collectively, the Tastify Program and all other aspects of the site accessed at tastify.com).
      </Typography>
      <Typography variant='body1' paragraph>
        We provide these Terms of Use (the “Agreement”) to inform users of our policies with regard to the terms and conditions governing use of all aspects of the Site, including but not limited to the Tastify Program. Use of the Site is conditioned on acceptance, without modification, of this Agreement by You as a user of this Site (“You” or “User”).
      </Typography>
      <Typography variant='body1' paragraph>
        IF YOU CHOOSE TO ACCEPT THIS AGREEMENT BY CHECKING ON THE "I AGREE" BOX, YOU AGREE TO BECOME BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT AS IT IS PRESENTED TO YOU AS OF THE DATE OF SUCH ASSENT (THE “EFFECTIVE DATE”). NO CHANGES (ADDITIONS OR DELETIONS) TO THIS AGREEMENT WILL BE ACCEPTED BY TASTIFY. IF YOU DO NOT AGREE TO ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU WILL NOT HAVE ANY RIGHT TO USE THE TASTIFY PROGRAM OR ANY OTHER ASPECTS OF THIS SITE. YOU SHOULD DOWNLOAD AND PRINT THIS AGREEMENT FOR YOUR RECORDS.
      </Typography>
      <Typography variant='body1' paragraph>
        By visiting this Site, You agree to the following:
      </Typography>

      <ol>
        <li>
          <Typography variant='h6' sx={{mb: 1}}>Definitions</Typography>
          <Typography variant='body2' paragraph>
            In addition to any other defined terms, the following terms have the following meaning: (i) “ Server” means the computer software or hardware that serves and hosts the Site to Users; (ii) “Tastify Program” means TASTIFY’s proprietary program which assists users in analyzing taste profiles for, and other flavors and components of, coffee and other beverages; and (iii) “User(s)” means You and any other end users gaining access to the Site through You.
          </Typography>
        </li>

        <li>
          <Typography variant='h6' sx={{mb: 1}}>Access: Conditions to Use of the Site</Typography>
          <ol>
            <li>
              <Typography variant='body2' paragraph>
                Subject to all of the terms and conditions of this Agreement, TASTIFY hereby grants to User a non-exclusive, revocable, and limited right to access and use the Tastify Program and other aspects of the Site in strict compliance with this Agreement (“Access Right”). TASTIFY reserves the right to suspend or revoke this Access Right at our discretion without notice. Notwithstanding the foregoing Access Right, TASTIFY also has the right to change, suspend, or discontinue any (or all) aspects or features of the Tastify Program or this Site at any time, and from time to time, including the availability of any content or features on the Tastify Program or this Site. This Access Right granted to User under this Agreement will immediately terminate upon the expiration, cancellation or termination of this Agreement for any reason.
            </Typography>
            </li>
            <li>
              <Typography variant='body2' paragraph>
                User shall be responsible for obtaining and maintaining any equipment or ancillary services needed to connect to or access the Site, including, without limitation, Internet connections, telecommunication services, hardware, or software.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' paragraph>
                As a condition of User’s use of the Site, User covenants to TASTIFY that User will not use the Site for any unlawful purpose or for any purpose that is prohibited by this Agreement. User may not use the Site in any manner that could damage, disable, overburden, or impair the Tastify Program, any other aspects of the Site or any Servers, or interfere with any other party's use and enjoyment of the Tastify Program or any other aspects of the Site. User may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' paragraph>
                Notwithstanding any assistance that TASTIFY may provide, the User assumes sole responsibility for uploading and updating any of the User’s Content (as defined herein) through the Site.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' paragraph>
                Without limiting the generality of the foregoing, User agrees to all of the following provisions: (a) User will not upload to, distribute or otherwise publish through the Site any data, information, messages, text, works, material or any other content, including, without limitation, any personal identifiable information related to the User (collectively, "Content") that is unlawful, libelous, defamatory, invasive of privacy or publicity rights, harassing, threatening, abusive, inflammatory, or otherwise objectionable; (b) User will not upload or transmit any Content that would violate the rights of any party, or would otherwise create liability or violate any local, state, federal or international law; (c) User will not upload or transmit any Content that may infringe any patent, trademark, trade secret, copyright, or other intellectual or proprietary right of any party anywhere; (d) User will not impersonate any person or entity or otherwise misrepresent the User’s affiliation with a person or entity; and (e) User will not use the Site for purposes not authorized by TASTIFY .
              </Typography>
            </li>
            <li>
              <Typography variant='body2' paragraph>
                Without limiting the generality of the foregoing, User agrees to all of the following provisions: (a) User is prohibited from violating or attempting to violate the security of the Site or any Server, including, without limitation: (i) accessing data not intended for such User or logging into a server or account which the User is not authorized to access; (ii) attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization; or (iii) attempting to interfere with service to any other party, host, or network; (b) any violation of any system or network security (including, but not limited to, that of the Site or any Server) may result in civil or criminal liability; and (c) TASTIFY has the right to investigate occurrences that may involve such violations and may involve, and cooperate with, law enforcement authorities in prosecuting Users who are involved in such violations. TASTIFY also reserves the right to cooperate with any and all law enforcement agencies, including complying with warrants, court orders and subpoenas and disclosing to law enforcement agencies any information about any User and anything a User does with respect to the Site. By User’s use of the Site, User authorizes TASTIFY to take such action.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' paragraph>
                In the event the Site, now or in the future, allows User to upload, transmit, or otherwise contribute any Content to the Site, User hereby represents and warrants to TASTIFY that User has the lawful right to distribute and reproduce such Content. Also, User is solely responsible for its conduct while using the Site, including but not limited to all of its Content. TASTIFY takes no responsibility for any uploading, transmission, contribution or other uses of the User’s Content by User or any other party.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' paragraph>
                In addition to any other right to terminate this Agreement, TASTIFY hereby has the absolute right to immediately terminate, without warning, any account which it believes, in its sole discretion, breaches any of the provisions of this Section 2.
              </Typography>
            </li>
          </ol>
        </li>

        <li>
          <Typography variant='h6' sx={{mb: 1}}>Copyrights and Other Intellectual Property Rights; Reservation of Rights</Typography>
          <ol>
            <li>
              <Typography variant='body2' paragraph>
                TASTIFY’s policy is to respect the copyright and intellectual property rights of others. TASTIFY has the absolute right to: (i) immediately terminate, without warning, all rights (including, without limitation, all Access Rights) of any User who (in TASTIFY’s determination) appear to infringe upon the copyright or intellectual property rights of others, and (ii) remove any Content from the Site that, in TASTIFY’s determination, may infringe the copyright or other intellectual property rights of any third party.
            </Typography>
            </li>
            <li>
              <Typography variant='body2' paragraph>
                This Agreement shall not be interpreted to transfer any rights in any intellectual property from TASTIFY to any User. User agrees that TASTIFY or its licensors solely own all inventions, patents, trademarks/service marks, logos, images, graphics, content, reports, analysis, data, formulae, processes, techniques, software, website designs, all other copyrights, and all other intellectual property provided in, or made available by using, or otherwise contained in, the Tastify Program and all other aspects of the Site and otherwise provided in furtherance of this Agreement (collectively, “TASTIFY IP Assets”)
              </Typography>
            </li>
            <li>
              <Typography variant='body2' paragraph>
                TASTIFY IP Assets may not be used by User without the prior written permission from TASTIFY, and then only with proper acknowledgement. Any rights not expressly granted herein to User are reserved to TASTIFY. In addition to any other conditions on User’s Access Right as set forth in this Agreement, User’s Access Right is subject to the following additional conditions: (i) User shall not modify, disassemble, decompile or reverse translate or create derivative works from any of the TASTIFY IP Assets or otherwise attempt to derive any source code of the same or let any third party do the same; (ii) no copyrighted material, content, or any other TASTIFY IP Assets may be downloaded, modified, copied, displayed, transferred, distributed, sold, published, broadcast or otherwise used except as expressly stated either in such materials or in this notice without the express prior written permission of TASTIFY (which TASTIFY may or may not grant in its sole discretion); (iii) User shall not remove, alter, cover or obscure any copyright notices or other proprietary rights notices of TASTIFY or any other party placed on or embedded in the TASTIFY IP ASSETS and shall otherwise retain all such notices on all copies of the same; and (iv) use of any of the TASTIFY IP Assets is prohibited unless User is an authorized User in good standing. Unauthorized use is a violation of copyright and other intellectual property rights and is actionable under law.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' paragraph>
                User agrees to keep strictly confidential all TASTIFY IP Assets that have not been made publically available by TASTIFY. User also acknowledges and agrees that the terms and conditions of this provision shall survive the cancellation, expiration or termination of this Agreement for any reason.
              </Typography>
            </li>
          </ol>
        </li>

        <li>
          <Typography variant='h6' sx={{mb: 1}}>Privacy</Typography>
          <ol>
            <li>
              <Typography variant='body2' paragraph>
                User agrees that: (i) if the User has any Content or any login or password associated with this Site, then User is solely responsible for maintaining the confidentiality of the same; and (ii) if the User has any login or password associated with this Site, then User (a) is solely responsible for all uses of its login and password regardless of whether these uses are authorized by User, and (b) User will immediately notify TASTIFY of any unauthorized use of the User’s login and password.
            </Typography>
            </li>
            <li>
              <Typography variant='body2' paragraph>
                The {openPrivacy} for the Site is hereby incorporated into this Agreement by this reference and User hereby agrees to comply with the same at all times.
              </Typography>
            </li>
          </ol>
        </li>

        <li>
          <Typography variant='h6' sx={{mb: 1}}>Indemnity</Typography>
          <Typography variant='body2' paragraph>
            User will indemnify and hold TASTIFY, its parents, subsidiaries, affiliates, officers, and employees, harmless, including costs and attorneys' fees, from any claim or demand made by any third party due to or arising out of the User's use access to the Site, use of the Site, the violation or other breach of this Agreement by the User, or the infringement by the User, or any third party using the User's account, of any intellectual property or other right of any person or entity.
          </Typography>
        </li>

        <li>
          <Typography variant='h6' sx={{mb: 1}}>Site Resources</Typography>
          <Typography variant='body2' paragraph>
            The Tastify Program as well as other aspects of the Site may offer or provide a variety of services, features, functionalities, information, data or facts (collectively, “SITE Resources”) for the User’s benefit. While TASTIFY endeavors to provide the most current and accurate Site Resources as possible, the User acknowledges and agrees (i) the Site Resources may be general in nature, and may not apply to particular factual circumstances; (ii) the Site Resources may contain errors and should not be relied upon or act as a substitution for independent investigation by the User. ALL SITE RESOURCES ARE PROVIDED “AS IS” AND SITE RESOURCES AVAILABLE THROUGH THIS SITE MAY BE SUPERSEDED AND/OR MAY INCLUDE INACCURACIES. ALSO, WHERE ANY INFORMATION OR DATA CONTAINED AS A SITE RESOURCE IS OBTAINED FROM ANOTHER SOURCE, THEN THE OTHER SOURCE’S TERMS OF USE MAY APPLY TO YOUR USE OF THE SAME. TASTIFY MAY MAKE IMPROVEMENTS AND/OR CHANGES TO THE TASTIFY PROGRAM, ANY SITE RESOURCES, ANY OTHER ASPECTS OF THE SITE OR ANY SERVERS AT ANY TIME.
          </Typography>
        </li>

        <li>
          <Typography variant='h6' sx={{mb: 1}}>Links to Third Party Sites</Typography>
          <Typography variant='body2' paragraph>
            As a convenience to User, the Site may now, or in the future, provide links to other Internet web sites that are not owned by TASTIFY , and are not under our control (“Third Party Websites”). TASTIFY does not control the Third Party Websites and is not responsible for the Content included in them including, without limitation, any subsequent links contained within a linked web site, or any changes or updates to a linked web site. Any reference from the TASTIFY Site to any entity, product, service or information does not constitute an endorsement or recommendation by TASTIFY. No Third Party Website is authorized to make any representations or warranties on our behalf. Your visit to any Third Party Websites are subject to the terms and conditions of such Third Party Websites, and not this Site’s Agreement. User should refer to each Third Party Website’s specific terms.
          </Typography>
        </li>

        <li>
          <Typography variant='h6' sx={{mb: 1}}>Disclaimer of Warranties; Disclaimer of Liability</Typography>
          <ol>
            <li>
              <Typography variant='body2' paragraph>
                TASTIFY MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OR ACCURACY OF THE TASTIFY PROGRAM OR ANY OTHER SITE RESOURCES CONTAINED IN THIS SITE. THE TASTIFY PROGRAM, ALL SITE RESOURCES AND ALL OTHER ASPECTS OF THE SITE ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND. TASTIFY HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, TASTIFY FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. TASTIFY DOES NOT REPRESENT OR WARRANT THAT THE TASTIFY PROGRAM, SITE RESOURCES OR ANY OTHER ASPECTS OF THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT THE DEFECTS WILL BE CORRECTED, OR THAT THE SERVER MAKING THIS SITE AVAILABLE IS FREE OF COMPUTER VIRUSES OR OTHER HARMFUL COMPONENTS.
            </Typography>
            </li>
            <li>
              <Typography variant='body2' paragraph>
                TASTIFY SHALL NOT BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM INCONVENIENCE, OR LOSS OF USE, RESOURCES OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THE TASTIFY PROGRAM, THE SITE RESOURCES OR ANY OTHER ASPECTS OF THE SITE, OR ANY THIRD PARTY WEBSITES, EVEN IF TASTIFY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES.
              </Typography>
            </li>
          </ol>
        </li>

        <li>
          <Typography variant='h6' sx={{mb: 1}}>Change in these Terms of Use</Typography>
          <Typography variant='body2' paragraph>
            Once User begins to use its Access Rights, TASTIFY may modify this Agreement at any time and User will thereafter be bound by the version of this Agreement that is in effect at the time User uses the Site. Any use of the Tastify Program or any other aspects of the Site by User thereafter shall be deemed to constitute acceptance by User of the amendments.
          </Typography>
        </li>

        <li>
          <Typography variant='h6' sx={{mb: 1}}>Termination</Typography>
          <Typography variant='body2' paragraph>
            In the event User, or anyone else using User’s privileges, violate the terms of this Agreement (as determined in TASTIFY’s discretion), TASTIFY reserves the right to take any action it deems appropriate, including, but not limited to, termination of this Agreement, including all Access Rights (as such term is defined in Section 2.1 above). In addition to any other right of TASTIFY to terminate this Agreement, TASTIFY further reserves the right, without notice, at any time, in its sole discretion, and for any reason, to terminate this Agreement, including all Access Rights. TASTIFY is not required to provide mail or web page forwarding at termination.
          </Typography>
        </li>

        <li>
          <Typography variant='h6' sx={{mb: 1}}>Miscellaneous Provisions</Typography>
          <ol>
            <li>
              <Typography variant='body2' paragraph>
                <strong>Governing Law</strong>. This Agreement shall be construed and controlled by the laws of the State of Oregon. The laws of the State of Oregon will govern any dispute arising from the terms of this Agreement or a breach of this Agreement. User agrees to personal jurisdiction by the state and federal courts sitting in the State of Oregon in Multnomah County.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' paragraph>
                <strong>Notices</strong>. Except as otherwise expressly provided in this Agreement, any communications between the parties, or notices to be given hereunder, will be given in writing by personal delivery, express courier, facsimile, or United States Postal Service, postage prepaid, to either User (at the last email address or other address it provided to TASTIFY), or to SUSTAINABLE HARVES (to the email address set forth below), or to such other addresses or numbers as either Party may hereafter indicate pursuant to this Section. Unless otherwise provided in this Agreement: (i) any communication or notice addressed and sent by U.S. Mail will be deemed to be given five (5) days after mailing via US Mail; (ii) any communication or notice delivered by facsimile will be deemed to be given when the transmitting machine generates a receipt of a successful transmission of the notice; or (iii) any communication or notice delivered by email will be deemed to be given when the email has been generated and sent by the sender.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' paragraph>
                <strong>Remedies</strong>. User acknowledges that monetary damages may not be a sufficient remedy for unauthorized use of the Tastify Program or any other aspects of the Site, and therefore User agrees that TASTIFY is entitled, without waiving any other rights or remedies, to such injunctive or equitable relief as may be deemed proper by a court or arbitration panel of competent jurisdiction without necessity of posting a bond and without having to plead and prove lack of an adequate remedy at law.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' paragraph>
                <strong>Attorney Fees</strong>. If any suit or action is filed by any party to enforce this Agreement or otherwise with respect to the subject matter of this Agreement, the prevailing party shall be entitled to recover reasonable attorney fees incurred in preparation or in prosecution or defense of such suit or action as fixed by the trial court and, if any appeal is taken from the decision of the trial court, reasonable attorney fees as fixed by the appellate court.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' paragraph>
                <strong>Binding Effect; No Assignment by User; Permissible Assignment by TASTIFY</strong>. This Agreement shall be binding upon and inure to the benefit of each party’s respective successors and lawful assigns; provided, however, that User may not assign this Agreement, in whole or in part. Any purported assignment in violation of this Section shall be void. TASTIFY shall have the right to assign this Agreement, or any part of it, in its sole discretion to any party, and all covenants and agreements hereunder shall inure to the benefit of and be enforceable by such successors and assigns.
              </Typography>
            </li>
            <li>
              <Typography variant='body2' paragraph>
                <strong>Entire Agreement; Modification; Waiver</strong>. This Agreement, including the Privacy Statement and any other exhibit referenced herein, constitutes the entire agreement between the parties with respect to the subject matter hereof. It shall not be modified except by a written agreement dated subsequent to the date of this Agreement and signed by both parties. None of the provisions of this Agreement shall be deemed to have been waived by any act or acquiescence on the part of TASTIFY, User, their agents, or employees, but only by an instrument in writing signed by an authorized employee of TASTIFY and User. No waiver of any provision of this Agreement shall constitute a waiver of any other provision(s) or of the same provision on another occasion. If any part of this Agreement is determined to be invalid or unenforceable pursuant to applicable law, then the remainder of the Agreement shall continue in effect.
              </Typography>
            </li>
          </ol>
        </li>
      </ol>

      <Typography variant='body1' paragraph>
        If You believe that the Company has not complied with the policies outlined in this Privacy Statement, or if You have questions relating to privacy, then You should write to Us at {openMail}.
      </Typography>
    </Box>
  )
}