/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-15 20:30:46
 * @modify date 2022-02-15 20:30:46
 */

import React from 'react'
import clsx from 'clsx'
import { find, upperCase, takeRight } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import {
  Container, Grid, Paper, Typography, Box, Button,
  Stepper, Step, StepLabel, Card, FormControlLabel, Checkbox,
  Switch, Stack
} from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import { Elements } from '@stripe/react-stripe-js'

import { useStores } from 'models'
import { findRoute } from 'constants/routes'
import {HeaderTitle, StripePayment, ModalConfirm, DormantModal} from 'components'
import { personalPlan, professionalPlan, proguestPlan } from 'assets/images'
import { formatMoney, globalAction } from 'utils'
import {
  INDIVIDUAL_PLAN_ID,
  ENTERPRISE_PLAN_ID,
  ENTERPRISE_PLUS_PLAN_ID,
  INDIVIDUAL_ANNUALLY_PLAN_ID,
  ENTERPRISE_ANNUALLY_PLAN_ID,
  ENTERPRISE_PLUS_ANNUALLY_PLAN_ID,
  STRIPE_PROMISE
} from 'config/env'

import { useStyles } from './plan.styles'

export const Plan: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const homeLink = findRoute('home')
  const {
    companyStore: {
      subscriptionPlanId, licensesNumber, isTrialExpired, isTrial, unpaidSubscription,
      isCustomer, initialPlanIdSelection, updatePlan, setValue: setCompanyValue
    },
    cupperStore: { members },
    subscriptionStore: { subscriptions, getSubscriptions, updateSubscription },
    notificationStore
  } = useStores()

  const individual = find(subscriptions, ['id', INDIVIDUAL_PLAN_ID])
  const enterprise = find(subscriptions, ['id', ENTERPRISE_PLAN_ID])
  const enterprisePlus = find(subscriptions, ['id', ENTERPRISE_PLUS_PLAN_ID])
  const individualAnnually = find(subscriptions, ['id', INDIVIDUAL_ANNUALLY_PLAN_ID])
  const enterpriseAnnually = find(subscriptions, ['id', ENTERPRISE_ANNUALLY_PLAN_ID])
  const enterprisePlusAnnually = find(subscriptions, ['id', ENTERPRISE_PLUS_ANNUALLY_PLAN_ID])
  const disableIndividual = (members > 1 || licensesNumber > 1) && !isTrial
  const planId = subscriptionPlanId || initialPlanIdSelection

  const [step, setStep] = React.useState(0)
  const [usePreviousCard, setUsePreviousCard] = React.useState(false)
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [openDormantModal, setOpenDormantModal] = React.useState(unpaidSubscription)
  const [openDormant, setOpenDormant] = React.useState(false)
  const [annually, setAnnually] = React.useState(false)

  const steps = [
    t('subscription.changeYourPlan'),
    t('subscription.paymentDetails')
  ]

  const individualFeatures = [
    'oneLicense',
    'unlimitedCuppingSession',
    'advancedDatabaseSearch',
    'reportGeneration',
    'accessToApi',
    'tastifyWheelGeneration',
    'forumAccess'
  ]

  const enterpriseFeatures = [
    'multipleLicense',
    ...takeRight(individualFeatures, 6),
    'teamFunctionality',
    'prioritySupport'
  ]

  const enterprisePlusFeatures = [
    ...enterpriseFeatures,
    'guestCuppers',
    'personalOnboarding'
  ]

  const selectPlan = (planId: string) => {
    if (!planId) return

    if (isCustomer) {
      globalAction(notificationStore, {
        action: async() => await updateSubscription(planId, unpaidSubscription),
        afterAction: () => setStep(1)
      })
      return
    }

    setCompanyValue('initialPlanIdSelection', planId)
    globalAction(notificationStore, {
      action: async() => await updatePlan(),
      afterAction: () => setStep(1)
    })
  }

  const loadSubscriptions = () => globalAction(notificationStore, { action: getSubscriptions })

  const selectPlanStep = (
    <Grid container spacing={3} rowSpacing={20} sx={{alignItems: 'center'}}>
      <Grid item xs={12} md={4}>
        <Paper className={classes.planWrapper}>
          <img alt={t('subscription.individual')} src={personalPlan} className={classes.image} />
          {(annually ? individualAnnually?.id === planId : individual?.id === planId) &&
            <Box className={classes.ribbon}>
              <Typography variant='h6' color='white'>
                { t('subscription.currentPlan') }
              </Typography>
            </Box>
          }
          <Typography variant='h5' sx={{mb: 1, mt: 12}}>{t('subscription.individual')}</Typography>

          <Box className={classes.priceWrapper}>
            <Typography color='primary'>{upperCase(annually ? individualAnnually?.currency : individual?.currency)}</Typography>
            <Typography color='primary' variant='h2'>
              {annually ? (individualAnnually && formatMoney(individualAnnually.price/12, individualAnnually.currency)) : (individual && formatMoney(individual.price, individual.currency))}
            </Typography>
            <Typography sx={{mt: 'auto'}}>{t('subscription.userMonth')}</Typography>
          </Box>
          {annually &&
              <Typography variant="body2" color="text.disabled" mb={2.5}>
                {upperCase(individualAnnually?.currency)}&nbsp;
                {individualAnnually && formatMoney(individualAnnually.price, individualAnnually.currency)}&nbsp;
                {t('subscription.userYear')}
              </Typography>
          }
          <Typography variant='subtitle2' sx={{fontWeight: 400, mb: 3}}>
            {t(`subscription.${annually ? 'billedAnnually' : 'billedMonthly'}`)}
          </Typography>

          <Button
            size='large'
            variant='outlined'
            sx={{mb: 3}}
            disabled={disableIndividual || ( annually ? individualAnnually?.id === planId : individual?.id === planId)}
            onClick={isTrial ? () => setOpenConfirm(true) : () => selectPlan(annually ? individualAnnually?.id : individual?.id)}
          >
            {t('subscription.selectPlan')}
          </Button>
          {disableIndividual && (individual?.id !== planId|| individualAnnually?.id === planId) &&
            <Typography variant='caption' color='error' paragraph sx={{mt: -2, mb: 2}}>
              {t('subscription.error.selectPlanIndividual')}
            </Typography>
          }

          {individualFeatures.map((feature, index) =>
            <Box key={index} className={classes.feature}>
              <CheckCircle color='warning' sx={{mr: 1}} />
              <Typography align='left' variant='body2'>
                {t(`subscription.${feature}`)}
              </Typography>
            </Box>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper className={clsx(classes.planWrapper, 'enterprise')}>
          <img alt={t('subscription.enterprise')} src={professionalPlan} className={classes.image} />
          <Box className={classes.ribbon}>
            <Typography variant='h6' color='white'>
              {annually ? (enterpriseAnnually?.id === planId ? t('subscription.currentPlan') : t('subscription.mostPopular')) : (enterprise?.id === planId ? t('subscription.currentPlan') : t('subscription.mostPopular'))}
            </Typography>
          </Box>
          <Typography variant='h5' sx={{mb: 1, mt: 12}}>{t('subscription.enterprise')}</Typography>

          <Box className={classes.priceWrapper}>
            <Typography color='primary'>{upperCase(annually ? enterpriseAnnually?.currency : enterprise?.currency)}</Typography>
            <Typography color='primary' variant='h2'>
              {annually ? (enterpriseAnnually && formatMoney(enterpriseAnnually.price/12, enterpriseAnnually.currency)) : (enterprise && formatMoney(enterprise.price, enterprise.currency))}
            </Typography>
            <Typography sx={{mt: 'auto'}}>{t('subscription.userMonth')}</Typography>
          </Box>
          {annually &&
              <Typography variant="body2" color="text.disabled" mb={2.5}>
                {upperCase(enterpriseAnnually?.currency)}&nbsp;
                {enterpriseAnnually && formatMoney(enterpriseAnnually.price, enterpriseAnnually.currency)}&nbsp;
                {t('subscription.userYear')}
              </Typography>
          }
          <Typography variant='body3' component='p' sx={{mb: 3}}>
            {t(`subscription.${annually ? 'billedAnnually' : 'billedMonthly'}`)}
          </Typography>

          <Button
            size='large'
            variant='contained'
            sx={{mb: 3, px: 3, py: 1.5}}
            onClick={() => selectPlan(annually ? enterpriseAnnually?.id : enterprise?.id)}
            disabled={(annually ? enterpriseAnnually?.id === planId : enterprise?.id === planId) && !isTrial && !unpaidSubscription}
          >
            {t('subscription.selectPlan')}
          </Button>

          {enterpriseFeatures.map((feature, index) =>
            <Box key={index} className={classes.feature}>
              <CheckCircle color='warning' sx={{mr: 1}} />
              <Typography align='left' variant='body2'>
                {t(`subscription.${feature}`)}
              </Typography>
            </Box>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper className={classes.planWrapper}>
          <img alt={t('subscription.enterprisePlus')} src={proguestPlan} className={classes.image} />
          {(annually ? enterprisePlusAnnually?.id === planId : enterprisePlus?.id === planId) &&
            <Box className={classes.ribbon}>
              <Typography variant='h6' color='white'>
                { t('subscription.currentPlan') }
              </Typography>
            </Box>
          }
          <Typography variant='h5' sx={{mb: 1, mt: 12}}>{t('subscription.enterprisePlus')}</Typography>

          <Box className={classes.priceWrapper}>
            <Typography color='primary'>{upperCase(annually ? enterprisePlusAnnually?.currency : enterprisePlus?.currency)}</Typography>
            <Typography color='primary' variant='h2'>
              {annually ? (enterprisePlusAnnually && formatMoney(enterprisePlusAnnually.price/12, enterprisePlusAnnually.currency)) : (enterprisePlus && formatMoney(enterprisePlus.price, enterprisePlus.currency))}
              </Typography>
            <Typography sx={{mt: 'auto'}}>{t('subscription.userMonth')}</Typography>
          </Box>
          {annually &&
              <Typography variant="body2" color="text.disabled" mb={2.5}>
                {upperCase(enterprisePlusAnnually?.currency)}&nbsp;
                {enterprisePlusAnnually && formatMoney(enterprisePlusAnnually.price, enterprisePlusAnnually.currency)}&nbsp;
                {t('subscription.userYear')}
              </Typography>
          }
          <Typography variant='subtitle2' sx={{fontWeight: 400, mb: 3}}>
            {t(`subscription.${annually ? 'billedAnnually' : 'billedMonthly'}`)}
          </Typography>

          <Button
            size='large'
            variant='outlined'
            sx={{mb: 3}}
            onClick={() => selectPlan(annually ? enterprisePlusAnnually?.id : enterprisePlus?.id)}
            disabled={(annually ? enterprisePlusAnnually?.id === planId : enterprisePlus?.id === planId) && !isTrial && !unpaidSubscription}
          >
            {t('subscription.selectPlan')}
          </Button>

          {enterprisePlusFeatures.map((feature, index) =>
            <Box key={index} className={classes.feature}>
              <CheckCircle color='warning' sx={{mr: 1}} />
              <Typography align='left' variant='body2'>
                {t(`subscription.${feature}`)}
              </Typography>
            </Box>
          )}
        </Paper>
      </Grid>
    </Grid>
  )

  const paymentDetailsStep = (
    <Card className={classes.cardWrapper}>
      {!isTrialExpired &&
        <FormControlLabel
          control={
            <Checkbox
              checked={usePreviousCard}
              onChange={() => setUsePreviousCard(!usePreviousCard)}
            />
          }
          label={`${t('subscription.usePreviousCard')}`}
          className={classes.cardCheckbox}
        />
      }
      <Elements stripe={STRIPE_PROMISE}>
        <StripePayment {...(usePreviousCard && {onSave: () => navigate(homeLink)})} />
      </Elements>
    </Card>
  )

  React.useEffect(() => {
    if (planId.includes('yearly')) setAnnually(true)
    if (subscriptions.length > 0) return

    loadSubscriptions()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <HeaderTitle
        backUrl={homeLink}
        breadcrumb={t('common.backTo', { menu: t('menu.home')})}
      />

      <Container className={classes.stepWrapper}>
        <Stepper activeStep={step} >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel className={classes.stepLabel}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Container>
      {step === 0 &&
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
          <Typography color={!annually ? 'primary' : ''}>{t('subscription.monthly')}</Typography>
          <Switch checked={annually} onChange={(e) => setAnnually(e.target.checked)}/>
          <Typography color={annually ? 'primary' : ''}>{t('subscription.annually')}</Typography>
        </Stack>
      }
      <Container sx={{mb: 6, mt: 20}}>
        {step === 0 && selectPlanStep}
        {step === 1 && paymentDetailsStep}
      </Container>

      <ModalConfirm
        open={openConfirm}
        description={ReactHtmlParser(t('subscription.confirmSelectIndividualTrialVersion'))}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          setOpenConfirm(false)
          selectPlan(annually ? individualAnnually?.id : individual?.id)
        }}
      />

      <DormantModal
        rootStore={useStores()}
        open={openDormantModal}
        setOpen={setOpenDormantModal}
        openDormant={openDormant}
        setOpenDormant={setOpenDormant}
      />
    </>
  )
})
