/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-01 21:15:06
 * @modify date 2022-03-01 21:15:06
 */

import { Instance, SnapshotOut, types } from "mobx-state-tree"

import {
  withReset,
  withRootStore
} from "models/extensions"

/**
 * Model description here for TypeScript hints.
 */
export const CupperModel = types
  .model("Cupper")
  .props({
    id: types.identifier,
    name: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    avatar: types.maybeNull(types.union(types.string, types.number)),
    lastActivity: types.maybeNull(types.string),
    canCreateCuppingSessions: types.optional(types.boolean, false),
    adminPrivileges: types.optional(types.boolean, false),
    active: types.optional(types.boolean, false),
    activationSettingDisabled: types.optional(types.boolean, false),
    adminPrivilegesSettingDisabledFor: types.optional(types.boolean, false),
    unremindable: types.optional(types.boolean, false),
    unremovable: types.optional(types.boolean, false),
    canApproveSample: types.optional(types.boolean, false),
  })
  .extend(withRootStore)
  .extend(withReset)

type CupperType = Instance<typeof CupperModel>
export interface Cupper extends CupperType {}
type CupperSnapshotType = SnapshotOut<typeof CupperModel>
export interface CupperSnapshot extends CupperSnapshotType {}
