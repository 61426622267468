/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-04 06:18:17
 * @modify date 2022-04-04 06:18:17
 */

import React from 'react'
import { Box, Typography } from '@mui/material'

import { LanguageProps } from './languange.props'

export const En: React.FC<LanguageProps> = (props: LanguageProps) => {  
  const { openMail, openTermsOfUse } = props
  
  return (
    <Box>
      <Typography variant='h5' sx={{mb: 2, mt: {md: '-25px'}}}>
        PRIVACY STATEMENT FOR USERS OF THE TASTIFY PROGRAM
      </Typography>
      <Typography variant='body1' paragraph>
        This Privacy Statement governs all use of TASTIFY, INC.’s (“Company” or “We” or “Us” or “TASTIFY”) “Tastify Program” (as identified below) and the Company’s site that provides access to its “Tastify Program” (unless indicated otherwise, the term “Site” means, collectively, the Tastify Program and all other aspects of the site accessed at tastify.com). The term :Tastify Program” means TASTIFY’s proprietary program which assists users in analyzing taste profiles for, and other flavors and components of, coffee and other beverages.
      </Typography>

      <Typography variant='body1' paragraph>
        We provide this Privacy Statement to explain to you, the user of the Tastify Program and all other aspects of the Site (“You” or “User”) our policies with regard to the privacy of information We may gather through your use of the Tastify Program or other features of the Site.
      </Typography>
      
      <ol>
        <li>
          <Typography variant='h6' sx={{mb: 1}}>COLLECTION OF PERSONALLY IDENTIFIABLE INFORMATION</Typography>
          <Typography variant='body2' paragraph>
            During your use of the Tastify Program or other features of the Site, we may collect and retain the following (hereinafter referred to as your “personal identifiable information”): (i) information and data about You (such as your name, e-mail address, mailing address, phone number, and other personal data); (ii) any “Content” that You may have uploaded, distributed, published or otherwise used via the Tastify Program or the Site (for purposes of this Privacy Statement, the term “Content” has the same meaning as in the Terms of Use agreement that governs your use of the Tastify Program and the Site); or (iii) any other information or data about how You use, or the results of your use of, the Tastify Program/Site, including without limitation the results of your cupping sessions.
          </Typography>
          <Typography variant='body2' paragraph>
            During your use of the Tastify Program or other features of the Site, we may collect and retain the following (hereinafter referred to as your “personal identifiable information”): (i) information and data about You (such as your name, e-mail address, mailing address, phone number, and other personal data); (ii) any “Content” that You may have uploaded, distributed, published or otherwise used via the Tastify Program or the Site (for purposes of this Privacy Statement, the term “Content” has the same meaning as in the Terms of Use agreement that governs your use of the Tastify Program and the Site); or (iii) any other information or data about how You use, or the results of your use of, the Tastify Program/Site, including without limitation the results of your cupping sessions.
          </Typography>
          <Typography variant='body2' paragraph>
            All of your personally identifiable information that we collect during your use of the Tastify Program or any other features of the Site may be used: (i) to provide you proper access to, and to provide You the results from your use of, the Tastify Program; (ii) to provide You with notices, material, or other information about features or functionalities of the Tastify Program;(iii) to provide you with notices, material or other information about any services, products, goods, or any other items of the Company or of any commercial partner of the Company (“Our Commercial Partners”) (collectively “ Products”); (iv) to make available to You any other information about the Company, the Company’s Products, or Products of Our Commercial Partners; (v) to make available to You any other information about any other events, functions, or items promoted or otherwise made available by the Company or any of Our Commercial Partners; or (vi) for any other lawful purpose (hereinafter, the “Intended Uses”).
          </Typography>
          <Typography variant='body2' paragraph>
            The Company does not intentionally share personally identifiable information with third parties without your consent, provided, however, We may share some or all of your personal identifiable information : (i) with a third party (including, without limitation, Our Commercial Partners) to perform any features or functions that You request via the Site; (ii) to the extent it becomes necessary to comply with applicable laws or to enforce our rights; (iii) with any business partners or affiliates of the Company, including, but not limited to, Our Commercial Partners, for any of the other “Intended Uses” noted in the Privacy Statement; and/or (iv) to the extent, and pursuant to, the terms of the Site’s “{openTermsOfUse}” agreement. If You have any hesitation about providing such information to Us and/or having such information used in any manner permitted in this Privacy Statement or in the Site’s “Term of Use for Users”, then You should no longer use the Site.
          </Typography>
          <Typography variant='body2' paragraph>
            In the event You have provided Us with personally identifiable information such as that discussed above, and thereafter You do not want to continue to directly receive further email communications from the Company, You must send an email message to info@tastify.com stating that You no longer want to receive email communications directly from the Company (“Opt Out”). Once the Company has received your request to Opt Out, the Company will use commercially reasonable efforts, in a commercially reasonable time period, to try to carry out your request to Opt Out; provided, however, We will not have any obligation to retrieve, and cannot prevent the further use by others (including, without limitation, Our Commercial Partners) of your personal identifiable information that may have been shared with others up to that time.
          </Typography>
          <Typography variant='body2' paragraph>
            If You elect to Opt Out, and provided You have properly delivered your Opt Out request to the Company in accordance with this Privacy Statement, You will no longer receive emails directly from Company after a commercially reasonable time from the date You have sent your Opt Out notice to Company. However, the Company does not have any obligations under, or liability arising out of, this Privacy Statement with regard to: (a) your use of any other internet websites, blogs, bulletin boards, chat rooms, and similar forums which are not under Company’s control and ownership, even if You linked to any of these forums via the Site; and (b) any other communications sent to You by any party (including, without limitation, Our Commercial Partners) other than directly from the Company. Accordingly, You should contact such other entities (including, without limitation, Our Commercial Partners) directly regarding their use of your personally identifiable information.
          </Typography>
        </li>

        <li>
          <Typography variant='h6' sx={{mb: 1}}>ENFORCEMENT OF THIS PRIVACY STATEMENT</Typography>
          <Typography variant='body2' paragraph>
            You hereby confirm and agree that by your act of using this Site, including, but not limited to, using the Tastify Program, uploading any of your personal identifiable information (including, but not limited to, providing any of your Content while using the Tastify Program), You: (a) unconditionally agree to all of the terms and conditions of this Privacy Statement; and (b) hereby confirm and agree that no provision of this Privacy Statement, including, but not limited to, the Opt Out provisions of Section 1, shall limit, condition, alter, or amend, in any way whatsoever, any of the rights You have granted to the Company pursuant to Site’s “{openTermsOfUse}”. If there is any inconsistency between the rights of the Company pursuant to this Site’s “Terms of Use” and this Privacy Statement, then You agree that this Site’s “Terms of Use” shall govern.
          </Typography>
        </li>

        <li>
          <Typography variant='h6' sx={{mb: 1}}>PROTECTION OF PERSONALLY IDENTIFIABLE INFORMATION</Typography>
          <Typography variant='body2' paragraph>
            Except for any use by internet search engines, or any use by Our Commercial Partners, and as otherwise set forth in this Privacy Statement (which includes, but is not limited to, the “Intended Uses” noted in the Privacy Statement)and in this Site’s “Terms of Use” that govern your use of this Site, We generally do not intentionally make available to the general public any other personally identifiable information You provide to Us in connection with your use of the Site. Notwithstanding the foregoing, the transmission of personally identifiable information between You and the Site may not be encrypted and may not be secure. While we strive to protect your personal identifiable information, we cannot ensure or warrant the security of any information transmitted to, from, or over the Site, and You do so at your own risk.
          </Typography>
        </li>
        
        <li>
          <Typography variant='h6' sx={{mb: 1}}>ACCESSING AND UPDATING YOUR PERSONALLY IDENTIFIABLE INFORMATION</Typography>
          <Typography variant='body2' paragraph>
            You are solely responsible for correcting, updating, modifying or eliminating any and all of your personally identifiable information as it appears in, and as otherwise stored or contained in, the Site. Without in any way limiting the foregoing, You acknowledge and agree that We have no duty to correct, update, modify or eliminate any of your personally identifiable information as it appears in, is stored, or is otherwise contained in, the Site.
          </Typography>
        </li>
        
        <li>
          <Typography variant='h6' sx={{mb: 1}}>LINKS TO, AND USE OF, THIRD PARTY SITES</Typography>
          <Typography variant='body2' paragraph>
            The Site may, now or in the future, provide links or other access to Internet websites (including, but not limited to, any online payment solution services), blogs, bulletin boards, chat rooms, and similar forums which are not under the Company’s sole control and not solely owned by the Company (collectively referred to as “Third Party Sites”) If You click on a link to, or otherwise gain access to, such a Third Party Site, You will be transported to one of these Third Party Sites. THIS PRIVACY STATEMENT APPLIES TO OUR “SITE” (AS DEFINED HEREIN) ONLY, AND IS NOT INTENDED TO DESCRIBE THE PRIVACY POLICIES OF THIRD PARTY SITES WITH REGARD TO THEIR COLLECTION AND USE OF PERSONALLY IDENTIFIABLE INFORMATION. THE COMPANY IS NOT RESPONSIBLE FOR THE PRIVACY PRACTICES OF THIRD PARTY SITES. IF YOU CLICK ON A LINK TO, OR ARE SENT TO A LINK OF, OR OTHERWISE GAIN ACCESS TO, A THIRD PARTY SITE, YOU SHOULD REVIEW THE PRIVACY STATEMENT OF SUCH THIRD PARTY SITE (IF ANY) TO DETERMINE THAT THIRD PARTY SITE'S PRACTICES WITH REGARD TO THE COLLECTION AND USE OF PERSONALLY IDENTIFIABLE INFORMATION. Without limiting the generality of the foregoing, You acknowledge that if You use any Third Party Site in any way, You are aware that any of your personally identifiable information that You provide to that Third Party Site might be read, collected, or otherwise used by other users of that Third Party Site or by any other third parties, and could be used to send You unsolicited messages. We are not responsible for any personally identifiable information You choose to submit in, or otherwise make available to, these Third Party Sites. Any link on the Site to a Third Party Site does not imply an endorsement of the privacy practices of such Third Party Site by Us, and no such Third Party Site is authorized to make any representation or warranty on our behalf.
          </Typography>
        </li>
        
        <li>
          <Typography variant='h6' sx={{mb: 1}}>NON-PERSONALLY IDENTIFIABLE INFORMATION</Typography>
          <Typography variant='body2' paragraph>
            During your visit to the Site, We may use your Internet Protocol (“IP”) address to help Us administer the Site. Also, our server may place small temporary text files called “cookies” on your computer's processor or in the browser directory of your hard drive. We use your IP address and/or cookies to gather non-personally identifiable demographic information, such as how many Users visited a certain part of the Site, but not to track a specific IP address to the identity of a particular individual User. You may choose not to accept cookies by setting your web browser to either refuse all cookies or to notify You each time before a cookie is set.
          </Typography>
        </li>
        
        <li>
          <Typography variant='h6' sx={{mb: 1}}>DO NOT TRACK</Typography>
          <Typography variant='body2' paragraph>
            Some browsers have a “do not track” feature that lets web site users inform web sites or other applications that they do not want to have their online activities tracked. These ”do not track” features may also give website users other choices regarding the collection of their personal identifiable information. However, these “do not track” features and “do not track’ signals are not yet uniform. Accordingly, each User of this Site is hereby notified that this Site is not currently set up to respond to any of your “do not track” features or “do not track” signals.
          </Typography>
        </li>
        
        <li>
          <Typography variant='h6' sx={{mb: 1}}>THIS PRIVACY STATEMENT MAY CHANGE</Typography>
          <Typography variant='body2' paragraph>
            This Privacy Statement may be changed from time to time and at any time by the Company. The Company reserves the right to change it without notice to You. YOU ARE BOUND BY THE VERSION OF THIS PRIVACY STATEMENT THAT IS IN EFFECT AT THE TIME YOU VISIT THE SITE. THE TERMS OF THIS PRIVACY STATEMENT MAY BE DIFFERENT THE NEXT TIME YOU USE THIS SITE. ANY USE OF THE COMPANY’S SITE BY YOU AFTER SUCH AMENDMENT(S) SHALL BE DEEMED TO CONSTITUTE ACCEPTANCE BY YOU OF THE AMENDMENT(S). YOU SHOULD REGULARLY REVIEW AND PRINT THIS PRIVACY STATEMENT FOR YOUR RECORDS.
          </Typography>
        </li>
      </ol>

      <Typography variant='body1' paragraph>
        If You believe that the Company has not complied with the policies outlined in this Privacy Statement, or if You have questions relating to privacy, then You should write to Us at {openMail}.
      </Typography>
    </Box>
  )
}